import Container from '@material-ui/core/Container';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AppProvider } from './config/AppContext';
import Home from './routes/Home/Home';
import Login from './routes/Login/Login';
import LocalStorageService from './services/LocalStorage/LocalStorage.service';
import PosTerminal from './routes/Pos/Pos2';

function App() {
  const [redirectUrl, setRedirectUrl] = useState('/login');
  const isAuthenticated = (): boolean =>
    !!LocalStorageService.getLocalData('authToken');

  useEffect(() => {
    const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
    if (tenant_schema === 'true') {
      setRedirectUrl('/twin-dolphin');
    } else {
      setRedirectUrl('/login');
    }
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <AppProvider>
        <BrowserRouter>
          <Container maxWidth="xl" className="main-container">
            <Switch>
              <Route
                exact={true}
                path="/"
                render={() => (
                  <Redirect
                    to={isAuthenticated() ? '/home/venues' : redirectUrl}
                  />
                )}
              />
              <Route
                path="/home"
                render={(routeProps: any) =>
                  isAuthenticated() ? (
                    <Home {...routeProps} />
                  ) : (
                    <Redirect to={redirectUrl} />
                  )
                }
              />
              <Route
                path="/login"
                render={(routeProps: any) => <Login {...routeProps} />}
              />
              <Route
                path="/twin-dolphin"
                render={(routeProps: any) => <Login {...routeProps} />}
              />
              <Route
                path="/terminal"
                render={(routeProps: any) =>
                  isAuthenticated() ? (
                    <PosTerminal {...routeProps} />
                  ) : (
                    <Redirect to={redirectUrl} />
                  )
                }
              />
            </Switch>
          </Container>
        </BrowserRouter>
      </AppProvider>
    </SnackbarProvider>
  );
}

export default App;
