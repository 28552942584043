import { isEmpty } from 'lodash';
import { WithSnackbarProps } from 'notistack';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import {
  ICreateACHAccountRequest,
  IUpdateVenueWithImageRequest,
} from '../../models/Request.model';
import { IUser } from '../../models/User.model';
import { IVenue, IVenueAch } from '../../models/Venue.model';
import { canEditVenue } from '../../services/Auth.service';
import MyProfile from './MyProfile/MyProfile';
import OrganizationDetails from './OrganizationDetails/OrganizationDetails';
import { VenueLocation } from './VenueLocation/VenueLocation';

export interface ISettingsTab {
  component: any;
  label: string;
  name: string;
  route: string;
}

const myProfileTab: ISettingsTab = {
  component: MyProfile,
  label: 'My Profile',
  name: 'myProfile',
  route: 'my-profile',
};

const organizationDetailsTab: ISettingsTab = {
  component: OrganizationDetails,
  label: 'Organization Settings',
  name: 'settings',
  route: 'organization-details',
};

const locationsTab: ISettingsTab = {
  component: VenueLocation,
  label: 'Delivery Locations / Suites',
  name: 'locations',
  route: 'locations',
};

export const getSecondaryTabs = (
  user: IUser,
  venueId: string,
): ISettingsTab[] => {
  if (canEditVenue(user, venueId)) {
    return [myProfileTab, organizationDetailsTab, locationsTab];
  }

  return [myProfileTab];
};

export interface ISettingsProps extends WithSnackbarProps, RouteComponentProps {
  venueId: string;
}

export interface IOrganizationDetailsFormValues extends Partial<IVenue> {
  imageData?: any;
  imageUrl?: string;
}

export interface IOrganizationDetailsProps extends WithSnackbarProps {
  onUpdate: (request?: IUpdateVenueWithImageRequest) => void;
  setShowImageDetails: (show: boolean) => void;
  showImageDetails: boolean;
  venue: any;
}

export interface IValidLocation {
  name: string;
  locName: string;
  used: boolean;
  value: string;
  validValues: string[];
}

export type ISettingsRequest =
  | ICreateACHAccountRequest
  | IUpdateVenueWithImageRequest;

export type IPaymentSettingsFormValues = IVenueAch;

export const initialAchDetails: IPaymentSettingsFormValues = {
  accountNumber: '',
  accountNumberConfirm: '',
  addressLine1: '',
  addressLine2: '',
  businessUnit: true,
  city: '',
  companyName: '',
  companyTaxId: '',
  dob: new Date(),
  firstName: '',
  lastName: '',
  routingNumber: '',
  ssnLast4: '',
  state: '',
  zip: '',
};

export interface IPaymentSettingsProps {
  bankAccountLast4?: string;
  bankAccountName?: string;
  disableActionButton: boolean;
  gatewayConnectAccountId?: string;
  onDelete: () => Promise<void>;
  onUpdate: (updatedData: any) => Promise<void>;
}

export const organizationDetails = {
  deliverySpotsInfo: `Set the delivery spots that you want your customers to input such as row numbers, parking spots, seat number`,
  locationNotificationInfo: `FanFood users can receive notifications when they are within specified distance from your location`,
  optionalInfo: `(Optional) - May be completed later in the 'Account Settings'`,
  requiredInfo: `*Required to complete the onboarding process`,
  subtitleInfo: `Please complete your organizational details so that users may access your store(s) in the Fanfood app`,
  tenantBuildingImageInfo: `Image should be 500x400 px and less than 25mb in size`,
  venueBannerImageInfo: `Image should be 500x150 px and less than 25mb in size`,
};

export const paymentSettingsInfo = {
  optionalInfo: `(Optional) - Can be filled later from the Account Settings`,
  requiredInfo: `* Required to complete the onboarding process.`,
  subtitleInfo: `Please complete your payment settings in order to quickly and securely receive your revenue on all your digital sales`,
};

export const achSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Required'),
  accountNumberConfirm: Yup.string().when('accountNumber', {
    is: val => !isEmpty(val),
    otherwise: Yup.string().notRequired(),
    then: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('accountNumber')], 'Account Numbers dont match'),
  }),
  addressLine1: Yup.string().required('Required'),
  businessUnit: Yup.boolean().default(true),
  city: Yup.string().required('Required'),
  companyName: Yup.string().when('businessUnit', {
    is: true,
    otherwise: Yup.string().notRequired(),
    then: Yup.string().required('Required'),
  }),
  companyTaxId: Yup.string().when('businessUnit', {
    is: true,
    otherwise: Yup.string().notRequired(),
    then: Yup.string().required('Required'),
  }),
  dob: Yup.date().required(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  routingNumber: Yup.string()
    .label('Routing Number')
    .length(9, 'Must be exactly 9 digits')
    .required('Required'),
  ssnLast4: Yup.string()
    .label('Last SSN digits')
    .length(4, 'Must be exactly 4 digits')
    .required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

export const organizationSettingsSchema = Yup.object().shape({
  active: Yup.boolean().default(false),
  addressLine1: Yup.string()
    .nullable()
    .required('Required'),
  alcoholLimit: Yup.number().default(0),
  alcoholMessage: Yup.string()
    .nullable()
    .when('alcoholLimit', {
      is: val => val > 0,
      otherwise: Yup.string().notRequired(),
      then: Yup.string().required('Required'),
    }),
  allowFreeProducts: Yup.boolean().default(false),
  city: Yup.string()
    .nullable()
    .required('Required'),
  contactCountryCode: Yup.string().default('1'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .nullable()
    .required('Required'),
  contactFirstName: Yup.string()
    .nullable()
    .required('Required'),
  contactLastName: Yup.string()
    .nullable()
    .required('Required'),
  contactPhoneNumber: Yup.string()
    .nullable()
    .required('Required'),
  deliveryAvailable: Yup.boolean().default(false),
  maxDistance: Yup.number().min(3),
  name: Yup.string().required('Required'),
  pickupAvailable: Yup.boolean().default(false),
  salesTaxPercent: Yup.number().when('calculateTaxes', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(1, 'Sales tax percent must be greater than 0')
      .max(100)
      .required('Required'),
  }),
  state: Yup.string()
    .nullable()
    .required('Required'),
  timezone: Yup.string().nullable(),
  type: Yup.string().required('Required'),
  vanityUrl: Yup.string()
    .strict(true)
    .required('Required')
    .lowercase('URL must be a lowercase string')
    .matches(/^[a-zA-Z]+$/, {
      excludeEmptyString: true,
      message: 'URL cannot contain spaces, special characters or numbers',
    })
    .max(30, 'URL cannot be more than 30 characters'),
  zip: Yup.string()
    .nullable()
    .required('Required'),
});
