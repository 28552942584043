import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link, Restaurant } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PartnerTable from '../../../components/Partner/PartnerTable';
import { useAppContext } from '../../../config/AppContext';
import {
  IVenueLocation,
  VenueLocationType,
} from '../../../models/Location.model';
import { IPartnerLocation } from '../../../models/Partner.model';
import { IStore } from '../../../models/Store.model';
import { UserVenueRole } from '../../../models/User.model';
import { isAdmin } from '../../../services/Auth.service';
import PartnerService from '../../../services/Partner/Partner.service';
import StoreService from '../../../services/Store/Store.service';
import VenueInviteService from '../../../services/Venue/VenueInvite.service';
import {
  getDeliveryLocationWebLink,
  getSuiteInviteLink,
} from '../../../services/WebLinks.service';
import { venuePath } from '../../TabConfig';
import './VenueLocation.scss';

interface IVenueDeliveryLocationProps {
  location: IVenueLocation;
}

export const VenueDeliveryLocation = ({
  location,
}: IVenueDeliveryLocationProps) => {
  const { venue, user } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const isSuite = location.type === VenueLocationType.SUITE;
  const [suiteKitchen, setSuiteKitchen] = useState<IStore | undefined>();
  const [stores, setStores] = useState<IStore[]>([]);
  const [partnerLocations, setPartnerLocations] = useState<IPartnerLocation[]>(
    [],
  );

  const fetchPartnerLocations = async () => {
    const { data } = await PartnerService.getPartnerLocations({
      venueLocationId: location.id,
    });

    setPartnerLocations(data);
  };

  const fetchSuiteKitchen = async () => {
    if (!isSuite) {
      return;
    }

    const { data } = await StoreService.getSuiteKitchen(location.id);
    setSuiteKitchen(data);
  };

  const fetchStores = async () => {
    const { data } = await StoreService.getStoresForLocation(location);
    setStores(data.items);
  };

  useEffect(() => {
    if (location && location.isDeliveryLocation) {
      fetchPartnerLocations();
    }
  }, [location]);

  useEffect(() => {
    fetchSuiteKitchen();
  }, [location]);

  useEffect(() => {
    fetchStores();
  }, [location]);

  if (!venue || !user) {
    return null;
  }

  const copyLocationLinkToClipboard = (
    deliveryLocationId: string,
    storeId: string,
  ) => {
    navigator.clipboard.writeText(
      getDeliveryLocationWebLink(venue.id, deliveryLocationId, storeId),
    );
    enqueueSnackbar('Store Delivery Link Copied to Clipboard', {
      variant: 'success',
    });
  };

  const buildSuiteInviteLink = async () => {
    const { data } = await VenueInviteService.createVenueInvite({
      allEvents: true,
      role: UserVenueRole.SUITE_CUSTOMER,
      suiteIds: [location.id],
      venueId: venue.id,
    });

    navigator.clipboard.writeText(getSuiteInviteLink(data.id));
    enqueueSnackbar('Suite Invite Link Copied to Clipboard', {
      variant: 'success',
    });
  };

  const renderStoreListItem = (store: IStore, isSuiteRender: boolean) => (
    <ListItem
      button={true}
      onClick={() =>
        history.push(venuePath(venue.id, `stores/${store.id}/menu`))
      }
      disableRipple={true}
      key={store.id}
    >
      <ListItemIcon>
        <Restaurant />
      </ListItemIcon>
      <ListItemText primary={store.name} />
      {venue.useNewLocations && (
        <Button
          size="medium"
          className={`header-button classic-button`}
          onClick={(e: any) => {
            e.stopPropagation();
            isSuiteRender
              ? buildSuiteInviteLink()
              : copyLocationLinkToClipboard(location.id, store.id);
          }}
        >
          <div className="icon-title-wrapper">
            <Link />
            <span className="icon-title">
              {isSuiteRender
                ? 'Build Suite Invite Link'
                : 'Copy Link To Store Delivery Location'}
            </span>
          </div>
        </Button>
      )}
    </ListItem>
  );

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Typography component="h6" variant="h6">
          {location.name}{' '}
          {location.isCustomInput && <small>(Custom Input Field)</small>}
        </Typography>
      </Grid>

      {isSuite && (
        <>
          <Grid item={true} xs={12}>
            <Typography component="h6" variant="body1">
              {suiteKitchen ? (
                <>
                  <span>Suite Kitchen:</span>
                  <List>{renderStoreListItem(suiteKitchen, true)}</List>
                </>
              ) : (
                <span className="required">
                  This Suite is not assigned to a Suite Kitchen
                </span>
              )}
            </Typography>
          </Grid>
        </>
      )}

      {!isEmpty(stores) && (
        <Grid item={true} xs={12}>
          <Typography component="h6" variant="body1">
            The Following Stores can deliver to this location:
          </Typography>
          <List>{stores.map(store => renderStoreListItem(store, false))}</List>
        </Grid>
      )}

      {isAdmin(user) && (
        <Grid item={true} xs={12}>
          <PartnerTable
            baseEntityId={location.id}
            createPartnerData={PartnerService.createPartnerLocation}
            deletePartnerData={PartnerService.deletePartnerLocation}
            entityName={'Partner Location'}
            keys={{
              partner: 'Partner',
              partnerLocationId: 'Partner Location ID',
            }}
            partnerData={partnerLocations}
            refreshData={fetchPartnerLocations}
          />
        </Grid>
      )}
    </Grid>
  );
};
