export enum Partner {
  CUBS = 'CUBS',
  FANFOOD = 'FANFOOD',
}

interface IPartnerGetQuery {
  partners?: string[];
}

export interface IGetPartnerEvent extends IPartnerGetQuery {
  eventId: string;
}

export interface IGetPartnerLocation extends IPartnerGetQuery {
  venueLocationId: string;
}

export interface IPartnerEntity {
  id: string;
  partner: Partner;
}

export interface ICreatePartnerEntity {
  baseEntityId: string;
  partnerEntityId: string;
  partner: Partner;
}

export interface IPartnerLocation extends IPartnerEntity {
  partnerLocationId: string;
  venueLocationId: string;
}

export interface IPartnerEvent extends IPartnerEntity {
  eventId: string;
  partnerEventId: string;
}
