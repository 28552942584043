import {
  Button,
  DialogContent,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useAppContext } from '../../../config/AppContext';
import { useStoreContext } from '../../../config/StoreContext';
import { FeeUnit, IStore, ValidStoreType } from '../../../models/Store.model';
import { IVenue } from '../../../models/Venue.model';
import { isSuperAdmin } from '../../../services/Auth.service';
import AddImage from '../../AddImage/AddImage';
import './StoreDialog.scss';

const StoreDialog = () => {
  const { user, venue } = useAppContext();
  const { store, updateStore } = useStoreContext();

  const [hasMinimumOrder, setHasMinimumOrder] = useState<boolean>(
    (store?.orderMinimum ?? 0) > 0,
  );
  const [hasOrderVolumeLimit, setHasOrderVolumeLimit] = useState<boolean>(
    (store?.maxOrdersPerFifteenMinutes ?? 0) > 0,
  );

  if (!venue || !user || !store) {
    return null;
  }

  const onSaveStore = async (
    values: IStore,
    formikActions: FormikActions<IStore>,
  ) => {
    const { setSubmitting } = formikActions;
    await updateStore(store.id, values);
    setSubmitting(false);
  };

  return (
    <Formik<IStore>
      initialValues={store}
      onSubmit={onSaveStore}
      validationSchema={Yup.object().shape({
        deliveryFee: Yup.number()
          .nullable()
          .min(0, 'Delivery Fee must be at least 0')
          .max(100, 'Delivery Fee must be at most 100'),
        deliveryFeeSharePercent: Yup.number()
          .nullable()
          .min(0, 'Delivery Fee Share Percent must be at least 0')
          .max(100, 'Delivery Fee Share Percent must be at most 100'),
        deliveryRevenueSharePercent: Yup.number()
          .nullable()
          .min(0, 'Delivery Revenue Share Percent must be at least 0')
          .max(100, 'Delivery Revenue Share Percent must be at most 100'),
        liquorTaxPercent: Yup.number()
          .nullable()
          .min(0, 'Liquor Tax Percent must be at least 0')
          .max(100, 'Liquor Tax Percent must be at most 100'),
        name: Yup.string().required('Name is Required'),
        ...(hasMinimumOrder && {
          orderMinimum: Yup.number()
            .positive('Minimum order amount must be positive')
            .required('Minimum order amount is required'),
        }),
        ...(hasOrderVolumeLimit && {
          maxOrdersPerFifteenMinutes: Yup.number()
            .positive('Maximum orders per 15 minutes must be positive')
            .required('Maximum orders per 15 minutes is required'),
        }),
        pickupFee: Yup.number()
          .nullable()
          .min(0, 'Pickup Fee must be at least 0')
          .max(100, 'Pickup Fee must be at most 100'),
        pickupFeeSharePercent: Yup.number()
          .nullable()
          .min(0, 'Pickup Fee Share Percent must be at least 0')
          .max(100, 'Pickup Fee Share Percent must be at most 100'),
        pickupRevenueSharePercent: Yup.number()
          .nullable()
          .min(0, 'Pickup Revenue Share Percent must be at least 0')
          .max(100, 'Pickup Revenue Share Percent must be at most 100'),
        priority: Yup.number()
          .integer()
          .required('Display Order is required'),
        salesTaxPercent: Yup.number()
          .nullable()
          .min(0, 'Sales Tax Percent must be at least 0')
          .max(100, 'Sales Tax Percent must be at most 100'),
        type: Yup.string().required('Store Type is required'),
      })}
      render={(formProps: FormikProps<IStore>) => {
        const {
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          submitForm,
          touched,
          values,
        } = formProps;

        const renderSetting = (
          control: JSX.Element,
          field: keyof IStore,
          overridableToggleChecked: boolean,
          overriddenField?: keyof IVenue,
        ) => (
          <>
            <div className="store-dialog__content__setting">
              {control}

              {overriddenField && (
                <div className="store-dialog__content__setting__toggle">
                  <Switch
                    checked={overridableToggleChecked}
                    classes={{ track: 'custom-colored-switch-track' }}
                    color="primary"
                    onClick={() => setFieldValue(field, undefined)}
                    size="medium"
                  />
                  <InputLabel>
                    Use Venue value ({`${venue[overriddenField]}`})
                  </InputLabel>
                </div>
              )}
            </div>
            <ErrorMessage className="error" component="div" name={field} />
          </>
        );

        const renderBooleanSetting = (
          field: keyof IStore,
          label: string,
          overriddenField?: keyof IVenue,
        ) => {
          const value = values[field];

          return renderSetting(
            <div className="store-dialog__content__setting__toggle">
              <Switch
                checked={!!value}
                classes={{ track: 'custom-colored-switch-track' }}
                color="primary"
                name={field}
                onChange={handleChange}
                size="medium"
                value={value}
              />
              <InputLabel>{label}</InputLabel>
            </div>,
            field,
            value === null || typeof value === 'undefined',
            overriddenField,
          );
        };

        // ignoring this due to prettier being unaware of valid <T,> syntax
        // prettier-ignore
        const renderEnumSelectSetting = <TEnum,>(
            field: keyof IStore,
            label: string,
            enumType: TEnum,
            required: boolean,
            overriddenField?: keyof IVenue,
          ) => {
            const value = values[field];

            return renderSetting((
              <TextField
                error={touched[field] && !!errors[field]}
                label={label}
                name={field}
                onBlur={handleBlur}
                onChange={handleChange}
                required={required}
                select={true}
                value={value || ''}
              >
                <MenuItem value={undefined}>&nbsp;</MenuItem>
                {Object.entries(enumType).map(
                  (type: any, index: number) => (
                    <MenuItem value={type[0]} key={index}>
                      {type[1]}
                    </MenuItem>
                  ),
                )}
              </TextField>
            ),
              field,
              !value,
              overriddenField,
            );
          };

        const renderNumericSetting = (
          field: keyof IStore,
          label: string,
          overriddenField?: keyof IVenue,
        ) => {
          const value = values[field];

          return renderSetting(
            <TextField
              autoComplete="off"
              error={touched[field] && !!errors[field]}
              inputProps={{
                min: '0.00',
                step: '0.01',
                type: 'number',
              }}
              label={label}
              name={field}
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={typeof value !== 'undefined' ? value : ''}
            />,
            field,
            !value,
            overriddenField,
          );
        };

        const renderTextSetting = (
          field: keyof IStore,
          label: string,
          placeholder: string,
          required: boolean,
          overriddenField?: keyof IVenue,
        ) => {
          const value = values[field];

          return renderSetting(
            <TextField
              error={touched[field] && !!errors[field]}
              label={label}
              name={field}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              required={required}
              type="text"
              value={value}
            />,
            field,
            !value,
            overriddenField,
          );
        };
        return (
          <>
            <DialogContent className="store-dialog__content">
              {renderTextSetting('name', 'Name', 'Enter Name', true)}
              {renderTextSetting(
                'location',
                'Location',
                'Enter Location',
                false,
              )}
              {renderTextSetting(
                'description',
                'Description',
                'Short description to describe your products',
                false,
              )}
              {renderNumericSetting('priority', 'Display Order')}
              {renderEnumSelectSetting(
                'type',
                'Store Type',
                ValidStoreType,
                true,
              )}

              {!!values.id && (
                <>
                  {renderBooleanSetting('active', 'Active')}
                  {renderBooleanSetting('visible', 'Show In Store List')}
                  <div className="store-dialog__content__setting">
                    <div className="store-dialog__content__setting__toggle">
                      <Switch
                        checked={hasMinimumOrder}
                        classes={{ track: 'custom-colored-switch-track' }}
                        color="primary"
                        onChange={() => {
                          const willHaveMinimumOrder = !hasMinimumOrder;
                          if (!willHaveMinimumOrder) {
                            setFieldValue('orderMinimum', 0);
                          }
                          setHasMinimumOrder(willHaveMinimumOrder);
                        }}
                        size="medium"
                        value={hasMinimumOrder}
                      />
                      <InputLabel>Minimum Order Amount</InputLabel>
                    </div>
                  </div>

                  {hasMinimumOrder && (
                    <>
                      <div className="store-dialog__content__setting">
                        <TextField
                          autoComplete="off"
                          error={touched.orderMinimum && !!errors.orderMinimum}
                          inputProps={{
                            min: 0.0,
                            step: 0.01,
                            type: 'number',
                          }}
                          label="Minimum Order Amount"
                          name="orderMinimum"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required={hasMinimumOrder}
                          type="number"
                          value={values.orderMinimum}
                        />
                        <InputLabel>
                          Applies only to paid products for this Store
                        </InputLabel>
                      </div>

                      <ErrorMessage
                        className="error"
                        component="div"
                        name="orderMinimum"
                      />
                    </>
                  )}

                  <div className="store-dialog__content__setting">
                    <div className="store-dialog__content__setting__toggle">
                      <Switch
                        checked={hasOrderVolumeLimit}
                        classes={{ track: 'custom-colored-switch-track' }}
                        color="primary"
                        onChange={() => {
                          const willHaveOrderVolumeLimit = !hasOrderVolumeLimit;
                          if (!willHaveOrderVolumeLimit) {
                            setFieldValue('maxOrdersPerFifteenMinutes', 0);
                          }
                          setHasOrderVolumeLimit(willHaveOrderVolumeLimit);
                        }}
                        size="medium"
                        value={hasOrderVolumeLimit}
                      />
                      <InputLabel>Limit Order Quantity</InputLabel>
                    </div>
                  </div>

                  {hasOrderVolumeLimit && (
                    <>
                      <div className="store-dialog__content__setting">
                        <TextField
                          autoComplete="off"
                          error={
                            touched.maxOrdersPerFifteenMinutes &&
                            !!errors.maxOrdersPerFifteenMinutes
                          }
                          inputProps={{
                            min: 0,
                            step: 1,
                            type: 'number',
                          }}
                          label="Maximum Orders per 15 Minute Interval"
                          name="maxOrdersPerFifteenMinutes"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required={hasOrderVolumeLimit}
                          type="number"
                          value={values.maxOrdersPerFifteenMinutes}
                        />
                      </div>

                      <ErrorMessage
                        className="error"
                        component="div"
                        name="maxOrdersPerFifteenMinutes"
                      />
                    </>
                  )}
                  {renderBooleanSetting('customDiscount', 'Custom Discount')}
                  {renderBooleanSetting(
                    'deliveryAvailable',
                    'Delivery Available',
                  )}
                  {renderBooleanSetting('pickupAvailable', 'Pickup Available')}
                  {renderBooleanSetting(
                    'orderNowAvailable',
                    'Order Now Available',
                  )}
                  {renderBooleanSetting(
                    'orderAheadAvailable',
                    'Order Ahead Available',
                  )}
                  {renderBooleanSetting(
                    'orderInstructionsAvailable',
                    'Customer Notes',
                  )}
                  {renderBooleanSetting('showProductImages', 'Product Images')}
                  {renderBooleanSetting('allowOpenTabs', 'Allow Open Tabs')}
                  {/* SUPER_ADMIN only */}
                  {isSuperAdmin(user) && (
                    <fieldset>
                      <legend>Admin only</legend>

                      {values.deliveryAvailable && (
                        <div>
                          <h3>Delivery</h3>

                          {renderEnumSelectSetting(
                            'deliveryFeeUnit',
                            'Delivery Fee Unit',
                            FeeUnit,
                            false,
                            'deliveryFeeUnit',
                          )}
                          {renderNumericSetting(
                            'deliveryFee',
                            'Delivery Fee',
                            'deliveryFee',
                          )}
                          {renderNumericSetting(
                            'deliveryFeeSharePercent',
                            'Delivery Fee Share Percent',
                            'deliveryFeeSharePercent',
                          )}
                          {renderNumericSetting(
                            'deliveryRevenueSharePercent',
                            'Delivery Revenue Share Percent',
                            'deliveryRevenueSharePercent',
                          )}
                          {renderBooleanSetting(
                            'enableDeliveryTips',
                            'Enable Delivery Tips',
                            'enableDeliveryTips',
                          )}
                        </div>
                      )}

                      {values.pickupAvailable && (
                        <div>
                          <h3>Pickup</h3>

                          {renderEnumSelectSetting(
                            'pickupFeeUnit',
                            'Pickup Fee Unit',
                            FeeUnit,
                            false,
                            'pickupFeeUnit',
                          )}
                          {renderNumericSetting(
                            'pickupFee',
                            'Pickup Fee',
                            'pickupFee',
                          )}
                          {renderNumericSetting(
                            'pickupFeeSharePercent',
                            'Pickup Fee Share Percent',
                            'pickupFeeSharePercent',
                          )}
                          {renderNumericSetting(
                            'pickupRevenueSharePercent',
                            'Pickup Revenue Share Percent',
                            'pickupRevenueSharePercent',
                          )}
                          {renderBooleanSetting(
                            'enablePickupTips',
                            'Enable Pickup Tips',
                            'enablePickupTips',
                          )}
                        </div>
                      )}

                      <div>
                        <h3>Taxes</h3>

                        {renderBooleanSetting(
                          'calculateTaxes',
                          'Tax Calculation',
                          'calculateTaxes',
                        )}
                        {renderNumericSetting(
                          'salesTaxPercent',
                          'Sales Tax Percent',
                          'salesTaxPercent',
                        )}
                        {renderNumericSetting(
                          'liquorTaxPercent',
                          'Liquor Tax Percent',
                          'liquorTaxPercent',
                        )}
                      </div>

                      <div>
                        <h3>Other</h3>
                        {values.type === ValidStoreType.REGULAR &&
                          venue.allowOpenTabs &&
                          renderBooleanSetting(
                            'acceptsOpenTabOrders',
                            'Accept Open Tab Orders',
                          )}
                        {renderBooleanSetting(
                          'bypassOrderFulfillment',
                          'Bypass Order Fulfillment',
                        )}
                      </div>
                    </fieldset>
                  )}

                  {store.imageUrl && store.imageUrl.length > 0 && (
                    <div>
                      <img
                        alt="store-front"
                        src={store.imageUrl}
                        height="200"
                        width="400"
                        className="store-image"
                      />
                    </div>
                  )}
                </>
              )}
              <div className="upload-image">
                <AddImage
                  getFile={(storeImage: string | null) =>
                    setFieldValue('image_file', storeImage)
                  }
                  height={200}
                  minHeight={250}
                  minWidth={500}
                  previewBottom={false}
                  showImageDetails={true}
                  width={400}
                />
              </div>
            </DialogContent>
            <div className="button-holder-similar-margin">
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                variant="contained"
              >
                <div className="icon-title-wrapper">
                  <CheckOutlinedIcon />
                  <span className="icon-title">Save</span>
                </div>
              </Button>
            </div>
          </>
        );
      }}
    />
  );
};

export default StoreDialog;
