import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { IVenueDashboardAverages } from '../routes/Reporting/ReportingConfig';
import { getTimeString } from '../services/Util/Util.service';

const OrderStats = ({
  deliveryTime,
  fulfillmentTime,
  pickupTime,
  orderSize,
}: IVenueDashboardAverages): JSX.Element => (
  <Paper classes={{ root: 'graph-container' }}>
    <Grid container={true} className="order-stats-grid-heading">
      <Typography variant="h6">Order Stats</Typography>
    </Grid>
    <Grid container={true} className="order-stats-grid-container">
      <Grid
        item={true}
        classes={{ item: 'order-stats-grid-item' }}
        className="order-stats-circle-container"
      >
        <div className="order-stats-circle yellow-border">
          {getTimeString(+(deliveryTime || 0).toFixed(2))}
        </div>
        Avg Delivery Time
      </Grid>
      <Grid
        item={true}
        classes={{ item: 'order-stats-grid-item' }}
        className="order-stats-circle-container"
      >
        <div className="order-stats-circle teal-border">
          {getTimeString(+(pickupTime || 0).toFixed(2))}
        </div>
        Avg Pickup Time
      </Grid>
      <Grid
        item={true}
        classes={{ item: 'order-stats-grid-item' }}
        className="order-stats-circle-container"
      >
        <div className="order-stats-circle red-border">
          {getTimeString(+(fulfillmentTime || 0).toFixed(2))}
        </div>
        Avg Fulfillment Time
      </Grid>
      <Grid
        item={true}
        classes={{ item: 'order-stats-grid-item' }}
        className="order-stats-circle-container"
      >
        <div className="order-stats-circle purple-border">
          {(orderSize || 0).toFixed(1)} items
        </div>
        Avg Order Size
      </Grid>
    </Grid>
  </Paper>
);

export default OrderStats;
