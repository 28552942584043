import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ErrorMessage, Formik } from 'formik';
import { cloneDeep, get, isString } from 'lodash';
import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import PartnerTable from '../../components/Partner/PartnerTable';
import { useAppContext } from '../../config/AppContext';
import { IEvent } from '../../models/Event.model';
import { IPartnerEvent } from '../../models/Partner.model';
import { IVenue } from '../../models/Venue.model';
import { isAdmin } from '../../services/Auth.service';
import PartnerService from '../../services/Partner/Partner.service';
import { getTimezoneDate, getUTCDate } from '../../services/Util/Util.service';
import './EventDetails.scss';
import {
  EventValidationSchema,
  OrderAheadCutoffType,
  orderAheadIntervalTimes,
} from './EventsConfig';

interface IEventDetailsProps extends RouteProps {
  event: IEvent;
  onAddOrEditEvent: (eventDetails: IEvent) => Promise<void>;
  buildingVenues: IVenue[];
  close: () => void;
}

const EventDetails = ({
  event,
  close,
  onAddOrEditEvent,
  buildingVenues,
}: IEventDetailsProps) => {
  const { user, venue } = useAppContext();
  const [partnerEvents, setPartnerEvents] = useState<IPartnerEvent[]>([]);

  const fetchPartnerEvents = async () => {
    const { data } = await PartnerService.getPartnerEvents({
      eventId: event.id,
    });

    setPartnerEvents(data);
  };

  useEffect(() => {
    if (event.id) {
      fetchPartnerEvents();
    }
  }, [event]);

  const eventCopy = cloneDeep(event);
  eventCopy.startDate = getTimezoneDate(
    new Date(eventCopy.startDate),
    venue?.timezone,
  );
  eventCopy.endDate = getTimezoneDate(
    new Date(eventCopy.endDate),
    venue?.timezone,
  );
  eventCopy.advanceOrderWindow = get(event, 'advanceOrderWindow', 0) / 60;

  eventCopy.advanceOrderInterval = Math.floor(
    get(event, 'advanceOrderInterval', 0) / 60,
  );
  eventCopy.maxOrdersPerInterval = get(event, 'maxOrdersPerInterval', 0);

  eventCopy.buildingVenueId = get(event, 'buildingVenueId');
  if (venue && venue.type === 'SERVICE_PROVIDER') {
    eventCopy.name = venue.name;
  }
  eventCopy.orderAheadCutoffType = event.orderAheadCutoffType;

  return (
    <>
      <div className="event-container">
        <div className="event-header">
          <BackButton onClick={close} />
          <span id="form-details-title" className="section-title">
            {event.id ? event.name : 'Add Event'}
          </span>
        </div>
        <div className="event-details" aria-labelledby="form-dialog-title">
          <div className="event-form">
            <div className="form-required-field">* Required Field</div>
            <Formik
              initialValues={eventCopy}
              onSubmit={values => {
                const { endDate, startDate } = values;
                onAddOrEditEvent({
                  ...values,
                  endDate: isString(endDate)
                    ? endDate
                    : getUTCDate(
                        new Date(endDate),
                        get(venue, 'timezone', ''),
                      ).toISOString(),
                  startDate: isString(startDate)
                    ? startDate
                    : getUTCDate(
                        new Date(startDate),
                        get(venue, 'timezone', ''),
                      ).toISOString(),
                });
              }}
              validationSchema={EventValidationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                handleBlur,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid spacing={1} container={true} className="container">
                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel className="required">Title</InputLabel>
                      <TextField
                        fullWidth={true}
                        required={true}
                        error={touched.name && !!errors.name}
                        className="event-form__field"
                        name="name"
                        inputProps={{
                          type: 'string',
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        render={() => (
                          <div className="error">Name is a required field</div>
                        )}
                        name="name"
                      />
                    </Grid>

                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel>Description (Optional)</InputLabel>
                      <TextField
                        fullWidth={true}
                        error={touched.description && !!errors.description}
                        className="event-form__field"
                        name="description"
                        inputProps={{
                          type: 'string',
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        autoComplete="off"
                      />
                    </Grid>
                    {venue && venue.type === 'SERVICE_PROVIDER' && (
                      <Grid
                        item={true}
                        xs={10}
                        className="form-field-container"
                      >
                        <InputLabel>
                          Select an Event Location (Optional)
                        </InputLabel>
                        <Select
                          fullWidth={true}
                          name="buildingVenueId"
                          className="event-form__field"
                          value={values.buildingVenueId}
                          onChange={handleChange}
                        >
                          <MenuItem value={undefined}>None</MenuItem>
                          {buildingVenues.map(
                            (tenantBuilding: any, index: number) => (
                              <MenuItem key={index} value={tenantBuilding.id}>
                                {tenantBuilding.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </Grid>
                    )}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid
                        item={true}
                        xs={10}
                        className="form-field-container"
                      >
                        <InputLabel className="required">
                          Event Start Date &amp; Time
                        </InputLabel>
                        <div className="date-time-picker">
                          <KeyboardDateTimePicker
                            fullWidth={true}
                            required={true}
                            name="startDate"
                            ampm={true}
                            mask="__/__/____ __:__ _M"
                            format="MM/dd/yyyy hh:mm a"
                            disablePast={eventCopy.id ? false : true}
                            className="event-form__field"
                            variant="inline"
                            margin="normal"
                            minutesStep={5}
                            value={values.startDate}
                            onChange={value => {
                              setFieldValue('startDate', value);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="startDate"
                        />
                      </Grid>
                      <Grid
                        item={true}
                        xs={10}
                        className="form-field-container"
                      >
                        <InputLabel className="required">
                          Last Order Delivery Date &amp; Time
                        </InputLabel>
                        <div className="date-time-picker">
                          <KeyboardDateTimePicker
                            fullWidth={true}
                            required={true}
                            name="endDate"
                            ampm={true}
                            mask="__/__/____ __:__ _M"
                            format="MM/dd/yyyy hh:mm a"
                            disablePast={eventCopy.id ? false : true}
                            className="event-form__field"
                            variant="inline"
                            margin="normal"
                            minutesStep={5}
                            value={values.endDate}
                            onChange={value => {
                              setFieldValue('endDate', value);
                            }}
                            minDate={values.startDate}
                            minDateMessage="End Date must come after Start Date"
                          />
                        </div>
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="endDate"
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel>Order Ahead Cut-Off Time in Hours</InputLabel>
                      <TextField
                        fullWidth={true}
                        className="event-form__field"
                        name="advanceOrderWindow"
                        onChange={handleChange}
                        type="number"
                        inputProps={{
                          min: '0',
                          step: '1',
                          type: 'number',
                        }}
                        value={values.advanceOrderWindow}
                      />
                      <Typography className="side-text">
                        Stop Order Ahead before event or each time interval
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel>Order Ahead Cut-Off Type</InputLabel>
                      <Select
                        fullWidth={true}
                        name="orderAheadCutoffType"
                        className="event-form__field"
                        value={values.orderAheadCutoffType}
                        onChange={handleChange}
                        classes={{ root: 'advance-order-select-field' }}
                      >
                        <MenuItem value={OrderAheadCutoffType.EVENT}>
                          Entire event
                        </MenuItem>
                        <MenuItem value={OrderAheadCutoffType.TIMESLOT}>
                          Per time interval
                        </MenuItem>
                      </Select>
                      <Typography className="side-text">
                        Configure Order Ahead to cut off before entire event or
                        per time interval
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel>Select Order Ahead Interval</InputLabel>
                      <Select
                        fullWidth={true}
                        name="advanceOrderInterval"
                        className="event-form__field"
                        value={values.advanceOrderInterval}
                        onChange={handleChange}
                        classes={{ root: 'advance-order-select-field' }}
                      >
                        {orderAheadIntervalTimes.map((intervalTime: number) => (
                          <MenuItem
                            key={intervalTime}
                            value={intervalTime}
                          >{`${intervalTime} minutes`}</MenuItem>
                        ))}
                      </Select>
                      <Typography className="side-text">
                        Allow your customer to choose their fulfillment time
                        slot
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel>
                        Maximum Number of Orders per Interval
                      </InputLabel>
                      <TextField
                        fullWidth={true}
                        className="event-form__field"
                        name="maxOrdersPerInterval"
                        onChange={handleChange}
                        type="number"
                        inputProps={{
                          min: '0',
                          step: '1',
                          type: 'number',
                        }}
                        value={values.maxOrdersPerInterval}
                      />
                      <Typography className="side-text">
                        Cap the number of orders you recieve per time slot
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} className="buttons-container">
                      <DialogActions>
                        <Button
                          color="primary"
                          onClick={close}
                          variant="outlined"
                        >
                          <div className="icon-title-wrapper">
                            <span className="icon-title">Cancel</span>
                          </div>
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={
                            Object.keys(errors).length > 0 ||
                            values.startDate >= values.endDate
                          }
                        >
                          <div className="icon-title-wrapper">
                            <span className="icon-title">
                              {event.id ? 'Update' : 'Save'}
                            </span>
                          </div>
                        </Button>
                      </DialogActions>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
        </div>
        {event && event.id && isAdmin(user) && (
          <PartnerTable
            baseEntityId={event.id}
            createPartnerData={PartnerService.createPartnerEvent}
            deletePartnerData={PartnerService.deletePartnerEvent}
            entityName={'Partner Event'}
            keys={{ partnerEventId: 'Partner Event ID', partner: 'Partner' }}
            partnerData={partnerEvents}
            refreshData={fetchPartnerEvents}
          />
        )}
      </div>
    </>
  );
};

export default EventDetails;
