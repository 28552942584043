import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import { useAppContext } from '../../config/AppContext';
import {
  canChangeVenues,
  getVenueIds,
  isSuperAdmin,
} from '../../services/Auth.service';
import VenueService from '../../services/Venue/Venue.service';
import { getDefaultVenueTabId } from '../TabConfig';
import AddVenueDialog from './AddVenueDialog/AddVenueDialog';
import './VenueList.scss';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service'
const VenueList = ({ history }: RouteComponentProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [venues, setVenues] = useState<any>([]);
  const [showAddVenueDialog, setShowAddVenueDialog] = useState(false);
  const [venueType, setVenueType] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { setSelectedStoreId, setShowSpinner, user } = useAppContext();
  const {
    logout,
  } = useAppContext();
  const canCreateNewVenue = user && isSuperAdmin(user);

  // This effect clears the locally stored selectedStoreId (FAN-435)
  // useEffect(() => {
  //   setSelectedStoreId(undefined);
  //   const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
  //   if (tenant_schema) {
  //     if (tenant_schema === 'true') {
  //       LocalStorageService.storeData('tenant-schema', true);
  //       setVenueType('other')
  //     } else if (tenant_schema === 'fasle') {
  //       setVenueType('Fan Food')
  //     }
  //   } else {
  //     LocalStorageService.storeData('tenant-schema', false);
  //   }
  // }, []);

  // Ensure User is authorized for VenueList, otherwise redirect to single venue page
  useEffect(() => {
    if (user && !canChangeVenues(user)) {
      const venueId = getVenueIds(user)[0];
      const defaultTabId = getDefaultVenueTabId(venueId, user);
      history.push(`/home/venue-details/${venueId}/${defaultTabId}`);
    }
  }, [user]);

  // Fetch Authorized Venues on searchTerm/page/rowsPerPage change
  useEffect(() => {
    (async () => {
      setShowSpinner(true);
      try {
        const {
          data: { items, totalItems },
        } = await VenueService.getUserVenues(rowsPerPage, page + 1, searchTerm);
        setTotalCount(totalItems);
        setVenues(items);
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error.response);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
      }
      setShowSpinner(false);
    })();
  }, [page, rowsPerPage, searchTerm, venueType ]);

  const handleAddVenue = async (name: string) => {
    setShowSpinner(true);
    try {
      // TODO: We really shouldn't be creating venues here, we should be waiting to create until the user submits a valid venue.
      // However, this is clearly easier and lets us have only an Edit page (instead of both create/edit pages), so I'll leave this for now.
      const {
        data: { id },
      } = await VenueService.createVenue({ name });
      history.push(`/home/venue-details/${id}/edit`);
      enqueueSnackbar('Venue has been successfully created', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const handleSearchVenue = (event: any) => {
    const venueName = get(event, ['target', 'value']);
    setSearchTerm(venueName);
  };
  // const handleVenueTypeSelect = async (event: any) => {
  //   setVenueType(event.target.value);
  //   if(event.target.value === 'Fan Food'){
  //     logout();
  //     history.push('/login');
  //     LocalStorageService.storeData('tenant-schema', false);
  //   }
  //   else{
  //   logout();
  //   history.push('/login');
  //   LocalStorageService.storeData('tenant-schema', true);
  //   }
  // };
 
  return (
    <div className="venue-list">
      <Typography component="h1" variant="h5" className="section-title">
        Venues
      </Typography>
      <div className="search--bar">
        <label className="search--bar-label">Venue Name: </label>
        <TextField
          id="outlined-bare"
          onChange={handleSearchVenue}
          placeholder="Search by Venue Name, City or State"
          margin="dense"
          variant="outlined"
          InputProps={{
            classes: {
              input: 'search--bar-input',
              notchedOutline: 'search--bar-outline',
            },
          }}
        />
        {/* <InputLabel
          htmlFor="type"
          classes={{ root: 'search--bar-label' }}
          style={{  marginLeft: '40px' }}
        >
          Venue Type :
        </InputLabel>
        <Select
          value={venueType || 'Fan Food'}
          onChange={handleVenueTypeSelect}
          classes={{
            root: 'select-venue-type',
          }}
        >
          <MenuItem key="Fan Food" value="Fan Food">
            Fan Food
          </MenuItem>
          <MenuItem key="other" value="other">
            Others
          </MenuItem>
        </Select> */}
      </div>
      <div className="venue-list__controlbar">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50, 100, 150]}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
        {canCreateNewVenue && (
          <Button
            size="large"
            color="primary"
            classes={{ root: 'add-venue-button' }}
            onClick={() => setShowAddVenueDialog(true)}
          >
            <div className="icon-title-wrapper">
              <AddOutlinedIcon />
              <span className="icon-title">Add Venue</span>
            </div>
          </Button>
        )}
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Venue Name</TableCell>
            <TableCell align="center">City</TableCell>
            <TableCell align="center">State</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venues.map((venue: any) => (
            <TableRow key={venue.id}>
              <TableCell align="center">{venue.name}</TableCell>
              <TableCell align="center">{venue.city}</TableCell>
              <TableCell align="center">{venue.state}</TableCell>
              <TableCell align="center">{venue.type}</TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  classes={{ root: 'manage-button' }}
                  onClick={() =>
                    history.push(
                      `/home/venue-details/${venue.id}/${getDefaultVenueTabId(
                        venue.id,
                        user,
                      )}`,
                    )
                  }
                >
                  Manage
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {showAddVenueDialog && (
        <AddVenueDialog
          onAddVenue={handleAddVenue}
          onClose={() => setShowAddVenueDialog(false)}
        />
      )}
    </div>
  );
};

export default VenueList;
