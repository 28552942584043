import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { useAppContext } from '../../config/AppContext';
import { IStore } from '../../models/Store.model';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';
import VenueRevenueSummaryExplainer from './VenueRevenueSummaryExplainer';

interface IVenueRevenueSummaryProps {
  onChangeStore: (storeId: string) => void;
  onChangeTransactionType: (type: string) => void;
  selectedStoreId: string | undefined;
  data: IVenueRevenueSummaryDataRecord[];
  isAdmin: boolean;
}

export interface IVenueRevenueSummaryDataRecord extends IHasCurrency {
  discounts: number;
  ffShare: number;
  orders: number;
  owedToStore: number;
  processingFees: number;
  productSales: number;
  sales: number;
  serviceCharge: number;
  storeName: string;
  terminal: string;
  storeRevenue: number;
  taxes: number;
  tips: number;
  venueName: string;
}

const helpText = 'This report pulls orders based on the date they were placed.';
const exportName = 'venue-revenue-summary';
const columns: Array<IReportColumn<IVenueRevenueSummaryDataRecord>> = [
  {
    accessor: record => record.venueName,
    adminOnly: true,
    name: 'Venue',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.storeName,
    name: 'Store Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.terminal,
    name: 'Terminal',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.orders,
    name: 'Total Orders Sold',
    total: true,
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.sales,
    name: 'Total Sales',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.productSales,
    name: 'Total Product Sales',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.storeRevenue,
    name: 'Store Revenue Share',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.serviceCharge,
    name: 'Total Service Charge Share',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.tips,
    name: 'Tips',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.discounts,
    name: 'Discounts',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.taxes,
    name: 'Total Taxes',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.processingFees,
    name: 'Total Credit Card Fees',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.ffShare,
    name: 'FF Share',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.owedToStore,
    name: 'Total Owed to Store',
    total: true,
    type: ReportColumnType.currency,
  },
];

const VenueRevenueSummary = (props: IVenueRevenueSummaryProps): JSX.Element => {
  const [headerInfoDialogOpen, setHeaderInfoDialogOpen] = React.useState(false);
  const { stores } = useAppContext();
  const { onChangeStore, selectedStoreId } = props;
  const { onChangeTransactionType } = props;
  const [transactionType, setTransactionType] = React.useState('All');
  const transactionTypes: string[] = ['All', 'CC', 'CASH'];
  const handleStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    onChangeStore(newSelectedStoreId);
  };
  const handleTransactionTypeSelect = (e: any) => {
    setTransactionType(e.target.value);
    onChangeTransactionType(e.target.value);
  };

  return (
    <>
      <div className="records-container">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="search-groupby" style={{ marginRight: '20px' }}>
            <InputLabel htmlFor="type" classes={{ root: 'report-type-label' }}>
              Terminal Store:
            </InputLabel>
            <Select
              value={selectedStoreId || 'All Stores'}
              onChange={handleStoreSelect}
              classes={{
                root: 'select-store',
              }}
            >
              <MenuItem key="" value="All Stores">
                All Stores
              </MenuItem>
              {stores.map((store: IStore) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="search-groupby" style={{ marginLeft: '20px' }}>
            <InputLabel htmlFor="type" classes={{ root: 'report-type-label' }}>
              Transaction Type:
            </InputLabel>
            <Select
              value={transactionType}
              onChange={handleTransactionTypeSelect}
              classes={{
                root: 'select-store',
              }}
            >
              {transactionTypes.map((e: string) => {
                switch (e) {
                  case 'CC':
                    return (
                      <MenuItem value={e} key={e}>
                        All Credit Card Payments
                      </MenuItem>
                    );
                  case 'CASH':
                    return (
                      <MenuItem value={e} key={e}>
                        All Cash Payments
                      </MenuItem>
                    );
                  default:
                    return (
                      <MenuItem value={e} key={e}>
                        All Payments
                      </MenuItem>
                    );
                }
              })}
            </Select>
          </div>
        </div>
        <Typography
          onClick={() => setHeaderInfoDialogOpen(true)}
          className="header-tags-info-link"
        >
          What does each value mean?
        </Typography>
      </div>
      <VenueRevenueSummaryExplainer
        open={headerInfoDialogOpen}
        onClose={() => setHeaderInfoDialogOpen(false)}
      />
      <BasicReport {...{ columns, exportName, helpText }} {...props} />
    </>
  );
};

export default VenueRevenueSummary;
