import {
  ICreateNotification,
  INotification,
} from '../../routes/Marketing/Notifications/Notificationconfig';
import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/notifications`;

const getAllNotifications = (
  venueId: string,
  limit: number,
  name: string,
  page: number,
  title: string,
  content: string,
) =>
  axiosWithAuth.get(
    `${baseUrl}?venueId=${venueId}&limit=${limit}&name=${name}&page=${page}&title=${title}&content=${content}`,
  );

const createNotification = (body: ICreateNotification) =>
  axiosWithAuth.post(`${baseUrl}`, body);

const deleteNotification = (notificationId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${notificationId}`);

const updateNotification = (
  notificationId: string,
  updateBody: INotification,
) => axiosWithAuth.put(`${baseUrl}/${notificationId}`, updateBody); // check if body has id here for notifications

const sendNotificationNow = (notificationId: string) =>
  axiosWithAuth.post(`${baseUrl}/${notificationId}/send`);

export default {
  baseUrl,
  createNotification,
  deleteNotification,
  getAllNotifications,
  sendNotificationNow,
  updateNotification,
};
