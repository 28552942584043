import * as Yup from 'yup';

export interface IProfile {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export const ProfileValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
});
