import React from 'react';
import { Button } from '@material-ui/core';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export interface IWorksheet {
  data: any[];
  name: string;
}

export interface IExportToExcelProps {
  buttonText: string;
  filename: string;
  getWorksheets: () => Promise<IWorksheet[]>;
}

export const UserListTemplate = ({ buttonText, filename, getWorksheets }: IExportToExcelProps) => {
  const exportToExcel = async (): Promise<void> => {
    const worksheets = await getWorksheets();

    const wb = XLSX.utils.book_new();
    worksheets.forEach((worksheet: any) => {
      const ws = XLSX.utils.json_to_sheet(worksheet.data);
      XLSX.utils.book_append_sheet(wb, ws, worksheet.name);
    });

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const finalData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(finalData, `${filename}.xlsx`);
  };

  return (
    <div className="export-to-excel">
      <Button
        startIcon={<CloudDownloadIcon />}
        color="primary"
        size="medium"
        variant="contained"
        onClick={exportToExcel}
        style={{ fontWeight: 'bold' }} // Inline style for demonstration; consider using makeStyles for more complex customizations
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default UserListTemplate;
