import {
  Button,
  Chip,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import { format } from 'date-fns';
import { get, isEmpty, partition, some } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useState } from 'react';
import { useAppContext } from '../../../config/AppContext';
import ILineItem, {
  ILineItemSubSelection,
} from '../../../models/LineItem.model';
import ILineItemGroup from '../../../models/LineItemGroup.model';
import { IOrder } from '../../../models/Order.model';
import { IStore, ValidStoreType } from '../../../models/Store.model';
import OpenTabsService from '../../../services/OpenTabs/OpenTabs.service';
import OrdersService from '../../../services/Orders/Orders.service';
import { getTimezoneDate } from '../../../services/Util/Util.service';
import {
  IOrderAction,
  IOrderSchema,
  IOrderState,
  IUpdateLineItemGroup,
  REFUNDSTATUS,
  VALIDSTATUS,
} from '../Orders.config';
import LocalStorageService from '../../../services/LocalStorage/LocalStorage.service';

interface IOrderRowProps extends WithSnackbarProps {
  expandByDefault: boolean;
  onUpdateLineItemGroupStatus: (
    lineItemGroupId: string,
    body: IUpdateLineItemGroup,
  ) => Promise<void>;
  order: IOrder;
  orderStates: IOrderState[];
  schema: IOrderSchema[];
  setCurrentOrderForRunner: React.Dispatch<any>;
  setSelectedOrder: React.Dispatch<any>;
  setShowOrderDetailsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDialogCover: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRunnerDialog: React.Dispatch<React.SetStateAction<boolean>>;
  store: IStore;
  showActionButton: boolean;
  tabIdCall?: (tabId: string, hide?: boolean) => void;
  tabValue?: number;
  terminalName?: string;
  setCoverOrder: React.Dispatch<React.SetStateAction<any>>;
  setCoverValue: React.Dispatch<React.SetStateAction<any>>;
}

const OrderRow = (props: IOrderRowProps) => {
  const {
    enqueueSnackbar,
    expandByDefault,
    onUpdateLineItemGroupStatus,
    order,
    orderStates,
    schema,
    setCurrentOrderForRunner,
    setShowRunnerDialog,
    store,
    showActionButton,
    tabIdCall,
    tabValue,
    terminalName,
    setSelectedOrder,
    setShowOrderDetailsDialog,
    setShowDialogCover,
    setCoverOrder,
    setCoverValue,
  } = props;
  
 
  const { stores, venue } = useAppContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSubOrders, setShowSubOrders] = useState<boolean>(false);
  // used to determine whether an order is able to be grabbed, and whether to alert the employee that it's in the future
  const venueTimezone = get(venue, 'timezone', '');
  const venueNow = getTimezoneDate(new Date(), venueTimezone);
  // this takes the venue's current date, truncates all time and adds a calendar day
  const venueTomorrow = new Date(
    venueNow.setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000,
  );

  // Finds all Line Item Groups with associated Line Items.
  const lineItemGroups: ILineItemGroup[] = order.lineItemGroups
    .map((lineItemGroup: ILineItemGroup) => {
      const lineItems = order.lineItems.filter(
        (lineItem: ILineItem) => lineItem.lineItemGroupId === lineItemGroup.id,
      );
      Object.assign(lineItemGroup, { lineItems });
      return lineItemGroup;
    })
    .filter(
      (lineItemGroup: ILineItemGroup) => lineItemGroup.lineItems.length > 0,
    )
    // Filters Line Item Groups if store type is `FULFILLMENT_CENTER`.
    .filter(
      (lineItemGroup: ILineItemGroup) =>
        store.type !== ValidStoreType.FULFILLMENT_CENTER ||
        (!!lineItemGroup.fulfillmentCenterId &&
          lineItemGroup.fulfillmentCenterId === store.id),
    )
    // Sorts Line Item Groups so that first is always the current Store's.
    .sort((a: ILineItemGroup, b: ILineItemGroup) => {
      const aIsForThisStore =
        a.fulfillmentCenterId && a.fulfillmentCenterId === store.id;
      const bIsForThisStore =
        b.fulfillmentCenterId && b.fulfillmentCenterId === store.id;

      if (aIsForThisStore && !bIsForThisStore) {
        return -1;
      } else if (bIsForThisStore && !aIsForThisStore) {
        return 1;
      }

      return 0;
    });

  if (lineItemGroups.length <= 0) {
    return <></>;
  }

  // Finds current store's Line item Group.
  const storeControlledLig = lineItemGroups.find(
    (lig: ILineItemGroup) => lig.fulfillmentCenterId === store.id,
  );

  const getStatusButtons = (lineItemGroup?: ILineItemGroup): JSX.Element => {
    const {
      prepDueTime,
      refundStatus,
      status: orderStatus,
      type,
      tabId,
    } = order;
    if (refundStatus === REFUNDSTATUS.FULL_REFUNDED || !lineItemGroup) {
      return <></>;
    }

    const { id, runnerId, status: ligStatus } = lineItemGroup;

    const { actions } = orderStates.find(
      (state: IOrderState) =>
        (state.type ? state.type === type : true) && state.status === ligStatus,
    )!;

    if (orderStatus === VALIDSTATUS.PRE_ORDER && prepDueTime) {
      const prepDueTimeInVenueTimezone = getTimezoneDate(
        new Date(prepDueTime),
        venueTimezone,
      );
      if (prepDueTimeInVenueTimezone > venueTomorrow) {
        return <>Future order</>;
      }
    }

    if (order.tabOrderType === 'SUB_ORDER' && tabValue === 3) {
      return <></>;
    }
    return (
      <>
        {actions &&
          actions.map((action: IOrderAction, actionIndex: number) => (
            <Button
              className={classnames(
                'order__action-buttons',
                ligStatus,
                `${ligStatus}-${action.nextStatus}`,
                isLoading && 'disabled',
              )}
              disabled={isLoading}
              key={actionIndex}
              onClick={
                action.nextStatus === VALIDSTATUS.OUT_FOR_DELIVERY
                  ? event => {
                      event.stopPropagation();
                      setCurrentOrderForRunner(order);
                      setShowRunnerDialog(true);
                    }
                  : // : action.nextStatus === VALIDSTATUS.SUB_ORDER
                  // ? event => {
                  //     alert('Hello World');
                  //   }
                  action.nextStatus === VALIDSTATUS.OPEN_TABS
                  ? async event => {
                      event.stopPropagation();
                      await OpenTabsService.OpenTabReceipt(
                        { isActive: false, order },
                        tabId,
                      );
                      setIsLoading(true);
                    }
                  : async event => {
                      event.stopPropagation();
                      setIsLoading(true);
                      await onUpdateLineItemGroupStatus(id, {
                        runnerId,
                        status: action.nextStatus as any,
                      });
                      setIsLoading(false);
                    }
              }
              onFocus={event => event.stopPropagation()}
              size="small"
            >
              {action.displayName}
            </Button>
          ))}
      </>
    );
  };

  const getOrderDetails = () => {
    const orderDetails = (
      <>
        {lineItemGroups.map(
          (lineItemGroup: ILineItemGroup, ligIndex: number) => {
            const showFulfillmentCenterInfo =
              !!lineItemGroup.fulfillmentCenter &&
              lineItemGroup.fulfillmentCenterId !== store.id;
            const showLineItemGroupStatus =
              store.type !== ValidStoreType.FULFILLMENT_CENTER;
            const validLineItems = lineItemGroup.lineItems.filter(
              (lineItem: ILineItem) => {
                if (lineItem.quantity > 0) {
                  return true;
                }
                return lineItem?.subSelections?.some((subSelect: any) => {
                  return subSelect?.subSelections?.some((subProduct: any) => {
                    let refundedProductCount = 0;
                    lineItem?.refundSubProducts?.forEach((e: any) => {
                      if (e?.productId === subProduct.productId) {
                        refundedProductCount += e.quantity;
                      }
                    });
                    if (
                      (lineItem.quantity + lineItem.quantityRefunded) *
                        subProduct.quantity >
                      refundedProductCount
                    ) {
                      return true;
                    }
                    return false;
                  });
                });
              },
            );
            const status = orderStates.find(
              (os: IOrderState) => os.status === lineItemGroup.status,
            ) || { value: '' };
            return (
              <div
                className={classnames(
                  'line-item-group',
                  showFulfillmentCenterInfo && 'fulfillment-center',
                )}
                key={ligIndex}
              >
                {showFulfillmentCenterInfo && (
                  <p>Fulfilled by: {lineItemGroup.fulfillmentCenter!.name}</p>
                )}
                {showLineItemGroupStatus && (
                  <>
                    <div>
                      Status:{' '}
                      <Chip
                        label={status.value}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <hr />
                  </>
                )}
                {validLineItems.length > 0 ? (
                  validLineItems.map((lineItem: ILineItem, liIndex: number) => (
                    <Grid key={liIndex} item={true}>
                      <div className="orders-table-row__details__lineItems line-item-details-section">
                        <div className="line-item-details__item-name">
                          <span>
                            {lineItem.product.name}
                            {lineItem.quantity > 0 ? (
                              ` (x${lineItem.quantity})`
                            ) : (
                              <span
                                style={{ color: '#ee3653', fontSize: '12px' }}
                              >
                                Refunded (x{lineItem.quantityRefunded})
                              </span>
                            )}
                          </span>
                          {lineItem.subSelections && (
                            <div className="line-item-details__item-selections">
                              {lineItem.subSelections
                                .filter(
                                  (category: ILineItemSubSelection) =>
                                    category.subSelections &&
                                    category.subSelections.length > 0,
                                )
                                .map((category: ILineItemSubSelection) =>
                                  category.subSelections!.map(
                                    (item: ILineItemSubSelection, iIndex) => {
                                      let refundedProductCount = 0;
                                      lineItem?.refundSubProducts?.forEach(
                                        e => {
                                          if (e?.productId === item.productId) {
                                            refundedProductCount += e.quantity;
                                          }
                                        },
                                      );

                                      const calculatedValue = Math.abs(
                                        refundedProductCount -
                                          item.quantity *
                                            (lineItem.quantity +
                                              lineItem.quantityRefunded),
                                      );

                                      if (calculatedValue > 0) {
                                        return (
                                          <div key={iIndex}>
                                            <p className="line-item-details__combo-details">
                                              {`${item.name} (x ${Math.abs(
                                                refundedProductCount -
                                                  item.quantity *
                                                    (lineItem.quantity +
                                                      lineItem.quantityRefunded),
                                              )})`}
                                            </p>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    },
                                  ),
                                )}
                            </div>
                          )}

                          {lineItem.instructions && (
                            <div className="line-item-details__instructions">
                              - {lineItem.instructions}
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <span>N/A</span>
                )}
              </div>
            );
          },
        )}
        {!isEmpty(get(order, 'instructions', '')) && (
          <div className="order-notes">
            Note: {get(order, 'instructions', '')}
          </div>
        )}
        {!isEmpty(get(order, 'note', '')) && (
          <div className="order-notes">Note: {get(order, 'note', '')}</div>
        )}
      </>
    );
    return orderDetails;
  };

  const printReceipt = async (
    orderId: string,
    storeId: string | undefined,
    lineItemGroupId: string | undefined,
  ) => {
    try {
      setIsLoading(true);
      await OrdersService.printReceipt(orderId, storeId, lineItemGroupId);
      enqueueSnackbar('Print has been added to the queue', {
        variant: 'success',
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showCoverDialog = () => {
    setShowDialogCover(true);
    setCoverOrder(order);
    if (!order.cover) {
      setCoverValue('0');
    }
    setCoverValue(order.cover);
  };

  const getActionButtons = (): JSX.Element[] => {
    const { refundStatus, status, cover, type } = order;
    const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
    const actionButtons = [];
    if (tenant_schema === 'true' && status === 'NEW') {
      {
        actionButtons.push(
          <Button
            className="order__action-buttons"
            disabled={isLoading}
            key="cover"
            onClick={showCoverDialog}
            size="small"
          >
            Add Cover
          </Button>,
        );
      }
    }

    // Conditionally displays Refund button.
    if (
      refundStatus !== REFUNDSTATUS.FULL_REFUNDED &&
      store.type !== ValidStoreType.FULFILLMENT_CENTER
    ) {
      actionButtons.push(
        <Button
          className="order__action-buttons"
          disabled={isLoading}
          key="refund"
          onClick={() => setSelectedOrder(order)}
          size="small"
        >
          Refund
        </Button>,
      );
    }

    // Displays Order Details button.
    actionButtons.push(
      <Button
        className="order__action-buttons"
        disabled={isLoading}
        key="order-details"
        onClick={() => {
          setSelectedOrder(order);
          setShowOrderDetailsDialog(true);
        }}
        size="small"
      >
        Order Details
      </Button>,
    );

    // Conditionally displays Print Receipt button.
    const isFulfillmentCenter =
      store.type === ValidStoreType.FULFILLMENT_CENTER;
    const printToStore = stores.find(
      (s: IStore) => s.id === (isFulfillmentCenter ? store.id : order.storeId),
    );

    if (
      printToStore &&
      some(printToStore.printerIds) &&
      // status !== VALIDSTATUS.NEW &&
      // status !== VALIDSTATUS.PRE_ORDER &&
      // status !== VALIDSTATUS.DONE &&
      refundStatus !== REFUNDSTATUS.FULL_REFUNDED
    ) {
      actionButtons.push(
        <Button
          className="order__action-buttons"
          disabled={isLoading}
          key="reprint"
          onClick={() =>
            printReceipt(
              order.id,
              printToStore.id,
              isFulfillmentCenter && !!storeControlledLig
                ? storeControlledLig.id
                : undefined,
            )
          }
          size="small"
        >
          Re-Print
        </Button>,
      );
    }

    return actionButtons;
  };

  const constructDate = (
    date: Date | undefined,
    columnName: string,
    orderStatus: string,
  ) => {
    if (!date) {
      return '';
    }

    const displayFormat =
      columnName === 'Placed' || columnName === 'Due'
        ? 'MM/dd/yyyy - hh:mm a'
        : 'hh:mm a';

    const venueEventDate = getTimezoneDate(new Date(date), venueTimezone);

    return (
      <>
        {format(venueEventDate, displayFormat)}
        {orderStatus === VALIDSTATUS.PRE_ORDER &&
          columnName === 'Due' &&
          venueEventDate > venueTomorrow && (
            <>
              <br />
              <b>* Future order</b>
            </>
          )}
      </>
    );
  };
  const constructCover = (cover: string | undefined, columnName: string) => {
    if (!cover) {
      return '';
    }

    const displayFormat = cover;

    return <>{displayFormat}</>;
  };

  const constructLocation = (location: any = []) => {
    if (!location) {
      location = [];
    }

    let locationCopy = '';
    location.forEach((each: { name: string; value: string }, i: number) => {
      return i === 0
        ? (locationCopy += `${each.name} ${each.value}`)
        : (locationCopy += `, ${each.name} ${each.value}`);
    });
    return locationCopy;
  };

  const getFormattedData = (column: IOrderSchema) => {
    const entity =
      column.entity === 'lineItemGroup' && !!storeControlledLig
        ? storeControlledLig
        : order;
    switch (column.type) {
      case 'date':
        if (!isEmpty(get(entity, column.key, ''))) {
          const runnerName =
            column.key === 'orderOutForDeliveryTime'
              ? ` ${get(entity, 'runner.firstName', '')} ${get(
                  entity,
                  'runner.lastName',
                  '',
                ).charAt(0)}`
              : '';
          return (
            <>
              <Grid className="orders-table-row__details__timeline" item={true}>
                <Typography
                  className="orders-table-row__details__uppercase"
                  variant="body1"
                >
                  <b>
                    {column.name}
                    {runnerName ? ` (${runnerName})` : ''}
                  </b>
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography
                  className="orders-table-row__details__timeline__info"
                  variant="body1"
                >
                  {constructDate(
                    get(entity, column.key, ''),
                    column.name,
                    entity.status,
                  )}
                </Typography>
              </Grid>
            </>
          );
        } else {
          return <></>;
        }
      case 'check':
        const cover = get(entity, column.key, '');

        if (!cover) {
          return;
        }
        return (
          <div className="test_class" style={{ display: 'flex' }}>
            <Grid item={true}>
              <Typography
                className="orders-table-row__details__header"
                variant="h6"
              >
                <b>{column.name.toUpperCase()}</b>
              </Typography>
            </Grid>
            <Grid
              item={true}
              style={{
                marginLeft: '100px',
              }}
            >
              <Typography
                className="orders-table-row__details__header"
                variant="h6"
                style={{ fontWeight: 'bold' }}
              >
                {constructCover(get(entity, column.key, ''), column.name)}
              </Typography>
            </Grid>
          </div>
        );
      case 'location':
        return order.type === 'PICKUP' ? (
          ''
        ) : (
          <>
            <Grid item={true}>
              <Typography
                className="orders-table-row__details__header"
                variant="h6"
              >
                <b>{column.name.toUpperCase()}</b>
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                className="orders-table-row__details__inner-column__info"
                variant="body1"
              >
                {constructLocation(get(entity, column.key, ''))}
              </Typography>
            </Grid>
          </>
        );
      case 'string':
        const value = get(entity, column.key, '');
        if (!value) {
          return;
        }

        return (
          <>
            <Grid item={true}>
              <Typography
                className="orders-table-row__details__header"
                variant="h6"
              >
                <b>{column.name.toUpperCase()}</b>
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                className="orders-table-row__details__inner-column__info"
                variant="body1"
              >
                {value}
              </Typography>
            </Grid>
          </>
        );
    }
  };

  const constructLocationAndTimeline = () => {
    const [timelineColumns, additionalColumns] = partition(schema, [
      'type',
      'date',
    ]);

    return (
      <Grid container={true} direction="column">
        <Grid container={true} alignItems="center">
          {additionalColumns.map((column: any, i: number) => (
            <Grid
              alignItems="flex-start"
              container={true}
              direction="column"
              justify="center"
              key={i}
            >
              {getFormattedData(column)}
            </Grid>
          ))}
        </Grid>
        {order?.tabId && (
          <Grid item={true}>
            <Typography
              className="orders-table-row__details__header"
              variant="h6"
            >
              <b>TAB ORDER</b>
            </Typography>
            <Typography
              className="orders-table-row__details__inner-column__info"
              variant="body1"
            >
              {`${get(order, 'tab.name', '')}`}
            </Typography>
          </Grid>
        )}
        <Typography className="orders-table-row__details__header" variant="h6">
          <b>TIMELINE</b>
        </Typography>
        <Grid
          className="orders-table-row__details__inner-column__container"
          container={true}
        >
          {timelineColumns.map((column: any, j: number) => {
            return (
              <Grid key={j} container={true} justify="space-between">
                {getFormattedData(column)}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  // Displays status of Line Item Group if Store is FC; otherwise displays Order Status.
  const getStatus = (orderStatus: VALIDSTATUS | REFUNDSTATUS) => {
    const statusColorClasses = `${orderStatus}-label-color`;
    const isFulfillmentCenter =
      store.type === ValidStoreType.FULFILLMENT_CENTER;

    return (
      <div className={`order-status ${statusColorClasses}`}>
        {
          (
            orderStates.find(
              (s: IOrderState) =>
                s.status ===
                (isFulfillmentCenter && !!storeControlledLig
                  ? storeControlledLig.status
                  : orderStatus),
            ) || {
              status: orderStatus,
              value: '',
            }
          ).value
        }
      </div>
    );
  };

  const getDate = (date: any) => {
    const venueDate = getTimezoneDate(new Date(date), venueTimezone);
    const displayFormat = 'MM/dd/yyyy - hh:mm a';
    const formatedDate = format(venueDate, displayFormat);
    return formatedDate;
  };

  const getTabId = (id?: string, hide?: boolean) => {
    if (tabIdCall) {
      tabIdCall(id as string, hide);
    }
    hide ? setShowSubOrders(false) : setShowSubOrders(true);
  };
  return (
    <ExpansionPanel
      classes={{ root: 'orders-table-row' }}
      defaultExpanded={expandByDefault}
      square={true}
    >
      <ExpansionPanelSummary
        classes={{
          content: 'orders-table-row__header',
          expandIcon: 'orders-table-row__icon',
          expanded: 'expanded',
        }}
        expandIcon={
          <ChevronRightIcon className="orders-table-row__icon__svg" />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid alignItems="baseline" container={true}>
          {showActionButton &&
            order.refundStatus !== REFUNDSTATUS.FULL_REFUNDED && (
              <Grid item={true} xs={4}>
                {getStatusButtons(storeControlledLig)}
              </Grid>
            )}
          <Grid item={true} xs={2}>
            Order #{get(order, 'orderNo', '')}
          </Grid>
          <Grid item={true} xs={2}>
            {`${get(order, 'user.firstName', '')} ${get(
              order,
              'user.lastName',
              '',
            ).charAt(0)}`}
          </Grid>
          <Grid className="orders-table-row__status" item={true} xs={3}>
            <Grid alignItems="baseline" container={true}>
              Order status: <b>{getStatus(order.status)}</b>
            </Grid>
          </Grid>
          {terminalName && (
            <Grid className="orders-table-row__status" item={true} xs={2}>
              <Grid alignItems="baseline" container={true}>
                Order Source:{' '}
                <b>
                  {terminalName?.charAt(0).toUpperCase()}
                  {terminalName?.slice(1)}
                </b>
              </Grid>
            </Grid>
          )}
          {terminalName && (
            <Grid className="orders-table-row__status" item={true} xs={3}>
              <Grid alignItems="baseline" container={true}>
                Order Time: <b>{getDate(order.createdTime)}</b>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
      <Divider orientation="horizontal" />
      <ExpansionPanelDetails className="orders-table-row__details">
        <Grid
          className="orders-table-row__details__outer-column"
          item={true}
          xs={3}
        >
          <Typography
            className="orders-table-row__details__header"
            variant="h6"
          >
            <b>ORDER</b>
          </Typography>
          {getOrderDetails()}
        </Grid>
        <Grid
          className="orders-table-row__details__inner-column"
          item={true}
          xs={4}
        >
          {constructLocationAndTimeline()}
        </Grid>
        <Grid
          className="orders-table-row__details__outer-column"
          item={true}
          xs={5}
        >
          <Grid container={true} direction="column" justify="flex-end">
            {getActionButtons()}
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
      {get(order, 'tabOrderType', '') === 'MAIN_ORDER' &&
        order.status === 'DONE' && (
          <Grid container={true} direction="row">
            <Typography className="sub_order_row">Sub Orders: </Typography>
            {showSubOrders ? (
              <ChevronRightIcon
                cursor="pointer"
                className="orders-table-row__icon Mui-expanded expanded sub_order_icon"
                onClick={() => getTabId(get(order, 'tabId', ''), true)}
              />
            ) : (
              <ChevronRightIcon
                cursor="pointer"
                className="orders-table-row__icon__svg"
                onClick={() => getTabId(get(order, 'tabId', ''))}
              />
            )}
          </Grid>
        )}
    </ExpansionPanel>
  );
};

export default withSnackbar(OrderRow);
