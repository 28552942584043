import { Icon } from '@material-ui/core';
import React from 'react';
import './index.scss';

interface IBackButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

const BackButton = (props: IBackButtonProps) => {
  const { onClick } = props;

  return (
    <span className="back-button" {...{ onClick }}>
      <Icon>arrow_back</Icon> Back
    </span>
  );
};

export default BackButton;
