import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import React from 'react';
import * as XLSX from 'xlsx';

export const ExportCSV = (props: any) => {
  const { csvData, fileName, topHeaders, isAdminReport } = props;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (
    data: any,
    fName: any,
    header: any,
    isAdminReporting: any,
  ) => {
    let ws: any;
    if (header && isAdminReporting) {
      ws = XLSX.utils.aoa_to_sheet(header);
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
        { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },
      ];
      ws['!merges'] = merge;
      XLSX.utils.sheet_add_json(ws, data, { origin: 'A2' });
    } else if (header && !isAdminReporting) {
      ws = XLSX.utils.aoa_to_sheet(header);
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
        { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
      ];
      ws['!merges'] = merge;
      XLSX.utils.sheet_add_json(ws, data, { origin: 'A2' });
    } else {
      ws = XLSX.utils.json_to_sheet(data);
    }
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const finalData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(finalData, fName + fileExtension);
  };

  return (
    <Button
      color="primary"
      size="small"
      variant="contained"
      className="export-btn"
      onClick={(e: any) =>
        exportToCSV(csvData, fileName, topHeaders, isAdminReport)
      }
    >
      Export to Excel
    </Button>
  );
};
