import axios from 'axios';
import LocalStorageService from './LocalStorage/LocalStorage.service';

declare const ENVARS: any;

export interface IPaginatedItems<TData> {
  items: TData[];
  totalItems: number;
}
export interface IApiResponse<TData> {
  data: TData;
}

const axiosWithAuth = axios.create({
  baseURL: `${ENVARS.API.BASE_URL}/api/v1`,
  headers: {
    'X-Platform': 'MP',
  },
});

export const axiosCreateOrder = axios.create({
  baseURL: `${ENVARS.API.BASE_URL}/api/v2`,
  headers: {
    'X-Platform': 'MP',
  },
});

export const axiosWithCart = axios.create({
  baseURL: `${ENVARS.API.BASE_URL}/api/v2`,
  headers: {
    'X-Platform': 'MP',
  },
});

export const axiosWithAccessAndroidPOS = axios.create({
  baseURL: `${ENVARS.API.BASE_URL}/api/v2/terminals`,
  headers: {
    'X-Platform': 'MP',
  },
});

axiosWithAuth.interceptors.request.use(async config => {
  const token = LocalStorageService.getLocalData('authToken');
  const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (tenant_schema === 'true') {
    config.headers['td-api-key'] = 'tdxpixey-123X$';
  } else if (tenant_schema === 'fasle' || !tenant_schema) {
    delete config.headers['td-api-key'];
  }
  return config;
});

axiosCreateOrder.interceptors.request.use(async config => {
  const token = LocalStorageService.getLocalData('authToken');
  const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (tenant_schema === 'true') {
    config.headers['td-api-key'] = 'tdxpixey-123X$';
  } else if (tenant_schema === 'fasle' || !tenant_schema) {
    delete config.headers['td-api-key'];
  }
  return config;
});

axiosWithCart.interceptors.request.use(async config => {
  const token = LocalStorageService.getLocalData('authToken');
  const tenant_schema = LocalStorageService.getLocalData('tenant-schema');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (tenant_schema === 'true') {
    config.headers['td-api-key'] = 'tdxpixey-123X$';
  } else if (tenant_schema === 'fasle' || !tenant_schema) {
    delete config.headers['td-api-key'];
  }
  return config;
});
axiosWithAccessAndroidPOS.interceptors.request.use(async config => {
  const token = LocalStorageService.getLocalData('authToken');
  const tenant_schema = LocalStorageService.getLocalData('tenant-schema');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (tenant_schema === 'true') {
    config.headers['td-api-key'] = 'tdxpixey-123X$';
  } else if (tenant_schema === 'fasle' || !tenant_schema) {
    delete config.headers['td-api-key'];
  }
  return config;
});

export default axiosWithAuth;
