import { Button, Icon, TextField } from '@material-ui/core';
import { get } from 'lodash';
import React, { ChangeEvent, Component } from 'react';
import AvatarEditor from 'react-avatar-editor';
import './AddImage.scss';

interface IProps {
  getImageURL?: any;
  getName?: any;
  getFile?: any;
  width: number;
  height: number;
  previewBottom?: boolean;
  showName?: boolean;
  showImageDetails?: boolean;
  setShowImageDetails?: any;
  imageName?: string;
  minHeight?: number;
  minWidth?: number;
}
class AddImage extends Component<IProps, any> {
  public static getDerivedStateFromProps(props: any, state: any) {
    if (
      props.height !== state.initialHeight ||
      props.width !== state.initialWidth
    ) {
      return {
        editorHeight: props.height,
        editorWidth: props.width,
        initialHeight: props.height,
        initialWidth: props.width,
      };
    }
    return null;
  }
  private editor: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      brandName: this.props.imageName || '',
      editedFile: '',
      editedImageURL: '',
      editorHeight: 200,
      editorWidth: 200,
      extension: '',
      fileName: '',
      globalProductName: '',
      height: '',
      initialHeight: '',
      initialWidth: '',
      lowRes: false,
      originalImage: '',
      rotate: 0,
      type: '',
      viewPreview: false,
      width: '',
      zoom: 1,
    };
  }

  public setDimensions = (height: number, width: number, src: any) => {
    const {
      width: propWidth,
      height: propHeight,
      minHeight,
      minWidth,
    } = this.props;
    let validationHeight;
    let validationWidth;
    if (minHeight && minWidth) {
      validationHeight = minHeight;
      validationWidth = minWidth;
    } else {
      validationHeight = propHeight;
      validationWidth = propWidth;
    }
    if (height >= validationHeight && width >= validationWidth) {
      this.setState({
        height,
        lowRes: false,
        originalImage: src,
        rotate: 0,
        viewPreview: false,
        width,
      });
    } else {
      this.setState({ lowRes: true, originalImage: src });
    }
  };

  public setEditorRef = (editor: any) => (this.editor = editor);

  public onChangeName = (e: ChangeEvent) => {
    const { getName } = this.props;
    const brandName = get(e, ['target', 'value'], '');
    this.setState({ ...this.state, brandName }, () => {
      getName(this.state.brandName);
    });
  };

  public setRotate = (degrees: number) => {
    const {
      rotate,
      editorHeight,
      editorWidth,
      initialHeight,
      initialWidth,
    } = this.state;
    const newDegrees =
      rotate + degrees === 360 || rotate + degrees === -360
        ? 0
        : rotate + degrees;
    if (
      rotate + degrees === 90 ||
      rotate + degrees === 270 ||
      rotate + degrees === -90 ||
      rotate + degrees === -270
    ) {
      this.setState({
        ...this.state,
        editorHeight: editorWidth,
        editorWidth: editorHeight,
        rotate: newDegrees,
      });
      return;
    }
    this.setState({
      ...this.state,
      editorHeight: initialHeight,
      editorWidth: initialWidth,
      rotate: newDegrees,
    });
  };

  public setZoom = (e: any) => {
    const zoom = get(e, ['target', 'value'], 1);
    this.setState({ ...this.state, zoom: Number(zoom) });
  };

  public togglePreview = () => {
    this.setState({ ...this.state, viewPreview: true });
  };

  public onUploadImage = (
    e: any,
    callback: (a: number, b: number, c: any) => void,
  ) => {
    const file = get(e, ['target', 'files', '0'], '');
    const { setShowImageDetails } = this.props;
    if (typeof file === 'string') {
      return;
    }
    this.setState({
      extension: file.type.substr(file.type.indexOf('/') + 1),
      fileName: file.name,
      type: file.type,
    });
    const image = new Image();
    const src = window.URL.createObjectURL(file);
    image.src = src;
    image.onload = () => {
      callback(image.height, image.width, src);
    };
    if (setShowImageDetails) {
      setShowImageDetails(true);
    }
  };

  public loadImage = (preview: boolean) => {
    const { brandName, type, extension, fileName, lowRes } = this.state;
    const { getFile, getImageURL } = this.props;
    if (this.editor) {
      const canvas = this.editor.getImage().toDataURL(type, 0.7);
      const name = brandName || fileName;
      fetch(canvas)
        .then(res => res.arrayBuffer())
        .then(res =>
          this.setState(
            {
              ...this.state,
              editedFile: new File([res], `${name}.${extension}`, {
                type,
              }),
              editedImageURL: canvas,
              viewPreview: preview,
            },
            () => {
              if (typeof getFile !== 'function') {
                return;
              }
              const file = !lowRes
                ? this.state.editedFile
                : 'low resolution image';
              getFile(file);

              if (typeof getImageURL !== 'function') {
                return;
              }
              getImageURL(canvas);
            },
          ),
        );
    }
  };

  public onClickPreview = () => {
    this.loadImage(true);
  };

  public render() {
    const {
      originalImage,
      fileName,
      rotate,
      zoom,
      editedImageURL,
      viewPreview,
      editorHeight,
      editorWidth,
      lowRes,
      brandName,
    } = this.state;
    const {
      width: propWidth,
      height: propHeight,
      showImageDetails,
      minHeight,
      minWidth,
    } = this.props;
    let errorMessage;
    if (lowRes) {
      errorMessage = `Please choose an image with a minimum resolution of ${
        minWidth ? minWidth : propWidth
      }px x ${minHeight ? minHeight : propHeight}px`;
    }

    const { width, height, previewBottom, showName } = this.props;

    return (
      <div className="image-upload-preview">
        {showName && (
          <TextField
            required={true}
            label="Name"
            fullWidth={true}
            onChange={this.onChangeName}
            value={brandName}
          />
        )}
        <div className="image-editor-holder">
          <div className="image-upload-button-container">
            <label
              htmlFor="image-upload"
              className="image-upload-button-container__label"
            >
              Choose image
            </label>
            {showImageDetails && fileName}
            <input
              id="image-upload"
              accept="image/*"
              type="file"
              onChange={e => this.onUploadImage(e, this.setDimensions)}
              className="image-upload-button-container__input"
            />
          </div>
          <div
            className={
              !previewBottom
                ? 'image-upload-preview__container'
                : 'image-upload-preview__container--wrap'
            }
          >
            {originalImage && showImageDetails ? (
              <div className={lowRes ? 'noShow' : 'avatar-holder'}>
                <AvatarEditor
                  className="avatar-editor"
                  ref={this.setEditorRef}
                  image={originalImage}
                  width={editorWidth}
                  height={editorHeight}
                  border={20}
                  color={[128, 128, 128, 0.6]}
                  scale={zoom}
                  rotate={rotate}
                  onImageReady={() => {
                    this.loadImage(false);
                  }}
                />
                <div className="controls">
                  <Button
                    size="small"
                    className="controls__button"
                    onClick={() => this.setRotate(-90)}
                  >
                    <Icon>rotate_left</Icon>
                  </Button>
                  <Button
                    size="small"
                    className="controls__button"
                    onClick={() => this.setRotate(90)}
                  >
                    <Icon>rotate_right</Icon>
                  </Button>
                  <div className="controls__zoom">
                    <input
                      id="zoom-scale"
                      type="range"
                      value={zoom}
                      name="scale"
                      min={1}
                      max={2}
                      step={0.1}
                      onChange={this.setZoom}
                      className="zoom-scale"
                    />
                    <label htmlFor="zoom-scale" className="zoom-label">
                      zoom
                    </label>
                  </div>
                  <Button
                    size="small"
                    className="controls__button preview"
                    onClick={this.onClickPreview}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
            {errorMessage && errorMessage}
            {showImageDetails && viewPreview && !lowRes && (
              <div className="preview-image">
                <img
                  src={editedImageURL}
                  width={width || editorWidth}
                  height={height || editorHeight}
                  className={previewBottom ? 'generated-image' : 'm-20'}
                  alt="Preview"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddImage;
