import {
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import './Reporting.scss';

interface IHeaderTagsInfoDialog {
  open: boolean;
  onClose: () => void;
}

interface IHeaderTag {
  title: string;
  description: string;
}

interface IHeaderTagFormula {
  title: string;
  formula: string;
}

const venueRevenueSummaryHeaderTags = [
  {
    description: 'Total value of products sold before taxes and fees',
    title: 'Total Product Sales',
  },
  {
    description:
      'Total amount earned by your store after reducing FanFood share',
    title: 'Store Revenue Share',
  },
  {
    description: 'Total amount of convenient fees earned by your store',
    title: 'Total Service Charge Share',
  },
  {
    description:
      'Total amount earned by your store after reducing FanFood share',
    title: 'Tips',
  },
  {
    description:
      'Total local sales and alcohol taxes charged ( % on total product sales + service charges paid on each order )',
    title: 'Total Taxes',
  },
  {
    description: 'Total amount of promo codes used by your customers',
    title: 'Discount Share',
  },
  {
    description: 'Sum of total value of all orders (including taxes & fees)',
    title: 'Total Sales',
  },
  {
    description:
      'Total processing fees charged on sales(2.9% + $0.30 per transaction)',
    title: 'Total Credit Card Fees',
  },
  {
    description: 'Final total earned by your store',
    title: 'Total Owed to Store',
  },
];

const venueRevenueSummaryFormulas = [
  {
    formula: 'Store Revenue Share + FanFood Revenue Share',
    title: 'Total Product Sales',
  },
  {
    formula: 'Total Product Sales - FanFood Revenue Share',
    title: 'Store Revenue Share',
  },
  {
    formula:
      'Store Revenue Share + Service Charge Share + Tips + Taxes - Discount',
    title: 'Total Sales',
  },
  {
    formula: 'Total Sales - Credit Card Fees',
    title: 'Total Owed to Store',
  },
];

const VenueRevenueSummaryExplainer = (props: IHeaderTagsInfoDialog) => {
  const { onClose, open } = props;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{ root: 'header-tags-dialog', paper: 'header-tags-paper' }}
    >
      <>
        <CancelIcon className="close-dialog-button" onClick={() => onClose()} />
        <List className="header-tags-dialog__list">
          <ListItem>
            <ListItemText
              className="header-tags-dialog__title"
              primary="What does each value mean?"
            />
          </ListItem>
          {venueRevenueSummaryHeaderTags.map(
            (headerTag: IHeaderTag, index: number) => (
              <ListItem key={index} className="header-tag">
                <ListItemText
                  className="header-tag__title"
                  primary={`${headerTag.title}:`}
                  classes={{
                    primary: 'header-tag__title_content',
                  }}
                />
                <ListItemText
                  className="header-tag__description"
                  secondary={headerTag.description}
                  classes={{
                    secondary: 'header-tag__description_content',
                  }}
                />
              </ListItem>
            ),
          )}
        </List>
        <Divider />
        <List className="header-tags-dialog__list">
          <ListItem>
            <ListItemText
              className="header-tags-dialog__title"
              primary="Formulas"
            />
          </ListItem>
          {venueRevenueSummaryFormulas.map(
            (headerTag: IHeaderTagFormula, index: number) => (
              <ListItem key={index} className="header-tag">
                <ListItemText
                  className="header-tag__formula"
                  primary={`${headerTag.title} = ${headerTag.formula}`}
                  classes={{
                    primary: 'header-tag__formula_content',
                  }}
                />
              </ListItem>
            ),
          )}
        </List>
      </>
    </Dialog>
  );
};

export default VenueRevenueSummaryExplainer;
