import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    MenuItem,
    Select,
  } from '@material-ui/core';
  import React, { useState, useEffect } from 'react';
  import './PrinterDialog.scss';
import { IStore } from '../../../models/Store.model';
  interface IPrinterDialogProps {
    onClose: () => void;
    onAddOrEditPrinter: (val: string) => void;
    stores: IStore[]
  }
  
  const MovePrinterDialog = ({
    onClose,
    onAddOrEditPrinter,
    stores
  }: IPrinterDialogProps) => {

    const [selectedStoreId, setSelectedStoreId] = useState<string>('');

    const handleChange = (event: any) => {
      setSelectedStoreId(event.target.value)
    }
    return (
      <Dialog
        classes={{ paper: 'printer-dialog' }}
        fullWidth={true}
        open={true}
        onClose={onClose}
        maxWidth="md"
      >
        <DialogTitle>
          <span className="section-title">
            Select Store
          </span>
          <Icon onClick={onClose} className="close-icon">
            cancel
          </Icon>
        </DialogTitle>
        <DialogContent className="form">

            <Select
              fullWidth={true}
              name="buildingVenueId"
              className="event-form__field"
              value={selectedStoreId}
              onChange={handleChange}
            >
              {stores.length > 0 && stores.map((item: any) => 
                <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
              )
            }
            </Select>
        </DialogContent>
  
        <div className="button-holder-similar-margin">
          <DialogActions>
            <Button color="primary" onClick={onClose} variant="outlined">
              <div className="icon-title-wrapper">
                <span className="icon-title">Cancel</span>
              </div>
            </Button>
            <Button
              color="primary"
              onClick={() =>
                onAddOrEditPrinter(selectedStoreId)
              }
              variant="contained"
              disabled={!selectedStoreId}
            >
              <div className="icon-title-wrapper">
                <span className="icon-title">
                  Save
                </span>
              </div>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };
  
  export default MovePrinterDialog;