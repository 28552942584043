import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route } from 'react-router-dom';
import { useAppContext } from '../../config/AppContext';
import { ISecondaryTab } from '../../config/Common';
import Chat from './Chat/Chat';
import './Support.scss';

const Support = (props: any) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const { selectedStoreId } = useAppContext();

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newTabValue: number,
  ) => {
    setTabValue(newTabValue);
  };

  const {
    location: { pathname },
    venueId,
  } = props;

  useEffect(() => {
    if (pathname.indexOf('/support/') === -1) {
      props.history.push(`${props.match.url}/chat`);
      setTabValue(0);
    }
  }, [pathname]);

  const secondaryTabs: ISecondaryTab[] = [
    {
      component: Chat,
      name: 'Chat',
      route: 'chat',
    },
  ];

  return (
    <div className="support-container">
      <div className="support-header-container">
        <Typography classes={{ root: 'section-title' }}>Support</Typography>
      </div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor="primary"
        classes={{
          flexContainer: 'secondary-tabs-buttons-container',
          indicator: 'indicator',
          root: 'secondary-tabs-container',
        }}
      >
        {secondaryTabs.map((secondaryTab: any, index: number) => (
          <Tab
            component={Link}
            to={`${props.match.url}/${secondaryTab.route}`}
            key={index}
            classes={{
              root: 'secondary-tab',
              selected: 'selectedTab',
              wrapper: 'secondary-tab-label',
            }}
            label={secondaryTab.name}
          />
        ))}
      </Tabs>
      {secondaryTabs.map((secondaryTab: any, index: number) => (
        <Route
          render={childProps => (
            <secondaryTab.component
              {...childProps}
              venueId={venueId}
              selectedStoreId={selectedStoreId}
            />
          )}
          path={`${props.match.path}/${secondaryTab.route}`}
          key={index}
        />
      ))}
      {!tabValue && <Redirect to={`${props.match.path}`} />}
    </div>
  );
};

export default Support;
