import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export const noDataAvailable = (colSpan: number, text?: string) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className="table-cell-no-data">
        {text || 'No Data Available'}
      </TableCell>
    </TableRow>
  );
};
