import { Switch, SwitchProps } from '@material-ui/core';
import React from 'react';

import './CustomSwitch.scss';

const CustomSwitch = (props: SwitchProps) => {
  return (
    <Switch
      disableRipple={true}
      classes={{
        root: 'custom-toggle-red',
        switchBase: 'switchBase',
        thumb: 'thumb',
        track: 'track',
      }}
      {...props}
    />
  );
};

export default CustomSwitch;
