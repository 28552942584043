import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { compareDesc, endOfDay, startOfDay, subHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { find, get } from 'lodash';
import CategoryService from '../../../services/Category/Category.service';
import InventoryService from '../../../services/Inventory/Inventory.service';
import ExportToExcel, { IWorksheet } from '../../../components/ExportToExcel';
import { getUTCDate } from '../../../services/Util/Util.service';
import { useAppContext } from '../../../config/AppContext';

const AdjustmentLog = (props: any) => {
  const [startDate, setStartDate] = useState<Date>(new Date(Date.now()));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedStoreId, setSelectedStoredId] = useState('All');
  const [selectedCategory, setSeletedCategory] = useState('All');
  const [categories, setCategories] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [items, setItems] = useState([]);
  const { venue } : any = useAppContext();
  useEffect(() => {
    fetchCategories();
  }, [selectedStoreId]);
  const handleStoreSelect = async (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All') {
      newSelectedStoreId = undefined;
      setSeletedCategory('All');
      setCategories([]);
    }
    setSelectedStoredId(newSelectedStoreId);
  };
  const handleCategorySelect = (event: any) => {
    let newSelectedCategoryId = get(event, ['target', 'value']);
    setSeletedCategory(newSelectedCategoryId);
  };
  const fetchCategories = async () => {
    if (!selectedStoreId || selectedStoreId == 'All') {
      return;
    }
    try {
      const { data }: any = await CategoryService.getAllCategories(
        selectedStoreId,
        100,
        1,
      );
      setCategories(data.items);
    } catch (err) {
      console.log(err, 'error while fetching categories');
    }
  };
  const getWorksheets = async (): Promise<any> => {
    const worksheets: IWorksheet[] = [
      {
        name: 'inventory-detail',
        data: items.map((record: any) => ({
          'Item Name': record.product_name,
          'Category Name': record.category_name,
          'Starting Inventory': record.starting_inventory,
          Sale: record.total_quantity,
          Loss: record.loss_count,
          'Ending Inventory': record.inventory_count,
          'Change Amount':record.changeamount
        })),
      },
    ];
    return worksheets;
  };

  useEffect(() => {
    searchInventory();
  }, [selectedStoreId, selectedCategory, searchKey]);
  const searchInventory = async () => {
    try {
      const reportStartDate = new Date(startDate.getTime());
      const reportEndDate = new Date(startDate.getTime());
      reportStartDate.setUTCHours(0, 1, 0, 0);
      reportEndDate.setUTCHours(23, 59, 0, 0);
      const start_date = reportStartDate.toISOString();
      const end_date = reportEndDate.toISOString();
      const { data } = await InventoryService.getInventoryLevels(
        venue?.id,
        selectedStoreId !== 'All' ? selectedStoreId : '',
        selectedCategory !== 'All' ? selectedCategory : '',
        searchKey,
        start_date,
        end_date,
      );
      setItems(data);
    } catch (err) {
      console.log(err, 'error while fetching inventory');
    }
  };
  // const renderFilterControls = (): JSX.Element => {
  //   return (
  //     <>
  //       <KeyboardDatePicker
  //         autoOk={true}
  //         disableToolbar={true}
  //         variant="inline"
  //         format="MM/dd/yyyy"
  //         margin="normal"
  //         className="date-picker-inline"
  //         label="Start Date"
  //         maxDate={new Date(Date.now())}
  //         value={startDate}
  //         onChange={date => date && setStartDate(date)}
  //         KeyboardButtonProps={{
  //           'aria-label': 'change date',
  //         }}
  //       />
  //       <Button
  //         color="primary"
  //         size="small"
  //         variant="contained"
  //         onClick={searchInventory}
  //         classes={{
  //           root: 'apply-btn',
  //         }}
  //       >
  //         Apply
  //       </Button>
  //     </>
  //   );
  // };

  return (
    <div className="reporting-root">
      <div
        className="reporting-input-container"
        style={{ marginBottom: '30px' }}
      >
        <Grid item lg={2}>
          <div className="block-1">
            <input
              placeholder="Search"
              style={{ border: '1px solid grey', padding: '6px' }}
              value={searchKey}
              onChange={event => {
                setSearchKey(event.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item lg={3}>
          <div className="block-2">
            <Grid
              container={true}
              direction="row"
              alignItems="center"
              className="report-type-select"
              xs={6}
              lg={12}
              item={true}
            >
              <InputLabel
                htmlFor="type"
                classes={{ root: 'report-type-label' }}
                style={{ color: 'black' }}
              >
                Store :
              </InputLabel>
              <Select
                value={selectedStoreId || 'All'}
                onChange={handleStoreSelect}
                classes={{
                  root: 'select-store',
                }}
              >
                <MenuItem key="All" value="All">
                  All
                </MenuItem>
                {props?.stores?.length > 0 ? (
                  props?.stores?.map((store: any) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="no-category" disabled>
                    No Store Available
                  </MenuItem>
                )}
              </Select>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={3}>
          <div className="block-2">
            <Grid
              container={true}
              direction="row"
              alignItems="center"
              className="report-type-select"
              xs={6}
              lg={12}
              item={true}
            >
              <InputLabel
                htmlFor="type"
                classes={{ root: 'report-type-label' }}
                style={{ color: 'black' }}
              >
                Category
              </InputLabel>
              <Select
                value={selectedCategory || 'All'}
                onChange={handleCategorySelect}
                classes={{
                  select: 'reporting-select',
                }}
                name="type"
                style={{ width: '200px' }}
              >
                {categories.length > 0 && (
                  <MenuItem key="All" value="All">
                    All
                  </MenuItem>
                )}

                {categories.length > 0 ? (
                  categories.map((category: any) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="no-category" disabled>
                    No Category Available
                  </MenuItem>
                )}
              </Select>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={4}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container={true} justify="flex-end" xs={10} item={true}>
              {renderFilterControls()}
            </Grid>
          </MuiPickersUtilsProvider> */}
        </Grid>
      </div>
      <div>
        <ExportToExcel
          buttonText="Export to Excel"
          filename="inventory-levels-detail"
          getWorksheets={getWorksheets}
        />
      </div>

      <Table className="reporting__table">
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Item Name
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Category
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Starting Inventory
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Item Sales (Period)
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Loss
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Ending Inventory
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Change Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any) => (
            <TableRow key={row.pID}>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.product_name}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.category_name}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.starting_inventory}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.total_quantity}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.loss_count}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.inventory_count}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.changeamount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default AdjustmentLog;
