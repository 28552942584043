import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';
import MoreVert from '@material-ui/icons/MoreVert';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { cloneDeep, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LocationTypeType } from '../../../../models/LocationTypeType.model';

const locationValidation = Yup.object().shape({
  name: Yup.string().required('Location name is required'),
  type: Yup.string()
    .notRequired()
    .nullable(),
  validValues: Yup.string(),
});

const ValidLocationHierarchy = (props: any) => {
  const {
    handleAddLocation,
    handleDeleteLocation,
    handleSaveLocation,
    isEdit,
    level,
    location,
    objectPath,
    setValidLocationTypes,
    validateLocation,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEditing, setIsEditing] = useState(isEdit || false);

  const processValidValues =
    typeof get(location, 'validValues') === 'string'
      ? get(location, 'validValues', '')
      : get(location, 'validValues', []).join(', ');

  const [name, setName] = useState(get(location, 'name', ''));
  const [type, setType] = useState(get(location, 'type'));
  const [validValues, setValidValues] = useState(processValidValues);

  const locationChildren = get(location, 'validLocationTypes', []);

  useEffect(() => {
    setIsEditing(isEdit);
  }, [isEdit, location]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSaveSubLocation = (values: any, formikBag: any) => {
    const {
      name: newName,
      type: newType,
      validValues: newValidValues,
    } = values;
    const isValid = validateLocation(newName, objectPath);

    if (!isValid) {
      return formikBag.setFieldError('name', 'Name should be unique');
    }

    const locationType = {
      name: newName,
      type: newType,
      validValues: newValidValues
        .replace(/\s*,\s*/g, ',')
        .split(',')
        .filter((locationValue: string) => !isEmpty(locationValue)),
    };
    setName(newName);
    setType(newType);
    setValidValues(newValidValues);

    handleSaveLocation(location, locationType, objectPath);
    return setIsEditing(false);
  };

  const handleCancelEdit = (values: any, formikBag: any) => {
    if (!get(location, 'id')) {
      handleDeleteSubLocation();
      return setIsEditing(false);
    }

    setValidLocationTypes((prevState: any) => {
      const newState = cloneDeep(prevState);
      const valueToEdit = get(newState, objectPath);
      valueToEdit.isEdit = false;
      return newState;
    });

    return setIsEditing(false);
  };

  const handleCloneLocation = () => {
    const objectPathDeconstruct = objectPath.split('.');
    const currentIndex = objectPathDeconstruct.pop();
    const parentObjectPath = objectPathDeconstruct.join('.');

    return handleAddLocation(
      parentObjectPath,
      'CLONE - ' + name,
      type,
      validValues,
      currentIndex + 1,
    );
  };

  const handleAddSubLocation = () => {
    const objectPathDeconstruct = objectPath + '.validLocationTypes';

    return handleAddLocation(objectPathDeconstruct, '', undefined, [], 0);
  };

  const handleDeleteSubLocation = () => {
    const objectPathDeconstruct = objectPath.split('.');
    const parentLevelIndex = objectPathDeconstruct.pop();
    const parentObjectPath = objectPathDeconstruct.join('.');

    if (!objectPathDeconstruct.length) {
      return handleDeleteLocation(objectPath, '');
    } else {
      return handleDeleteLocation(parentLevelIndex, parentObjectPath);
    }
  };

  return (
    <>
      {isEditing ? (
        <Formik
          initialValues={{
            name,
            type,
            validValues,
          }}
          onReset={handleCancelEdit}
          onSubmit={handleSaveSubLocation}
          validationSchema={locationValidation}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleReset,
            touched,
            values,
          }: FormikProps<any>) => (
            <ListItem
              classes={{
                container: 'valid-locations__form-row-container',
                root: `valid-locations__form-row level-${level}`,
              }}
            >
              <ListItemText>
                <Grid container={true} direction="row" alignItems="center">
                  <form
                    className="valid-locations__form"
                    onSubmit={handleSubmit}
                  >
                    <Grid item={true} xs={4}>
                      <TextField
                        className="valid-locations__form__textfield"
                        error={get(touched, 'name') && !!get(errors, 'name')}
                        name="name"
                        onChange={handleChange}
                        placeholder="Location Name"
                        required={true}
                        value={values.name}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="name"
                      />
                      <InputLabel className="valid-locations__form__example-values">
                        e.g. Section, Suite, Row, etc.
                      </InputLabel>
                    </Grid>
                    <Grid item={true} xs={4}>
                      {level <= 1 && (
                        <Select
                          name="type"
                          onChange={handleChange}
                          value={values.type}
                        >
                          {Object.keys(LocationTypeType).map(
                            (locationTypeType: string, index: number) => (
                              <MenuItem value={locationTypeType} key={index}>
                                {locationTypeType}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      )}
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="type"
                      />
                    </Grid>
                    <Grid
                      className="valid-locations__form__values"
                      item={true}
                      xs={4}
                    >
                      <Grid
                        className="valid-locations__form__values-container"
                        container={true}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item={true} xs={6}>
                          <Grid container={true} direction="column">
                            <TextField
                              className="valid-locations__form__textfield"
                              error={
                                get(touched, 'validValues') &&
                                !!get(errors, validValues)
                              }
                              name="validValues"
                              onChange={handleChange}
                              placeholder="Location Values (separate with commas)"
                              value={values.validValues}
                            />
                            <InputLabel className="valid-locations__form__example-values">
                              e.g. 1, 2, 3, A, B, C, etc.
                            </InputLabel>
                          </Grid>
                        </Grid>
                        <Button
                          className="valid-locations__form__add-sublocation"
                          type="submit"
                          variant="outlined"
                        >
                          SAVE
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  className="valid-locations__form__cancel"
                  onClick={handleReset}
                >
                  <Cancel />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </Formik>
      ) : (
        <ListItem className={`valid-locations__form-row level-${level}`}>
          <ListItemText>
            <Grid container={true} direction="row" alignItems="center">
              <Grid item={true} xs={4}>
                {name}
              </Grid>
              <Grid item={true} xs={4}>
                {type}
              </Grid>
              <Grid item={true} xs={4}>
                <Grid
                  alignItems="center"
                  container={true}
                  direction="row"
                  justify="space-between"
                >
                  {validValues}
                  <Button
                    className="valid-locations__form__add-sublocation"
                    onClick={handleAddSubLocation}
                    variant="contained"
                  >
                    <Add />
                    SUBLOCATION
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton
              className="valid-locations__form__options"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={e => {
                  handleCloneLocation();
                  setAnchorEl(null);
                }}
              >
                Clone
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsEditing(true);
                  setAnchorEl(null);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDeleteSubLocation();
                  setAnchorEl(null);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {locationChildren.map((child: any, index: number) => (
        <ValidLocationHierarchy
          key={`${get(child, 'id', index)}__subLocation`}
          handleAddLocation={handleAddLocation}
          handleDeleteLocation={handleDeleteLocation}
          handleSaveLocation={handleSaveLocation}
          isEdit={get(child, 'isEdit', false)}
          level={level + 1}
          location={child}
          objectPath={`${objectPath}.validLocationTypes.${index}`}
          setValidLocationTypes={setValidLocationTypes}
          validateLocation={validateLocation}
        />
      ))}
    </>
  );
};

export default ValidLocationHierarchy;
