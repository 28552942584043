import { UserVenueRole } from '../../models/User.model';
import { IVenueInvite } from '../../models/VenueInvite.model';
import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/venue-invites`;

interface ICreateVenueInviteDto {
  readonly allEvents?: boolean;
  readonly eventIds?: string[];
  readonly role: UserVenueRole;
  readonly storeIds?: string[];
  readonly suiteIds?: string[];
  readonly venueId: string;
}

const createVenueInvite = (dto: ICreateVenueInviteDto) =>
  axiosWithAuth.post<IVenueInvite>(baseUrl, dto);

const findAllVenueInvites = (venueId: string) =>
  axiosWithAuth.get<IVenueInvite[]>(baseUrl, { params: { venueId } });

const deleteVenueInvite = (inviteId: string) =>
  axiosWithAuth.delete<void>(`${baseUrl}/${inviteId}`);

export default {
  createVenueInvite,
  deleteVenueInvite,
  findAllVenueInvites,
};
