import { createContext, useContext } from 'react';

export default function createContextWrapper<T>() {
  const context = createContext<T | undefined>(undefined);

  const useContextHook = () => {
    const genericContext = useContext(context);

    if (!genericContext) {
      throw new Error('Context must be nested below a provider');
    }

    return genericContext;
  };

  return [useContextHook, context, context.Provider] as const;
}
