import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import DeleteDialog from '../../../components/DeleteDialog';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { PartnerId } from '../../../components/Partner/PartnerId';
import { useAppContext } from '../../../config/AppContext';
import { ITaxRate } from '../../../models/TaxRate.model';
import TaxRateService, {
  IAddOrUpdateTaxRateDto,
} from '../../../services/Venue/TaxRate.service';
import { TaxRateDialog } from './TaxRateDialog';

const newTaxRate: Partial<ITaxRate> = {
  name: '',
  percent: 0,
};

export const TaxRates = () => {
  const { fetchTaxRates, taxRates, venue } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [taxRateForEdit, setTaxRateForEdit] = useState<
    Partial<ITaxRate> | undefined
  >();
  const [taxRateToDelete, setTaxRateToDelete] = useState<
    ITaxRate | undefined
  >();

  const createOrUpdateTaxRate = async ({
    applyToAllProducts,
    id,
    name,
    percent,
  }: IAddOrUpdateTaxRateDto) => {
    if (!venue) {
      return;
    }

    try {
      if (id) {
        await TaxRateService.updateTaxRate(id, {
          name,
          percent,
        });
      } else {
        await TaxRateService.createTaxRate({
          applyToAllProducts,
          name,
          percent,
          venueId: venue.id,
        });
      }
      setTaxRateForEdit(undefined);
      await fetchTaxRates();
    } catch (error) {
      enqueueSnackbar(`Error saving Tax Rate`, {
        variant: 'error',
      });
    }
  };

  const deleteTaxRate = async () => {
    if (!taxRateToDelete) {
      return;
    }
    try {
      await TaxRateService.deleteTaxRate(taxRateToDelete.id);
      setTaxRateToDelete(undefined);
      await fetchTaxRates();
    } catch (error) {
      enqueueSnackbar(`Error deleting Tax Rate`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Grid item={true} xs={12}>
        <Typography className="container-title">
          Custom Tax Rates
          <span className="appended-text"> (Optional)</span>
        </Typography>
      </Grid>
      <Grid item={true} xs={8}>
        <Typography className="italic">
          Add Custom Tax Rates to your products. Any product that is not
          assigned one or more Custom Tax Rates will default to the venue's
          default Sales Tax Percent or Liquor Tax Percent.
        </Typography>
      </Grid>
      <Grid item={true} xs={4}>
        <Button
          size="medium"
          color="primary"
          aria-label="add"
          variant="contained"
          className="dark-button"
          onClick={() => setTaxRateForEdit(newTaxRate)}
        >
          <div className="icon-title-wrapper">
            <AddOutlinedIcon />
            <span className="icon-title">Add</span>
          </div>
        </Button>
      </Grid>
      <Grid item={true} xs={12}>
        <Table>
          <TableHead>
            <TableRow
              classes={{
                root: 'table-row',
              }}
            >
              <TableCell classes={{ root: 'table-cell table-cell-head' }}>
                Name
              </TableCell>
              <TableCell classes={{ root: 'table-cell table-cell-head' }}>
                Percent
              </TableCell>
              <TableCell classes={{ root: 'table-cell table-cell-head' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxRates && taxRates.length > 0
              ? taxRates.map((taxRate: ITaxRate) => (
                  <TableRow key={taxRate.id} id={taxRate.id}>
                    <TableCell className="table-cell">
                      {taxRate.name}
                      <PartnerId
                        id={taxRate.partnerTaxRateId}
                        partner={taxRate.partner}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      {taxRate.percent}%
                    </TableCell>
                    <TableCell className="table-cell">
                      <Button
                        size="small"
                        onClick={() => setTaxRateForEdit(taxRate)}
                        value="edit"
                      >
                        <div className="icon-title-wrapper">
                          <EditOutlinedIcon />
                          <span className="icon-title">Edit</span>
                        </div>
                      </Button>
                      <Button
                        size="small"
                        value="delete"
                        onClick={() => setTaxRateToDelete(taxRate)}
                      >
                        <div className="icon-title-wrapper">
                          <DeleteOutlinedIcon />
                          <span className="icon-title">Delete</span>
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : noDataAvailable(3, 'No Custom Tax Rates')}
          </TableBody>
        </Table>
      </Grid>
      {taxRateToDelete && (
        <DeleteDialog
          dialogTitle="Delete Tax Rate"
          onClose={() => setTaxRateToDelete(undefined)}
          onSubmit={deleteTaxRate}
          dialogContent="Are you sure you want to delete this Tax Rate? This Custom Tax Rate will be removed from all products. This cannot be undone."
        />
      )}
      {taxRateForEdit && venue && (
        <TaxRateDialog
          createOrUpdateTaxRate={createOrUpdateTaxRate}
          onClose={() => setTaxRateForEdit(undefined)}
          taxRate={taxRateForEdit}
        />
      )}
    </>
  );
};
