import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { useAppContext } from '../../config/AppContext';
import VenueDetails from '../VenueDetails/VenueDetails';
import './Home.scss';
import HomeTab from './HomeTab';
import Navbar from './Navbar';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service'
const Home = (props: any): JSX.Element => {
  const [tabValue, setTabValue] = useState('venues');
  const { enqueueSnackbar } = useSnackbar();
  const {
    loadCurrentUser,
    logout,
    setShowSpinner,
    setVenue,
    showSpinner,
    user,
  } = useAppContext();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#009dc7',
      },
      secondary: {
        main: '#009dc7',
      },
    },
  });

  useEffect(() => {
    if (['/home', '/home/'].includes(props.location.pathname)) {
      props.history.push('/home/venues');
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!user) {
        setShowSpinner(true);
        const success = await loadCurrentUser();
        if (!success) {
          enqueueSnackbar('Active login not found.', { variant: 'error' });
          await logout();
          const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
          if(tenant_schema === "true"){
            return props.history.push('/twin-dolphin');
          }
          props.history.push('/login');
        }
        setShowSpinner(false);
      }
    })();
  }, [user]);

  useEffect(() => {
    let defaultTabIndex = '';
    switch (props.location.pathname) {
      case '/home':
      case '/home/venues':
        setVenue(undefined);
        defaultTabIndex = 'venues';
        break;
      case '/home/global-products':
        defaultTabIndex = 'global-products';
        break;
      case '/home/user-admins':
        defaultTabIndex = 'user-admins';
        break;
      case '/home/reporting':
        defaultTabIndex = 'reporting';
        break;
      case '/home/tags':
        defaultTabIndex = 'tags';
        break;
      case '/home/printers':
        defaultTabIndex = 'printers';
        break;
      default:
        defaultTabIndex = 'venues';
        break;
    }
    setTabValue(defaultTabIndex);
  }, [props.location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <div className="home">
        {showSpinner && <Spinner />}
        <Navbar />
        <Route path="/home/venue-details/:venueId" component={VenueDetails} />
        <HomeTab
          tabValue={tabValue}
          assignTabValue={(value: any) => setTabValue(value)}
        />
      </div>
    </ThemeProvider>
  );
};

export default Home;
