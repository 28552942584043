import { uniq } from 'lodash';
import {
  ISecurityGroup,
  IUser,
  UserRole,
  UserVenueRole,
} from '../models/User.model';

const ManagerPortalRoles = [
  UserVenueRole.VENUE_MANAGER,
  UserVenueRole.STORE_MANAGER,
  UserVenueRole.STORE_EMPLOYEE,
];

const getSecurityGroups = (user?: IUser): ISecurityGroup[] => {
  if (!user) {
    return [];
  }
  return (user.securityGroups || []).filter(sg =>
    ManagerPortalRoles.includes(sg.role),
  );
};

export const isAdmin = (user?: IUser): boolean => {
  return !!(
    user && [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN].includes(user.role)
  );
};

export const isVenueManager = (user: IUser, venueId: string): boolean => {
  const securityGroup = getSecurityGroups(user).find(
    (sg: ISecurityGroup) =>
      sg.venueId === venueId && sg.role === UserVenueRole.VENUE_MANAGER,
  );
  return !!securityGroup;
};

export const isStoreManager = (
  user: IUser | undefined,
  venueId: string,
  storeId?: string,
): boolean => {
  const securityGroup = getSecurityGroups(user).find(
    (sg: ISecurityGroup) =>
      sg.venueId === venueId &&
      sg.role === UserVenueRole.STORE_MANAGER &&
      (storeId ? (sg.storeIds || []).includes(storeId) : true),
  );
  return !!securityGroup;
};

export const canAccessManagerPortal = (user: IUser): boolean => {
  if (isAdmin(user)) {
    return true;
  }

  const venueIds = getVenueIds(user);
  return venueIds && venueIds.length > 0;
};

export const isSuperAdmin = (user: IUser): boolean => {
  return user && user.role === UserRole.SUPER_ADMIN;
};

export const canChangeVenues = (user?: IUser): boolean => {
  if (!user) {
    return false;
  }

  if (isAdmin(user)) {
    return true;
  }

  const venueIds = getVenueIds(user);
  return venueIds && venueIds.length > 1;
};

export const getVenueIds = (user: IUser): string[] => {
  return uniq(getSecurityGroups(user).map(sg => sg.venueId));
};

export const canAccessVenue = (user: IUser, venueId: string): boolean => {
  const securityGroup = getSecurityGroups(user).find(
    (sg: ISecurityGroup) => sg.venueId === venueId,
  );
  return isAdmin(user) || !!securityGroup;
};

export const canEditVenue = (user: IUser, venueId: string): boolean => {
  return isAdmin(user) || isVenueManager(user, venueId);
};

export const canEditStore = (
  user: IUser,
  venueId: string,
  storeId: string,
): boolean => {
  return (
    isAdmin(user) ||
    isVenueManager(user, venueId) ||
    isStoreManager(user, venueId, storeId)
  );
};

export const canEditVenueAch = (user: IUser, venueId: string): boolean => {
  return isSuperAdmin(user) || isVenueManager(user, venueId);
};

export const canEditStoreAch = (
  user: IUser,
  venueId: string,
  storeId: string,
): boolean => {
  return (
    isSuperAdmin(user) ||
    isVenueManager(user, venueId) ||
    isStoreManager(user, venueId, storeId)
  );
};
