import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { compareDesc, endOfDay, startOfDay, subHours } from 'date-fns';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service';
import { find, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import TagManager from '../../components/TagManager';
import { useAppContext } from '../../config/AppContext';
import { ITag } from '../../models/Tag.model';
import { isStoreManager } from '../../services/Auth.service';
import ReportingService, {
  IReportQuery,
  ReportType,
} from '../../services/Reporting/Reporting.service';
import TagService from '../../services/Tag.service';
import {
  getCurrencyPrefix,
  getUTCDate,
} from '../../services/Util/Util.service';
import BuildingRevenueSummary, {
  IBuildingRevenueSummaryDataRecord,
} from './BuildingRevenueSummary';
import BuildingUserDetail, {
  IBuildingUserDetailDataRecord,
} from './BuildingUserDetail';
import FlashReport, { IFlashReportDataRecord } from './FlashReport';
import PayoutDetail, { IPayoutDataRecord } from './PayoutDetail';
import PromoCodeSummary from './PromoCodeSummary';
import RefundSummary, { IRefundDataRecord } from './RefundSummary';
import './Reporting.scss';
import {
  getAccessibleReports,
  IReport,
  ReportSection,
} from './ReportingConfig';
import RunnerSummary from './RunnerSummary/RunnerSummary';
import { TaxReport } from './TaxReport/TaxReport';
import { MemberReport } from './MemberReport/MemberReport';
import UserDetail, { IUserDetailDataRecord } from './UserDetail';
import UserOverview, { IUserOverviewDataRecord } from './UserOverview';
import VenueEventSummary, {
  IVenueEventSummaryDataRecord,
} from './VenueEventSummary';
import { VenueProductSummary } from './VenueProductSummary/VenueProductSummary';
import VenueRevenueSummary, {
  IVenueRevenueSummaryDataRecord,
} from './VenueRevenueSummary';

import { DashboardReport } from './DashBoardReport/DashboardReport';
interface IReportingProps {
  section: ReportSection;
  venueId: string;
}
const Reporting = (props: IReportingProps): JSX.Element => {
  const { stores } = useAppContext();
  const { section, venueId } = props;
  const { setShowSpinner, user, venue } = useAppContext();

  const [accessibleReports, setAccessibleReports] = useState<IReport[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));
  const [reportData, setReportData] = useState<any[]>([]);
  const [selectedReport, setSelectedReport] = useState<IReport | undefined>(
    undefined,
  );
  const [reportStoreId, setReportStoreId] = useState<string | undefined>(
    undefined,
  );
  const [transactionType, setTransactionType] = useState<string | undefined>(
    undefined,
  );
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [dashBoardDate, setDashBoardDate] = useState<Date>(
    startOfDay(new Date()),
  );
  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const [venueCurrency, setVenueCurrency] = useState<string>('$');
  const [dashBoardStoreId, setDashBoardStoreId] = useState<string>('');
  const getReportByType = (reportType: ReportType): IReport | undefined =>
    find(accessibleReports, (report: IReport) => report.type === reportType);

  const changeReportType = (event: React.ChangeEvent<any>) => {
    setReportData([]);
    setReportStoreId(undefined);
    setSelectedReport(getReportByType(event.target.value));
  };

  const changeReportStoreId = (storeId: string) => {
    setReportStoreId(storeId);
    setReportData([]);
  };

  const changeTransactionType = (type: string) => {
    setTransactionType(type);
    setReportData([]);
  };

  const handleDashBoardStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    setDashBoardStoreId(newSelectedStoreId);
  };
  // initialize accessibleReports on page load
  useEffect(() => {
    if (!user) {
      return;
    }
    const reports = getAccessibleReports(user.role, section);
    setAccessibleReports(reports);
  }, [section, user]);

  // set default selectedReport once accessibleReports loads
  useEffect(() => {
    setSelectedReport(getReportByType(ReportType.VENUE_DASHBOARD));
  }, [accessibleReports]);

  const renderFilterControls = (): JSX.Element => {
    if (!selectedReport) {
      return <></>;
    }
    // if (selectedReport.type === ReportType.VENUE_DASHBOARD) {
    //   return (
    //     <>
    //       <KeyboardDatePicker
    //         autoOk={true}
    //         disableToolbar={true}
    //         variant="inline"
    //         format="MM/dd/yyyy"
    //         margin="normal"
    //         className="date-picker-inline"
    //         label="Report Date"
    //         maxDate={new Date(Date.now())}
    //         maxDateMessage="Start Date cannot be after today Date"
    //         value={dashBoardDate}
    //         onChange={date => date && setDashBoardDate(date)}
    //         KeyboardButtonProps={{
    //           'aria-label': 'change date',
    //         }}
    //       />
    //       <Button
    //         color="primary"
    //         size="small"
    //         variant="contained"
    //         //onClick={getReport}
    //         classes={{
    //           root: 'apply-btn',
    //         }}
    //       >
    //         Apply
    //       </Button>
    //     </>
    //   );
    // }
    return (
      <>
        <KeyboardDatePicker
          autoOk={true}
          disableToolbar={true}
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          className="date-picker-inline"
          label="Start Date"
          maxDate={endDate}
          maxDateMessage="Start Date cannot be after End Date"
          value={startDate}
          onChange={date => date && setStartDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          autoOk={true}
          disableToolbar={true}
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          className="date-picker-inline"
          label="End Date"
          maxDate={new Date()}
          value={endDate}
          onChange={date => date && setEndDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {section === ReportSection.Admin && selectedReport.showTagFilter && (
          <TagManager
            allTags={allTags}
            currentTags={selectedTags}
            onTagsUpdated={(tags: string[]) => setSelectedTags(tags)}
          />
        )}
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={compareDesc(startDate, endDate) === -1 ? true : false}
          onClick={getReport}
          classes={{
            root: 'apply-btn',
          }}
        >
          Apply
        </Button>
      </>
    );
  };

  const renderReportTable = (): JSX.Element => {
    if (!selectedReport) {
      return <></>;
    }

    const isAdminSection = section === ReportSection.Admin;

    switch (selectedReport.type) {
      case ReportType.VENUE_PRODUCT:
        return (
          <VenueProductSummary
            onChangeStore={changeReportStoreId}
            selectedStoreId={reportStoreId}
            venueCurrency={venueCurrency}
            venueProductSummaryData={reportData}
            onChangeTransactionType={changeTransactionType}
          />
        );
      case ReportType.RUNNER:
        return (
          <RunnerSummary
            data={reportData as any}
            isAdmin={isAdminSection}
            venueCurrency={venueCurrency}
          />
        );
      case ReportType.VENUE_REVENUE:
        return (
          <VenueRevenueSummary
            onChangeTransactionType={changeTransactionType}
            onChangeStore={changeReportStoreId}
            selectedStoreId={reportStoreId}
            data={reportData as IVenueRevenueSummaryDataRecord[]}
            isAdmin={isAdminSection}
          />
        );
      case ReportType.VENUE_DASHBOARD:
        return (
          <DashboardReport
            venueId={venueId}
            startDate={startDate}
            endDate={endDate}
            dashBoardStoreId={dashBoardStoreId}
            reportData={reportData}
          />
        );
      case ReportType.VENUE_EVENT_SUMMARY:
        return (
          <VenueEventSummary
            data={reportData as IVenueEventSummaryDataRecord[]}
            isAdmin={isAdminSection}
          />
        );
      case ReportType.PROMOTIONS:
        return <PromoCodeSummary data={reportData as any} isAdmin={false} />;
      case ReportType.FLASH_REPORT:
        return (
          <FlashReport
            data={reportData as IFlashReportDataRecord[]}
            isAdmin={true}
          />
        );
      case ReportType.USER_OVERVIEW:
        return <UserOverview data={reportData as IUserOverviewDataRecord[]} />;
      case ReportType.USER_DETAIL:
        return <UserDetail data={reportData as IUserDetailDataRecord[]} />;
      case ReportType.BUILDING_REVENUE_SUMMARY:
        return (
          <BuildingRevenueSummary
            data={reportData as IBuildingRevenueSummaryDataRecord[]}
            isAdmin={isAdminSection}
          />
        );
      case ReportType.BUILDING_USER_DETAIL:
        return (
          <BuildingUserDetail
            data={reportData as IBuildingUserDetailDataRecord[]}
            isAdmin={isAdminSection}
          />
        );
      case ReportType.PAYOUT_DETAIL:
        return (
          <PayoutDetail
            data={reportData as IPayoutDataRecord[]}
            onChangeStore={changeReportStoreId}
            selectedStoreId={reportStoreId}
            venueId={venueId}
          />
        );
      case ReportType.REFUND_SUMMARY:
        return (
          <RefundSummary
            data={reportData as IRefundDataRecord[]}
            isAdmin={isAdminSection}
          />
        );
      case ReportType.TAX_REPORT:
        return (
          <TaxReport
            onChangeStore={changeReportStoreId}
            selectedStoreId={reportStoreId}
            venueCurrency={venueCurrency}
            venueTaxReportData={reportData}
          />
        );
      case ReportType.MEMBER_REPORT:
        return (
          <MemberReport
            onChangeStore={changeReportStoreId}
            selectedStoreId={reportStoreId}
            memberReportReportData={reportData}
          />
        );
      default:
        return <div>Report has not yet been defined</div>;
    }
  };

  const getReport = async () => {
    if (!selectedReport) {
      return;
    }

    if (
      selectedReport.type === ReportType.PAYOUT_DETAIL &&
      isStoreManager(user, venueId) &&
      !reportStoreId
    ) {
      return;
    }

    const reportEndDate = endDate;
    let reportStartDate = startDate;

    setShowSpinner(true);

    if (compareDesc(reportEndDate, reportStartDate) === 0) {
      reportStartDate = subHours(reportEndDate, 24);
    }

    const startTime = getUTCDate(
      reportStartDate,
      get(venue, 'timezone', ''),
    ).toISOString();
    const endTime = getUTCDate(
      reportEndDate,
      get(venue, 'timezone', ''),
    ).toISOString();
    try {
      const queryPayload: IReportQuery = {
        endTime,
        reportType: selectedReport.type,
        startTime,
        tags: selectedTags,
        venueId,
      };
      if (
        [
          ReportType.VENUE_PRODUCT,
          ReportType.PAYOUT_DETAIL,
          ReportType.VENUE_REVENUE,
          ReportType.TAX_REPORT,
          ReportType.VENUE_PRODUCT,
        ].includes(selectedReport.type) &&
        !!reportStoreId
      ) {
        Object.assign(queryPayload, { storeId: reportStoreId });
      }
      if (
        (ReportType.VENUE_REVENUE === selectedReport.type ||
          ReportType.VENUE_PRODUCT === selectedReport.type) &&
        transactionType !== 'All'
      ) {
        Object.assign(queryPayload, { transactionType: transactionType });
      }
      if (ReportType.VENUE_DASHBOARD === selectedReport.type) {
        Object.assign(queryPayload, { storeId: dashBoardStoreId });
      }
      if (section === ReportSection.Admin && ReportType.MEMBER_REPORT !== selectedReport.type) {
        const { data } = await ReportingService.getAdminReports(queryPayload);
        setReportData(data);
        setVenueCurrency(getCurrencyPrefix(venue?.currency));
      } else {
        if (selectedReport.type === ReportType.MEMBER_REPORT) {
          const payload = {
            endTime,
            startTime,
            venueId,
          };
          const { data } = await ReportingService.getMembertReport(payload);
          setReportData(data);
        }
        const { data } = await ReportingService.getReport(queryPayload);
        if (selectedReport.type === ReportType.PROMOTIONS) {
          const output = Object.values(
            data.reduce((acc: any, curr: any) => {
              if (acc[curr.code]) {
                acc[curr.code].totalValue += curr.totalValue;
                acc[curr.code].totalSold++;
              } else {
                acc[curr.code] = {
                  ...curr,
                  totalSold: 1,
                };
              }
              return acc;
            }, {}),
          );
          setReportData(output);
        } else {
          setReportData(data);
        }
        setVenueCurrency(getCurrencyPrefix(venue?.currency));
      }

    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    (async () => {
      await getReport();
    })();
  }, [
    selectedReport,
    reportStoreId,
    transactionType,
    dashBoardDate,
    dashBoardStoreId,
  ]);

  useEffect(() => {
    (async () => {
      const { data } = await TagService.getAll();
      setAllTags(data.map((tag: ITag) => tag.name));
    })();
  }, []);

  if (!selectedReport) {
    return <></>;
  }

  return (
    <div className="reporting-root">
      <Typography
        component="h1"
        variant="h5"
        className="reporting-title section-title"
      >
        Reporting
      </Typography>
      <div className="reporting-input-container">
        <Grid
          container={true}
          direction="row"
          alignItems="center"
          className="report-type-select"
          xs={8}
          item={true}
        >
          <InputLabel htmlFor="type" classes={{ root: 'report-type-label' }}>
            Report Type:
          </InputLabel>
          <Select
            value={selectedReport.type}
            onChange={changeReportType}
            displayEmpty={false}
            classes={{
              select: 'reporting-select',
            }}
            name="type"
          >
            {accessibleReports.map((report: IReport, index: number) => (
              <MenuItem key={index} value={report.type}>
                {report.name}
              </MenuItem>
            ))}
            {}
          </Select>
        </Grid>
        {selectedReport.type === ReportType.VENUE_DASHBOARD && (
          <Grid
            container={true}
            direction="row"
            alignItems="center"
            className="report-type-select"
            xs={8}
            item={true}
          >
            <InputLabel htmlFor="type" classes={{ root: 'report-type-label' }}>
              Store:
            </InputLabel>
            <Select
              value={dashBoardStoreId || 'All Stores'}
              onChange={handleDashBoardStoreSelect}
              classes={{
                root: 'select-store',
              }}
            >
              <MenuItem key="" value="All Stores">
                All Stores
              </MenuItem>
              {stores.map((store: any) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container={true} justify="flex-end" xs={10} item={true}>
            {renderFilterControls()}
          </Grid>
        </MuiPickersUtilsProvider>
      </div>

      {renderReportTable()}
    </div>
  );
};

export default Reporting;
