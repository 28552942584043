import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { IVenueDashboardOrders } from '../routes/Reporting/ReportingConfig';

enum GraphType {
  TIMES,
  RUNNERS,
  STORES,
}

interface IGraphData {
  counts: number[];
  labels: string[];
  topLabel: string;
}

const TotalOrdersBar = ({
  formattedTimes,
  stores,
  runners,
}: IVenueDashboardOrders): JSX.Element => {
  const [type, setType] = useState(GraphType.TIMES);

  const graphData: IGraphData[] = [
    {
      counts: formattedTimes.map(ft => ft.count),
      labels: formattedTimes.map(ft => ft.name),
      topLabel: 'Total Orders',
    },
    {
      counts: runners.map(r => r.count),
      labels: runners.map(r => r.name),
      topLabel: 'Total Orders Per Runner',
    },
    {
      counts: stores.map(s => s.count),
      labels: stores.map(s => s.name),
      topLabel: 'Total Orders Per Store',
    },
  ];
  const { counts, labels, topLabel } = graphData[type];

  useEffect(() => {
    setType(GraphType.TIMES);
  }, []);

  const chartData = {
    datasets: [
      {
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        data: counts,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        label: topLabel,
      },
    ],
    labels,
  };

  return (
    <Paper classes={{ root: 'graph-container-orders-overview' }}>
      <Grid container={true} className="total-orders-grid-container">
        <Typography variant="h6">Total Orders</Typography>
        <div>
          <Button
            variant="outlined"
            size="small"
            classes={{ root: 'graph-button' }}
            color={type === GraphType.TIMES ? 'secondary' : 'default'}
            onClick={() => setType(GraphType.TIMES)}
          >
            Times
          </Button>
          <Button
            variant="outlined"
            size="small"
            classes={{ root: 'graph-button' }}
            color={type === GraphType.RUNNERS ? 'secondary' : 'default'}
            onClick={() => setType(GraphType.RUNNERS)}
          >
            Runners
          </Button>
          <Button
            variant="outlined"
            size="small"
            classes={{ root: 'graph-button' }}
            color={type === GraphType.STORES ? 'secondary' : 'default'}
            onClick={() => setType(GraphType.STORES)}
          >
            Stores
          </Button>
        </div>
      </Grid>
      <Bar
        data={chartData}
        width={100}
        height={50}
        options={{
          maintainAspectRatio: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
              },
            ],
          },
          tooltips: {
            custom: (tooltipModel: any) => {
              if (tooltipModel.labelColors) {
                tooltipModel.labelColors.push({
                  backgroundColor: 'rgba(165, 55, 253, 1)',
                  borderColor: 'rgba(165, 55, 253, 1)',
                });
              }

              if (tooltipModel.body && tooltipModel.body[0]) {
                tooltipModel.height = 75;
              }
            },
            enabled: true,
          },
        }}
      />
    </Paper>
  );
};

export default TotalOrdersBar;
