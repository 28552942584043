import {
  IStoreVenueLocation,
  IVenueLocation,
} from '../../models/Location.model';
import { IPrinter } from '../../models/Printer.model';
import { IStore, ValidStoreType } from '../../models/Store.model';
import axiosWithAuth, { IPaginatedItems } from '../ServiceConfig';

const baseUrl = `/stores`;

export interface IGetStoreOptions {
  limit: number;
  pageNumber: number;
  type?: string;
  venueId: string;
}

export interface ICreateStoreDto {
  description?: string;
  location?: string;
  name: string;
  priority: number;
  type: ValidStoreType;
  venueId: string;
  customDiscount :boolean;
}

export interface IAddStoreLocationsDto {
  storeVenueLocations: Array<{
    includeNested?: boolean;
    venueLocationId: string;
  }>;
}
export interface ScheduleHour {
  day: string;
  timeInterval: string;
  close: boolean;
  override: boolean;
}
export interface ScheduleHourDto {
  schedule: ScheduleHour[];
}

const createStore = (body: ICreateStoreDto) =>
  axiosWithAuth.post<IStore>(baseUrl, body);

const getAllStores = (options: IGetStoreOptions) =>
  axiosWithAuth.get(`${baseUrl}/admin-stores`, { params: options });

const getStore = (storeId: string) =>
  axiosWithAuth.get<IStore>(`${baseUrl}/${storeId}`);

const createAchAccount = (storeId: string, body: any) =>
  axiosWithAuth.post<IStore>(`${baseUrl}/${storeId}/achaccount`, body);

const deleteAchAccount = (storeId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${storeId}/achaccount`);

const getPrinters = (storeId: string) =>
  axiosWithAuth.get<IPrinter[]>(`${baseUrl}/${storeId}/printers`);

const updateStore = (storeId: string, body: any) =>
  axiosWithAuth.put<IStore>(`${baseUrl}/${storeId}`, body);

const deleteStore = (storeId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${storeId}`);

const getStoreLocations = (storeId: string) =>
  axiosWithAuth.get<IStoreVenueLocation[]>(`${baseUrl}/${storeId}/locations`);

const getStoresForLocation = (venueLocation: IVenueLocation) =>
  axiosWithAuth.get<IPaginatedItems<IStore>>(`${baseUrl}`, {
    params: {
      deliveryLocationId: venueLocation.id,
      orderType: 'DELIVERY',
      type: ValidStoreType.REGULAR,
      venueId: venueLocation.venueId,
    },
  });

const updateStoreLocations = (storeId: string, body: IAddStoreLocationsDto) =>
  axiosWithAuth.post<void>(`${baseUrl}/${storeId}/locations`, body);

const uploadStoreImage = ({ id, imageFile }: { id: any; imageFile: any }) =>
  axiosWithAuth.post(`${baseUrl}/upload/${id}`, imageFile);

const getSuiteKitchen = (suiteId: string) =>
  axiosWithAuth.get<IStore>(`${baseUrl}/suites/${suiteId}`);

const setStoreSchedule = (storeId: string, scheduleHour: ScheduleHourDto) =>
  axiosWithAuth.put<IStore>(`${baseUrl}/${storeId}/schedule-hour`, scheduleHour);

export default {
  createAchAccount,
  createStore,
  deleteAchAccount,
  deleteStore,
  getAllStores,
  getPrinters,
  getStore,
  getStoreLocations,
  getStoresForLocation,
  getSuiteKitchen,
  updateStore,
  updateStoreLocations,
  uploadStoreImage,
  setStoreSchedule
};
