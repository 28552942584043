import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorMessage, Formik } from 'formik';
import { cloneDeep, get } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { useAppContext } from '../../../config/AppContext';
import {
  getTimezoneDate,
  getUTCDate,
} from '../../../services/Util/Util.service';
import { PromotionType } from './PromotionType.enum';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PromoValidationSchema = () => {
  return Yup.object().shape(
    {
      code: Yup.string().required('Code is a required field'),
      discountAmount: Yup.number().when(['discountPercentage'], {
        is: discountPercentage => discountPercentage > 0,
        otherwise: Yup.number()
          .min(0)
          .positive(
            'One of Discount Amount or Discount Percentage must be greater than 0',
          ),

        then: Yup.number()
          .min(0)
          .notRequired()
          .test(
            'one',
            'Only one of Discount Amount or Discount Percentage can be more than 0',
            function(value) {
              const { discountPercentage } = this.parent;
              return !(discountPercentage > 0 && value > 0);
            },
          ),
      }),
      discountPercentage: Yup.number().when(['discountAmount'], {
        is: discountAmount => discountAmount > 0,
        otherwise: Yup.number()
          .min(0)
          .positive(
            'One of Discount Amount or Discount Percentage must be greater than 0',
          ),
        then: Yup.number()
          .min(0)
          .notRequired()
          .test(
            'one',
            'Only one of DiscountAmount or Discount Percentage can be more than 0',
            function(value) {
              const { discountAmount } = this.parent;
              return !(discountAmount > 0 && value > 0);
            },
          ),
      }),
      endDateTime: Yup.date()
        .required()
        .test('min', 'End Date should be greater than Start Date.', function(
          value,
        ) {
          const { startDateTime } = this.parent;
          return value > startDateTime;
        }),
      maxUsesPerUser: Yup.number()
        .min(1)
        .notRequired()
        .nullable(),
      minPurchaseAmount: Yup.number().when('type', {
        is: PromotionType.AUTOMATIC,
        otherwise: Yup.number()
          .min(0)
          .notRequired(),
        then: Yup.number()
          .min(0)
          .required('Minimum Purchase Amount is required.'),
      }),
      startDateTime: Yup.date().required(),
      storeIds: Yup.array().required('Please select at least one store.'),
      type: Yup.string()
        .oneOf(Object.keys(PromotionType))
        .required(),
    },
    [['discountAmount', 'discountPercentage']],
  );
};

const PromoDialog = (props: any) => {
  const { promo, close, submit } = props;
  const { venue, stores } = useAppContext();

  if (!venue) {
    return null;
  }

  const promoCopy = cloneDeep(promo);
  promoCopy.startDateTime = getTimezoneDate(
    new Date(promoCopy.startDateTime),
    get(venue, 'timezone', ''),
  );
  promoCopy.endDateTime = getTimezoneDate(
    new Date(promoCopy.endDateTime),
    get(venue, 'timezone', ''),
  );
  promoCopy.storeIds = stores.filter((f: any) => {
    return promoCopy.storeIds.some((v: any) => v === f.id);
  });

  return (
    <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className="section-title">
        <span className="section-title">
          {promoCopy.id ? 'Edit Promo' : 'Add Promo'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="generic-dialog">
          <Formik
            initialValues={promoCopy}
            onSubmit={values => {
              const {
                endDateTime,
                startDateTime,
                storeIds,
                type,
                discountAmount,
                discountPercentage,
                minPurchaseAmount,
                maxUsesPerUser,
              } = values;

              submit({
                ...values,
                discountAmount: discountAmount || 0,
                discountPercentage: discountPercentage || 0,
                endDateTime:
                  typeof endDateTime === 'string'
                    ? endDateTime
                    : getUTCDate(
                        new Date(endDateTime),
                        get(venue, 'timezone', ''),
                      ).toISOString(),
                maxUsesPerUser: maxUsesPerUser || null,
                minPurchaseAmount: minPurchaseAmount || 0,
                startDateTime:
                  typeof startDateTime === 'string'
                    ? startDateTime
                    : getUTCDate(
                        new Date(startDateTime),
                        get(venue, 'timezone', ''),
                      ).toISOString(),
                storeIds: storeIds.map((s: any) => s.id),
              });
            }}
            validationSchema={PromoValidationSchema}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Autocomplete
                  multiple={true}
                  className="generic-dialog__field"
                  options={stores}
                  disableCloseOnSelect={true}
                  onChange={(event, newValue) => {
                    setFieldValue('storeIds', newValue);
                  }}
                  value={values.storeIds}
                  noOptionsText="No Stores"
                  getOptionLabel={(option: any) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Stores"
                      placeholder="Select Stores"
                    />
                  )}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="storeIds"
                />
                <TextField
                  error={touched.code && !!errors.code}
                  required={true}
                  className="generic-dialog__field"
                  name="code"
                  inputProps={{
                    type: 'string',
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Code"
                  value={values.code}
                  autoComplete="off"
                />
                <ErrorMessage className="error" component="div" name="code" />
                <div className="generic-dialog__field">
                  This is Pos Discount:
                  <Switch
                    classes={{
                      track: 'custom-colored-switch-track',
                    }}
                    name="isPosDiscount"
                    color="primary"
                    checked={values.isPosDiscount}
                    value={values.isPosDiscount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    required={true}
                    name="startDateTime"
                    ampm={true}
                    disablePast={promoCopy.id ? false : true}
                    className="generic-dialog__field"
                    variant="inline"
                    margin="normal"
                    maxDate={values.endDateTime}
                    maxDateMessage="Start Date cannot be after End Date"
                    label="Start Date Time"
                    value={values.startDateTime}
                    onChange={(value: any) => {
                      setFieldValue('startDateTime', value);
                    }}
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="startDateTime"
                  />
                  <DateTimePicker
                    required={true}
                    name="endDateTime"
                    ampm={true}
                    disablePast={promoCopy.id ? false : true}
                    className="generic-dialog__field"
                    variant="inline"
                    margin="normal"
                    label="End Date Time"
                    value={values.endDateTime}
                    onChange={(value: any) => {
                      setFieldValue('endDateTime', value);
                    }}
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="endDateTime"
                  />
                </MuiPickersUtilsProvider>
                <FormControl required={true} className="generic-dialog__field">
                  <InputLabel shrink={true} htmlFor="age-label-placeholder">
                    Type
                  </InputLabel>
                  <Select
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayEmpty={true}
                    name="type"
                  >
                    {Object.keys(PromotionType).map(
                      (promotionType: string, index: number) => (
                        <MenuItem value={promotionType} key={index}>
                          {promotionType}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <ErrorMessage className="error" component="div" name="type" />
                <TextField
                  error={touched.discountAmount && !!errors.discountAmount}
                  className="generic-dialog__field"
                  name="discountAmount"
                  type="string"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Discount Amount"
                  value={values.discountAmount}
                  autoComplete="off"
                  inputProps={{
                    min: '0',
                    step: 0.01,
                    type: 'number',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="discountAmount"
                />
                <TextField
                  error={
                    touched.discountPercentage && !!errors.discountPercentage
                  }
                  className="generic-dialog__field"
                  name="discountPercentage"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Discount Percent"
                  value={values.discountPercentage}
                  autoComplete="off"
                  inputProps={{
                    min: '0',
                    step: 0.01,
                    type: 'number',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="discountPercentage"
                />
                <TextField
                  required={true}
                  error={
                    touched.minPurchaseAmount && !!errors.minPurchaseAmount
                  }
                  className="generic-dialog__field"
                  name="minPurchaseAmount"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Minimum Purchase Amount"
                  value={values.minPurchaseAmount}
                  autoComplete="off"
                  inputProps={{
                    min: 0.0,
                    step: 0.01,
                    type: 'number',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="minPurchaseAmount"
                />
                <TextField
                  required={false}
                  error={touched.maxUsesPerUser && !!errors.maxUsesPerUser}
                  className="generic-dialog__field"
                  name="maxUsesPerUser"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Maximum Orders Per User"
                  helperText="Number of orders a user can make with this promo code. Leave blank for unlimited orders."
                  value={values.maxUsesPerUser || ''}
                  autoComplete="off"
                  inputProps={{
                    min: 0,
                    step: 1,
                    type: 'number',
                  }}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="maxUsesPerUser"
                />
                <div className="generic-dialog__field">
                  Active:
                  <Switch
                    classes={{
                      track: 'custom-colored-switch-track',
                    }}
                    name="active"
                    color="primary"
                    checked={values.active}
                    value={values.active}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="generic-dialog__field">
                  Limit Users:
                  <Switch
                    classes={{
                      track: 'custom-colored-switch-track',
                    }}
                    name="limitUsers"
                    color="primary"
                    checked={values.limitUsers}
                    value={values.limitUsers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="generic-dialog__field">
                  Exclude Alcohol:
                  <Switch
                    classes={{
                      track: 'custom-colored-switch-track',
                    }}
                    name="excludeAlcohol"
                    color="primary"
                    checked={values.excludeAlcohol}
                    value={values.excludeAlcohol}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="button-holder-different-margin">
                  <DialogActions>
                    <Button onClick={close} color="primary" variant="outlined">
                      <div className="icon-title-wrapper">
                        <UndoOutlinedIcon />
                        <span className="icon-title">Cancel</span>
                      </div>
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                      <div className="icon-title-wrapper">
                        <CheckOutlinedIcon />
                        <span className="icon-title">
                          {promoCopy.id ? 'Update' : 'Add'}
                        </span>
                      </div>
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PromoDialog;
