import { Button } from '@material-ui/core';
import FileSaver from 'file-saver';
import React from 'react';
import * as XLSX from 'xlsx';

import './index.scss';

export interface IWorksheet {
  data: any[];
  name: string;
}

export interface IExportToExcelProps {
  buttonText: string;
  filename: string;
  getWorksheets: () => Promise<IWorksheet[]>;
}

export const ExportToExcel = (props: IExportToExcelProps) => {
  const { buttonText, filename, getWorksheets } = props;

  const exportToExcel = async (): Promise<void> => {
    const worksheets = await getWorksheets();

    const wb = XLSX.utils.book_new();
    worksheets.forEach((worksheet: any) => {
      const ws = XLSX.utils.json_to_sheet(worksheet.data);
      XLSX.utils.book_append_sheet(wb, ws, worksheet.name);
    });
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const finalData = new Blob([excelBuffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(finalData, `${filename}.xlsx`);
  };

  return (
    <div className="export-to-excel">
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={exportToExcel}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ExportToExcel;
