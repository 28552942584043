import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { AxiosResponse } from 'axios';
import { Dictionary, get, includes, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import {
  ICreatePartnerEntity,
  IPartnerEntity,
} from '../../models/Partner.model';
import PartnerDialog from './PartnerDialog';
import './PartnerTable.scss';

interface IPartnerTableProps<T> {
  baseEntityId: string;
  entityName: string;
  keys: Dictionary<string>;
  partnerData: T[];
  createPartnerData: (body: ICreatePartnerEntity) => Promise<AxiosResponse<T>>;
  deletePartnerData: (id: string) => Promise<AxiosResponse<void>>;
  refreshData: () => void;
}

export default function PartnerTable<T extends IPartnerEntity>({
  baseEntityId,
  entityName,
  keys,
  partnerData,
  deletePartnerData,
  createPartnerData,
  refreshData,
}: IPartnerTableProps<T>) {
  const { enqueueSnackbar } = useSnackbar();
  const [showAddDialog, setShowAddDialog] = useState(false);

  const mapData = (data: T[]) => {
    if (isEmpty(data)) {
      return [];
    }
    const getKeyByValue = <U extends keyof T>(key: U, obj: T) => obj[key];

    const entityKeys = Object.keys(data[0]);
    const displayKeys = Object.keys(keys);
    const filteredKeys = entityKeys.filter(key => includes(displayKeys, key));

    if (displayKeys.length !== filteredKeys.length) {
      return [];
    }
    return data.map((entry: T) => {
      const partnerEntityObj = {
        id: entry.id,
      };
      filteredKeys.forEach(key => {
        Object.assign(partnerEntityObj, {
          ...partnerEntityObj,
          [key]: getKeyByValue(key as keyof T, entry),
        });
      });
      return partnerEntityObj;
    });
  };

  const savePartnerEntity = async (values: ICreatePartnerEntity) => {
    try {
      await createPartnerData(values);
      refreshData();
      setShowAddDialog(false);
    } catch (error) {
      enqueueSnackbar(`There was an error saving this ${entityName}`, {
        variant: 'error',
      });
    }
  };

  const deletePartnerEntity = async (id: string) => {
    try {
      await deletePartnerData(id);
      refreshData();
    } catch (error) {
      enqueueSnackbar(`There was an error deleting this ${entityName}`, {
        variant: 'error',
      });
    }
  };

  return (
    <div className="partners">
      <div className="header-container">
        <Typography component="h6" variant="h6">
          {entityName}s
        </Typography>
        <Button
          size="medium"
          color="primary"
          aria-label="add"
          classes={{
            root: 'add-event-button',
          }}
          onClick={() => setShowAddDialog(true)}
        >
          <div className="icon-title-wrapper">
            <AddOutlinedIcon />
            <span className="icon-title">Add {entityName}</span>
          </div>
        </Button>
      </div>
      <div className="partners-table-container">
        <Table>
          <TableHead>
            <TableRow
              classes={{
                root: 'table-row',
              }}
            >
              {Object.values(keys).map(header => (
                <TableCell
                  key={header}
                  classes={{ root: 'table-cell table-cell-head' }}
                >
                  {header}
                </TableCell>
              ))}
              <TableCell
                classes={{
                  root: 'table cell table-cell-head',
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapData(partnerData).map((data, index) => (
              <TableRow key={index} className="partners-table-row">
                {Object.keys(keys).map(key => (
                  <TableCell key={key} className="table-cell">
                    {get(data, key)}
                  </TableCell>
                ))}
                <TableCell classes={{ root: 'table-cell-action' }}>
                  <Button
                    size="small"
                    value="delete"
                    onClick={() => deletePartnerEntity(data.id)}
                    classes={{
                      root: 'partners-table-button',
                    }}
                  >
                    <div className="icon-title-wrapper">
                      <DeleteOutlinedIcon />
                      <span className="icon-title">Delete</span>
                    </div>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {showAddDialog && (
        <PartnerDialog
          baseEntityId={baseEntityId}
          createPartnerEntity={savePartnerEntity}
          entityName={entityName}
          keys={keys}
          onClose={() => setShowAddDialog(false)}
        />
      )}
    </div>
  );
}
