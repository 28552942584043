import React, { useEffect, useState } from 'react';
import ProductService from '../../services/Product/Product.service';
import InventoryService from '../../services/Inventory/Inventory.service';
import { useAppContext } from '../../config/AppContext';
import meal from '../../images/meal.jpg';
import './Pos.scss';
// import axios from 'axios';
export default function Products({
  categoryId,
  addToCart,
  fetchProducts,
  handleFetchProductsFalse,
}: any) {
  const {
    selectedStoreId,
    // cartItems,
    // setCartItems
  } = useAppContext();

  const [products, setProducts] = useState<any>([]);
  // const [cartId, setCartId] = useState<string>('');
  const fetchStoreProductsByCategory = async (categoryId: string) => {
    const rowsPerPage = 100;
    const page = 0;
    const store = selectedStoreId!;

    try {
      const response: any = await ProductService.getStoreProducts(
        store,
        rowsPerPage,
        page + 1,
        categoryId,
      );
      console.log(response.data.items, 'Products');
      // out of stock Products
      // const { data } = await InventoryService.getOutOfStockProducts(
      //   store,
      //   categoryId,
      // );
      // console.log(data, 'outOfStockProducts');
      // //pop up those products that are not in stock
      // const filteredData = response.data.items.filter((item: any) => {
      //   return !data.find(
      //     (existingItem: any) => existingItem.product_id === item.id,
      //   );
      // });
      // console.log(filteredData, 'filtered data');
      // 
      const categoryMeals = response.data.items
        ?.filter((product: any) => product.active === true)
        .map((product: any, index: any) => ({
          id: product.id,
          key: index,
          description: product.description,
          name: product.name,
          imageUrl: product.imageUrl
            ? product.imageUrl
            : product.globalProduct && product.globalProduct.imageUrl,
          price: product.price,
          value: (index + 1).toString(),
        }));
      setProducts(categoryMeals);
      handleFetchProductsFalse();
      // console.log('products are fetched successfully', response.data)
    } catch (error) {
      // console.log(error);
    }
  };
  //  const handleAddToCart = (item: any, ind: number) => {
  //  console.log(cartItems, 'cartitemsss')
  //  console.log(item, 'itemmmm')
  //  setCartItems([...cartItems, item])
  //  if (cartItems.length > 0) {
  //  const payload = {
  //  productId: item.id,
  //  quantity: 1,
  //  }
  //  setCartItems([])
  //  axios.post(`http://localhost:3000/api/v2/cart/${cartId}/items`, payload)
  //       .then((response: any) => {
  //         console.log('addded line item success', response)
  //         setCartItems(response?.data.lineItems)
  //       })
  //       .catch((error) => {
  //         console.log('Error while addin g item to cart', error)
  //       })
  //   } else {
  //     const payload = {
  //       userId: "ec079031-f25e-4d6d-9b0d-5d51cfeb959e",
  //       storeId: "0c5627c6-62be-4b0d-9aa2-4fc10e68dd76",
  //       deliveryLocationId: null,
  //       eventId: null,
  //       lineItem: { productId: item.id, "quantity": 1 },
  //       orderType: "PICKUP",
  //       scheduledTime: "2023-04-27T10:00:00.000Z",
  //       gameDayOrder: true
  //     }
  //     axios.post('http://localhost:3000/api/v2/cart', payload)
  //     .then((response) => {
  //       console.log('add to cart item success', response)
  //       setCartItems(response.data.lineItems)
  //       setCartId(response.data.id)
  //     })
  //     .catch((error) => {
  //       console.log('Error while addin g item to cart', error)
  //     })
  //   }
  // };

  useEffect(() => {
    fetchStoreProductsByCategory(categoryId);
  }, [categoryId, fetchProducts]);
  return (
    <>
      <div
        className="outer_container"
        style={{ height: '400px', overflowY: 'scroll' }}
      >
        <div
          className="_left_container"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            paddingRight: '20px',
          }}
        >
          {products &&
            products?.map((item: any, ind: any) => {
              return (
                <div
                  onClick={() => addToCart(item, ind)}
                  key={ind}
                  className="rectangle-812"
                  style={{ margin: '10px' }}
                >
                  <div style={{ width: '50%', zIndex: '1000' }}>
                    <p
                      style={{ paddingBottom: '10px' }}
                      className="meal_card_heading"
                    >
                      {item.name}
                    </p>
                    {/* <p className="meal_description">{item.description}</p>s */}
                    <p className="meal_price">$ {item.price} </p>
                  </div>
                  <div className="_image">
                    <img
                      src={item.imageUrl}
                      width="120"
                      style={{
                        cursor: 'pointer',
                        objectFit: 'contain',
                        maxHeight: '70px',
                      }}
                    />
                    <button
                      style={{ display: 'none' }}
                      className="_cart_item_add"
                      // onClick={() => addToCart(item, ind)}
                    >
                      +
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
