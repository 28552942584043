export interface IVenueLocation {
  categoryName: string;
  id: string;
  isCustomInput: boolean;
  isDeliveryLocation: boolean;
  locations: IVenueLocation[];
  name: string;
  parentId: string | null;
  type: VenueLocationType;
  venueId: string;
}

export interface IStoreVenueLocation {
  id: string;
  includeNested: boolean;
  storeId: string;
  venueLocationId: string;
  venueLocation: IVenueLocation;
}

export interface ICreateLocationDto {
  categoryName?: string;
  isCustomInput: boolean;
  name: string;
  parentId?: string;
  type: VenueLocationType;
  venueId: string;
}

export interface IUpdateVenueLocationDto extends Partial<ICreateLocationDto> {}

export enum VenueLocationType {
  REGULAR = 'REGULAR',
  SUITE = 'SUITE',
}

// DEPRECATED: This is for legacy purposes only
export interface IVenueLocationType {
  id: string;
  name: string;
  validValues: string[];
}
