import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { noDataAvailable } from '../components/NoDataAvailable';
import { TabPanel } from '../components/TabPanel';
import { useAppContext } from '../config/AppContext';
import {
  IVenueDashboardStore,
  IVenueDashboardTop,
  IVenueDashboardTopCategory,
  IVenueDashboardTopProduct,
} from '../routes/Reporting/ReportingConfig';
import { getCurrencyPrefix } from '../services/Util/Util.service';

const Overview = ({
  categories,
  products,
  stores,
}: IVenueDashboardTop): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);
  const { venue } = useAppContext();

  return (
    <Paper classes={{ root: 'graph-container-orders-overview' }}>
      <Grid container={true} className="overview-heading">
        <Typography variant="h6">Overview</Typography>
      </Grid>
      <Tabs
        value={tabValue}
        onChange={(_, value) => setTabValue(value)}
        textColor="primary"
        classes={{
          indicator: 'venue-product-summary__tabs__selected',
          root: 'venue-product-summary__tabs',
        }}
      >
        <Tab
          label="Top Selling"
          classes={{
            root: 'venue-product-summary__tab',
            selected: 'selected',
          }}
        />
        <Tab
          label="Top Categories"
          classes={{
            root: 'venue-product-summary__tab',
            selected: 'selected',
          }}
        />
        <Tab
          label="Top Concessions Stores"
          classes={{
            root: 'venue-product-summary__tab',
            selected: 'selected',
          }}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Table className="reporting__table">
          <TableHead>
            <TableRow>
              {['Product Name', 'Sold'].map(th => (
                <TableCell
                  key={th}
                  classes={{
                    head: 'table-header',
                  }}
                >
                  {th}
                </TableCell>
              ))}
            </TableRow>
            {products.length < 1
              ? noDataAvailable(3)
              : products.map(
                  (product: IVenueDashboardTopProduct, index: number) =>
                    product.quantity > 0 && (
                      <TableRow key={index}>
                        <TableCell className="table-cell table-cell-no-data">
                          {product.name}
                        </TableCell>
                        <TableCell className="table-cell table-cell-no-data">
                          {product.quantity}
                        </TableCell>
                      </TableRow>
                    ),
                )}
          </TableHead>
          <TableBody />
        </Table>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Table className="reporting__table">
          <TableHead>
            <TableRow>
              {['Name', 'Sold'].map(th => (
                <TableCell
                  key={th}
                  classes={{
                    head: 'table-header',
                  }}
                >
                  {th}
                </TableCell>
              ))}
            </TableRow>
            {categories.length < 1
              ? noDataAvailable(3)
              : categories.map(
                  (category: IVenueDashboardTopCategory, index: number) => (
                    <TableRow key={index}>
                      <TableCell className="table-cell table-cell-no-data">
                        {category.name}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {category.quantity}
                      </TableCell>
                    </TableRow>
                  ),
                )}
          </TableHead>
          <TableBody />
        </Table>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Table className="reporting__table">
          <TableHead>
            <TableRow>
              {['Name', ' Products Sold', 'Total Amount'].map(th => (
                <TableCell
                  key={th}
                  classes={{
                    head: 'table-header',
                  }}
                >
                  {th}
                </TableCell>
              ))}
            </TableRow>
            {stores.length < 1
              ? noDataAvailable(3)
              : stores.map((store: IVenueDashboardStore, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="table-cell table-cell-no-data">
                      {store.name}
                    </TableCell>
                    <TableCell className="table-cell table-cell-no-data">
                      {store.items}
                    </TableCell>
                    <TableCell className="table-cell table-cell-no-data">
                      <NumberFormat
                        value={store.totalAmount}
                        decimalScale={2}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={getCurrencyPrefix(venue?.currency)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableHead>
          <TableBody />
        </Table>
      </TabPanel>
      <div className="display-text">
        {'* Displaying only top 10 in each category'}
      </div>
    </Paper>
  );
};

export default Overview;
