import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { getHeadersObject } from '../../../services/Util/Util.service';
import { ExportCSV } from '../ExportCSV';
import ExportToExcel, { IWorksheet } from '../../../components/ExportToExcel';
import { memberReportSummaryHeaders } from '../ReportingConfig';

interface IProps {
  onChangeStore: (storeId: string) => void;
  selectedStoreId: string | undefined;
  memberReportReportData: any[];
}

export function MemberReport({
  onChangeStore,
  memberReportReportData,
}: IProps) {
  const [csvData, setCsvData] = useState([{}]);;

  const setCsv = (objectValus: any) => {
    const summary: Array<{}> = [];
    if (!objectValus.length) {
      summary.push(getHeadersObject(memberReportSummaryHeaders));
    } else {
      objectValus.map((objectKey: any, ii: number) => {
        const obj = {
          'Member Id': objectKey.memberId,
          Area: objectKey.area,
          Location: objectKey.location,
          Cover: objectKey.cover,
          'Order Number': objectKey.orderNo,
          Item: objectKey.name,
          description: objectKey.instructions,
          Quantity: objectKey.quantity,
          Price: objectKey.productPrice,
          Tax: objectKey.taxAmount,
          Total: objectKey.total,
        };
        summary.push(obj);
      });
    }
    setCsvData(summary);
  };
  const getWorksheets = async (): Promise<IWorksheet[]> => {
    /* tslint:disable:object-literal-sort-keys */
    const worksheets: IWorksheet[] = [
      {
        name: 'user-overview',
        data: memberReportReportData.map((record: any) => ({
          'Member Id': record.memberId,
          Area: record.area,
          Location: record.location,
          Cover: record.cover,
          'Order Number': record.orderNo,
          Item: record.name,
          description: record.instructions,
          Quantity: record.quantity,
          Price: record.productPrice,
          Tax: record.taxAmount,
          Total: record.total,
        })),
      },
    ];
    /* tslint:enable:object-literal-sort-keys */

    return worksheets;
  };

  useEffect(() => {
    setCsv(memberReportReportData)
  }, [memberReportReportData]);

  const handleStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    onChangeStore(newSelectedStoreId);
  };

  return (
    <>
      <div className="records-container">
      </div>
      <ExportToExcel buttonText="Export to Excel" filename={'Member-Report-Summary'} getWorksheets={getWorksheets} />
      <Table className="reporting__table">
        <TableHead>
          <TableRow>
            {memberReportSummaryHeaders.map(text => (
              <TableCell
                key={text}
                classes={{
                  head: 'table-header',
                }}
              >
                {text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!memberReportReportData.length
            ? noDataAvailable(10)
            : memberReportReportData.map((data: any, index: number) => (
                <TableRow key={index}>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.memberId}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.area}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.location}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data?.cover}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.orderNo}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.name}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.instructions}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.quantity}{' '}
                  </TableCell>
                  {/* <TableCell className="table-cell table-cell-no-data">
                    {`${data.productPrice.toFixed(2)}`}{' '}
                  </TableCell>
                  
                  <TableCell className="table-cell table-cell-no-data">
                    {`${data.taxAmount.toFixed(2)}`} 
                  </TableCell> */}
                  <TableCell className="table-cell table-cell-no-data">
                    {`${data.productPrice}`}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {`${data.taxAmount}`}{' '}
                  </TableCell>
                  <TableCell className="table-cell table-cell-no-data">
                    {data.total}{' '}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </>
  );
}
