import {
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ErrorMessage, Formik } from 'formik';
import { chain, isEmpty, keyBy, sumBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useAppContext } from '../../../config/AppContext';
import ICategory from '../../../models/Category.model';
import IProduct, {
  AvailabilityType,
  humanReadableTaxRateTypes,
  IGlobalProduct,
  IProductTaxes,
  ISubCategory,
  ISubProduct,
  TaxRateType,
} from '../../../models/Product.model';
import { IFulfillmentCenter, IStore } from '../../../models/Store.model';
import { ITaxRate } from '../../../models/TaxRate.model';
import ProductService from '../../../services/Product/Product.service';
import { VALID_CATEGORY_TYPES } from '../Categories/Category.config';
import AddCustomImage from './AddCustomImage';
import ComboSelectItems from './Combos/ComboSelectItems';
import SelectGlobalProduct from './SelectGlobalProduct';
import { SelectProductImage } from './SelectProductImage';
import InventoryService from '../../../services/Inventory/Inventory.service';
import ProductTagService from '../../../services/ProductTag/ProductTag.service';
import { IProductTag } from '../../../models/ProductTag.model';
interface IProductImages {
  imageFile: any;
  isCustomImage: boolean;
}

export interface ISubCategoryMap {
  [categoryId: string]: ISubCategoryMapItem;
}

interface ISubCategoryMapItem {
  categoryId: string;
  multiselect: boolean;
  required: boolean;
  selectionLimit: number;
  subProductMap: ISubProductMap;
}

export interface ISubProductMap {
  [productId: string]: ISubProduct;
}

export interface ICategoryProducts {
  [categoryId: string]: IProduct[];
}

interface IAddEditProductFormProps {
  allowFreeProducts: boolean;
  currencyPrefix: string;
  categories: ICategory[];
  fulfillmentCenters: IFulfillmentCenter[];
  onClose: () => void;
  onSubmit: () => void;
  product: IProduct;
  store: IStore;
  taxRates: ITaxRate[];
  tags: IProductTag[];
}

const convertSubCategoriesToSubCategoryMap = (
  subCategories: ISubCategory[],
): ISubCategoryMap =>
  keyBy(
    subCategories.map((subCategory: ISubCategory) => ({
      ...subCategory,
      subProductMap: keyBy(
        subCategory.subProducts,
        (sp: ISubProduct) => sp.productId,
      ),
    })),
    (sc: ISubCategory) => sc.categoryId,
  );

const AddEditProductForm = ({
  allowFreeProducts,
  currencyPrefix,
  categories,
  fulfillmentCenters,
  onClose,
  onSubmit,
  product,
  store,
  taxRates,
  tags,
}: IAddEditProductFormProps) => {
  const { setShowSpinner, venue } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [viewGlobalProducts, setViewGlobalProducts] = useState<boolean>(false);
  const [viewAddCustomImage, setViewAddCustomImage] = useState<boolean>(false);

  const [subCategoryMap, setSubCategoryMap] = useState<ISubCategoryMap>(
    convertSubCategoriesToSubCategoryMap(product.subCategories),
  );
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [categoryProducts, setCategoryProducts] = useState<ICategoryProducts>(
    {},
  );
  const [categoryId, setCategoryId] = useState<string>(product.categoryId);
  const [tagId, setTagId] = useState<string>(
    product?.tagId ? product.tagId : '',
  );
  const [stockDisable, setStockDisable] = useState<boolean>(false);
  const [selectedInventory, setSelectedInventory] = useState<string>();

  const [availabilityType, setAvailabilityType] = useState<string>();
  const [testAvailabilityType, setTestAvailabilityType] = useState<string[]>(
    product.testAvailabilityType,
  );

  const [appliedTaxRates, setAppliedTaxRates] = useState<ITaxRate[]>([]);
  const [isAlcohol, setIsAlcohol] = useState<boolean>(product.alcohol);

  const productTaxes: IProductTaxes = useMemo(() => {
    if (!isEmpty(appliedTaxRates)) {
      return {
        effectiveTaxRate: sumBy(appliedTaxRates, tr => tr.percent),
        taxRateType: TaxRateType.CUSTOM,
        taxRates: appliedTaxRates,
      };
    }

    if (isAlcohol) {
      return store.liquorTaxPercent
        ? {
            effectiveTaxRate: store.liquorTaxPercent,
            taxRateType: TaxRateType.STORE_LIQUOR,
            taxRates: [],
          }
        : {
            effectiveTaxRate: venue?.liquorTaxPercent ?? 0,
            taxRateType: TaxRateType.VENUE_LIQUOR,
            taxRates: [],
          };
    }

    return store.salesTaxPercent
      ? {
          effectiveTaxRate: store.salesTaxPercent,
          taxRateType: TaxRateType.STORE_SALES,
          taxRates: [],
        }
      : {
          effectiveTaxRate: venue?.salesTaxPercent ?? 0,
          taxRateType: TaxRateType.VENUE_SALES,
          taxRates: [],
        };
  }, [appliedTaxRates, isAlcohol, store, venue]);

  const effectiveTaxRateString: string = useMemo(
    () =>
      `${productTaxes.effectiveTaxRate}% (${
        humanReadableTaxRateTypes[productTaxes.taxRateType]
      })`,
    [productTaxes],
  );

  const category = categories.find(
    (c: ICategory) => c.id === categoryId,
  ) as ICategory;
  const isOptionsCategory = category.type === VALID_CATEGORY_TYPES.OPTIONS;

  useEffect(() => {
    const getAllProducts = async () => {
      setShowSpinner(true);

      try {
        const {
          data: { items: allStoreProducts },
        } = await ProductService.getStoreProducts(store.id, 5000);

        setAllProducts(allStoreProducts);
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
        onClose();
      }
      setShowSpinner(false);
    };
    getAllProducts();
  }, [product, store]);

  useEffect(() => {
    const allCategoryProducts = chain(allProducts)
      .filter(
        (p: IProduct) =>
          p.id !== product.id &&
          [AvailabilityType.ALL, availabilityType].includes(p.availabilityType),
      )
      .groupBy((p: IProduct) => p.categoryId)
      .value() as ICategoryProducts;

    setCategoryProducts(allCategoryProducts);
  }, [availabilityType, allProducts, product]);
  useEffect(() => {
    if (product.id) {
      (async () => {
        const { data } = await InventoryService.getProductInventory(product.id);
        if (data) {
          setStockDisable(true);
          setSelectedInventory(data.id);
        } else setStockDisable(false);
      })();
    }
  }, [product]);

  useEffect(() => {
    (async () => {
      setShowSpinner(true);
      try {
        const { data } = await ProductService.getProductTaxes(product.id);
        setAppliedTaxRates(
          taxRates.filter(tr => data.taxRates.find(ptr => ptr.id === tr.id)),
        );
      } catch (error) {
        // No Product Taxes found, which is fine, it is already initialized to Venue/Store Tax Rate
      }
      setShowSpinner(false);
    })();
  }, [product, store]);

  // useEffect(() => {
  //   if (!product?.id) {
  //     setTestAvailabilityType(['POS','DELIVERY','PICKUP']);
  //   }
  // }, [product]);

  const buildComboJson = (): ISubCategory[] => {
    if (isOptionsCategory) {
      return [];
    }
    console.log(subCategoryMap, 'subCategoryMap');
    const subCategories = Object.values(subCategoryMap)
      .map((sc: ISubCategoryMapItem): ISubCategory | null => {
        console.log(sc, 'sc');
        const { subProductMap, ...subCategory } = sc;
        console.log(subProductMap, 'subProductMap');
        const subProducts = Object.values(subProductMap);
        if (subProducts.length === 0) {
          return null;
        }

        return {
          ...subCategory,
          subProducts,
        };
      })
      .filter((sc: ISubCategory | null): sc is ISubCategory => !!sc);

    return subCategories;
  };

  const onAddEditProduct = async (values: IProduct & IProductImages) => {
    const {
      id: productId,
      isCustomImage,
      imageFile,
      imageUrl,
      ...requestBody
    } = values;
    requestBody.alcohol = isAlcohol;
    requestBody.tagId = tagId
    requestBody.subCategories = buildComboJson();
    requestBody.stock = requestBody.stock || 0;
    if (!isOptionsCategory) {
      if (
        requestBody.testAvailabilityType.includes('PICKUP') &&
        requestBody.testAvailabilityType.includes('DELIVERY')
      ) {
        requestBody.availabilityType = 'ALL';
      } else if (requestBody.testAvailabilityType.includes('PICKUP')) {
        requestBody.availabilityType = 'PICKUP';
      } else if (requestBody.testAvailabilityType.includes('DELIVERY')) {
        requestBody.availabilityType = 'DELIVERY';
      } else if (
        !requestBody.testAvailabilityType.includes('PICKUP') &&
        !requestBody.testAvailabilityType.includes('DELIVERY')
      ) {
        requestBody.availabilityType = 'NONE';
      }
      if (requestBody.testAvailabilityType.includes('POS')) {
        requestBody.pos = true;
      } else if (!requestBody.testAvailabilityType.includes('POS')) {
        requestBody.pos = false;
      }
    } else {
      requestBody.availabilityType = 'ALL';
    }

    const taxRateIds = (appliedTaxRates || []).map((tr: ITaxRate) => tr.id);

    setShowSpinner(true);
    try {
      const formData = new FormData();

      if ((isCustomImage && imageUrl && imageFile) || !imageUrl) {
        formData.append('imageFile', imageFile);
      }

      if (product.id) {
        await ProductService.updateProduct(product.id, {
          ...requestBody,
          storeId: store.id,
        });

        if (isCustomImage && imageFile) {
          await ProductService.updateProductImage(product.id, formData);
        }

        await ProductService.updateProductTaxes(product.id, taxRateIds);

        enqueueSnackbar('Product has been successfully updated', {
          variant: 'success',
        });
      } else {
        const { data } = await ProductService.createProduct({
          ...requestBody,
          storeId: store.id,
        });

        if (isCustomImage && imageFile) {
          await ProductService.updateProductImage(data.id, formData);
        }

        if (taxRateIds.length > 0) {
          await ProductService.updateProductTaxes(data.id, taxRateIds);
        }

        enqueueSnackbar('Product has been successfully created', {
          variant: 'success',
        });
      }
      setShowSpinner(false);
      return onSubmit();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);

      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };
  const productValidationSchema = Yup.object().shape({
    testAvailabilityType: isOptionsCategory
      ? Yup.array()
      : Yup.array()
          .of(Yup.string())
          .min(1, 'At least one availability type is required.')
          .required('Availability type is required.'),
    categoryId: Yup.string().required('Category is required.'),
    fulfillmentCenterId: Yup.string().required(
      'Fulfillment Center is required.',
    ),
    name: Yup.string().required('Product name is required.'),
    inventoryCount: Yup.number()
      .typeError('Inventory count must be a number')
      .when('countAsInventory', {
        is: true,
        then: Yup.number()
          .required('Inventory count is required')
          .moreThan(0, 'Inventory count must be greater than 0'),
      }),
    price:
      allowFreeProducts || isOptionsCategory
        ? Yup.number()
            .required('Price is required.')
            .min(0, 'Price cannot be negative.')
        : Yup.number()
            .required('Price is required.')
            .positive('This venue does not allow free products.'),
    priority: Yup.number().positive('Priority must be greater than zero.'),
    stock: Yup.number()
      .integer()
      .min(0, 'Stock cannot be negative.'),
  });

  const comboSelectItems = useMemo(
    () => (
      <ComboSelectItems
        categories={categories}
        categoryProducts={categoryProducts}
        setSubCategoryMap={setSubCategoryMap}
        subCategoryMap={subCategoryMap}
      />
    ),
    [categories, categoryProducts, setSubCategoryMap, subCategoryMap],
  );

  return (
    <Grid
      container={true}
      className="menu__add-edit-container"
      alignContent="center"
      direction="column"
    >
      <Formik<IProduct & { imageFile: any; isCustomImage: boolean }>
        initialValues={{
          ...product,
          fulfillmentCenterId: product.fulfillmentCenterId || store.id,
          imageFile: null,
          imageUrl:
            product.imageUrl ||
            (product.globalProduct && product.globalProduct.imageUrl) ||
            '',
          isCustomImage: !!product.imageUrl,
        }}
        onSubmit={onAddEditProduct}
        validationSchema={productValidationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <Fragment>
            <form className="menu__add-edit-form combo" onSubmit={handleSubmit}>
              {!isOptionsCategory && (
                <SelectProductImage
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  errors={errors}
                  onOpenAddCustomImage={() => setViewAddCustomImage(true)}
                  onOpenGlobalProducts={() => setViewGlobalProducts(true)}
                />
              )}

              {values.id && (
                <Grid
                  className="menu-add-edit__row combo"
                  alignItems="center"
                  container={true}
                >
                  <Grid item={true} xs={3}>
                    <InputLabel
                      className="menu-add-edit__form-field-label"
                      error={touched.priority && !!errors.priority}
                    >
                      Priority
                    </InputLabel>
                  </Grid>
                  <Grid
                    className="menu-add-edit__form-field-container"
                    container={true}
                    direction="column"
                  >
                    <TextField
                      error={touched.priority && !!errors.priority}
                      className="menu-add-edit__form-field"
                      name="priority"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.priority}
                      autoComplete="off"
                      inputProps={{
                        min: '0',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      render={(message: string) => (
                        <span className="menu-add-edit__form-field-error">
                          {message}
                        </span>
                      )}
                      name="priority"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                className="menu-add-edit__row combo"
                alignItems="center"
                container={true}
              >
                <Grid item={true} xs={3}>
                  <InputLabel
                    className="menu-add-edit__form-field-label"
                    error={touched.name && !!errors.name}
                    required={true}
                  >
                    Name
                  </InputLabel>
                </Grid>
                <Grid
                  className="menu-add-edit__form-field-container"
                  direction="column"
                  alignItems="flex-start"
                  container={true}
                >
                  <TextField
                    className="menu-add-edit__form-field"
                    error={touched.name && !!errors.name}
                    required={true}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    render={(message: string) => (
                      <span className="menu-add-edit__form-field-error">
                        {message}
                      </span>
                    )}
                    name="name"
                  />
                </Grid>
              </Grid>
              {!isOptionsCategory && (
                <Grid
                  className="menu-add-edit__row combo"
                  alignItems="center"
                  container={true}
                >
                  <Grid item={true} xs={3}>
                    <InputLabel className="menu-add-edit__form-field-label">
                      Description
                    </InputLabel>
                  </Grid>
                  <Grid
                    container={true}
                    className="menu-add-edit__form-field-container"
                  >
                    <TextField
                      className="menu-add-edit__form-field"
                      error={touched.description && !!errors.description}
                      multiline={true}
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container={true}
                className="menu-add-edit__row combo"
                alignItems="center"
              >
                <Grid item={true} xs={3}>
                  <InputLabel
                    className="menu-add-edit__form-field-label"
                    error={touched.categoryId && !!errors.categoryId}
                    required={true}
                  >
                    Category
                  </InputLabel>
                </Grid>
                <Grid
                  className="menu-add-edit__form-field-container"
                  container={true}
                  direction="column"
                >
                  <Select
                    className="menu-add-edit__category-select"
                    value={values.categoryId}
                    onChange={e => {
                      const newCategoryId = e.target.value as string;
                      setFieldValue('categoryId', newCategoryId);
                      setCategoryId(newCategoryId);
                    }}
                  >
                    {categories.map((c: ICategory) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    render={(message: string) => (
                      <span className="menu-add-edit__form-field-error">
                        {message}
                      </span>
                    )}
                    name="categoryId"
                  />
                </Grid>
                {/* <Grid
                  className="menu-add-edit__form-field-container"
                  container={true}
                  direction="column"
                >
                  <Select
                    className="menu-add-edit__category-select"
                    value={values.tags}
                    onChange={e => {
                      const newCategoryId = e.target.value as string;
                      setFieldValue('categoryId', newCategoryId);
                      setCategoryId(newCategoryId);
                    }}
                  >
                    {categories.map((c: ICategory) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    render={(message: string) => (
                      <span className="menu-add-edit__form-field-error">
                        {message}
                      </span>
                    )}
                    name="categoryId"
                  />
                </Grid> */}
              </Grid>
              <Grid
                container={true}
                className="menu-add-edit__row combo"
                alignItems="center"
              >
                <Grid item={true} xs={3}>
                  <InputLabel
                    className="menu-add-edit__form-field-label"
                    error={touched.tagId && !!errors.tagId}
                    required={false}
                  >
                    Tag
                  </InputLabel>
                </Grid>
                <Grid
                  className="menu-add-edit__form-field-container"
                  container={true}
                  direction="column"
                >
                  <Select
                    className="menu-add-edit__category-select"
                    value={values.tagId}
                    onChange={e => {
                      const newTagId = e.target.value as string;
                      setFieldValue('tagId', newTagId);
                      setTagId(newTagId);
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {tags.map((c: IProductTag) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    render={(message: string) => (
                      <span className="menu-add-edit__form-field-error">
                        {message}
                      </span>
                    )}
                    name="tagId"
                  />
                </Grid>
              </Grid>
              <Grid
                className="menu-add-edit__row combo"
                alignItems="center"
                container={true}
              >
                <Grid item={true} xs={3}>
                  <InputLabel
                    className="menu-add-edit__form-field-label"
                    error={
                      touched.countAsInventory && !!errors.countAsInventory
                    }
                    required={true}
                  >
                    Count As Inventory
                  </InputLabel>
                </Grid>
                <Switch
                  classes={{
                    track: 'custom-colored-switch-track',
                  }}
                  checked={values.countAsInventory}
                  color="primary"
                  name="countAsInventory"
                  onChange={handleChange}
                  size="medium"
                  value={values.countAsInventory}
                />
                {stockDisable && values.countAsInventory && venue && (
                  <a
                    href={`/home/venue-details/${venue.id}/inventory/adjust_inventory/${selectedInventory}`}
                    className="adjust-inventory-link"
                    style={{ marginLeft: 'auto' }}
                  >
                    Adjust Inventory
                  </a>
                )}
              </Grid>
              {values.countAsInventory && (
                <Grid
                  className="menu-add-edit__row combo"
                  alignItems="center"
                  container={true}
                >
                  <Grid item={true} xs={3}>
                    <InputLabel
                      className="menu-add-edit__form-field-label"
                      error={touched.inventoryCount && !!errors.inventoryCount}
                      required={true}
                    >
                      Inventory Count
                    </InputLabel>
                  </Grid>
                  <Grid
                    className="menu-add-edit__form-field-container"
                    direction="column"
                    alignItems="flex-start"
                    container={true}
                  >
                    <TextField
                      className="menu-add-edit__form-field"
                      error={touched.inventoryCount && !!errors.inventoryCount}
                      required={values.countAsInventory ? true : false}
                      name="inventoryCount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={stockDisable}
                      value={values.inventoryCount}
                      autoComplete="off"
                      type="number"
                      inputMode="numeric"
                      inputProps={{ min: 0 }}
                    />
                    <ErrorMessage
                      render={(message: string) => (
                        <span className="menu-add-edit__form-field-error">
                          {message}
                        </span>
                      )}
                      name="name"
                    />
                  </Grid>
                </Grid>
              )}

              {isOptionsCategory && (
                <Grid
                  container={true}
                  className="menu-add-edit__row combo"
                  alignItems="center"
                >
                  <Typography
                    align="center"
                    className="menu-add-edit__disclaimer"
                  >
                    {`* Prices for "${category.name}" can be added when building a combo product`}
                  </Typography>
                </Grid>
              )}
              {!isOptionsCategory && (
                <Grid
                  container={true}
                  className="menu-add-edit__row combo"
                  alignItems="center"
                >
                  <Grid item={true} xs={3}>
                    <InputLabel
                      className="menu-add-edit__form-field-label"
                      error={
                        touched.testAvailabilityType &&
                        !!errors.testAvailabilityType
                      }
                      required={true}
                    >
                      Availability Type
                    </InputLabel>
                  </Grid>
                  <Grid
                    className="menu-add-edit__form-field-container"
                    container={true}
                    direction="column"
                  >
                    <Select
                      className="menu-add-edit__product-type-select"
                      value={values.testAvailabilityType}
                      multiple={true}
                      onChange={(e: any) => {
                        setFieldValue('testAvailabilityType', e.target.value);
                        setTestAvailabilityType(e.target.value);
                      }}
                      renderValue={(selected: any) => selected.join(', ')}
                    >
                      {Object.entries(AvailabilityType).map(
                        ([type, name]: [string, AvailabilityType]) =>
                          name !== 'ALL' &&
                          name !== 'NONE' && (
                            <MenuItem
                              key={`menu-add-edit__select-value__${type}`}
                              value={type}
                            >
                              <Checkbox
                                checked={values.testAvailabilityType.includes(
                                  type,
                                )}
                              />
                              {name}
                            </MenuItem>
                          ),
                      )}
                    </Select>
                    <ErrorMessage
                      render={(message: string) => (
                        <span className="menu-add-edit__form-field-error">
                          {message}
                        </span>
                      )}
                      name="availabilityType"
                    />
                  </Grid>
                </Grid>
              )}
              {!isOptionsCategory && (
                <Grid
                  container={true}
                  className="menu-add-edit__row combo"
                  alignItems="center"
                >
                  <Grid item={true} xs={3}>
                    <InputLabel
                      className="menu-add-edit__form-field-label"
                      error={
                        touched.fulfillmentCenterId &&
                        !!errors.fulfillmentCenterId
                      }
                      required={true}
                    >
                      Fulfillment Center
                    </InputLabel>
                  </Grid>
                  {fulfillmentCenters.length > 0 && (
                    <Grid
                      className="menu-add-edit__form-field-container"
                      container={true}
                      direction="column"
                    >
                      <Select
                        disabled={fulfillmentCenters.length <= 1}
                        displayEmpty={true}
                        className="menu-add-edit__product-type-select"
                        value={values.fulfillmentCenterId}
                        onChange={e => {
                          setFieldValue('fulfillmentCenterId', e.target.value);
                        }}
                      >
                        {fulfillmentCenters.map(
                          (fc: IFulfillmentCenter, index: number) => (
                            <MenuItem key={index} value={fc.id}>
                              {fc.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                      <ErrorMessage
                        render={(message: string) => (
                          <span className="menu-add-edit__form-field-error">
                            {message}
                          </span>
                        )}
                        name="fulfillmentCenterId"
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {/* We don't use stock at all right now, when we re-implement the inventory feature, we can re-enable this
                <Grid
                className="menu-add-edit__row combo"
                alignItems="center"
                container={true}
              >
                <Grid item={true} xs={3}>
                  <InputLabel
                    className="menu-add-edit__form-field-label"
                    error={touched.stock && !!errors.stock}
                  >
                    Stock
                  </InputLabel>
                </Grid>
                <Grid
                  className="menu-add-edit__form-field-container"
                  container={true}
                  direction="column"
                >
                  <TextField
                    error={touched.stock && !!errors.stock}
                    type="number"
                    className="menu-add-edit__form-field"
                    name="stock"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stock}
                    autoComplete="off"
                    inputProps={{
                      min: 0.0,
                      type: 'number',
                    }}
                  />
                  <ErrorMessage
                    render={(message: string) => (
                      <span className="menu-add-edit__form-field-error">
                        {message}
                      </span>
                    )}
                    name="stock"
                  />
                </Grid>
              </Grid> */}
              {!isOptionsCategory && (
                <Grid
                  className="menu-add-edit__row combo"
                  alignItems="center"
                  container={true}
                >
                  <Grid item={true} xs={3}>
                    <InputLabel
                      className="menu-add-edit__form-field-label"
                      error={touched.price && !!errors.price}
                      required={true}
                    >
                      Price
                    </InputLabel>
                  </Grid>
                  <Grid
                    className="menu-add-edit__form-field-container"
                    container={true}
                    direction="column"
                  >
                    <TextField
                      error={touched.price && !!errors.price}
                      required={true}
                      className="menu-add-edit__form-field"
                      name="price"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.price}
                      autoComplete="off"
                      inputProps={{
                        min: 0.0,
                        step: 0.01,
                        type: 'number',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencyPrefix}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      render={(message: string) => (
                        <span className="menu-add-edit__form-field-error">
                          {message}
                        </span>
                      )}
                      name="price"
                    />
                  </Grid>
                </Grid>
              )}
              {!isOptionsCategory && (
                <Grid
                  className="menu-add-edit__row combo"
                  alignItems="center"
                  container={true}
                >
                  <Grid item={true} xs={3}>
                    <InputLabel className="menu-add-edit__form-field-label">
                      Alcohol
                    </InputLabel>
                  </Grid>
                  <Grid>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={isAlcohol}
                      color="primary"
                      name="alcohol"
                      onChange={() => setIsAlcohol(prev => !prev)}
                      size="medium"
                      value={isAlcohol}
                    />
                  </Grid>
                </Grid>
              )}
              {!isOptionsCategory && (
                <Grid
                  className="menu-add-edit__row combo"
                  alignItems="center"
                  container={true}
                >
                  <Grid item={true} xs={3}>
                    <InputLabel className="menu-add-edit__form-field-label">
                      Effective Tax Rate
                    </InputLabel>
                  </Grid>
                  <Grid>{effectiveTaxRateString}</Grid>
                  {!isEmpty(taxRates) && (
                    <Autocomplete
                      multiple={true}
                      className="generic-dialog__field"
                      options={taxRates}
                      disableCloseOnSelect={true}
                      onChange={(event, newValue) => {
                        setAppliedTaxRates(newValue);
                      }}
                      value={appliedTaxRates}
                      noOptionsText="No Custom Tax Rates"
                      getOptionLabel={(option: ITaxRate) =>
                        `${option.name} (${option.percent}%)`
                      }
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckOutlinedIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name} ({option.percent}%)
                        </React.Fragment>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Tax Rates"
                          placeholder="Select Custom Tax Rates"
                        />
                      )}
                    />
                  )}
                </Grid>
              )}
              {!isOptionsCategory && comboSelectItems}
              <Grid
                container={true}
                className="menu-add-edit__control-buttons"
                alignItems="center"
                justify="center"
              >
                <Button
                  className="menu-add-edit__button"
                  color="primary"
                  onClick={onClose}
                  variant="outlined"
                >
                  <div className="icon-title-wrapper">
                    <UndoOutlinedIcon />
                    <span className="icon-title">Cancel</span>
                  </div>
                </Button>
                <Button
                  className="menu-add-edit__button"
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  <div className="icon-title-wrapper">
                    <CheckOutlinedIcon />
                    <span className="icon-title">
                      {product.id ? 'Update' : 'Add'}
                    </span>
                  </div>
                </Button>
              </Grid>
            </form>
            {viewGlobalProducts && (
              <SelectGlobalProduct
                onClose={() => setViewGlobalProducts(false)}
                onChangeProductImage={(globalProduct: IGlobalProduct) => {
                  setFieldValue('globalProductId', globalProduct.id);
                  setFieldValue('imageUrl', globalProduct.imageUrl);
                  setFieldValue('name', values.name || globalProduct.name);
                  setFieldValue('isCustomImage', false);
                  setFieldValue('imageFile', null);
                  setViewGlobalProducts(false);
                }}
              />
            )}
            {viewAddCustomImage && (
              <AddCustomImage
                closeDialog={() => setViewAddCustomImage(false)}
                onAddCustomImage={imageData => {
                  const { imageUrl, image } = imageData;
                  setFieldValue('isCustomImage', true);
                  setFieldValue('globalProductId', null);
                  setFieldValue('imageUrl', imageUrl);
                  setFieldValue('imageFile', image);
                  setViewAddCustomImage(false);
                }}
              />
            )}
          </Fragment>
        )}
      </Formik>
    </Grid>
  );
};

export default AddEditProductForm;
