import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { compareDesc, endOfDay, startOfDay, subHours } from 'date-fns';
import { find, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import '../Inventory.scss';
import '../../Reporting/Reporting.scss';
import InventoryService from '../../../services/Inventory/Inventory.service';
import { useAppContext } from '../../../config/AppContext';
import { getUTCDate } from '../../../services/Util/Util.service';
import ExportToExcel, { IWorksheet } from '../../../components/ExportToExcel';

const ViewInventory = (props: any) => {
  const [selectedStoreId, setSelectedStoredId] = useState('All');
  const [items, setItems] = useState<[]>([]);
  const [searchKey, setSearchKey] = useState('');
  const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));
  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const { venue } : any = useAppContext();

  useEffect(() => {
    searchInventory();
  }, [selectedStoreId, searchKey]);

  const handleStoreSelect = async (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    setSelectedStoredId(newSelectedStoreId);
  };

  const searchInventory = async () => {
    try {
      const reportEndDate = endDate;
      let reportStartDate = startDate;
      if (compareDesc(startDate, endDate) === 0) {
        reportStartDate = subHours(reportEndDate, 24);
      }

      const startTime = getUTCDate(
        reportStartDate,
        get(venue, 'timezone', ''),
      ).toISOString();
      const endTime = getUTCDate(
        reportEndDate,
        get(venue, 'timezone', ''),
      ).toISOString();
      const { data } = await InventoryService.getAdjustmentLogs(
        venue?.id,
        startTime,
        endTime,
        selectedStoreId !== 'All' ? selectedStoreId : '',
        searchKey ? searchKey : '',
      );
      setItems(data);
    } catch (err) {
      console.log(err, 'error while fetching inventory');
    }
  };

  const renderFilterControls = (): JSX.Element => {
    return (
      <>
        <KeyboardDatePicker
          autoOk={true}
          disableToolbar={true}
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          className="date-picker-inline"
          label="Start Date"
          maxDate={endDate}
          maxDateMessage="Start Date cannot be after End Date"
          value={startDate}
          onChange={date => date && setStartDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          autoOk={true}
          disableToolbar={true}
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          className="date-picker-inline"
          label="End Date"
          maxDate={new Date()}
          value={endDate}
          onChange={date => date && setEndDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={compareDesc(startDate, endDate) === -1 ? true : false}
          onClick={searchInventory}
          classes={{
            root: 'apply-btn',
          }}
        >
          Apply
        </Button>
      </>
    );
  };
  const formatModifiedTime = (isoDateString: any) => {
    const options: any = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = new Date(isoDateString).toLocaleString(
      undefined,
      options,
    );
    return formattedDate;
  };
  const getWorksheets = async (): Promise<any> => {
    const worksheets: IWorksheet[] = [
      {
        name: 'adjustmentLogs-detail',
        data: items.map((record: any) => ({
          'Change Date & time': formatModifiedTime(record.modifiedtime),
          'Product Name': record.productname,
          'Change Amount': record.changeamount,
          'Change Reason': record.reason,
          'Change By': record.changeby,
          'Comment': record.comment,
        })),
      },
    ];
    return worksheets;
  };

  return (
    <div className="reporting-root">
      <Grid container spacing={0} style={{ marginBottom: '30px' }}>
        <Grid item lg={3}>
          <div className="block-1">
            <input
              placeholder="Search"
              style={{ border: '1px solid grey', padding: '6px' }}
              value={searchKey}
              onChange={event => {
                setSearchKey(event.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item lg={3}>
          <div className="block-2">
            <Grid
              container={true}
              direction="row"
              alignItems="center"
              className="report-type-select"
              xs={6}
              lg={12}
              item={true}
            >
              <InputLabel
                htmlFor="type"
                classes={{ root: 'report-type-label' }}
                style={{ color: 'black' }}
              >
                Store :
              </InputLabel>
              <Select
                value={selectedStoreId || 'All'}
                onChange={handleStoreSelect}
                classes={{
                  root: 'select-store',
                }}
              >
                <MenuItem key="All" value="All">
                  All
                </MenuItem>
                {props?.stores?.length > 0 ? (
                  props?.stores?.map((store: any) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="no-category" disabled>
                    No Store Available
                  </MenuItem>
                )}
              </Select>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={6} style ={{marginTop :'-33px'}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container={true} justify="flex-end" xs={10} item={true}>
              {renderFilterControls()}
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div>
      <ExportToExcel
          buttonText="Export to Excel"
          filename="adjustmentLogs-detail"
           getWorksheets={getWorksheets}
        />
      </div>
      <Table className="reporting__table">
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Change Date & Time
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Item
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Change Amount
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Change Reason
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Changed By
            </TableCell>
            <TableCell
              classes={{
                head: 'table-header',
              }}
            >
              Comment
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell className="table-cell table-cell-no-data product-item">
                {formatModifiedTime(row.modifiedtime)}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.productname}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.changeamount}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.reason}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.changeby}
              </TableCell>
              <TableCell className="table-cell table-cell-no-data product-item">
                {row.comment}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default ViewInventory;
