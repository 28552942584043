import { IGlobalProduct } from '../../models/Product.model';
import axiosWithAuth, { IPaginatedItems } from '../ServiceConfig';

const baseUrl = `/global-products`;

const createGlobalProduct = (body: any) =>
  axiosWithAuth.post(`${baseUrl}`, body);

const getAllGlobalProducts = (
  limit: number,
  page: number,
  globalProduct: string,
) =>
  axiosWithAuth.get<IPaginatedItems<IGlobalProduct>>(
    `${baseUrl}?name=${globalProduct}&limit=${limit}&page=${page}`,
  );

const deleteGlobalProduct = (id: number) =>
  axiosWithAuth.delete(`${baseUrl}/${id}`);

const updateGlobalProduct = (id: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${id}`, body);

export default {
  baseUrl,
  createGlobalProduct,
  deleteGlobalProduct,
  getAllGlobalProducts,
  updateGlobalProduct,
};
