import { IVenueLocation } from '../../models/Location.model';
import { LocationTypeType } from '../../models/LocationTypeType.model';
import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/venues`;

const getAllVenues = (limit: number, page: number) =>
  axiosWithAuth.get(`${baseUrl}?limit=${limit}&page=${page}`);

const getUserVenues = (limit: number, page: number, search: string = '') =>
  axiosWithAuth.get(
    `${baseUrl}/admin-venues?limit=${limit}&page=${page}&search=${search}`,
  );

const getVenue = (venueId: string) =>
  axiosWithAuth.get(`${baseUrl}/${venueId}`);

const createVenue = (body: any) => axiosWithAuth.post(baseUrl, body);

const createACHAccount = (id: string, body: any) =>
  axiosWithAuth.post(`${baseUrl}/${id}/achaccount`, body);

const deleteACHAccount = (id: string) =>
  axiosWithAuth.delete(`${baseUrl}/${id}/achaccount`);

const updateVenue = (id: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${id}`, body);

const uploadVenueImage = (id: string, imageFile: any) =>
  axiosWithAuth.post(`${baseUrl}/upload/${id}`, imageFile);

const createLocationType = (
  venueId: string,
  body: {
    name: string;
    parentTypeId?: string;
    type: LocationTypeType;
    validValues: string[];
  },
) => axiosWithAuth.post(`${baseUrl}/${venueId}/locationType`, body);

const updateLocationType = (
  venueId: string,
  locationTypeId: string,
  body: {
    name: string;
    type: LocationTypeType;
    validValues: string[];
  },
) =>
  axiosWithAuth.put(
    `${baseUrl}/${venueId}/locationType/${locationTypeId}`,
    body,
  );

const deleteLocationType = (venueId: string, locationTypeId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${venueId}/locationType/${locationTypeId}`);

const createUserAdmin = (venueId: string, body: any) =>
  axiosWithAuth.post(`${baseUrl}/${venueId}/adminUsers`, body);

const deleteUserAdmin = (venueId: string, userId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${venueId}/adminUsers/${userId}`);

const deleteVenue = (venueId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${venueId}`);

const updateUserAdmin = (venueId: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${venueId}/adminUsers/${body.id}`, body);

const getAllUserAdmins = (
  venueId: string,
  limit: number,
  page: number,
  firstName: string,
  lastName: string,
  roles: string = '',
) =>
  axiosWithAuth.get(`${baseUrl}/${venueId}/adminUsers`, {
    params: {
      firstName,
      lastName,
      limit,
      page,
      ...(roles && { roles }),
    },
  });

const getVenuesByType = (
  includeInactive: boolean,
  type: string,
  limit: number,
) =>
  axiosWithAuth.get(
    `${baseUrl}?includeInactive=${includeInactive}&type=${type}&limit=${limit}`,
  );

const getVenueSuites = (venueId: string) =>
  axiosWithAuth.get<IVenueLocation[]>(`${baseUrl}/${venueId}/suites`);

export default {
  baseUrl,
  createACHAccount,
  createLocationType,
  createUserAdmin,
  createVenue,
  deleteACHAccount,
  deleteLocationType,
  deleteUserAdmin,
  deleteVenue,
  getAllUserAdmins,
  getAllVenues,
  getUserVenues,
  getVenue,
  getVenueSuites,
  getVenuesByType,
  updateLocationType,
  updateUserAdmin,
  updateVenue,
  uploadVenueImage,
};
