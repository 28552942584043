import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import NumberFormat from 'react-number-format';
import ExportToExcel, { IWorksheet } from '../../components/ExportToExcel';
import { noDataAvailable } from '../../components/NoDataAvailable';
import { CurrencyCode } from '../../models/Venue.model';
import { getCurrencyPrefix } from '../../services/Util/Util.service';

export interface IHasCurrency {
  currency: CurrencyCode;
}

export enum ReportColumnType {
  currency,
  date,
  integer,
  string,
}

interface IBasicReportProps<TDataRecord extends IHasCurrency> {
  columns: Array<IReportColumn<TDataRecord>>;
  data: TDataRecord[];
  exportName: string;
  isAdmin: boolean;
  helpText?: string;
}

export interface IReportColumn<TDataRecord> {
  accessor: (record: TDataRecord) => string | number;
  adminOnly?: boolean;
  name: string;
  total?: boolean;
  type: ReportColumnType;
}

const getCellType = (
  value: string | number,
  type: ReportColumnType,
): string => {
  switch (type) {
    case ReportColumnType.currency:
    case ReportColumnType.integer:
      if (isNaN(+value)) {
        return 's';
      } // ensure it's actually numerical data
      return 'n';
    case ReportColumnType.date:
      return 's'; // TODO - implement. the value should be 'd', but need to try parse first
    default:
      return 's';
  }
};

export const renderDataCell = <TDataRecord extends IHasCurrency>(
  column: IReportColumn<TDataRecord>,
  record: TDataRecord,
): JSX.Element => {
  const value = column.accessor(record);

  return renderTableCell(
    renderTableCellContents(column, record.currency, value),
  );
};

export const renderReportBody = <TDataRecord extends IHasCurrency>(
  columns: Array<IReportColumn<TDataRecord>>,
  data: TDataRecord[],
) => {
  return (
    <>
      {data.length <= 0
        ? noDataAvailable(columns.length)
        : data.map((record: TDataRecord, rix: number) => (
            <TableRow key={rix}>
              {columns.map(
                (column: IReportColumn<TDataRecord>, cix: number) => (
                  <React.Fragment key={cix}>
                    {renderDataCell(column, record)}
                  </React.Fragment>
                ),
              )}
            </TableRow>
          ))}
    </>
  );
};

// prettier-ignore
export const renderReportHeader = <TDataRecord,>(
  columns: Array<IReportColumn<TDataRecord>>,
): JSX.Element => {
  return (
    <>
      {columns.map((column: IReportColumn<TDataRecord>, cix: number) => (
        <TableCell key={cix} classes={{ head: 'table-header' }}>
          {column.name}
        </TableCell>
      ))}
    </>
  );
};

export const renderReportTotals = <TDataRecord extends IHasCurrency>(
  columns: Array<IReportColumn<TDataRecord>>,
  data: TDataRecord[],
): JSX.Element => {
  return (
    <>
      {data.length > 0 &&
        _.some(
          columns,
          (column: IReportColumn<TDataRecord>) => column.total,
        ) && (
          <TableRow>
            {columns.map((column: IReportColumn<TDataRecord>, cix: number) => {
              // column was not requested to be totaled or it is not numerical. if it is not requested
              // to be totaled and is the first column, it will display "Total" as the contents
              if (
                !column.total ||
                !_.includes(
                  [ReportColumnType.currency, ReportColumnType.integer],
                  column.type,
                )
              ) {
                const content = cix === 0 ? <>Total</> : undefined;

                return (
                  <React.Fragment key={cix}>
                    {renderTableCell(content)}
                  </React.Fragment>
                );
              }

              let currency = CurrencyCode.USD;
              if (column.type === ReportColumnType.currency) {
                const currencies = _(data)
                  .map((record: TDataRecord) => record.currency)
                  .uniq()
                  .value();

                // cannot sum multiple currencies
                if (currencies.length > 1) {
                  return (
                    <React.Fragment key={cix}>
                      {renderTableCell()}
                    </React.Fragment>
                  );
                }

                currency = currencies[0];
              }

              const value = _.sumBy(
                data,
                (record: TDataRecord) => +column.accessor(record),
              );

              return (
                <React.Fragment key={cix}>
                  {renderTotalCell(column, currency, value)}
                </React.Fragment>
              );
            })}
          </TableRow>
        )}
    </>
  );
};

const renderTableCell = (content?: JSX.Element): JSX.Element => {
  return (
    <TableCell className="table-cell table-cell-no-data">{content}</TableCell>
  );
};

// prettier-ignore
const renderTotalCell = <TDataRecord,>(
  column: IReportColumn<TDataRecord>,
  currency: CurrencyCode | undefined,
  value: string | number,
): JSX.Element =>
  renderTableCell(renderTableCellContents(column, currency, value));

// prettier-ignore
const renderTableCellContents = <TDataRecord,>(
  column: IReportColumn<TDataRecord>,
  currency: CurrencyCode | undefined,
  value: string | number,
): JSX.Element => {
  switch (column.type) {
    case ReportColumnType.currency:
      const currencyPrefix = getCurrencyPrefix(currency); // defaults to `$`

      return (
        <NumberFormat
          value={value}
          decimalScale={2}
          displayType="text"
          fixedDecimalScale={true}
          thousandSeparator={true}
          prefix={currencyPrefix}
        />
      );
    case ReportColumnType.date:
      try {
        return <>{new Date(value).toLocaleString()}</>;
      } catch {
        return <>{value}</>;
      }
    case ReportColumnType.integer:
      return (
        <NumberFormat
          value={value}
          decimalScale={0}
          displayType="text"
          fixedDecimalScale={true}
          thousandSeparator={true}
        />
      );
    default:
      return <>{value}</>;
  }
};

export const getWorksheets = async <TDataRecord extends IHasCurrency>(
  data: TDataRecord[],
  reportColumns: Array<IReportColumn<TDataRecord>>,
  exportName: string,
): Promise<IWorksheet[]> => {
  const worksheets: IWorksheet[] = [
    {
      data: data.map((record: TDataRecord) => {
        return Object.assign(
          reportColumns.reduce(
            (obj: any, column: IReportColumn<TDataRecord>) => {
              const value = column.accessor(record);
              return Object.assign(obj, {
                [column.name]: {
                  t: getCellType(value, column.type),
                  v: value,
                },
              });
            },
            {},
          ),
          {
            Currency: {
              t: 's',
              v: record.currency,
            },
          },
        );
      }),
      name: exportName,
    },
  ];

  return worksheets;
};

const BasicReport = <TDataRecord extends IHasCurrency>(
  props: IBasicReportProps<TDataRecord>,
): JSX.Element => {
  const { columns, data, exportName, isAdmin, helpText } = props;

  let reportColumns = columns.filter(
    column => !(column.adminOnly || false) || isAdmin,
  );
  const filterTerminal = data.some((x: any) => x?.terminal)
  if (!filterTerminal) {
    reportColumns = columns.filter(
      column => (column.name !== 'Terminal')
    );
  } else {
    data.find((x:any) =>  {
      if(!x.terminal) {
        x.terminal = 'Mobile Orders'
      }
    })
  }

  return (
    <>
      <div className="records-container">
        <div>{helpText}</div>
        <ExportToExcel
          buttonText="Export to Excel"
          filename={exportName}
          getWorksheets={() => getWorksheets(data, reportColumns, exportName)}
        />
      </div>
      <div>
        <Table className="reporting__table">
          <TableHead>
            <TableRow>{renderReportHeader(reportColumns)}</TableRow>
          </TableHead>
          <TableBody>
            {renderReportBody(reportColumns, data)}
            {renderReportTotals(reportColumns, data)}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default BasicReport;
