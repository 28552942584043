import { Typography } from '@material-ui/core';
import { toLower, upperFirst } from 'lodash';
import React from 'react';
import { Partner } from '../../models/Partner.model';

export interface IPartnerIdProps {
  id: string;
  partner: Partner;
}

export const PartnerId = ({ id, partner }: IPartnerIdProps) => {
  if (!partner || !id) {
    return null;
  }

  return (
    <Typography variant="subtitle2">
      {upperFirst(toLower(partner))} ID: {id}
    </Typography>
  );
};
