import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import React from 'react';
import './CustomTooltip.scss';

interface ICustomTooltip {
  onClickAway: () => void;
  onOpen: () => void;
  open: boolean;
  title: string;
  children: any;
  placement?: TooltipProps['placement'];
}

const CustomTooltip = (props: ICustomTooltip) => {
  const { onClickAway, onOpen, open, title, placement } = props;
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="custom-tooltip">
        <Tooltip
          placement={placement}
          title={title}
          classes={{
            popper: 'custom-tooltip__popper',
            tooltip: `custom-tooltip__tooltip ${
              placement && placement === 'top' ? 'arrow-bottom' : `arrow-top`
            }`,
          }}
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          arrow={true}
        >
          <IconButton className="custom-tooltip__icon-button" onClick={onOpen}>
            {props.children}
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CustomTooltip;
