import { Grid, Paper, Typography } from '@material-ui/core';
import { Chart } from 'chart.js';
import { get } from 'lodash';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IVenueDashboardTotals } from '../routes/Reporting/ReportingConfig';

export default function GeneralStatsChart({
  deliveryOrders,
  pickupOrders,
  promosUsed,
}: IVenueDashboardTotals) {
  const totalOrders = deliveryOrders + pickupOrders || -1;
  const colors = {
    green: '#20B2AA',
    violet: '#9400D3',
    yellow: '#f8cc01',
  };

  Chart.pluginService.register({
    beforeDraw: (chart: any) => {
      const width = get(chart, ['chart', 'width']);
      const height = get(chart, ['chart', 'height']);
      const ctx = get(chart, ['chart', 'ctx']);

      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + 'em sans-serif';
      ctx.textBaseline = 'middle';

      if (get(chart, ['chart', 'config', 'type']) === 'doughnut') {
        let text = '0';
        if (get(chart, 'chart.config.data.datasets[0].data[0]')) {
          text = chart.chart.config.data.datasets[0].data[0];
        }
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 1.9;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  });

  const doughnutOptions = {
    cutoutPercentage: 90,
    tooltips: {
      enabled: false,
    },
  };

  const totalDeliveryOrdersData = {
    datasets: [
      {
        backgroundColor: [colors.yellow],
        data: [deliveryOrders, totalOrders - deliveryOrders],
        hoverBackgroundColor: [colors.yellow],
      },
    ],
  };
  const totalPickupOrdersData = {
    datasets: [
      {
        backgroundColor: [colors.green],
        data: [pickupOrders, totalOrders - pickupOrders],
        hoverBackgroundColor: [colors.green],
      },
    ],
  };
  const totalPromosUsedData = {
    datasets: [
      {
        backgroundColor: [colors.violet],
        data: [promosUsed, totalOrders - promosUsed],
        hoverBackgroundColor: [colors.violet],
      },
    ],
  };
  return (
    <Paper classes={{ root: 'graph-container' }}>
      <Grid container={true} className="general-stats-grid-heading">
        <Typography variant="h6">General Stats</Typography>
      </Grid>
      <Grid container={true} className="general-stats-grid-container">
        <Grid item={true} className="general-stats_grid">
          <Doughnut
            options={doughnutOptions}
            data={totalDeliveryOrdersData}
            height={110}
            width={185}
          />
          <Typography className="general-stats-typography">
            Total Delivery Orders
          </Typography>
        </Grid>
        <Grid item={true} className="general-stats_grid">
          <Doughnut
            options={doughnutOptions}
            data={totalPickupOrdersData}
            height={110}
            width={185}
          />
          <Typography className="general-stats-typography">
            Total Pickup Orders
          </Typography>
        </Grid>
        <Grid item={true} className="general-stats_grid">
          <Doughnut
            options={doughnutOptions}
            data={totalPromosUsedData}
            height={110}
            width={185}
          />
          <Typography className="general-stats-typography">
            Total Promos Used
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
