import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import DeleteDialog from '../../../components/DeleteDialog';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { PartnerId } from '../../../components/Partner/PartnerId';
import { useAppContext } from '../../../config/AppContext';
import { IProductTag } from '../../../models/ProductTag.model';
import ProductTagService, {
  IAddOrUpdateProductTagDto,
} from '../../../services/ProductTag/ProductTag.service';
import { ProductTagDialog } from './ProductTagDialog';

const newTag: Partial<IProductTag> = {
  name: '',
};

export const Tagging = () => {
  const { fetchProductTags, productTags, venue } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [tagNameForEdit, setTagNameForEdit] = useState<
    Partial<IProductTag> | undefined
  >();
  const [tagNameToDelete, setTagNameToDelete] = useState<
    IProductTag | undefined
  >();

  const createOrUpdateTag = async ({ id, name }: IAddOrUpdateProductTagDto) => {
    if (!venue) {
      return;
    }

    try {
      if (id) {
        await ProductTagService.updateProductTag(id, {
          name,
        });
      } else {
        await ProductTagService.createProductTag({
          name,
          venueId: venue.id,
        });
      }
      setTagNameForEdit(undefined);
      await fetchProductTags();
    } catch (error) {
      enqueueSnackbar(`Error saving Tag`, {
        variant: 'error',
      });
    }
  };

  const deleteTaxRate = async () => {
    if (!tagNameToDelete) {
      return;
    }
    try {
      await ProductTagService.deleteProductTag(tagNameToDelete.id);
      setTagNameToDelete(undefined);
      await fetchProductTags();
    } catch (error) {
      enqueueSnackbar(`Error deleting Tax Rate`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Grid item={true} xs={8}>
        <Typography className="italic">
          Add Tag,to assign tag to Any product edit product and assign tag from
          tag list.
        </Typography>
      </Grid>
      <Grid item={true} xs={4}>
        <Button
          size="medium"
          color="primary"
          aria-label="add"
          variant="contained"
          className="dark-button"
          onClick={() => setTagNameForEdit(newTag)}
        >
          <div className="icon-title-wrapper">
            <AddOutlinedIcon />
            <span className="icon-title">Add</span>
          </div>
        </Button>
      </Grid>
      <Grid item={true} xs={12}>
        <Table>
          <TableHead>
            <TableRow
              classes={{
                root: 'table-row',
              }}
            >
              <TableCell classes={{ root: 'table-cell table-cell-head' }}>
                Name
              </TableCell>
              <TableCell classes={{ root: 'table-cell table-cell-head' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productTags && productTags.length > 0
              ? productTags.map((tag: IProductTag) => (
                  <TableRow key={tag.id} id={tag.id}>
                    <TableCell className="table-cell">{tag.name}</TableCell>
                    <TableCell className="table-cell">
                      <Button
                        size="small"
                        onClick={() => setTagNameForEdit(tag)}
                        value="edit"
                      >
                        <div className="icon-title-wrapper">
                          <EditOutlinedIcon />
                          <span className="icon-title">Edit</span>
                        </div>
                      </Button>
                      <Button
                        size="small"
                        value="delete"
                        onClick={() => setTagNameToDelete(tag)}
                      >
                        <div className="icon-title-wrapper">
                          <DeleteOutlinedIcon />
                          <span className="icon-title">Delete</span>
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : noDataAvailable(2, 'No Tag')}
          </TableBody>
        </Table>
      </Grid>
      {tagNameToDelete && (
        <DeleteDialog
          dialogTitle="Delete Tag"
          onClose={() => setTagNameToDelete(undefined)}
          onSubmit={deleteTaxRate}
          dialogContent="Are you sure you want to delete this Tag? This Tag will be removed from all products. This cannot be undone."
        />
      )}
      {tagNameForEdit && venue && (
        <ProductTagDialog
          createOrUpdateTag={createOrUpdateTag}
          onClose={() => setTagNameForEdit(undefined)}
          productTag={tagNameForEdit}
        />
      )}
    </>
  );
};
