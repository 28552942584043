import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import ExportToExcel, { IWorksheet } from '../../components/ExportToExcel';
import { noDataAvailable } from '../../components/NoDataAvailable';

interface IUserOverviewProps {
  data: IUserOverviewDataRecord[];
}

export interface IUserOverviewDataRecord {
  newUsers: number;
  ordersPerUser: number;
  returningUsers: number;
  spendPerUser: number;
  totalUsers: number;
}

const headers = [
  'Total Users',
  'New Users',
  'Returning Users',
  'Spend Per User',
  'Orders Per User',
];

const UserOverview = (props: IUserOverviewProps) => {
  const { data } = props;

  const getWorksheets = async (): Promise<IWorksheet[]> => {
    /* tslint:disable:object-literal-sort-keys */
    const worksheets: IWorksheet[] = [
      {
        name: 'user-overview',
        data: data.map((record: IUserOverviewDataRecord) => ({
          'Total Users': record.totalUsers,
          'New Users': record.newUsers,
          'Returning Users': record.returningUsers,
          'Spend Per User': record.spendPerUser.toFixed(2),
          'Orders Per User': record.ordersPerUser.toFixed(1),
        })),
      },
    ];
    /* tslint:enable:object-literal-sort-keys */

    return worksheets;
  };

  return (
    <>
      <div className="records-container">
        {/* this aligns the export button to the right by taking up a column position */}
        <div />

        <ExportToExcel
          buttonText="Export to Excel"
          filename="user-overview"
          getWorksheets={getWorksheets}
        />
      </div>
      <div>
        <Table className="reporting__table">
          <TableHead>
            <TableRow>
              {headers.map((text: string, index: number) => (
                <TableCell key={index} classes={{ head: 'table-header' }}>
                  {text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length
              ? noDataAvailable(headers.length)
              : data.map((record: IUserOverviewDataRecord, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.totalUsers}
                          decimalScale={0}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.newUsers}
                          decimalScale={0}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.returningUsers}
                          decimalScale={0}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.spendPerUser}
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.ordersPerUser}
                          decimalScale={1}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default UserOverview;
