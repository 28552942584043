import React from 'react';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';

interface IFlashReportProps {
  data: IFlashReportDataRecord[];
  isAdmin: boolean;
}

export interface IFlashReportDataRecord extends IHasCurrency {
  date: string;
  gmv: number;
  storeName: string;
  totalOrders: number;
  transactionalRevenue: number;
  venueName: string;
}

const exportName = 'flash-report';
const columns: Array<IReportColumn<IFlashReportDataRecord>> = [
  {
    accessor: record => record.date,
    name: 'Order Created Date',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.venueName,
    name: 'Venue Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.storeName,
    name: 'Store Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.transactionalRevenue,
    name: 'Transactional Revenue',
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.gmv,
    name: 'GMV',
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.totalOrders,
    name: 'Total Orders',
    type: ReportColumnType.integer,
  },
];

const FlashReport = (props: IFlashReportProps): JSX.Element => (
  <BasicReport {...{ columns, exportName }} {...props} />
);

export default FlashReport;
