import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { PosTabs } from './PosTabs';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from '../../config/AppContext';
import axiosWithAuth, { axiosWithCart } from '../../services/ServiceConfig';
import CheckoutCart from './CheckoutCart';
import './Pos.scss';
import { makeStyles } from '@material-ui/core/styles';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service';
import { ArrowBack } from '@material-ui/icons';
import PromoService from '../../services/Promo/Promo.service';
import VenueService from '../../services/Venue/Venue.service';
import StoreService from '../../services/Store/Store.service';
import PrinterService from '../../services/Printer/Printer.service';
import CustomProduct from './CustomProduct/CustomProduct';
import InventoryService from '../../services/Inventory/Inventory.service';
import DialogContentText from '@material-ui/core/DialogContentText';
declare const ENVARS: any;
const useStyles = makeStyles({
  title: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '24px',
    textAlign: 'center',
  },
  closeButton: {
    color: '#009bc5',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#009bc5',
      backgroundColor: '#fff',
    },
    minWidth: '128px',
    minHeight: '56px',
    borderRadius: '1',
    marginTop: '16px',
    flex: '1',
    marginRight: '40px',
    border: '1px solid #009bc5',
  },
  cancleDiscountButton: {
    color: '#009bc5',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#009bc5',
      backgroundColor: '#fff',
    },
    minWidth: '128px',
    minHeight: '56px',
    borderRadius: '1',
    marginTop: '16px',
    flex: '1',
    marginLeft: '40px',
    border: '1px solid #009bc5',
  },
  addToOrderButton: {
    color: '#fff',
    backgroundColor: '#DA112E',
    '&:hover': {
      color: '#009bc5',
      backgroundColor: '#fff',
    },
    minWidth: '128px',
    minHeight: '56px',
    borderRadius: '1',
    marginTop: '16px',
    flex: '1',
  },
  proceedButton: {
    color: '#fff',
    backgroundColor: '#009bc5',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#009bc5',
    },
    minWidth: '128px',
    minHeight: '56px',
    borderRadius: '1',
    marginTop: '16px',
    flex: '1',
    marginLeft: '40px',
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
  },
  checkboxLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  discountPinLabel: {
    color: '#009bc5',
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  discountPinTextField: {
    borderBottom: '2px solid #009bc5',
  },
  InstructionField: {
    '& .MuiInputBase-root:hover': {
      borderColor: 'initial',
    },
    '& .MuiInputBase-root.Mui-focused': {
      borderColor: 'initial',
    },
    fontSize: '12px',
    fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
  },
});
function PosTerminal() {
  interface Terminal {
    id: string;
    image: null | string; // Update the type based on the actual data type
    isDeleted: boolean;
    isPOSCash: boolean;
    locationId: string;
    registrationCode: string;
    storeId: string;
    stripeTerminalId: string;
    terminalName: string;
    userId: string;
    venueId: string;
  }
  const history = useHistory();
  const {
    setStripeTerminal,
    stripeTerminal,
    cartItems,
    setCartItems,
    subTotal,
    selectedStoreId,
    setSelectedStoreId,
  } = useAppContext();
  const location: any = useLocation();
  setStripeTerminal(location?.state.item?.stripeTerminalId);
  const pdfRef = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isCheckoutDialogOpen, setIsCheckoutDialogOpen] = useState(false);
  const [isConfirmationDialog, setIsConfirmationDialog] = useState(false);
  const [isPosCash, setPosCash] = useState(false);
  const [order, setOrder] = useState<any>({});
  const [promo, setPromo] = useState([]);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isOrderCompltedDialogOpen, setIsOrderCompltedDialogOpen] = useState(
    false,
  );
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [iscashCompletedDialogOpen, setIsCashCompletedDialogOpen] = useState(
    false,
  );
  const [isAddOnDialogOpen, setIsAddOnDialogOpen] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [product, setProduct] = useState<any>({});
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [outOfStockItems, setOutOfStockItems] = useState<any[]>([]);
  const [
    isPaymentConfirmationDialogOpen,
    setIsPaymentConfirmationDialogOpen,
  ] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [Productloading, setProductloading] = React.useState(false);
  const [addOnLoading, setAddOnLoading] = useState(false);
  const [processingTerminalId, setProcessingTerminalId] = useState(1);
  const [processingPaymentIntentId, setProcessingPaymentIntentId] = useState(1);
  const [isCardPayment, setIsCardPayment] = useState(false);
  const [cartId, setCartId] = useState('');
  const [finalAmount, setFinalAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [convenienceFee, setconvenienceFee] = useState(0);
  const userId = window.localStorage.getItem('userId');
  const storeId = window.localStorage.getItem('storeId');
  const classes = useStyles();
  const [isCashDialog, setIsCashDialog] = useState(false);
  const [isAmountError, setIsAmountError] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState('0');
  const [returnAmount, setReturnAmount] = useState('0');
  const [cartDataIds, setCartDataIds] = useState({});
  const [showDiscounts, setShowDiscounts] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState('');
  const [discountPin, setDiscountPin] = useState('');
  const [venueTaxCaluculated, setVenueTaxCalculated] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [customPercentage, setCustomPercentage] = useState(0);
  const [showCustomDiscounts, setShowCustomDiscounts] = useState(false);
  const [storeDiscount, setStoreDiscount] = useState(false);
  const [terminal, setTerminal] = useState<Terminal | null>(null);
  const [showCustomProduct, setShowCustomProduct] = useState(false);
  const [storeData, setStoreData] = useState();
  const [venueData, setVenueData] = useState();
  const [fetchProducts, setFetchProducts] = useState(false);
  const [outOfStockPopUp, setOutOfStockPopUp] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [note, setNote] = useState('');
  const contentRef: any = useRef();
  const currentUrl = window.location.href;
  const [existingCart, setExistingCart] = useState<any>();
  const [showCartPopup, setShowCartPopup] = React.useState(false);

  useEffect(() => {
    (async () => {
      if (cartId && storeId == selectedStoreId) {
        axiosWithCart
          .get(`cart/${cartId}`)
          .then(async response => {
            setCartItems(response.data.lineItems);
            setCartDataIds({
              storeId: response.data.storeId,
              venueId: response.data.venueId,
            });
            venueTaxCaluculated && setTaxAmount(response.data.tax);
            setconvenienceFee(response.data.convenienceFee);
            if (customPercentage > 0 || customAmount > 0) {
              await handleCustomDiscountonUpdateCart(
                response.data.subTotal,
                response.data.lineItems.length,
              );
            } else {
              setDiscountAmount(response.data.discountAmt);
            }
            setTaxAmount(response.data.tax);
            setconvenienceFee(response.data.convenienceFee);
          })
          .catch(error => {
            console.log('Error while addin g item to cart', error);
          })
          .catch(error => {
            console.log('Error while addin g item to cart', error);
          });
      }
      if (selectedStoreId) {
        const response: any = await StoreService.getStore(selectedStoreId);
        setStoreDiscount(response?.data?.customDiscount);
        setStoreData(response.data);
        setSelectedStoreId(selectedStoreId);
      }
      const check = window.localStorage.getItem('cartId');
      if (!check) {
        setCartItems([]);
        setTaxAmount(0);
        setconvenienceFee(0);
        setDiscountApplied(false);
        setDiscountAmount(0);
        setCustomPercentage(0);
        setCustomAmount(0);
      }
    })();
  }, [cartId, storeId]);

  useEffect(() => {
    const value: any = window.localStorage.getItem('cartId');
    if (value) {
      setCartId(value);
    }
  }, [cartItems]);
  useEffect(() => {
    VenueService.getVenue(location?.state.item?.venueId).then(resp => {
      setVenueTaxCalculated(resp.data?.calculateTaxes);
      setVenueData(resp.data);
    });
  }, [location?.state.item?.venueId]);

  useEffect(() => {
    if (stripeTerminal && selectedStoreId) {
      (async () => {
        const response: any = await axiosWithAuth.get(
          `terminals/store/${selectedStoreId}`,
        );
        if (response.data.length > 0) {
          const terminals = response.data;
          const matchingTerminal: any[] = terminals.filter((terminal: any) => {
            return terminal.stripeTerminalId === stripeTerminal;
          });
          if (matchingTerminal.length > 0) {
            setTerminal(matchingTerminal[0]);
          }
        }
      })();
    }
  }, [stripeTerminal]);

  const onClickCancel = () => {
    setLoading(true);
    // setLoading(true)
    const payload = {
      stripeTerminalId: stripeTerminal,
    };

    axiosWithAuth
      .post('terminals/cancel', payload)
      .then(response => {
        enqueueSnackbar('success', {
          variant: 'success',
        });
        setLoading(false);
      })
      .catch(error => {
        enqueueSnackbar('response', {
          variant: 'error',
        });
        console.log('Error while requesting process payment', error);
        setLoading(false);
      });
  };
  const onClickGetReader = () => {
    setLoading(true);

    axiosWithAuth
      .get(`terminals/get-readers/${stripeTerminal}`)
      .then(response => {
        enqueueSnackbar(response.data.label, {
          variant: 'success',
        });
        setLoading(false);
      })
      .catch(error => {
        enqueueSnackbar('error', {
          variant: 'error',
        });
        console.log('Error while requesting process payment', error);
        setLoading(false);
      });
  };
  const onClickReaderDisplay = () => {
    setLoading(true);
    axiosWithAuth
      .get(`terminals/status/${processingPaymentIntentId}`)
      .then((response: any) => {
        enqueueSnackbar(response?.data.message, {
          variant: 'success',
        });
        setLoading(false);
      })
      .catch(error => {
        enqueueSnackbar('response', {
          variant: 'error',
        });
        setLoading(false);
      });
  };
  const handleCheckoutDailog = async () => {
    if (cartItems.length > 0) {
      const subTotal = cartItems?.reduce(
        (accumulator: any, currentItem: any) => {
          return accumulator + currentItem.total;
        },
        0,
      );
      setFinalAmount(subTotal + taxAmount + convenienceFee - discountAmount);
      setIsCheckoutDialogOpen(!isCheckoutDialogOpen);
      // setIsCardPayment(false);
    } else {
      enqueueSnackbar('Please add items to cart first', {
        variant: 'warning',
      });
    }
  };
  const handleCheckoutDailogClose = () => {
    const payload = {
      stripeTerminalId: stripeTerminal,
      paymentIntenId: processingPaymentIntentId,
    };
    if (isCardPayment) {
      setLoading(true);
      axiosWithAuth
        .post('terminals/cancel', payload)
        .then(response => {
          setLoading(false);
          enqueueSnackbar('Process has been canceled successfully reader', {
            variant: 'success',
          });
        })
        .catch(error => {
          enqueueSnackbar('Could not cancel the process', {
            variant: 'error',
          });
          setLoading(false);
        });
    }
    setIsCheckoutDialogOpen(!isCheckoutDialogOpen);
    setIsCardPayment(false);
    setIsConfirmationDialog(false);
  };
  const handleCloseOrderComplete = () => {
    setIsOrderCompltedDialogOpen(false);
  };
  const handleInstructionsChange = (event: any) => {
    setInstructions(event.target.value);
  };
  const handleCheckboxChange = async (
    eId: string,
    itemId: string,
    productId: string,
  ) => {
    //  check Product Quantity in cart
    // const quantity = await calculateQuantity(productId);
    // Check Product Quantity is available
    // const responseHandleQuantity: any = await handleInventoryQuantity([
    //   {
    //     productId: productId,
    //     quantity: quantity + 1,
    //   },
    // ]);
    // if (!responseHandleQuantity.success) {
    //   return enqueueSnackbar(`${responseHandleQuantity.message}`, {
    //     variant: 'error',
    //   });
    // }
    const selectedCategoryItems = selectedItems.filter(
      item => item.parentId === eId,
    );
    const selectedItemIndex = selectedCategoryItems.findIndex(
      item => item.id === itemId,
    );
    let newSelectedItems: any[] = [];

    if (selectedItemIndex !== -1) {
      // If the checkbox is already selected, remove it from the selectedItems array
      newSelectedItems = selectedCategoryItems.filter(
        (item, index) => index !== selectedItemIndex,
      );
    } else {
      // If the checkbox is not selected
      const category = product?.subCategories?.find(
        (subCategory: any) => subCategory.id === eId,
      );
      const selectionLimit = category?.selectionLimit;

      if (selectionLimit && selectedCategoryItems.length >= selectionLimit) {
        // If the selection limit has been reached, do not allow selecting more checkboxes
        return;
      }

      // Add the new checkbox to the selectedItems array
      newSelectedItems = [
        ...selectedCategoryItems,
        { id: itemId, parentId: eId },
      ];
    }

    setSelectedItems([
      ...selectedItems.filter(item => item.parentId !== eId),
      ...newSelectedItems,
    ]);
  };
  const handlecloseAddOn = () => {
    setIsAddOnDialogOpen(false);
    setSelectedItems([]);
  };
  const handleGenerateReceipt = async () => {
    try {
      order.venueTaxCaluculated = venueTaxCaluculated;
      order.terminalName = terminal?.terminalName;
      await PrinterService.PrintDocument(order);
    } catch (e) {
      enqueueSnackbar('Error While Printing Document', {
        variant: 'error',
      });
    } finally {
      await getProductIdsFromOrder();
      setIsPaymentConfirmationDialogOpen(false);
      setReturnAmount('0');
      setEnteredAmount('0');
      setIsCashCompletedDialogOpen(false);
      setIsOrderCompltedDialogOpen(false);
      setIsCheckoutDialogOpen(false);
      setCartItems([]);
      setTaxAmount(0);
      setconvenienceFee(0);
      setDiscountApplied(false);
      setDiscountAmount(0);
      enqueueSnackbar('order completed successfully', {
        variant: 'success',
      });
    }
  };
  const handlePaymentSuccessDailog = () => {
    setIsPaymentConfirmationDialogOpen(false);
    // setSubtotal(0)
  };
  const onClickCreditCard = () => {
    setLoading(true);
    const payload = {
      stripeTerminalId: stripeTerminal,
      currency: 'usd',
      payment_method_types: 'card_present',
      capture_method: 'manual',
      amount: subTotal,
    };

    axiosWithAuth
      .post('terminals/card-checkout', payload)
      .then(response => {
        setProcessingPaymentIntentId(
          response['data']['processPaymentIntentId'],
        );
        setProcessingTerminalId(response['data']['processTerminal']);
        setIsCardPayment(true);
        setLoading(false);
      })
      .catch(error => {
        console.log('Error while requesting card checkout', error);
      });
  };
  const onClickCreate = async () => {
    setLoading(true);
    const productsAlongQuantities = await filterProductWithQuantity();
    const responseHandleQuantity: any = await handleInventoryQuantity(
      productsAlongQuantities,
    );
    if (!responseHandleQuantity.success) {
      setLoading(false);
      return enqueueSnackbar(`${responseHandleQuantity.message}`, {
        variant: 'error',
      });
    }
    const payload = {
      stripeTerminalId: stripeTerminal,
      currency: 'usd',
      payment_method_types: 'card_present',
      amount: (subTotal + taxAmount + convenienceFee - discountAmount).toFixed(
        2,
      ),
      cartDataIds: cartDataIds,
    };

    axiosWithAuth
      .post('terminals/create', payload)
      .then(response => {
        setProcessingPaymentIntentId(
          response['data']['processPaymentIntentId'],
        );
        setProcessingTerminalId(response['data']['processedTerminaltId']);
        setLoading(false);
        setIsCardPayment(true);
        enqueueSnackbar('amount has been successuly proccessed for reader', {
          variant: 'success',
        });
      })
      .catch(error => {
        enqueueSnackbar('Requires payment processing', {
          variant: 'error',
        });
        console.log('Error while requesting create payment', error);
        setLoading(false);
      });
  };
  const onClickPresent = () => {
    setLoading(true);
    const payload = {
      stripeTerminalId: stripeTerminal,
    };

    axiosWithAuth
      .post('terminals/present', payload)
      .then(response => {
        enqueueSnackbar(response.data.message, {
          variant: 'success',
        });
        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Amount in not processed yet', {
          variant: 'error',
        });
        setLoading(false);
      });
  };
  const onClickConfirm = () => {
    setLoading(true);
    // setLoading(true)
    const payload = {
      paymentIntenId: processingPaymentIntentId,
    };

    axiosWithAuth
      .post('terminals/confirm', payload)
      .then(response => {
        setLoading(false);
        enqueueSnackbar('response.data.message', {
          variant: 'success',
        });
      })
      .catch(error => {
        enqueueSnackbar('Payment method not found for this process', {
          variant: 'error',
        });
        console.log('Error while requesting present', error);
        setLoading(false);
      });
  };
  const onClickCapture = () => {
    setLoading(true);
    const createOrderPayload = {
      paymentIntentId: processingPaymentIntentId,
      terminalId: stripeTerminal,
      cartId: cartItems[0]?.cartId,
      payloadOrderType: 'POS',
      finalPrice: Number(
        (subTotal + taxAmount - discountAmount + convenienceFee).toFixed(2),
      ),
      tipAmt: 0,
    };
    if (customAmount > 0 || customPercentage > 0) {
      Object.assign(createOrderPayload, { discountAmount: discountAmount });
    }
    if (note) {
      Object.assign(createOrderPayload, { note: note });
    }
    axios
      .post(`${ENVARS.API.BASE_URL}/api/v2/orders`, createOrderPayload, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${LocalStorageService.getLocalData(
            'authToken',
          )}`,
        },
      })
      .then(async r => {
        setOrder(r.data);
        setLoading(false);
        setIsCheckoutDialogOpen(false);
        setIsPaymentConfirmationDialogOpen(false);
        setIsCardPayment(false);
        setLoading(false);
        setProcessingPaymentIntentId(1);
        setProcessingTerminalId(1);
        setCartItems([]);
        setTaxAmount(0);
        setconvenienceFee(0);
        setIsOrderCompltedDialogOpen(true);
        setPosCash(false);
        setNote('');
        enqueueSnackbar('Order has been placed successfully', {
          variant: 'success',
        });
        setIsCashCompletedDialogOpen(true);
        setCustomAmount(0);
        setCustomPercentage(0);
        setDiscountAmount(0);
        setSelectedDiscount('');
      })
      .catch(error => {
        console.log(error?.response?.data?.message, 'error?.message');
        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'error',
        });
        console.log('Error while creating order', error);
        setLoading(false);
      });
  };
  const onClickCapturePayment = (val: any) => {
    setLoading(true);
    const payload = {
      processedTerminalId: processingTerminalId,
      processedPaymentId: processingPaymentIntentId,
      test: val,
    };
    axiosWithAuth
      .post('terminals/capture-payment', payload)
      .then(response => {
        setIsCheckoutDialogOpen(false);
        setIsPaymentConfirmationDialogOpen(true);
        setIsCardPayment(true);
        setLoading(false);
        setProcessingPaymentIntentId(1);
        setProcessingTerminalId(1);
        setCartItems([]);
      })
      .catch(error => {
        console.log('Error while requesting capture', error);
        setIsCheckoutDialogOpen(false);
        // setIsPaymentConfirmationDialogOpen(true)
      });
  };
  const onClickCash = async () => {
    const productsAlongQuantities = await filterProductWithQuantity();
    const responseHandleQuantity: any = await handleInventoryQuantity(
      productsAlongQuantities,
    );
    if (!responseHandleQuantity.success) {
      return enqueueSnackbar(`${responseHandleQuantity.message}`, {
        variant: 'error',
      });
    }
    setIsCheckoutDialogOpen(false);
    setIsCashDialog(true);
  };
  const onClickClearCart = async () => {
    await axios.delete(`${ENVARS.API.BASE_URL}/api/v1/cart/${cartId}`);
    window.localStorage.removeItem('cartId');
    setCartItems([]);
    setTaxAmount(0);
    setconvenienceFee(0);
    setDiscountApplied(false);
    setDiscountAmount(0);
    setCustomPercentage(0);
    setCustomAmount(0);
  };
  const handleAddToBag = async () => {
    try {
      setAddOnLoading(true);
      if (selectedItems.length < 1) {
        await handleAddToCart(product, 1);
      } else {
        let subSelections = [];
        let parentIndex: any = {};

        for (let i = 0; i < selectedItems.length; i++) {
          if (selectedItems[i]?.id !== '') {
            let item = selectedItems[i];
            let parentId = item.parentId;

            if (parentId in parentIndex) {
              let parent = subSelections[parentIndex[parentId]];
              parent.subSelections.push({ id: item.id, quantity: 1 });
            } else {
              let parent = {
                subSelections: [{ id: item.id, quantity: 1 }],
                id: parentId,
              };
              parentIndex[parentId] = subSelections.length;
              subSelections.push(parent);
            }
          }
        }
        if (cartItems.length > 0) {
          const payload = {
            instructions: instructions,
            productId: product.id,
            quantity: 1,
            subSelections: subSelections,
          };

          const response = await axiosWithCart.post(
            `cart/${cartId}/items`,
            payload,
          );
          setCartItems(response?.data.lineItems);
          venueTaxCaluculated && setTaxAmount(response.data.tax);
          setconvenienceFee(response.data.convenienceFee);
          setCartDataIds({
            storeId: response.data.storeId,
            venueId: response.data.venueId,
          });
          if (customPercentage > 0 || customAmount > 0) {
            await handleCustomDiscountonUpdateCart(
              response.data.subTotal,
              response.data.lineItems.length,
            );
          } else {
            setDiscountAmount(response.data.discountAmt);
          }
        } else {
          // const { data }: any = await axiosWithCart.get(
          //   `cart/userCart/${userId}`,
          // );
          // if (data.status === 200) {
          //   setExistingCart(data.cart);
          //   setShowCartPopup(true);
          //   setIsAddOnDialogOpen(false);
          //   return;
          // }
          const payload = {
            userId: userId,
            storeId: selectedStoreId,
            deliveryLocationId: null,
            eventId: null,
            lineItem: {
              instructions: '',
              productId: product.id,
              quantity: 1,
              subSelections: subSelections,
            },
            orderType: 'POS',
            scheduledTime: '2023-04-27T10:00:00.000Z',
            gameDayOrder: true,
          };
          const response = await axiosWithCart.post('cart', payload);
          console.log(response, 'response++');
          setCartItems(response.data.lineItems);
          venueTaxCaluculated && setTaxAmount(response.data.tax);
          setconvenienceFee(response.data.convenienceFee);
          if (customPercentage > 0 || customAmount > 0) {
            await handleCustomDiscountonUpdateCart(
              response.data.subTotal,
              response.data.lineItems.length,
            );
          } else {
            setDiscountAmount(response.data.discountAmt);
          }
          window.localStorage.setItem('cartId', response.data.id);
          setCartId(response.data.id);
          window.localStorage.setItem(
            'storeId',
            selectedStoreId ? selectedStoreId : '',
          );
        }
      }
      setIsAddOnDialogOpen(false);
      setSelectedItems([]);
      setAddOnLoading(false);
    } catch (error) {
      setAddOnLoading(false);
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
      console.log('Error while adding item to bag', error);
    }
  };
  const fetchProduct = async (itemId: String) => {
    const product = await axios.get(
      `${ENVARS.API.BASE_URL}/api/v1/products/${itemId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${LocalStorageService.getLocalData(
            'authToken',
          )}`,
        },
      },
    );
    const { data } = product;
    if (data?.subCategories?.length > 0) {
      for (let i = 0; i < data?.subCategories.length; i++) {
        const currentCategory: any = data?.subCategories[i];
        let activeSubProducts = [];
        for (let j = 0; j < currentCategory.subProducts.length; j++) {
          const { active } = currentCategory.subProducts[j];
          if (active) {
            activeSubProducts.push(currentCategory.subProducts[j]);
          }
        }
        data.subCategories[i].subProducts = activeSubProducts;
        if (data.subCategories[i].subProducts.length < 1) {
          data.subCategories.splice(i, 1);
          i--;
        }
      }
    }
    setProduct(product.data);
    return product.data;
  };

  const handleAddToCart = async (item: any, ind: number) => {
    try {
      const product: any = await fetchProduct(item.id);
      //check cart data Quantity
      if (product?.subCategories?.length > 0 && !isAddOnDialogOpen) {
        const newArray = product?.subCategories.flatMap((category: any) =>
          category.subProducts
            .filter(
              (subProduct: any) =>
                subProduct.autoSelect === true && subProduct.active === true,
            )
            .map((subProduct: any) => ({
              id: subProduct.id,
              parentId: category.id,
            })),
        );

        setSelectedItems(newArray);
        setIsAddOnDialogOpen(true);
      } else {
        setProductloading(true);
        if (cartItems.length > 0) {
          const payload = {
            productId: item.id,
            quantity: 1,
          };
          const response = await axiosWithCart.post(
            `cart/${cartId}/items`,
            payload,
          );
          setProductloading(false);
          setCartItems(response?.data.lineItems);
          venueTaxCaluculated && setTaxAmount(response.data.tax);
          setconvenienceFee(response.data.convenienceFee);
          if (customPercentage > 0 || customAmount > 0) {
            await handleCustomDiscountonUpdateCart(
              response.data.subTotal,
              response.data.lineItems.length,
            );
          } else {
            setDiscountAmount(response.data.discountAmt);
          }
        } else {
          const payload = {
            userId: userId,
            storeId: selectedStoreId,
            deliveryLocationId: null,
            eventId: null,
            lineItem: { productId: item.id, quantity: 1 },
            orderType: 'POS',
            scheduledTime: '2023-04-27T10:00:00.000Z',
            gameDayOrder: true,
          };
          const response = await axiosWithCart.post('cart', payload);
          setCartItems(response.data.lineItems);
          venueTaxCaluculated && setTaxAmount(response.data.tax);
          setconvenienceFee(response.data.convenienceFee);
          if (customPercentage > 0 || customAmount > 0) {
            await handleCustomDiscountonUpdateCart(
              response.data.subTotal,
              response.data.lineItems.length,
            );
          } else {
            setDiscountAmount(response.data.discountAmt);
          }
          window.localStorage.setItem('cartId', response.data.id);
          setCartId(response.data.id);
          window.localStorage.setItem(
            'storeId',
            selectedStoreId ? selectedStoreId : '',
          );
          setProductloading(false);
        }
      }
    } catch (error) {
      setProductloading(false);
      setIsAddOnDialogOpen(false);
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };
  const removeFromCart = (ind: number, lineItemId: string, qty: number) => {
    axiosWithCart
      .put(`cart/${cartId}/items/${lineItemId}`, { quantity: 0 })
      .then(async response => {
        setCartItems(response.data.lineItems);
        setTaxAmount(response.data.tax);
        setconvenienceFee(response.data.convenienceFee);
        if (customPercentage > 0 || customAmount > 0) {
          await handleCustomDiscountonUpdateCart(
            response.data.subTotal,
            response.data.lineItems.length,
          );
        } else {
          setDiscountAmount(response.data.discountAmt);
        }
      });
  };
  const decrementFromCart = (ind: number, lineItemId: string, qty: number) => {
    axiosWithCart
      .put(`cart/${cartId}/items/${lineItemId}`, { quantity: qty - 1 })
      .then(async response => {
        setCartItems(response.data.lineItems);
        if (customPercentage > 0 || customAmount > 0) {
          await handleCustomDiscountonUpdateCart(
            response.data.subTotal,
            response.data.lineItems.length,
          );
        } else {
          setDiscountAmount(response.data.discountAmt);
        }
        setTaxAmount(response.data.tax);
        setconvenienceFee(response.data.convenienceFee);
      })
      .catch(error => {
        console.log(error, ' error occurred');
      });
  };
  const handleClick = (value: any) => {
    if (enteredAmount === '0') {
      setEnteredAmount(value);
    } else if (value === '.' && enteredAmount.toString().includes('.')) {
      return;
    } else if (value === '.' && !enteredAmount.toString().includes('.')) {
      setEnteredAmount(enteredAmount.toString() + value);
    } else {
      setEnteredAmount(enteredAmount.toString() + value);
    }
  };
  const calculateBill = async () => {
    const paidAmt = finalAmount - convenienceFee;
    if (+enteredAmount < paidAmt) {
      setIsAmountError(true);
    } else {
      setIsAmountError(false);
      setReturnAmount((parseFloat(enteredAmount) - paidAmt).toFixed(2));
      await handleTransactionComplete();
    }
  };
  const handleTransactionComplete = async () => {
    setLoading(true);
    setIsCashCompletedDialogOpen(true);
    const createOrderPayload = {
      cartId: cartItems[0]?.cartId,
      finalPrice: Number((subTotal + taxAmount - discountAmount).toFixed(2)),
      tipAmt: 0,
      taxAmount: taxAmount,
      payloadOrderType: 'POS_CASH',
      terminalId: stripeTerminal,
    };
    if (note) {
      Object.assign(createOrderPayload, { note: note });
    }
    if (customAmount > 0 || customPercentage > 0) {
      Object.assign(createOrderPayload, { discountAmount: discountAmount });
    }
    axios
      .post(`${ENVARS.API.BASE_URL}/api/v2/orders`, createOrderPayload, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${LocalStorageService.getLocalData(
            'authToken',
          )}`,
        },
      })
      .then(async r => {
        setIsCashDialog(false);
        setOrder(r.data);
        setCartItems([]);
        setTaxAmount(0);
        setconvenienceFee(0);
        setFinalAmount(0);
        setLoading(false);
        setPosCash(true);
        setDiscountAmount(0);
        setCustomPercentage(0);
        setCustomAmount(0);
        setSelectedDiscount('');
        setNote('');
      })
      .catch(error => {
        console.log('Error while creating order', error);
        setLoading(false);
        setIsCashCompletedDialogOpen(false);
        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'error',
        });
      });
  };
  const onClickShowDiscountPopup = async () => {
    const { venueId, storeId } = location?.state.item;
    const { data } = await PromoService.storePromos(venueId, storeId);
    const updatedState = data.map((promo: any) => ({
      name: promo.code,
      price: promo.discountAmount,
      percent: promo.discountPercentage,
    }));
    setPromo(updatedState);
    setShowDiscounts(true);
  };
  const onClickAddPromo = async () => {
    if (promo.length > 0 && selectedDiscount) {
      const { venueId, storeId } = location?.state.item;
      axiosWithCart
        .post(`cart/${cartId}/promo`, {
          code: selectedDiscount,
          storeId: storeId,
          venueId: venueId,
        })
        .then(response => {
          setDiscountAmount(response.data.discountAmt);
          enqueueSnackbar('promo added successfully', {
            variant: 'success',
          });
          setDiscountApplied(true);
          setShowDiscounts(false);
        })
        .catch(err => {
          enqueueSnackbar('failed to add promo || invalid promo', {
            variant: 'error',
          });
        });
      return;
    }
  };
  const handleCustomDiscount = async () => {
    try {
      if (customPercentage > 0 || customAmount > 0) {
        setShowCustomDiscounts(false);
        const { venueId, storeId } = location?.state.item;
        const finalCustomValue = (
          (subTotal / 100) *
          Number(customPercentage)
        ).toFixed(2);
        if (customAmount > 0 && Number(customAmount) <= subTotal) {
          await axiosWithCart.post(`cart/${cartId}/customDiscount`, {
            discountAmount: customAmount,
            storeId: storeId,
            venueId: venueId,
          });
          setDiscountAmount(Number(customAmount));
          enqueueSnackbar('Discount Amount added successfully', {
            variant: 'success',
          });
          setDiscountApplied(true);
          setShowDiscounts(false);
        } else if (customPercentage > 0 && finalCustomValue <= subTotal) {
          await axiosWithCart.post(`cart/${cartId}/customDiscount`, {
            discountAmount: finalCustomValue,
            storeId: storeId,
            venueId: venueId,
          });
          setDiscountAmount(Number(finalCustomValue));
          enqueueSnackbar('Discount Percentage added successfully', {
            variant: 'success',
          });
          setDiscountApplied(true);
          setShowDiscounts(false);
        } else {
          enqueueSnackbar('Discount should be less than the total', {
            variant: 'warning',
          });
        }
      }
    } catch (err) {
      setShowCustomDiscounts(true);
      enqueueSnackbar('Error While Handling Custom Discount', {
        variant: 'warning',
      });
    }
  };
  const handleCustomDiscountonUpdateCart = async (
    cartSubTotal: number,
    totalItems: number,
  ) => {
    try {
      const { venueId, storeId } = location?.state.item;
      if (totalItems < 1) {
        setDiscountApplied(false);
        setDiscountAmount(0);
        setCustomAmount(0);
        setCustomPercentage(0);
      } else {
        if (customPercentage > 0 || customAmount > 0) {
          setDiscountApplied(true);
          setShowDiscounts(false);
          const { venueId, storeId } = location?.state.item;
          const finalCustomValue = (
            (cartSubTotal / 100) *
            Number(customPercentage)
          ).toFixed(2);
          if (customAmount > 0 && Number(customAmount) <= cartSubTotal) {
            await axiosWithCart.post(`cart/${cartId}/customDiscount`, {
              discountAmount: customAmount,
              storeId: storeId,
              venueId: venueId,
            });
            setDiscountAmount(Number(customAmount));
          } else if (
            customPercentage > 0 &&
            Number(finalCustomValue) <= cartSubTotal
          ) {
            await axiosWithCart.post(`cart/${cartId}/customDiscount`, {
              discountAmount: finalCustomValue,
              storeId: storeId,
              venueId: venueId,
            });
            setDiscountAmount(Number(finalCustomValue));
          }
        }
      }
    } catch (error) {
      enqueueSnackbar('Error While Handling Custom Discount', {
        variant: 'warning',
      });
    }
  };
  const handleDiscountSelection = (event: any) => {
    setSelectedDiscount(event.target.value);
    setCustomPercentage(0);
    setCustomAmount(0);
  };
  const handleManagerPinChange = (event: any) => {
    setDiscountPin(event.target.value);
  };
  const onCashClose = () => {
    setReturnAmount('0');
    setIsAmountError(false);
  };
  const customDiscountHandleChange = (type: string, val: number) => {
    if (type === 'amount') {
      setCustomAmount(val);
      setCustomPercentage(0);
      setSelectedDiscount('');
      return;
    }
    if (type === 'percentage') {
      setCustomPercentage(val);
      setCustomAmount(0);
      setSelectedDiscount('');
      return;
    }
  };
  const handleFetchProducts = () => {
    setFetchProducts(true);
    setShowCustomProduct(false);
  };
  const handleFetchProductsFalse = () => {
    setFetchProducts(false);
    setShowCustomProduct(false);
  };
  const filterProductWithQuantity = async () => {
    const productQuantityDict: any = {};

    for (const item of cartItems) {
      if (!productQuantityDict[item.productId]) {
        productQuantityDict[item.productId] = {
          quantity: item.quantity || 0,
          name: item.name, // Add item name
        };
      } else {
        productQuantityDict[item.productId].quantity += item.quantity || 0;
      }

      for (const subSelectionCategory of item.subSelections) {
        for (const subSelection of subSelectionCategory.subSelections) {
          if (subSelection.productId) {
            if (!productQuantityDict[subSelection.productId]) {
              productQuantityDict[subSelection.productId] = {
                quantity: subSelection.quantity * item.quantity,
                name: subSelection.name, // Add subSelection name
              };
            } else {
              productQuantityDict[subSelection.productId].quantity +=
                subSelection.quantity * item.quantity;
            }
          }
        }
      }
    }
    const productQuantityArray = Object.keys(productQuantityDict).map(
      productId => ({
        productId,
        quantity: productQuantityDict[productId].quantity,
        name: productQuantityDict[productId].name,
      }),
    );
    return productQuantityArray;
  };
  const handleInventoryQuantity = async (Products: any[]) => {
    for (let i = 0; i < Products.length; i++) {
      const { productId, quantity, name } = Products[i];
      const currentProduct = await fetchProduct(productId);
      if (currentProduct && currentProduct?.countAsInventory) {
        const { data } = await InventoryService.getProductInventory(productId);
        if (data && data.active) {
          if (data.inventory_count < quantity) {
            return {
              success: false,
              message: name
                ? `${name} is out of stock`
                : 'item is out of stock',
            };
          }
        }
      }
    }
    return {
      success: true,
      message: `item avaialbe`,
    };
  };
  const getProductIdsFromOrder = async () => {
    const produtIds: any[] = [];
    for (let i = 0; i < order.lineItems.length; i++) {
      const currentItem: any = order.lineItems[i];
      if (!produtIds.includes(currentItem?.product?.id)) {
        produtIds.push(currentItem?.product?.id);
      }
      for (const subSelectionCategory of currentItem?.subSelections) {
        for (const subSelection of subSelectionCategory.subSelections) {
          if (
            subSelection?.productId &&
            !produtIds.includes(subSelection?.productId)
          ) {
            produtIds.push(subSelection?.productId);
          }
        }
      }
    }
    const outOfStockProducts: any[] = [];
    if (produtIds.length > 0) {
      for (let j = 0; j < produtIds.length; j++) {
        const currentProduct: any = await fetchProduct(produtIds[j]);
        if (
          currentProduct &&
          currentProduct.countAsInventory &&
          currentProduct.stock < 1
        ) {
          outOfStockProducts.push(currentProduct.name);
        }
      }
    }
    setOutOfStockItems(outOfStockProducts);
    if (outOfStockProducts.length > 0) {
      setFetchProducts(true);
      setOutOfStockPopUp(true);
    }
  };
  // const onDeleteCart = async () => {
  //   try {
  //     if (existingCart && existingCart.id) {
  //       await axiosWithCart.delete(`/cart/${existingCart.id}`);
  //     }
  //     setShowCartPopup(false);
  //     setExistingCart({});
  //     setCartItems([]);
  //     setAddOnLoading(false);
  //     setLoading(false);
  //     setProductloading(false);
  //   } catch (error) {
  //     enqueueSnackbar('Error while Deleting Cart', {
  //       variant: 'error',
  //     });
  //     setShowCartPopup(false);
  //     setExistingCart({});
  //     setCartItems([]);
  //     setAddOnLoading(false);
  //     setLoading(false);
  //     setProductloading(false);
  //   }
  // };
  // useEffect(() => {
  //   return () => {
  //     axiosWithAuth
  //       .delete(`/access-terminal/${userId}`)
  //       .then(response => {
  //         console.log('access delete');
  //       })
  //       .catch(err => {
  //         console.log('error ');
  //       });
  //   };
  // }, []);
  const onDeleteCart = async () => {
    try {
      if (cartId) {
        await axiosWithCart.delete(`/cart/${cartId}`);
      }
      setShowCartPopup(false);
      setExistingCart({});
      setCartItems([]);
      setAddOnLoading(false);
      setLoading(false);
      setProductloading(false);
    } catch (error) {
      enqueueSnackbar('Error while Deleting Cart', {
        variant: 'error',
      });
      setShowCartPopup(false);
      setExistingCart({});
      setCartItems([]);
      setAddOnLoading(false);
      setLoading(false);
      setProductloading(false);
    }
  };

  return (
    <>
      {/* <div className="home"> */}
      {/* <Navbar /> */}
      <div className="main_container_pos">
        <header className="_heading_container">
          <span>{location?.state.item?.terminalName}</span>
          <button
            className="_top_secondaryBtn"
            onClick={() => history.goBack()}
          >
            Back to Manager Portal
          </button>
        </header>
        <div className="_tabs-cart-container">
          <div className="_tabs">
            <PosTabs
              fetchProducts={fetchProducts}
              handleFetchProductsFalse={handleFetchProductsFalse}
              addToCart={handleAddToCart}
              Productloading={Productloading}
            />
          </div>
          <div className="_cart">
            <CheckoutCart
              addToCart={handleAddToCart}
              removeItem={removeFromCart}
              decrementItem={decrementFromCart}
              taxAmount={taxAmount}
              selectedItems={selectedItems}
              discountAmount={discountAmount}
              convenienceFee={convenienceFee}
            />
          </div>
        </div>
        <div className="_button_container">
          <div className="_button_container_left">
            {cartItems.length > 0 && discountAmount === 0 && (
              <>
                <div className="_button_container_left_right">
                  <button
                    className="_button_discounts"
                    onClick={onClickShowDiscountPopup}
                  >
                    DISCOUNTS
                  </button>
                </div>
              </>
            )}
            <div className="_button_container_left_right">
              <button
                className="_button_addNote"
                onClick={() => {
                  setShowNotePopup(true);
                }}
              >
                Add Note
              </button>
            </div>
            <div className="_button_container_left_right">
              <button
                className="_button_discounts"
                onClick={() => {
                  setShowCustomProduct(true);
                }}
              >
                Add Custom Product
              </button>
            </div>
          </div>
          <div className="_button_container_right">
            <button onClick={onClickClearCart} className="_button_clear">
              Clear All
            </button>
            <button className="_button_checkout" onClick={handleCheckoutDailog}>
              Checkout
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>

      <Dialog
        open={isCheckoutDialogOpen}
        // onClose={handleCheckoutDailog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ textAlign: 'center', width: '100%' }}
        >
          {' '}
          Select payment method ...
        </DialogTitle>
        <DialogContent>
          {/* <div style={{ marginLeft: '20px' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClickPresent}
              style={{ textTransform: 'capitalize', marginBottom: '20px' }}
              className="testButtons"
            >
              Simulated terminal
            </Button>
          </div> */}
          <div>
            {/* <p style={{ textAlign: 'center' }}>
              ORDER - 139410
            </p> */}
            {loading ? (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <CircularProgress size={100} />
              </div>
            ) : (
              <div>
                {isCardPayment ? (
                  <>
                    {!currentUrl.includes('https://venue.fanfoodapp.com') && (
                      <div
                        style={{ textAlign: 'center', marginBottom: '20px' }}
                      >
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={onClickPresent}
                        >
                          Test Payment
                        </Button>
                      </div>
                    )}
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        style={{ textAlign: 'center' }}
                        variant="outlined"
                        className="_button_adjust"
                        onClick={onClickCapture}
                      >
                        Confirm Payment
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="_buttom_container">
                      <div className="_buttom_container">
                        {terminal?.isPOSCash ? (
                          <Button
                            variant="outlined"
                            className="_button_adjust"
                            onClick={onClickCash}
                            style={{ marginLeft: '130px' }}
                          >
                            <span
                              style={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                color: 'black',
                              }}
                            >
                              ${(finalAmount - convenienceFee).toFixed(2)}
                            </span>

                            <span
                              style={{
                                fontSize: '20px',
                                color: 'white',
                                marginLeft: '10px',
                              }}
                            >
                              Cash
                            </span>
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              className="_button_view"
                              onClick={onClickCreate}
                            >
                              <span
                                style={{
                                  fontSize: '22px',
                                  fontWeight: 'bold',
                                  color: 'black',
                                  marginBottom: '40px',
                                }}
                              >
                                ${finalAmount.toFixed(2)}
                              </span>
                              <span
                                style={{
                                  fontSize: '20px',
                                  color: 'black',
                                  marginLeft: '-85px',
                                  marginTop: '10px',
                                }}
                              >
                                Credit Card
                              </span>
                            </Button>
                            <Button
                              variant="outlined"
                              className="_button_adjust"
                              onClick={onClickCash}
                            >
                              <span
                                style={{
                                  fontSize: '22px',
                                  fontWeight: 'bold',
                                  color: 'black',
                                  marginBottom: '40px',
                                }}
                              >
                                ${(finalAmount - convenienceFee).toFixed(2)}
                              </span>

                              <span
                                style={{
                                  fontSize: '20px',
                                  color: 'black',
                                  marginLeft: '-57px',
                                  marginTop: '10px',
                                }}
                              >
                                Cash
                              </span>
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {loading ? (
            <></>
          ) : (
            // <div style={{ textAlign: 'center' }}>
            //   <CircularProgress size={100} />
            // </div>
            <div style={{ textAlign: 'center', display: 'flex' }}>
              {/* <div style={{ marginLeft: '20px' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClickCreate}
                  style={{ textTransform: 'capitalize' }}
                  className="testButtons"
                >
                  Start process
                </Button>
              </div> */}

              {/* <div 
                        style={{ marginLeft:'20px'}}
                        >
                          <Button color="primary" variant="outlined"
                           onClick={onClickProcess}
                           style={{textTransform:'capitalize'}}
                           className='testButtons'
                          >
                            Process
                          </Button>
                          
                        </div> */}

              {/* <div 
                        style={{ marginLeft:'20px'}}
                        >
                          <Button color="primary" variant="outlined"
                          onClick={onClickConfirm}
                           style={{textTransform:'capitalize'}}
                           className='testButtons'
                          >
                            Confirm
                           
                          </Button>
                          
                        </div> */}
              <div style={{ marginLeft: '20px' }}>
                {/* <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClickCapture}
                  style={{ textTransform: 'capitalize' }}
                  className="testButtons"
                >
                  Capture
                </Button> */}

                {/* <Button
                            variant="outlined"  className="testButtons"
                            onClick={onClickCreditCard}
                          >
                            Credit Card
                          </Button> */}
                {/* <Button
                            variant="outlined"  className="testButtons"
                            onClick={onClickCash}
                          >
                            Cash
                          </Button> */}
              </div>
              {/* <h3 style={{ marginLeft: '10px' }}>check statuses</h3>
              <div style={{ marginLeft: '20px' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClickCancel}
                  style={{ textTransform: 'capitalize' }}
                  className="testButtons"
                >
                  Cancel Process
                </Button>
              </div> */}

              {/* <div style={{ marginLeft: '20px' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClickReaderDisplay}
                  style={{ textTransform: 'capitalize' }}
                  className="testButtons"
                >
                  check status
                </Button>
              </div> */}

              {/* <div style={{ marginLeft: '20px' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClickGetReader}
                  style={{ textTransform: 'capitalize' }}
                  className="testButtons"
                >
                  Get Reader
                </Button>
              </div> */}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={() =>
                isCardPayment
                  ? setIsConfirmationDialog(true)
                  : setIsCheckoutDialogOpen(false)
              }
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Confirmation diaglog to cancel process */}
      <Dialog
        open={isConfirmationDialog}
        // onClose={handleCheckoutDailog}
        aria-labelledby="form-dialog-title"
        // fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ textAlign: 'center', width: '100%' }}
        >
          Are you sure, you want to cancel the process ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <div style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCheckoutDailogClose}
            >
              Yes
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setIsConfirmationDialog(false)}
            >
              No
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={isOrderCompltedDialogOpen}
        classes={{ paper: classes.dialog }}
        maxWidth="md"
      
    <Dialog open={isOrderCompltedDialogOpen} classes={{ paper: classes.dialog }} 
      maxWidth="md">
      <DialogTitle className={classes.title}>{`The Amount  has been Captured Successfully!!`}</DialogTitle>      
       <DialogContent>
       <div style={{ textAlign: 'center' }}>
              ORDER - {orderNumber}
        </div>
       </DialogContent>
      <DialogActions>
      <Button 
        onClick={handleProceedReceipt} 
        className={classes.proceedButton}
        autoFocus>
          Print Receipt
        </Button>
        <Button 
         onClick={handleCloseOrderComplete} 
         className={classes.closeButton} 
         color="primary">
          Complete Order
        </Button>
      </DialogActions>
    </Dialog>
    {loading ?
              (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={100} />
                </div>
              ) :(
                <Dialog open={iscashCompltedDialogOpen} classes={{ paper: classes.dialog }} 
      maxWidth="md">
      <DialogTitle className={classes.title}>{`The Amount Due Back To The Customer :- $ ${returnAmount}`}</DialogTitle> 
      <DialogActions>
      <Button 
        onClick={handleCashReceipt} 
        className={classes.proceedButton}
        autoFocus>
          Print Receipt
        </Button>
        <Button 
         onClick={handleTransactionComplete} 
         className={classes.closeButton} 
         color="primary">
          Complete Order
        </Button>
      </DialogActions>
    </Dialog>
              )
      }
    

    <Dialog open={isAddOnDialogOpen} onClose={handlecloseAddOn} maxWidth="md" fullWidth={true}>
      <DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton onClick={handlecloseAddOn} style={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
          {product?.name}
          <div style={{ width: '48px' }} />
        </Box>
      </DialogTitle>
      <DialogContent>
      <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>
        {product?.category?.name}
      </div>
      <div style={{ fontSize: '1rem', marginBottom: '1rem' }}>
        Please select your food:
      </div>
      {
        product?.subCategories?.length>0 &&(
          product.subCategories.map((e:any)=>(
            e.subProducts?.map((item:any, index:number) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedItems.some(x => x.id === item.id)}
                    onChange={()=>handleCheckboxChange(item)}
                    value={item.id}
                  />
                }
                label={`${item.name}  (${item.price})$` }
              />
            ))
          ))
        )
      }
        <TextField label="Special Instructions" value={instructions} onChange={handleInstructionsChange} fullWidth />
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
          <Button variant="contained" color='primary' onClick={handleAddToBag} style={{ width: '50%', minWidth: '150px', backgroundColor: '#DA112E'}}>
            Add to cart
          </Button>
        </Box>
      </DialogContent>
    </Dialog>

      
      <Dialog open={false} onClose={handlePaymentSuccessDailog} aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className={classes.title}
        >{`The Amount  has been Captured Successfully!!`}</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center' }} ref={contentRef}>
            ORDER -
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePrintReceipt}
            className={classes.proceedButton}
            autoFocus
          >
            Print Receipt
          </Button>
          <Button
            onClick={handleCloseOrderComplete}
            className={classes.closeButton}
            color="primary"
          >
            Complete Order
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={iscashCompletedDialogOpen}
        classes={{ paper: classes.dialog }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            minHeight: '400px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={100} />
          </div>
        ) : (
          <>
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
              Order Number {order?.orderNo}
            </DialogTitle>
            {isPosCash ? (
              <DialogContent
                style={{ flex: '1', overflow: 'revert', textAlign: 'center' }}
              >
                <div className="noPrint">
                  <Typography
                    variant="h5"
                    component="p"
                    style={{ margin: '10px 0' }}
                  >
                    Payment Received
                  </Typography>

                  <Typography
                    variant="h6"
                    component="p"
                    style={{ margin: '10px 0', fontSize: 'x-large' }}
                  >
                    $ {enteredAmount}
                  </Typography>
                </div>
                <DialogContent style={{ textAlign: 'center' }}>
                  <div className="noPrint">
                    <Typography
                      variant="h5"
                      component="p"
                      style={{ margin: '10px 0' }}
                    >
                      Please Provide Customer
                    </Typography>
                    <Typography
                      variant="h6"
                      component="p"
                      style={{
                        margin: '10px 0',
                        fontWeight: 'bold',
                        fontSize: 'xx-large',
                        visibility: 'visible',
                      }}
                    >
                      $ {returnAmount}
                    </Typography>
                  </div>
                </DialogContent>
              </DialogContent>
            ) : (
              <DialogContent
                style={{ textAlign: 'center', overflow: 'revert' }}
              >
                <div className="noPrint">
                  <Typography
                    variant="h5"
                    component="p"
                    style={{ margin: '10px 0' }}
                  >
                    {' '}
                    {`The Amount of $ ${
                      order?.total ? (order?.total).toFixed(2) : 0
                    } has been Captured Successfully!!`}
                  </Typography>
                </div>
              </DialogContent>
            )}
            <DialogContent>
              <div style={{ textAlign: 'center' }} ref={contentRef}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>Name :</div>
                  <div style={{ textAlign: 'right' }}>
                    {order?.user?.firstName} {order?.user?.lastName}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>Phone:</div>
                  <div style={{ textAlign: 'right' }}>
                    {order?.user?.phoneNumber}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>Time:</div>
                  <div style={{ textAlign: 'right' }}>
                    {new Date(order.createdTime).toLocaleDateString()}
                  </div>
                </div>
                {order?.lineItems?.map((e: any, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '10px',
                          textAlign: 'left',
                        }}
                      >
                        <div>
                          {e?.name} ({e?.quantity} @ {e?.productPrice})
                        </div>
                        <div>{e?.total?.toFixed(2)}</div>
                      </div>
                      {e?.subSelections?.length > 0 &&
                        e?.subSelections?.map((g: any, subIndex: number) => {
                          return g?.subSelections?.map(
                            (f: any, nestedIndex: number) => (
                              <div
                                key={`${index}-${subIndex}-${nestedIndex}`}
                                style={{
                                  textAlign: 'left',
                                  marginLeft: '15px',
                                }}
                              >
                                {f?.name}
                              </div>
                            ),
                          );
                        })}
                    </div>
                  );
                })}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>Sub Total</div>
                  <div style={{ textAlign: 'right' }}>
                    {order?.subTotal?.toFixed(2)}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  {venueTaxCaluculated && (
                    <>
                      <div style={{ textAlign: 'left' }}>Sales Tax</div>
                      <div style={{ textAlign: 'right' }}>
                        {order?.taxAmt?.toFixed(2)}
                      </div>
                    </>
                  )}
                </div>
                {order?.discountAmt > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>Discount Amount</div>
                    <div style={{ textAlign: 'right' }}>
                      {order?.discountAmt?.toFixed(2)}
                    </div>
                  </div>
                )}
                {order?.convenienceFee > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>POS Charges</div>
                    <div style={{ textAlign: 'right' }}>
                      {order?.convenienceFee?.toFixed(2)}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>Order Total</div>
                  <div style={{ textAlign: 'right' }}>
                    {order?.total?.toFixed(2)}
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogContent>
                <>
                  <div className="noPrint">
                    <Button
                      onClick={async () => {
                        await getProductIdsFromOrder();
                        setReturnAmount('0');
                        setEnteredAmount('0');
                        setTaxAmount(0);
                        setconvenienceFee(0);
                        setDiscountApplied(false);
                        setDiscountAmount(0);
                        setIsCashCompletedDialogOpen(false);
                      }}
                      className={classes.closeButton}
                      color="primary"
                    >
                      Complete Order
                    </Button>
                    <Button
                      onClick={handleGenerateReceipt}
                      className={classes.proceedButton}
                      autoFocus
                    >
                      Print Receipt
                    </Button>
                  </div>
                </>
              </DialogContent>
            </DialogActions>
          </>
        )}
      </Dialog>
      {/* <Dialog
        open={isAddOnDialogOpen}
        onClose={handlecloseAddOn}
        maxWidth="md"
        fullWidth={true}
      >
        {addOnLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={100} />
          </div>
        ) : (
          <>
            <DialogTitle>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  onClick={handlecloseAddOn}
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
                {product?.name}
                <div style={{ width: '48px' }} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>
                {product?.category?.name}
              </div>
              <div style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                Please select your food:
              </div>
              {product?.subCategories?.length > 0 &&
                product.subCategories.map((e: any) => (
                  <>
                    <p>
                      {e.name}
                      {e.required ? (
                        <span style={{ color: 'red' }}>
                          {' '}
                          upto{e?.selectionLimit > 0 ? e?.selectionLimit : 1}
                        </span>
                      ) : (
                        <span style={{ color: '#474350' }}>
                          {' '}
                          upto{e?.selectionLimit > 0 ? e?.selectionLimit : 1}
                        </span>
                      )}
                    </p>
                    {e.subProducts?.map((item: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={selectedItems.some(
                              x => x.id === item.id && x.parentId === e.id,
                            )}
                            onChange={() => handleCheckboxChange(e.id, item.id)}
                            value={item.id}
                            disabled={
                              (e.selectionLimit >= 1 &&
                                selectedItems.filter(x => x.parentId === e.id)
                                  .length >= e.selectionLimit &&
                                !selectedItems.some(
                                  x => x.id === item.id && x.parentId === e.id,
                                )) ||
                              (!e.selectionLimit &&
                                selectedItems.some(
                                  x => x.parentId === e.id && x.id !== item.id,
                                ))
                            }
                          />
                        }
                        label={`${item.name}  (${item.price})$`}
                      />
                    ))}
                  </>
                ))}
              <TextField
                label="Special Instructions"
                value={instructions}
                onChange={handleInstructionsChange}
                fullWidth
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="1rem"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={product?.subCategories?.some(
                    (e: any) =>
                      e.required &&
                      !selectedItems.some(x => x.parentId === e.id),
                  )}
                  onClick={handleAddToBag}
                  style={{
                    width: '50%',
                    minWidth: '150px',
                    backgroundColor: '#DA112E',
                    cursor: product?.subCategories?.some(
                      (e: any) =>
                        e.required &&
                        !selectedItems.some(x => x.parentId === e.id),
                    )
                      ? 'not-allowed'
                      : 'pointer',
                  }}
                >
                  Add to cart
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog> */}
      <Dialog
        open={isAddOnDialogOpen}
        onClose={handlecloseAddOn}
        fullWidth
        PaperProps={{
          style: {
            margin: '0 0 9.3px',
            borderRadius: '13.1px',
            backgroundColor: '#fff',
            maxWidth: '60%',
          },
        }}
      >
        {addOnLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={100} />
          </div>
        ) : (
          <>
            <DialogTitle>
              <IconButton
                onClick={handlecloseAddOn}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div>
              <DialogContent
                style={{
                  overflowY: 'auto',
                  position: 'relative',
                  paddingTop: 0,
                  paddingBottom: 20,
                }}
              >
                <div
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    color: '#474350',
                    fontFamily: 'Roboto "Helvetica" Arial  sans-serif',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    lineHeight: '1.1',
                    letterSpacing: 'normal',
                    marginLeft: '15px',
                    wordWrap: 'break-word',
                    width: '330px',
                  }}
                >
                  {product?.name?.charAt(0)?.toUpperCase() +
                    product?.name?.slice(1)}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: 20,
                    height: '140px',
                    width: '160px',
                  }}
                >
                  <img
                    src={
                      product.imageUrl
                        ? product.imageUrl
                        : product.globalProduct &&
                          product.globalProduct.imageUrl
                    }
                    style={{
                      objectFit: 'contain',
                      maxHeight: '140px',
                    }}
                  />
                </div>
                <div
                  style={{
                    color: '#6e727cd9',
                    fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
                    fontSize: '14px',
                    marginTop: '10px',
                    marginLeft: '15px',
                    marginBottom: '25px',
                    wordWrap: 'break-word',
                    fontWeight: 'normal',
                    maxWidth: '40%',
                  }}
                >
                  {product.description ? product.description : ''}
                </div>
                <div
                  style={{
                    color: '#474350',
                    fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
                    fontSize: '14px',
                    marginTop: '50px',
                    marginLeft: '15px',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                  }}
                >
                  {product?.category?.name?.toUpperCase()}
                </div>
                <div
                  style={{
                    fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.29',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    marginTop: '10px',
                    color: ' #474350',
                    maxHeight: '250px',
                    overflowX: 'scroll',
                    direction: 'rtl',
                  }}
                >
                  <div style={{ direction: 'ltr' }}>
                    {product?.subCategories?.length > 0 &&
                      product.subCategories.map((e: any, eIndex: number) => (
                        <div key={e.id}>
                          <p
                            style={{
                              color: '#474350',
                              fontFamily:
                                'Roboto, "Helvetica", Arial, sans-serif',
                              marginTop: '0px',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              fontStretch: 'normal',
                              fontStyle: 'normal',
                              lineHeight: '1.29',
                              letterSpacing: 'normal',
                              textAlign: 'left',
                              marginLeft: '16px',
                              wordWrap: 'break-word',
                              marginBottom: '20px',
                            }}
                          >
                            {e?.name?.charAt(0)?.toUpperCase() +
                              e?.name?.slice(1)}
                            {e.required ? (
                              <span style={{ color: 'red' }}>
                                {'   '}
                                up to
                                {'  '}
                                {e?.selectionLimit > 0 ? e?.selectionLimit : 1}
                              </span>
                            ) : (
                              <span style={{ color: '#6e727cd9' }}>
                                {'   '}
                                up to{' '}
                                {e?.selectionLimit > 0 ? e?.selectionLimit : 1}
                              </span>
                            )}
                          </p>
                          {e.subProducts?.map((item: any, index: number) => (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                key={item.id}
                                style={{
                                  color: '#474350',
                                  fontFamily:
                                    'Roboto, "Helvetica", Arial, sans-serif',
                                  marginTop: '-15px',
                                  marginLeft: '18px',
                                  wordWrap: 'break-word',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedItems.some(
                                        x =>
                                          x.id === item.id &&
                                          x.parentId === e.id,
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          e.id,
                                          item.id,
                                          item.productId,
                                        )
                                      }
                                      value={item.id}
                                      disabled={
                                        (e.selectionLimit >= 1 &&
                                          selectedItems.filter(
                                            x => x.parentId === e.id,
                                          ).length >= e.selectionLimit &&
                                          !selectedItems.some(
                                            x =>
                                              x.id === item.id &&
                                              x.parentId === e.id,
                                          )) ||
                                        (!e.selectionLimit &&
                                          selectedItems.some(
                                            x =>
                                              x.parentId === e.id &&
                                              x.id !== item.id,
                                          ))
                                      }
                                      icon={
                                        eIndex === 0 ? (
                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                        ) : (
                                          <RadioButtonUncheckedIcon fontSize="small" />
                                        )
                                      }
                                      checkedIcon={
                                        eIndex === 0 ? (
                                          <CheckBoxIcon fontSize="small" />
                                        ) : (
                                          <CheckCircleIcon fontSize="small" />
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    item?.name
                                      ? `${item?.name
                                          .charAt(0)
                                          ?.toUpperCase()}${item?.name.slice(
                                          1,
                                        )}`
                                      : ''
                                  }
                                  style={{
                                    fontSize: '14px',
                                    color: '#474350',
                                    marginBottom: '10px',
                                    fontFamily:
                                      'Roboto, "Helvetica", Arial, sans-serif',
                                    wordWrap: 'break-word',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  fontFamily:
                                    'Roboto, "Helvetica", Arial, sans-serif',
                                  marginTop: '-5px',
                                  marginRight: '10px',
                                }}
                              >
                                $ {item?.price.toFixed(2)}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
                <div style={{ marginTop: '-3px' }}>
                  <TextField
                    value={instructions}
                    onChange={handleInstructionsChange}
                    className={classes.InstructionField}
                    fullWidth
                  />
                </div>
                <p
                  style={{
                    color: '#6e727cd9',
                    fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
                    marginTop: '2px',
                    fontSize: '14px',
                  }}
                >
                  optional
                </p>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="1rem"
                >
                  <Button
                    variant="contained"
                    disabled={product?.subCategories?.some(
                      (e: any) =>
                        e.required &&
                        !selectedItems.some(x => x.parentId === e.id),
                    )}
                    onClick={handleAddToBag}
                    style={{
                      width: '100%',
                      color: 'white',
                      margin: 'auto',
                      height: '55px',
                      borderRadius: '9px',
                      backgroundColor: '#ee3653',
                      fontWeight: 'bold',
                      cursor: product?.subCategories?.some(
                        (e: any) =>
                          e.required &&
                          !selectedItems.some(x => x.parentId === e.id),
                      )
                        ? 'not-allowed'
                        : 'pointer',
                    }}
                  >
                    Add to cart
                  </Button>
                </Box>
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>

      {/* <Dialog
        open={isPaymentConfirmationDialogOpen}
        onClose={handlePaymentSuccessDailog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          {' '}
          {`The Amount of $ ${
            finalAmount ? (finalAmount + taxAmount).toFixed(2) : 0
          } has been Captured Successfully!!`}
        </DialogTitle>
        <DialogContent>
          <Dialog
            open={isPaymentConfirmationDialogOpen}
            onClose={handlePaymentSuccessDailog}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
              {' '}
              {`The Amount has been Captured Successfully!!`}
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
              <div style={{ textAlign: 'center' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handlePaymentSuccessDailog}
                >
                  Back
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </DialogContent>
      </Dialog> */}

      <Dialog
        open={isCashDialog}
        onClose={onCashClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <div style={{ position: 'relative' }}>
          <Button
            style={{ position: 'absolute', top: 3, left: 10 }}
            onClick={async () => {
              setIsCashDialog(false);
            }}
          >
            <ArrowBack />
          </Button>
        </div>
        <DialogContent>
          <p
            className="calculater-heading"
            style={{ textAlign: 'center', fontSize: '22px' }}
          >
            Total Bill ${(finalAmount - convenienceFee).toFixed(2)}
          </p>
          <p className="calculater-heading">
            How much cash has the customer given you?
          </p>
          <p
            style={{
              margin: '0px',
              marginBottom: '11px',
              color: '#474350',
              fontSize: '30px',
            }}
          >
            Entered Amount : $ {parseFloat(enteredAmount).toFixed(2)}
          </p>

          <div style={{ width: '80%' }}>
            <div>
              <Button
                className="calculater-button"
                onClick={() => handleClick(1)}
              >
                1
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(2)}
              >
                2
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(3)}
              >
                3
              </Button>
            </div>

            <div>
              <Button
                className="calculater-button"
                onClick={() => handleClick(4)}
              >
                4
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(5)}
              >
                5
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(6)}
              >
                6
              </Button>
            </div>

            <div>
              <Button
                className="calculater-button"
                onClick={() => handleClick(7)}
              >
                7
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(8)}
              >
                8
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(9)}
              >
                9
              </Button>
            </div>

            <div>
              <Button
                className="calculater-button"
                onClick={() => setEnteredAmount('0')}
              >
                X
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick(0)}
              >
                0
              </Button>
              <Button
                className="calculater-button"
                onClick={() => handleClick('.')}
              >
                .
              </Button>
              <Button className="enter-button" onClick={calculateBill}>
                ENTER
              </Button>
            </div>
            {isAmountError ? (
              <p style={{ color: 'red' }}>
                ERROR: That amount is not enough for this order.
              </p>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showDiscounts} maxWidth="md">
        <DialogTitle>Available Discounts</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {promo.length > 0
                    ? 'Apply available discounts to order'
                    : 'No availble promo codes'}
                </FormLabel>
                {promo.length > 0 && (
                  <RadioGroup
                    value={selectedDiscount}
                    onChange={handleDiscountSelection}
                  >
                    {promo.map((option: any, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.name}
                        control={<Radio />}
                        label={`${option.name} ${
                          option.price > 0
                            ? `($${option.price})`
                            : `(${option.percent}%)`
                        }`}
                      />
                    ))}
                  </RadioGroup>
                )}
                {storeDiscount && (
                  <div>
                    <FormLabel
                      style={{
                        paddingTop: '20px',
                        fontSize: '1.2rem',
                        color: '#000',
                      }}
                    >
                      Add Custom Discount
                    </FormLabel>
                    <button
                      style={{
                        marginLeft: '10px',
                        marginTop: '0',
                        border: 'none',
                        borderRadius: '50px',
                        width: '40px',
                        height: '40px',
                        fontSize: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowDiscounts(false);
                        setDiscountAmount(0);
                        setShowCustomDiscounts(true);
                      }}
                    >
                      +
                    </button>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    autoFocus
                    className={classes.addToOrderButton}
                    onClick={onClickAddPromo}
                  >
                    Add to order
                  </Button>
                  <Button
                    className={classes.cancleDiscountButton}
                    color="primary"
                    onClick={() => {
                      setShowDiscounts(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog open={showCustomDiscounts} maxWidth="md">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{
                    paddingTop: '20px',
                    fontSize: '1.2rem',
                    color: '#000',
                  }}
                >
                  Add Custom Discount
                </FormLabel>
                <TextField
                  className="generic-dialog__field"
                  name="discountAmount"
                  type="string"
                  onChange={(e: any) =>
                    customDiscountHandleChange('amount', e.target.value)
                  }
                  label="Discount Amount"
                  value={customAmount}
                  autoComplete="off"
                  inputProps={{
                    min: '0',
                    step: 0.01,
                    type: 'number',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className="generic-dialog__field"
                  name="discountPercentage"
                  type="number"
                  onChange={(e: any) =>
                    customDiscountHandleChange('percentage', e.target.value)
                  }
                  label="Discount Percent"
                  value={customPercentage}
                  autoComplete="off"
                  inputProps={{
                    min: '0',
                    step: 0.01,
                    type: 'number',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    autoFocus
                    className={classes.addToOrderButton}
                    onClick={handleCustomDiscount}
                  >
                    Add to order
                  </Button>
                  <Button
                    className={classes.cancleDiscountButton}
                    color="primary"
                    onClick={() => {
                      setShowCustomDiscounts(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog open={showCustomProduct} maxWidth="md">
        <DialogContent>
          <Grid container spacing={2}>
            <CustomProduct
              storeContext={location?.state.storeContext}
              venue={venueData}
              fetchProducts={handleFetchProducts}
              handleFetchProductsFalse={handleFetchProductsFalse}
            />
            {/* {showCustomProduct && product && venue && store &&
              <AddEditProductForm
                allowFreeProducts={venue.allowFreeProducts}
                categories={categories}
                currencyPrefix={getCurrencyPrefix(venue.currency)}
                fulfillmentCenters={fulfillmentCenters}
                onClose={() => setProduct(undefined)}
                onSubmit={onSubmitProductForm}
                product={product}
                store={store}
                taxRates={taxRates}
              />
            } */}

            {/* <Grid item xs={12} md={7}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{
                    paddingTop: '20px',
                    fontSize: '1.2rem',
                    color: '#000',
                  }}
                >
                  Add Custom Product
                </FormLabel>
                <TextField
                  className="generic-dialog__field"
                  name="customProductName"
                  type="string"
                  onChange={(e: any) => {
                    console.log('Custom Product Name');
                  }}
                  label="Product Name"
                  value={customProductName}
                  autoComplete="off"
                  inputProps={{
                    min: '0',
                    step: 0.01,
                    type: 'number',
                  }}
                />
                <TextField
                  className="generic-dialog__field"
                  name="customProductPrice"
                  type="number"
                  onChange={(e: any) => {
                    console.log('Custom Product Price');
                  }}
                  label="Price"
                  value={customProductPrice}
                  autoComplete="off"
                  inputProps={{
                    min: '0',
                    step: 0.01,
                    type: 'number',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    autoFocus
                    className={classes.addToOrderButton}
                    onClick={() => {
                      console.log('Add to Store');
                    }}
                  >
                    Add to Store
                  </Button>
                  <Button
                    className={classes.cancleDiscountButton}
                    color="primary"
                    onClick={() => {
                      setShowCustomProduct(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog open={outOfStockPopUp} maxWidth="sm">
        <DialogTitle>
          Following Products are now out of Stock and Marked Inactive
        </DialogTitle>
        <DialogContent>
          <div className="dialogue-box">
            <ul style={{ listStyle: 'inside maroon circle' }}>
              {outOfStockItems.map((product, index) => (
                <li key={index}>{product}</li>
              ))}
            </ul>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Button
                onClick={() => {
                  setOutOfStockPopUp(false);
                  setOutOfStockItems([]);
                }}
                className={classes.closeButton}
                style={{
                  maxWidth: '130px',
                  maxHeight: '40px',
                  minWidth: '129px',
                  minHeight: '40px',
                }}
                color="primary"
              >
                close
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showNotePopup}
        maxWidth="md"
        onClose={() => {
          setNote('');
          setShowNotePopup(false);
        }}
      >
        <DialogContent className="dialogCustom">
          <Grid container spacing={2} className="dialogInner">
            <Grid item xs={12} md={9}>
              <FormControl component="fieldset">
                <TextField
                  className="generic-dialog__field"
                  name="note"
                  type="string"
                  onChange={(e: any) => setNote(e.target.value)}
                  label="Note"
                  value={note}
                  autoComplete="off"
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    autoFocus
                    className={classes.addToOrderButton}
                    onClick={() => {
                      setNote(note);
                      setShowNotePopup(false);
                    }}
                  >
                    Add to order
                  </Button>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        open={showCartPopup}
        onClose={() => {
          setShowCartPopup(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: 'center' }}
          >
            you can only be logged into one terminal at a time with a user and
            you are currently logged in. You have to remove existing cart.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={onDeleteCart}
            style={{
              backgroundColor: '#EE3653',
              color: 'white',
              border: 'none',
            }}
          >
            Clear Cart
          </Button>
          <Button
            onClick={() => {
              setShowCartPopup(false);
            }}
            color="primary"
            variant="outlined"
          >
            No Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PosTerminal;
