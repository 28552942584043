import IProduct, { IProductTaxes } from '../../models/Product.model';
import axiosWithAuth, { IPaginatedItems } from '../ServiceConfig';

const baseUrl = `/products`;
const baseUrlInventory = `/inventory`;

const createProduct = (body: any) =>
  axiosWithAuth.post<IProduct>(baseUrl, body);

const getStoreProducts = (
  storeId: string,
  limit?: number,
  page?: number,
  categoryId: string = '',
) =>
  axiosWithAuth.get<IPaginatedItems<IProduct>>(baseUrl, {
    params: {
      storeId,
      ...(limit && { limit }),
      ...(page && { page }),
      ...(categoryId && { categoryId }),
    },
  });

const getProduct = (productId: string) =>
  axiosWithAuth.get<IProduct>(`${baseUrl}/${productId}`);



const updateProduct = (productId: string, body: any) =>
  axiosWithAuth.put<IProduct>(`${baseUrl}/${productId}`, body);

const updateProductImage = (productId: string, body: any) =>
  axiosWithAuth.put<IProduct>(`${baseUrl}/${productId}/image`, body);

const getProductTaxes = (productId: string) =>
  axiosWithAuth.get<IProductTaxes>(`${baseUrl}/${productId}/taxes`);

const updateProductTaxes = (productId: string, taxRateIds: string[]) =>
  axiosWithAuth.put<IProductTaxes>(`${baseUrl}/${productId}/taxes`, {
    taxRateIds,
  });

const deleteProduct = (productId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${productId}`);

export default {
  createProduct,
  deleteProduct,
  getProduct,
  getProductTaxes,
  getStoreProducts,
  updateProduct,
  updateProductImage,
  updateProductTaxes,
};
