import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';
import { isUndefined } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { useAppContext } from '../../../config/AppContext';
import {
  IVenueLocation,
  VenueLocationType,
} from '../../../models/Location.model';
import './VenueLocationDialog.scss';

interface IVenueLocationDialog {
  location: IVenueLocation | undefined;
  locationAction: (values: IVenueLocation) => Promise<void>;
  onClose: () => void;
  parent: IVenueLocation | undefined;
}

const VenueLocationValidationSchema = {
  categoryName: Yup.string().notRequired(),
  isCustomInput: Yup.boolean().notRequired(),
  name: Yup.string().required(),
  type: Yup.string().required(),
};

const VenueLocationDialog = ({
  location,
  locationAction,
  onClose,
  parent,
}: IVenueLocationDialog) => {
  const { venue } = useAppContext();

  if (!venue) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: 'location-dialog',
      }}
    >
      <DialogTitle id="form-dialog-title" className="section-title">
        <span className="section-title">
          {!isUndefined(location) ? 'Edit Location' : 'Add Location'}
        </span>
        <div className="form-required-field">* Required Field</div>
      </DialogTitle>
      <DialogContent>
        <div className="location-form">
          <Formik
            initialValues={
              location || {
                categoryName: '',
                id: '',
                isCustomInput: false,
                isDeliveryLocation: true,
                locations: [],
                name: '',
                parentId: parent ? parent.id : null,
                type: !!parent ? parent.type : VenueLocationType.REGULAR,
                venueId: venue.id as string,
              }
            }
            onSubmit={async values => {
              await locationAction(values);
            }}
            validationSchema={VenueLocationValidationSchema}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid spacing={1} container={true} className="container">
                  <Grid item={true} xs={10} className="form-field-container">
                    <InputLabel className="required">Name</InputLabel>
                    <TextField
                      fullWidth={true}
                      required={true}
                      error={touched.name && !!errors.name}
                      className="location-form__field"
                      name="name"
                      inputProps={{
                        type: 'string',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      render={() => (
                        <div className="error">Name is a required field</div>
                      )}
                      name="name"
                    />
                  </Grid>

                  <Grid item={true} xs={10} className="form-field-container">
                    <InputLabel>
                      Nested Label (Optional)
                      <br />
                      <small>
                        Will show up as the dropdown label for the next section
                        in our apps.
                        <br />
                        e.g. a location "Section 1" may have a Nested Label:
                        "Row"
                      </small>
                    </InputLabel>
                    <TextField
                      fullWidth={true}
                      error={touched.categoryName && !!errors.categoryName}
                      className="location-form__field"
                      name="categoryName"
                      inputProps={{
                        type: 'string',
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.categoryName}
                      autoComplete="off"
                    />
                  </Grid>
                  {!parent && (
                    <Grid item={true} xs={10} className="form-field-container">
                      <InputLabel>Type</InputLabel>
                      <Select
                        name="type"
                        required={true}
                        className="location-form__field width-45"
                        value={values.type}
                        onChange={handleChange}
                      >
                        <MenuItem value={VenueLocationType.REGULAR}>
                          {VenueLocationType.REGULAR}
                        </MenuItem>
                        <MenuItem value={VenueLocationType.SUITE}>
                          {VenueLocationType.SUITE}
                        </MenuItem>
                      </Select>
                    </Grid>
                  )}
                  {values.isDeliveryLocation && (
                    <Grid item={true} xs={10} className="form-field-container">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="checkbox"
                            name="isCustomInput"
                            checked={values.isCustomInput || false}
                            onChange={handleChange}
                          />
                        }
                        label="Allow Custom User Input"
                      />
                    </Grid>
                  )}
                  <Grid item={true} xs={12} className="buttons-container">
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={onClose}
                        variant="outlined"
                      >
                        <div className="icon-title-wrapper">
                          <span className="icon-title">Cancel</span>
                        </div>
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={Object.keys(errors).length > 0}
                      >
                        <div className="icon-title-wrapper">
                          <span className="icon-title">
                            {!isUndefined(location) ? 'Update' : 'Save'}
                          </span>
                        </div>
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VenueLocationDialog;
