import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import classNames from 'classnames';
import { ErrorMessage, Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import * as Yup from 'yup';
import { IPromotionUser } from '../../../models/PromotionUser.model';
import { unMaskPhoneNumber } from '../../../services/Util/Util.service';

const validationSchema = Yup.object().shape(
  {
    countryCode: Yup.string().default('1'),
    email: Yup.string()
      .email('Must be a valid email address')
      .min(5, 'Email must be at least 5 characters')
      .max(255, 'Email must be fewer than 256 characters')
      .when('phoneNumber', {
        is: val => !!val,
        otherwise: Yup.string().required('Email or phone number required'),
        then: Yup.string(),
      }),
    firstName: Yup.string()
      .max(100, 'First name must be fewer than 100 characters')
      .notRequired(),
    lastName: Yup.string()
      .max(100, 'Last name must be fewer than 100 characters')
      .notRequired(),
    phoneNumber: Yup.string()
      .min(10, 'Phone number must be at least 10 characters')
      .when('email', {
        is: val => !!val,
        otherwise: Yup.string().required('Email or phone number required'),
        then: Yup.string(),
      }),
  },
  [['email', 'phoneNumber']],
);

interface IPromoUserDialogProps {
  onClose: () => void;
  onSubmit: (promoUser: Partial<IPromotionUser>) => Promise<void>;
  promoUser: Partial<IPromotionUser>;
}

const PromoUserDialog = (props: IPromoUserDialogProps) => {
  const { onClose, onSubmit, promoUser } = props;
  const phoneNumberUtil = PhoneNumberUtil.getInstance();

  const initialValues = Object.assign(
    {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    cloneDeep(promoUser),
  );
  // default `countryCode` to '1' even if we send up empty later
  initialValues.countryCode = initialValues.countryCode || '1';

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" {...{ onClose }}>
      <DialogTitle id="form-dialog-title" className="section-title">
        <span className="section-title">
          {initialValues.id ? 'Edit User' : 'Add User'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="generic-dialog">
          <Formik
            onSubmit={async values => {
              const phoneNumber =
                unMaskPhoneNumber(formatPhoneNumber(values.phoneNumber)) ||
                values.phoneNumber ||
                '';
              const countryCode = phoneNumber ? values.countryCode : '';
              await onSubmit({
                ...values,
                countryCode,
                phoneNumber,
              });
            }}
            {...{
              initialValues,
              validationSchema,
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoComplete="off"
                  className="generic-dialog__field"
                  error={touched.firstName && !!errors.firstName}
                  label="First name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="firstName"
                />

                <TextField
                  autoComplete="off"
                  className="generic-dialog__field"
                  error={touched.lastName && !!errors.lastName}
                  label="Last name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="lastName"
                />

                <TextField
                  autoComplete="off"
                  className="generic-dialog__field"
                  error={touched.email && !!errors.email}
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                />
                <ErrorMessage className="error" component="div" name="email" />

                <div className="form-container">
                  <InputLabel
                    className="user-form__field phoneInput"
                    error={touched.phoneNumber && !!errors.phoneNumber}
                    shrink={true}
                  >
                    Phone number
                  </InputLabel>
                  <PhoneInput
                    defaultCountry={phoneNumberUtil.getRegionCodeForCountryCode(
                      Number(values.countryCode),
                    )}
                    placeholder="999-999-9999"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    className={classNames(
                      'user-form__phone-container',
                      touched.phoneNumber && !!errors.phoneNumber && 'error',
                    )}
                    numberInputProps={{
                      className: 'user-form__phone-input',
                    }}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('phoneNumber', e)}
                    onCountryChange={(code: string = '') => {
                      if (!isEmpty(code)) {
                        setFieldValue(
                          'countryCode',
                          getCountryCallingCode(code),
                        );
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="countryCode"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="phoneNumber"
                  />
                </div>

                <div className="button-holder-different-margin">
                  <DialogActions>
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                    >
                      <div className="icon-title-wrapper">
                        <UndoOutlinedIcon />
                        <span className="icon-title">Cancel</span>
                      </div>
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                      <div className="icon-title-wrapper">
                        <CheckOutlinedIcon />
                        <span className="icon-title">
                          {initialValues.id ? 'Update' : 'Add'}
                        </span>
                      </div>
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PromoUserDialog;
