import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import AddImage from '../AddImage/AddImage';
import './AddGlobalProduct.scss';

interface IProps {
  addGlobalProduct: any;
  closeDialog: any;
  selectedBrandId?: string;
  selectedBrandUrl?: string;
  selectedBrandName?: string;
}
class AddGlobalProduct extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      disabledButton: true,
      globalProductName: this.props.selectedBrandName || '',
      image: '',
    };
  }

  public checkFileStatus = () => {
    const { image } = this.state;
    return image.length === 0 ? false : true;
  };

  public setImageFile = (file: any) => {
    if (file.name) {
      this.setState({ image: file });
    } else {
      this.setState({ image: '' });
    }
  };

  public handleGlobalProductAction = () => {
    const { globalProductName, image } = this.state;
    const { addGlobalProduct } = this.props;
    const formData = new FormData();
    formData.append('name', globalProductName);
    if (image) {
      formData.append('imageFile', image);
    }
    addGlobalProduct(formData);
  };

  public setGlobalProductName = (newName: string) => {
    this.setState({ globalProductName: newName });
  };

  public render() {
    const {
      closeDialog,
      selectedBrandId,
      selectedBrandUrl,
      selectedBrandName,
    } = this.props;
    const { globalProductName } = this.state;
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
        onClose={closeDialog}
        classes={{
          paper: 'global-products-dialog',
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <span className="section-title">
            {selectedBrandId ? 'Update Global Product' : 'New Global Product'}
          </span>
        </DialogTitle>
        <DialogContent classes={{ root: 'global-product-dialog-content' }}>
          <AddImage
            width={200}
            height={160}
            showName={true}
            previewBottom={false}
            getName={this.setGlobalProductName}
            getFile={this.setImageFile}
            showImageDetails={true}
            imageName={selectedBrandName}
            minHeight={400}
            minWidth={500}
          />
          {selectedBrandUrl && (
            <div>
              <img
                src={selectedBrandUrl}
                alt={`${selectedBrandName}`}
                width="200"
                height="160"
              />
            </div>
          )}
        </DialogContent>
        <div className="button-holder-similar-margin">
          <DialogActions>
            <Button onClick={closeDialog} color="primary" variant="outlined">
              <div className="icon-title-wrapper">
                <UndoOutlinedIcon />
                <span className="icon-title">Cancel</span>
              </div>
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                selectedBrandId
                  ? isEmpty(selectedBrandUrl) || isEmpty(globalProductName)
                  : !this.checkFileStatus() || isEmpty(globalProductName)
              }
              onClick={this.handleGlobalProductAction}
            >
              <div className="icon-title-wrapper">
                <CheckOutlinedIcon />
                <span className="icon-title">
                  {selectedBrandId
                    ? 'Update Global Product'
                    : 'Add Global Product'}
                </span>
              </div>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default AddGlobalProduct;
