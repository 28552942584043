import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { ITaxRate } from '../../../models/TaxRate.model';
import { IAddOrUpdateTaxRateDto } from '../../../services/Venue/TaxRate.service';
export interface ITaxRateDialogProps {
  createOrUpdateTaxRate: (taxRate: Partial<ITaxRate>) => Promise<void>;
  onClose: () => void;
  taxRate: Partial<ITaxRate>;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  percent: Yup.number()
    .min(0, 'Percent Cannot Be Negative')
    .max(100, 'Percent Cannot Be Greater Than 100'),
});

export const TaxRateDialog = ({
  createOrUpdateTaxRate,
  onClose,
  taxRate,
}: ITaxRateDialogProps) => {
  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" {...{ onClose }}>
      <DialogTitle id="form-dialog-title" className="section-title">
        <span className="section-title">
          {taxRate.id ? 'Edit Custom Tax Rate' : 'Add Custom Tax Rate'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="generic-dialog">
          <Formik<IAddOrUpdateTaxRateDto>
            onSubmit={async values => await createOrUpdateTaxRate(values)}
            initialValues={{
              ...taxRate,
              applyToAllProducts: false,
            }}
            validationSchema={validationSchema}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              setFieldValue,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoComplete="off"
                  className="generic-dialog__field"
                  error={touched.name && !!errors.name}
                  label="Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />
                <ErrorMessage className="error" component="div" name="name" />
                <TextField
                  autoComplete="off"
                  className="generic-dialog__field"
                  error={touched.percent && !!errors.percent}
                  label="Percent"
                  name="percent"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.percent}
                  type="number"
                  inputProps={{
                    min: '0',
                    step: '0.01',
                    type: 'number',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  className="error"
                  component="div"
                  name="percent"
                />
                {!taxRate.id && (
                  <FormControlLabel
                    className="generic-dialog__field "
                    control={
                      <Checkbox
                        className="checkbox"
                        checked={values.applyToAllProducts}
                        onChange={(e, checked) =>
                          setFieldValue('applyToAllProducts', checked)
                        }
                      />
                    }
                    label={<span>Apply to Entire Venue</span>}
                  />
                )}

                <div className="button-holder-different-margin">
                  <DialogActions>
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                    >
                      <div className="icon-title-wrapper">
                        <UndoOutlinedIcon />
                        <span className="icon-title">Cancel</span>
                      </div>
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid}
                    >
                      <div className="icon-title-wrapper">
                        <CheckOutlinedIcon />
                        <span className="icon-title">Save</span>
                      </div>
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};
