import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import ExportToExcel from '../../components/ExportToExcel';
import { noDataAvailable } from '../../components/NoDataAvailable';
import { useAppContext } from '../../config/AppContext';
import { IStore } from '../../models/Store.model';
import { isStoreManager } from '../../services/Auth.service';
import ReportingService from '../../services/Reporting/Reporting.service';
import BasicReport, {
  getWorksheets,
  IHasCurrency,
  IReportColumn,
  renderDataCell,
  renderReportHeader,
  ReportColumnType,
} from './BasicReport';

interface IPayoutDetailProps {
  data: IPayoutDataRecord[];
  onChangeStore: (storeId: string) => void;
  selectedStoreId: string | undefined;
  venueId: string;
}

export interface IPayoutDataRecord extends IHasCurrency {
  amount: number;
  date: string;
  id: string;
  pos_order: boolean;
}

const payoutDetailColumns: Array<IReportColumn<IPayoutDataRecord>> = [
  {
    accessor: record => record.id,
    name: 'ID',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.date,
    name: 'Payout Date',
    type: ReportColumnType.date,
  },
  {
    accessor: record => record.amount,
    name: 'Amount',
    type: ReportColumnType.currency,
  },
];

const payoutOrderColumns: Array<IReportColumn<IPayoutOrderDataRecord>> = [
  {
    accessor: record => record.orderNo,
    name: 'Order Number',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.date,
    name: 'Order Date',
    type: ReportColumnType.date,
  },
  {
    accessor: record => record.owedToLocation,
    name: 'Owed',
    total: true,
    type: ReportColumnType.currency,
  },
];

const PayoutDetail = (props: IPayoutDetailProps): JSX.Element => {
  const { data, onChangeStore, selectedStoreId, venueId } = props;
  const { stores, user } = useAppContext();

  const exportName = 'payout-overview';

  const handleStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    onChangeStore(newSelectedStoreId);
  };

  return (
    <div>
      <div className="records-container">
        {/* {isStoreManager(user, venueId) && ( */}
        <div className="search-groupby">
          <p className="label">Store: </p>
          <Select
            value={selectedStoreId || 'All Stores'}
            onChange={handleStoreSelect}
            classes={{
              root: 'select-store',
            }}
          >
            <MenuItem value="All Stores">All Stores</MenuItem>
            {stores.map((store: IStore) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {/* )} */}
        <ExportToExcel
          buttonText="Export Overview to Excel"
          filename={exportName}
          getWorksheets={() =>
            getWorksheets(data, payoutDetailColumns, exportName)
          }
        />
      </div>
      <Table className="reporting__table">
        <TableHead>
          <TableRow>{renderReportHeader(payoutDetailColumns)}</TableRow>
        </TableHead>
        <TableBody>
          {data.length <= 0
            ? noDataAvailable(payoutDetailColumns.length)
            : data.map((record: IPayoutDataRecord, rix: number) => (
                <React.Fragment key={rix}>
                  <TableRow>
                    {payoutDetailColumns.map(
                      (
                        column: IReportColumn<IPayoutDataRecord>,
                        cix: number,
                      ) => (
                        <React.Fragment key={cix}>
                          {renderDataCell(column, record)}
                        </React.Fragment>
                      ),
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={payoutDetailColumns.length}
                      align="center"
                    >
                      <PayoutOrderExpando
                        payoutId={record.id}
                        storeId={selectedStoreId}
                        venueId={venueId}
                        posOrder={record.pos_order}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
        </TableBody>
      </Table>
    </div>
  );
};

interface IPayoutOrderExpandoProps {
  payoutId: string;
  storeId?: string;
  venueId: string;
  posOrder: boolean;
}

interface IPayoutOrderDataRecord extends IHasCurrency {
  date: string;
  id: string;
  orderNo: number;
  owedToLocation: number;
  status: string;
}

const PayoutOrderExpando = (props: IPayoutOrderExpandoProps): JSX.Element => {
  const { payoutId, storeId, venueId, posOrder } = props;

  const [data, setData] = React.useState<IPayoutOrderDataRecord[]>([]);
  const [hasFetched, setHasFetched] = React.useState<boolean>(false);
  const [showExpanded, setShowExpanded] = React.useState<boolean>(false);
  const exportName = 'payout-expanded-details';

  const fetchData = async () => {
    try {
      const { data: orders } = await ReportingService.getPayoutOrders(
        venueId,
        payoutId,
        storeId,
        posOrder,
      );
      setData(orders);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
    } finally {
      setHasFetched(true);
    }
  };

  return (
    <>
      {showExpanded && (
        <BasicReport
          {...{ columns: payoutOrderColumns, exportName, data, isAdmin: false }}
        />
      )}
      <Button
        color="primary"
        onClick={() => {
          if (!hasFetched) {
            fetchData();
          }
          setShowExpanded(!showExpanded);
        }}
        size="small"
        variant="contained"
      >
        {showExpanded ? 'Hide' : 'Expand'}
      </Button>
    </>
  );
};

export default PayoutDetail;
