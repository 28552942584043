import { Button, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import BackButton from '../../../components/BackButton';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import DeleteDialog from '../../../components/DeleteDialog';
import { useAppContext } from '../../../config/AppContext';
import { useStoreContext } from '../../../config/StoreContext';
import ICategory from '../../../models/Category.model';
import { Partner } from '../../../models/Partner.model';
import IProduct, { AvailabilityType } from '../../../models/Product.model';
import ProductService from '../../../services/Product/Product.service';
import InventoryService from '../../../services/Inventory/Inventory.service';
import ProductTagService from '../../../services/ProductTag/ProductTag.service';
import { getCurrencyPrefix } from '../../../services/Util/Util.service';
import { VALID_CATEGORY_TYPES } from '../Categories/Category.config';
import AddEditProductForm from './AddEditProductForm';
import './Menu.scss';
import MenuTable from './MenuTable';

const Menu = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setShowSpinner, taxRates, venue, productTags } = useAppContext();
  const {
    categories,
    fetchProducts,
    fulfillmentCenters,
    products,
    productPagination,
    selectedProductCategoryId,
    setSelectedProductCategoryId,
    store,
  } = useStoreContext();

  const [product, setProduct] = useState<IProduct | undefined>(undefined);
  const [productIdForDelete, setProductIdForDelete] = useState<
    string | undefined
  >();

  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalCount,
  } = productPagination;

  const category = categories.find(c => c.id === selectedProductCategoryId);
  const isOptionsCategory = !!(
    category && category.type === VALID_CATEGORY_TYPES.OPTIONS
  );

  if (!store || !venue) {
    return null;
  }

  const selectNewProduct = () => {
    if (categories.length === 0) {
      return enqueueSnackbar(
        'You need to add a category before adding products',
        { variant: 'error' },
      );
    }

    const initialProductState = {
      active: true,
      alcohol: false,
      availabilityType: AvailabilityType.ALL,
      categoryId: selectedProductCategoryId || categories[0].id,
      tagId: '',
      fulfillmentCenterId: store.id,
      hasSubProducts: false,
      id: '',
      name: '',
      partner: Partner.FANFOOD,
      partnerProductId: '',
      price: 0,
      priority: 1,
      stock: 0,
      storeId: store.id,
      subCategories: [],
      taxable: true,
      pos: false,
      countAsInventory: false,
      inventoryCount: 0,
      testAvailabilityType: ['POS', 'DELIVERY', 'PICKUP'],
      tags: [],
    };
    setProduct(initialProductState);
  };

  const selectProduct = async (productId: string) => {
    setShowSpinner(true);
    try {
      const { data: productData }: any = await ProductService.getProduct(
        productId,
      );
      const availability = [];
      if (productData?.availabilityType === 'ALL') {
        availability.push('PICKUP', 'DELIVERY');
      } else if (productData?.availabilityType !== 'NONE') {
        availability.push(productData?.availabilityType);
      }
      if (productData.pos) {
        availability.push('POS');
      }
      productData.testAvailabilityType = availability;
      const { data } = await InventoryService.getProductInventory(productId);
      if (data) productData.inventoryCount = data.inventory_count;
      setProduct(productData);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const toggleProductActive = async (productId: string, active: boolean) => {
    setShowSpinner(true);
    try {
      await ProductService.updateProduct(productId, { active });
      await fetchProducts();
      enqueueSnackbar('Product status successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const onSubmitProductForm = async () => {
    setProduct(undefined);
    setPage(0);
    await fetchProducts();
  };

  const onDeleteProduct = async () => {
    if (!productIdForDelete) {
      return;
    }

    setShowSpinner(true);
    try {
      await ProductService.deleteProduct(productIdForDelete);
      setProductIdForDelete(undefined);
      await fetchProducts();
      enqueueSnackbar('Product successfully deleted', { variant: 'success' });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  if (!venue) {
    return null;
  }

  return (
    <Fragment>
      <div className="menu__title-row">
        {product && (
          <BackButton
            onClick={(e: React.MouseEvent) => setProduct(undefined)}
          />
        )}
        <div className="menu__title-store-category">
          <p>
            Store: <span className="menu__store-name">{store.name}</span>
          </p>
          {!product && (
            <div className="menu__title-category">
              <InputLabel className="menu__title-category__label">
                Category
              </InputLabel>
              <Select
                autoWidth={true}
                classes={{ select: 'menu__title-category__select' }}
                onChange={(event: any) =>
                  setSelectedProductCategoryId(event.target.value)
                }
                value={selectedProductCategoryId || ''}
                variant="filled"
              >
                {categories.map((c: ICategory, i: number) => (
                  <MenuItem
                    key={`menu__title-category__select-value__${i}`}
                    value={c.id}
                  >
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      </div>

      {/* TODO: Separate this out into its own standalone route instead of being under the Menu context */}
      {product && (
        <AddEditProductForm
          allowFreeProducts={venue.allowFreeProducts}
          categories={categories}
          currencyPrefix={getCurrencyPrefix(venue.currency)}
          fulfillmentCenters={fulfillmentCenters}
          onClose={() => setProduct(undefined)}
          onSubmit={onSubmitProductForm}
          product={product}
          store={store}
          taxRates={taxRates}
          tags={productTags}
        />
      )}

      {!product && (
        <div className="pagination">
          <div className="menu__pagination-toolbar">
            <CustomPagination
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              totalCount={totalCount}
            />
            <Grid>
              <Button
                size="large"
                color="primary"
                classes={{ root: 'menu-product__add-button' }}
                onClick={() => selectNewProduct()}
              >
                <div className="icon-title-wrapper">
                  <AddOutlinedIcon />
                  <span className="icon-title">Add Product</span>
                </div>
              </Button>
            </Grid>
          </div>
          <MenuTable
            isOptionsCategory={isOptionsCategory}
            onDeleteProduct={(productId: string) =>
              setProductIdForDelete(productId)
            }
            onSelectProduct={(productId: string) => selectProduct(productId)}
            products={products}
            toggleProductActive={toggleProductActive}
            venue={venue}
          />
        </div>
      )}
      {productIdForDelete && (
        <DeleteDialog
          dialogTitle="Delete Product from Store"
          dialogContent="Are you sure you want to remove this Product?"
          onClose={() => setProductIdForDelete(undefined)}
          onSubmit={onDeleteProduct}
        />
      )}
    </Fragment>
  );
};

export default Menu;
