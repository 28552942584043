import React from 'react';
import ConfirmDialog from './ConfirmDialog';

const DeleteDialog = (props: any) => {
  const { dialogTitle, dialogContent, onClose, onSubmit } = props;

  return (
    <ConfirmDialog
      content={dialogContent}
      onClose={onClose}
      onSubmit={onSubmit}
      submitText="Delete"
      title={dialogTitle}
    />
  );
};

export default DeleteDialog;
