import { UserRole } from '../../models/User.model';
import { ReportType } from '../../services/Reporting/Reporting.service';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service';
const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
export enum ReportSection {
  Admin,
  Venue,
}

export interface IReport {
  name: string;
  roles: UserRole[];
  sections: ReportSection[];
  showTagFilter: boolean;
  type: ReportType;
}

export const Reports: IReport[] = [
  {
    name: 'Dashboard',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Admin, ReportSection.Venue],
    showTagFilter: true,
    type: ReportType.VENUE_DASHBOARD,
  },
  ...(tenant_schema === 'true'
    ? [
        {
          name: 'Member Report',
          roles: [
            UserRole.SUPER_ADMIN,
            UserRole.FANFOOD_ADMIN,
            UserRole.REGULAR,
          ],
          sections: [ReportSection.Admin, ReportSection.Venue],
          showTagFilter: true,
          type: ReportType.MEMBER_REPORT,
        },
      ]
    : []),
  {
    name: 'Venue Revenue Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Admin, ReportSection.Venue],
    showTagFilter: true,
    type: ReportType.VENUE_REVENUE,
  },
  {
    name: 'Venue Event Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Admin, ReportSection.Venue],
    showTagFilter: true,
    type: ReportType.VENUE_EVENT_SUMMARY,
  },
  {
    name: 'Venue Product Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Venue],
    showTagFilter: false,
    type: ReportType.VENUE_PRODUCT,
  },
  {
    name: 'Runner Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Admin, ReportSection.Venue],
    showTagFilter: false,
    type: ReportType.RUNNER,
  },
  {
    name: 'Promo Code Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Admin, ReportSection.Venue],
    showTagFilter: false,
    type: ReportType.PROMOTIONS,
  },
  {
    name: 'Payout Detail',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Venue],
    showTagFilter: false,
    type: ReportType.PAYOUT_DETAIL,
  },
  {
    name: 'Flash Report',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN],
    sections: [ReportSection.Admin],
    showTagFilter: true,
    type: ReportType.FLASH_REPORT,
  },
  {
    name: 'User Overview',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN],
    sections: [ReportSection.Admin],
    showTagFilter: false,
    type: ReportType.USER_OVERVIEW,
  },
  {
    name: 'User Detail',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN],
    sections: [ReportSection.Admin],
    showTagFilter: true,
    type: ReportType.USER_DETAIL,
  },
  {
    name: 'Building Revenue Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN],
    sections: [ReportSection.Admin],
    showTagFilter: true,
    type: ReportType.BUILDING_REVENUE_SUMMARY,
  },
  {
    name: 'Building User Detail',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN],
    sections: [ReportSection.Admin],
    showTagFilter: true,
    type: ReportType.BUILDING_USER_DETAIL,
  },
  {
    name: 'Refund Summary',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Venue],
    showTagFilter: false,
    type: ReportType.REFUND_SUMMARY,
  },
  {
    name: 'Tax Report',
    roles: [UserRole.SUPER_ADMIN, UserRole.FANFOOD_ADMIN, UserRole.REGULAR],
    sections: [ReportSection.Venue],
    showTagFilter: false,
    type: ReportType.TAX_REPORT,
  },
];

export interface IVenueRevenueSummary {
  convenienceFeeShare: number;
  tipShare: number;
  discountShare: number;
  id?: any;
  isDeleted: boolean;
  name: string;
  orderCount: number;
  totalOrderAmt: number;
  venueRevenueShare: number;
  totalTax: number;
  gatewayFees: number;
  totalOwedToVenue: number;
  totalSales: number;
}

export interface IPromoCodeSummary {
  code: string;
  totalSold: number;
  totalValue: number;
}

export interface IVenueProduct extends IVenueProductBase {
  subProducts: IVenueProductBase[];
}

export interface IVenueProductBase {
  name: string;
  tag?: string;
  itemsSold: number;
  revenue: number;
  stock?: number;
}

export interface IVenueDashboard {
  averages: IVenueDashboardAverages;
  orders: IVenueDashboardOrders;
  top: IVenueDashboardTop;
  totals: IVenueDashboardTotals;
  tableData: [];
}

export interface IVenueDashboardAverages {
  deliveryTime: number;
  fulfillmentTime: number;
  pickupTime: number;
  orderAmt: number;
  orderSize: number;
}

export interface IVenueDashboardTop {
  categories: IVenueDashboardTopCategory[];
  products: IVenueDashboardTopProduct[];
  stores: IVenueDashboardStore[];
}

export interface IVenueDashboardTopCategory {
  name: string;
  quantity: number;
}

export interface IVenueDashboardTopProduct {
  name: string;
  quantity: number;
}

export interface IVenueDashboardOrders {
  formattedTimes: IVenueDashboardTime[];
  stores: IVenueDashboardStore[];
  runners: IVenueDashboardRunner[];
  times: string[];
}

export interface IVenueDashboardRunner {
  count: number;
  id: string;
  name: string;
}

export interface IVenueDashboardStore {
  count: number;
  id: string;
  isDeleted: boolean;
  items: number;
  name: string;
  totalAmount: number;
}

export interface IVenueDashboardTime {
  count: number;
  name: string;
}

export interface IVenueDashboardTotals {
  deliveryOrders: number;
  orders: number;
  pickupOrders: number;
  promosUsed: number;
  sales: number;
}

export const emptyDashboard: IVenueDashboard = {
  averages: {
    deliveryTime: 0,
    fulfillmentTime: 0,
    orderAmt: 0.0,
    orderSize: 0.0,
    pickupTime: 0,
  },
  orders: {
    formattedTimes: [],
    runners: [],
    stores: [],
    times: [],
  },
  top: {
    categories: [],
    products: [],
    stores: [],
  },
  totals: {
    deliveryOrders: 0,
    orders: 0,
    pickupOrders: 0,
    promosUsed: 0,
    sales: 0,
  },
  tableData: [],
};

export const venueProductSummaryHeaders = [
  'Tag',
  'Product Name',
  'Total Sold',
  'Total Revenue',
];

export const taxReportSummaryHeaders = [
  'Tax name',
  'Tax Percentage',
  'Tax Amount',
];

export const memberReportSummaryHeaders = [
  'Member Id',
  'Area',
  'Location',
  'Cover',
  'Order Number',
  'Item',
  'description',
  'Quantity',
  'Price',
  'Tax',
  'Total',
];

export const getAccessibleReports = (role: UserRole, section: ReportSection) =>
  Reports.filter((report: IReport) =>
    report.roles.includes(role),
  ).filter((report: IReport) => report.sections.includes(section));
