import { ITag } from '../models/Tag.model';
import axiosWithAuth from './ServiceConfig';

const baseUrl = `/tags`;

const create = (name: string) => axiosWithAuth.post(baseUrl, { name });

const getAll = () => axiosWithAuth.get<ITag[]>(`${baseUrl}`);

export default {
  create,
  getAll,
};
