import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import {
  Link,
  Route,
  RouteComponentProps,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { useAppContext } from '../../config/AppContext';
import { getGlobalTabs, IGlobalTab } from '../TabConfig';

interface IProps extends RouteComponentProps {
  assignTabValue: (newTabValue: string) => void;
  tabValue: string;
}

const HomeTab = (props: IProps): JSX.Element | null => {
  const { assignTabValue, tabValue } = props;
  const handleSwitchTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    assignTabValue(newValue);
  };

  const { user } = useAppContext();
  const { pathname } = useLocation();

  if (!user) {
    return null;
  }

  if (pathname.match('/home/venue-details')) {
    return null;
  }

  const globalTabs = getGlobalTabs(user);
  return (
    <div className="home-tabs-container">
      <Tabs
        value={tabValue}
        onChange={handleSwitchTab}
        textColor="secondary"
        classes={{
          indicator: 'indicator',
          root: 'home__tabs',
        }}
      >
        {globalTabs.map((tab: IGlobalTab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            component={Link}
            to={tab.to}
            value={tab.value}
          />
        ))}
      </Tabs>
      {globalTabs.map((tab: IGlobalTab) => (
        <Route
          exact={true}
          key={`${tab.value}_route`}
          path={tab.to}
          render={(childProps: any) => (
            <tab.component {...childProps} {...tab.props} />
          )}
        />
      ))}
    </div>
  );
};

export default withRouter(HomeTab);
