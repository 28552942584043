import {
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../config/AppContext';
import { useStoreContext } from '../../../config/StoreContext';
import {
  IVenueLocation,
  IVenueLocationType,
  VenueLocationType,
} from '../../../models/Location.model';
import { ValidStoreType } from '../../../models/Store.model';
import { isSuperAdmin } from '../../../services/Auth.service';
import StoreService from '../../../services/Store/Store.service';
import './StoreDialog.scss';

interface IStoreLocationTypes {
  [locationTypeId: string]: string[];
}

interface IStoreLocationTypeIds {
  [locationTypeId: string]: string;
}

export const StoreLocations = () => {
  const { user, venue, venueLocations, venueSuites } = useAppContext();
  const { fetchStore, store, storeLocations } = useStoreContext();
  const { enqueueSnackbar } = useSnackbar();

  const [storeLocationTypes, setStoreLocationTypes] = useState<
    IStoreLocationTypes
  >({});
  const [storeLocationTypeIds, setStoreLocationTypeIds] = useState<
    IStoreLocationTypeIds
  >({});
  const [storeSuites, setStoreSuites] = useState<IVenueLocation[]>([]);
  const [storeSections, setStoreSections] = useState<IVenueLocation[]>([]);
  const [expandedLocationId, setExpandedLocationId] = useState<
    string | undefined
  >();

  const storeSuiteIds = storeSuites.map(suite => suite.id);
  const storeSectionIds = storeSections.map(section => section.id);

  useEffect(() => {
    const newStoreLocationTypes: IStoreLocationTypes = {};
    const oldStoreLocationTypeIds: IStoreLocationTypeIds = {};

    (store?.validLocationTypes || []).forEach(lt => {
      oldStoreLocationTypeIds[lt.locationTypeId] = lt.id;
      newStoreLocationTypes[lt.locationTypeId] = lt.validValues || [];
    });

    setStoreLocationTypes(newStoreLocationTypes);
    setStoreLocationTypeIds(oldStoreLocationTypeIds);
  }, [store]);

  useEffect(() => {
    const sections = storeLocations
      .filter(sl => sl.includeNested)
      .map(sl => sl.venueLocation);
    setStoreSections(sections);

    const suites = storeLocations
      .map(sl => sl.venueLocation)
      .filter(
        vl => vl.type === VenueLocationType.SUITE && vl.isDeliveryLocation,
      );
    setStoreSuites(suites);
  }, [storeLocations]);

  const handleSectionSelection = (locationType: IVenueLocationType) => {
    const values = storeLocationTypes[locationType.id];
    if (isEmpty(values)) {
      setStoreLocationTypes(prev => ({
        ...prev,
        [locationType.id]: locationType.validValues || [],
      }));
    } else {
      setStoreLocationTypes(prev => ({
        ...prev,
        [locationType.id]: [],
      }));
    }
  };

  const handleValueSelection = (
    locationType: IVenueLocationType,
    value: string,
  ) => {
    const values = storeLocationTypes[locationType.id] || [];
    if (values.includes(value)) {
      setStoreLocationTypes(prev => ({
        ...prev,
        [locationType.id]: values.filter(v => v !== value),
      }));
    } else {
      setStoreLocationTypes(prev => ({
        ...prev,
        [locationType.id]: [...values, value],
      }));
    }
  };

  const onSelectAllSuites = () => {
    if (storeSuiteIds.length === venueSuites.length) {
      setStoreSuites([]);
    } else {
      setStoreSuites(venueSuites);
    }
  };

  const isStoreSuiteChecked = (location: IVenueLocation) => {
    return storeSuiteIds.includes(location.id);
  };

  const toggleSuite = (location: IVenueLocation) => {
    if (isStoreSuiteChecked(location)) {
      setStoreSuites(storeSuites.filter(suite => suite.id !== location.id));
    } else {
      setStoreSuites([...storeSuites, location]);
    }
  };

  const onSelectAllSections = () => {
    if (storeSectionIds.length === venueLocations.length) {
      setStoreSections([]);
    } else {
      setStoreSections(venueLocations);
    }
  };

  const isStoreSectionChecked = (location: IVenueLocation) => {
    return storeSectionIds.includes(location.id);
  };

  const toggleSection = (location: IVenueLocation) => {
    if (isStoreSectionChecked(location)) {
      setStoreSections(
        storeSections.filter(section => section.id !== location.id),
      );
    } else {
      setStoreSections([...storeSections, location]);
    }
  };

  const saveLocations = async () => {
    if (!store) {
      return;
    }

    try {
      const newValidLocationTypes = Object.keys(storeLocationTypes).map(
        locationTypeId => ({
          id: storeLocationTypeIds[locationTypeId],
          locationTypeId,
          validValues: storeLocationTypes[locationTypeId],
        }),
      );
      await StoreService.updateStore(store.id, {
        validLocationTypes: newValidLocationTypes,
      });

      const newStoreLocations = [
        ...storeSuites.map(s => ({ venueLocationId: s.id })),
        ...storeSections.map(s => ({
          includeNested: true,
          venueLocationId: s.id,
        })),
      ];
      await StoreService.updateStoreLocations(store.id, {
        storeVenueLocations: newStoreLocations,
      });

      await fetchStore();

      enqueueSnackbar('Locations saved successfully', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log({ error });
      enqueueSnackbar('Error Saving Locations. Please Try again.', {
        variant: 'error',
      });
    }
  };

  if (!store || !venue || !user) {
    return null;
  }

  const superAdmin = isSuperAdmin(user);

  return (
    <div className="store-locations">
      <div className="store-dialog__locations">
        {store.type === ValidStoreType.SUITE_KITCHEN && (
          <StoreLocationList
            isLocationChecked={isStoreSuiteChecked}
            locations={venueSuites}
            selectAllLocations={onSelectAllSuites}
            selectedLocationIds={storeSuiteIds}
            subtitle="Select the Suites that this Suite Kitchen can delivery to."
            toggleLocation={toggleSuite}
            title="Suite Locations"
          />
        )}
        <div className="store-location-section">
          <Typography align="left" variant="h6">
            Delivery Sections
          </Typography>
          {superAdmin && (
            <Typography align="left" variant="subtitle1">
              Super Admin Note: These locations will soon be deprecated. Please
              build this venue out using the new Delivery Sections module.
            </Typography>
          )}

          {(venue.validLocationTypes || []).map(
            (locationType: IVenueLocationType) => {
              if (isEmpty(locationType.validValues)) {
                return null;
              }

              const values = storeLocationTypes[locationType.id] || [];
              const validValues = locationType.validValues || [];

              return (
                <ExpansionPanel
                  expanded={expandedLocationId === locationType.id}
                  key={locationType.id}
                  onChange={(e: any, expanded: boolean) =>
                    setExpandedLocationId(
                      expanded ? locationType.id : undefined,
                    )
                  }
                >
                  <ExpansionPanelSummary
                    classes={{
                      content: 'combo__category-panel__header',
                      expandIcon: 'combo__category-panel__header-icon',
                    }}
                    expandIcon={<ExpandLess />}
                  >
                    <FormControlLabel
                      label={locationType.name}
                      control={
                        <Checkbox
                          checked={values.length === validValues.length}
                          classes={{
                            indeterminate: 'primary',
                          }}
                          color="primary"
                          indeterminate={
                            values.length > 0 &&
                            values.length !== validValues.length
                          }
                          onChange={() => handleSectionSelection(locationType)}
                        />
                      }
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    classes={{
                      root: 'combo__category-panel__body',
                    }}
                  >
                    <FormGroup>
                      {validValues.map((value: string) => (
                        <FormControlLabel
                          key={value}
                          label={value}
                          control={
                            <Checkbox
                              checked={values.includes(value)}
                              onChange={() =>
                                handleValueSelection(locationType, value)
                              }
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            },
          )}
        </div>

        {superAdmin && store.type === ValidStoreType.REGULAR && (
          <StoreLocationList
            isLocationChecked={isStoreSectionChecked}
            locations={venueLocations}
            selectAllLocations={onSelectAllSections}
            selectedLocationIds={storeSectionIds}
            subtitle="Select the Sections that this Store can deliver to."
            toggleLocation={toggleSection}
            title="Delivery Sections"
          />
        )}

        <Grid item={true} xs={12} className="buttons-container">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={saveLocations}
          >
            <div className="icon-title-wrapper">
              <span className="icon-title">Save</span>
            </div>
          </Button>
        </Grid>
      </div>
    </div>
  );
};

interface IStoreLocationListProps {
  isLocationChecked: (l: IVenueLocation) => boolean;
  locations: IVenueLocation[];
  selectAllLocations: () => void;
  selectedLocationIds: string[];
  subtitle?: string;
  toggleLocation: (l: IVenueLocation) => void;
  title: string;
}

const StoreLocationList = ({
  isLocationChecked,
  locations,
  selectAllLocations,
  selectedLocationIds,
  subtitle,
  toggleLocation,
  title,
}: IStoreLocationListProps) => {
  return (
    <div className="store-location-section">
      <Typography align="left" variant="h6">
        {title}
      </Typography>
      {subtitle && (
        <Typography align="left" variant="subtitle1">
          {subtitle}
        </Typography>
      )}
      <List className="location-list">
        <ListItem
          button={true}
          onClick={selectAllLocations}
          disableRipple={true}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={locations.length === selectedLocationIds.length}
              tabIndex={-1}
            />
          </ListItemIcon>
          <ListItemText primary={`Select All`} />
        </ListItem>
        {locations.map(location => {
          return (
            <ListItem
              key={location.id}
              button={true}
              onClick={() => toggleLocation(location)}
              disableRipple={true}
              className="location-list-item"
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isLocationChecked(location)}
                  tabIndex={-1}
                  value={location.id}
                />
              </ListItemIcon>
              <ListItemText primary={location.name} secondary={location.type} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
