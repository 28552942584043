declare const ENVARS: any;

export const getVenueWebLink = (venueId: string): string =>
  `${ENVARS.WEB.BASE_URL}/consumer/stores/${venueId}`;

export const getStoreWebLink = (venueId: string, storeId: string): string =>
  `${getVenueWebLink(venueId)}/products/${storeId}`;

export const getDeliveryLocationWebLink = (
  venueId: string,
  deliveryLocationId: string,
  storeId?: string,
): string => {
  const baseLink = getVenueWebLink(venueId);
  const storesPath = storeId ? `/products/${storeId}` : '';
  const queryParam = `?dl=${deliveryLocationId}`;
  return `${baseLink}${storesPath}${queryParam}`;
};

export const getSuiteInviteLink = (inviteId: string): string =>
  `${ENVARS.WEB.BASE_URL}/invites/${inviteId}`;
