import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import './AdjustInventory.scss';
// import '../../Reporting/Reporting.scss'
import snackIcon2 from '../../../images/printerIcon.png';
import CategoryService from '../../../services/Category/Category.service';
import InventoryService from '../../../services/Inventory/Inventory.service';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
function AdjustInvetory(props: any) {
  interface InventoryItem {
    name: string;
    imageUrl: string | null;
    inventory_count: number;
    id: string;
    comment: string;
    reason: string | null;
    category_name: string | null;
    gpimageurl: string | null;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStoreId, setSelectedStoredId] = useState(
    props?.stores[0]?.id ? props?.stores[0]?.id : '',
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSeletedCategory] = useState('All');
  const [searchKey, setSearchKey] = useState('');
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [inventoryChange, setInventoryChange] = useState('0');
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [signError, setSignError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const user_id = window.localStorage.getItem('userId');

  const location = useLocation();
  const fullUrl = location.pathname;
  const [
    selectedInventory,
    setSelectedInventory,
  ] = useState<InventoryItem | null>(null);

  useEffect(() => {
    fetchCategories();
  }, [selectedStoreId]);
  useEffect(() => {
    searchInventory();
  }, [selectedStoreId, selectedCategory, searchKey]);
  const handleStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    setSeletedCategory('All');
    setSelectedInventory(null);
    setSelectedStoredId(newSelectedStoreId);
  };
  const handleCategorySelect = (event: any) => {
    let newSelectedCategoryId = get(event, ['target', 'value']);
    setSelectedInventory(null);
    setSeletedCategory(newSelectedCategoryId);
  };
  const fetchCategories = async () => {
    if (!selectedStoreId || selectedStoreId == 'All') {
      return;
    }
    try {
      const { data }: any = await CategoryService.getAllCategories(
        selectedStoreId,
        100,
        1,
      );
      setCategories(data.items);
    } catch (err) {
      console.log(err, 'error while fetching categories');
    }
  };

  const searchInventory = async () => {
    try {
      const { data } = await InventoryService.searchInventory(
        selectedStoreId !== 'All' || selectedStoreId !== ''
          ? selectedStoreId
          : '',
        selectedCategory !== 'All' ? selectedCategory : '',
        searchKey,
      );
      setInventory(data);
    } catch (err) {
      console.log(err, 'error while fetching inventory');
    }
  };
  const updateInventory = async () => {
    try {
      setDisableButton(true);
      if (!selectedInventory) return;
      if (inventoryChange === '0') {
        setError(true);
        setDisableButton(false);
        return setErrorMessage('It Should be Greater Than 0');
      }
      let inventory_count = 0;
      const latestInventory: any = await InventoryService.getInventoryById(
        selectedInventory.id,
      );
      let change_amount = '';
      let loss_count = latestInventory.data.loss_count
        ? latestInventory.data.loss_count
        : 0;
      if (inventoryChange.includes('+')) {
        const numericValue = parseInt(inventoryChange.substring(1), 10);
        inventory_count = +latestInventory.data.inventory_count + numericValue;
        change_amount = '+' + numericValue;
      } else if (inventoryChange.includes('-')) {
        const numericValue = parseInt(inventoryChange.substring(1), 10);
        inventory_count = +latestInventory.data.inventory_count - numericValue;
        loss_count = +loss_count + numericValue;
        change_amount = '-' + numericValue;
        if (numericValue > latestInventory.data.inventory_count) {
          setErrorMessage(
            'inventory change should be lesser than exisiting quantity',
          );
          setDisableButton(false);
          return setSignError(true);
        }
      } else {
        setErrorMessage('Selecte one sign');
        setDisableButton(false);
        return setSignError(true);
      }
      const { data } = await InventoryService.updateInventroy(
        selectedInventory.id,
        {
          comment,
          reason,
          inventory_count,
          change_amount,
          loss_count,
          user_id,
        },
      );
      setSelectedInventory({
        ...data,
        name: data.product.name,
        gpimageurl: data?.product?.globalProduct?.imageUrl,
        imageUrl: data?.product?.globalProduct?.imageUrl,
      });
      setReason('');
      setInventoryChange('0');
      setComment('');
      await searchInventory();
      enqueueSnackbar('Inventory update successfully', {
        variant: 'success',
      });
      setDisableButton(false);
    } catch (err) {
      console.log(err, 'error while updating inventory');
      setDisableButton(false);
      enqueueSnackbar('Server Error', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (
        fullUrl.includes('adjust_inventory') &&
        inventory.length > 0 &&
        selectedInventory === null
      ) {
        const urlParts = fullUrl.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const foundInventory = inventory.find(e => e.id === lastPart);
        if (foundInventory) {
          setSelectedInventory(foundInventory);
        }
      }
    })();
  }, [fullUrl, inventory]);
  return (
    <>
      <div>
        <h2
          className=""
          style={{ marginLeft: '61px', textTransform: 'uppercase' }}
        ></h2>
        <div className="main_container_adjust">
          <div className="left_container">
            <div className="store-select">
              <Grid
                container={true}
                direction="row"
                alignItems="center"
                className="report-type-select"
                xs={6}
                item={true}
              >
                <InputLabel
                  htmlFor="type"
                  classes={{ root: 'report-type-label' }}
                  style={{ color: 'black' }}
                >
                  Store :
                </InputLabel>
                <Select
                  value={selectedStoreId}
                  onChange={handleStoreSelect}
                  classes={{
                    root: 'select-store',
                  }}
                >
                  {props?.stores?.length > 0 ? (
                    props?.stores?.map((store: any) => (
                      <MenuItem key={store.id} value={store.id}>
                        {store.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="no-category" disabled>
                      No Store Available
                    </MenuItem>
                  )}
                </Select>
              </Grid>
            </div>

            <div className="product-search-container">
              <div className="product-name-search">
                <label>Product Name</label>
                <input
                  placeholder="Search..."
                  style={{ border: '1px solid grey', padding: '6px' }}
                  value={searchKey}
                  onChange={event => {
                    setSearchKey(event.target.value);
                  }}
                />
              </div>

              <div className=" ">
                <Grid
                  container={true}
                  direction="row"
                  alignItems="center"
                  className="report-type-select"
                  xs={6}
                  lg={12}
                  item={true}
                >
                  <InputLabel
                    htmlFor="type"
                    classes={{ root: 'report-type-label' }}
                    style={{ color: 'black' }}
                  >
                    Category
                  </InputLabel>
                  <Select
                    value={selectedCategory || 'All'}
                    onChange={handleCategorySelect}
                    classes={{
                      select: 'reporting-select',
                    }}
                    name="type"
                    style={{ width: '200px' }}
                  >
                    {categories.length > 0 && (
                      <MenuItem key="All" value="All">
                        All
                      </MenuItem>
                    )}

                    {categories.length > 0 ? (
                      categories.map((category: any) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="no-category" disabled>
                        No Category Available
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              </div>
            </div>

            <div className="snacks-wrapper">
              {inventory.length > 0 ? (
                <>
                  {inventory.map((item, index) => (
                    <div
                      className={`snack ${
                        selectedInventory?.id === item.id ? 'selected' : ''
                      }`}
                      key={item.id}
                      onClick={() => setSelectedInventory(item)}
                    >
                      <img
                        src={
                          item?.imageUrl
                            ? item.imageUrl
                            : item?.gpimageurl
                            ? item.gpimageurl
                            : ''
                        }
                        width="90"
                        height="100"
                      />
                      <span>{item.name}</span>
                    </div>
                  ))}
                </>
              ) : (
                <>No Product Available</>
              )}
            </div>
          </div>
          {selectedInventory && (
            <div className="right_container">
              <div className="main-right-container">
                <div className="selected-image">
                  <img
                    alt=""
                    width="103"
                    height="117"
                    src={
                      selectedInventory?.imageUrl
                        ? selectedInventory.imageUrl
                        : selectedInventory?.gpimageurl
                        ? selectedInventory.gpimageurl
                        : ''
                    }
                  />
                </div>
                <div className="category-name">
                  <span>{selectedInventory.category_name}</span>
                </div>
                <div className="image-title">
                  <span>{selectedInventory.name}</span>
                </div>
                <div className="inventory-inputs">
                  <div className="input-label1">
                    Current Inventory{' '}
                    <input
                      className="current-inventory-input"
                      readOnly={true}
                      value={selectedInventory.inventory_count}
                    />
                  </div>
                  <div className="input-label2">
                    Inventory Change{' '}
                    <input
                      style={{
                        color: inventoryChange.includes('-')
                          ? '#ee3653'
                          : 'inherit',
                      }}
                      className="change-inventory-input"
                      value={inventoryChange}
                      onChange={(event: any) => {
                        setError(false);
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, '');
                        if (inventoryChange.includes('+')) {
                          const valueWithSign = `+${numericValue.slice(0)}`;
                          return setInventoryChange(valueWithSign);
                        } else if (inventoryChange.includes('-')) {
                          const valueWithSign = `-${numericValue.slice(0)}`;
                          return setInventoryChange(valueWithSign);
                        }

                        setInventoryChange(numericValue);
                      }}
                    />
                  </div>
                </div>
                <div>
                  {error && <div className="error-message">{errorMessage}</div>}
                </div>

                <div className="input-buttons">
                  <button
                    className="left-button"
                    onClick={() => {
                      setSignError(false);
                      if (inventoryChange === '0' || inventoryChange === '') {
                        setErrorMessage('It Should be Greater Than 0');
                        return setError(true);
                      }
                      if (inventoryChange.startsWith('+')) {
                        setInventoryChange(`-${inventoryChange.slice(1)}`);
                      } else if (!inventoryChange.startsWith('-')) {
                        setInventoryChange(`-${inventoryChange}`);
                      }
                      setReason('Spoilage');
                    }}
                  >
                    <span>-</span>
                  </button>
                  <button
                    className="right-button"
                    onClick={() => {
                      setSignError(false);
                      if (inventoryChange === '0' || inventoryChange === '') {
                        setErrorMessage('It Should be Greater Than 0');
                        return setError(true);
                      }
                      if (inventoryChange.startsWith('-')) {
                        setInventoryChange(`+${inventoryChange.slice(1)}`);
                      } else if (!inventoryChange.startsWith('+')) {
                        setInventoryChange(`+${inventoryChange}`);
                      }
                      setReason('Inventory Purchase');
                    }}
                  >
                    <span>+</span>
                  </button>
                </div>
                <div>
                  {signError && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>

                <div className="change-inputs">
                  <div className="input-label2">
                    Change Reason
                    <select
                      className="change-input"
                      value={reason}
                      disabled={true}
                    >
                      <option value=""></option>
                      <option value="Inventory Purchase">
                        Inventory Purchase
                      </option>
                      <option value="Spoilage">Spoilage</option>
                    </select>
                  </div>
                  <div className="input-label2">
                    Comment{' '}
                    <input
                      className="comment-input"
                      value={comment}
                      onChange={(event: any) => {
                        setComment(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="apply-button">
                  <button
                    className="button reset"
                    onClick={() => {
                      setReason('');
                      setError(false);
                      setErrorMessage('');
                      setInventoryChange('0');
                      setComment('');
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="button"
                    onClick={updateInventory}
                    disabled={disableButton}
                  >
                    Apply Changes{' '}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdjustInvetory;
