import React from 'react';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';

interface IVenueEventSummaryProps {
  data: IVenueEventSummaryDataRecord[];
  isAdmin: boolean;
}

export interface IVenueEventSummaryDataRecord extends IHasCurrency {
  discounts: number;
  eventDate: string;
  eventNames: string;
  liveOrders: number;
  owedToStore: number;
  preOrders: number;
  processingFees: number;
  productSales: number;
  sales: number;
  serviceCharge: number;
  storeName: string;
  storeRevenue: number;
  taxes: number;
  tips: number;
  venueName: string;
}

const helpText =
  'This report pulls orders based on the date they were completed.';
const exportName = 'venue-event-summary';
const columns: Array<IReportColumn<IVenueEventSummaryDataRecord>> = [
  {
    accessor: record => record.venueName,
    adminOnly: true,
    name: 'Venue',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.storeName,
    name: 'Store',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.eventNames,
    name: 'Event',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.eventDate,
    name: 'Order Fulfillment Date',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.liveOrders,
    name: 'Live Orders',
    total: true,
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.preOrders,
    name: 'Pre-Orders',
    total: true,
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.sales,
    name: 'Total Sales',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.productSales,
    name: 'Product Sales',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.storeRevenue,
    name: 'Revenue Share',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.serviceCharge,
    name: 'Service Charge',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.tips,
    name: 'Tips',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.discounts,
    name: 'Discounts',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.taxes,
    name: 'Taxes',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.processingFees,
    name: 'Processing Fees',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.owedToStore,
    name: 'Owed to Store',
    total: true,
    type: ReportColumnType.currency,
  },
];

const VenueEventSummary = (props: IVenueEventSummaryProps): JSX.Element => (
  <BasicReport {...{ columns, exportName, helpText }} {...props} />
);

export default VenueEventSummary;
