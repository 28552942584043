import { Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useAppContext } from '../../config/AppContext';
import { isSuperAdmin } from '../../services/Auth.service';
import VenueService from '../../services/Venue/Venue.service';
import './EditVenue.scss';
import { IVenueSettingsFormValues } from './EditVenueConfig';
import VenueSettings from './VenueSettings/VenueSettings';

const EditVenue = () => {
  const [showImageDetails, setShowImageDetails] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { setShowSpinner, setVenue, user, venue } = useAppContext();

  if (!venue || !user) {
    return null;
  }

  const updateVenueSettings = async (venueData?: IVenueSettingsFormValues) => {
    if (!venueData) {
      return await getVenueDetails();
    }

    setShowSpinner(true);
    try {
      if (venueData.imageData) {
        await VenueService.uploadVenueImage(venue.id, venueData.imageData);
        delete venueData.imageData;
        delete venueData.imageUrl;
        setShowImageDetails(false);
      }

      delete venueData.modifiedTime;
      delete venueData.createdTime;

      const { data } = await VenueService.updateVenue(venue.id, venueData);
      setVenue(data);
      enqueueSnackbar('Venue has been successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const getVenueDetails = async () => {
    setShowSpinner(true);
    try {
      const { data } = await VenueService.getVenue(venue.id);
      setVenue(data);
      enqueueSnackbar('Venue Locations have been successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  return (
    <div className="edit-venue-container">
      <Typography classes={{ root: 'section-title' }}>Edit Venue</Typography>

      {!isEmpty(venue) && (
        <VenueSettings
          disableActionButton={!isSuperAdmin(user)}
          onUpdate={updateVenueSettings}
          showImageDetails={showImageDetails}
          setShowImageDetails={setShowImageDetails}
          venue={venue}
        />
      )}
    </div>
  );
};

export default EditVenue;
