import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { Country, CurrencyCode, IVenue } from '../../models/Venue.model';

export const SignupSchema = Yup.object().shape({
  active: Yup.boolean().default(false),
  addressLine1: Yup.string()
    .nullable()
    .required('Required'),
  alcoholLimit: Yup.number().default(0),
  alcoholMessage: Yup.string()
    .nullable()
    .when('alcoholLimit', {
      is: val => val > 0,
      otherwise: Yup.string().notRequired(),
      then: Yup.string().required('Required'),
    }),
  allowFreeProducts: Yup.boolean().default(false),
  city: Yup.string()
    .nullable()
    .required('Required'),
  contactCountryCode: Yup.string().default('1'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .nullable()
    .required('Required'),
  contactFirstName: Yup.string()
    .nullable()
    .required('Required'),
  contactLastName: Yup.string()
    .nullable()
    .required('Required'),
  contactPhoneNumber: Yup.string()
    .nullable()
    .required('Required'),
  deliveryAvailable: Yup.boolean().default(false),
  deliveryFee: Yup.number()
    .when(['deliveryAvailable', 'deliveryFeeUnit'], {
      is: (deliveryAvailable, deliveryFeeUnit) => {
        return deliveryAvailable && deliveryFeeUnit === 'PERCENT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .max(100)
        .required('Required'),
    })
    .when(['deliveryAvailable', 'deliveryFeeUnit'], {
      is: (deliveryAvailable, deliveryFeeUnit) => {
        return deliveryAvailable && deliveryFeeUnit === 'AMOUNT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .required('Required'),
    }),
  deliveryFeeSharePercent: Yup.number().when('deliveryAvailable', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(0)
      .max(100)
      .required('Required'),
  }),
  deliveryFeeUnit: Yup.string().oneOf(['AMOUNT', 'PERCENT']),
  deliveryRevenueSharePercent: Yup.number().when('deliveryAvailable', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(0)
      .max(100)
      .required('Required'),
  }),
  maxDistance: Yup.number().min(3),
  name: Yup.string().required('Required'),
  orderAheadAvailable: Yup.boolean().default(false),
  orderNowAvailable: Yup.boolean().default(true),
  pickupAvailable: Yup.boolean().default(false),
  pickupFee: Yup.number()
    .when(['pickupAvailable', 'pickupFeeUnit'], {
      is: (pickupAvailable, pickupFeeUnit) => {
        return pickupAvailable && pickupFeeUnit === 'PERCENT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .max(100)
        .required('Required'),
    })
    .when(['pickupAvailable', 'pickupFeeUnit'], {
      is: (pickupAvailable, pickupFeeUnit) => {
        return pickupAvailable && pickupFeeUnit === 'AMOUNT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .required('Required'),
    }),
  pickupFeeSharePercent: Yup.number().when('pickupAvailable', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(0)
      .max(100)
      .required('Required'),
  }),
  pickupFeeUnit: Yup.string().oneOf(['AMOUNT', 'PERCENT']),
  pickupRevenueSharePercent: Yup.number().when('pickupAvailable', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(0)
      .max(100)
      .required('Required'),
  }),
  salesTaxPercent: Yup.number().when('calculateTaxes', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(1, 'Sales tax percent must be greater than 0')
      .max(100)
      .required('Required'),
  }),
  mobileCCFeesPercentage: Yup.number()
    .min(0, 'Credit card fee percent must be greater than 0')
    .max(100),
  mobileCCFeesNumber: Yup.number()
    .min(0, 'Credit card fee number must be greater than 0')
    .max(100),
  posCCFeesPercentage: Yup.number()
    .min(0, 'Pos fee percent must be greater than 0')
    .max(100),
  posCCFeesNumber: Yup.number()
    .min(0, 'Pos fee number must be greater than 0')
    .max(100),
  sendOrderNotifications: Yup.boolean().default(true),
  state: Yup.string()
    .nullable()
    .required('Required'),
  timezone: Yup.string().nullable(),
  type: Yup.string().required('Required'),
  vanityUrl: Yup.string()
    .strict(true)
    .required('Required')
    .lowercase('URL must be a lowercase string')
    .matches(/^[a-zA-Z]+$/, {
      excludeEmptyString: true,
      message: 'URL cannot contain spaces, special characters or numbers',
    })
    .max(30, 'URL cannot be more than 30 characters'),
  zip: Yup.string()
    .nullable()
    .required('Required'),

  //POS Setup schema
  posFee: Yup.number()
    .when(['posFeeUnit'], {
      is: posFeeUnit => {
        return posFeeUnit === 'PERCENT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .max(100)
        .required('Required'),
    })
    .when(['posFeeUnit'], {
      is: posFeeUnit => {
        return posFeeUnit === 'AMOUNT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .required('Required'),
    }),
  posFeeSharePercent: Yup.number().when('posFee', {
    is: posFee => posFee >= 0,
    then: Yup.number()
      .min(0, 'Value must be at least 0')
      .max(100, 'Value must be at most 100')
      .required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  posFeeUnit: Yup.string().oneOf(['AMOUNT', 'PERCENT']),
  posRevenueSharePercent: Yup.number().when('posFee', {
    is: posFee => posFee >= 0,
    then: Yup.number()
      .min(0, 'Value must be at least 0')
      .max(100, 'Value must be at most 100')
      .required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  //Service Fee Schema
  serviceAvailable: Yup.boolean().default(false),
  serviceFee: Yup.number()
    .when(['serviceAvailable', 'serviceFeeUnit'], {
      is: (serviceAvailable, serviceFeeUnit) => {
        return serviceAvailable && serviceFeeUnit === 'PERCENT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .max(100)
        .required('Required'),
    })
    .when(['serviceAvailable', 'serviceFeeUnit'], {
      is: (serviceAvailable, serviceFeeUnit) => {
        return serviceAvailable && serviceFeeUnit === 'AMOUNT';
      },
      otherwise: Yup.number().notRequired(),
      then: Yup.number()
        .min(0)
        .required('Required'),
    }),
  serviceFeeSharePercent: Yup.number().when('serviceAvailable', {
    is: true,
    otherwise: Yup.number().notRequired(),
    then: Yup.number()
      .min(0)
      .max(100)
      .required('Required'),
  }),
  serviceFeeUnit: Yup.string().oneOf(['AMOUNT', 'PERCENT']),
});

export const ACHSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Required'),
  accountNumberConfirm: Yup.string().when('accountNumber', {
    is: val => !isEmpty(val),
    otherwise: Yup.string().notRequired(),
    then: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('accountNumber')], 'Account Numbers dont match'),
  }),
  addressLine1: Yup.string().required('Required'),
  businessUnit: Yup.boolean().default(true),
  city: Yup.string().required('Required'),
  companyName: Yup.string().when('businessUnit', {
    is: true,
    otherwise: Yup.string().notRequired(),
    then: Yup.string().required('Required'),
  }),
  companyTaxId: Yup.string().when('businessUnit', {
    is: true,
    otherwise: Yup.string().notRequired(),
    then: Yup.string().required('Required'),
  }),
  currency: Yup.string()
    .required('Currency is Required')
    .default('USD'),
  dob: Yup.date().required(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  routingNumber: Yup.string()
    .label('Routing Number')
    .length(9)
    .required('Required'),
  ssnLast4: Yup.string()
    .label('Last SSN digits')
    .length(4)
    .required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

export const validLocationsValidationSchema = Yup.object().shape({
  locName: Yup.string().required('Required'),
});

export interface ISelectConfig {
  name: string;
  value: string;
}

export interface IValidLocationPayload {
  name: string;
  validValues: string[];
}

export const feeUnits: ISelectConfig[] = [
  {
    name: 'Amount',
    value: 'AMOUNT',
  },
  {
    name: 'Percent',
    value: 'PERCENT',
  },
];

export const currencyOptions: ISelectConfig[] = [
  {
    name: 'USD - $',
    value: CurrencyCode.USD,
  },
  {
    name: 'CAD - $',
    value: CurrencyCode.CAD,
  },
  {
    name: 'ZAR - R',
    value: CurrencyCode.ZAR,
  },
  {
    name: 'AED - AED',
    value: CurrencyCode.AED,
  },
];

export const CountryOptions: ISelectConfig[] = [
  {
    name: 'United States (US)',
    value: Country.US,
  },
  {
    name: 'Canada (CA)',
    value: Country.CA,
  },
  {
    name: 'South Africa (ZA)',
    value: Country.ZA,
  },
  {
    name: 'UAE (AE)',
    value: Country.AE,
  },
];

export const venueTypeConfig: ISelectConfig[] = [
  {
    name: 'Stadium',
    value: 'STADIUM',
  },
  {
    name: 'Golf Course',
    value: 'GOLF_COURSE',
  },
  {
    name: 'Sports Complex',
    value: 'SPORTS_COMPLEX',
  },
  {
    name: 'Custom',
    value: 'CUSTOM',
  },
  {
    name: 'Tenant Building',
    value: 'TENANT_BUILDING',
  },
  {
    name: 'Service Provider',
    value: 'SERVICE_PROVIDER',
  },
];

export interface IValidLocation {
  validValues: string[];
  name: string;
  locName: string;
  used: boolean;
  value: string;
}

export interface IVenueSettingsFormValues extends Partial<IVenue> {
  imageData?: any;
}
