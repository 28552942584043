import {
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { ErrorMessage } from 'formik';
import React from 'react';
import NumberFormat from 'react-number-format';
import { noDataAvailable } from '../../../../components/NoDataAvailable';
import { useAppContext } from '../../../../config/AppContext';
import IProduct from '../../../../models/Product.model';
import { getCurrencyPrefix } from '../../../../services/Util/Util.service';
import { ISubProductMap } from '../AddEditProductForm';

const comboTableColumnNames = [
  { align: 'center', name: 'Include Add-on', size: 'small' },
  { align: 'center', name: 'Auto Select', size: 'small' },
  { align: 'left', name: 'Name', size: 'small' },
  { align: 'left', name: 'Price', size: 'small' },
  { align: 'left', name: 'Quantity Limit*', size: 'small' },
];

interface IComboTableProps {
  categoryId: string;
  isMultiselect: boolean;
  products: IProduct[];
  selectedProducts: ISubProductMap;
  updateProductProperties: (
    productId: string,
    categoryId: string,
    active: boolean,
    values: any
  ) => void;
}

const ComboTable = ({
  categoryId,
  isMultiselect,
  products,
  selectedProducts,
  updateProductProperties,
}: IComboTableProps) => {
  const { venue } = useAppContext();
  const currencyPrefix = getCurrencyPrefix(venue?.currency);

  return (
    <Table className="menu__table">
      <TableBody>
        <TableRow classes={{ root: 'menu__table-header' }}>
          {comboTableColumnNames.map((column: any, i: number) => (
            <TableCell
              key={i}
              align={column.align}
              size={column.size}
              variant="head"
              classes={{ root: 'menu__table-cell' }}
            >
              <p>{column.name}</p>
            </TableCell>
          ))}
        </TableRow>
        {products.length < 1
          ? noDataAvailable(7)
          : products.map((product: any) => {
              const selectedProduct = selectedProducts[product.id];

              return (
                <TableRow key={product.id} hover={true}>
                  <TableCell
                    align="center"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    <Checkbox
                      checked={!!selectedProduct}
                      onClick={() => {
                        const values = selectedProduct
                          ? { removeSelectedProduct: true }
                          : {};
                        updateProductProperties(
                          product.id,
                          categoryId,
                          product.active,
                          values,
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    {!!selectedProduct &&
                      (isMultiselect ? (
                        <Checkbox
                          checked={!!selectedProduct.autoSelect}
                          onClick={() => {
                            const autoSelect = !selectedProduct.autoSelect;
                            console.log(autoSelect ,'autoSelect')
                            updateProductProperties(
                              product.id,
                              categoryId,
                              product.active,
                              {
                                autoSelect,
                              },
                            );
                          }}
                        />
                      ) : (
                        <Radio
                          checked={!!selectedProduct.autoSelect}
                          onClick={() => {
                            const autoSelect = !selectedProduct.autoSelect;
                            updateProductProperties(
                              product.id,
                              categoryId,
                              product.active,
                              {
                                autoSelect,
                              },
                            );
                          }}
                        />
                      ))}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    <p>{product.name}</p>
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    {selectedProduct && (
                      <NumberFormat
                        className="menu__table-cell__input"
                        decimalScale={2}
                        displayType={'input'}
                        min={0.0}
                        onValueChange={values =>
                          updateProductProperties(
                            product.id,
                            categoryId,
                            product.active,
                            {
                              price: values.floatValue || 0,
                            },
                          )
                        }
                        placeholder={`${currencyPrefix}0.00`}
                        prefix={currencyPrefix}
                        thousandSeparator={true}
                        value={selectedProduct.price || ''}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    {selectedProduct && (
                      <>
                        <TextField
                          autoComplete="off"
                          classes={{ root: 'menu__quantity-text' }}
                          inputProps={{
                            min: 0,
                            type: 'number',
                          }}
                          name="quantityLimit"
                          onChange={event => {
                            updateProductProperties(
                              product.id,
                              categoryId,
                              product.active,
                              {
                                quantityLimit:
                                  event.target.value.length > 0
                                    ? Number(event.target.value)
                                    : 0,
                              },
                            );
                          }}
                          type="number"
                          value={selectedProduct.quantityLimit.toString()}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="quantityLimit"
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
      </TableBody>
    </Table>
  );
};
export default ComboTable;
