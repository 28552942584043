import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactChild, useEffect, useState } from 'react';
import createContextWrapper from '../hooks/create-context.hook';
import { IPagination, usePagination } from '../hooks/pagination.hook';
import ICategory from '../models/Category.model';
import { IStoreVenueLocation } from '../models/Location.model';
import { IPrinter } from '../models/Printer.model';
import IProduct from '../models/Product.model';
import {
  IFulfillmentCenter,
  IStore,
  ValidStoreType,
} from '../models/Store.model';
import CategoryService from '../services/Category/Category.service';
import ProductService from '../services/Product/Product.service';
import StoreService from '../services/Store/Store.service';
import { useAppContext } from './AppContext';

interface IStoreProviderProps {
  children: ReactChild;
  storeId: string;
}

interface IStoreContext {
  categories: ICategory[];
  categoryMaxPriority: number;
  categoryPagination: IPagination;
  fetchCategories: () => Promise<void>;
  fetchPrinters: () => Promise<void>;
  fetchProducts: () => Promise<void>;
  fetchStore: () => Promise<void>;
  fulfillmentCenters: IFulfillmentCenter[];
  printers: IPrinter[];
  productPagination: IPagination;
  products: IProduct[];
  selectedProductCategoryId: string | undefined;
  setSelectedProductCategoryId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  store: IStore | undefined;
  storeLocations: IStoreVenueLocation[];
  updateStore: (id: string, store: Partial<IStore>) => Promise<void>;
  setStore: React.Dispatch<React.SetStateAction<IStore | undefined>>;
}

const [
  useStoreContext,
  StoreContext,
  StoreContextProvider,
] = createContextWrapper<IStoreContext>();

const StoreProvider = ({ children, storeId }: IStoreProviderProps) => {
  const { setShowSpinner, venue } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoryMaxPriority, setCategoryMaxPriority] = useState<number>(0);
  const categoryPagination: IPagination = usePagination(100);

  const [printers, setPrinters] = useState<IPrinter[]>([]);

  const [products, setProducts] = useState<IProduct[]>([]);
  const productPagination: IPagination = usePagination(100);
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState<
    string | undefined
  >();

  const [storeLocations, setStoreLocations] = useState<IStoreVenueLocation[]>(
    [],
  );

  const [store, setStore] = useState<IStore | undefined>();
  const [fulfillmentCenters, setFulfillmentCenters] = useState<
    IFulfillmentCenter[]
  >([]);

  useEffect(() => {
    if (storeId && (!store || store.id !== storeId)) {
      fetchStore();
    }
  }, [storeId, venue]);

  useEffect(() => {
    fetchFulfillmentCenters();
  }, [store, venue]);

  useEffect(() => {
    fetchCategories();
  }, [store, categoryPagination.page, categoryPagination.rowsPerPage]);

  useEffect(() => {
    fetchProducts();
  }, [
    store,
    selectedProductCategoryId,
    productPagination.page,
    productPagination.rowsPerPage,
  ]);

  useEffect(() => {
    fetchPrinters();
  }, [store]);

  useEffect(() => {
    fetchStoreLocations();
  }, [store]);

  const fetchStore = async () => {
    if (!venue || !storeId) {
      return;
    }

    setShowSpinner(true);
    try {
      const { data } = await StoreService.getStore(storeId);
      setStore(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const fetchFulfillmentCenters = async () => {
    if (!store || !venue) {
      return;
    }

    const { data } = await StoreService.getAllStores({
      limit: 100,
      pageNumber: 1,
      type: ValidStoreType.FULFILLMENT_CENTER,
      venueId: venue.id,
    });

    const fcs: IFulfillmentCenter[] = data.items.map((s: IStore) => ({
      id: s.id,
      name: s.name,
    }));

    // Sets "This Store" as first in array.
    fcs.unshift({
      id: store.id,
      name: 'This Store',
    });
    setFulfillmentCenters(fcs);
  };

  const updateStoreImage = async (
    id: string,
    imageFile: any,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('imageFile', imageFile);
      await StoreService.uploadStoreImage({ id, imageFile: formData });
    } catch (error) {
      enqueueSnackbar(
        'Something went wrong uploading the image. Please try again.',
        {
          variant: 'error',
        },
      );
    }
  };

  const updateStore = async (
    id: string,
    updateParams: Partial<IStore>,
  ): Promise<void> => {
    setShowSpinner(true);
    if (updateParams.image_file) {
      await updateStoreImage(id, updateParams.image_file);
    }

    try {
      const { data } = await StoreService.updateStore(id, updateParams);
      setStore(data);

      enqueueSnackbar('Store has been successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const fetchCategories = async () => {
    if (!store) {
      return;
    }

    const { rowsPerPage, page, setTotalCount } = categoryPagination;
    setShowSpinner(true);
    try {
      const { data } = await CategoryService.getAllCategories(
        store.id,
        rowsPerPage,
        page + 1,
      );

      setCategories(data.items);
      setTotalCount(data.totalItems);
      setCategoryMaxPriority(data.maxPriority);

      if (!selectedProductCategoryId && !isEmpty(data.items)) {
        setSelectedProductCategoryId(data.items[0].id);
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const fetchProducts = async () => {
    if (!store || !selectedProductCategoryId) {
      return;
    }

    const { rowsPerPage, page, setTotalCount } = productPagination;
    setShowSpinner(true);
    try {
      const { data } = await ProductService.getStoreProducts(
        store.id,
        rowsPerPage,
        page + 1,
        selectedProductCategoryId,
      );

      setProducts(data.items);
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const fetchPrinters = async () => {
    if (!store) {
      return;
    }

    try {
      const { data } = await StoreService.getPrinters(store.id);
      setPrinters(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
  };

  const fetchStoreLocations = async () => {
    if (!store) {
      return;
    }

    try {
      const { data } = await StoreService.getStoreLocations(store.id);
      setStoreLocations(data);
    } catch (error) {
      setStoreLocations([]);
    }
  };

  return (
    <StoreContextProvider
      value={{
        categories,
        categoryMaxPriority,
        categoryPagination,
        fetchCategories,
        fetchPrinters,
        fetchProducts,
        fetchStore,
        fulfillmentCenters,
        printers,
        productPagination,
        products,
        selectedProductCategoryId,
        setSelectedProductCategoryId,
        store,
        storeLocations,
        updateStore,
        setStore,
      }}
    >
      {children}
    </StoreContextProvider>
  );
};

export { useStoreContext, StoreContext, StoreProvider };
