import { ITaxRate } from '../../models/TaxRate.model';
import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/tax-rates`;

export interface IAddOrUpdateTaxRateDto extends Partial<ITaxRate> {
  applyToAllProducts?: boolean;
}

export interface ICreateTaxRateDto {
  applyToAllProducts?: boolean;
  name?: string;
  percent?: number;
  venueId: string;
}

export interface IUpdateTaxRateDto {
  name?: string;
  percent?: number;
}

const getAllTaxRates = (venueId: string) =>
  axiosWithAuth.get<ITaxRate[]>(baseUrl, { params: { venueId } });

const createTaxRate = (dto: ICreateTaxRateDto) =>
  axiosWithAuth.post<ITaxRate>(baseUrl, dto);

const updateTaxRate = (taxRateId: string, dto: IUpdateTaxRateDto) =>
  axiosWithAuth.put<ITaxRate>(`${baseUrl}/${taxRateId}`, dto);

const deleteTaxRate = (taxRateId: string) =>
  axiosWithAuth.delete<void>(`${baseUrl}/${taxRateId}`);

export default {
  createTaxRate,
  deleteTaxRate,
  getAllTaxRates,
  updateTaxRate,
};
