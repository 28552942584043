import { Grid, Typography } from '@material-ui/core';
import { useSnackbar, withSnackbar } from 'notistack';
import React from 'react';
import { useAppContext } from '../../../config/AppContext';
import { useStoreContext } from '../../../config/StoreContext';
import StoreService from '../../../services/Store/Store.service';
import PaymentSettings from '../../Settings/PaymentSettings/PaymentSettings';
import './index.scss';

const ACH = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { setShowSpinner } = useAppContext();
  const { store, fetchStore } = useStoreContext();

  if (!store) {
    return <></>;
  }

  const { bankAccountLast4, bankAccountName, gatewayConnectAccountId } = store;

  const updateAchDetails = async (achDetails: any) => {
    setShowSpinner(true);
    try {
      await StoreService.createAchAccount(store.id, achDetails);
      await fetchStore();

      enqueueSnackbar('Venue ACH details have been successfully updated.', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const deleteACHAccount = async () => {
    setShowSpinner(true);
    try {
      await StoreService.deleteAchAccount(store.id);
      await fetchStore();

      enqueueSnackbar('ACH account successfully deleted', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  return (
    <>
      <Grid container={true} xs={8} className="ach-subtitle">
        <Typography variant="subtitle1">
          ** This ACH section is for locations that would like to be paid out
          separately at the Store level. If you have already set up ACH at the
          Venue level and don't require specific payouts for this Store, then
          you do not need to set this up.
        </Typography>
      </Grid>
      <PaymentSettings
        bankAccountLast4={bankAccountLast4}
        bankAccountName={bankAccountName}
        disableActionButton={false}
        gatewayConnectAccountId={gatewayConnectAccountId}
        onUpdate={updateAchDetails}
        onDelete={deleteACHAccount}
      />
    </>
  );
};

export default withSnackbar(ACH);
