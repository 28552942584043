import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  TextField,
  Select,
  Checkbox,
  Switch,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { IPrinter } from '../../../models/Printer.model';
import './PrinterDialog.scss';
import { IAddEditPrinterParams } from './Printers/StorePrinters';
import { useAppContext } from '../../../config/AppContext';
import axiosWithAuth from '../../../services/ServiceConfig';
interface IPrinterDialogProps {
  onClose: () => void;
  onAddOrEditPrinter: (dto: IAddEditPrinterParams) => void;
  printer: Partial<IPrinter>;
}

const PrinterDialog = ({
  onClose,
  onAddOrEditPrinter,
  printer,
}: IPrinterDialogProps) => {
  const { selectedStoreId } = useAppContext();
  const [name, setName] = useState(printer.name || '');
  const [printAuto, setPrintAuto] = useState(printer.printAuto || false);
  const [copyCount, setCopyCount] = useState(printer.copyCount || 1);
  const [printerLocation, setPrinterLocation] = useState('All Orders');
  const [printerTerminals, setPrinterTerminals] = useState<string[]>([]);
  const [allTerminals, setAllTerminals] = useState([]);
  useEffect(() => {
    axiosWithAuth
      .get(`terminals/store/${selectedStoreId}`)
      .then((response: any) => {
        setAllTerminals(response.data);
      })
      .catch((error: any) => {
        console.log('Error while fetching terminals ', error);
      });
  }, []);
  useEffect(() => {
    try {
      if (printer) {
        setPrinterTerminals(printer?.terminals ?? []);
        if (printer?.allOrders) {
          setPrinterLocation('All Orders');
        } else if (printer?.mobileOrders) {
          setPrinterLocation('Mobile Orders');
        } else if (printer.terminals && printer.terminals.length > 0) {
          setPrinterLocation(printer?.terminals[0]);
        }
      }
    } catch (error) {
      console.log('Error Matching Terminals');
    }
  }, [printer]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleTerminalSelect = (event: any) => {
    const { value } = event.target;
    if (value === 'All Orders') {
      const getTerminalIds: any = allTerminals.map(
        (e: any) => e.stripeTerminalId,
      );
      setPrinterTerminals(getTerminalIds);
      setPrinterLocation('All Orders');
    } else if (value === 'Mobile Orders') {
      setPrinterTerminals([]);
      setPrinterLocation('Mobile Orders');
    } else {
      setPrinterTerminals([value]);
      setPrinterLocation(value);
    }
  };
  return (
    <Dialog
      classes={{ paper: 'printer-dialog' }}
      fullWidth={true}
      open={true}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="section-title">
          {printer.id ? `Printer Settings` : `Add Printer`}
        </span>
        <Icon onClick={onClose} className="close-icon">
          cancel
        </Icon>
      </DialogTitle>
      <DialogContent className="form">
        <div className="form-required-field">* Required Field</div>
        <div className="form_field">
          <p className="form_field__label">
            Printer Name <span className="form_field__label__required">*</span>
          </p>
          <TextField
            className="printer-name"
            name="name"
            onChange={event => setName(event.target.value)}
            required={true}
            type="text"
            value={name}
          />
          <p className="form_field__info">Printer name must match printer</p>
        </div>
        {printer.id && (
          <>
            <div className="form_field">
              <p className="form_field__label">Number Of Copies</p>
              <TextField
                name="copyCount"
                onChange={event => setCopyCount(+event.target.value)}
                select={true}
                value={copyCount}
              >
                {[1, 2, 3, 4, 5].map((c: number) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form_field">
              <p className="form_field__label">Printer Status</p>
              <p
                className={`form_field__value ${
                  printer.status === 'Ready' ? 'success-color' : 'error-color'
                }`}
              >
                {printer.status || 'N/A'}
                <Icon
                  className={`status-icon ${
                    printer.status === 'Ready' ? 'success-color' : 'error-color'
                  }`}
                >
                  {printer.status === 'Ready' ? 'done' : 'warning'}
                </Icon>
              </p>
            </div>
          </>
        )}
        <div className="form_field">
          <p className="form_field__label">
            Location <span className="form_field__label__required">*</span>
          </p>
          <Select
            value={printerLocation}
            onChange={handleTerminalSelect}
            MenuProps={MenuProps}
          >
            <MenuItem key="All Orders" value="All Orders">
              All Orders
            </MenuItem>
            {allTerminals.map((terminal: any) => (
              <MenuItem key={terminal.id} value={terminal.stripeTerminalId}>
                {terminal.terminalName}
              </MenuItem>
            ))}
            <MenuItem key="Mobile Orders" value="Mobile Orders">
              Mobile Orders
            </MenuItem>
          </Select>
        </div>
        <div className="generic-dialog__field">
          Auto Print:
          <Switch
            classes={{
              track: 'custom-colored-switch-track',
            }}
            name="autoPrint"
            color="primary"
            checked={printAuto}
            value={printAuto}
            onChange={(event: any) => {
              setPrintAuto(event.target.checked);
            }}
          />
        </div>
      </DialogContent>

      <div className="button-holder-similar-margin">
        <DialogActions>
          <Button color="primary" onClick={onClose} variant="outlined">
            <div className="icon-title-wrapper">
              <span className="icon-title">Cancel</span>
            </div>
          </Button>
          <Button
            color="primary"
            onClick={() =>
              onAddOrEditPrinter({
                copyCount,
                id: printer.id,
                name,
                printerTerminals,
                printerLocation,
                printAuto
              })
            }
            variant="contained"
            disabled={isEmpty(name) || printerLocation.length === 0}
          >
            <div className="icon-title-wrapper">
              <span className="icon-title">
                {printer.id ? `Save` : `Add Printer`}
              </span>
            </div>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default PrinterDialog;
