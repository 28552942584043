import * as Yup from 'yup';

export enum OrderAheadCutoffType {
  EVENT = 'EVENT',
  TIMESLOT = 'TIMESLOT',
}

export enum RelativeTime {
  UPCOMING = 'Upcoming',
  PAST = 'Past',
  ALL = 'All',
}

export const EventValidationSchema = Yup.object().shape({
  active: Yup.bool().required(),
  advanceOrderWindow: Yup.number(),
  description: Yup.string(),
  endDate: Yup.date().required(),
  name: Yup.string().required(),
  orderAheadCutoffType: Yup.string().required(),
  startDate: Yup.date().required(),
  venueId: Yup.string(),
});

const ms = 1000 * 60 * 15;
const startDate = new Date(Math.round(new Date().getTime() / ms) * ms);

const endDate = new Date();
endDate.setTime(startDate.getTime() + 4 * 60 * 60 * 1000); // default to 4 hours after start date

export const initialEventState = {
  active: false,
  advanceOrderInterval: 900,
  advanceOrderWindow: 0,
  description: '',
  endDate,
  id: '',
  maxOrdersPerInterval: 100,
  name: '',
  orderAheadCutoffType: OrderAheadCutoffType.EVENT,
  startDate,
  venueId: '',
};

export const orderAheadIntervalTimes = [5, 10, 15, 30, 45, 60];
