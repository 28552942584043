import { Button } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import { useAppContext } from '../../../config/AppContext';
import { IStore } from '../../../models/Store.model';
import { canEditVenue } from '../../../services/Auth.service';
import StoreService, {
  ICreateStoreDto,
} from '../../../services/Store/Store.service';
import { venuePath } from '../../TabConfig';
import '../Stores.scss';
import { AddStoreDialog } from './AddStoreDialog';
import { isFulfullmentCenter, IStoresProps } from './Store.config';
import { StoreContainer } from './StoreContainer';
import './Stores.scss';
import StoresTable from './StoresTable';

export const Stores = () => {
  const {
    fetchStores,
    setAllStores,
    setSelectedStoreId,
    setShowSpinner,
    storePagination,
    stores,
    user,
    venue,
  } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [showAddStoreDialog, setShowAddStoreDialog] = useState<boolean>(false);

  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalCount,
  } = storePagination;

  if (!venue || !user) {
    return null;
  }

  const canAddStore = canEditVenue(user, venue.id);

  const handleStoreToggle = async (
    event: React.ChangeEvent<{}>,
    storeId: string,
    key: string,
  ) => {
    const { data: updatedStore } = await StoreService.updateStore(storeId, {
      [key]: get(event, ['target', 'checked']),
    });
    // replace store in global stores array
    const storeIndex = stores.findIndex(
      (existingStore: IStore) => existingStore.id === updatedStore.id,
    );

    if (storeIndex >= 0) {
      setAllStores(Object.assign([...stores], { [storeIndex]: updatedStore }));
    }
  };

  const selectStore = (store: IStore, isNewStore: boolean = false) => {
    setSelectedStoreId(store.id);

    let route = `stores/${store.id}/menu`;
    if (isNewStore) {
      route = `stores/${store.id}/categories`;
    } else if (isFulfullmentCenter(store)) {
      route = `stores/${store.id}/edit`;
    }
    history.push(venuePath(venue.id, route));
  };

  const addStore = async (store: ICreateStoreDto) => {
    const { description, location, name, priority, type , customDiscount } = store;

    setShowSpinner(true);
    try {
      const { data: newStore } = await StoreService.createStore({
        description,
        location,
        name,
        priority,
        type,
        venueId: venue.id,
        customDiscount
      });

      selectStore(newStore, true);
      fetchStores();

      enqueueSnackbar('Store has been successfully created', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  return (
    <Fragment>
      <h2 className="section-title">Stores</h2>
      <div className="pagination">
        <div className="stores__pagination-toolbar">
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={totalCount}
          />
          {canAddStore && (
            <Button
              size="large"
              color="primary"
              classes={{ root: 'add-store-button' }}
              onClick={() => setShowAddStoreDialog(true)}
            >
              <div className="icon-title-wrapper">
                <AddOutlinedIcon />
                <span className="icon-title">Add Store</span>
              </div>
            </Button>
          )}
        </div>
        <StoresTable
          handleSwitchUpdate={handleStoreToggle}
          selectStore={selectStore}
          stores={stores}
        />
        {showAddStoreDialog && (
          <AddStoreDialog
            addStore={addStore}
            onClose={() => setShowAddStoreDialog(false)}
          />
        )}
      </div>
    </Fragment>
  );
};

export const StoresContainer = (props: IStoresProps) => {
  return (
    <div className="stores-container">
      <Route
        exact={true}
        path="/home/venue-details/:venueId/stores/store-list"
        render={({ match }: RouteComponentProps<{ venueId: string }>) => (
          <Redirect to={venuePath(match.params.venueId, 'stores')} />
        )}
      />
      <Route
        path={`/home/venue-details/:venueId/stores/:storeId`}
        component={StoreContainer}
        {...props}
      />
      <Route
        exact={true}
        path={`/home/venue-details/:venueId/stores`}
        component={Stores}
        {...props}
      />
    </div>
  );
};
