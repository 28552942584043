import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactChild, useEffect, useState } from 'react';
import { Client } from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import { Paginator } from 'twilio-chat/lib/interfaces/paginator';
import createContextWrapper from '../hooks/create-context.hook';
import { IPagination, usePagination } from '../hooks/pagination.hook';
import { IEvent } from '../models/Event.model';
import { IVenueLocation } from '../models/Location.model';
import { IStore } from '../models/Store.model';
import { ITaxRate } from '../models/TaxRate.model';
import { IProductTag } from '../models/ProductTag.model';

import { IUser } from '../models/User.model';
import { IVenue } from '../models/Venue.model';
import { canAccessManagerPortal } from '../services/Auth.service';
import EventService from '../services/Event/Event.service';
import LocalStorageService from '../services/LocalStorage/LocalStorage.service';
import VenueLocationService from '../services/Location/VenueLocation/VenueLocation.service';
import StoreService from '../services/Store/Store.service';
import SupportService from '../services/Support/support.service';
import UserService from '../services/User/User.service';
import axiosWithAuth from '../services/ServiceConfig';
import TaxRateService from '../services/Venue/TaxRate.service';
import ProductTagService from '../services/ProductTag/ProductTag.service';
import VenueService from '../services/Venue/Venue.service';
import { axiosWithAccessAndroidPOS } from '../services/ServiceConfig';
const getTokenAndCreateClient = async (venueId: string): Promise<Client> => {
  const {
    data: { jwt },
  } = await SupportService.getAccessToken(venueId);
  const client = await Client.create(jwt);

  return client;
};

interface IUnreadMessage {
  order: string;
  storeId: string;
}

interface IUnreadMessagesMap {
  [key: string]: IUnreadMessage;
}

interface IAppContext {
  buildingVenues: IVenue[];
  channels: Channel[];
  client: Client | undefined;
  currentChannel: Channel | undefined;
  eventPagination: IPagination;
  events: IEvent[];
  fetchEvents: (upcoming?: boolean) => Promise<void>;
  fetchStores: () => Promise<void>;
  getAllTerminals: () => Promise<void>;
  fetchTaxRates: () => Promise<void>;
  fetchProductTags: () => Promise<void>;
  fetchVenueLocations: () => Promise<void>;
  fetchVenueSuites: () => Promise<void>;
  loadCurrentUser: () => Promise<boolean>;
  logout: () => void;
  selectedStoreId: string | undefined;
  setAllStores: (stores: IStore[]) => void;
  setCurrentChannel: React.Dispatch<React.SetStateAction<Channel | undefined>>;
  setSelectedStoreId: (storeId: string | undefined) => void;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  setUnreadMessages: React.Dispatch<React.SetStateAction<IUnreadMessagesMap>>;
  setVenue: React.Dispatch<React.SetStateAction<IVenue | undefined>>;
  setStripeTerminal: React.Dispatch<React.SetStateAction<any>>;
  setCartItems: React.Dispatch<React.SetStateAction<any>>;
  setAllTerminals: React.Dispatch<React.SetStateAction<any>>;
  allTerminals: any[];
  setSubtotal: React.Dispatch<React.SetStateAction<any>>;
  showSpinner: boolean;
  storePagination: IPagination;
  stores: IStore[];
  taxRates: ITaxRate[];
  productTags: IProductTag[];
  unreadMessages: IUnreadMessagesMap;
  user: IUser | undefined;
  venue: IVenue | undefined;
  venueLocations: IVenueLocation[];
  venueSuites: IVenueLocation[];
  stripeTerminal: string | undefined;
  cartItems: any[];
  subTotal: any;
}

interface IAppProviderProps {
  children: ReactChild;
}

const [useAppContext, AppContext, AppContextProvider] = createContextWrapper<
  IAppContext
>();

const AppProvider = ({ children }: IAppProviderProps) => {
  const initializeSelectedStoreId = (): string | undefined => {
    const storedSelectedStoreId =
      LocalStorageService.getLocalData('selectedStoreId') || undefined;

    // We can eventually remove this line, once we are confident we no longer store
    // "All Stores" in the selectedStoreId. Until then, we should keep this.
    if (storedSelectedStoreId === 'All Stores') {
      LocalStorageService.clearLocalByKey('selectedStoreId');
      return undefined;
    }

    return storedSelectedStoreId;
  };
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [venue, setVenue] = useState<IVenue | undefined>(undefined);
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [currentChannel, setCurrentChannel] = useState<Channel | undefined>(
    undefined,
  );

  const [allTerminals, setAllTerminals] = useState<any[]>([]);
  const [stripeTerminal, setStripeTerminal] = useState<any>();
  const [cartItems, setCartItems] = useState<any>([]);
  const [subTotal, setSubtotal] = useState<any>(0);

  const [buildingVenues, setBuildingVenues] = useState<IVenue[]>([]);
  const eventPagination: IPagination = usePagination(20);
  const [events, setEvents] = useState<IEvent[]>([]);

  const storePagination: IPagination = usePagination(100);
  const [stores, setStores] = useState<IStore[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<IUnreadMessagesMap>({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedStoreId, setSelectedStoreIdState] = useState<
    string | undefined
  >(initializeSelectedStoreId());
  const [taxRates, setTaxRates] = useState<ITaxRate[]>([]);
  const [productTags, setProductTags] = useState<IProductTag[]>([]);

  const [venueLocations, setVenueLocations] = useState<IVenueLocation[]>([]);
  const [venueSuites, setVenueSuites] = useState<IVenueLocation[]>([]);

  const setSelectedStoreId = (storeId: string | undefined): void => {
    setSelectedStoreIdState(storeId);

    if (storeId) {
      LocalStorageService.storeData('selectedStoreId', storeId);
    } else {
      LocalStorageService.clearLocalByKey('selectedStoreId');
    }
  };

  const loadCurrentUser = async (): Promise<boolean> => {
    setShowSpinner(true);
    let success = false;
    try {
      // initialize user
      const {
        data: currentUser,
      }: { data: IUser } = await UserService.getCurrentUser();
      window.localStorage.setItem(currentUser.id, 'userId');
      setUser(currentUser);
      if (canAccessManagerPortal(currentUser)) {
        success = true;
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
      await logout();
    }
    return success;
  };

  const logout = async () => {
    await LocalStorageService.clearLocalData();
    setUser(undefined);
    window.localStorage.removeItem('userId');
  };

  const setAllStores = (allStores: IStore[]) => {
    if (allStores.length <= 0) {
      setStores([]);
      LocalStorageService.clearLocalByKey('selectedStoreId');
      return;
    }

    setStores(allStores);

    if (
      !selectedStoreId ||
      !allStores.map(s => s.id).includes(selectedStoreId)
    ) {
      setSelectedStoreId(allStores[0].id);
    }
  };

  // this is currently set to 150 to create a buffer against the true
  // limit of 250 in Twilio since the app will only clean up channel
  // counts when it is running. a future consideration would also be to
  // drop channels older than 6 months in addition to the numeric limit
  const channelLimit = 150;

  const getAllChannels = async (c: Client): Promise<void> => {
    let page: Paginator<Channel> = await c.getSubscribedChannels();
    let allChannels: Channel[] = page.items;

    while (page.hasNextPage) {
      const newPage = await page.nextPage();

      allChannels.push(...newPage.items);
      page = newPage;
    }

    allChannels = _(allChannels)
      .sortBy((channel: Channel) =>
        channel.lastMessage
          ? channel.lastMessage.dateCreated
          : channel.dateUpdated,
      )
      .reverse()
      .value();

    // unsubscribe from channels over the set limit
    const channelsToLeave = _.remove(
      allChannels,
      (channel: Channel, index: number) => index >= channelLimit,
    );
    for (const channel of channelsToLeave) {
      await channel.leave();

      // tslint:disable-next-line: no-console
      console.log(`Left channel: ${channel.uniqueName}`);
    }

    allChannels = _(allChannels)
      .filter((channel: Channel) => !!channel.lastMessage)
      .value();

    // set unread message count
    const initialUnreadMessages: { [key: string]: IUnreadMessage } = allChannels
      .filter(
        (channel: Channel) =>
          typeof channel.lastMessage === 'undefined' ||
          typeof channel.lastMessage.index === 'undefined' ||
          channel.lastConsumedMessageIndex === null ||
          channel.lastConsumedMessageIndex < channel.lastMessage.index,
      )
      .reduce(
        (unread: any, channel: Channel) =>
          Object.assign(unread, {
            [`${channel.sid}`]: {
              order: channel.uniqueName,
              storeId: channel.friendlyName,
            },
          }),
        {},
      );
    setUnreadMessages(initialUnreadMessages);

    setChannels(allChannels);
  };

  useEffect(() => {
    (async () => {
      if (venue) {
        const c = await getTokenAndCreateClient(venue.id);

        c.on('channelInvited', async (channel: Channel) => {
          try {
            await channel.join();

            await getAllChannels(c);
          } catch (error) {
            // tslint:disable-next-line: no-console
            console.log(error);
          }
        });

        c.on('messageAdded', async () => {
          await getAllChannels(c);
        });

        await getAllChannels(c);

        setClient(c);
      }
    })();
  }, [venue]);

  useEffect(() => {
    (async () => {
      if (!venue || venue.type === 'SERVICE_PROVIDER') {
        return;
      }

      try {
        const {
          data: { items },
        } = await VenueService.getVenuesByType(true, 'TENANT_BUILDING', 10000);

        setBuildingVenues(items);
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log('Error fetching Buildings: ', error);
      }
    })();
  }, [venue]);

  useEffect(() => {
    fetchTaxRates();
  }, [venue]);

  useEffect(() => {
    fetchProductTags();
  }, [venue]);

  useEffect(() => {
    fetchEvents();
  }, [venue]);

  useEffect(() => {
    fetchStores();
  }, [venue, storePagination.page, storePagination.rowsPerPage]);

  useEffect(() => {
    fetchVenueLocations();
  }, [venue]);

  useEffect(() => {
    fetchVenueSuites();
  }, [venue]);

  useEffect(() => {
    if (selectedStoreId) {
      getAllTerminals();
    }
  }, [selectedStoreId]);

  const fetchProductTags = async () => {
    if (!venue) {
      return;
    }

    setShowSpinner(true);
    try {
      const { data } = await ProductTagService.getAllProductTag(venue.id);
      setProductTags(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
    setShowSpinner(false);
  };
  const fetchTaxRates = async () => {
    if (!venue) {
      return;
    }

    setShowSpinner(true);
    try {
      const { data } = await TaxRateService.getAllTaxRates(venue.id);
      setTaxRates(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
    setShowSpinner(false);
  };

  const fetchStores = async () => {
    if (!venue) {
      return;
    }

    const { rowsPerPage, page, setTotalCount } = storePagination;
    setShowSpinner(true);
    try {
      const { data } = await StoreService.getAllStores({
        limit: rowsPerPage,
        pageNumber: page,
        venueId: venue.id,
      });

      setAllStores(data.items);
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const getAllTerminals = async () => {
    axiosWithAuth
      .get(`terminals/store/${selectedStoreId}`)
      .then(response => {
        setAllTerminals(response.data);
      })
      .catch(error => {
        // console.log('Error while fetching terminals ', error);
      });
  };

  const getAllAndroidPOS = async () => {
    axiosWithAuth
      .get(`terminals/store/${selectedStoreId}`)
      .then(response => {
        setAllTerminals(response.data);
      })
      .catch(error => {
      });
  }

  const fetchEvents = async (upcoming: boolean = true) => {
    if (!venue) {
      return;
    }

    const { rowsPerPage, page, setTotalCount } = eventPagination;
    setShowSpinner(true);
    try {
      const { data } = await EventService.getAllEvents(
        rowsPerPage,
        page + 1,
        venue.id,
        false,
        upcoming,
        !upcoming,
      );

      setEvents(data.items);
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const fetchVenueLocations = async () => {
    if (!venue) {
      return;
    }
    try {
      const { data } = await VenueLocationService.getVenueLocations(venue.id);
      setVenueLocations(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

  const fetchVenueSuites = async () => {
    if (!venue) {
      return;
    }
    try {
      const { data } = await VenueLocationService.getVenueSuites(venue.id);
      setVenueSuites(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

  return (
    <AppContextProvider
      value={{
        buildingVenues,
        channels,
        client,
        currentChannel,
        eventPagination,
        events,
        fetchEvents,
        fetchStores,
        fetchTaxRates,
        fetchProductTags,
        fetchVenueLocations,
        fetchVenueSuites,
        loadCurrentUser,
        logout,
        selectedStoreId,
        setAllStores,
        setAllTerminals,
        setCurrentChannel,
        setSelectedStoreId,
        setShowSpinner,
        setUnreadMessages,
        setVenue,
        setCartItems,
        setSubtotal,
        setStripeTerminal,
        subTotal,
        stripeTerminal,
        cartItems,
        showSpinner,
        storePagination,
        stores,
        taxRates,
        productTags,
        unreadMessages,
        user,
        venue,
        venueLocations,
        venueSuites,
        allTerminals,
        getAllTerminals,
      }}
    >
      {children}
    </AppContextProvider>
  );
};

export { useAppContext, AppContext, AppProvider };
