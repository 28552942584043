import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../config/AppContext';
import delIcon from '../../images/del.jpg';

export default function CheckoutCart({
  addToCart,
  removeItem,
  decrementItem,
  taxAmount,
  selectedItems,
  discountAmount,
  convenienceFee,
}: any) {
  const { cartItems, subTotal, setSubtotal } = useAppContext();

  setSubtotal(
    cartItems?.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.total;
    }, 0),
  );
  return (
    <>
      {/* Checkout Cart------------------------ */}

      <div className="_order_summary">
        <p style={{ textAlign: 'center' }}>Order Summary</p>
      </div>
      <div className="_right_container">
        <div className="_items_Container">
          {cartItems &&
            cartItems.map((item: any, ind: number) => {
              return (
                <div key={ind}>
                  <div className="_item_details_header">
                    <div>
                      <p style={{ marginBottom: '20px' }}>{item.name}</p>
                      <div style={{ marginTop: '-10px' }}>
                        {item.subSelections?.map((selection: any) =>
                          selection?.subSelections?.map(
                            (selectedItem: any, index: number) => (
                              <p
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  margin: 0,
                                  marginBottom: '10px',
                                  padding: 0,
                                }}
                                key={index}
                              >
                                {selectedItem.name}
                              </p>
                            ),
                          ),
                        )}
                      </div>

                      <div></div>
                    </div>
                    <span>
                      <div>
                        <p style={{ marginRight: '-4px' }}>
                          $ {(item.productPrice * item.quantity).toFixed(2)}
                        </p>
                        <div style={{ marginTop: '-10px' }}>
                          {item.subSelections?.map((selection: any) =>
                            selection?.subSelections?.map(
                              (selectedItem: any, index: number) => (
                                <p
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'normal',
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  key={index}
                                >
                                  ${' '}
                                  {(selectedItem.price * item.quantity).toFixed(
                                    2,
                                  )}
                                </p>
                              ),
                            ),
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="_item_buttons" style={{ display: 'flex' }}>
                    <div className="_item_button">
                      <button
                        className="_cart_item_add"
                        style={{ marginLeft: 0, marginTop: 0 }}
                        onClick={() => {
                          decrementItem(ind, item.id, item.quantity);
                        }}
                      >
                        -
                      </button>
                    </div>
                    <div className="_item_button">
                      <img
                        alt=""
                        style={{ marginLeft: 0 }}
                        src={delIcon}
                        height="37px"
                        width="35"
                        onClick={() => removeItem(ind, item.id, item.quantity)}
                      />
                    </div>
                    <div className="_item_button">
                      <button
                        className="_cart_item_add"
                        style={{ marginLeft: 0, marginTop: 0 }}
                        onClick={() => {
                          let product = item;
                          product.id = item.productId;
                          addToCart(product, ind);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div className="_item_button">
                      <p style={{ marginLeft: 10, marginTop: 0 }}>
                        {item.quantity}x
                      </p>
                    </div>
                  </div>
                  {/* <div className="_item_details">
                    {item.description
                      ?.split(',')
                      .map((value: string, ind: number) => (
                        <p key={ind}>{value}</p>
                      ))}
                  </div> */}
                </div>
              );
            })}
        </div>
        <div className="_divider" />
        <div className="_total_container">
          <div className="_subtotal">
            <span>Subtotal</span>
            <span>$ {subTotal.toFixed(2)}</span>
          </div>
          <div className="_subtotal">
            <span>Tax</span>
            <span>$ {taxAmount.toFixed(2)}</span>
          </div>
          {convenienceFee > 0 && (
            <div className="_subtotal">
              <span>POS Charges (Applied to CC only)</span>
              <span>+ $ {convenienceFee.toFixed(2)}</span>
            </div>
          )}
          {discountAmount > 0 && (
            <div className="_subtotal">
              <span>Discount Applied</span>
              <span>- $ {discountAmount.toFixed(2)}</span>
            </div>
          )}
          <div className="_total">
            <span>Order Total</span>
            <span>
              ${' '}
              {(
                subTotal +
                taxAmount -
                discountAmount +
                convenienceFee
              ).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
