import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useStoreContext } from '../../../config/StoreContext';
import { minsToSecs, secsToMins } from '../../../services/Util/Util.service';
import './EtaDialog.scss';

const EtaDialog = (): JSX.Element => {
  const { store, updateStore } = useStoreContext();

  const [averageDeliveryTimeMinutes, setAverageDeliveryTimeMinutes] = useState<
    number
  >(secsToMins(store?.averageDeliveryTimeSeconds ?? 0));

  const [averagePrepTimeMinutes, setAveragePrepTimeMinutes] = useState<number>(
    secsToMins(store?.averagePrepTimeSeconds ?? 0),
  );

  if (!store) {
    return <></>;
  }

  const renderTimeSelection = ({
    description,
    setTimeInMinutes,
    timeInMinuteOptions,
    timeInMinutes,
    title,
  }: {
    description: string;
    setTimeInMinutes: React.Dispatch<React.SetStateAction<number>>;
    timeInMinuteOptions: number[];
    timeInMinutes: number;
    title: string;
  }) => {
    return (
      <div className="eta-dialog-type-container">
        <Typography className="eta-dialog-type-title">
          {title}: <span>{timeInMinutes} Minutes</span>
        </Typography>
        <Typography className="eta-dialog-type-subtitle">
          {description}
        </Typography>
        <div className="eta-dialog-buttons-container">
          {timeInMinuteOptions.map((option: number, index: number) => (
            <Button
              className={classNames(
                'eta-button',
                option === timeInMinutes && 'active',
              )}
              color="primary"
              key={index}
              onClick={() => setTimeInMinutes(option)}
              variant="outlined"
            >
              {option}m
            </Button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="eta-dialog">
      {renderTimeSelection({
        description:
          'Average time it takes to prep a single order in a low-traffic period',
        setTimeInMinutes: setAveragePrepTimeMinutes,
        timeInMinuteOptions: [5, 10, 15, 20, 30, 60],
        timeInMinutes: averagePrepTimeMinutes,
        title: 'Average Prep Time',
      })}
      {renderTimeSelection({
        description:
          'Average time it takes to deliver an order after it has been prepped',
        setTimeInMinutes: setAverageDeliveryTimeMinutes,
        timeInMinuteOptions: [5, 10, 15, 20, 30, 60],
        timeInMinutes: averageDeliveryTimeMinutes,
        title: 'Average Delivery Time',
      })}
      <div className="button-holder-similar-margin">
        <Button
          onClick={() =>
            updateStore(store.id, {
              averageDeliveryTimeSeconds: minsToSecs(
                averageDeliveryTimeMinutes,
              ),
              averagePrepTimeSeconds: minsToSecs(averagePrepTimeMinutes),
            })
          }
          color="primary"
          variant="contained"
        >
          <div className="icon-title-wrapper">
            <span className="icon-title">Save</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default EtaDialog;
