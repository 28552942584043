import {
  ICreateLocationDto,
  IUpdateVenueLocationDto,
  IVenueLocation,
} from '../../../models/Location.model';
import axiosWithAuth from '../../ServiceConfig';

const basePath = `/venue-locations`;
const venueBasePath = '/venues';

const createVenueLocation = async (venueLocationDto: ICreateLocationDto) =>
  await axiosWithAuth.post<IVenueLocation>(`${basePath}`, venueLocationDto);

const cloneVenueLocation = async (locationId: string) =>
  await axiosWithAuth.post<IVenueLocation>(`${basePath}/${locationId}/clone`);

const deleteVenueLocation = async (locationId: string) =>
  await axiosWithAuth.delete<void>(`${basePath}/${locationId}`);

const getVenueLocations = async (venueId: string) =>
  await axiosWithAuth.get<IVenueLocation[]>(
    `${venueBasePath}/${venueId}/locations`,
  );

const getVenueSuites = async (venueId: string) =>
  await axiosWithAuth.get<IVenueLocation[]>(
    `${venueBasePath}/${venueId}/suites`,
  );

const findVenueLocation = async (locationId: string) =>
  await axiosWithAuth.get<IVenueLocation>(`${basePath}/${locationId}`);

const updateVenueLocation = async (
  locationId: string,
  updateLocationDto: IUpdateVenueLocationDto,
) =>
  await axiosWithAuth.put<IVenueLocation>(
    `${basePath}/${locationId}`,
    updateLocationDto,
  );

export default {
  cloneVenueLocation,
  createVenueLocation,
  deleteVenueLocation,
  findVenueLocation,
  getVenueLocations,
  getVenueSuites,
  updateVenueLocation,
};
