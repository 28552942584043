import {
  Button,
  Chip,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';
import React from 'react';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { PartnerId } from '../../../components/Partner/PartnerId';
import { useAppContext } from '../../../config/AppContext';
import {
  IStore,
  ValidStoreType,
  validStoreTypeName,
} from '../../../models/Store.model';
import { canEditStore } from '../../../services/Auth.service';
import {
  isFulfullmentCenter,
  storeTableColumnNames,
  tooltipInfo,
} from './Store.config';

interface IStoresTableProps {
  stores: IStore[];
  handleSwitchUpdate: (
    event: React.ChangeEvent<{}>,
    storeId: string,
    key: string,
  ) => Promise<void>;
  selectStore: (store: IStore) => void;
}

const StoresTable = ({
  handleSwitchUpdate,
  selectStore,
  stores,
}: IStoresTableProps) => {
  const { user, venue } = useAppContext();

  enum storeActions {
    active = 'active',
    orderAheadAvailable = 'orderAheadAvailable',
    orderNowAvailable = 'orderNowAvailable',
  }

  const [tooltip, setTooltip] = React.useState({
    active: '',
    orderAheadAvailable: '',
    orderNowAvailable: '',
  });

  const handleTooltipClose = (actionName: storeActions, storeId: string) => {
    if (tooltip[actionName] === storeId) {
      setTooltip({ ...tooltip, [actionName]: '' });
    }
  };

  const handleTooltipOpen = (actionName: storeActions, storeId: string) => {
    setTooltip({ ...tooltip, [actionName]: storeId });
  };

  if (!user || !venue) {
    return null;
  }

  return (
    <Table className="stores__table">
      <TableBody>
        <TableRow classes={{ root: 'stores__table-header' }}>
          {storeTableColumnNames.map((column: any, i: number) => (
            <TableCell
              key={i}
              align="left"
              size={column.size}
              variant="head"
              classes={{ root: 'stores__table-cell' }}
            >
              <p>{column.name}</p>
            </TableCell>
          ))}
        </TableRow>
        {stores.length > 0
          ? stores.map((store: IStore) => (
              <TableRow
                key={store.id}
                hover={true}
                className="stores-table-row"
              >
                <TableCell
                  key={`store ${store.id}_name`}
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'stores__table-cell' }}
                >
                  <p>
                    {store.name}
                    <span
                      className={classNames(
                        'stores__table-cell__active-indicator',
                        store.active && 'active',
                      )}
                    />
                  </p>
                  {store.type !== ValidStoreType.REGULAR && (
                    <Chip
                      label={validStoreTypeName[store.type]}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  <PartnerId
                    id={store.partnerStoreId}
                    partner={store.partner}
                  />
                </TableCell>
                <TableCell
                  key={`store ${store.id}_location`}
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'stores__table-cell' }}
                >
                  <p>{store.location || ''}</p>
                </TableCell>
                <TableCell
                  key={`store ${store.id}_active`}
                  align="left"
                  size="small"
                  variant="body"
                  classes={{ root: 'stores__table-cell' }}
                >
                  <div className="store__action__toggle-buttons">
                    <span>Active</span>
                    <Switch
                      disabled={!venue.active}
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.active}
                      color="primary"
                      name="active"
                      onChange={e => handleSwitchUpdate(e, store.id, 'active')}
                      size="medium"
                      value={store.active}
                    />
                    {!venue.active && (
                      <CustomTooltip
                        placement="top"
                        open={tooltip[storeActions.active] === store.id}
                        title={tooltipInfo.active}
                        onOpen={() =>
                          handleTooltipOpen(storeActions.active, store.id)
                        }
                        onClickAway={() =>
                          handleTooltipClose(storeActions.active, store.id)
                        }
                      >
                        <HelpOutlineIcon className="store__action__help-icon" />
                      </CustomTooltip>
                    )}
                  </div>
                  <div className="store__action__toggle-buttons">
                    <span>Customer Notes</span>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.orderInstructionsAvailable}
                      color="primary"
                      name="orderInstructionsAvailable"
                      onChange={e =>
                        handleSwitchUpdate(
                          e,
                          store.id,
                          'orderInstructionsAvailable',
                        )
                      }
                      size="medium"
                      value={store.orderInstructionsAvailable}
                    />
                  </div>
                  <div className="store__action__toggle-buttons">
                    <span>Product Images</span>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.showProductImages}
                      color="primary"
                      name="showProductImages"
                      onChange={e =>
                        handleSwitchUpdate(e, store.id, 'showProductImages')
                      }
                      size="medium"
                      value={store.showProductImages}
                    />
                  </div>
                  <div className="store__action__toggle-buttons">
                    <span>Allow Open Tabs</span>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.allowOpenTabs}
                      color="primary"
                      name="allowOpenTabs"
                      onChange={e =>
                        handleSwitchUpdate(e, store.id, 'allowOpenTabs')
                      }
                      size="medium"
                      value={store.allowOpenTabs}
                    />
                  </div>
                  {/* <div className="store__action__toggle-buttons">
                    <span>Custom Discount</span>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.customDiscount}
                      color="primary"
                      name="customDiscount"
                      onChange={e =>
                        handleSwitchUpdate(
                          e,
                          store.id,
                          'customDiscount',
                        )
                      }
                      size="medium"
                      value={store.customDiscount}
                    />
                  </div> */}
                </TableCell>

                <TableCell
                  key={`store ${store.id}_active orderAhead`}
                  align="center"
                  size="small"
                  variant="body"
                  classes={{ root: 'stores__table-cell' }}
                >
                  <div className="store__action__toggle-buttons">
                    <div className="switch-container">
                      <span>Now</span>

                      <Switch
                        disabled={!venue.orderNowAvailable}
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        checked={store.orderNowAvailable}
                        color="primary"
                        name="orderNowAvailable"
                        onChange={e =>
                          handleSwitchUpdate(e, store.id, 'orderNowAvailable')
                        }
                        size="medium"
                        value={store.orderNowAvailable}
                      />
                    </div>
                    {!venue.orderNowAvailable && (
                      <CustomTooltip
                        placement="top"
                        open={
                          tooltip[storeActions.orderNowAvailable] === store.id
                        }
                        title={tooltipInfo.orderNowAvailable}
                        onOpen={() =>
                          handleTooltipOpen(
                            storeActions.orderNowAvailable,
                            store.id,
                          )
                        }
                        onClickAway={() =>
                          handleTooltipClose(
                            storeActions.orderNowAvailable,
                            store.id,
                          )
                        }
                      >
                        <HelpOutlineIcon className="store__action__help-icon" />
                      </CustomTooltip>
                    )}
                  </div>

                  <div className="store__action__toggle-buttons">
                    <div className="switch-container">
                      <span>Ahead</span>

                      <Switch
                        disabled={!venue.orderAheadAvailable}
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        checked={store.orderAheadAvailable}
                        color="primary"
                        name="orderAheadAvailable"
                        onChange={e =>
                          handleSwitchUpdate(e, store.id, 'orderAheadAvailable')
                        }
                        size="medium"
                        value={store.orderAheadAvailable}
                      />
                    </div>
                    {!venue.orderAheadAvailable && (
                      <CustomTooltip
                        open={
                          tooltip[storeActions.orderAheadAvailable] === store.id
                        }
                        title={tooltipInfo.orderAheadAvailable}
                        onOpen={() =>
                          handleTooltipOpen(
                            storeActions.orderAheadAvailable,
                            store.id,
                          )
                        }
                        onClickAway={() =>
                          handleTooltipClose(
                            storeActions.orderAheadAvailable,
                            store.id,
                          )
                        }
                      >
                        <HelpOutlineIcon className="store__action__help-icon" />
                      </CustomTooltip>
                    )}
                  </div>
                </TableCell>
                <TableCell
                  key={`store ${store.id}_active delivery`}
                  align="center"
                  size="small"
                  variant="body"
                  classes={{ root: 'stores__table-cell' }}
                >
                  <div className="store__action__toggle-buttons">
                    <span>Pick Up</span>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.pickupAvailable}
                      color="primary"
                      name="pickupAvailable"
                      onChange={e =>
                        handleSwitchUpdate(e, store.id, 'pickupAvailable')
                      }
                      size="medium"
                      value={store.pickupAvailable}
                    />
                  </div>
                  <div className="store__action__toggle-buttons">
                    <span>Delivery</span>
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={store.deliveryAvailable}
                      color="primary"
                      name="deliveryAvailable"
                      onChange={e =>
                        handleSwitchUpdate(e, store.id, 'deliveryAvailable')
                      }
                      size="medium"
                      value={store.deliveryAvailable}
                    />
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'stores__table-cell' }}
                >
                  {(!isFulfullmentCenter(store) ||
                    canEditStore(user, venue.id, store.id)) && (
                    <Button
                      size="small"
                      classes={{ root: 'store__action-buttons' }}
                      onClick={() => selectStore(store)}
                      value="Manage"
                      title="Manage Store"
                    >
                      <div className="icon-title-wrapper">
                        <SettingsIcon />
                        <span>Manage</span>
                      </div>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))
          : noDataAvailable(5)}
      </TableBody>
    </Table>
  );
};

export default StoresTable;
