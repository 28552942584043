import React, { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useSnackbar } from 'notistack';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axiosWithAuth, { axiosWithAccessAndroidPOS } from '../../../services/ServiceConfig';
import { Alert } from '@material-ui/lab';
import { useAppContext } from '../../../config/AppContext';
import { useHistory } from 'react-router-dom';
import AddImage from '../../AddImage/AddImage';
import { useStoreContext } from '../../../config/StoreContext';

interface IAndroidPOSTerminal {
  terminalName: string;
  registrationCode: string;
  venueId: string;
  storeId: string;
  locationId: string;
  isActivated: string;
  id: string;
}

const Terminals = () => {
  const history = useHistory();
  const {
    user,
    venue,
    selectedStoreId,
    allTerminals,
    getAllTerminals,
    taxRates,
  } = useAppContext();

  const {
    categories,
    fulfillmentCenters,
    products,
    selectedProductCategoryId,
    store,
  } = useStoreContext();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isAddTerminal, setIsAddTerminal] = React.useState(false);
  const [isEditTerminal, setIsEditTerminal] = React.useState(false);
  const [isDeleteTerminal, setIsDeleteTerminal] = React.useState(false);
  const [terminalName, setTerminalName] = React.useState('');
  const [terminalPin, setTerminalPin] = React.useState('');
  const [editTerminalPin, setEditTerminalPin] = React.useState('');
  const [registrationCode, setRegistrationCode] = React.useState('');
  const [selectedDeId, setSelectedDelId] = React.useState('');
  const [isPosCash, setIsPosCash] = React.useState(true);
  const [editTerminalName, setEditTerminalName] = React.useState('');
  const [editRegistrationCode, setEditRegistrationCode] = React.useState('');
  const [isEditTerminalId, setIsEditTerminalId] = React.useState('');
  const [terminalLocationId, setTerminalLocationId] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [terminalNameError, setTerminalNameError] = React.useState(false);
  const [
    terminalRegistrationCodeError,
    setTerminalRegistrationCodeError,
  ] = React.useState(false);

  const [editTerminalNameError, setEditTerminalNameError] = React.useState(
    false,
  );
  const [
    editRegistrationCodeError,
    setEditRegistrationCodeError,
  ] = React.useState(false);

  const [showError, setShowError] = React.useState(false);
  const [showError1, setShowError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState('');

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showImageUploadPopUp, setShowImageUploadPopUp] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState('');

  const [image, setImage] = React.useState('');
  const [storeContext, setStoreContext] = React.useState();
  const userId = window.localStorage.getItem('userId');
  const [showCartPopup, setShowCartPopup] = React.useState(false);
  const [cart, setCar] = React.useState<any>();
  const [selectTerminal, setSelectTerminal] = React.useState();
  const handleAddTerminalOpen = () => {
    setIsAddTerminal(true);
  };

  const [allAndroidPOS, setAllAndroidPOS] = React.useState<IAndroidPOSTerminal[]>();
  const [isAddingPOS, setIsAddingPOS] = React.useState(false);
  const [newPOSName, setNewPOSName] = React.useState("");
  const getAllAndroidPOS = async () => {
    const res = (await axiosWithAccessAndroidPOS.get(`AndroidPOSTerminals/forStore/${selectedStoreId}`)).data;
    setAllAndroidPOS(res);
  }
  const createAndroidPOS = async () => {
    if (!newPOSName) {
      return;
    }
    const payload = {
      terminalName: newPOSName,
      venueId: venue?.id,
      storeId: selectedStoreId,
    };
    axiosWithAccessAndroidPOS
      .post('AndroidPOSTerminals', payload)
      .then(async response => {
        handleAddPOSClose();
        await getAllAndroidPOS();
        setShowSuccess(true);
      })
      .catch(error => {
        setShowError2(true);
        setShowErrorMessage(error.response.data.messa);
      });
  };
  const handleAddPOSClose = () => {
    setNewPOSName('');
    setIsAddingPOS(false);
  }

  const handleAddTerminalClose = () => {
    setTerminalNameError(false);
    setTerminalRegistrationCodeError(false);
    setIsAddTerminal(false);
    setTerminalName('');
    setRegistrationCode('');
    setEditTerminalPin('');
  };

  const handleEditTerminalOpen = (terminal: any) => {
    setIsEditTerminalId(terminal.id);
    setEditTerminalName(terminal.terminalName);
    setEditRegistrationCode(terminal.registrationCode);
    setIsPosCash(terminal.isPOSCash);
    setEditTerminalPin(terminal.terminalPin);
    setIsEditTerminal(true);
  };

  const handleEditTerminalClose = () => {
    setIsEditTerminal(false);
  };

  const handleDeleteTerminalOpen = (terminal: any) => {
    setSelectedDelId(terminal.id);
    setIsDeleteTerminal(true);
  };

  const handleDeleteTerminalClose = () => {
    setSelectedDelId('');
    setIsDeleteTerminal(false);
  };
  const setImageFile = (file: any) => {
    if (file.name) {
      setImage(file);
    } else {
      setImage('');
    }
  };

  const createTerminal = async () => {
    if (terminalName === '' || terminalName === undefined) {
      setTerminalNameError(true);
      return;
    }
    const payload = {
      terminalName,
      userId: user,
      venueId: venue,
      storeId: selectedStoreId,
      isPosCash,
    };
    if (!isPosCash) {
      if (registrationCode === '' || registrationCode === undefined) {
        setTerminalRegistrationCodeError(true);
        return;
      }
      Object.assign(payload, { registrationCode: registrationCode });
    }
    if (terminalPin) {
      Object.assign(payload, { terminalPin: terminalPin });
    }
    axiosWithAuth
      .post('terminals', payload)
      .then(async response => {
        if (response.data.status === 403) {
          setShowError(true);
          return;
        }
        if (response.data.status === 400) {
          setShowError1(true);
          return;
        }
        setShowError(false);
        setShowError1(false);
        setShowError2(false);

        handleAddTerminalClose();
        await getAllTerminals();
        setTerminalName('');
        setRegistrationCode('');
        setTerminalPin('');
        setEditTerminalPin('');
        setShowSuccess(true);
      })
      .catch(error => {
        setShowError2(true);
        setShowErrorMessage(error.response.data.messa);
      });
  };

  const onDeleteTerminal = async () => {
    setIsDeleteTerminal(true);
    axiosWithAuth
      .delete(`terminals/${selectedDeId}`)
      .then(async response => {
        setShowSuccess(true);
        handleDeleteTerminalClose();
        setIsDeleteTerminal(false);
        const result = allTerminals.filter((terminal: any) => {
          return terminal.id !== selectedDeId;
        });
        await getAllTerminals();
      })
      .catch(error => {
      });
  };

  const onUpdateTerminal = async () => {
    if (editTerminalName === '' || editTerminalName === undefined) {
      setEditTerminalNameError(true);
      return;
    }

    if (editRegistrationCode === '' || editRegistrationCode === undefined) {
      setEditRegistrationCodeError(true);
      return;
    }

    const payload = {
      terminalName: editTerminalName,
      terminalPin: editTerminalPin,
    };
    axiosWithAuth
      .put(`terminals/${isEditTerminalId}`, payload)
      .then(async response => {
        setShowSuccess(true);
        setIsDeleteTerminal(false);
        setIsEditTerminal(false);
        setEditTerminalPin('');
        await getAllTerminals();
      })
      .catch(error => {
      });
  };

  useEffect(() => {
    if (allTerminals?.length < 1) {
      (async () => {
        await getAllTerminals();
      })();
    }
    (async () => {
      await getAllAndroidPOS()
    })();
    if (store && categories && products) {
      const arr: any = [];
      arr.push(
        categories,
        products,
        store,
        fulfillmentCenters,
        selectedProductCategoryId,
        taxRates,
      );
      setStoreContext(arr);
    }
  }, [store, categories, products]);

  const handleUploadImage = (terminal: any) => {
    setIsEditTerminalId(terminal.id);
    setTerminalLocationId(terminal.locationId);
    setShowImageUploadPopUp(true);
    if (terminal.image != null) {
      setImageUrl(terminal.image);
    }
  };

  const handleUploadImageCancel = () => {
    setShowImageUploadPopUp(false);
  };
  const uploadImage = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      if (image) {
        formData.append('imageFile', image);
        formData.append('locationId', terminalLocationId);
        await axiosWithAuth.put(
          `terminals/terminalImage/${isEditTerminalId}`,
          formData,
        );
        setShowSuccess(true);
        setIsDeleteTerminal(false);
        setShowImageUploadPopUp(false);
        await getAllTerminals();
        enqueueSnackbar('Image Uploaded successfully', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Please add image', {
          variant: 'warning',
        });
      }
      setIsLoading(false);
    } catch (err) {
      setShowImageUploadPopUp(false);
      enqueueSnackbar('Error While Uploading image', {
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  const createAccess = async (item: any) => {
    try {
      setSelectTerminal(item);
      const check = window.localStorage.getItem('cartId');
      if (check) {
        window.localStorage.removeItem('cartId');
      }
      history.push('/terminal', { item, storeContext });
      return;
    } catch (error) {
      console.log(error.message, 'error loading');
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };


  return (
    <>
      {showSuccess && <Message />}
      <div id='add-terminal'>
        <Button
          variant="outlined"
          onClick={handleAddTerminalOpen}
          className="_button_upper"
        >
          ADD TERMINAL
        </Button>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Terminal Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allTerminals.map((item: any, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="_terminal_tab">
                    {item.terminalName}
                  </TableCell>
                  <TableCell className="_status_tab">Connected</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={async () => {
                        await createAccess(item);
                      }}
                      startIcon={<ArrowUpwardOutlinedIcon />}
                      className="_button"
                    >
                      Access
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<EditOutlinedIcon />}
                      onClick={() => handleEditTerminalOpen(item)}
                      className="_button"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<PhotoLibraryOutlinedIcon />}
                      className="_button"
                      onClick={() => handleUploadImage(item)}
                    >
                      Image
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={() => handleDeleteTerminalOpen(item)}
                      className="_button"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Dialog
          open={isAddTerminal}
          onClose={handleAddTerminalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">ADD TERMINAL</DialogTitle>
          <DialogContent>
            {showError1 && <Alert severity="error">Invalid Serial No.!</Alert>}
            {showError && <Alert severity="error">Terminal Already Exist</Alert>}
            {showError2 && <Alert severity="error">{showErrorMessage}</Alert>}

            <TextField
              margin="dense"
              id="name"
              label="Terminal Name"
              type="text"
              fullWidth={true}
              value={terminalName}
              onChange={newValue => setTerminalName(newValue.target.value)}
              required={true}
              error={terminalNameError}
            />

            <TextField
              margin="dense"
              id="name"
              label="Registration Code"
              type="text"
              fullWidth={true}
              value={registrationCode}
              disabled={isPosCash ? true : false}
              onChange={newValue => setRegistrationCode(newValue.target.value)}
              required={true}
              error={terminalRegistrationCodeError}
            />
            <label htmlFor="pos-cash-switch">For Cash Only</label>
            <Switch
              classes={{
                track: 'custom-colored-switch-track',
              }}
              checked={isPosCash}
              color="primary"
              name="alcohol"
              onChange={(event: any) => {
                if (event.target.value) setRegistrationCode('');
                setIsPosCash(prev => !prev);
              }}
              size="medium"
              value={isPosCash}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAddTerminalClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button color="primary" variant="outlined" onClick={createTerminal}>
              ADD
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isEditTerminal}
          onClose={handleEditTerminalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">EDIT TERMINAL</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="Terminal Name"
              type="text"
              fullWidth={true}
              value={editTerminalName}
              onChange={newValue => setEditTerminalName(newValue.target.value)}
              error={editTerminalNameError}
            />

            <TextField
              disabled={true}
              margin="dense"
              id="name"
              label="Terminal Serial Number"
              type="text"
              fullWidth={true}
              value={editRegistrationCode}
              onChange={newValue =>
                setEditRegistrationCode(newValue.target.value)
              }
              error={editRegistrationCodeError}
            />

            <label htmlFor="pos-cash-switch">For Cash Only</label>
            <Switch
              classes={{
                track: 'custom-colored-switch-track',
              }}
              checked={isPosCash}
              color="primary"
              name="alcohol"
              disabled={true}
              size="medium"
              value={isPosCash}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleEditTerminalClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={onUpdateTerminal} color="primary" variant="outlined">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDeleteTerminal}
          onClose={handleDeleteTerminalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
            DELETE TERMINAL
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: 'center' }}
            >
              Are you sure you want to Delete the Terminal.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={handleDeleteTerminalClose}
                color="primary"
                variant="outlined"
              >
                No Go Back
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={onDeleteTerminal}
              >
                Yes Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showImageUploadPopUp}
          onClose={handleDeleteTerminalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
            Upload Image
          </DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={imageUrl}
              width="120"
              style={{ objectFit: 'contain', maxHeight: '100px' }}
            />
          </div>
          {isLoading ? (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <CircularProgress size={100} />
            </div>
          ) : (
            <>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ textAlign: 'center' }}
                >
                  <AddImage
                    width={200}
                    height={160}
                    previewBottom={false}
                    showImageDetails={true}
                    getFile={setImageFile}
                    getImageURL={async (url: any) => {
                      setImageUrl(url);
                    }}
                    minHeight={600}
                    minWidth={720}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={handleUploadImageCancel}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={uploadImage}
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </>
          )}
        </Dialog>
      </div>
      <div id='add-android-pos'>
        <Button
          variant="outlined"
          onClick={() => setIsAddingPOS(true)}
          className="_button_upper"
        >
          ADD POS
        </Button>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>POS Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Registration Code</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAndroidPOS && allAndroidPOS.map((item: IAndroidPOSTerminal, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="_terminal_tab">
                    {item.terminalName}
                  </TableCell>
                  <TableCell className="_status_tab" style={{color: !item.isActivated ? 'red' : ''}}>{item.isActivated ? 'Activated' : 'Not Activated'}</TableCell>
                  <TableCell >{item.registrationCode.toUpperCase()}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      startIcon={<EditOutlinedIcon />}
                      onClick={() => handleEditTerminalOpen(item)}
                      className="_button"
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Dialog
          open={isAddingPOS}
          onClose={handleAddPOSClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">ADD POS</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="Terminal Name"
              type="text"
              fullWidth={true}
              value={newPOSName}
              onChange={newValue => setNewPOSName(newValue.target.value)}
              required={true}
            />

          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAddPOSClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button color="primary" variant="outlined" onClick={createAndroidPOS}>
              ADD
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default Terminals;

const Message = () => {
  const [alert, setAlert] = React.useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {alert && (
        <Alert severity="success" variant="filled">
          Success!
        </Alert>
      )}
    </>
  );
};
