import {
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
// import CreateIcon from '@material-ui/icons/Create';
// import DeleteIcon from '@material-ui/icons/Delete';
import { get } from 'lodash';
import React from 'react';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import DeleteIcon from '../../../images/deleteIcon.png';
import EditIcon from '../../../images/editIcon.png';
import { sponsorsTableColumnNames } from './SponsorsConfig';

const SponsorsTable = (props: any) => {
  const {
    onChangeActive,
    onDeleteSponsor,
    onEditSponsor,
    sponsorModules,
  } = props;

  return (
    <Table className="sponsors__table">
      <TableBody>
        <TableRow classes={{ root: 'sponsors__table-header' }}>
          {sponsorsTableColumnNames.map((column: any, i: number) => (
            <TableCell
              key={i}
              align={column.align}
              size={column.size}
              variant="head"
              classes={{ root: 'sponsors__table-cell' }}
            >
              <p>{column.name}</p>
            </TableCell>
          ))}
        </TableRow>
        {sponsorModules.length > 0
          ? sponsorModules.map((sponsorModule: any) => (
              <TableRow
                key={sponsorModule.id}
                hover={true}
                className="sponsors-table-row"
              >
                <TableCell
                  key={`sponsor ${sponsorModule.id}_name`}
                  align="left"
                  variant="body"
                  classes={{ root: 'sponsors__table-cell' }}
                >
                  <p>
                    {get(
                      sponsorModule,
                      ['sponsors', '0', 'name'],
                      sponsorModule.title,
                    )}
                  </p>
                </TableCell>
                <TableCell
                  key={`sponsor ${sponsorModule.id}_active`}
                  align="right"
                  size="small"
                  variant="body"
                  classes={{ root: 'sponsors__table-cell' }}
                >
                  <Switch
                    classes={{
                      track: 'custom-colored-switch-track',
                    }}
                    checked={sponsorModule.active}
                    color="secondary"
                    name="active"
                    onChange={e => onChangeActive(e, sponsorModule)}
                    size="medium"
                    value={sponsorModule.active}
                  />
                </TableCell>
                <TableCell
                  key={`sponsor ${sponsorModule.id}_action`}
                  align="right"
                  size="small"
                  variant="body"
                  classes={{ root: 'sponsors__table-cell' }}
                >
                  <Button
                    size="small"
                    classes={{ root: 'sponsors__action-buttons' }}
                    onClick={() => onEditSponsor(sponsorModule)}
                    variant="text"
                  >
                    <img
                      alt="edit sponsor module icon button"
                      src={EditIcon}
                      className="sponsors__action-buttons__edit-icon"
                    />
                  </Button>
                  <Button
                    size="small"
                    classes={{ root: 'sponsors__action-buttons' }}
                    onClick={() => onDeleteSponsor(sponsorModule)}
                    variant="text"
                  >
                    <img
                      alt="delete sponsor module icon button"
                      src={DeleteIcon}
                      className="sponsors__action-buttons__delete-icon"
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          : noDataAvailable(3)}
      </TableBody>
    </Table>
  );
};

export default SponsorsTable;
