import { IProductTag } from '../../models/ProductTag.model';
import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/product-tags`;
export interface IAddOrUpdateProductTagDto extends Partial<IProductTag> {
  venueId?: string;
}
export interface ICreateProductTagDto {
  name?: string;
  venueId: string;
}

export interface IUpdateProductTagDto {
  name?: string;
}

const getAllProductTag = (venueId: string) =>
  axiosWithAuth.get<IProductTag[]>(baseUrl, { params: { venueId: venueId } });

const createProductTag = (dto: ICreateProductTagDto) =>
  axiosWithAuth.post<IProductTag>(baseUrl, dto);

const updateProductTag = (tagId: string, dto: IUpdateProductTagDto) =>
  axiosWithAuth.put<IProductTag>(`${baseUrl}/${tagId}`, dto);

const deleteProductTag = (tagId: string) =>
  axiosWithAuth.delete<void>(`${baseUrl}/${tagId}`);

export default {
  createProductTag,
  deleteProductTag,
  getAllProductTag,
  updateProductTag,
};
