import React from 'react';
import Dashboard from '../../Dashboard/Dashboard';

interface IProps {
  venueId: string | undefined;
  startDate: Date;
  endDate: Date;
  dashBoardStoreId: string | undefined;
  reportData: any;
}

export function DashboardReport({
  venueId,
  startDate,
  endDate,
  dashBoardStoreId,
  reportData,
}: IProps) {
  return (
    <>
      <Dashboard
        venueId={venueId}
        startDate={startDate}
        endDate={endDate}
        dashBoardStoreId={dashBoardStoreId}
        reportData={reportData}
      />
    </>
  );
}
