import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/users`;

const getAllUserAdmins = (
  limit: number,
  page: number,
  role: string,
  firstName: string,
  lastName: string,
) =>
  axiosWithAuth.get(
    `${baseUrl}/admins?role=${role}&limit=${limit}&page=${page}&firstName=${firstName}&lastName=${lastName}`,
  );

const getAllUsers = (
  limit: number,
  page: number,
  role: string,
  firstName: string,
  lastName: string,
) =>
  axiosWithAuth.get(
    `${baseUrl}?role=${role}&limit=${limit}&page=${page}&firstName=${firstName}&lastName=${lastName}`,
  );

const getCurrentUser = () => axiosWithAuth.get(`${baseUrl}/current`);

const getUserById = (userId: string) =>
  axiosWithAuth.get(`${baseUrl}/${userId}`);

const createUserAdmin = (body: any) =>
  axiosWithAuth.post(`${baseUrl}/admin`, body);

const deleteUserAdmin = (userId: string) =>
  axiosWithAuth.delete(`${baseUrl}/admin/${userId}`);

const updateUserAdmin = (id: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/admin/${id}`, body);

const updateUser = (id: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${id}`, body);

export default {
  baseUrl,
  createUserAdmin,
  deleteUserAdmin,
  getAllUserAdmins,
  getAllUsers,
  getCurrentUser,
  getUserById,
  updateUser,
  updateUserAdmin,
};
