import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  List,
  Typography,
} from '@material-ui/core';
import { cloneDeep, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LocationTypeType } from '../../../../models/LocationTypeType.model';
import VenueService from '../../../../services/Venue/Venue.service';
import ValidLocationHierarchy from './ValidLocationHierarchy';
import './ValidLocationsDialog.scss';

interface IValidLocationType {
  id?: string;
  isEdit?: boolean;
  name: string;
  parentTypeId?: string | null;
  type: LocationTypeType | undefined;
  validValues: [] | string;
  validLocationTypes: IValidLocationType[] | [];
  venueId?: string;
}

const ValidLocationsDialog = (props: any) => {
  const {
    close,
    isEditingLocation,
    onLocationUpdate,
    validLocationTypes,
    venueId,
  } = props;

  const [currentValidLocationTypes, setCurrentValidLocationTypes] = useState<
    IValidLocationType[]
  >([]);

  const preprocessValidLocations = (newLocations: any) => {
    newLocations.forEach((location: any) => {
      location.isEdit = false;
      preprocessValidLocations(get(location, 'validLocationTypes'));
    });
    return newLocations;
  };

  useEffect(() => {
    const newLocations = cloneDeep(validLocationTypes);
    const processedLocations = preprocessValidLocations(newLocations);
    setCurrentValidLocationTypes(processedLocations);
  }, [validLocationTypes]);

  const handleAddLocation = (
    objectPath: string,
    newLocationName: string,
    newLocationType: LocationTypeType | undefined,
    newLocationValues: [],
    indexToAdd: number,
  ) => {
    const newLocation: IValidLocationType = {
      isEdit: true,
      name: newLocationName,
      type: newLocationType,
      validLocationTypes: [],
      validValues: newLocationValues,
    };
    setCurrentValidLocationTypes(prevState => {
      const validLocations: IValidLocationType[] = cloneDeep(prevState);

      if (!isEmpty(objectPath)) {
        const locationToAdd = get(validLocations, objectPath, []);
        locationToAdd.splice(indexToAdd, 0, newLocation);
      } else {
        validLocations.push(newLocation);
      }
      return validLocations;
    });
  };

  const handleDeleteLocation = async (
    indexToDelete: number,
    objectPath: string,
  ) => {
    const locationToDelete = !objectPath.length
      ? get(currentValidLocationTypes, indexToDelete)
      : get(currentValidLocationTypes, objectPath + '.' + indexToDelete, []);
    const locationTypeId = get(locationToDelete, 'id');

    if (!isEmpty(locationTypeId)) {
      try {
        await VenueService.deleteLocationType(venueId, locationTypeId);
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error);
      }
      return await onLocationUpdate('');
    } else {
      setCurrentValidLocationTypes(prevState => {
        const validLocations: IValidLocationType[] = cloneDeep(prevState);

        if (!isEmpty(objectPath)) {
          const localLocationToDelete = get(validLocations, objectPath, []);
          localLocationToDelete.splice(indexToDelete, 1);
        } else {
          validLocations.splice(indexToDelete, 1);
        }
        return validLocations;
      });
    }
  };

  const handleSaveLocation = async (
    location: any,
    locationType: any,
    objectPath: string,
  ) => {
    const objectPathDeconstruct = objectPath.split('.');
    const parentObjectPath = objectPathDeconstruct.slice(0, -2).join('.');
    const parentLocation = get(currentValidLocationTypes, parentObjectPath, []);

    try {
      const locationTypeId = get(location, 'id');
      if (!isEmpty(locationTypeId)) {
        await VenueService.updateLocationType(
          venueId,
          locationTypeId,
          locationType,
        );
      } else {
        if (!isEmpty(parentLocation)) {
          locationType.parentTypeId = get(parentLocation, 'id');
          await VenueService.createLocationType(venueId, locationType);
        } else {
          await VenueService.createLocationType(venueId, locationType);
        }
      }
      return await onLocationUpdate('');
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
    }
  };

  const validateLocation = (locationName: string, objectPath: string) => {
    const objectPathDeconstruct = objectPath.split('.');
    const currentIndex = objectPathDeconstruct.pop();
    const parentObjectPath = objectPathDeconstruct.join('.');
    const currentLocations = !objectPathDeconstruct.length
      ? currentValidLocationTypes
      : get(currentValidLocationTypes, parentObjectPath, []);

    return !currentLocations.some(
      (location: any, index: number) =>
        index !== Number(currentIndex) &&
        get(location, 'name', '') === locationName,
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={true}
      onClose={close}
      aria-labelledby="locations-dialog-title"
    >
      <DialogTitle
        classes={{
          root: 'dialog-root',
        }}
        id="locations-dialog-title"
      >
        <div className="valid-locations__dialog-header" onClick={close}>
          <span className="section-title">
            {isEditingLocation ? 'Update' : 'Add'} Valid Locations
          </span>
          <Icon>clear</Icon>
        </div>
      </DialogTitle>
      <DialogContent
        classes={{
          root: 'dialog-root',
        }}
      >
        <Grid container={true} className="valid-locations__form-header">
          <Grid item={true} xs={4}>
            <Typography variant="h5">Location</Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Typography variant="h5">Type</Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Grid container={true} direction="row" justify="space-between">
              <Typography variant="h5">Value</Typography>
              <Button
                className="valid-locations__form-header__add-location"
                color="primary"
                variant="contained"
                onClick={() =>
                  handleAddLocation('', '', LocationTypeType.REGULAR, [], 0)
                }
              >
                <Icon>add</Icon>
                LOCATION
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {currentValidLocationTypes.length > 0 ? (
          currentValidLocationTypes.map((location, index) => (
            <List
              className="valid-locations__hierarchy"
              key={`${location.id}__${index}`}
            >
              <ValidLocationHierarchy
                key={`${get(location, 'name', index)}__validLocations`}
                handleAddLocation={handleAddLocation}
                handleDeleteLocation={handleDeleteLocation}
                handleSaveLocation={handleSaveLocation}
                isEdit={get(location, 'isEdit', false)}
                level={1}
                location={location}
                objectPath={`${index}`}
                setValidLocationTypes={setCurrentValidLocationTypes}
                validateLocation={validateLocation}
              />
            </List>
          ))
        ) : (
          <List className="valid-locations__no-entries">
            <InputLabel className="valid-locations__no-entries-label">
              {' '}
              Please add locations
            </InputLabel>
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ValidLocationsDialog;
