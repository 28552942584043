import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import { useAppContext } from '../../../config/AppContext';
import { usePagination } from '../../../hooks/pagination.hook';
import { IGlobalProduct } from '../../../models/Product.model';
import GlobalProductService from '../../../services/GlobalProducts/GlobalProduct.service';
import './SelectGlobalProduct.scss';

const SelectGlobalProduct = (props: any) => {
  const [globalProducts, setGlobalProducts] = useState<IGlobalProduct[]>([]);
  const [searchGlobalProduct, setSearchGlobalProduct] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const { onClose, onChangeProductImage } = props;
  const { setShowSpinner } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    setTotalCount,
  } = usePagination(20);

  useEffect(() => {
    fetchGlobalProducts(rowsPerPage, page + 1);
  }, [page, rowsPerPage, searchGlobalProduct]);

  const fetchGlobalProducts = async (limit: number, pageNumber: number) => {
    setShowSpinner(true);
    try {
      const { data } = await GlobalProductService.getAllGlobalProducts(
        limit,
        pageNumber,
        searchGlobalProduct,
      );

      setGlobalProducts(data.items);
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const handleSearchProduct = async (event: any) => {
    setShowSpinner(true);
    try {
      const globalProduct = get(event, ['target', 'value']);
      setPage(0);
      setSearchGlobalProduct(globalProduct);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
    setShowSpinner(false);
  };

  return (
    <Dialog
      classes={{ paper: 'menu-product__dialog' }}
      fullWidth={true}
      maxWidth="lg"
      open={true}
      onClose={onClose}
    >
      <DialogTitle>
        <span className="section-title">Global Product List</span>
      </DialogTitle>
      <div className="search--bar">
        <label className="search--bar-label">Product Name: </label>
        <TextField
          id="outlined-bare"
          onChange={handleSearchProduct}
          placeholder="Search..."
          margin="dense"
          variant="outlined"
          InputProps={{
            classes: {
              input: 'search--bar-input',
              notchedOutline: 'search--bar-outline',
            },
          }}
        />
      </div>
      <div className="pagination">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
      </div>
      <div className="menu-product__dialog-grid__margin">
        <Grid className="grid-container" container={true}>
          {globalProducts.map((product: any) => (
            <Grid
              item={true}
              key={product.id}
              className={`grid-item
                ${get(selectedProduct, 'id') === product.id && 'selected'}`}
              onClick={() => setSelectedProduct(product)}
              xs={2}
            >
              <Grid
                container={true}
                alignItems="center"
                justify="center"
                direction="column"
              >
                <img
                  alt={product.name}
                  className="menu-product__image"
                  src={product.imageUrl}
                />
                <h4>{product.name}</h4>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="button-holder-similar-margin">
        <DialogActions className="menu-product-dialog__control">
          <Button color="primary" onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => onChangeProductImage(selectedProduct)}
            variant="contained"
          >
            Choose Product
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default SelectGlobalProduct;
