import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { get } from 'lodash';
import React, { Fragment } from 'react';
import './Categories.scss';
import {
  categoryDialogFieldNames,
  ICategoryFieldNames,
  VALID_CATEGORY_TYPES,
} from './Category.config';

const CategoryDialog = (props: any) => {
  const {
    category,
    error,
    isAddingNewCategory,
    onClose,
    onAddOrEditCategory,
    onDetailsChange,
  } = props;

  const dialogTitle = category.id ? 'Edit Category' : 'Add Category';
  const primaryButtonText = category.id ? 'Update' : 'Add';

  return (
    <Dialog
      classes={{ paper: 'category-dialog' }}
      fullWidth={true}
      open={true}
      onClose={onClose}
    >
      <DialogTitle className="category-dialog__title">
        <span className="section-title">{dialogTitle}</span>
      </DialogTitle>
      <DialogContent>
        {categoryDialogFieldNames.map(
          (field: ICategoryFieldNames, i: number) => (
            <Fragment key={`add-category-${field.name}`}>
              <TextField
                key={`add-category-${field.name}`}
                disabled={
                  field.propertyName === 'priority' && isAddingNewCategory
                }
                autoFocus={i === 0}
                className="add-category__text-field"
                error={get(error, field.propertyName, '').length > 0}
                label={field.name}
                name={field.propertyName}
                onChange={onDetailsChange}
                placeholder={field.placeholder || ''}
                required={field.required || false}
                type={field.type}
                value={get(category, field.propertyName) || ''}
              />
              <span
                className={`add-category__error ${get(
                  error,
                  field.propertyName,
                  '',
                ).length > 0 && `active`}`}
              >
                {get(error, field.propertyName, '').length > 0 &&
                  `${get(error, field.propertyName)}`}
              </span>
              <div className="pad-bottom" />
            </Fragment>
          ),
        )}
        <TextField
          className="add-category__text-field"
          label="Category Type"
          name="type"
          select={true}
          value={get(category, 'type')}
          onChange={onDetailsChange}
        >
          {Object.entries(VALID_CATEGORY_TYPES).map((type: any, i: number) => (
            <MenuItem
              key={`category-add-edit__select-value__${i}`}
              value={type[0]}
            >
              {type[1]}
            </MenuItem>
          ))}
        </TextField>
        <div className="pad-bottom" />
      </DialogContent>
      <div className="button-holder-similar-margin">
        <DialogActions className="category-dialog__control">
          <Button color="primary" onClick={onClose} variant="outlined">
            <div className="icon-title-wrapper">
              <UndoOutlinedIcon />
              <span className="icon-title">Cancel</span>
            </div>
          </Button>
          <Button
            color="primary"
            disabled={!!error.name || !!error.priority}
            onClick={onAddOrEditCategory}
            variant="contained"
          >
            <div className="icon-title-wrapper">
              <CheckOutlinedIcon />
              <span className="icon-title">{primaryButtonText}</span>
            </div>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CategoryDialog;
