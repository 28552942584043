import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { IUser, UserRole } from '../models/User.model';
import {
  isAdmin,
  isStoreManager,
  isSuperAdmin,
  isVenueManager,
} from '../services/Auth.service';
import { StoresContainer } from './Concessions/Stores/Stores';
import Dashboard from './Dashboard/Dashboard';
import EditVenue from './EditVenue/EditVenue';
import { Events } from './Events/Events';
import GlobalProducts from './GlobalProducts/GlobalProducts';
import Marketing from './Marketing/Marketing';
import Orders from './Orders/Orders';
import Printers from './Printers';
import Reporting from './Reporting/Reporting';
import Inventory from './Inventory/Inventory';
import { ReportSection } from './Reporting/ReportingConfig';
import Settings from './Settings/Settings';
import Support from './Support/Support';
import Tags from './Tags';
import Users from './Users/Users';
import VenueList from './VenueList/VenueList';

export interface IGlobalTab {
  component: any;
  label: string;
  props: any;
  to: string;
  value: string;
}

const venuesTab: IGlobalTab = {
  component: VenueList,
  label: 'Venues',
  props: {},
  to: '/home/venues',
  value: 'venues',
};

const globalProductsTab: IGlobalTab = {
  component: GlobalProducts,
  label: 'Global Products',
  props: {},
  to: '/home/global-products',
  value: 'global-products',
};

const reportingAdminTab: IGlobalTab = {
  component: Reporting,
  label: 'Reporting',
  props: {
    section: ReportSection.Admin,
  },
  to: '/home/reporting',
  value: 'reporting',
};

const tagsTab: IGlobalTab = {
  component: Tags,
  label: 'Tags',
  props: {},
  to: '/home/tags',
  value: 'tags',
};

const printersTab: IGlobalTab = {
  component: Printers,
  label: 'Printers',
  props: {},
  to: '/home/printers',
  value: 'printers',
};

export const getGlobalTabs = (user: IUser): IGlobalTab[] => {
  switch (user.role) {
    case UserRole.SUPER_ADMIN:
      return [
        venuesTab,
        globalProductsTab,
        reportingAdminTab,
        tagsTab,
        printersTab,
      ];
    case UserRole.FANFOOD_ADMIN:
      return [venuesTab, globalProductsTab, reportingAdminTab];
    default:
      return [venuesTab];
  }
};

export interface IVenueTab {
  component: any;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  id: string;
  props: any;
  route: string;
  title: string;
}

// const dashboardTab: IVenueTab = {
//   component: Dashboard,
//   icon: HomeOutlinedIcon,
//   id: 'dashboard',
//   props: {},
//   route: '/dashboard',
//   title: 'Dashboard',
// };

const ordersTab: IVenueTab = {
  component: Orders,
  icon: ShoppingCartOutlinedIcon,
  id: 'orders',
  props: {},
  route: '/orders',
  title: 'Orders',
};

const storesTab: IVenueTab = {
  component: StoresContainer,
  icon: DashboardOutlinedIcon,
  id: 'stores',
  props: {},
  route: '/stores',
  title: 'Stores',
};
const InventoryTab: any = {
  component: Inventory,
  icon: DashboardOutlinedIcon,
  id: 'inventory',
  props: {},
  route: '/inventory',
  title: 'Inventory',
};
const eventsTab: IVenueTab = {
  component: Events,
  icon: DateRangeOutlinedIcon,
  id: 'events',
  props: {},
  route: '/events',
  title: 'Events',
};

const marketingTab: IVenueTab = {
  component: Marketing,
  icon: RecordVoiceOverOutlinedIcon,
  id: 'marketing',
  props: {},
  route: '/marketing',
  title: 'Marketing',
};

const usersTab: IVenueTab = {
  component: Users,
  icon: PersonOutlineOutlinedIcon,
  id: 'users',
  props: {},
  route: '/users',
  title: 'Users',
};

const reportingTab: IVenueTab = {
  component: Reporting,
  icon: BusinessCenterOutlinedIcon,
  id: 'reporting',
  props: {
    section: ReportSection.Venue,
  },
  route: '/reporting',
  title: 'Reporting',
};

const settingsTab: IVenueTab = {
  component: Settings,
  icon: SettingsIcon,
  id: 'settings',
  props: {},
  route: '/settings',
  title: 'Settings',
};

const supportTab: IVenueTab = {
  component: Support,
  icon: QuestionAnswerOutlinedIcon,
  id: 'support',
  props: {},
  route: '/support',
  title: 'Support',
};

const editVenueTab: IVenueTab = {
  component: EditVenue,
  icon: QuestionAnswerOutlinedIcon,
  id: 'edit',
  props: {},
  route: '/edit',
  title: 'Edit Venue',
};

const adminVenueTabs = [
  // dashboardTab,
  ordersTab,
  storesTab,
  eventsTab,
  marketingTab,
  usersTab,
  reportingTab,
  settingsTab,
  supportTab,
  InventoryTab,
];

export const getVenueTabs = (venueId: string, user?: IUser) => {
  if (!user) {
    return [];
  }

  if (isSuperAdmin(user)) {
    return [...adminVenueTabs, editVenueTab];
  }

  if (isAdmin(user) || isVenueManager(user, venueId)) {
    return adminVenueTabs;
  }

  if (isStoreManager(user, venueId)) {
    return [
      //dashboardTab,
      ordersTab,
      storesTab,
      reportingTab,
      settingsTab,
      supportTab,
      InventoryTab,
    ];
  }

  return [ordersTab, storesTab, settingsTab, supportTab, InventoryTab];
};

export const getDefaultVenueTabId = (venueId: string, user?: IUser) => {
  if (!user) {
    return 'orders';
  }

  if (
    isAdmin(user) ||
    isVenueManager(user, venueId) ||
    isStoreManager(user, venueId)
  ) {
    return 'orders';
  }

  return 'orders';
};

export const venuePath = (venueId: string, path: string) =>
  `/home/venue-details/${venueId}/${path}`;
