import { IOrder } from '../../models/Order.model';
import axiosWithAuth from '../ServiceConfig';
const basePath = '/opentabs';

export interface IOpenTabDto {
  name: null;
  tabBalance: number;
  userId?: string;
  suiteId?: string;
  eventId?: string;
  alcoholAllowed: boolean;
  isActive: boolean;
}
export interface IOpenTabUpdateDto {
  name?: null;
  tabBalance?: number | undefined;
  userId?: string;
  suiteId?: string;
  eventId?: string;
  alcoholAllowed?: boolean;
  isActive?: boolean;
}
export interface IInviteDto {
  phoneNumber: string;
  countryCode: string;
  name: string;
  tabOwner?: string;
  message: string;
  link: string;
}

export interface IOrderReceiptDto {
  isActive: boolean;
  order: IOrder;
}

const GetTab = async (suiteId: string, eventId: string) => {
  return await axiosWithAuth.get(`${basePath}/${suiteId}/${eventId}`);
};
const SaveTab = async (data: IOpenTabDto) => {
  return await axiosWithAuth.post(`${basePath}/save`, data);
};
const UpdateTab = async (data: IOpenTabUpdateDto, openTabId: string) => {
  return await axiosWithAuth.post(`${basePath}/update/${openTabId}`, data);
};

const OpenTabReceipt = async (data: IOrderReceiptDto, openTabId: string) => {
  return await axiosWithAuth.post(
    `${basePath}/updateWithPayment/${openTabId}`,
    data,
  );
};

export default {
  GetTab,
  OpenTabReceipt,
  SaveTab,
  UpdateTab,
};
