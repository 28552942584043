import { IUpdateLineItemGroup } from './../routes/Orders/Orders.config';
import axiosWithAuth from './ServiceConfig';

const baseUrl = `/lineItemGroups`;

const update = (id: string, body: IUpdateLineItemGroup) =>
  axiosWithAuth.put(`${baseUrl}/${id}`, body);

export default {
  update,
};
