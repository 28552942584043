import { Button } from '@material-ui/core';
import React from 'react';

export interface IHeaderButtonProps {
  href?: string;
  icon: any;
  onClick?: () => void | Promise<void>;
  target?: string;
  text: string;
  type: HeaderButtonType;
}

export enum HeaderButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export const HeaderButton = ({
  icon: Icon,
  onClick,
  text,
  type,
  ...props
}: IHeaderButtonProps) => {
  return (
    <Button
      size="large"
      color={type}
      aria-label={type}
      className={`header-button ${type}-button`}
      onClick={onClick}
      {...props}
    >
      <div className="icon-title-wrapper">
        <Icon />
        <span className="icon-title">{text}</span>
      </div>
    </Button>
  );
};
