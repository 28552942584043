import { WithSnackbarProps } from 'notistack';
import { IVenueLocation } from '../../../models/Location.model';
import { Partner } from '../../../models/Partner.model';
import { IStore, ValidStoreType } from '../../../models/Store.model';
declare const ENVARS: any;

export interface ISelectedStore {
  id: string;
  name: string;
  type: ValidStoreType;
}

export interface IStoreFieldNames {
  name: string;
  placeholder: string;
  propertyName: string;
  required?: boolean;
}

export const isFulfullmentCenter = (store: IStore) =>
  store.type === ValidStoreType.FULFILLMENT_CENTER;

const storeTableColumnNames = [
  { name: 'Name', size: 'medium' },
  { name: 'Location', size: 'medium' },
  { name: 'Quick Settings', size: 'small' },
  { name: 'Order Times', size: 'small' },
  { name: 'Order Methods', size: 'small' },
  { name: 'Actions', size: 'small' },
];

const storeInitialState: IStore = {
  acceptsOpenTabOrders: false,
  active: false,
  allowOpenTabs: false,
  averageDeliveryTimeSeconds: 10 * 60,
  averagePrepTimeSeconds: 10 * 60,
  bypassOrderFulfillment: false,
  cartLevelNotesAvailable: true,
  deliveryAvailable: false,
  description: '',
  id: '',
  image_file: '',
  isDeleted: false,
  location: '',
  maxOrdersPerFifteenMinutes: 0,
  name: '',
  orderAheadAvailable: false,
  orderInstructionsAvailable: true,
  orderMinimum: 0,
  orderNowAvailable: true,
  partner: Partner.FANFOOD,
  partnerStoreId: '',
  pickupAvailable: false,
  printerIds: [],
  priority: 1,
  productLevelNotesAvailable: false,
  showProductImages: true,
  type: ValidStoreType.REGULAR,
  venueId: '',
  visible: true,
  customDiscount: false,
  scheduleHour: [],
};

const printerInitialState = {
  copyCount: 1,
  name: '',
  status: '',
};

const storeDialogFieldNames = [
  {
    name: 'Name',
    placeholder: 'Enter Name',
    propertyName: 'name',
    required: true,
  },
  {
    name: 'Location',
    placeholder: 'Enter Location',
    propertyName: 'location',
  },
  {
    name: 'Description',
    placeholder: 'Short description to describe your products',
    propertyName: 'description',
  },
];

const tooltipInfo = {
  active:
    'Store cannot be set Active as the option is disabled at the Location level',
  orderAheadAvailable:
    'Order Ahead option cannot be enabled as this option is disabled at the Location level',
  orderNowAvailable:
    'Order Now option cannot be enabled as this option is disabled at the Location level',
};

const getPrinterServerURL = (printerId: string) => {
  return `${ENVARS.API.BASE_URL}/api/v1/printers/${printerId}/getInvoice`;
};

export interface IStoresProps extends WithSnackbarProps {
  venueId: string;
}

export interface IStoresState {
  activeAction: string;
  cloneStoreName: string;
  currentStoreId: string;
  error: string;
  loading: boolean;
  numberCompleted: number;
  openBackdrop: boolean;
  page: number;
  printerDetails: any;
  printQueueCleared: boolean;
  rowsPerPage: number;
  storeDetails: IStore;
  stores: IStore[];
  totalCount: number;
  viewEtaEditDialog: boolean;
  viewDeleteStoreDialog: boolean;
  viewPrinterDialog: boolean;
  viewStoreDialog: boolean;
  venueLocations: IVenueLocation[];
  venueSuites: IVenueLocation[];
}

export {
  storeTableColumnNames,
  printerInitialState,
  storeInitialState,
  storeDialogFieldNames,
  tooltipInfo,
  getPrinterServerURL,
};
