import React from 'react';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';

interface IBuildingUserDetailProps {
  data: IBuildingUserDetailDataRecord[];
  isAdmin: boolean;
}

export interface IBuildingUserDetailDataRecord extends IHasCurrency {
  activeSince: string;
  aov: number;
  buildingName: string;
  email: string;
  firstName: string;
  lastName: string;
  lastOrderDate: string;
  phoneNumber: string;
  totalEvents: number;
  totalOrders: number;
}

const exportName = 'building-user-detail';
const columns: Array<IReportColumn<IBuildingUserDetailDataRecord>> = [
  {
    accessor: record => record.firstName,
    name: 'First Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.lastName,
    name: 'Last Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.email,
    name: 'Email',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.phoneNumber,
    name: 'Phone Number',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.buildingName,
    name: 'Building Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.activeSince,
    name: 'Active Since',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.lastOrderDate,
    name: 'Last Order Date',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.totalOrders,
    name: 'Total Orders',
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.totalEvents,
    name: 'Total Events',
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.aov,
    name: 'AOV',
    type: ReportColumnType.currency,
  },
];

const BuildingUserDetail = (props: IBuildingUserDetailProps): JSX.Element => (
  <BasicReport {...{ columns, exportName }} {...props} />
);

export default BuildingUserDetail;
