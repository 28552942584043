import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { IPrinterWithStore } from '../../models/Printer.model';
import PrinterService from '../../services/Printer/Printer.service';
import './index.scss';

const Printers = (): JSX.Element => {
  const [printers, setPrinters] = React.useState<IPrinterWithStore[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const loadPrinters = async () => {
    try {
      const { data } = await PrinterService.getAll();

      setPrinters(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      await loadPrinters();
    })();
  }, []);

  return (
    <div className="printers">
      <Typography component="h1" variant="h5" className="section-title">
        Printers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Venue</TableCell>
            <TableCell align="center">Store</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Copy Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printers.map((printer: IPrinterWithStore) => (
            <TableRow key={printer.id}>
              <TableCell align="center">{printer.id}</TableCell>
              <TableCell align="center">{printer.name}</TableCell>
              <TableCell align="center">
                {printer.store &&
                  printer.store.venue &&
                  printer.store.venue.name}
              </TableCell>
              <TableCell align="center">
                {printer.store && printer.store.name}
              </TableCell>
              <TableCell align="center">{printer.status || 'N/A'}</TableCell>
              <TableCell align="center">{printer.copyCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Printers;
