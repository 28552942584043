import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Switch,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useAppContext } from '../../../config/AppContext';
import { ValidStoreType } from '../../../models/Store.model';
import { ICreateStoreDto } from '../../../services/Store/Store.service';
import './StoreDialog.scss';

interface IStoreDialogProps {
  addStore: (store: ICreateStoreDto) => Promise<void>;
  onClose: () => void;
}

export const AddStoreDialog = ({ addStore, onClose }: IStoreDialogProps) => {
  const { venue } = useAppContext();

  const onSaveStore = async (
    values: ICreateStoreDto,
    formikActions: FormikActions<ICreateStoreDto>,
  ) => {
    const { setSubmitting } = formikActions;
    await addStore(values);
    setSubmitting(false);
  };

  if (!venue) {
    return null;
  }

  return (
    <Dialog
      classes={{ paper: 'store-dialog' }}
      fullWidth={true}
      open={true}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="section-title">Add Store</span>
      </DialogTitle>
      <Formik<ICreateStoreDto>
        initialValues={{
          description: '',
          location: '',
          name: '',
          priority: 1,
          type: ValidStoreType.REGULAR,
          venueId: venue.id,
          customDiscount: false,
        }}
        onSubmit={onSaveStore}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is Required'),
          priority: Yup.number()
            .integer()
            .required('Display Order is required'),
          type: Yup.string().required('Store Type is required'),
        })}
        render={(formProps: FormikProps<ICreateStoreDto>) => {
          const {
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            submitForm,
            touched,
            values,
          } = formProps;

          const renderSetting = (
            control: JSX.Element,
            field: keyof ICreateStoreDto,
          ) => (
            <>
              <div className="store-dialog__content__setting">{control}</div>
              <ErrorMessage className="error" component="div" name={field} />
            </>
          );

          // ignoring this due to prettier being unaware of valid <T,> syntax
          // prettier-ignore
          const renderEnumSelectSetting = <TEnum,>(
            field: keyof ICreateStoreDto,
            label: string,
            enumType: TEnum,
            required: boolean,
          ) => {
            const value = values[field];

            return renderSetting((
              <TextField
                error={touched[field] && !!errors[field]}
                label={label}
                name={field}
                onBlur={handleBlur}
                onChange={handleChange}
                required={required}
                select={true}
                value={value || ''}
              >
                <MenuItem value={undefined}>&nbsp;</MenuItem>
                {Object.entries(enumType).map(
                  (type: any, index: number) => (
                    <MenuItem value={type[0]} key={index}>
                      {type[1]}
                    </MenuItem>
                  ),
                )}
              </TextField>
            ),
              field,
            );
          };

          const renderNumericSetting = (
            field: keyof ICreateStoreDto,
            label: string,
          ) => {
            const value = values[field];

            return renderSetting(
              <TextField
                autoComplete="off"
                error={touched[field] && !!errors[field]}
                inputProps={{
                  min: '0.00',
                  step: '0.01',
                  type: 'number',
                }}
                label={label}
                name={field}
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={typeof value !== 'undefined' ? value : ''}
              />,
              field,
            );
          };

          const renderTextSetting = (
            field: keyof ICreateStoreDto,
            label: string,
            placeholder: string,
            required: boolean,
          ) => {
            const value = values[field];

            return renderSetting(
              <TextField
                error={touched[field] && !!errors[field]}
                label={label}
                name={field}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
                required={required}
                type="text"
                value={value}
              />,
              field,
            );
          };
          const renderSwitchSetting = (field: keyof ICreateStoreDto , label: string) => {
            const value = values[field];

            return renderSetting(
              <div>
              <span>{label}</span>
              <Switch
                classes={{
                  track: 'custom-colored-switch-track',
                }}
                checked={typeof value === 'boolean' && value}
                color="primary"
                name="customDiscount"
                onChange={handleChange}
                size="medium"
                value={value}
              />
              </div>,
              field,
            );
          };

          return (
            <>
              <DialogContent className="store-dialog__content">
                {renderTextSetting('name', 'Name', 'Enter Name', true)}
                {renderTextSetting(
                  'location',
                  'Location',
                  'Enter Location',
                  false,
                )}
                {renderTextSetting(
                  'description',
                  'Description',
                  'Short description to describe your products',
                  false,
                )}
                {renderSwitchSetting(
                  'customDiscount',
                  'Custom Discount'
                )}

                {renderNumericSetting('priority', 'Display Order')}
                {renderEnumSelectSetting(
                  'type',
                  'Store Type',
                  ValidStoreType,
                  true,
                )}
              </DialogContent>
              <div className="button-holder-similar-margin">
                <DialogActions>
                  <Button color="primary" onClick={onClose} variant="outlined">
                    <div className="icon-title-wrapper">
                      <UndoOutlinedIcon />
                      <span className="icon-title">Cancel</span>
                    </div>
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    variant="contained"
                  >
                    <div className="icon-title-wrapper">
                      <CheckOutlinedIcon />
                      <span className="icon-title">Add</span>
                    </div>
                  </Button>
                </DialogActions>
              </div>
            </>
          );
        }}
      />
    </Dialog>
  );
};
