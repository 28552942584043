import { TablePagination } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import './CustomPagination.scss';

interface ICustomPaginationProps {
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  setPage: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  totalCount: number;
}

const CustomPagination = ({
  page,
  rowsPerPage,
  rowsPerPageOptions = [20, 40, 60],
  setPage,
  setRowsPerPage,
  totalCount,
}: ICustomPaginationProps) => {
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      classes={{
        caption: 'pagination-caption',
        root: 'pagination-control',
        select: 'pagination-select',
        toolbar: 'pagination-control-buttons',
      }}
      colSpan={3}
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': 'Rows per page' },
        native: true,
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default CustomPagination;
