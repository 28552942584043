import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useAppContext } from '../../../config/AppContext';
import { ISearchCriteria } from '../Orders';
import './OrdersSearchFields.scss';

interface ISearchFieldsProps {
  isLoading: boolean;
  searchCriteria: ISearchCriteria;
  setSearchCriteria: React.Dispatch<React.SetStateAction<ISearchCriteria>>;
  triggerRefresh: () => void;
  currentTab?: string;
}

const SearchFields = (props: ISearchFieldsProps) => {
  const {
    currentTab,
    isLoading,
    searchCriteria,
    setSearchCriteria,
    triggerRefresh,
  } = props;

  const { events, fetchEvents } = useAppContext();

  useEffect(() => {
    if (currentTab === 'pre-orders') {
      fetchEvents(true);
    }
  }, [currentTab]);

  const {
    firstName,
    lastName,
    orderNo,
    startTime,
    eventDay,
    endTime,
  } = searchCriteria;

  return (
    <Grid className="orders-search-fields" container={true}>
      <Grid item={true} xs={10}>
        <Grid container={true} justify="flex-start">
          <TextField
            className="search-fields"
            label="Order No"
            margin="dense"
            variant="outlined"
            value={orderNo}
            onChange={(e: any) => {
              setSearchCriteria({
                ...searchCriteria,
                orderNo: e.target.value || '',
              });
            }}
          />
          <TextField
            className="search-fields"
            label="First Name"
            margin="dense"
            variant="outlined"
            value={firstName}
            onChange={(e: any) => {
              setSearchCriteria({
                ...searchCriteria,
                firstName: e.target.value || '',
              });
            }}
          />
          <TextField
            className="search-fields"
            label="Last Name"
            margin="dense"
            variant="outlined"
            value={lastName}
            onChange={(e: any) => {
              setSearchCriteria({
                ...searchCriteria,
                lastName: e.target.value || '',
              });
            }}
          />
          {currentTab !== 'pre-orders' && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                clearable={true}
                label="Order Time From"
                value={startTime}
                onChange={(e: any) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    startTime: e,
                  });
                }}
                maxDate={endTime}
                maxDateMessage="Start Date cannot be after End Date"
                format="yyyy/MM/dd hh:mm a"
                className="time-fields"
              />
              <DateTimePicker
                clearable={true}
                label="Order Time To"
                value={endTime}
                onChange={(e: any) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    endTime: e,
                  });
                }}
                format="yyyy/MM/dd hh:mm a"
                className="time-fields"
              />
            </MuiPickersUtilsProvider>
          )}
          {currentTab === 'pre-orders' && (
            <Grid>
              {!eventDay && (
                <InputLabel className="select-fields-label">
                  Event Day
                </InputLabel>
              )}
              <Select
                required={true}
                variant="outlined"
                className="select-fields"
                value={eventDay?.toString()}
                onChange={(e: any) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    eventDay: e.target.value.startDate,
                    eventName: e.target.value.name,
                  });
                }}
              >
                <MenuItem disabled={true} value={'Event Day'}>
                  Event Day
                </MenuItem>
                {events.map((e: any) => (
                  <MenuItem key={e.id} value={e}>
                    {`${e.name} - ${moment(e.startDate).format('L')}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item={true} xs={2}>
        <Button
          className="search-orders-button"
          disabled={isLoading}
          onClick={triggerRefresh}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchFields;
