import { Button, TablePagination } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { get } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import DeleteDialog from '../../../components/DeleteDialog';
import { AppContext } from '../../../config/AppContext';
import NotificationService from '../../../services/Notification/Notification.service';
import { defaultNotificationState, INotification } from './Notificationconfig';
import NotificationDialog from './NotificationDialog/NotificationDialog';
import './Notifications.scss';
import NotificationTable from './NotificationTable/NotificationTable';

interface INotificationProps {
  venueId: string;
  enqueueSnackbar: any;
  closeSnackbar: any;
}

interface INotificationState {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  notifications: INotification[];
  showNotificationDialog: boolean;
  showDeleteDialog: boolean;
  notification: any;
}

class Notifications extends React.Component<
  INotificationProps,
  INotificationState
> {
  constructor(props: INotificationProps) {
    super(props);
    this.state = {
      notification: defaultNotificationState,
      notifications: [],
      page: 0,
      rowsPerPage: 20,
      showDeleteDialog: false,
      showNotificationDialog: false,
      totalCount: 0,
    };
  }

  public componentDidMount() {
    const { rowsPerPage, page } = this.state;
    this.fetchNotifications(rowsPerPage, page + 1);
  }

  public fetchNotifications = async (
    limit: number,
    pageNumber: number,
    name: string = '',
    title: string = '',
    content: string = '',
  ) => {
    const { setShowSpinner } = this.context;
    setShowSpinner(true);
    const { enqueueSnackbar } = this.props;
    try {
      const { venueId } = this.props;
      const { data } = await NotificationService.getAllNotifications(
        venueId,
        limit,
        name,
        pageNumber,
        title,
        content,
      );
      this.setState({
        notifications: get(data, ['items'], []),
        page: pageNumber - 1,
        totalCount: get(data, ['totalItems'], 0),
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage }, () => {
      const { rowsPerPage, page } = this.state;
      this.fetchNotifications(rowsPerPage, page + 1);
    });
  };

  public onNotificationChangeActive = async (
    event: any,
    selectedNotification: INotification,
  ) => {
    const { rowsPerPage, page } = this.state;
    await NotificationService.updateNotification(
      get(selectedNotification, 'id'),
      {
        ...selectedNotification,
        active: get(event, ['target', 'checked']),
      },
    );
    this.fetchNotifications(rowsPerPage, page + 1);
  };

  public handleChangeRowsPerPage = (event: any) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(get(event, ['target', 'value'], 20), 10),
      },
      () => {
        const { rowsPerPage } = this.state;
        this.fetchNotifications(rowsPerPage, 1);
      },
    );
  };

  public handleSubmitNotification = async (notificationDetails: any) => {
    const { venueId, enqueueSnackbar } = this.props;
    const { setShowSpinner } = this.context;
    const { scheduleDelivery, scheduleTime } = notificationDetails;
    setShowSpinner(true);
    try {
      if (!scheduleDelivery && scheduleTime) {
        notificationDetails.scheduleTime = null;
      }
      if (notificationDetails.id) {
        await NotificationService.updateNotification(
          notificationDetails.id,
          notificationDetails,
        );
        enqueueSnackbar('Notification has been successfully updated', {
          variant: 'success',
        });
      } else {
        await NotificationService.createNotification({
          ...notificationDetails,
          venueId,
        });
        enqueueSnackbar('Notification has been successfully created', {
          variant: 'success',
        });
      }
      this.setState({ showNotificationDialog: false, page: 0 }, () => {
        const { rowsPerPage, page } = this.state;
        this.fetchNotifications(rowsPerPage, page + 1);
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public deleteNotification = async () => {
    const { notification, rowsPerPage } = this.state;
    const { setShowSpinner } = this.context;
    const { enqueueSnackbar } = this.props;
    setShowSpinner(true);
    try {
      if (get(notification, 'id')) {
        await NotificationService.deleteNotification(notification.id);
        this.setState({ showDeleteDialog: false, page: 0 });
        this.fetchNotifications(rowsPerPage, 1);
        enqueueSnackbar('Notification successfully deleted', {
          variant: 'success',
        });
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public handleEditNotification = (selectedNotification: INotification) => {
    const { scheduleTime } = selectedNotification;
    if (scheduleTime === null) {
      selectedNotification.scheduleTime = new Date();
    }
    this.setState({
      notification: selectedNotification,
      showNotificationDialog: true,
    });
  };

  public handleDeleteNotification = (selectedNotification: INotification) => {
    this.setState({
      notification: selectedNotification,
      showDeleteDialog: true,
    });
  };

  public handleSearchNotification = (
    name: string,
    title: string,
    content: string,
  ) => {
    this.setState({ page: 0 }, () => {
      const { rowsPerPage } = this.state;
      this.fetchNotifications(rowsPerPage, 1, name, title, content);
    });
  };

  public sendNotificationNow = async (notificationId: string) => {
    try {
      const { rowsPerPage } = this.state;
      await NotificationService.sendNotificationNow(notificationId);
      this.fetchNotifications(rowsPerPage, 1);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
  };

  public render() {
    const {
      page,
      rowsPerPage,
      notifications,
      showDeleteDialog,
      totalCount,
      showNotificationDialog,
      notification,
    } = this.state;
    const timezone = get(this.context, 'venue.timezone', '');
    return (
      <Fragment>
        <h2 className="notifications__title section-title">Notifications</h2>
        <div className="notifications-pagination-toolbar">
          <TablePagination
            component="div"
            rowsPerPageOptions={[20, 40, 60]}
            classes={{
              caption: 'pagination-caption',
              root: 'pagination-control',
              select: 'pagination-select',
              toolbar: 'pagination-control-buttons',
            }}
            colSpan={3}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'Items per page' },
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Button
            size="large"
            color="primary"
            classes={{ root: 'add-notification-button' }}
            onClick={() => {
              this.setState({
                notification: defaultNotificationState,
                showNotificationDialog: true,
              });
            }}
          >
            <div className="icon-title-wrapper">
              <AddOutlinedIcon />
              <span className="icon-title">Add Notification</span>
            </div>
          </Button>
        </div>
        {showNotificationDialog && (
          <NotificationDialog
            handleSubmitNotification={this.handleSubmitNotification}
            close={() => {
              this.setState({ showNotificationDialog: false });
            }}
            notification={notification}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            dialogTitle="Delete Notification"
            dialogContent="Are you sure you want to remove this Notification?"
            onClose={() => this.setState({ showDeleteDialog: false })}
            onSubmit={this.deleteNotification}
          />
        )}
        <NotificationTable
          notifications={notifications}
          searchNotification={this.handleSearchNotification}
          editNotification={this.handleEditNotification}
          deleteNotification={this.handleDeleteNotification}
          onChangeActive={this.onNotificationChangeActive}
          sendNotificationNow={this.sendNotificationNow}
          venueTimezone={timezone}
        />
      </Fragment>
    );
  }
}

Notifications.contextType = AppContext;
export default withSnackbar(Notifications);
