import * as Yup from 'yup';

export interface INotification {
  id: string;
  name: string;
  title: string;
  content: string;
  active: boolean;
  failCount: number;
  scheduleDelivery: boolean;
  scheduleTime: Date;
  sentTime: Date;
  successCount: number;
}

export interface ICreateNotification {
  venueId: string;
  name: string;
  title: string;
  content: string;
  scheduleDelivery: boolean;
  scheduleTime: Date;
  active?: boolean;
}

export interface INotificationConfig {
  fieldName: string;
  label: string;
  placeholder: string;
}

export interface ISearchConfig {
  index: number;
  value: string;
}

export const defaultNotificationState: ICreateNotification = {
  active: true,
  content: '',
  name: '',
  scheduleDelivery: false,
  scheduleTime: new Date(),
  title: '',
  venueId: '',
};

export const notificationConfig: INotificationConfig[] = [
  {
    fieldName: 'name',
    label: 'Name',
    placeholder: 'Enter Notification Name',
  },
  {
    fieldName: 'title',
    label: 'Title',
    placeholder: 'Enter Notification Title',
  },
  {
    fieldName: 'content',
    label: 'Content',
    placeholder: 'Enter Notification Content',
  },
];

export const columns = [
  { name: 'Name' },
  { name: 'Title' },
  { name: 'Content' },
  { name: 'Last Sent Time' },
  { name: 'Active' },
  { name: 'Action' },
];

export const NotificationValidationSchema = () => {
  return Yup.object().shape({
    content: Yup.string()
      .required()
      .max(173, 'Please do not enter over 173 characters'),
    name: Yup.string().required(),
    scheduleDelivery: Yup.string(),
    scheduleTime: Yup.date(),
    title: Yup.string()
      .required()
      .max(43, 'Please do not enter over 43 characters'),
  });
};
