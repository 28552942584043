import GoogleMapReact, { Coords } from 'google-map-react';
import React, { PureComponent } from 'react';

const DefaultMarker = (props: any) => (
  <img
    alt=""
    src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png"
    draggable={true}
    style={{
      border: '0px',
      height: '43px',
      left: '0px',
      margin: '0px',
      maxWidth: 'none',
      padding: '0px',
      position: 'absolute',
      top: '0px',
      userSelect: 'none',
      width: '27px',
    }}
  />
);

interface IProps {
  center?: Coords;
  lat?: number | null;
  lng?: number | null;
  zoom?: number;
}

class SimpleMap extends PureComponent<IProps, {}> {
  public static defaultProps = {
    center: {
      lat: 41.7962928,
      lng: -88.19741479999999,
    } as Coords,
    zoom: 11,
  };

  public createMapOptions = (maps: any) => {
    return {
      mapTypeControl: true,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  public render() {
    const { center, zoom } = this.props;
    const lat = this.coalesce(
      SimpleMap.defaultProps.center.lat,
      this.props.lat,
    );
    const lng = this.coalesce(
      SimpleMap.defaultProps.center.lng,
      this.props.lng,
    );

    return (
      // Important! Always set the container height explicitly
      <div
        style={{
          height: '300px',
          marginTop: '1em',
          width: '100%',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCrVw_k1LkxbdH_ZFAymLQ8y5kmz7A1Zro' }}
          defaultCenter={center || SimpleMap.defaultProps.center}
          defaultZoom={zoom || SimpleMap.defaultProps.zoom}
          center={{ lat, lng } as Coords}
          options={this.createMapOptions}
        >
          <DefaultMarker lat={lat} lng={lng} text="A" />
        </GoogleMapReact>
      </div>
    );
  }

  private coalesce = (defaultCoord: number, coord?: number | null): number =>
    typeof coord === 'undefined' || coord === null ? defaultCoord : coord;
}

export default SimpleMap;
