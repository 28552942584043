import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  InfoRounded,
  Keyboard,
  Link as LinkIcon,
} from '@material-ui/icons';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import { useSnackbar } from 'notistack';
import React from 'react';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { useAppContext } from '../../../config/AppContext';
import { IVenueLocation } from '../../../models/Location.model';
import { getDeliveryLocationWebLink } from '../../../services/WebLinks.service';
import './VenueLocation.scss';
interface IVenueLocationTable {
  locations: IVenueLocation[];
  handleLocationSelection: (location: IVenueLocation) => void;
  onCloneLocation: (location: IVenueLocation) => void;
  onDeleteLocation: (location: IVenueLocation) => void;
  onEditLocation: (location: IVenueLocation) => void;
}

export const VenueLocationTable = ({
  locations,
  handleLocationSelection,
  onEditLocation,
  onDeleteLocation,
  onCloneLocation,
}: IVenueLocationTable) => {
  const { enqueueSnackbar } = useSnackbar();
  const { venue } = useAppContext();

  if (!venue) {
    return null;
  }

  const copyLocationLinkToClipboard = (deliveryLocationId: string) => {
    navigator.clipboard.writeText(
      getDeliveryLocationWebLink(venue.id, deliveryLocationId),
    );
    enqueueSnackbar('Venue Delivery Link Copied to Clipboard', {
      variant: 'success',
    });
  };

  return (
    <div className="locations-table-container">
      <Table>
        <TableHead>
          <TableRow
            classes={{
              root: 'table-row',
            }}
          >
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Name
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Type
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              <Tooltip
                title={`Will show up as the dropdown label for the next section in our apps. E.g. a location "Section 1" may have a Nested Label: "Row"`}
                placement="top"
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <span>Nested Label</span>
                  <InfoRounded />
                </div>
              </Tooltip>
            </TableCell>
            <TableCell classes={{ root: 'table-cell-action table-cell-head' }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations && locations.length
            ? locations.map((location: IVenueLocation) => (
                <TableRow
                  key={location.id}
                  id={location.id}
                  className="locations-table-row"
                >
                  <TableCell
                    onClick={() => handleLocationSelection(location)}
                    className="table-cell table-cell-location-name"
                  >
                    <Grid container={true} direction="row" alignItems="center">
                      {location.name}{' '}
                      {location.isDeliveryLocation && (
                        <Tooltip
                          title="This location can be selected for delivery"
                          placement="top"
                        >
                          <CheckCircleOutline color="primary" />
                        </Tooltip>
                      )}
                      {location.isDeliveryLocation && location.isCustomInput && (
                        <Tooltip
                          title="This location is a Custom Input Field"
                          placement="top"
                        >
                          <Keyboard color="secondary" />
                        </Tooltip>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell className="table-cell">{location.type}</TableCell>
                  <TableCell className="table-cell">
                    {location.categoryName}
                  </TableCell>
                  <TableCell classes={{ root: 'table-cell-action' }}>
                    <Button
                      size="small"
                      onClick={() => {
                        onEditLocation(location);
                      }}
                      classes={{
                        root: 'locations-table-button',
                      }}
                      value="edit"
                    >
                      <div className="icon-title-wrapper">
                        <EditOutlinedIcon />
                        <span className="icon-title">Edit</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      classes={{ root: 'locations-table-button' }}
                      onClick={() => onCloneLocation(location)}
                    >
                      <div className="icon-title-wrapper">
                        <FilterNoneOutlinedIcon />
                        <span className="icon-title">Clone</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      value="delete"
                      onClick={() => {
                        onDeleteLocation(location);
                      }}
                      classes={{
                        root: 'locations-table-button',
                      }}
                    >
                      <div className="icon-title-wrapper">
                        <DeleteOutlinedIcon />
                        <span className="icon-title">Delete</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      value="delete"
                      onClick={() => copyLocationLinkToClipboard(location.id)}
                      classes={{
                        root: 'locations-table-button',
                      }}
                    >
                      <div className="icon-title-wrapper">
                        <LinkIcon />
                        <span className="icon-title">Copy Link</span>
                      </div>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : noDataAvailable(4)}
        </TableBody>
      </Table>
    </div>
  );
};
