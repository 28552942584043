import { Tab, Tabs, Typography } from '@material-ui/core';
import { subMilliseconds } from 'date-fns';
import { includes, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import { useAppContext } from '../../config/AppContext';
import { IStore } from '../../models/Store.model';
import { canAccessVenue } from '../../services/Auth.service';
import OrdersService from '../../services/Orders/Orders.service';
import VenueService from '../../services/Venue/Venue.service';
import { PollingInterval } from '../Orders/Orders.config';
import { getDefaultVenueTabId, getVenueTabs, IVenueTab } from '../TabConfig';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service';
import './VenueDetails.scss';

const VenueDetails = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pollingId, setPollingId] = useState<ReturnType<typeof setInterval>>();
  const {
    logout,
    setVenue,
    setShowSpinner,
    stores,
    user,
    venue,
  } = useAppContext();

  const history = useHistory();
  const { pathname } = useLocation();
  const { venueId } = useParams<{ venueId: string }>();

  const defaultVenueTabId = getDefaultVenueTabId(venueId, user);
  const venueTabs = getVenueTabs(venueId, user);
  const currentTab = pathname.replace(`/home/venue-details/${venueId}`, '');

  // Redirect /venues/:id to /venues/:id/dashboard or /venues/:id/orders
  useEffect(() => {
    if (!venueId || (user && !canAccessVenue(user, venueId))) {
      history.push(`/home/venues`);
      return;
    }

    if (['', '/'].includes(currentTab)) {
      history.push(`/home/venue-details/${venueId}/${defaultVenueTabId}`);
    }
  }, [pathname, venueId]);

  const [activeTabId, setActiveTabId] = useState(defaultVenueTabId);
  const [showLogoutDialog, setShowLogoutDialog] = React.useState<boolean>(
    false,
  );

  const getCurrentVenue = async () => {
    setShowSpinner(true);
    try {
      const { data } = await VenueService.getVenue(venueId);
      setVenue(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
      history.push(`/home/venues`);
    }
    setShowSpinner(false);
  };

  const initializeNewOrdersDing = async () => {
    const storeIds = stores.map((store: IStore) => store.id);
    if (isEmpty(storeIds)) {
      return;
    }

    const audio = new Audio(
      'https://fanfood-public.s3.amazonaws.com/TingTing.mp3',
    );

    const intervalId = setInterval(
      () => getNewOrdersCount({ storeIds, venueId }, audio),
      PollingInterval.orderNotification,
    );
    setPollingId(intervalId);
  };

  const getNewOrdersCount = async (
    requestBody: { storeIds: string[]; venueId: string },
    audio: any,
  ) => {
    const callTime = subMilliseconds(
      new Date(),
      PollingInterval.orderNotification,
    ).toISOString();

    try {
      const {
        data: { count },
      } = await OrdersService.getNewOrdersCount({
        ...requestBody,
        startTime: callTime,
      });

      if (count) {
        audio.play();
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
  };

  useEffect(() => {
    getCurrentVenue();
  }, [venueId]);

  useEffect(() => {
    initializeNewOrdersDing();
  }, [venue, stores]);

  useEffect(() => {
    return () => pollingId && clearInterval(pollingId);
  }, [pollingId]);

  useEffect(() => {
    const activeVenueTab = venueTabs.find((tab: IVenueTab) =>
      includes(currentTab, tab.route),
    );
    if (activeVenueTab) {
      setActiveTabId(activeVenueTab.id);
    }
  }, [pathname, user, venueId]);

  const handleSwitchTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTabId(newValue);
  };

  const handleLogOut = async () => {
    const tenant_schema = await LocalStorageService.getLocalData(
      'tenant-schema',
    );
    if (tenant_schema === 'true') {
      props.history.push('/twin-dolphin');
    } else {
      props.history.push('/login');
    }
    await logout();
  };

  if (!user) {
    return null;
  }

  return (
    <div className="home-body-container">
      <div className="tabs-container">
        <Tabs
          orientation="vertical"
          value={activeTabId}
          onChange={handleSwitchTab}
          textColor="secondary"
          classes={{
            indicator: 'indicator',
          }}
          variant="scrollable"
        >
          {venueTabs.map((tab: IVenueTab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              label={
                <>
                  <div className="tab-title-wrapper">
                    <span className="tab-title">{tab.title}</span>
                  </div>
                </>
              }
              component={Link}
              to={`/home/venue-details/${venueId}${tab.route}`}
              classes={{
                root: 'tab',
                selected: 'venuedetails-selectedTab',
                textColorSecondary: 'tab-text-secondary',
              }}
            />
          ))}
          <Tab
            key={'logout'}
            value={'logout'}
            label={
              <div className="tab-title-wrapper">
                <span className="tab-title">Logout</span>
              </div>
            }
            onClick={() => setShowLogoutDialog(true)}
            classes={{
              root: 'tab',
              selected: 'venuedetails-selectedTab',
              textColorSecondary: 'tab-text-secondary',
            }}
          />
        </Tabs>
        {showLogoutDialog && (
          <ConfirmDialog
            content="Are you sure you want to log out?"
            onClose={() => setShowLogoutDialog(false)}
            onSubmit={handleLogOut}
            title="Log out?"
          />
        )}
      </div>
      <div className="window-container">
        <div className="window">
          {venueTabs.map((tab: IVenueTab) => {
            const RouteComponent = tab.component;
            return (
              <Route
                key={tab.id}
                path={`/home/venue-details/${venueId}${tab.route}`}
                render={(childProps: any) => (
                  <RouteComponent
                    {...childProps}
                    {...tab.props}
                    venueId={venueId}
                  />
                )}
              />
            );
          })}
        </div>
        <div className="window-footer">
          <Typography classes={{ root: 'footer-text' }} align="center">
            Fanfood Powered
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default VenueDetails;
