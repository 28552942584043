import React from 'react';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';

export interface IRefundDataRecord extends IHasCurrency {
  orderNo: string;
  orderPlacedDate: string;
  refundAmt: number;
  refundDate: string;
}

export interface IRefundSummary {
  data: IRefundDataRecord[];
  isAdmin: boolean;
}

const exportName = 'refund-summary';

const columns: Array<IReportColumn<IRefundDataRecord>> = [
  {
    accessor: record => record.orderNo,
    name: 'Order No',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.orderPlacedDate,
    name: 'Order Placed Date',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.refundDate,
    name: 'Refund Date',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.refundAmt,
    name: 'Refund Amount',
    total: true,
    type: ReportColumnType.currency,
  },
];

const RefundSummary = (props: IRefundSummary): JSX.Element => {
  return <BasicReport {...{ columns, exportName }} {...props} />;
};

export default RefundSummary;
