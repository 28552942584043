import React from 'react';
import { getTimeString } from '../../../services/Util/Util.service';

import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from '../BasicReport';

interface IProps {
  data: IRunnerSummary[];
  isAdmin: boolean;
  venueCurrency: string;
}
export interface IRunnerSummary extends IHasCurrency {
  averageDeliveryTime: number;
  deliveryCount: number;
  firstName: string;
  runnerId: string;
  totalTipAmt: number;
  venueName: string;
}

const exportName = 'Runner-Summary';
const columns: Array<IReportColumn<IRunnerSummary>> = [
  {
    accessor: record => record.venueName,
    adminOnly: true,
    name: 'Venue Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.firstName,
    name: 'Runner Name',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.totalTipAmt,
    name: 'Tip',
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.deliveryCount,
    name: 'Total Deliveries',
    type: ReportColumnType.string,
  },
  {
    accessor: record => getTimeString(record.averageDeliveryTime),
    name: 'Average Delivery Time',
    type: ReportColumnType.string,
  },
];

const RunnerSummary = (props: IProps): JSX.Element => (
  <BasicReport {...{ columns, exportName }} {...props} />
);

export default RunnerSummary;
