import {
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { PartnerId } from '../../../components/Partner/PartnerId';
import IProduct from '../../../models/Product.model';
import { IVenue } from '../../../models/Venue.model';
import {
  getCurrencyPrefix,
  getProductImage,
} from '../../../services/Util/Util.service';
import { VALID_CATEGORY_TYPES } from '../Categories/Category.config';

const menuTableColumnNames = [
  { name: 'Photo', size: 'small' },
  { name: 'Name', size: 'medium' },
  { name: 'Price', size: 'small' },
  { name: 'Active', size: 'small' },
  // { name: 'Stock', size: 'small' }, We don't use stock at all right now
  { name: 'Priority', size: 'small' },
  { name: 'Inventory', size: 'small' },
  { name: 'Availability Type', size: 'small' },
  { name: 'Action', size: 'medium' },
];

const optionsTableColumnNames = [
  { name: '', size: 'small' },
  { name: 'Name', size: 'medium' },
  { name: 'Priority', size: 'small' },
  { name: 'Inventory', size: 'small' },
  { name: 'Action', size: 'medium' },
];

interface IMenuTableProps {
  isOptionsCategory: boolean;
  onDeleteProduct: (productId: string) => void;
  onSelectProduct: (productId: string) => void;
  products: IProduct[];
  toggleProductActive: (productId: string, active: boolean) => Promise<void>;
  venue: IVenue;
}

const MenuTable = ({
  isOptionsCategory,
  onDeleteProduct,
  onSelectProduct,
  products,
  toggleProductActive,
  venue,
}: IMenuTableProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const columnNames = isOptionsCategory
    ? optionsTableColumnNames
    : menuTableColumnNames;

  const handleActiveToggle = (product: IProduct, active: boolean) => {
    const isPriceZero = product.price === 0;
    const isOptionType =
      product.category &&
      product.category.type === VALID_CATEGORY_TYPES.OPTIONS;
    // Throws error if attempting to activate a free product
    //  when venue doesn't allow for free products. Disabling
    //  is allowed.
    if (!venue.allowFreeProducts && isPriceZero && !isOptionType && active) {
      enqueueSnackbar!('Price must be greater than zero prior to activating.', {
        variant: 'error',
      });
      return;
    }

    toggleProductActive(product.id, active);
  };
  useEffect(() => {
    products.map((productData: any) => {
      const availability = [];
      if (productData?.availabilityType === 'ALL') {
        availability.push('PICKUP', 'DELIVERY');
      } else if (productData?.availabilityType !== 'NONE') {
        availability.push(productData?.availabilityType);
      }
      if (productData.pos) {
        availability.push('POS');
      }
      productData.testAvailabilityType = availability;
    });
  }, [products]);

  console.log(products, 'products+');

  return (
    <Table className="menu__table">
      <TableBody>
        <TableRow classes={{ root: 'menu__table-header' }}>
          {columnNames.map((column: any, i: number) => (
            <TableCell
              key={i}
              align="left"
              size={column.size}
              variant="head"
              classes={{ root: 'menu__table-cell' }}
            >
              <p>{column.name}</p>
            </TableCell>
          ))}
        </TableRow>
        {products.length < 1
          ? noDataAvailable(columnNames.length)
          : products.map((product: IProduct) => (
              <TableRow key={product.id} hover={true}>
                <TableCell
                  key={`product ${product.id}_photo`}
                  align="center"
                  size="small"
                  variant="body"
                  classes={{ root: 'menu__table-cell photo' }}
                >
                  {getProductImage(product) && (
                    <img
                      alt="product"
                      className="menu__product-image"
                      src={getProductImage(product)}
                    />
                  )}
                </TableCell>
                <TableCell
                  key={`product ${product.id}_name`}
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'menu__table-cell' }}
                >
                  <p>{product.name}</p>
                  <PartnerId
                    id={product.partnerProductId}
                    partner={product.partner}
                  />
                </TableCell>
                {!isOptionsCategory && (
                  <TableCell
                    key={`product ${product.id}_price`}
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    <p>
                      <NumberFormat
                        value={product.price}
                        decimalScale={2}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={getCurrencyPrefix(venue.currency)}
                      />
                    </p>
                  </TableCell>
                )}

                {!isOptionsCategory && (
                  <TableCell
                    key={`product ${product.id}_active`}
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={product.active}
                      color="primary"
                      name="active"
                      disabled={
                        product.stock < 1 && product.countAsInventory === true
                          ? true
                          : false
                      }
                      onChange={(e, checked) =>
                        handleActiveToggle(product, checked)
                      }
                      size="medium"
                      value={product.active}
                    />
                  </TableCell>
                )}
                {/* We don't use stock at all right now, when we re-implement the inventory feature, we can re-enable this
                {!isOptionsCategory && (
                  <TableCell
                    key={`product ${product.id}_stock`}
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    <p>{product.stock}</p>
                  </TableCell>
                )} */}
                <TableCell
                  key={`product ${product.id}_priority`}
                  align="left"
                  size="small"
                  variant="body"
                  classes={{ root: 'menu__table-cell' }}
                >
                  <p>{product.priority}</p>
                </TableCell>
                <TableCell
                  key={`product ${product.id}_stock`}
                  align="left"
                  size="small"
                  variant="body"
                  classes={{ root: 'menu__table-cell' }}
                >
                  <p>{product.stock}</p>
                </TableCell>
                {!isOptionsCategory && product?.testAvailabilityType && (
                  <TableCell
                    key={`product ${product.id}_availability-type`}
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'menu__table-cell' }}
                  >
                    <p>{product.testAvailabilityType.join(', ')}</p>
                  </TableCell>
                )}
                <TableCell
                  key={`product ${product.id}_action`}
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'menu__table-cell' }}
                >
                  <Button
                    size="small"
                    classes={{ root: 'menu__action-buttons' }}
                    onClick={() => onSelectProduct(product.id)}
                  >
                    <div className="icon-title-wrapper">
                      <CreateOutlinedIcon />
                      <span className="icon-title">Edit</span>
                    </div>
                  </Button>
                  <Button
                    size="small"
                    classes={{ root: 'menu__action-buttons' }}
                    onClick={() => onDeleteProduct(product.id)}
                  >
                    <div className="icon-title-wrapper">
                      <DeleteOutlinedIcon />
                      <span className="icon-title">Delete</span>
                    </div>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

export default MenuTable;
