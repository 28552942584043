export interface ICategoryFieldNames {
  name: string;
  placeholder: string | undefined;
  propertyName: string;
  required?: boolean;
  type: string;
}

export enum VALID_CATEGORY_TYPES {
  STANDALONE = 'STANDALONE',
  MERCHANDISE = 'MERCHANDISE',
  OPTIONS = 'OPTIONS',
}

const categoryTableColumnNames = [
  { name: 'Category', size: 'medium' },
  { name: 'Active', size: 'small' },
  { name: 'Priority', size: 'small' },
  { name: 'Type', size: 'small' },
  { name: 'Action', size: 'medium' },
];

const categoryInitialState = {
  name: '',
  priority: 0,
  type: VALID_CATEGORY_TYPES.STANDALONE,
};

const categoryDialogFieldNames = [
  {
    name: 'Category',
    placeholder: 'Enter Category Name',
    propertyName: 'name',
    required: true,
    type: 'text',
  },
  {
    name: 'Priority',
    placeholder: '0',
    propertyName: 'priority',
    type: 'number',
  },
];

export {
  categoryTableColumnNames,
  categoryInitialState,
  categoryDialogFieldNames,
};
