import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { IProductTag } from '../../../models/ProductTag.model';
import { IAddOrUpdateProductTagDto } from '../../../services/ProductTag/ProductTag.service';
export interface IProductTagDialogProps {
  createOrUpdateTag: (productTag: Partial<IProductTag>) => Promise<void>;
  onClose: () => void;
  productTag: Partial<IProductTag>;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

export const ProductTagDialog = ({
  createOrUpdateTag,
  onClose,
  productTag,
}: IProductTagDialogProps) => {
  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" {...{ onClose }}>
      <DialogTitle id="form-dialog-title" className="section-title">
        <span className="section-title">
          {productTag.id ? 'Edit Tag' : 'Add Tag'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="generic-dialog">
          <Formik<IAddOrUpdateProductTagDto>
            onSubmit={async values => await createOrUpdateTag(values)}
            initialValues={{
              ...productTag,
            }}
            validationSchema={validationSchema}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              setFieldValue,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoComplete="off"
                  className="generic-dialog__field"
                  error={touched.name && !!errors.name}
                  label="Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />
                <ErrorMessage className="error" component="div" name="name" />
                <div className="button-holder-different-margin">
                  <DialogActions>
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                    >
                      <div className="icon-title-wrapper">
                        <UndoOutlinedIcon />
                        <span className="icon-title">Cancel</span>
                      </div>
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid}
                    >
                      <div className="icon-title-wrapper">
                        <CheckOutlinedIcon />
                        <span className="icon-title">Save</span>
                      </div>
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};
