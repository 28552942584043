import { IconButton, Snackbar, SnackbarProps } from '@material-ui/core';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import './CustomSnackbar.scss';

type TransitionProps = Omit<SlideProps, 'direction'>;
type CustomSnackbarProps = Omit<SnackbarProps, 'onClose'>;
type ICustomSnackbar = CustomSnackbarProps & { onClose: () => void };

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const CustomSnackbar = (props: ICustomSnackbar) => {
  const { onClose } = props;

  return (
    <Snackbar
      classes={{
        root: 'custom-snackbar-alert',
      }}
      TransitionComponent={TransitionDown}
      {...props}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          className="custom-snackbar-close-button"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default CustomSnackbar;
