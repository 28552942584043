import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/reports`;

export enum ReportType {
  BUILDING_REVENUE_SUMMARY = 'BUILDING_REVENUE_SUMMARY',
  BUILDING_USER_DETAIL = 'BUILDING_USER_DETAIL',
  FLASH_REPORT = 'FLASH_REPORT',
  PAYOUT_DETAIL = 'PAYOUT_DETAIL',
  PROMOTIONS = 'PROMOTIONS',
  RUNNER = 'RUNNER',
  USER_DETAIL = 'USER_DETAIL',
  USER_OVERVIEW = 'USER_OVERVIEW',
  VENUE_DASHBOARD = 'VENUE_DASHBOARD',
  VENUE_EVENT_SUMMARY = 'VENUE_EVENT_SUMMARY',
  VENUE_PRODUCT = 'VENUE_PRODUCT',
  VENUE_REVENUE = 'VENUE_REVENUE',
  REFUND_SUMMARY = 'REFUND_SUMMARY',
  TAX_REPORT = 'TAX_REPORT',
  MEMBER_REPORT = 'MEMBER_REPORT',
}

export interface IReportQuery {
  endTime: string;
  reportType: ReportType;
  startTime: string;
  storeId?: string;
  tags: string[];
  venueId?: string;
}
export interface IMemberReportQ {
  endTime: string;
  startTime: string;
  venueId: string;
}

const get = (url: string, options: IReportQuery) =>
  axiosWithAuth.post(url, options);

const getAdminReports = (options: IReportQuery) =>
  get(`${baseUrl}/admin`, options);

const getReport = (options: IReportQuery) => get(`${baseUrl}`, options);

const getPayoutOrders = (
  venueId: string,
  payoutId: string,
  storeId?: string,
  posOrder?: boolean,
) =>
  axiosWithAuth.get(
    `${baseUrl}/payout-orders/${venueId}/${payoutId}/${posOrder}`,
    {
      params: {
        ...(storeId && { storeId }),
      },
    },
  );

const getMembertReport = (body: IMemberReportQ) =>
axiosWithAuth.post(`${baseUrl}/twinDolphinOrderReport`, body);

export default {
  getAdminReports,
  getPayoutOrders,
  getReport,
  getMembertReport,
};
