import {
  Button,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { ErrorMessage, Field, Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { get, isEmpty } from 'lodash';
import ColorPicker from 'material-ui-color-picker';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect, useState } from 'react';
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import TagManager from '../../../components/TagManager';
import { ITag } from '../../../models/Tag.model';
import { IVenue, PaymentGateway } from '../../../models/Venue.model';
import TagService from '../../../services/Tag.service';
import {
  getTimezone,
  unMaskPhoneNumber,
} from '../../../services/Util/Util.service';
import VenueService from '../../../services/Venue/Venue.service';
import {
  HeaderButton,
  HeaderButtonType,
} from '../../../theme/components/buttons/HeaderButton';
import AddImage from '../../AddImage/AddImage';
import GoogleAutoComplete from '../../Map/GoogleAutoComplete';
import SimpleMap from '../../Map/SimpleMap';
import {
  CountryOptions,
  currencyOptions,
  feeUnits,
  ISelectConfig,
  IValidLocation,
  IVenueSettingsFormValues,
  SignupSchema,
  venueTypeConfig,
} from '../EditVenueConfig';
import ValidLocationsDialog from './ValidLocationsDialog/ValidLocationsDialog';
import './VenueSettings.scss';
declare const ENVARS: any;

interface IVenueSettingsProps extends WithSnackbarProps {
  disableActionButton: boolean;
  onUpdate: (venueData?: IVenueSettingsFormValues) => Promise<void>;
  showImageDetails: boolean;
  setShowImageDetails: (show: boolean) => void;
  venue: IVenue;
}

const VenueSettings = (props: IVenueSettingsProps) => {
  const {
    venue,
    onUpdate,
    disableActionButton,
    enqueueSnackbar,
    showImageDetails,
    setShowImageDetails,
  } = props;
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const [showValidLocationsDialog, setShowValidLocationsDialog] = useState(
    false,
  );
  const [venueTimezone, setVenueTimezone] = useState({});
  const [newImageFile, setNewImageFile] = useState<any>(null);

  const validLocationTypes = venue.validLocationTypes || [];
  const venueId = get(venue, 'id', '');

  const [displayValidLocationsError, setDisplayValidLocationsError] = useState(
    validLocationTypes.length < 1,
  );

  const [allTags, setAllTags] = React.useState<string[]>([]);

  const defaultPrimaryColor = '#da112e';
  const imageHeight = ['TENANT_BUILDING', 'SERVICE_PROVIDER'].includes(
    venue.type,
  )
    ? 400
    : 150;

  useEffect(() => {
    const validLocations = get(venue, 'validLocationTypes', []);
    setDisplayValidLocationsError(validLocations.length < 1);

    if (venue.latitude && venue.longitude) {
      getTimezoneData(venue.latitude, venue.longitude);
    }

    (async () => {
      const { data } = await TagService.getAll();
      setAllTags(data.map((tag: ITag) => tag.name));
    })();
  }, [venue]);

  const getTimezoneData = async (latitude: number, longitude: number) => {
    try {
      const { data } = await getTimezone(latitude, longitude);
      setVenueTimezone(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
  };

  const handleVenueSettingsUpdate = async (
    values: IVenueSettingsFormValues,
  ) => {
    if (values.deliveryAvailable! && displayValidLocationsError) {
      enqueueSnackbar('Please add at least one valid location for delivery', {
        variant: 'error',
      });
      return;
    }

    values.timezone = get(venueTimezone, 'timeZoneId');
    values.deliveryFeeSharePercent = 100 - values.deliveryFeeSharePercent!;
    values.deliveryRevenueSharePercent =
      100 - values.deliveryRevenueSharePercent!;
    values.pickupFeeSharePercent = 100 - values.pickupFeeSharePercent!;
    values.pickupRevenueSharePercent = 100 - values.pickupRevenueSharePercent!;
    //POS FEE SETUP
    values.posFeeSharePercent = 100 - values.posFeeSharePercent!;
    values.posRevenueSharePercent = 100 - values.posRevenueSharePercent!;
    //Service FEE SETUP
    values.serviceFeeSharePercent = 100 - values.serviceFeeSharePercent!;
    if (values.contactPhoneNumber!.indexOf('+') > -1) {
      values.contactPhoneNumber = unMaskPhoneNumber(
        formatPhoneNumber(values.contactPhoneNumber!),
      );
    }

    if (newImageFile) {
      const formData = new FormData();
      formData.append('imageFile', newImageFile);
      values.imageData = formData;
    }
    // custom fee object handling
    let obj = {
      mobileCCFeesPercentage: 0,
      mobileCCFeesNumber: 0,
    };
    let obj1 = {
      posCCFeesPercentage: 0,
      posCCFeesNumber: 0,
    };
    if (values.mobileCCFeesPercentage) {
      obj.mobileCCFeesPercentage = values.mobileCCFeesPercentage;
      values.mobileCCFees = obj;
      delete values.mobileCCFeesPercentage;
    } else {
      delete values.mobileCCFees?.mobileCCFeesPercentage;
    }
    if (values.mobileCCFeesNumber) {
      obj.mobileCCFeesNumber = values.mobileCCFeesNumber;
      values.mobileCCFees = obj;
      delete values.mobileCCFeesNumber;
    } else {
      delete values.mobileCCFees?.mobileCCFeesNumber;
    }
    if (values.posCCFeesPercentage) {
      obj1.posCCFeesPercentage = values.posCCFeesPercentage;
      values.posCCFees = obj1;
      delete values.posCCFeesPercentage;
    } else {
      delete values.posCCFees?.posCCFeesPercentage;
    }
    if (values.posCCFeesNumber) {
      obj1.posCCFeesNumber = values.posCCFeesNumber;
      values.posCCFees = obj1;
      delete values.posCCFeesNumber;
    } else {
      delete values.posCCFees?.posCCFeesPercentage;
    }

    await onUpdate(values);
    setNewImageFile(null);
  };

  const handleDeleteLocation = async (curLocation: IValidLocation) => {
    const locationTypeId = get(curLocation, 'id');
    try {
      await VenueService.deleteLocationType(venueId, locationTypeId);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }

    return await onUpdate();
  };

  const copySquareLinkToClipboard = () => {
    navigator.clipboard.writeText(
      `${ENVARS.API.BASE_URL}/api/v1/partners/square/oauth?venueId=${venueId}`,
    );
    enqueueSnackbar('Square OAuth URL Copied to Clipboard', {
      variant: 'success',
    });
  };

  const syncBypass = () => {
    const url = `${ENVARS.API.BASE_URL}/api/v1/bypass/oauth?venueId=${venueId}`;
    window.open(url, '_blank');
  };

  if (venue?.mobileCCFees?.mobileCCFeesPercentage) {
    venue.mobileCCFeesPercentage = venue.mobileCCFees.mobileCCFeesPercentage;
    venue.mobileCCFeesNumber = venue.mobileCCFees.mobileCCFeesNumber;
  }
  if (venue?.posCCFees?.posCCFeesPercentage) {
    venue.posCCFeesPercentage = venue.posCCFees.posCCFeesPercentage;
    venue.posCCFeesNumber = venue.posCCFees.posCCFeesNumber;
  }

  return (
    <>
      <Formik<IVenueSettingsFormValues>
        initialValues={{
          ...venue,
          deliveryFeeSharePercent: 100 - venue.deliveryFeeSharePercent,
          deliveryRevenueSharePercent: 100 - venue.deliveryRevenueSharePercent,
          pickupFeeSharePercent: 100 - venue.pickupFeeSharePercent,
          pickupRevenueSharePercent: 100 - venue.pickupRevenueSharePercent,
          posFeeSharePercent: 100 - venue.posFeeSharePercent,
          posRevenueSharePercent: 100 - venue.posRevenueSharePercent,
          serviceFeeSharePercent: 100 - venue.serviceFeeSharePercent,
        }}
        onSubmit={handleVenueSettingsUpdate}
        validationSchema={SignupSchema}
        enableReinitialize={true}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
        }) => {
          // handle venue theme without properties. e.g., theme is undefined or {};
          const themePrimaryColor =
            (values.theme && values.theme.primaryColor) || defaultPrimaryColor;
          return (
            <div>
              <form
                onSubmit={handleSubmit}
                onKeyPress={(event: any) => {
                  const key = event.charCode || event.keyCode || 0;
                  if (key === 13) {
                    event.preventDefault();
                  }
                }}
              >
                <div className="venue-settings">
                  <div className="container">
                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={get(touched, 'name') && !!get(errors, 'name')}
                      name="name"
                      label="Venue Name"
                      className="field"
                      value={values.name}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="name"
                    />
                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        get(touched, 'vanityUrl') && !!get(errors, 'vanityUrl')
                      }
                      name="vanityUrl"
                      label="Vanity URL"
                      className="field"
                      value={values.vanityUrl}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="vanityUrl"
                    />
                    <Field
                      className="field"
                      required={true}
                      name="addressLine1"
                      render={({ field, form }: { field: any; form: any }) => (
                        <GoogleAutoComplete
                          label="Enter your address"
                          className="field"
                          field={field}
                          error={
                            get(touched, 'addressLine1') &&
                            !!get(errors, 'addressLine1')
                          }
                          value={values.name}
                          onPlaceLoaded={async (
                            latitude: number,
                            longitude: number,
                            address: any,
                          ) => {
                            const {
                              addressLine1,
                              city,
                              country,
                              state,
                              zip,
                            } = address;

                            setFieldValue('addressLine1', addressLine1);
                            setFieldValue('city', city);
                            setFieldValue('state', state);
                            setFieldValue('zip', zip || '');
                            setFieldValue('country', country || '');
                            setFieldValue('latitude', latitude);
                            setFieldValue('longitude', longitude);
                            await getTimezoneData(latitude, longitude);
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="addressLine1"
                    />
                    <SimpleMap
                      lat={values.latitude}
                      lng={values.longitude}
                      zoom={15}
                    />

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Address Line 2"
                      name="addressLine2"
                      className="field"
                      value={values.addressLine2 || ''}
                      autoComplete="off"
                    />

                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={get(touched, 'city') && !!get(errors, 'city')}
                      label="City"
                      name="city"
                      className="field"
                      value={values.city}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="city"
                    />

                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={get(touched, 'state') && !!get(errors, 'state')}
                      label="State"
                      name="state"
                      className="field"
                      value={values.state}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="state"
                    />

                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Zip"
                      name="zip"
                      error={get(touched, 'zip') && !!get(errors, 'zip')}
                      className="field"
                      value={values.zip}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="zip"
                    />

                    <FormControl required={true} className="field">
                      <InputLabel shrink={true}>Country</InputLabel>
                      <Select
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="country"
                      >
                        {CountryOptions.map(
                          ({ name, value }: ISelectConfig) => (
                            <MenuItem key={name} value={value}>
                              {name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        get(touched, 'contactFirstName') &&
                        !!get(errors, 'contactFirstName')
                      }
                      label="Contact First Name"
                      className="field"
                      name="contactFirstName"
                      value={values.contactFirstName}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="contactFirstName"
                    />

                    <TextField
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        get(touched, 'contactLastName') &&
                        !!get(errors, 'contactLastName')
                      }
                      label="Contact Last Name"
                      className="field"
                      name="contactLastName"
                      value={values.contactLastName}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="contactLastName"
                    />

                    <TextField
                      required={true}
                      name="contactEmail"
                      type="email"
                      onChange={handleChange}
                      error={
                        get(touched, 'contactEmail') &&
                        !!get(errors, 'contactEmail')
                      }
                      onBlur={handleBlur}
                      label="Contact Email"
                      className="field"
                      value={values.contactEmail}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="contactEmail"
                    />

                    <InputLabel
                      className="phoneInput field"
                      error={
                        get(touched, 'contactPhoneNumber') &&
                        !!get(errors, 'contactPhoneNumber')
                      }
                      required={true}
                      shrink={true}
                    >
                      Contact Number
                    </InputLabel>
                    <PhoneInput
                      defaultCountry={phoneNumberUtil.getRegionCodeForCountryCode(
                        Number(values.contactCountryCode || '1'),
                      )}
                      placeholder="Enter Phone Number"
                      name="contactPhoneNumber"
                      value={values.contactPhoneNumber!}
                      className={`phone-container ${
                        get(touched, 'contactPhoneNumber') &&
                        !!get(errors, 'contactPhoneNumber')
                          ? 'error'
                          : ''
                      }`}
                      numberInputProps={{
                        className: 'phone-container__input',
                      }}
                      onChange={(e: string) => {
                        setFieldValue('contactPhoneNumber', e);
                      }}
                      onCountryChange={(code: string = '') => {
                        if (!isEmpty(code)) {
                          setFieldValue(
                            'contactCountryCode',
                            getCountryCallingCode(code),
                          );
                        }
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="contactPhoneNumber"
                    />

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Description"
                      className="field"
                      name="description"
                      value={values.description}
                      autoComplete="off"
                    />

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Venue Timezone"
                      className="field"
                      name="timezone"
                      value={
                        !!get(venueTimezone, 'timeZoneId')
                          ? `${get(venueTimezone, 'timeZoneId')} - ${get(
                              venueTimezone,
                              'timeZoneName',
                            )}`
                          : ''
                      }
                      autoComplete="off"
                      disabled={true}
                    />
                    {/* Theme Primary Color preview
                            inline styles used to pass dynamic colors into css */}
                    <div className="theme-container">
                      <Grid container={true} spacing={3}>
                        <Grid
                          item={true}
                          md={4}
                          xs={6}
                          className="theme-wrapper"
                        >
                          <Typography>Select a color theme:</Typography>
                          <ColorPicker
                            className="text-field"
                            value={themePrimaryColor}
                            onChange={color => {
                              setFieldValue('theme', {
                                primaryColor: color,
                              });
                            }}
                            hintText={themePrimaryColor}
                          />
                        </Grid>
                        <Grid item={true} xs={6}>
                          <Button
                            variant="contained"
                            size="small"
                            aria-label="restore default"
                            onClick={() =>
                              setFieldValue('theme', {
                                primaryColor: undefined,
                              })
                            }
                          >
                            Restore default color
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container={true} spacing={3}>
                        <Grid item={true} md={4} xs={6}>
                          <div
                            className="theme-preview-header"
                            style={{ color: themePrimaryColor }}
                          >
                            HEADER PREVIEW
                          </div>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <div
                            className="theme-preview-button"
                            style={{ backgroundColor: themePrimaryColor }}
                          >
                            BUTTON PREVIEW
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    {venue.imageUrl ? (
                      <img
                        alt="venue.name"
                        className="theme-container"
                        src={venue.imageUrl}
                        width={500}
                        height={imageHeight}
                      />
                    ) : null}
                    <AddImage
                      getFile={(imageFile: any) => {
                        setNewImageFile(imageFile);
                      }}
                      width={500}
                      height={imageHeight}
                      showImageDetails={showImageDetails}
                      setShowImageDetails={setShowImageDetails}
                      minWidth={500}
                      minHeight={imageHeight}
                    />
                  </div>
                  <div className="container">
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="active"
                        color="primary"
                        checked={values.active}
                        value={values.active}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Active
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="allowFreeProducts"
                        color="primary"
                        checked={values.allowFreeProducts}
                        value={values.allowFreeProducts}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Allow Free Products
                    </div>
                    <div
                      className="field"
                      style={{
                        marginLeft: '21px',
                        marginBottom: '7px',
                      }}
                    >
                      POS Fees
                    </div>
                    <div className="setting-subselection">
                      <FormControl required={true} className="field">
                        <InputLabel shrink={true}>POS Fee Unit</InputLabel>
                        <Select
                          value={values.posFeeUnit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="posFeeUnit"
                        >
                          {feeUnits.map((unit: ISelectConfig) => (
                            <MenuItem key={unit.name} value={unit.value}>
                              {unit.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        required={true}
                        className="field"
                        name="posFee"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          get(touched, 'posFee') && !!get(errors, 'posFee')
                        }
                        label="POS Fee"
                        value={values.posFee}
                        autoComplete="off"
                        inputProps={{
                          min: '0.00',
                          step: '0.01',
                          type: 'number',
                        }}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="posFee"
                      />
                      <TextField
                        required={true}
                        className="field"
                        name="posFeeSharePercent"
                        type="number"
                        error={
                          get(touched, 'posFeeSharePercent') &&
                          !!get(errors, 'posFeeSharePercent')
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="FanFood POS Fee Share Percent"
                        value={values.posFeeSharePercent}
                        autoComplete="off"
                        inputProps={{
                          min: '0.00',
                          step: '0.01',
                          type: 'number',
                        }}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="posFeeSharePercent"
                      />
                      <TextField
                        required={true}
                        className="field"
                        name="posRevenueSharePercent"
                        type="number"
                        error={
                          get(touched, 'posRevenueSharePercent') &&
                          !!get(errors, 'posRevenueSharePercent')
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="FanFood POS Revenue Share Percent"
                        value={values.posRevenueSharePercent}
                        autoComplete="off"
                        inputProps={{
                          min: '0.00',
                          step: '0.01',
                          type: 'number',
                        }}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="posRevenueSharePercent"
                      />
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="serviceAvailable"
                        color="primary"
                        checked={values.serviceAvailable}
                        value={values.serviceAvailable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Service Fee Applied
                    </div>
                    {values.serviceAvailable && (
                      <div className="setting-subselection">
                        <FormControl required={true} className="field">
                          <InputLabel shrink={true}>
                            Service Fee Unit
                          </InputLabel>
                          <Select
                            value={values.serviceFeeUnit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="serviceFeeUnit"
                          >
                            {feeUnits.map((unit: ISelectConfig) => (
                              <MenuItem key={unit.name} value={unit.value}>
                                {unit.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          required={true}
                          className="field"
                          name="serviceFee"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            get(touched, 'serviceFee') &&
                            !!get(errors, 'serviceFee')
                          }
                          label="Service Fee"
                          value={values.serviceFee}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="serviceFee"
                        />
                        <TextField
                          required={true}
                          className="field"
                          name="serviceFeeSharePercent"
                          type="number"
                          error={
                            get(touched, 'serviceFeeSharePercent') &&
                            !!get(errors, 'serviceFeeSharePercent')
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="FanFood Service Fee Share Percent"
                          value={values.serviceFeeSharePercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="serviceFeeSharePercent"
                        />
                      </div>
                    )}
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="deliveryAvailable"
                        color="primary"
                        checked={values.deliveryAvailable}
                        value={values.deliveryAvailable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Delivery Available
                    </div>
                    {values.deliveryAvailable && (
                      <div className="setting-subselection">
                        <FormControl required={true} className="field">
                          <InputLabel shrink={true}>
                            Delivery Fee Unit
                          </InputLabel>
                          <Select
                            value={values.deliveryFeeUnit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="deliveryFeeUnit"
                          >
                            {feeUnits.map((unit: ISelectConfig) => (
                              <MenuItem key={unit.name} value={unit.value}>
                                {unit.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          required={true}
                          className="field"
                          name="deliveryFee"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            get(touched, 'deliveryFee') &&
                            !!get(errors, 'deliveryFee')
                          }
                          label="Delivery Fee"
                          value={values.deliveryFee}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="deliveryFee"
                        />
                        <TextField
                          required={true}
                          className="field"
                          name="deliveryFeeSharePercent"
                          type="number"
                          error={
                            get(touched, 'deliveryFeeSharePercent') &&
                            !!get(errors, 'deliveryFeeSharePercent')
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="FanFood Delivery Fee Share Percent"
                          value={values.deliveryFeeSharePercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="deliveryFeeSharePercent"
                        />
                        <TextField
                          required={true}
                          className="field"
                          name="deliveryRevenueSharePercent"
                          type="number"
                          error={
                            get(touched, 'deliveryRevenueSharePercent') &&
                            !!get(errors, 'deliveryRevenueSharePercent')
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="FanFood Delivery Revenue Share Percent"
                          value={values.deliveryRevenueSharePercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="deliveryRevenueSharePercent"
                        />
                        <div className="field">
                          <Switch
                            classes={{
                              track: 'custom-colored-switch-track',
                            }}
                            name="enableDeliveryTips"
                            color="primary"
                            checked={values.enableDeliveryTips}
                            value={values.enableDeliveryTips}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          Enable Delivery Tips
                        </div>
                      </div>
                    )}
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="pickupAvailable"
                        color="primary"
                        checked={values.pickupAvailable}
                        value={values.pickupAvailable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Pickup Available
                    </div>
                    {values.pickupAvailable && (
                      <div className="setting-subselection">
                        <FormControl required={true} className="field">
                          <InputLabel shrink={true}>Pickup Fee Unit</InputLabel>
                          <Select
                            value={values.pickupFeeUnit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="pickupFeeUnit"
                          >
                            {feeUnits.map((unit: ISelectConfig) => (
                              <MenuItem key={unit.name} value={unit.value}>
                                {unit.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          required={true}
                          className="field"
                          name="pickupFee"
                          type="number"
                          error={
                            get(touched, 'pickupFee') &&
                            !!get(errors, 'pickupFee')
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Pickup Fee"
                          value={values.pickupFee}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="pickupFee"
                        />
                        <TextField
                          required={true}
                          className="field"
                          name="pickupFeeSharePercent"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            get(touched, 'pickupFeeSharePercent') &&
                            !!get(errors, 'pickupFeeSharePercent')
                          }
                          label="FanFood Pickup Fee Share Percent"
                          value={values.pickupFeeSharePercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="pickupFeeSharePercent"
                        />
                        <TextField
                          required={true}
                          className="field"
                          name="pickupRevenueSharePercent"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            get(touched, 'pickupRevenueSharePercent') &&
                            !!get(errors, 'pickupRevenueSharePercent')
                          }
                          label="FanFood Pickup Revenue Share Percent"
                          value={values.pickupRevenueSharePercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="pickupRevenueSharePercent"
                        />
                        <div className="field">
                          <Switch
                            classes={{
                              track: 'custom-colored-switch-track',
                            }}
                            name="enablePickupTips"
                            color="primary"
                            checked={values.enablePickupTips}
                            value={values.enablePickupTips}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          Enable Pickup Tips
                        </div>
                      </div>
                    )}

                    {(values.enablePickupTips || values.enableDeliveryTips) && (
                      <div className="field">
                        <Switch
                          classes={{
                            track: 'custom-colored-switch-track',
                          }}
                          name="tipRecipient"
                          color="primary"
                          checked={values.tipRecipient}
                          value={values.tipRecipient}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Tips Go To Location
                      </div>
                    )}
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="sendOrderNotifications"
                        color="primary"
                        checked={values.sendOrderNotifications}
                        value={values.sendOrderNotifications}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Allow Order Notifications
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="orderAheadAvailable"
                        color="primary"
                        checked={values.orderAheadAvailable}
                        value={values.orderAheadAvailable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Order Ahead Available
                    </div>

                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="orderNowAvailable"
                        color="primary"
                        checked={values.orderNowAvailable}
                        value={values.orderNowAvailable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Order Now Available
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="calculateTaxes"
                        color="primary"
                        checked={values.calculateTaxes}
                        value={values.calculateTaxes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Tax Calculation
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="allowOpenTabs"
                        color="primary"
                        checked={values.allowOpenTabs}
                        value={values.allowOpenTabs}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Allow Open Tabs (Suites only)
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="deferPaymentCapture"
                        color="primary"
                        checked={values.deferPaymentCapture}
                        value={values.deferPaymentCapture}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Defer all payment capture
                    </div>
                    <div className="field">
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="useNewLocations"
                        color="primary"
                        checked={values.useNewLocations}
                        value={values.useNewLocations}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Use New Locations Module For This Venue
                    </div>

                    <FormControl required={true} className="field">
                      <InputLabel shrink={true}>Display Currency</InputLabel>
                      <Select
                        value={values.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="currency"
                      >
                        {currencyOptions.map((unit: ISelectConfig) => (
                          <MenuItem key={unit.name} value={unit.value}>
                            {unit.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      className="field"
                      name="liquorTaxPercent"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Liquor Tax Percent"
                      value={values.liquorTaxPercent}
                      autoComplete="off"
                      inputProps={{
                        min: '0.00',
                        step: '0.01',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="liquorTaxPercent"
                    />
                    <TextField
                      className="field"
                      name="salesTaxPercent"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Sales Tax Percent"
                      value={values.salesTaxPercent}
                      autoComplete="off"
                      inputProps={{
                        min: '0.00',
                        step: '0.01',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="salesTaxPercent"
                    />
                    <TextField
                      className="field"
                      name="promotionThreshold"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Promotion Threshold"
                      value={values.promotionThreshold}
                      autoComplete="off"
                      inputProps={{
                        min: '0.00',
                        step: '0.01',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="promotionThreshold"
                    />
                    <TextField
                      className="field"
                      name="promotionFundLeft"
                      disabled={true}
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Promotion Fund Left"
                      value={values.promotionFundLeft}
                      autoComplete="off"
                      inputProps={{
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="promotionFundLeft"
                    />
                    <TextField
                      className="field"
                      name="alcoholLimit"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Alcohol Limit"
                      value={values.alcoholLimit}
                      autoComplete="off"
                      inputProps={{
                        min: '0',
                        step: '1',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="alcoholLimit"
                    />
                    {values.alcoholLimit! > 0 && (
                      <>
                        <TextField
                          required={true}
                          name="alcoholMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Alcohol Message"
                          error={
                            get(touched, 'alcoholMessage') &&
                            !!get(errors, 'alcoholMessage')
                          }
                          className="field"
                          value={values.alcoholMessage || ''}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error"
                          component="div"
                          name="alcoholMessage"
                        />
                      </>
                    )}
                    <TextField
                      className="field"
                      name="alcoholAgeRestriction"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Alcohol Age Restriction"
                      value={values.alcoholAgeRestriction}
                      autoComplete="off"
                      inputProps={{
                        min: '0',
                        step: '1',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="alcoholAgeRestriction"
                    />
                    <TextField
                      className="field"
                      name="maxDistance"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Max Distance (miles) for Geolocated Notifications"
                      value={values.maxDistance}
                      autoComplete="off"
                      inputProps={{
                        min: '3',
                        step: '0.1',
                        type: 'number',
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="maxDistance"
                    />
                    <Grid container={true} justify="space-between">
                      <Grid>
                        <TextField
                          className="field"
                          name="mobileCCFeesPercentage"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Mobile CC Fees %"
                          value={values.mobileCCFeesPercentage}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <Grid style={{ width: '10rem' }}>
                          <ErrorMessage
                            className="error"
                            component="div"
                            name="mobileCCFeesPercentage"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column-reverse',
                        }}
                      >
                        +
                      </Grid>
                      <Grid>
                        <TextField
                          className="field"
                          name="mobileCCFeesNumber"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Mobile CC Fees $"
                          value={values.mobileCCFeesNumber}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <Grid style={{ width: '10rem' }}>
                          <ErrorMessage
                            className="error"
                            component="div"
                            name="mobileCCFeesNumber"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container={true} justify="space-between">
                      <Grid>
                        <TextField
                          className="field"
                          name="posCCFeesPercentage"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="POS CC Fees %"
                          value={values.posCCFeesPercentage}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <Grid style={{ width: '10rem' }}>
                          <ErrorMessage
                            className="error"
                            component="div"
                            name="posCCFeesPercentage"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column-reverse',
                        }}
                      >
                        +
                      </Grid>
                      <Grid>
                        <TextField
                          className="field"
                          name="posCCFeesNumber"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="POS CC Fees $"
                          value={values.posCCFeesNumber}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <Grid style={{ width: '10rem' }}>
                          <ErrorMessage
                            className="error"
                            component="div"
                            name="posCCFeesNumber"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <FormControl
                      required={true}
                      className="venue-type-form__field"
                    >
                      <InputLabel htmlFor="age-native-required">
                        Venue Type
                      </InputLabel>
                      <Select
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="type"
                        displayEmpty={true}
                        className="venue-type-form__field"
                        inputProps={{
                          id: 'age-native-required',
                        }}
                      >
                        {venueTypeConfig.map(
                          (type: ISelectConfig, index: any) => (
                            <MenuItem key={index} value={type.value}>
                              {type.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>

                    <FormControl
                      required={true}
                      className="venue-type-form__field"
                    >
                      <InputLabel htmlFor="age-native-required">
                        Payment Gateway
                      </InputLabel>
                      <Select
                        value={values.paymentGateway}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="paymentGateway"
                        displayEmpty={true}
                        className="venue-type-form__field"
                        inputProps={{
                          id: 'age-native-required',
                        }}
                      >
                        {Object.values(PaymentGateway).map(gateway => (
                          <MenuItem key={gateway} value={gateway}>
                            {gateway}
                          </MenuItem>
                        ))}
                      </Select>
                      {values.paymentGateway === PaymentGateway.SQUARE && (
                        <HeaderButton
                          icon={LinkIcon}
                          onClick={() => copySquareLinkToClipboard()}
                          text="Copy Square OAuth URL"
                          type={HeaderButtonType.SECONDARY}
                        />
                      )}
                      {values.paymentGateway === PaymentGateway.BYPASS && (
                        <HeaderButton
                          icon={LinkIcon}
                          onClick={() => syncBypass()}
                          text="Sync Bypass Store"
                          type={HeaderButtonType.SECONDARY}
                        />
                      )}
                    </FormControl>

                    <FormControl
                      required={true}
                      className="venue-type-form__field"
                    >
                      <TagManager
                        allTags={allTags}
                        currentTags={venue.tags || []}
                        onTagsUpdated={(tags: string[]) => {
                          setFieldValue('tags', tags);
                        }}
                      />
                    </FormControl>

                    <div className="valid-locations-container">
                      <InputLabel required={true}>Valid Locations :</InputLabel>
                      <Button
                        variant="contained"
                        className="margin-left"
                        size="medium"
                        aria-label="add"
                        onClick={() => {
                          setShowValidLocationsDialog(true);
                        }}
                      >
                        <Icon>add</Icon> Add{' '}
                      </Button>
                    </div>

                    <Grid className="locations-grid-container">
                      {validLocationTypes.map(
                        (validLocation: any, index: any) => (
                          <Grid key={index} item={true} className="grid-item">
                            <div className="location-name">
                              {validLocation.name}
                            </div>

                            <div className="location-name">
                              {validLocation.type && (
                                <span>({validLocation.type})</span>
                              )}
                            </div>

                            <div className="icon-container">
                              <Icon
                                className="icon-btn"
                                onClick={() => {
                                  setShowValidLocationsDialog(true);
                                }}
                              >
                                edit
                              </Icon>
                              <Icon
                                className="icon-btn"
                                onClick={() => {
                                  handleDeleteLocation(validLocation);
                                }}
                              >
                                close
                              </Icon>
                            </div>
                          </Grid>
                        ),
                      )}
                    </Grid>
                    {displayValidLocationsError && values.deliveryAvailable && (
                      <div className="error-message">
                        Need at least one valid location.
                      </div>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="save-btn"
                      disabled={disableActionButton}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
      {showValidLocationsDialog && (
        <ValidLocationsDialog
          close={() => {
            setShowValidLocationsDialog(false);
          }}
          onLocationUpdate={async (value: string) => await onUpdate()}
          venueId={venueId}
          validLocationTypes={validLocationTypes}
        />
      )}
    </>
  );
};

export default withSnackbar(VenueSettings);
