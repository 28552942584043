import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputLabel,
} from '@material-ui/core';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { useAppContext } from '../../../config/AppContext';
import { IStore } from '../../../models/Store.model';
import { getHeadersObject } from '../../../services/Util/Util.service';
import { ExportCSV } from '../ExportCSV';
import {
  IVenueProduct,
  IVenueProductBase,
  venueProductSummaryHeaders,
} from '../ReportingConfig';

interface IProps {
  onChangeStore: (storeId: string) => void;
  selectedStoreId: string | undefined;
  venueCurrency: string
  venueProductSummaryData: IVenueProduct[];
  onChangeTransactionType: (type: string) => void;
}

export function VenueProductSummary({
  onChangeStore,
  selectedStoreId,
  venueCurrency,
  venueProductSummaryData,
  onChangeTransactionType
}: IProps) {
  const [csvData, setCsvData] = useState([{}]);
  const [transactionType, setTransactionType] = useState('All');

  const { stores } = useAppContext();
  const transactionTypes: string[] = ['All', 'CC', 'CASH', 'MB'];

  const setData = () => {
    const summary: Array<{}> = [];
    if (!venueProductSummaryData.length) {
      summary.push(getHeadersObject(venueProductSummaryHeaders));
    } else {
      venueProductSummaryData.forEach((venueProduct: IVenueProduct) => {
        const obj = {
          'Product Name': venueProduct.name,
          'Total Sold': venueProduct.itemsSold,
          // tslint:disable-next-line: object-literal-sort-keys
          'Total Revenue': venueProduct.revenue,
        };
        summary.push(obj);

        if (venueProduct.subProducts) {
          venueProduct.subProducts.forEach((product: IVenueProductBase) => {
            const subobj = {
              'Product Name': `${venueProduct.name} - ${product.name}`,
              'Total Sold': product.itemsSold,
              // tslint:disable-next-line: object-literal-sort-keys
              'Total Revenue': product.revenue,
            };
            summary.push(subobj);
          });
        }
      });
    }
    setCsvData(summary);
  };

  useEffect(() => {
    setData();
  }, [venueProductSummaryData]);

  const handleStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    onChangeStore(newSelectedStoreId);
  };
  const handleTransactionTypeSelect = (e: any) => {
    setTransactionType(e.target.value);
    onChangeTransactionType(e.target.value);
  };

  return (
    <>
      <div className="records-container">
        <div className="search-groupby">
          <p className="label">Store: </p>
          <Select
            value={selectedStoreId || 'All Stores'}
            onChange={handleStoreSelect}
            classes={{
              root: 'select-store',
            }}
          >
            <MenuItem key="" value="All Stores">
              All Stores
            </MenuItem>
            {stores.map((store: IStore) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="search-groupby">
          <InputLabel htmlFor="type" classes={{ root: 'report-type-label' }}>
            Transaction Type:
          </InputLabel>
          <Select
            value={transactionType}
            onChange={handleTransactionTypeSelect}
            classes={{
              root: 'select-store',
            }}
          >
            {transactionTypes.map((e: string) => {
              switch (e) {
                case 'CC':
                  return (
                    <MenuItem value={e} key={e}>
                      Credit Card Products
                    </MenuItem>
                  );
                case 'CASH':
                  return (
                    <MenuItem value={e} key={e}>
                      Cash Products
                    </MenuItem>
                  );
                case 'MB':
                  return (
                    <MenuItem value={e} key={e}>
                      Mobile Products
                    </MenuItem>
                  );
                default:
                  return (
                    <MenuItem value={e} key={e}>
                      All Products
                    </MenuItem>
                  );
              }
            })}
          </Select>
        </div>
        <ExportCSV csvData={csvData} fileName={'Venue-Product-Summary'} />
      </div>

      <Table className="reporting__table">
        <TableHead>
          <TableRow>
            {venueProductSummaryHeaders.map(text => (
              <TableCell
                key={text}
                classes={{
                  head: 'table-header',
                }}
              >
                {text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!venueProductSummaryData.length
            ? noDataAvailable(4)
            : venueProductSummaryData.map(
                (venueProduct: IVenueProduct, ii: number) => (
                  <Fragment key={ii}>
                    <TableRow>
                    <TableCell className="table-cell table-cell-no-data product-item">
                        {venueProduct.tag}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data product-item">
                        {venueProduct.name}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data product-item">
                        {venueProduct.itemsSold}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data product-item">
                        {venueProduct.revenue > 0 ? (
                          <NumberFormat
                            value={venueProduct.revenue}
                            decimalScale={2}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            prefix={venueCurrency || '$'}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    {venueProduct.subProducts.map(
                      (subproduct: IVenueProductBase, jj: number) => (
                        <TableRow key={jj}>
                          <TableCell className="table-cell table-cell-no-data">
                            &#8735; {subproduct.tag}
                          </TableCell>
                          <TableCell className="table-cell table-cell-no-data">
                            &#8735; {subproduct.name}
                          </TableCell>
                          <TableCell className="table-cell table-cell-no-data">
                            {subproduct.itemsSold}
                          </TableCell>
                          <TableCell className="table-cell table-cell-no-data">
                            {subproduct.revenue > 0 ? (
                              <NumberFormat
                                value={subproduct.revenue}
                                decimalScale={2}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                prefix={venueCurrency || '$'}
                              />
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </Fragment>
                ),
              )}
        </TableBody>
      </Table>
    </>
  );
}
