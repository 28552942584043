import axios from 'axios';
import { formatDistance } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { AsYouTypeFormatter } from 'google-libphonenumber';
import { get } from 'lodash';
import { CurrencyCode } from '../../models/Venue.model';

export const formatNumber = (userInput: string): string => {
  userInput = userInput || '';
  const formatter = new AsYouTypeFormatter('US');
  let formattedNumber = '';
  unMaskPhoneNumber(userInput)
    .split('')
    .forEach((digit: string) => {
      formattedNumber = formatter.inputDigit(digit);
    });
  return formattedNumber;
};

export const unMaskPhoneNumber = (ph: string): string => {
  return ph.replace(/[- )(]/g, '');
};

export const getHeadersObject = (headers: string[]) => {
  const headersObj = headers.reduce(
    (a, key) => Object.assign(a, { [key]: '' }),
    {},
  );
  return headersObj;
};

export const getDateString = (date: Date) => {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.toISOString();
};

export const getTimezone = (latitude: number, longitude: number) =>
  axios.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Date.now() /
      1000}&key=AIzaSyCrVw_k1LkxbdH_ZFAymLQ8y5kmz7A1Zro`,
  );

export const getTimezoneDate = (date: Date, timeZone?: string) => {
  if (!timeZone) {
    return date;
  }

  const zonedDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return zonedDate instanceof Date && !isNaN(zonedDate.getTime())
    ? zonedDate
    : date;
};

export const getUTCDate = (date: Date, timezone?: string) => {
  if (!timezone) {
    return date;
  }

  const utcTime = zonedTimeToUtc(date, timezone);
  return utcTime;
};

export const getVenueCurrency = (venueName: string) => {
  if (venueName && venueName.toLowerCase().includes('dubai')) {
    return 'AED';
  }

  return '$';
};

export const getCurrencyPrefix = (currencyCode?: CurrencyCode) => {
  if (!currencyCode) {
    return '$';
  }

  const currencyValues = {
    AED: 'AED',
    CAD: '$',
    USD: '$',
    ZAR: 'R',
  };

  return get(currencyValues, [currencyCode], '$');
};

export const getProductImage = (product: any) => {
  if (product.imageUrl) {
    return product.imageUrl;
  }
  if (product.globalProduct && product.globalProduct.imageUrl) {
    return product.globalProduct.imageUrl;
  }

  return undefined;
};

export const secsToMins = (timeInSecs: number) => Math.floor(timeInSecs / 60);

export const minsToSecs = (timeInMins: number) => Math.floor(timeInMins * 60);

export const collectSubProducts = (current: any[], collection: any[]): void => {
  current.forEach((ss: any) => {
    if (ss.subSelections) {
      collectSubProducts(ss.subSelections, collection);
    } else if (ss.type === 'PRODUCT') {
      collection.push(ss);
    }
  });
};

export const getTimeString = (seconds: number): string => {
  if (seconds === 0) {
    return 'N/A';
  }
  return formatDistance(0, seconds * 1000, { includeSeconds: true });
};
