import { UserVenueRole } from '../../models/User.model';

export const humanReadableRoles: { [key in UserVenueRole]: string } = {
  [UserVenueRole.VENUE_MANAGER]: 'Venue Manager',
  [UserVenueRole.STORE_MANAGER]: 'Store Manager',
  [UserVenueRole.STORE_EMPLOYEE]: 'Store Employee',
  [UserVenueRole.RUNNER]: 'Runner',
  [UserVenueRole.SUITE_ATTENDANT]: 'Suite Attendant',
  [UserVenueRole.SUITE_CUSTOMER]: 'Suite Customer',
  [UserVenueRole.POS_USER]: 'POS User',
};
export interface IUserAdmin {
  allEvents: boolean;
  countryCode: string;
  email: string;
  eventIds: string[];
  firstName: string;
  id?: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  storeIds: string[];
  suites?: string[];
}
