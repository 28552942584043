import React, { useState  , useEffect} from 'react';
import { Button, MenuItem, Select } from '@material-ui/core';
import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  InputLabel
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TabPanel } from '../../components/TabPanel';
import './Inventory.scss';
import ViewInventory from './ViewInventory/ViewInventory';
import AdjustmentLog from './AdjustmentLog/AdjustmentLog';
import AdjustInvetory from './InventoryAdjustment/AdjustInventory';
import { useAppContext } from '../../config/AppContext';
import { useLocation } from 'react-router-dom';

function Inventory() {
  const { stores } = useAppContext();
  const [value, setValue] = useState(0);
  const location = useLocation();
  const fullUrl = location.pathname;

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      if (fullUrl.includes('adjust_inventory') ) {
       setValue(2)
      }
      else{
        setValue(0)
      }
    })();
  }, [fullUrl]);
  return (
    <>
      {/* <div>
        {isViewInventory ? (
          <>
            <h2
              className=""
              style={{ marginLeft: '20px', textTransform: 'uppercase' }}
            >
              Inventory
            </h2>
            <div>
              <p style={{ textAlign: 'center' }}>
                What do you want to do with Inventory?
              </p>
              <div className="_buttom_container">
                <Button
                  variant="outlined"
                  className="_button_view"
                  onClick={onClickViewInventoryTabs}
                >
                  View Inventory
                </Button>
                <Button
                  variant="outlined"
                  className="_button_adjust"
                  onClick={onClickViewAdjustInventory}
                >
                  Adjust Inventory
                </Button>
                <Button variant="outlined" className="_button_reorder">
                  Re-order Inventory
                </Button>
              </div>
            </div>
          </>
        ) : null}
        <div></div>

        {isViewInventoryTabs ? (
          <>
            <Paper classes={{ root: 'graph-container-inventory-overview' }}>
              <Button
                variant="outlined"
                onClick={onClickBack}
                startIcon={<ArrowBackIcon />}
                className="back-button"
              >
                Back
              </Button>
              <Grid container={true} className="overview-heading">
                <h2
                  className=""
                  style={{ marginLeft: '20px', textTransform: 'uppercase' }}
                >
                  VIEW INVENTORY
                </h2>
              </Grid>
              <Tabs
                value={tabValue}
                onChange={(_, value) => setTabValue(value)}
                textColor="primary"
                classes={{
                  indicator: 'inventory-product-summary_tabs_selected',
                  root: 'inventory-product-summary__tabs',
                }}
              >
                <Tab
                  label="Inventory Levels"
                  classes={{
                    root: 'inventory-product-summary__tab',
                    selected: 'selected',
                  }}
                />
                <Tab
                  label="Adjustment Log"
                  classes={{
                    root: 'inventory-product-summary__tab',
                    selected: 'selected',
                  }}
                />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <ViewInventory stores={stores} />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <AdjustmentLog />
              </TabPanel>
              <div className="display-text">
                {'* Displaying only top 10 in each category'}
              </div>
            </Paper>
          </>
        ) : null}

        {isViewAdjustInventory ? (
          <>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<ArrowBackIcon />}
              className="back-button"
            ></Button>
            <AdjustInvetory stores={stores} />
          </>
        ) : null}
      </div> */}
      {/* <AdjustInvetory /> */}
      <div>
        <p style={{ textAlign: 'center' }}>
          What do you want to do with Inventory?
        </p>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="View Inventory" />
          <Tab label="Adjustment Log" />
          <Tab label="Adjust Inventory" />
        </Tabs>
        {value === 0 && (
          <div>
            <>
               <AdjustmentLog stores={stores} />
            </>
          </div>
        )}
        {value === 1 && (
          <div>
            <>
              <ViewInventory stores={stores} />
            </>
          </div>
        )}
         {value === 2 && (
          <div>
            <>
              <AdjustInvetory stores={stores} />
            </>
          </div>
        )}
      </div>
    </>
  );
}

export default Inventory;
