import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/auth`;

const sendOTP = (body: any) => axiosWithAuth.post(`${baseUrl}/sendotp`, body);

const verifyOTP = (body: any) =>
  axiosWithAuth.post(`${baseUrl}/verify-otp`, body);

const verifyCaptcha = (body: any) =>
  axiosWithAuth.post(`${baseUrl}/verify-captcha`, body);

export default {
  baseUrl,
  sendOTP,
  verifyCaptcha,
  verifyOTP,
};
