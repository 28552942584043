import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { ISecondaryTab } from '../../config/Common';
import './CustomTabs.scss';

interface ICustomTabProps {
  tabs: ISecondaryTab[];
}

const CustomTabs: React.FC<ICustomTabProps> = ({ tabs }: ICustomTabProps) => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  useEffect(() => {
    const index: number = tabs.findIndex(
      ({ route }: ISecondaryTab) => pathname.includes(route) && route !== '',
    );
    setSelectedTabIndex(index === -1 ? 0 : index);
  }, [pathname, tabs]);

  return (
    <div className="custom-tabs-container">
      <Tabs
        value={selectedTabIndex}
        onChange={onTabChange}
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        classes={{
          flexContainer: 'secondary-tabs-buttons-container',
          indicator: 'indicator',
          root: 'secondary-tabs-container',
        }}
      >
        {tabs.map(({ route, name }: ISecondaryTab, index: number) => (
          <Tab
            component={Link}
            to={`${url}${route}`}
            key={index}
            label={name}
            classes={{
              root: 'secondary-tab',
              selected: 'selectedTab',
            }}
          />
        ))}
      </Tabs>
    </div>
  );
};

export const CustomRoutes: React.FC<ICustomTabProps> = ({
  tabs,
  ...props
}: ICustomTabProps) => {
  const { path } = useRouteMatch();

  return (
    <>
      {tabs.map((tab: ISecondaryTab, tabIndex: number) => (
        <Route
          render={childProps => (
            <tab.component {...childProps} {...props} tab={tab} />
          )}
          exact={true}
          path={`${path}${tab.route}`}
          key={tabIndex}
        />
      ))}
    </>
  );
};

export default CustomTabs;
