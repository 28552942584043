import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/auth/twilio`;

const getAccessToken = (username: string) => {
  return axiosWithAuth.post(baseUrl, {
    username,
  });
};

export default {
  getAccessToken,
};
