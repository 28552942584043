import { IPromotionUser } from '../../models/PromotionUser.model';
import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/promotions`;

const create = (promotionId: string, promoUser: Partial<IPromotionUser>) =>
  axiosWithAuth.post(`${baseUrl}/${promotionId}/users`, promoUser);

const remove = (promotionId: string, promotionUserId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${promotionId}/users/${promotionUserId}`);

const getAll = async (
  promotionId: string,
  params: {
    email?: string;
    firstName?: string;
    lastName?: string;
    limit: number;
    page: number;
    phoneNumber?: string;
  },
) => axiosWithAuth.get(`${baseUrl}/${promotionId}/users`, { params });

const update = (
  promotionId: string,
  promotionUserId: string,
  promoUser: Partial<IPromotionUser>,
) =>
  axiosWithAuth.put(
    `${baseUrl}/${promotionId}/users/${promotionUserId}`,
    promoUser,
  );

  const addUserList = async (
    promotionId: string,
    promoUser: Partial<IPromotionUser[]>,
  ) =>{
    try{
      return axiosWithAuth.post(
        `${baseUrl}/${promotionId}/users/user-list`,
        promoUser)
    }
    catch(err){
      return (err);
    }
  }

    


export default {
  create,
  remove,
  getAll,
  update,
  addUserList
};
