import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import './Spinner.scss';

const Spinner = () => {
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="loading-spinner">
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={completed}
        className="spinner-custom"
      />
    </div>
  );
};

export default Spinner;
