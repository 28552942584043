import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { chain, sumBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ILineItem, {
  ILineItemSubSelection,
} from '../../../../models/LineItem.model';
import ILineItemGroup from '../../../../models/LineItemGroup.model';
import { IOrder } from '../../../../models/Order.model';
import {
  collectSubProducts,
  getCurrencyPrefix,
} from '../../../../services/Util/Util.service';
import {
  IOrderState,
  RefundReason,
  RefundReasons,
  RefundType,
} from '../../Orders.config';
import './RefundModal.scss';

interface IRefundDisplayItem {
  details?: string;
  displayIndex: number;
  lineItemGroupId: string;
  lineItemId: string;
  name: string;
  price: number;
  refund: boolean;
}

export interface IRefundItem {
  id: string;
  subProducts?: [];
  quantity: number;
  price: number;
  selectedQuantity: number;
}

interface IRefundModalProps {
  onClose: () => void;
  order: IOrder;
  orderStates: IOrderState[];
  refundOrder: (
    type: string,
    id: string,
    reason: string,
    lineItems: IRefundItem[],
  ) => Promise<void>;
}

const RefundModal = (props: IRefundModalProps) => {
  const { order, orderStates, onClose, refundOrder } = props;
  const { id, lineItems, currency } = order;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectAllItemsCheck, setSelectAllItemsCheck] = useState(false);
  const [refundReason, setRefundReason] = useState(
    RefundReason.REQUESTED_BY_CUSTOMER,
  );
  const [refundDisplayItems, setRefundDisplayItems] = useState<
    IRefundDisplayItem[]
  >([]);
  const [refundItems, setRefundItems] = useState<IRefundItem[]>([]);

  // Converts line items into display items.
  // useEffect(() => {
  //   if (!lineItems) {
  //     setRefundDisplayItems([]);
  //     return;
  //   }
  //   let displayIndex = 0;
  //   const displayItems = lineItems.reduce(
  //     (allItems: any[], currentLineItem: any) => {
  //       const { name, price } = currentLineItem.product;
  //       console.log(currentLineItem ,'currentLineItem')
  //       // tslint:disable-next-line: prefer-const
  //       let subProducts: ILineItemSubSelection[] = [];
  //       collectSubProducts(currentLineItem.subSelections || [], subProducts);
  //       const details =
  //         subProducts.length > 0
  //           ? subProducts
  //               .map((sp: ILineItemSubSelection) => sp.name.toLowerCase())
  //               .join(', ')
  //           : undefined;
  //       const subProductsAlongIds =
  //         subProducts.length > 0
  //           ? subProducts.map((sp: any) => ({
  //               name: sp.name.toLowerCase(),
  //               productId: sp.productId,
  //               id: sp.id,
  //               refund: false,
  //               price: sp.price,
  //               quantity: sp.quantity * currentLineItem.quantity,
  //             }))
  //           : [];
  //       const quantitiesArray = new Array(
  //         currentLineItem.quantity + currentLineItem.quantityRefunded,
  //       );
  //       quantitiesArray.fill(
  //         currentLineItem.quantity + currentLineItem.quantityRefunded,
  //       );

  //       quantitiesArray.forEach(() => {
  //         console.log(displayIndex ,'displayIndex')
  //         let totalisRefunded = 0;
  //         const updatedItems = subProductsAlongIds.map((e: any) => {
  //           const isRefunded = currentLineItem?.refundSubProducts?.some(
  //             (refundSubProduct: any) => {
  //               return (
  //                 refundSubProduct.subProductIndex === displayIndex &&
  //                 refundSubProduct.productId === e.productId
  //               );
  //             },
  //           );
  //           if (isRefunded) totalisRefunded += 1;
  //           return {
  //             ...e,
  //             alreadyRefunded: isRefunded,
  //             subProductIndex: displayIndex,
  //           };
  //         });
  //         console.log(totalisRefunded ,'totalisRefunded')
  //         allItems.push({
  //           details,
  //           subProductsAlongIds: updatedItems,
  //           displayIndex,
  //           lineItemGroupId: currentLineItem.lineItemGroupId,
  //           lineItemId: currentLineItem.id,
  //           name,
  //           price: price,
  //           refund: false,
  //           alreadyRefunded:
  //           totalisRefunded === updatedItems?.length &&
  //             subProductsAlongIds?.length > 0
  //               ? true
  //               : false,
  //         });
  //         displayIndex++;
  //       });

  //       return allItems;
  //     },
  //     [],
  //   );

  //   setRefundDisplayItems(displayItems);
  // }, []);
  useEffect(() => {
    if (!lineItems) {
      setRefundDisplayItems([]);
      return;
    }

    let displayIndex = 0;
    const displayItems = lineItems.reduce(
      (allItems: any[], currentLineItem: any) => {
        // if (currentLineItem.quantity > 0) {
        const { name, price } = currentLineItem.product;
        let subProducts: ILineItemSubSelection[] = [];
        collectSubProducts(currentLineItem.subSelections || [], subProducts);
        const details =
          subProducts.length > 0
            ? subProducts
                .map((sp: ILineItemSubSelection) => sp.name.toLowerCase())
                .join(', ')
            : undefined;
        const subProductsAlongIds =
          subProducts.length > 0
            ? subProducts.map((sp: any, index: number) => ({
                name: sp.name.toLowerCase(),
                productId: sp.productId,
                id: sp.id,
                refund: false,
                price: sp.price,
                quantity:
                  sp.quantity *
                  (currentLineItem.quantity + currentLineItem.quantityRefunded),
                selectedQuantity: 0,
                subProductIndex: index,
              }))
            : [];

        if (currentLineItem?.refundSubProducts?.length > 0) {
          currentLineItem?.refundSubProducts.forEach((refundedProduct: any) => {
            const { productId, quantity } = refundedProduct;
            const matchingSubProduct = subProductsAlongIds.find(
              subProduct => subProduct.productId === productId,
            );
            if (matchingSubProduct) {
              matchingSubProduct.quantity -= quantity;
            }
          });
        }

        const updatedSubProductsAlongIds = subProductsAlongIds?.filter(
          subProduct => subProduct?.quantity > 0,
        );
        if (
          updatedSubProductsAlongIds.length > 0 ||
          currentLineItem.quantity > 0
        ) {
          allItems.push({
            details,
            subProductsAlongIds: updatedSubProductsAlongIds,
            displayIndex,
            lineItemGroupId: currentLineItem.lineItemGroupId,
            lineItemId: currentLineItem.id,
            name,
            price: price,
            refund: false,
            quantity: currentLineItem.quantity,
            selectedQuantity: 0,
          });
          displayIndex++;
        }
        return allItems;
      },
      [],
    );

    setRefundDisplayItems(displayItems);
  }, []);

  // Coverts display items into items to refund.
  useEffect(() => {
    const itemsToRefund: IRefundItem[] = [];

    refundDisplayItems
      .filter(
        (rdi: any) =>
          rdi.refund ||
          (rdi?.subProductsAlongIds &&
            rdi.subProductsAlongIds.some(
              (subProduct: any) => subProduct?.refund,
            )),
      )
      .forEach((rdi: any) => {
        const foundItem: any = itemsToRefund.find(
          (ri: any) => ri.id === rdi.lineItemId,
        );
        const subProductsToRefund: any = rdi?.subProductsAlongIds
          .filter((e: any) => e?.refund)
          .map((e: any) => ({
            productId: e.productId,
            quantity: 1,
            price: e.price,
            selectedQuantity: e.selectedQuantity,
          }));
        if (!!foundItem) {
          foundItem.quantity += 1;
          rdi?.subProductsAlongIds
            .filter((e: any) => e?.refund)
            .forEach((subProduct: any) => {
              foundItem.subProducts.push({
                productId: subProduct.productId,
                quantity: 1,
                price: subProduct.price,
                selectedQuantity: subProduct.selectedQuantity,
              });
            });
        } else {
          itemsToRefund.push({
            id: rdi.lineItemId,
            quantity: rdi.selectedQuantity,
            price: rdi.price,
            subProducts: subProductsToRefund,
            selectedQuantity: rdi.selectedQuantity,
          });

          // itemsToRefund.push({
          //   id: rdi.lineItemId,
          //   quantity:
          //     rdi?.subProductsAlongIds.length > 0
          //       ? rdi?.subProductsAlongIds.length ===
          //         subProductsToRefund?.length
          //         ? 1
          //         : 0
          //       : 1,
          //   subProducts: subProductsToRefund,
          //   selectedQuantity: rdi.selectedQuantity,
          // });
        }
      });
    setRefundItems(itemsToRefund);
  }, [refundDisplayItems]);

  const onRefundClick = async () => {
    const totalOrderQty = sumBy(lineItems, (li: ILineItem) => li.quantity);
    let totalLineItemSubProductQty = 0;
    lineItems.forEach((lineItem: any) => {
      for (const subSelectionCategory of lineItem.subSelections) {
        for (const subSelection of subSelectionCategory.subSelections) {
          totalLineItemSubProductQty +=
            subSelection.quantity * lineItem.quantity;
        }
      }
    });
    const duplicateRefundItems = refundItems?.map(product => {
      const copyP = { ...product };
      copyP.quantity = product.selectedQuantity;
      copyP?.subProducts?.map((subProduct: any) => {
        subProduct.quantity = subProduct.selectedQuantity;
        delete subProduct?.selectedQuantity;
      });
      return copyP;
    });
    const totalRefundItemSubProductQty = duplicateRefundItems.reduce(
      (accumulator: number, ri: any) => {
        const subProductQty = ri.subProducts.reduce(
          (subAccumulator: number, subProduct: any) => {
            return subAccumulator + subProduct.quantity;
          },
          0,
        );

        return accumulator + subProductQty;
      },
      0,
    );
    const totalRefundQty = sumBy(
      duplicateRefundItems,
      (ri: any) => ri.quantity,
    );
    let refundType =
      totalRefundQty < totalOrderQty ||
      totalRefundItemSubProductQty < totalLineItemSubProductQty
        ? RefundType.PARTIAL
        : RefundType.FULL;
    if (totalRefundItemSubProductQty > totalLineItemSubProductQty) {
      refundType = RefundType.PARTIAL;
    }
    try {
      setIsLoading(true);
      await refundOrder(refundType, id, refundReason, duplicateRefundItems);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  // Before change
  // const handleCheck = (isRefund: boolean, displayIndex: number) => {
  //   console.log(isRefund ,'isRefund')
  //   const checkItemIndex = refundDisplayItems.findIndex(
  //     (rdi: IRefundDisplayItem) => rdi.displayIndex === displayIndex,
  //   );
  //   if (checkItemIndex >= 0) {
  //     const updatedItem = Object.assign(refundDisplayItems[checkItemIndex], {
  //       refund: isRefund,
  //     });
  //     setRefundDisplayItems(
  //       Object.assign([...refundDisplayItems], {
  //         [checkItemIndex]: updatedItem,
  //       }),
  //     );
  //   }
  //   const isAllItemsSelected = refundDisplayItems.every(
  //     (rdi: IRefundDisplayItem) => rdi.refund,
  //   );
  //   setSelectAllItemsCheck(isAllItemsSelected);
  // };
  // After Change
  const handleCheck = (isRefund: boolean, displayIndex: number) => {
    const updatedRefundDisplayItems = refundDisplayItems.map((rdi: any) => {
      if (rdi.displayIndex === displayIndex) {
        rdi.refund = isRefund;
        if (!isRefund) rdi.selectedQuantity = 0;
        else if (isRefund) {
          rdi.selectedQuantity = 1;
        }
      }
      return rdi;
    });
    setRefundDisplayItems(updatedRefundDisplayItems);

    const isAllItemsSelected = updatedRefundDisplayItems.every((rdi: any) => {
      if (rdi?.subProductsAlongIds?.length > 0) {
        return rdi.subProductsAlongIds.every((subProduct: any) => {
          return subProduct?.refund && rdi.refund;
        });
      } else {
        return rdi.refund;
      }
    });
    setSelectAllItemsCheck(isAllItemsSelected);
  };
  const handleCheckSubProduct = (
    isRefund: boolean,
    productId: string,
    displayIndex: number,
    subProductIndex: number,
  ) => {
    const updatedRefundDisplayItems = refundDisplayItems.map((rdi: any) => {
      if (rdi.displayIndex === displayIndex) {
        if (rdi.subProductsAlongIds.length > 0) {
          rdi.subProductsAlongIds.forEach((subItem: any) => {
            if (
              subItem.productId === productId &&
              subItem.subProductIndex === subProductIndex
            ) {
              subItem.refund = isRefund;
              if (!isRefund) {
                subItem.selectedQuantity = 0;
                // rdi.selectedQuantity = 0;
              } else if (isRefund) {
                subItem.selectedQuantity = 1;
              }
            }
          });
        }
      }
      return rdi;
    });
    setRefundDisplayItems(updatedRefundDisplayItems);
    const isAllItemsSelected = updatedRefundDisplayItems.every((rdi: any) => {
      return (
        rdi.subProductsAlongIds.every((subProduct: any) => subProduct.refund) &&
        rdi.refund
      );
    });
    setSelectAllItemsCheck(isAllItemsSelected);
  };
  // Old to handle all Items to select
  // const handleSelectAll = (isSelectAll: boolean) => {
  //   const newItems = refundDisplayItems.map((rdi: IRefundDisplayItem) =>
  //     Object.assign(rdi, { refund: isSelectAll }),
  //   );

  //   setSelectAllItemsCheck(isSelectAll);
  //   setRefundDisplayItems(newItems);
  // };

  // const handleSelectAll = (isSelectAll: boolean) => {
  //   const newItems = refundDisplayItems.map((rdi: any) => {
  //     const updatedItem = { ...rdi, refund: isSelectAll };
  //     if (updatedItem.subProductsAlongIds.length > 0) {
  //       updatedItem.subProductsAlongIds = updatedItem.subProductsAlongIds.map(
  //         (subItem: any) => {
  //           return { ...subItem, refund: isSelectAll };
  //         },
  //       );
  //     }
  //     return updatedItem;
  //   });
  //   setSelectAllItemsCheck(isSelectAll);
  //   setRefundDisplayItems(newItems);
  // };

  const handleSelectAll = (isSelectAll: boolean) => {
    const newItems = refundDisplayItems.map((rdi: any) => {
      const updatedItem = { ...rdi, refund: isSelectAll, selectedQuantity: 1 };
      if (updatedItem.subProductsAlongIds.length > 0) {
        updatedItem.subProductsAlongIds = updatedItem.subProductsAlongIds.map(
          (subItem: any) => {
            return { ...subItem, refund: isSelectAll, selectedQuantity: 1 };
          },
        );
      }
      return updatedItem;
    });
    setSelectAllItemsCheck(isSelectAll);
    setRefundDisplayItems(newItems);
  };
  const decrementItem = (item: any) => {
    const newItems = refundDisplayItems.map((rdi: any) => {
      if (
        rdi.lineItemId === item.lineItemId &&
        item.selectedQuantity !== 0 &&
        item.displayIndex === rdi.displayIndex
      ) {
        rdi.selectedQuantity -= 1;
        if (rdi.selectedQuantity === 0) {
          rdi.refund = false;
        }
      }
      return rdi;
    });
    setRefundDisplayItems(newItems);
  };
  const incrementItem = (item: any) => {
    const newItems = refundDisplayItems.map((rdi: any) => {
      if (
        rdi.lineItemId === item.lineItemId &&
        rdi.quantity! > item.selectedQuantity &&
        item.displayIndex === rdi.displayIndex
      ) {
        rdi.selectedQuantity += 1;
      }
      return rdi;
    });
    setRefundDisplayItems(newItems);
  };
  const decrementSubItem = (item: any, productId: string) => {
    const newItems = refundDisplayItems.map((rdi: any) => {
      if (
        rdi.lineItemId === item.lineItemId &&
        item.displayIndex === rdi.displayIndex
      ) {
        rdi?.subProductsAlongIds?.map((subProduct: any) => {
          if (
            subProduct?.productId === productId &&
            subProduct?.selectedQuantity !== 0
          ) {
            subProduct.selectedQuantity -= 1;
            if (subProduct.selectedQuantity === 0) {
              subProduct.refund = false;
            }
          }
        });
      }
      return rdi;
    });
    setRefundDisplayItems(newItems);
  };
  const incrementSubItem = (item: any, productId: string) => {
    const newItems = refundDisplayItems.map((rdi: any) => {
      if (
        rdi.lineItemId === item.lineItemId &&
        item.displayIndex === rdi.displayIndex
      ) {
        rdi?.subProductsAlongIds?.map((subProduct: any) => {
          if (
            subProduct?.selectedQuantity < subProduct.quantity &&
            subProduct?.productId === productId
          ) {
            subProduct.selectedQuantity += 1;
          }
        });
      }
      return rdi;
    });
    setRefundDisplayItems(newItems);
  };
  return (
    <Dialog
      className="refund-modal-container"
      open={true}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle className="refund-title-container">
        <Typography className="new-section-title">
          Please select items to refund:
        </Typography>
        <IconButton
          aria-label="close"
          className="modal-close-button"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="modal-content-container">
        <div
          onClick={() => handleSelectAll(!selectAllItemsCheck)}
          className="partial-name select-all"
        >
          <Checkbox
            className={`checkbox ${
              selectAllItemsCheck ? 'custom-checkbox-color' : ''
            }`}
            checked={selectAllItemsCheck}
          />
          <span>Select All</span>
        </div>
        <div className="partial-order-line-items">
          {chain(refundDisplayItems)
            .groupBy((item: IRefundDisplayItem) => item.lineItemGroupId)
            .map((items: IRefundDisplayItem[], lineItemGroupId: string) => {
              const lineItemGroup =
                order.lineItemGroups &&
                order.lineItemGroups.find(
                  (lig: ILineItemGroup) => lig.id === lineItemGroupId,
                );
              if (!lineItemGroup) {
                return <Fragment key={lineItemGroupId} />;
              }

              const { value: status } = orderStates.find(
                (state: IOrderState) =>
                  (state.type ? state.type === order.type : true) &&
                  state.status === lineItemGroup.status,
              )!;
              const fcName =
                lineItemGroup.fulfillmentCenter &&
                lineItemGroup.fulfillmentCenter.name;
              return (
                <Fragment key={lineItemGroupId}>
                  <span>
                    {`${fcName ? `${fcName} | ` : ''}status: ${status}`}
                  </span>
                  {items.map((item: any, ii: number) => (
                    <div className="refund-item-container" key={ii}>
                      <div className="refund-name-number">
                        <>
                          <div className="partial-name">
                            {item.quantity > 0 && (
                              <Checkbox
                                className={`checkbox ${
                                  item.refund ? 'custom-checkbox-color' : ''
                                }`}
                                style={{ margin: '1px' }}
                                onClick={() =>
                                  handleCheck(!item.refund, item.displayIndex)
                                }
                                checked={item.refund}
                              />
                            )}

                            <span>{item.name}</span>
                          </div>
                        </>

                        <span
                          className="refund-price"
                          style={{ display: 'flex' }}
                        >
                          {item.refund && (
                            <div
                              className=""
                              style={{ display: 'flex', marginRight: '18px' }}
                            >
                              <div className="_item_button">
                                <button
                                  className="_cart_item_add"
                                  style={{}}
                                  onClick={() => {
                                    decrementItem(item);
                                  }}
                                >
                                  -
                                </button>
                              </div>
                              <div className="_item_button">
                                <p
                                  style={{
                                    position: 'relative',
                                    margin: '1px',
                                  }}
                                >
                                  {item.selectedQuantity}x
                                </p>
                              </div>
                              <div className="_item_button">
                                <button
                                  className="_cart_item_add"
                                  style={{}}
                                  onClick={() => {
                                    incrementItem(item);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          )}
                          <NumberFormat
                            value={item.price}
                            decimalScale={2}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            prefix={getCurrencyPrefix(currency)}
                          />
                        </span>
                      </div>
                      {item?.subProductsAlongIds?.length > 0 &&
                        item?.subProductsAlongIds?.map((e: any, j: number) => (
                          <div
                            className="refund-name-number-sub-product"
                            key={j}
                          >
                            <div className="partial-name">
                              <>
                                <>
                                  <span className="refund-subselections">{`-`}</span>
                                  <Checkbox
                                    className={`checkbox ${
                                      e?.refund ? 'custom-checkbox-color' : ''
                                    }`}
                                    checked={e?.refund}
                                    onClick={() =>
                                      handleCheckSubProduct(
                                        !e.refund,
                                        e?.productId,
                                        item?.displayIndex,
                                        e?.subProductIndex,
                                      )
                                    }
                                  />
                                  <span>{e?.name}</span>
                                </>
                              </>
                            </div>

                            <span
                              className="refund-price"
                              style={{ display: 'flex' }}
                            >
                              {e.refund && (
                                <div
                                  className="_item_buttons"
                                  style={{
                                    display: 'flex',
                                    marginRight: '20px',
                                  }}
                                >
                                  <div className="_item_button">
                                    <button
                                      className="_cart_item_add"
                                      style={{}}
                                      onClick={() => {
                                        decrementSubItem(item, e?.productId);
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>
                                  <div className="_item_button">
                                    <p
                                      style={{
                                        position: 'relative',
                                        margin: '1px',
                                      }}
                                    >
                                      {e.selectedQuantity}x
                                    </p>
                                  </div>
                                  <div className="_item_button">
                                    <button
                                      className="_cart_item_add"
                                      style={{}}
                                      onClick={() => {
                                        incrementSubItem(item, e?.productId);
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              )}
                              <NumberFormat
                                value={e?.price}
                                decimalScale={2}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                prefix={getCurrencyPrefix(currency)}
                              />
                            </span>
                          </div>
                        ))}
                    </div>
                  ))}
                </Fragment>
              );
            })
            .value()}
        </div>
        <Box className="refund-reason-container">
          <Typography component="h2" className="new-section-title">
            Reason for the refund:
          </Typography>
          <div className="required">Required</div>
          <Box className="refund-modal-buttons-container">
            {RefundReasons.map((menuItem: any, ii: number) => (
              <Button
                className={ii !== 0 ? 'button-left-margin' : ''}
                key={ii}
                variant={
                  refundReason === menuItem.key ? 'contained' : 'outlined'
                }
                color="primary"
                onClick={() => setRefundReason(menuItem.key)}
              >
                {menuItem.name}
              </Button>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className="refund-actions-container">
        <Button
          type="submit"
          className="refund-button"
          variant="contained"
          onClick={onRefundClick}
          size="small"
          disabled={
            isLoading 
            ||
            // refundDisplayItems.every((item: IRefundDisplayItem) => !item.refund)
            refundItems.length < 1
          }
        >
          <div className="icon-title-wrapper">
            <span className="icon-title">REFUND ITEMS</span>
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundModal;
