import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import { format } from 'date-fns';
import { get } from 'lodash';
import React from 'react';
import { noDataAvailable } from '../../components/NoDataAvailable';
import { useAppContext } from '../../config/AppContext';
import { IEvent } from '../../models/Event.model';
import { getTimezoneDate } from '../../services/Util/Util.service';
import './Events.scss';

interface IEventsTable {
  events: IEvent[];
  selectEvent: (event: IEvent) => void;
  deleteEvent: (event: IEvent) => void;
  cloneEvent: (event: IEvent) => Promise<void>;
}

const EventsTable = ({
  events,
  selectEvent,
  deleteEvent,
  cloneEvent,
}: IEventsTable) => {
  const { venue } = useAppContext();

  const constructDate = (date: any) => {
    const timezone = get(venue, 'timezone', '');
    return format(
      getTimezoneDate(new Date(date), timezone),
      'MM-dd-yyyy hh:mm a',
    );
  };

  if (!venue) {
    return null;
  }

  return (
    <div className="events-table-container">
      <Table>
        <TableHead>
          <TableRow
            classes={{
              root: 'table-row',
            }}
          >
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Title
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              {venue.type === 'SERVICE_PROVIDER' ? 'Location' : 'Description'}
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Start Date/Time
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Last Order Delivery Date/Time
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Cut-Off Time (in Hours)
            </TableCell>
            <TableCell classes={{ root: 'table-cell-action table-cell-head' }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.length
            ? events.map((event: IEvent, index: number) => (
                <TableRow
                  key={index}
                  id={event.id}
                  className="events-table-row"
                >
                  <TableCell
                    onClick={() => selectEvent(event)}
                    className="table-cell table-cell-name"
                  >
                    {get(event, 'name')}
                  </TableCell>
                  <TableCell className="table-cell">
                    {venue.type === 'SERVICE_PROVIDER'
                      ? get(event, 'buildingVenue.name')
                      : get(event, 'description')}
                  </TableCell>
                  <TableCell className="table-cell">
                    {constructDate(get(event, 'startDate'))}
                  </TableCell>
                  <TableCell className="table-cell">
                    {constructDate(get(event, 'endDate'))}
                  </TableCell>
                  <TableCell className="table-cell cutOffTime">
                    {get(event, 'advanceOrderWindow') / 60}
                  </TableCell>
                  <TableCell classes={{ root: 'table-cell-action' }}>
                    <Button
                      size="small"
                      classes={{ root: 'events-table-button' }}
                      onClick={() => cloneEvent(event)}
                    >
                      <div className="icon-title-wrapper">
                        <FilterNoneOutlinedIcon />
                        <span className="icon-title">Clone</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      value="delete"
                      onClick={() => {
                        deleteEvent(event);
                      }}
                      classes={{
                        root: 'events-table-button',
                      }}
                    >
                      <div className="icon-title-wrapper">
                        <DeleteOutlinedIcon />
                        <span className="icon-title">Delete</span>
                      </div>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : noDataAvailable(6)}
        </TableBody>
      </Table>
    </div>
  );
};

export default EventsTable;
