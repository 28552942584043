import {
  Badge,
  Button,
  ListItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useAppContext } from '../../config/AppContext';
import ChatIcon from '../../images/chat_btn.png';
import FanfoodLogo from '../../images/logo_red.png';
import { canChangeVenues } from '../../services/Auth.service';
import { getVenueWebLink } from '../../services/WebLinks.service';
import './Home.scss';

const Navbar = (): JSX.Element => {
  const [
    showNotifications,
    setShowNotifications,
  ] = React.useState<HTMLButtonElement | null>(null);

  const { unreadMessages, user, venue } = useAppContext();
  const history = useHistory();

  const isVenueChangeable = canChangeVenues(user);

  const handleOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const hasUnreadMessages: boolean = Object.keys(unreadMessages).length > 0;
    if (hasUnreadMessages) {
      setShowNotifications(get(event, 'currentTarget'));
    } else if (venue) {
      history.push(`/home/venue-details/${venue.id}/support`);
    }
  };

  return (
    <div className="app-header">
      <div className="logo-venue">
        <img className="fanfood-logo" src={FanfoodLogo} alt="Fanfood" />
        {venue && (
          <div className="venue">
            {venue.name}
            {isVenueChangeable && venue.id && (
              <Button
                className="btn-link"
                component="button"
                onClick={() => {
                  history.push('/home/venues');
                }}
              >
                Change
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="header-right">
        {venue && venue.active && (
          <Button
            className="btn-link"
            href={getVenueWebLink(venue.id)}
            target="_blank"
          >
            View Venue In Web
          </Button>
        )}
        {user && (
          <Typography classes={{ root: 'greeting-text' }}>
            Hi, {user.firstName}!
          </Typography>
        )}
        {venue && (
          <Badge
            badgeContent={Object.keys(unreadMessages).length}
            classes={{ badge: 'menu__chat__number' }}
            color="primary"
            invisible={Object.keys(unreadMessages).length === 0}
          >
            <Button
              classes={{
                label: 'menu-button-label menu-button-chat-color',
              }}
              onClick={handleOpenPopover}
            >
              <div className="menu__chat">
                <img className="chat-icon" src={ChatIcon} alt="chat-icon" />
              </div>
            </Button>
            <Popover
              anchorEl={showNotifications}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              open={Boolean(showNotifications)}
              onClose={() => setShowNotifications(null)}
            >
              {Object.values(unreadMessages).map((message: any, i: number) => (
                <ListItem
                  onClick={() =>
                    history.push(`/home/venue-details/${venue.id}/support`, [
                      { storeId: get(message, 'storeId') },
                    ])
                  }
                  divider={true}
                  key={`${message}__${i}`}
                >
                  <Typography className="menu__chat__messages" variant="h6">
                    {get(message, 'order')}: You have a new message
                  </Typography>
                </ListItem>
              ))}
            </Popover>
          </Badge>
        )}
      </div>
    </div>
  );
};

export default withRouter(Navbar);
