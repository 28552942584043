import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ILineItem, {
  ILineItemSubSelection,
} from '../../../models/LineItem.model';
import { IOrder } from '../../../models/Order.model';
import {
  collectSubProducts,
  getCurrencyPrefix,
} from '../../../services/Util/Util.service';

import './OrderDetailsDialog.scss';

interface IOrderDetailsDialogProps {
  onClose: () => void;
  order: IOrder;
}

const OrderDetailsDialog = (props: IOrderDetailsDialogProps) => {
  const { order, onClose } = props;

  const [PhoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!isEmpty(get(order, 'user'))) {
      const phoneNumber = getInternationalPhoneNumber();
      setPhoneNumber(phoneNumber);
    }
  }, [order]);

  const getInternationalPhoneNumber = () => {
    const PNF = require('google-libphonenumber').PhoneNumberFormat;
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    const phone = phoneUtil.parseAndKeepRawInput(
      '+' +
        get(order, 'user.countryCode', '') +
        get(order, 'user.phoneNumber', ''),
      '',
    );
    return phoneUtil.format(phone, PNF.INTERNATIONAL);
  };
  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <span className="section-title">Order Details - #{order.orderNo}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} justify="flex-start">
          {!isEmpty(get(order, 'user')) && (
            <>
              <div className="line-item-headers">
                <Typography
                  variant="h5"
                  className="line-item-headers__customer"
                >
                  Customer
                </Typography>
              </div>
              <div className="customer-details-section">
                <div className="customer-detail">
                  {`${get(order, 'user.firstName', '')} ${get(
                    order,
                    'user.lastName',
                    '',
                  )}`}
                </div>
                <div className="customer-detail">{PhoneNumber}</div>
                <div className="customer-detail">
                  {get(order, 'user.email', '')}
                </div>
              </div>
            </>
          )}
          {!isEmpty(get(order, 'event')) && (
            <>
              <div className="line-item-headers">
                <Typography
                  variant="h5"
                  className="line-item-headers__customer"
                >
                  Event
                </Typography>
              </div>
              <div className="customer-details-section">
                <div className="customer-detail">
                  {get(order, 'event.name', '')}
                </div>
              </div>
            </>
          )}
          {!isEmpty(get(order, 'note')) && (
            <>
              <div className="customer-detai ls-section">
                <div className="customer-detail">
                  Note {get(order, 'note', '')}
                </div>
              </div>
            </>
          )}
          <div className="line-item-headers">
            <Typography variant="h5" className="line-item-headers__item">
              Item(s)
            </Typography>
            <Typography variant="h5" className="line-item-headers__price">
              Cost
            </Typography>
          </div>
          {!isEmpty(order) &&
            order.lineItems.map((lineItem: ILineItem) => {
              // tslint:disable-next-line: prefer-const
              let subProducts: ILineItemSubSelection[] = [];
              collectSubProducts(lineItem.subSelections || [], subProducts);

              return (
                <Fragment key={lineItem.id}>
                  <Grid key={lineItem.id} item={true} xs={8}>
                    <div className="line-item-details-section">
                      <div className="line-item-details__item-name">
                        {lineItem.quantity >= 1 ? (
                          <div>
                            {lineItem.product.name} x{lineItem.quantity}
                            {lineItem.quantityRefunded > 0 && (
                              <span
                                style={{
                                  color: '#e33b3b',
                                  textDecoration: 'line-through',
                                  fontSize: '1.1rem',
                                }}
                              >
                                (Refunded x{lineItem.quantityRefunded})
                              </span>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              color: '#e33b3b',
                              textDecoration: 'line-through',
                              fontSize: '1.1rem',
                            }}
                          >
                            {lineItem.product.name} (x
                            {lineItem.quantityRefunded})
                          </div>
                        )}
                        {subProducts.length > 0 && (
                          <div>
                            {subProducts.map(
                              (sp: ILineItemSubSelection, ii: number) => {
                                const category = lineItem.subSelections.find(
                                  (ss: ILineItemSubSelection) =>
                                    ss.id === sp.parentId,
                                );
                                let refundedProductCount = 0;
                                let refundedSubProductPrice = 0;
                                lineItem?.refundSubProducts?.map(e => {
                                  if (e?.productId === sp.productId) {
                                    refundedProductCount += e.quantity;
                                    refundedSubProductPrice +=
                                      e.quantity * e.price;
                                  }
                                });
                                let displayPrice =
                                  sp.price *
                                    (lineItem.quantity +
                                      lineItem.quantityRefunded) -
                                  refundedSubProductPrice;
                                const displayName =
                                  refundedProductCount > 0
                                    ? `${sp.name} (Refunded x ${refundedProductCount})`
                                    : sp.name;
                                if (
                                  sp.quantity *
                                    (lineItem.quantity +
                                      lineItem.quantityRefunded) -
                                    refundedProductCount ==
                                  0
                                ) {
                                  return (
                                    <p
                                      className="line-item-details__combo-details"
                                      style={{
                                        color: '#e33b3b',
                                        textDecoration: 'line-through',
                                      }}
                                      key={ii}
                                    >
                                      {`${category ? `${category.name}: ` : ''}
                                      ${sp.name} x${refundedProductCount}
                                      `}
                                    </p>
                                  );
                                } else {
                                  return (
                                    <p
                                      className="line-item-details__combo-details"
                                      key={ii}
                                    >
                                      {`${
                                        category ? `${category.name}: ` : ''
                                      }${displayName}${
                                        sp.quantity > 1
                                          ? ` (x${sp.quantity})`
                                          : ''
                                      }`}
                                    </p>
                                  );
                                }
                              },
                            )}
                          </div>
                        )}
                        {lineItem.instructions && (
                          <div className="line-item-details__instructions">
                            - {lineItem.instructions}
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid key={`${lineItem.id}_pricing`} item={true} xs={4}>
                    <div className="line-item-details-section pricing">
                      <div
                        className="line-item-pricing"
                        style={{
                          color: lineItem.quantity > 0 ? '' : '#e33b3b',
                        }}
                      >
                        <NumberFormat
                          value={lineItem.product.price * lineItem.quantity}
                          decimalScale={2}
                          displayType={'text'}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          prefix={getCurrencyPrefix(order.currency)}
                        />
                      </div>

                      {subProducts.length > 0 && (
                        <div>
                          {subProducts.map(
                            (sp: ILineItemSubSelection, ii: number) => {
                              const category = lineItem.subSelections.find(
                                (ss: ILineItemSubSelection) =>
                                  ss.id === sp.parentId,
                              );
                              let refundedProductCount = 0;
                              let refundedSubProductPrice = 0;
                              lineItem?.refundSubProducts?.map(e => {
                                if (e?.productId === sp.productId) {
                                  refundedProductCount += e.quantity;
                                  refundedSubProductPrice +=
                                    e.quantity * e.price;
                                }
                              });

                              let displayPrice = (
                                +sp.price *
                                  (+lineItem.quantity +
                                    +lineItem.quantityRefunded) -
                                +refundedSubProductPrice
                              ).toFixed(2);
                              return (
                                <p
                                  className="line-item-details__combo-details"
                                  key={ii}
                                  style={{
                                    color:
                                      refundedProductCount -
                                        (lineItem.quantity +
                                          lineItem.quantityRefunded) *
                                          sp.quantity ===
                                      0
                                        ? '#e33b3b'
                                        : '',
                                  }}
                                >
                                  {`$${displayPrice}`}
                                </p>
                              );
                            },
                          )}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Fragment>
              );
            })}
          {!isEmpty(get(order, 'instructions', '')) && (
            <div className="order-notes">
              NOTES: {get(order, 'instructions', '')}
            </div>
          )}
        </Grid>
        <hr />
        <div className="pricing-details-section">
          <div className="individual-pricing">
            <div className="pricing-units">Subtotal: </div>
            <div className="pricing-values">
              {!isEmpty(order) && (
                <NumberFormat
                  value={order.subTotal}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={getCurrencyPrefix(order.currency)}
                />
              )}
            </div>
          </div>
          <div className="individual-pricing">
            <div
              className={`pricing-units ${
                get(order, 'discountAmt') === 0 ? '' : 'discount'
              }`}
            >
              Discount:
            </div>
            <div
              className={`pricing-values ${
                get(order, 'discountAmt') === 0 ? '' : 'discount'
              }`}
            >
              {!isEmpty(order) && (
                <>
                  {get(order, 'discountAmt') === 0 ? null : <span>-</span>}
                  <NumberFormat
                    value={order.discountAmt}
                    decimalScale={2}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={getCurrencyPrefix(order.currency)}
                  />
                </>
              )}
            </div>
          </div>
          <div className="individual-pricing">
            <div className="pricing-units">Service Charge: </div>
            <div className="pricing-values">
              {!isEmpty(order) && (
                <NumberFormat
                  value={order.convenienceFee}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={getCurrencyPrefix(order.currency)}
                />
              )}
            </div>
          </div>
          <div className="individual-pricing">
            <div className="pricing-units">Tip: </div>
            <div className="pricing-values">
              {!isEmpty(order) && (
                <NumberFormat
                  value={order.tipAmt}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={getCurrencyPrefix(order.currency)}
                />
              )}
            </div>
          </div>
          {order.taxCalculated && (
            <div className="individual-pricing">
              <div className="pricing-units">Tax: </div>
              <div className="pricing-values">
                {!isEmpty(order) && (
                  <NumberFormat
                    value={order.taxAmt}
                    decimalScale={2}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={getCurrencyPrefix(order.currency)}
                  />
                )}
              </div>
            </div>
          )}
          <div className="individual-pricing">
            <div className="pricing-units total">TOTAL: </div>
            <div className="pricing-values total">
              {!isEmpty(order) && (
                <NumberFormat
                  value={order.total}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={getCurrencyPrefix(order.currency)}
                />
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <div className="button-holder-similar-margin">
        <DialogActions>
          <Button
            classes={{ root: 'close-button' }}
            color="primary"
            onClick={onClose}
            variant="outlined"
          >
            <div className="icon-title-wrapper">
              <span className="icon-title">CLOSE</span>
            </div>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

OrderDetailsDialog.defaultProps = {
  isOrderCompleted: false,
};

export default OrderDetailsDialog;
