import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { AddOutlined, DeleteOutlined, Link } from '@material-ui/icons';
import { Dictionary, keyBy, sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DeleteDialog from '../../components/DeleteDialog';
import { noDataAvailable } from '../../components/NoDataAvailable';
import { useAppContext } from '../../config/AppContext';
import { IEvent } from '../../models/Event.model';
import { IVenueLocation } from '../../models/Location.model';
import { IStore } from '../../models/Store.model';
import { UserVenueRole } from '../../models/User.model';
import { IVenueInvite } from '../../models/VenueInvite.model';
import EventService from '../../services/Event/Event.service';
import VenueService from '../../services/Venue/Venue.service';
import VenueInviteService from '../../services/Venue/VenueInvite.service';
import { getSuiteInviteLink } from '../../services/WebLinks.service';
import {
  HeaderButton,
  HeaderButtonType,
} from '../../theme/components/buttons/HeaderButton';
import { humanReadableRoles } from './UserAdminConfig';
import './Users.scss';
import { VenueInviteDialog } from './VenueInviteDialog/VenueInviteDialog';

export const VenueInvites = () => {
  const [showVenueInviteDialog, setShowVenueInviteDialog] = useState(false);
  const [venueInviteIdToDelete, setVenueInviteIdToDelete] = useState<
    string | undefined
  >();
  const [venueInvites, setVenueInvites] = useState<IVenueInvite[]>([]);
  const [events, setEvents] = useState<Dictionary<IEvent>>({});
  const [suites, setSuites] = useState<Dictionary<IVenueLocation>>({});

  const { setShowSpinner, stores: allStores, venue } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const stores = keyBy(allStores, (s: IStore) => s.id);

  useEffect(() => {
    fetchVenueInvites();
  }, [venue]);

  useEffect(() => {
    fetchEvents();
  }, [venue]);

  useEffect(() => {
    fetchSuites();
  }, [venue]);

  const fetchVenueInvites = async () => {
    if (!venue) {
      return;
    }
    setShowSpinner(true);
    try {
      const { data } = await VenueInviteService.findAllVenueInvites(venue.id);
      setVenueInvites(sortBy(data, i => i.role));
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const fetchEvents = async () => {
    if (!venue) {
      return;
    }

    const {
      data: { items },
    } = await EventService.getAllEvents(500, 1, venue.id, true);
    const newEvents = keyBy(items, event => event.id);
    setEvents(newEvents);
  };

  const fetchSuites = async () => {
    if (!venue) {
      return;
    }

    const { data } = await VenueService.getVenueSuites(venue.id);
    const newSuites = keyBy(data, suite => suite.id);
    setSuites(newSuites);
  };

  const suiteNames = (invite: IVenueInvite) => {
    if (!invite.suiteIds) {
      return '-';
    }
    return invite.suiteIds.map((id: string) => suites[id]?.name).join(', ');
  };

  const storeNames = (invite: IVenueInvite): string => {
    if (!invite.storeIds) {
      return '-';
    }

    return invite.storeIds.map((id: string) => stores[id]?.name).join(', ');
  };

  const eventNames = (invite: IVenueInvite) => {
    if (invite.allEvents) {
      return 'All Events';
    }

    if (!invite.eventIds) {
      return '-';
    }

    return invite.eventIds.map((id: string) => events[id]?.name).join(', ');
  };

  const deleteVenueInvite = async () => {
    if (!venueInviteIdToDelete) {
      return;
    }

    setShowSpinner(true);
    try {
      await VenueInviteService.deleteVenueInvite(venueInviteIdToDelete);

      enqueueSnackbar('Venue Invite has been deleted successfully', {
        variant: 'success',
      });
      setVenueInviteIdToDelete(undefined);
      await fetchVenueInvites();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const copyInviteLink = (inviteId: string) => {
    navigator.clipboard.writeText(getSuiteInviteLink(inviteId));
    enqueueSnackbar('Invite Link Copied to Clipboard', {
      variant: 'success',
    });
  };

  const saveVenueInvite = async (invite: Partial<IVenueInvite>) => {
    if (!venue) {
      return;
    }
    setShowSpinner(true);
    try {
      const { data } = await VenueInviteService.createVenueInvite({
        allEvents: invite.allEvents,
        eventIds: invite.eventIds,
        role: invite.role || UserVenueRole.VENUE_MANAGER,
        storeIds: invite.storeIds,
        suiteIds: invite.suiteIds,
        venueId: venue.id,
      });

      copyInviteLink(data.id);
      enqueueSnackbar('Venue Invite has been created', {
        variant: 'success',
      });

      setShowVenueInviteDialog(false);
      await fetchVenueInvites();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  return (
    <div className="venue-invites-container">
      <div className="pagination-container">
        <HeaderButton
          icon={AddOutlined}
          onClick={() => setShowVenueInviteDialog(true)}
          text="Add Venue Invite"
          type={HeaderButtonType.PRIMARY}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow
            classes={{
              root: 'table-row',
            }}
          >
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Role
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Suites
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Events
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Stores
            </TableCell>
            <TableCell classes={{ root: 'table-cell table-cell-head' }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venueInvites.length > 0
            ? venueInvites.map((invite: IVenueInvite) => (
                <TableRow key={invite.id} className="users-table-row">
                  <TableCell classes={{ root: 'table-cell' }}>
                    {humanReadableRoles[invite.role]}
                  </TableCell>
                  <TableCell classes={{ root: 'table-cell' }}>
                    {suiteNames(invite)}
                  </TableCell>
                  <TableCell classes={{ root: 'table-cell' }}>
                    {eventNames(invite)}
                  </TableCell>
                  <TableCell classes={{ root: 'table-cell' }}>
                    {storeNames(invite)}
                  </TableCell>

                  <TableCell classes={{ root: 'table-cell' }}>
                    <Button
                      size="small"
                      value="delete"
                      onClick={() => setVenueInviteIdToDelete(invite.id)}
                      classes={{
                        root: 'userAdmin-table-button',
                      }}
                    >
                      <div className="icon-title-wrapper">
                        <DeleteOutlined />
                        <span className="icon-title">Delete</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      value="delete"
                      onClick={() => copyInviteLink(invite.id)}
                      classes={{
                        root: 'userAdmin-table-button',
                      }}
                    >
                      <div className="icon-title-wrapper">
                        <Link />
                        <span className="icon-title">Copy Invite Link</span>
                      </div>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : noDataAvailable(6, 'This venue has no active invites')}
        </TableBody>
      </Table>
      {venueInviteIdToDelete && (
        <DeleteDialog
          dialogTitle="Delete Venue Invite"
          dialogContent="Are you sure you want to remove this Venue Invite? It will prevent users from using the link to join this venue."
          onClose={() => setVenueInviteIdToDelete(undefined)}
          onSubmit={deleteVenueInvite}
        />
      )}

      {showVenueInviteDialog && (
        <VenueInviteDialog
          close={() => setShowVenueInviteDialog(false)}
          events={Object.values(events)}
          stores={Object.values(stores)}
          suites={Object.values(suites)}
          save={saveVenueInvite}
        />
      )}
    </div>
  );
};
