import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';
import AddImage from '../../AddImage/AddImage';

const AddSponsorImageDialog = (props: any) => {
  const {
    currentImageUrl,
    getImageURL,
    onClose,
    onImageSave,
    setImage,
  } = props;

  return (
    <Dialog
      classes={{ paper: 'sponsor-dialog' }}
      fullWidth={true}
      open={true}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography align="center" className="section-title">
          New Logo
        </Typography>
      </DialogTitle>
      <DialogContent className="add-sponsor-image__dialog-content">
        <div className="upload-image">
          <AddImage
            getFile={setImage}
            getImageURL={getImageURL}
            width={290}
            height={225}
            previewBottom={false}
            showImageDetails={true}
            minWidth={200}
            minHeight={200}
          />
        </div>
        {!!currentImageUrl && (
          <img src={currentImageUrl} width={200} height={200} />
        )}
      </DialogContent>
      <div className="button-holder-similar-margin">
        <DialogActions className="add-sponsor-image__dialog-actions">
          <Button color="primary" onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={onImageSave} variant="contained">
            Add Image
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddSponsorImageDialog;
