import { IStore } from '../../models/Store.model';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service';
const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
export interface ICompletedOrderState {
  totalCount: number;
  rowsPerPage: number;
  page: number;
  orders: any;
  firstName: '';
  from: any;
  lastName: '';
  orderId: '';
  to: any;
}

export interface IOrdersProps {
  venueId: string;
  stores: IStore[];
  selectedStoreId: string;
  enqueueSnackbar: any;
  closeSnackbar: any;
  handleSelectedStoreId: any;
}

export interface IUpdateOrder {
  runnerId?: string;
  status:
    | VALIDSTATUS.IN_PROCESS
    | VALIDSTATUS.READY_FOR_RUNNER
    | VALIDSTATUS.READY_FOR_PICKUP
    | VALIDSTATUS.OUT_FOR_DELIVERY
    | VALIDSTATUS.FULFILLED;
}

export interface IUpdateLineItemGroup {
  runnerId?: string;
  status:
    | VALIDSTATUS.IN_PROCESS
    | VALIDSTATUS.READY_FOR_RUNNER
    | VALIDSTATUS.READY_FOR_PICKUP
    | VALIDSTATUS.OUT_FOR_DELIVERY
    | VALIDSTATUS.DONE;
}

export interface IOrderFilter {
  orderId?: string;
  firstName?: string;
  lastName?: string;
  from?: string;
  to?: string;
}

export interface IOrderSchema {
  entity: 'order' | 'lineItemGroup';
  key: string;
  name: string;
  size?: 'medium' | 'small';
  type: 'action' | 'date' | 'location' | 'string' | 'check';
}

// export const ordersSchema: IOrderSchema[] = [
//   {
//     entity: 'order',
//     key: 'event.name',
//     name: 'Event',
//     size: 'medium',
//     type: 'string',
//   },
//   {
//     entity: 'order',
//     key: 'location',
//     name: 'Location',
//     size: 'medium',
//     type: 'location',
//   },
//   {
//     entity: 'lineItemGroup',
//     key: 'runner.firstName',
//     name: 'Runner',
//     size: 'small',
//     type: 'string',
//   },
//   {
//     entity: 'order',
//     key: 'createdTime',
//     name: 'Placed',
//     type: 'date',
//   },

//   {
//     entity: 'order',
//     key: 'cover',
//     name: 'cover',
//     type: 'string',
//   },
//   {
//     entity: 'order',
//     key: 'prepDueTime',
//     name: 'Due',
//     type: 'date',
//   },
//   {
//     entity: 'lineItemGroup',
//     key: 'orderPrepStartTime',
//     name: 'Grabbed',
//     type: 'date',
//   },
//   {
//     entity: 'lineItemGroup',
//     key: 'orderReadyTime',
//     name: 'Ready',
//     type: 'date',
//   },
//   {
//     entity: 'lineItemGroup',
//     key: 'orderOutForDeliveryTime',
//     name: 'Delivering',
//     type: 'date',
//   },
//   {
//     entity: 'lineItemGroup',
//     key: 'orderDoneTime',
//     name: 'Done',
//     type: 'date',
//   },
//   {
//     entity: 'order',
//     key: '2',
//     name: 'Action',
//     size: 'medium',
//     type: 'action',
//   },
// ];
export const ordersSchema: any[] = [
  {
    entity: 'order',
    key: 'event.name',
    name: 'Event',
    size: 'medium',
    type: 'string',
  },
  {
    entity: 'order',
    key: 'location',
    name: 'Location',
    size: 'medium',
    type: 'location',
  },
  {
    entity: 'lineItemGroup',
    key: 'runner.firstName',
    name: 'Runner',
    size: 'small',
    type: 'string',
  },
  {
    entity: 'order',
    key: 'createdTime',
    name: 'Placed',
    type: 'date',
  },
  ...(tenant_schema === 'true'
  ? [
      {
        entity: 'order',
        key: 'cover',
        name: 'cover',
        size: 'medium',
        type: 'check',
      },
    ]
  : []),
  {
    entity: 'order',
    key: 'prepDueTime',
    name: 'Due',
    type: 'date',
  },
  {
    entity: 'lineItemGroup',
    key: 'orderPrepStartTime',
    name: 'Grabbed',
    type: 'date',
  },
  {
    entity: 'lineItemGroup',
    key: 'orderReadyTime',
    name: 'Ready',
    type: 'date',
  },
  {
    entity: 'lineItemGroup',
    key: 'orderOutForDeliveryTime',
    name: 'Delivering',
    type: 'date',
  },
  {
    entity: 'lineItemGroup',
    key: 'orderDoneTime',
    name: 'Done',
    type: 'date',
  },
  {
    entity: 'order',
    key: '2',
    name: 'Action',
    size: 'medium',
    type: 'action',
  },
];

export const PollingInterval = {
  inProgressOrders: 1 * 60 * 1000, // 1 minute
  newOrders: 30 * 1000, // 30 seconds
  openTabs: 15 * 60 * 1000, // 15 minute
  orderNotification: 40 * 1000, // 40 seconds
  preOrders: 15 * 60 * 1000, // 15 minutes
};

export enum VALIDSTATUS {
  PRE_ORDER = 'PRE_ORDER',
  NEW = 'NEW',
  IN_PROCESS = 'IN_PROCESS',
  READY_FOR_RUNNER = 'READY_FOR_RUNNER',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  FULFILLED = 'FULFILLED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  OPEN_TABS = 'OPEN_TABS',
  SUB_ORDER = 'SUB_ORDER',
}

export interface ICurrentOrdersTab {
  id: string;
  name: string;
  pollingInterval: number;
  status: string[];
  updateIntervalText: string;
}

// converts pollingInterval milliseconds into a human readable update interval such as `30 seconds` or `5 minutes`
const calculateUpdateIntervalText = (pollingInterval: number): string => {
  const updateIntervalSeconds = pollingInterval / 1000;
  if (updateIntervalSeconds <= 60) {
    return `${updateIntervalSeconds} seconds`;
  }
  return `${updateIntervalSeconds / 60} minutes`;
};

export const currentOrdersSecondaryTabs: ICurrentOrdersTab[] = [
  {
    id: 'new',
    name: 'New',
    pollingInterval: PollingInterval.newOrders,
    status: [VALIDSTATUS.NEW, VALIDSTATUS.PRE_ORDER, VALIDSTATUS.SUB_ORDER],
    updateIntervalText: calculateUpdateIntervalText(PollingInterval.newOrders),
  },
  {
    id: 'in-progress',
    name: 'In Progress Orders',
    pollingInterval: PollingInterval.inProgressOrders,
    status: [
      VALIDSTATUS.IN_PROCESS,
      VALIDSTATUS.READY_FOR_RUNNER,
      VALIDSTATUS.READY_FOR_PICKUP,
      VALIDSTATUS.OUT_FOR_DELIVERY,
    ],
    updateIntervalText: calculateUpdateIntervalText(
      PollingInterval.inProgressOrders,
    ),
  },
  {
    id: 'pre-orders',
    name: 'Pre Orders',
    pollingInterval: PollingInterval.preOrders,
    status: [VALIDSTATUS.PRE_ORDER],
    updateIntervalText: calculateUpdateIntervalText(PollingInterval.preOrders),
  },
  {
    id: 'open-tabs',
    name: 'Open Tabs',
    pollingInterval: PollingInterval.openTabs,
    status: [VALIDSTATUS.SUB_ORDER],
    updateIntervalText: calculateUpdateIntervalText(PollingInterval.openTabs),
  },
];

export enum REFUNDSTATUS {
  FULL_REFUNDED = 'FULL',
  PARTIAL_REFUNDED = 'PARTIAL',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export interface IOrderAction {
  displayName: string;
  nextStatus: VALIDSTATUS;
}

export interface IOrderState {
  actions?: IOrderAction[];
  status: VALIDSTATUS | REFUNDSTATUS;
  type?: string;
  value: string;
}

export const getOrderStates = (
  bypassOrderFulfillment: boolean,
): IOrderState[] => [
  {
    actions: [
      {
        displayName: bypassOrderFulfillment ? 'Close' : 'Grab',
        nextStatus: bypassOrderFulfillment
          ? VALIDSTATUS.DONE
          : VALIDSTATUS.IN_PROCESS,
      },
    ],
    status: VALIDSTATUS.PRE_ORDER,
    value: 'Pre-Order',
  },
  {
    actions: [
      {
        displayName: bypassOrderFulfillment ? 'Close' : 'Grab',
        nextStatus: bypassOrderFulfillment
          ? VALIDSTATUS.DONE
          : VALIDSTATUS.IN_PROCESS,
      },
    ],
    status: VALIDSTATUS.NEW,
    value: 'New',
  },
  {
    actions: [
      {
        displayName: bypassOrderFulfillment ? 'Close' : 'Grab',
        nextStatus: bypassOrderFulfillment
          ? VALIDSTATUS.DONE
          : VALIDSTATUS.IN_PROCESS,
      },
    ],
    status: VALIDSTATUS.SUB_ORDER,
    type: 'DELIVERY',
    value: 'Sub-Order',
  },
  {
    actions: [
      {
        displayName: 'Mark as Ready',
        nextStatus: VALIDSTATUS.READY_FOR_RUNNER,
      },
    ],
    status: VALIDSTATUS.IN_PROCESS,
    type: 'DELIVERY',
    value: 'In Progress',
  },
  {
    actions: [
      {
        displayName: 'Mark as Ready',
        nextStatus: VALIDSTATUS.READY_FOR_PICKUP,
      },
    ],
    status: VALIDSTATUS.IN_PROCESS,
    type: 'PICKUP',
    value: 'In Progress',
  },
  {
    actions: [
      {
        displayName: 'Assign Runner',
        nextStatus: VALIDSTATUS.OUT_FOR_DELIVERY,
      },
    ],
    status: VALIDSTATUS.READY_FOR_RUNNER,
    value: 'Ready For Runner',
  },
  {
    actions: [
      {
        displayName: 'Complete Order',
        nextStatus: VALIDSTATUS.DONE,
      },
    ],
    status: VALIDSTATUS.READY_FOR_PICKUP,
    value: 'Ready For Pickup',
  },
  {
    actions: [
      {
        displayName: 'Mark Delivered',
        nextStatus: VALIDSTATUS.DONE,
      },
      {
        displayName: 'Unassign Runner',
        nextStatus: VALIDSTATUS.READY_FOR_RUNNER,
      },
    ],
    status: VALIDSTATUS.OUT_FOR_DELIVERY,
    value: 'Out For Delivery',
  },
  {
    status: VALIDSTATUS.FULFILLED,
    value: 'Delivered',
  },
  {
    actions: [
      {
        displayName: 'Close Tab',
        nextStatus: VALIDSTATUS.OPEN_TABS,
      },
      // {
      //   displayName: 'Modify',
      //   nextStatus: VALIDSTATUS.SUB_ORDER,
      // },
    ],
    status: VALIDSTATUS.DONE,
    value: 'Done',
  },
  {
    status: VALIDSTATUS.CANCELLED,
    value: 'Cancelled',
  },
  {
    status: REFUNDSTATUS.FULL_REFUNDED,
    value: 'Full Refund',
  },
  {
    status: REFUNDSTATUS.PARTIAL_REFUNDED,
    value: 'Partial Refund',
  },
  {
    status: REFUNDSTATUS.NOT_APPLICABLE,
    value: '',
  },
];

export enum RefundType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

export enum RefundReason {
  DUPLICATE = 'duplicate',
  FRADULENT = 'fraudulent',
  REQUESTED_BY_CUSTOMER = 'requested_by_customer',
}

export const RefundTypes = [
  { key: RefundType.FULL, name: 'Full' },
  { key: RefundType.PARTIAL, name: 'Partial' },
];

export const RefundReasons = [
  { key: RefundReason.DUPLICATE, name: 'Duplicate' },
  { key: RefundReason.FRADULENT, name: 'Fradulent' },
  { key: RefundReason.REQUESTED_BY_CUSTOMER, name: 'Customer Request' },
];
