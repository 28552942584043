import { useState } from 'react';

export interface IPagination {
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setTotalCount: React.Dispatch<React.SetStateAction<number>>;
  totalCount: number;
}

export const usePagination = (initialRowsPerPage: number = 20): IPagination => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [totalCount, setTotalCount] = useState<number>(0);

  return {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setTotalCount,
    totalCount,
  };
};
