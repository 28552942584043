import { MenuItem, Select, TablePagination } from '@material-ui/core';
import { get } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../config/AppContext';
import { IStore } from '../../../models/Store.model';
import OrdersService from '../../../services/Orders/Orders.service';
import { getUTCDate } from '../../../services/Util/Util.service';
import SearchFields from '../AllOrdersTable/OrdersSearchFields';
import OrdersTable from '../AllOrdersTable/OrdersTable';
import { IOrderTabComponentProps, RowsPerPageOptions } from '../Orders';
import { ordersSchema, VALIDSTATUS } from '../Orders.config';
import './OrderHistory.scss';

const OrderHistory = (props: IOrderTabComponentProps): JSX.Element => {
  const {
    enqueueSnackbar,
    handleChangePage,
    handleChangeRowsPerPage,
    handleStoreSelect,
    isLoading,
    lastRefreshRequested,
    orders,
    searchCriteria,
    selectedStoreId,
    setIsLoading,
    setOrders,
    setSearchCriteria,
    triggerRefresh,
    venueId,
    allTerminals,
    terminalId,
    handleTerminalSelect,
    terminalName,
  } = props;

  const { stores, venue } = useAppContext();
  const [totalCount, setTotalCount] = useState<number>(0);
  const getAllCompletedOrders = async (tabId?: string, hide?: boolean) => {
    const {
      endTime,
      firstName,
      lastName,
      orderNo,
      page,
      rowsPerPage,
      startTime,
    } = searchCriteria;
    try {
      let type = undefined;
      let currentTerminalId = undefined;
      if (terminalId === 'PICKUP' || terminalId === 'DELIVERY') {
        type = terminalId;
      } else if (terminalId !== 'all') {
        currentTerminalId = terminalId;
      }
      setIsLoading(true);
      const { data } = await OrdersService.getCurrentVenueHistoryOrders({
        endTime: endTime
          ? getUTCDate(endTime, get(venue, 'timezone', '')).toISOString()
          : undefined,
        firstName,
        lastName,
        limit: rowsPerPage,
        orderNo,
        page: page + 1, // needs to be 1 index
        sortBy: 'createdTime',
        sortOrder: 'DESC',
        startTime: startTime
          ? getUTCDate(startTime, get(venue, 'timezone', '')).toISOString()
          : undefined,
        status: tabId
          ? ['SUB_ORDER']
          : [VALIDSTATUS.CANCELLED, VALIDSTATUS.DONE, VALIDSTATUS.FULFILLED],
        storeId: selectedStoreId!,
        tabId: `${tabId}`,
        venueId,
        type,
        terminalId: currentTerminalId,
      });
      if (tabId) {
        const mainTabIndex = orders.findIndex(o => o.tabId === tabId);
        if (hide) {
          const indexes = orders
            .map((elm, idx) =>
              elm.tabId === tabId && elm.tabOrderType === 'SUB_ORDER'
                ? idx
                : '',
            )
            .filter(String);
          const ordersWithoutTabSubOrders = orders.filter((o, i) => {
            return !indexes.includes(i);
          });
          setOrders(ordersWithoutTabSubOrders);
        } else {
          const ordersWithSubOrders = orders.concat(
            data.items,
            orders.splice(mainTabIndex + 1, orders.length - 1),
          );
          setOrders(ordersWithSubOrders);
        }
      } else {
        setOrders(data.items);
      }
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      await getAllCompletedOrders();
    })();
  }, [
    lastRefreshRequested,
    searchCriteria.page,
    searchCriteria.rowsPerPage,
    selectedStoreId,
    searchCriteria.terminalId,
  ]);

  return (
    <div className="orders">
      <div
        className="search-groupby-container"
        style={{
          display: 'flex',
          gap: '15px',
        }}
      >
        <div className="search-groupby">
          <p className="label">Store: </p>
          <Select
            value={selectedStoreId}
            onChange={handleStoreSelect}
            classes={{
              root: 'select-store',
            }}
          >
            {stores.map((store: IStore) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="search-groupby">
          <p className="label">Order Source: </p>
          <Select
            value={terminalName}
            onChange={handleTerminalSelect}
            classes={{
              root: 'select-store',
            }}
          >
            <MenuItem key="all" value="all">
              All
            </MenuItem>
            <MenuItem key="PICKUP" value="PICKUP">
              Pick Up Orders
            </MenuItem>
            <MenuItem key="DELIVERY" value="DELIVERY">
              Delivery Orders
            </MenuItem>
            {allTerminals.map((terminal: any) => (
              <MenuItem key={terminal.id} value={terminal.terminalName}>
                {terminal.terminalName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="orders__container">
        <div className="orders__pagination">
          <TablePagination
            classes={{
              caption: 'pagination-caption',
              root: 'pagination-control',
              select: 'pagination-select',
              toolbar: 'pagination-control-buttons',
            }}
            colSpan={3}
            component="div"
            count={totalCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={searchCriteria.page}
            rowsPerPage={searchCriteria.rowsPerPage}
            rowsPerPageOptions={RowsPerPageOptions}
            SelectProps={{
              inputProps: { 'aria-label': 'Items per page' },
              native: true,
            }}
          />
        </div>
        <div className="orders__table">
          <OrdersTable
            assumeAllOrdersAreCompleted={true}
            expandOrders={false}
            isLoading={isLoading}
            onUpdateLineItemGroupStatus={async () => undefined}
            orders={orders}
            refresh={triggerRefresh}
            runners={[]}
            schema={ordersSchema}
            selectedRunnerId={undefined}
            selectedStoreId={selectedStoreId}
            selectRunner={() => undefined}
            showActionButton={false}
            tabId={getAllCompletedOrders}
            terminalName={terminalName}
          >
            <SearchFields
              {...{
                isLoading,
                searchCriteria,
                setSearchCriteria,
                triggerRefresh,
              }}
            />
          </OrdersTable>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(OrderHistory);
