import { Button, TextField, Typography } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Formik, FormikActions, FormikProps } from 'formik';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { ITag } from '../../models/Tag.model';
import TagService from '../../services/Tag.service';

import './index.scss';

interface IAddTagFormValues {
  name: string;
}

const Tags = (props: WithSnackbarProps): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<ITag[]>([]);

  const addTag = async (
    values: IAddTagFormValues,
    formikActions: FormikActions<IAddTagFormValues>,
  ) => {
    const { name } = values;
    const { resetForm, setSubmitting } = formikActions;
    const { enqueueSnackbar } = props;

    try {
      if (name.length <= 0) {
        return;
      }

      await TagService.create(name);

      await loadTags();

      resetForm();

      enqueueSnackbar(`Added tag: ${name}`, { variant: 'success' });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar(`Error: ${error.response.data.message}`, {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const loadTags = async () => {
    const { enqueueSnackbar } = props;

    setLoading(true);

    try {
      const { data } = await TagService.getAll();

      setTags(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      await loadTags();
    })();
  }, []);

  return (
    <div className="tags">
      <Typography component="h1" variant="h5" className="section-title">
        Tags
      </Typography>

      <Formik<IAddTagFormValues>
        initialValues={{
          name: '',
        }}
        onSubmit={addTag}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(1)
            .required(),
        })}
        render={(formProps: FormikProps<IAddTagFormValues>) => (
          <div>
            <TextField
              error={formProps.touched.name && !!formProps.errors.name}
              required={true}
              name="name"
              onChange={formProps.handleChange}
              value={formProps.values.name}
              autoComplete="off"
              onKeyDown={e => {
                // force submit on Enter press
                if (e.key === 'Enter') {
                  formProps.submitForm();
                }
              }}
            />

            <Button
              size="medium"
              color="primary"
              aria-label="add"
              classes={{
                root: 'tags__add-button',
              }}
              onClick={formProps.submitForm}
              disabled={loading || formProps.isSubmitting || !formProps.isValid}
            >
              <div className="icon-title-wrapper">
                <AddOutlinedIcon />
                <span className="icon-title">Add Tag</span>
              </div>
            </Button>
          </div>
        )}
      />

      <div className="tags__container">
        {tags.map((tag: ITag, index: number) => (
          <div className="tags__container__tag" key={index}>
            {tag.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withSnackbar(Tags);
