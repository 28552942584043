import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import ExportToExcel, { IWorksheet } from '../../components/ExportToExcel';
import { noDataAvailable } from '../../components/NoDataAvailable';
import { CurrencyCode } from '../../models/Venue.model';
import { getCurrencyPrefix } from '../../services/Util/Util.service';

interface IUserDetailProps {
  data: IUserDetailDataRecord[];
}

export interface IUserDetailDataRecord {
  activeSince: Date;
  aov: number;
  currency: CurrencyCode;
  email: string;
  firstName: string;
  lastName: string;
  lastOrderDate: Date;
  phoneNumber: string;
  totalEvents: number;
  totalOrders: number;
  venueName: string;
}

const headers = [
  'First Name',
  'Last Name',
  'Email',
  'Phone Number',
  'Venue Name',
  'Active Since',
  'Last Order Date',
  'Total Orders',
  'Total Events',
  'AOV',
];

const UserDetail = (props: IUserDetailProps) => {
  const { data } = props;

  const getWorksheets = async (): Promise<IWorksheet[]> => {
    /* tslint:disable:object-literal-sort-keys */
    const worksheets: IWorksheet[] = [
      {
        name: 'user-detail',
        data: data.map((record: IUserDetailDataRecord) => ({
          'First Name': record.firstName,
          'Last Name': record.lastName,
          Email: record.email,
          'Phone Number': record.phoneNumber,
          'Venue Name': record.venueName,
          'Active Since': record.activeSince,
          'Last Order Date': record.lastOrderDate,
          'Total Orders': record.totalOrders,
          'Total Events': record.totalEvents,
          AOV: record.aov.toFixed(2),
          Currency: record.currency,
        })),
      },
    ];
    /* tslint:enable:object-literal-sort-keys */

    return worksheets;
  };

  return (
    <>
      <div className="records-container">
        {/* this aligns the export button to the right by taking up a column position */}
        <div />

        <ExportToExcel
          buttonText="Export to Excel"
          filename="user-detail"
          getWorksheets={getWorksheets}
        />
      </div>
      <div>
        <Table className="reporting__table">
          <TableHead>
            <TableRow>
              {headers.map((text: string, index: number) => (
                <TableCell key={index} classes={{ head: 'table-header' }}>
                  {text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length
              ? noDataAvailable(headers.length)
              : data.map((record: IUserDetailDataRecord, index: number) => {
                  const currencyPrefix = getCurrencyPrefix(record.currency); // defaults to `$`

                  return (
                    <TableRow key={index}>
                      <TableCell className="table-cell table-cell-no-data">
                        {record.firstName}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {record.lastName}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {record.email}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {record.phoneNumber}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {record.venueName}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {new Date(record.activeSince).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        {new Date(record.lastOrderDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.totalOrders}
                          decimalScale={0}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.totalEvents}
                          decimalScale={0}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </TableCell>
                      <TableCell className="table-cell table-cell-no-data">
                        <NumberFormat
                          value={record.aov}
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          prefix={currencyPrefix}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default UserDetail;
