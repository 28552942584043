import React, { useEffect } from 'react';
import { Partner } from '../../../models/Partner.model';
import IProduct, { AvailabilityType } from '../../../models/Product.model';
import { getCurrencyPrefix } from '../../../services/Util/Util.service';
import AddEditProductForm from '../../Concessions/Menu/AddEditProductForm';
// import { useStoreContext } from "../../../config/StoreContext";
import { useAppContext } from '../../../config/AppContext';
const CustomProduct = ({
  storeContext,
  venue,
  fetchProducts,
  handleFetchProductsFalse,
}: any) => {
  const [product, setProduct] = React.useState<IProduct | undefined>(undefined);
  const categories = storeContext[0];
  const store = storeContext[2];
  const fulfillmentCenters = storeContext[3];
  const taxRates = storeContext[5];
  const { productTags } = useAppContext();
  const onSubmitProductForm = () => {
    setProduct(undefined);
    fetchProducts();
  };

  useEffect(() => {
    const initialProductState: IProduct = {
      active: true,
      alcohol: false,
      availabilityType: AvailabilityType.ALL,
      categoryId: categories[0].id,
      tagId: '',
      fulfillmentCenterId: store.id,
      hasSubProducts: false,
      id: '',
      name: '',
      partner: Partner.FANFOOD,
      partnerProductId: '',
      price: 0,
      priority: 1,
      stock: 0,
      storeId: store.id,
      subCategories: [],
      taxable: true,
      countAsInventory: false,
      inventoryCount: 0,
      pos: false,
      testAvailabilityType: ['POS', 'DELIVERY', 'PICKUP'],
      tags: [],
    };
    setProduct(initialProductState);
  }, []);

  const handleCloseDiaglog = () => {
    setProduct(undefined);
    handleFetchProductsFalse();
  };
  return (
    <div>
      {product && (
        <AddEditProductForm
          allowFreeProducts={venue.allowFreeProducts}
          categories={categories}
          currencyPrefix={getCurrencyPrefix(venue.currency)}
          fulfillmentCenters={fulfillmentCenters}
          onClose={() => handleCloseDiaglog()}
          onSubmit={onSubmitProductForm}
          product={product}
          store={store}
          taxRates={taxRates}
          tags={productTags}
        />
      )}
    </div>
  );
};

export default CustomProduct;
