import { Tab, Tabs, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import CustomSnackbar from '../../components/CustomSnackbar/CustomSnackbar';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import { useAppContext } from '../../config/AppContext';
import {
  ICreateACHAccountRequest,
  IUpdateVenueWithImageRequest,
} from '../../models/Request.model';
import { canEditVenue, canEditVenueAch } from '../../services/Auth.service';
import VenueService from '../../services/Venue/Venue.service';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import {
  getSecondaryTabs,
  ISettingsProps,
  ISettingsRequest,
  ISettingsTab,
} from './Settings.config';
import './Settings.scss';

const Settings = (props: ISettingsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { setShowSpinner, setVenue, user, venue } = useAppContext();
  const [showImageDetails, setShowImageDetails] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newTabValue: number,
  ) => {
    setActiveTabIndex(newTabValue);
  };

  const {
    location: { pathname },
  } = props;

  const { venueId } = props;

  useEffect(() => {
    if (pathname.indexOf('/settings/') === -1) {
      props.history.push(`${props.match.url}/my-profile`);
      setActiveTabIndex(0);
    } else if (pathname.indexOf('/organization-details') !== -1) {
      setActiveTabIndex(1);
    } else if (pathname.indexOf('/locations') !== -1) {
      setActiveTabIndex(2);
    } else if (pathname.indexOf('/payment-settings') !== -1) {
      setActiveTabIndex(3);
    }
  }, [pathname, props.history, props.match.url]);

  const updateVenueSettings = async (
    venueData: IUpdateVenueWithImageRequest,
  ) => {
    const { enqueueSnackbar } = props;

    setShowSpinner(true);
    try {
      if (get(venueData, 'imageData', false)) {
        await VenueService.uploadVenueImage(venueId, venueData.imageData);

        delete venueData.imageData;
        delete venueData.imageUrl;

        setShowImageDetails(false);
      }

      delete venueData.modifiedTime;
      delete venueData.createdTime;

      const { data } = await VenueService.updateVenue(venueId, venueData);
      setVenue(data);
      enqueueSnackbar('Venue has been successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const updateVenueACHDetails = async (
    achDetails: ICreateACHAccountRequest,
  ) => {
    const { enqueueSnackbar } = props;
    setShowSpinner(true);
    try {
      const { data } = await VenueService.createACHAccount(venueId, achDetails);
      setVenue(data);
      enqueueSnackbar('Venue ACH details have been successfully updated.', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const deleteACHAccount = async () => {
    const { enqueueSnackbar } = props;
    setShowSpinner(true);
    try {
      const { data } = await VenueService.deleteACHAccount(venueId);
      setVenue(data);
      enqueueSnackbar('ACH account successfully deleted', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const handleUpdate = async (
    request: ISettingsRequest,
    updateType: string,
  ) => {
    switch (updateType) {
      case 'settings':
        await updateVenueSettings(request as IUpdateVenueWithImageRequest);
        break;
      case 'ach':
        await updateVenueACHDetails(request as ICreateACHAccountRequest);
        break;
      default:
        break;
    }
  };

  function checkProfileComplete() {
    if (!venue) {
      return;
    }
    const venueData = { ...venue };

    if (venueData.active) {
      venueData.active = false;
      updateVenueSettings(venueData);
    } else {
      if (
        venueData.gatewayConnectAccountId &&
        venueData.addressLine1 &&
        venueData.addressLine1.length
      ) {
        venueData.active = true;
        updateVenueSettings(venueData);
      } else {
        setShowSnackbar(true);
      }
    }
  }

  if (!venue || !user) {
    return null;
  }

  const secondaryTabs = getSecondaryTabs(user, venue.id);
  const canViewPaymentSettings = canEditVenueAch(user, venue.id);

  return (
    <div className="settings-container">
      <div className="settings-header-container">
        <Typography classes={{ root: 'section-title' }}>Settings</Typography>
        {venue && canEditVenue(user, venue.id) && (
          <div className="open-store-toggle-container">
            <Typography className="open-store-text">Open Store(s)</Typography>
            <CustomSwitch
              name="openStoreToggle"
              color="primary"
              checked={get(venue, ['active'], false)}
              value={get(venue, ['active'], false)}
              onChange={checkProfileComplete}
            />
          </div>
        )}
      </div>

      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        textColor="primary"
        classes={{
          flexContainer: 'secondary-tabs-buttons-container',
          indicator: 'indicator',
          root: 'secondary-tabs-container',
        }}
      >
        {secondaryTabs.map((secondaryTab: ISettingsTab, index: number) => (
          <Tab
            component={Link}
            to={`${props.match.url}/${secondaryTab.route}`}
            key={index}
            classes={{
              root: 'secondary-tab',
              selected: 'selectedTab',
              wrapper: 'secondary-tab-label',
            }}
            label={secondaryTab.label}
          />
        ))}
        {canViewPaymentSettings && (
          <Tab
            component={Link}
            to={`${props.match.url}/payment-settings`}
            classes={{
              root: 'secondary-tab',
              selected: 'selectedTab',
              wrapper: 'secondary-tab-label',
            }}
            label="Payment Settings"
          />
        )}
      </Tabs>
      {secondaryTabs.map((secondaryTab: ISettingsTab, index: number) => (
        <Route
          render={childProps => (
            <secondaryTab.component
              {...childProps}
              {...props}
              venue={venue}
              onUpdate={async (request?: ISettingsRequest) =>
                await handleUpdate(
                  request || ({} as ISettingsRequest),
                  secondaryTab.name,
                )
              }
              showImageDetails={showImageDetails}
              setShowImageDetails={setShowImageDetails}
            />
          )}
          path={`${props.match.path}/${secondaryTab.route}`}
          key={index}
        />
      ))}
      {canViewPaymentSettings && (
        <Route
          render={() =>
            venue && (
              <PaymentSettings
                bankAccountLast4={venue.bankAccountLast4}
                bankAccountName={venue.bankAccountName}
                disableActionButton={false}
                gatewayConnectAccountId={venue.gatewayConnectAccountId}
                onUpdate={async (updatedData: ISettingsRequest) =>
                  await handleUpdate(updatedData, 'ach')
                }
                onDelete={deleteACHAccount}
              />
            )
          }
          path={`${props.match.path}/payment-settings`}
        />
      )}
      {showSnackbar && (
        <CustomSnackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message="Please complete all required fields in Organization and Payment Settings pages before opening your store"
          onClose={() => setShowSnackbar(false)}
          open={showSnackbar}
        />
      )}
    </div>
  );
};

export default withSnackbar(Settings);
