import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { get } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { AppContext } from '../../config/AppContext';
import GlobalProductService from '../../services/GlobalProducts/GlobalProduct.service';
import AddGlobalProduct from '../AddGlobalProduct/AddGlobalProduct';
import './GlobalProducts.scss';

interface IState {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  globalProducts: any[];
  deleteGlobalProductID: number;
  showDeleteModal: boolean;
  showProductModal: boolean;
  searchTerm: string;
  selectedBrandId: string;
  selectedBrandUrl: string;
  selectedBrandName: string;
}

class GlobalProducts extends Component<any, IState> {
  public state = {
    deleteGlobalProductID: 0,
    globalProducts: [],
    page: 0,
    rowsPerPage: 20,
    searchTerm: '',
    selectedBrandId: '',
    selectedBrandName: '',
    selectedBrandUrl: '',
    showDeleteModal: false,
    showProductModal: false,
    totalCount: 0,
  };

  public fetchGlobalProducts = async (
    limit: number,
    pageNumber: number,
    globalProduct: string = '',
  ) => {
    const { setShowSpinner } = this.context;
    const { enqueueSnackbar } = this.props;
    setShowSpinner(true);
    try {
      const { data } = await GlobalProductService.getAllGlobalProducts(
        limit,
        pageNumber,
        globalProduct,
      );
      this.setState({
        globalProducts: get(data, ['items'], []),
        totalCount: get(data, ['totalItems'], 0),
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public componentDidMount() {
    const { rowsPerPage, page } = this.state;
    this.fetchGlobalProducts(rowsPerPage, page + 1);
  }

  public handleSearchProduct = async (event: any) => {
    const globalProduct = get(event, ['target', 'value']);
    this.setState({ page: 0, searchTerm: globalProduct }, () => {
      const { rowsPerPage: limit, page, searchTerm } = this.state;
      this.fetchGlobalProducts(limit, page + 1, searchTerm);
    });
  };

  public handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage }, () => {
      const { rowsPerPage: limit, page, searchTerm } = this.state;
      this.fetchGlobalProducts(limit, page + 1, searchTerm);
    });
  };

  public handleChangeRowsPerPage = (event: any) => {
    this.setState(
      { rowsPerPage: parseInt(event.target.value, 10), page: 0 },
      () => {
        const { rowsPerPage: limit, page, searchTerm } = this.state;
        this.fetchGlobalProducts(limit, page + 1, searchTerm);
      },
    );
  };

  public handleDeleteGlobalProduct = (id: number) => {
    this.setState({ deleteGlobalProductID: id, showDeleteModal: true });
  };

  public updateGlobalProduct = async (globalProductData: any) => {
    const { setShowSpinner } = this.context;
    const { selectedBrandId } = this.state;
    const { enqueueSnackbar } = this.props;
    setShowSpinner(true);
    try {
      await GlobalProductService.updateGlobalProduct(
        selectedBrandId,
        globalProductData,
      );
      this.setState({ showProductModal: false, page: 0 }, () => {
        const { rowsPerPage: limit, page } = this.state;
        this.fetchGlobalProducts(limit, page + 1);
      });
      enqueueSnackbar('Global Product updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public handleAddGlobalProduct = async (globalProductData: any) => {
    const { setShowSpinner } = this.context;
    const { enqueueSnackbar } = this.props;
    setShowSpinner(true);
    try {
      await GlobalProductService.createGlobalProduct(globalProductData);
      this.setState({ showProductModal: false, page: 0 }, () => {
        const { rowsPerPage: limit, page } = this.state;
        this.fetchGlobalProducts(limit, page + 1);
      });
      enqueueSnackbar('Global Product added successfully', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public deleteGlobalProduct = async () => {
    const { deleteGlobalProductID } = this.state;
    const { setShowSpinner } = this.context;
    const { enqueueSnackbar } = this.props;
    setShowSpinner(true);
    try {
      this.setState({ showDeleteModal: true });
      await GlobalProductService.deleteGlobalProduct(deleteGlobalProductID);
      this.setState({ page: 0, showDeleteModal: false }, () => {
        const { rowsPerPage, page } = this.state;
        this.fetchGlobalProducts(rowsPerPage, page + 1);
      });
      enqueueSnackbar('Global Product deleted successfully', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  public showUpdateModal = (id: string, url: string, name: string) => {
    this.setState({
      selectedBrandId: id,
      selectedBrandName: name,
      selectedBrandUrl: url,
      showProductModal: true,
    });
  };

  public render() {
    const {
      page,
      rowsPerPage,
      totalCount,
      globalProducts,
      showDeleteModal,
      showProductModal,
      selectedBrandId,
      selectedBrandUrl,
      selectedBrandName,
      searchTerm,
    } = this.state;
    return (
      <div className="global-products">
        <Typography component="h1" variant="h5" className="section-title">
          Global Products
        </Typography>
        <div className="search--bar">
          <label className="search--bar-label">Product Name: </label>
          <TextField
            value={searchTerm}
            id="outlined-bare"
            onChange={this.handleSearchProduct}
            placeholder="Search..."
            margin="dense"
            variant="outlined"
            InputProps={{
              classes: {
                input: 'search--bar-input',
                notchedOutline: 'search--bar-outline',
              },
            }}
          />
        </div>
        {showProductModal && (
          <AddGlobalProduct
            closeDialog={() =>
              this.setState({
                selectedBrandId: '',
                selectedBrandName: '',
                selectedBrandUrl: '',
                showProductModal: false,
              })
            }
            selectedBrandId={selectedBrandId}
            selectedBrandUrl={selectedBrandUrl}
            selectedBrandName={selectedBrandName}
            addGlobalProduct={
              selectedBrandId
                ? this.updateGlobalProduct
                : this.handleAddGlobalProduct
            }
          />
        )}
        <div className="pagination">
          <TablePagination
            component="div"
            rowsPerPageOptions={[20, 40, 60]}
            colSpan={3}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'Items per page' },
              native: true,
            }}
            labelRowsPerPage="Products per page"
            classes={{
              caption: 'pagination-caption',
              root: 'pagination-control',
              select: 'pagination-select',
              toolbar: 'pagination-control-buttons',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <div className="global-products--toolbar">
            <Button
              onClick={() =>
                this.setState({
                  selectedBrandId: '',
                  selectedBrandName: '',
                  selectedBrandUrl: '',
                  showProductModal: true,
                })
              }
            >
              <div className="icon-title-wrapper">
                <AddOutlinedIcon />
                <span className="icon-title">Add Global Product</span>
              </div>
            </Button>
          </div>
        </div>
        <Grid className="grid-container" container={true} spacing={4}>
          {globalProducts.map((d: any) => (
            <Grid item={true} key={d.id} xs={2} className="grid-item">
              <span
                className="close-icon"
                onClick={() => this.handleDeleteGlobalProduct(d.id)}
              >
                <Icon classes={{ root: 'icon-root' }}>close</Icon>
              </span>
              <div
                onClick={() => this.showUpdateModal(d.id, d.imageUrl, d.name)}
                className="name-gp-holder"
              >
                <img
                  src={
                    d.imageUrl
                      ? d.imageUrl
                      : 'https://via.placeholder.com/226x226.png?text=Image Coming Soon'
                  }
                  alt={d.name}
                />
                <h4>{d.name}</h4>
              </div>
            </Grid>
          ))}
        </Grid>
        <Dialog
          open={showDeleteModal}
          fullWidth={true}
          className="delete-globalProduct-modal"
        >
          <DialogTitle>
            <span className="section-title">Confirm Action</span>
          </DialogTitle>
          <DialogContent>
            <p>Are you sure you want to remove this Global Product ?</p>
          </DialogContent>
          <div className="button-holder-similar-margin">
            <DialogActions className="modal-actions">
              <Button
                onClick={() => this.setState({ showDeleteModal: false })}
                color="primary"
                variant="outlined"
              >
                <div className="icon-title-wrapper">
                  <UndoOutlinedIcon />
                  <span className="icon-title">Cancel</span>
                </div>
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={this.deleteGlobalProduct}
              >
                <div className="icon-title-wrapper">
                  <CheckOutlinedIcon />
                  <span className="icon-title">OK</span>
                </div>
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

GlobalProducts.contextType = AppContext;
export default withSnackbar(GlobalProducts);
