import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import { find, get, isEmpty, isEqual } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DeleteDialog from '../../../components/DeleteDialog';
import { useAppContext } from '../../../config/AppContext';
import SponsorService from '../../../services/Sponsors/Sponsor.service';
import Sponsor from './Sponsor';
import './Sponsors.scss';
import {
  ISponsor,
  sponsorModuleInitialState,
  sponsorValidationSchema,
} from './SponsorsConfig';
import SponsorsTable from './SponsorsTable';

const SponsorModules = (props: any) => {
  const [sponsorModules, setSponsorModules] = useState([]);
  const [currentSponsorModule, setCurrentSponsorModule] = useState(
    sponsorModuleInitialState,
  );
  const [
    viewDeleteSponsorModuleDialog,
    setViewDeleteSponsorModuleDialog,
  ] = useState(false);
  const [sponsorModuleToDelete, setSponsorModuleToDelete] = useState(
    sponsorModuleInitialState,
  );
  const [sponsorsToDelete, setSponsorsToDelete] = useState([]);

  const { setShowSpinner } = useAppContext();
  const { enqueueSnackbar, venueId } = props;

  useEffect(() => {
    fetchSponsorModules();
  }, [venueId]);

  const fetchSponsorModules = async () => {
    setShowSpinner(true);
    try {
      const { data } = await SponsorService.getAllSponsorModules(
        venueId,
        1000,
        1,
      );
      setSponsorModules(get(data, 'items', []));
      setCurrentSponsorModule(sponsorModuleInitialState);
      setSponsorModuleToDelete(sponsorModuleInitialState);
      setSponsorsToDelete([]);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const onChangeActiveSponsorModule = async (e: any, sponsorModule: any) => {
    setShowSpinner(true);
    try {
      const activeValue = get(e, ['target', 'checked']);
      await SponsorService.updateSponsorModule(sponsorModule.id, {
        active: activeValue,
      });
      enqueueSnackbar(
        `${get(
          sponsorModule,
          ['sponsors', '0', 'name'],
          sponsorModule.title,
        )} Module was successfully updated`,
        {
          variant: 'success',
        },
      );
      await fetchSponsorModules();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      const defaultError = 'Something went wrong. Please try again.';
      const errorToDisplay = get(
        error,
        ['response', 'data', 'message'],
        defaultError,
      );
      enqueueSnackbar(
        typeof errorToDisplay === 'string' ? errorToDisplay : defaultError,
        {
          variant: 'error',
        },
      );
    }
    setShowSpinner(false);
  };

  const createSponsorImageForm = (sponsor: ISponsor) => {
    const formData = new FormData();
    formData.append('name', sponsor.name);
    formData.append('imageFile', get(sponsor, 'imageFile', ''));
    return formData;
  };

  const onAddEditSponsorModule = async (values: any, formikBag: any) => {
    if (isEmpty(values.id)) {
      setShowSpinner(true);

      try {
        const {
          data: newSponsorModule,
        } = await SponsorService.createSponsorModule({
          title: values.title,
          venueId,
        });
        const createSponsorsPromises = values.sponsors.map(
          (sponsor: ISponsor) => {
            const formData = createSponsorImageForm(sponsor);
            return SponsorService.createSponsor(newSponsorModule.id, formData);
          },
        );

        await Promise.all(createSponsorsPromises);
        enqueueSnackbar(
          `${get(
            newSponsorModule,
            ['sponsors', '0', 'name'],
            newSponsorModule.title,
          )} Module was successfully created`,
          {
            variant: 'success',
          },
        );
        formikBag.resetForm();
        await fetchSponsorModules();
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error.response);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
      }
      setShowSpinner(false);
    } else {
      setShowSpinner(true);
      try {
        const createUpdateSponsorsPromises = values.sponsors.map(
          async (sponsor: ISponsor) => {
            const prevSponsor = find(currentSponsorModule.sponsors, [
              'id',
              get(sponsor, 'id'),
            ]);

            if (!isEqual(sponsor, prevSponsor)) {
              if (isEmpty(sponsor.id)) {
                const formData = createSponsorImageForm(sponsor);
                return SponsorService.createSponsor(values.id, formData);
              } else {
                if (typeof sponsor.imageFile !== 'undefined') {
                  const formData = createSponsorImageForm(sponsor);
                  return SponsorService.updateSponsor(
                    values.id,
                    get(sponsor, 'id', ''),
                    formData,
                  );
                } else {
                  return SponsorService.updateSponsor(
                    values.id,
                    get(sponsor, 'id', ''),
                    { name: sponsor.name },
                  );
                }
              }
            }
          },
        );

        const deleteSponsorsPromises = sponsorsToDelete.map(
          (sponsorId: string) =>
            SponsorService.deleteSponsor(values.id, sponsorId),
        );

        await Promise.all([
          ...createUpdateSponsorsPromises,
          ...deleteSponsorsPromises,
        ]);

        if (!isEqual(values.title, currentSponsorModule.title)) {
          await SponsorService.updateSponsorModule(values.id, {
            title: values.title,
          });
        }
        enqueueSnackbar(
          `${get(
            values,
            ['sponsors', '0', 'name'],
            values.title,
          )} Module was successfully updated`,
          {
            variant: 'success',
          },
        );
        await fetchSponsorModules();
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error.response);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
      }
      setShowSpinner(false);
    }
  };

  const onDeleteSponsorModule = async () => {
    setShowSpinner(true);
    try {
      const moduleId = get(sponsorModuleToDelete, 'id');
      await SponsorService.deleteSponsorModule(moduleId);
      enqueueSnackbar(
        `${get(
          sponsorModuleToDelete,
          ['sponsors', '0', 'name'],
          sponsorModuleToDelete.title,
        )} Module was successfully deleted`,
        {
          variant: 'success',
        },
      );
      setViewDeleteSponsorModuleDialog(false);
      await fetchSponsorModules();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  return (
    <div className="sponsor-modules">
      <h2 className="sponsor-modules__title section-title">
        Add a Sponsor/Partner Module
      </h2>
      <Typography className="sponsor-modules__title-caption" variant="caption">
        * Required
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={currentSponsorModule}
        onSubmit={onAddEditSponsorModule}
        validationSchema={sponsorValidationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form
            className="sponsor-modules__add-edit-form"
            onSubmit={handleSubmit}
          >
            <Grid container={true} direction="column">
              <TextField
                className="sponsor-modules__text-field"
                error={touched.title && !!errors.title}
                label="Sponsor Title"
                inputProps={{
                  maxLength: 30,
                }}
                name="title"
                onChange={handleChange}
                required={true}
                type="text"
                value={values.title}
              />
              <ErrorMessage className="error" component="div" name="title" />
              <Typography
                className="sponsor-modules__text-field__caption"
                variant="caption"
              >
                e.g. Our Sponsors, In Partnership with
              </Typography>
            </Grid>
            <FieldArray
              name="sponsors"
              render={(arrayHelpers: any) => (
                <>
                  {values.sponsors.map((sponsor, index) => (
                    <Sponsor
                      key={`sponsorModule-sponsor${index}`}
                      arrayHelpers={arrayHelpers}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setSponsorsToDelete={setSponsorsToDelete}
                      sponsorNum={index}
                      touched={touched}
                      values={values}
                    />
                  ))}
                  <Grid container={true}>
                    <Button
                      color="primary"
                      className="sponsor-modules__add-sponsor"
                      onClick={() =>
                        arrayHelpers.push({ name: '', imageUrl: '' })
                      }
                      variant="text"
                    >
                      <AddOutlinedIcon />
                      Add another sponsor
                    </Button>
                  </Grid>
                </>
              )}
            />
            <Button
              color="primary"
              className="sponsor-modules__save-sponsor"
              type="submit"
              variant="outlined"
            >
              SAVE
            </Button>
          </form>
        )}
      </Formik>
      <Divider />
      <SponsorsTable
        onChangeActive={onChangeActiveSponsorModule}
        onDeleteSponsor={(sponsorModule: any) => {
          setSponsorModuleToDelete(sponsorModule);
          setViewDeleteSponsorModuleDialog(true);
        }}
        onEditSponsor={setCurrentSponsorModule}
        sponsorModules={sponsorModules}
      />
      {viewDeleteSponsorModuleDialog && (
        <DeleteDialog
          dialogTitle="Delete Sponsor Module"
          dialogContent="Are you sure you want to remove this Sponsor Module?"
          onClose={() => {
            setSponsorModuleToDelete(sponsorModuleInitialState);
            setViewDeleteSponsorModuleDialog(false);
          }}
          onSubmit={onDeleteSponsorModule}
        />
      )}
    </div>
  );
};

export default withSnackbar(SponsorModules);
