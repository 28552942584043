import { boolean } from 'yup';
import ICategory from '../../models/Category.model';
import axiosWithAuth, { IPaginatedItems } from '../ServiceConfig';

const baseUrl = `/categories`;

const createCategory = (body: any) =>
  axiosWithAuth.post<ICategory>(baseUrl, body);

const getAllCategories = (storeId: string, limit?: number, page?: number) =>
  axiosWithAuth.get<IPaginatedItems<ICategory> & { maxPriority: number }>(
    `${baseUrl}?storeId=${storeId}&limit=${limit}&page=${page}`,
  );

const getAllPOSCategories =async (query : any) => {
 return await axiosWithAuth.get<IPaginatedItems<ICategory> & { maxPriority: number }>(
    '/categories',
    { params: query },
  );
};

const updateCategory = (categoryId: string, body: any) =>
  axiosWithAuth.put<ICategory>(`${baseUrl}/${categoryId}`, body);

const deleteCategory = (categoryId: string) =>
  axiosWithAuth.delete<void>(`${baseUrl}/${categoryId}`);

export default {
  createCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
  getAllPOSCategories,
};
