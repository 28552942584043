import {
  ICreatePartnerEntity,
  IGetPartnerEvent,
  IGetPartnerLocation,
  IPartnerEvent,
  IPartnerLocation,
} from '../../models/Partner.model';
import axiosWithAuth from '../ServiceConfig';

const basePathEvent = '/partner-events';
const basePathLocation = '/partner-locations';

const createPartnerEvent = async ({
  baseEntityId,
  partnerEntityId,
  partner,
}: ICreatePartnerEntity) => {
  return await axiosWithAuth.post<IPartnerEvent>(`${basePathEvent}`, {
    eventId: baseEntityId,
    partner,
    partnerEventId: partnerEntityId,
  });
};

const createPartnerLocation = async ({
  baseEntityId,
  partnerEntityId,
  partner,
}: ICreatePartnerEntity) => {
  return await axiosWithAuth.post<IPartnerLocation>(`${basePathLocation}`, {
    partner,
    partnerLocationId: partnerEntityId,
    venueLocationId: baseEntityId,
  });
};

const deletePartnerEvent = async (id: string) => {
  return await axiosWithAuth.delete<void>(`${basePathEvent}/${id}`);
};

const deletePartnerLocation = async (id: string) => {
  return await axiosWithAuth.delete<void>(`${basePathLocation}/${id}`);
};

const getPartnerEvents = async (query: IGetPartnerEvent) => {
  return await axiosWithAuth.get<IPartnerEvent[]>(`${basePathEvent}`, {
    params: query,
  });
};

const getPartnerLocations = async (query: IGetPartnerLocation) => {
  return await axiosWithAuth.get<IPartnerLocation[]>(`${basePathLocation}`, {
    params: query,
  });
};

export default {
  createPartnerEvent,
  createPartnerLocation,
  deletePartnerEvent,
  deletePartnerLocation,
  getPartnerEvents,
  getPartnerLocations,
};
