import axiosWithAuth from '../ServiceConfig';
import axiosCreateOrder from '../ServiceConfig';

const baseUrl = `/orders`;

export interface IGetCurrentVenueOrdersParams {
  endTime?: string;
  firstName?: string;
  lastName?: string;
  limit?: number;
  orderNo?: string;
  page?: number;
  refundStatus?: string[];
  sortBy: string;
  sortOrder?: 'ASC' | 'DESC';
  startTime?: string;
  status: string[];
  storeId: string;
  venueId: string;
  tabId?: string;
  eventDay?: string;
  eventName?: string;
}

const getCurrentVenueOrders = (params: IGetCurrentVenueOrdersParams) => {
  return axiosWithAuth.get(`${baseUrl}/current`, {
    params,
  });
};
const getCurrentVenueHistoryOrders = (params: any) => {
  return axiosWithAuth.get(`${baseUrl}/current/orderHistory`, {
    params,
  });
};
const UpdateOrder = (orderId: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${orderId}`, body);

const RefundOrder = (orderId: string, body: any) =>
  axiosWithAuth.post(`${baseUrl}/${orderId}/refund`, body);

const getNewOrdersCount = (body: any) =>
  axiosWithAuth.post(`${baseUrl}/latest`, body);

const printReceipt = (
  orderId: string,
  storeId: string | undefined,
  lineItemGroupId: string | undefined,
) =>
  axiosWithAuth.post(`${baseUrl}/`, null, {
    params: {
      lineItemGroupId,
      storeId,
    },
  });

// const TestPrintReceipt = (
//   orderId: string,
//   storeId: string | undefined,
//   lineItemGroupId: string | undefined,
// ) =>
//   axiosWithAuth.put(`${baseUrl}/${orderId}/printInvoice`, null, {
//     params: {
//       lineItemGroupId,
//       storeId,
//     },
//   });
const addCover = (orderId: string, cover: string) => {
  return axiosWithAuth.post(`${baseUrl}/add-cover/${orderId}`, {cover : cover});
};

export default {
  RefundOrder,
  UpdateOrder,
  getCurrentVenueOrders,
  getNewOrdersCount,
  printReceipt,
  getCurrentVenueHistoryOrders,
  // TestPrintReceipt,
  addCover,
};
