import {
  Button,
  Size,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import XLSX from 'xlsx';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect, useState } from 'react';
import BackButton from '../../../components/BackButton';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import DeleteDialog from '../../../components/DeleteDialog';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { useAppContext } from '../../../config/AppContext';
import deleteIcon from '../../../images/deleteIcon.png';
import editIcon from '../../../images/editIcon.png';
import { IPromotionUser } from '../../../models/PromotionUser.model';
import PromoUserService from '../../../services/Promo/PromoUser.service';
import PromoUserDialog from './PromoUserDialog';
import UserListTemplate, { IWorksheet } from '../../../components/UserTemplate';

const Columns: Array<{ name: string; size: Size }> = [
  { name: 'First Name', size: 'small' },
  { name: 'Last Name', size: 'small' },
  { name: 'Email', size: 'small' },
  { name: 'Phone Number', size: 'small' },
  { name: 'Action', size: 'medium' },
];

interface IEditPromoUsersProps extends WithSnackbarProps {
  onCloseEditUsers: () => void;
  promotionId: string;
}

const EditPromoUsers = (props: IEditPromoUsersProps): JSX.Element => {
  const { enqueueSnackbar, onCloseEditUsers, promotionId } = props;
  const { setShowSpinner } = useAppContext();

  const [page, setPage] = useState<number>(0);
  const [promoUsers, setPromoUsers] = useState<IPromotionUser[]>([]);
  const [promoUserIdToDelete, setPromoUserIdToDelete] = useState<
    string | undefined
  >(undefined);
  const [promoUserToEdit, setPromoUserToEdit] = useState<
    Partial<IPromotionUser> | undefined
  >(undefined);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [searchCriteria, setSearchCriteria] = useState<Partial<IPromotionUser>>(
    {},
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isFile, setIsFile] = useState(false);
  const [isFileError, setIsFileError] = useState(false);
  const [fileError, setFileError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    (async () => {
      await onSearch();
    })();
  }, []);

  const onSearch = async () => await fetchPromoUsers(rowsPerPage, page + 1);

  useEffect(() => {
    fetchPromoUsers(rowsPerPage, page + 1);
  }, [page, rowsPerPage]);

  const fetchPromoUsers = async (limit: number, pageNumber: number) => {
    setShowSpinner(true);

    try {
      const { data } = await PromoUserService.getAll(promotionId, {
        ...searchCriteria,
        limit,
        page: pageNumber,
      });

      setPromoUsers(data.items);
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);

      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const handleAddOrEditPromoUser = async (
    promoUser: Partial<IPromotionUser>,
  ) => {
    setShowSpinner(true);

    try {
      if (promoUser.id) {
        await PromoUserService.update(promotionId, promoUser.id, promoUser);

        enqueueSnackbar('Promotion user has been successfully updated', {
          variant: 'success',
        });
      } else {
        await PromoUserService.create(promotionId, {
          ...promoUser,
        });

        enqueueSnackbar('Promotion has been successfully created', {
          variant: 'success',
        });
      }

      await fetchPromoUsers(rowsPerPage, page + 1);
      setPromoUserToEdit(undefined);
    } catch (error) {
      // tslint:disable-next-line: no-console

      const errorMessage = error.response.data.message
        .map((message: any) => Object.values(message.constraints))
        .flat()
        .join(', ');

      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setShowSpinner(false);
    }
  };

  const handleDeletePromoUser = async () => {
    if (!promoUserIdToDelete) {
      return;
    }

    setShowSpinner(true);

    try {
      await PromoUserService.remove(promotionId, promoUserIdToDelete);
      await fetchPromoUsers(rowsPerPage, 1);
      setPromoUserIdToDelete(undefined);

      enqueueSnackbar('Promotion successfully deleted', { variant: 'success' });
    } catch (error) {
      // tslint:disable-next-line: no-console

      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    } finally {
      setShowSpinner(false);
    }
  };
  const handleSubmitUserList = async () => {
    try {
      if (userList.length < 1) {
        setFileError('File is empty Please check your file');
        setIsFileError(true);
        setIsFile(false);
        return;
      }
      userList.map((user: any) => {
        if (Object.keys(user).length < 1) {
          setFileError('Missing columns Please Check File');
        }
        if (
          !user.firstName ||
          !user.email ||
          !user.phoneNumber ||
          !user.countryCode
        ) {
          setFileError(`Missing columns Please Check File`);
        }
      });
      await PromoUserService.addUserList(promotionId, userList);
      await fetchPromoUsers(rowsPerPage, 1);
      setIsOpen(false);
      setFileError('');
      setIsFileError(false);
      setIsFile(false);
    } catch (error) {
      setFileError('Cloumn Validation Error Please check file');
      setIsFileError(true);
      setIsFile(false);
    }
  };
  const handleFileUpload = (e: any) => {
    setFileError(``);
    setIsFileError(false);
    const uploadedFile = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const requiredColumns = [
        'First Name',
        'Last Name',
        'Email',
        'Phone Number',
        'Country Code',
      ];
      const sheetColumns: any = parsedData[0];

      const missingColumns: any = requiredColumns.filter(
        column => !sheetColumns.includes(column),
      );

      if (missingColumns.length === 0) {
        setIsFile(true);
        setFileError('');
        const usersData: any = [];
        parsedData
          .slice(1)
          .filter((row: any) =>
            row.some(
              (cell: any) =>
                Object.keys(cell).length > 0 &&
                cell !== null &&
                cell !== undefined,
            ),
          )
          .forEach((row: any) => {
            usersData.push({
              firstName: row[0],
              lastName: row[1],
              email: row[2],
              phoneNumber: row[3].toString(),
              countryCode: row[3].toString(),
            });
          });
        setUserList(usersData);
      } else {
        setFileError(`Missing columns: ${missingColumns}`);
        setIsFileError(true);
        setIsFile(false);
      }
    };

    reader.readAsArrayBuffer(uploadedFile);
  };
  const getWorksheets = async (): Promise<any> => {
    const worksheets: IWorksheet[] = [
      {
        name: 'user-list-template',
        data: [
          {
            'First Name': '',
            'Last Name': '',
            Email: '',
            'Phone Number': '',
            'Country Code': '',
          },
        ],
      },
    ];
    return worksheets;
  };
  return (
    <div className="promos">
      <BackButton onClick={onCloseEditUsers} />
      <h2 className="promos__title section-title">Edit Users</h2>

      <div className="promos-pagination-toolbar">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
        <div>
          <Button
            size="large"
            color="primary"
            className="add-promo-button"
            onClick={() => setPromoUserToEdit({})}
            style={{
              marginRight: '20px',
            }}
          >
            <div className="icon-title-wrapper">
              <AddOutlinedIcon />
              <span className="icon-title">Add User</span>
            </div>
          </Button>
          <Button
            size="large"
            color="primary"
            className="add-promo-button"
            style={{
              backgroundColor: '#e3515d',
            }}
            onClick={() => setIsOpen(true)}
          >
            <div className="icon-title-wrapper">
              <AddOutlinedIcon />
              <span className="icon-title">Upload User List</span>
            </div>
          </Button>
        </div>
      </div>
      {promoUserToEdit && (
        <PromoUserDialog
          onClose={() => setPromoUserToEdit(undefined)}
          onSubmit={handleAddOrEditPromoUser}
          promoUser={promoUserToEdit}
        />
      )}
      <div className="promos-table-container">
        <Table className="promos-table">
          <TableHead className="promos-table-header">
            <TableRow>
              {Columns.map((column: { name: string; size: Size }) => (
                <TableCell
                  key={column.name}
                  size={column.size}
                  align="center"
                  variant="head"
                  classes={{ root: 'promos-table-header__cells' }}
                >
                  <p>{column.name}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <TextField
                  margin="dense"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      firstName: event.target.value,
                    })
                  }
                  placeholder="First name"
                  variant="outlined"
                />
              </TableCell>
              <TableCell
                align="left"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <TextField
                  margin="dense"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      lastName: event.target.value,
                    })
                  }
                  placeholder="Last name"
                  variant="outlined"
                />
              </TableCell>
              <TableCell
                align="left"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <TextField
                  margin="dense"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      email: event.target.value,
                    })
                  }
                  placeholder="Email"
                  variant="outlined"
                />
              </TableCell>
              <TableCell
                align="left"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <TextField
                  margin="dense"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      phoneNumber: event.target.value,
                    })
                  }
                  placeholder="Phone number"
                  variant="outlined"
                />
              </TableCell>
              <TableCell
                align="center"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <Button onClick={onSearch} className="search-button">
                  Search
                </Button>
              </TableCell>
            </TableRow>
            {promoUsers.length > 0
              ? promoUsers.map((user: IPromotionUser) => (
                  <TableRow
                    id={user.id}
                    key={user.id}
                    className="promos-table-row"
                  >
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {user.firstName}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {user.lastName}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {user.email}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {user.phoneNumber}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <Button
                        size="small"
                        className="promo-edit-button"
                        onClick={() => setPromoUserToEdit(user)}
                        value="edit"
                      >
                        <div className="icon-title-wrapper">
                          <img
                            src={editIcon}
                            alt="edit icon"
                            title="Edit"
                            width={20}
                          />
                        </div>
                      </Button>
                      <Button
                        size="small"
                        className="promo-edit-button"
                        onClick={() => setPromoUserIdToDelete(user.id)}
                        value="delete"
                      >
                        <div className="icon-title-wrapper">
                          <img
                            src={deleteIcon}
                            alt="delete icon"
                            title="Delete"
                            width={20}
                          />
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : noDataAvailable(Columns.length)}
          </TableBody>
        </Table>
      </div>
      {promoUserIdToDelete && (
        <DeleteDialog
          dialogTitle="Delete Promotion User"
          onClose={() => setPromoUserIdToDelete(undefined)}
          onSubmit={handleDeletePromoUser}
          dialogContent="Are you sure you want to delete this promotion user?"
        />
      )}
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setIsFile(false);
          setFileError('');
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: 'center' }}
          >
            <div>
              <UserListTemplate
                buttonText="User List Template"
                filename="user-list-template"
                getWorksheets={getWorksheets}
              />
            </div>
            Upload List of users those can use this promo code
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            {fileError && (
              <div
                className="error"
                style={{
                  color: '#EE3653',
                }}
              >
                {fileError}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmitUserList}
            color="primary"
            variant="outlined"
            disabled={!isFile || isFileError}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              setIsFile(false);
              setIsFileError(false);
              setFileError('');
            }}
            style={{
              backgroundColor: '#EE3653',
              color: 'white',
              border: 'none',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withSnackbar(EditPromoUsers);
