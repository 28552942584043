import {
  MenuItem,
  Select,
  Tab,
  TablePagination,
  Tabs,
} from '@material-ui/core';
import { format } from 'date-fns';
import { get } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import ExportToExcel, { IWorksheet } from '../../../components/ExportToExcel';
import { useAppContext } from '../../../config/AppContext';
import ILineItemGroup from '../../../models/LineItemGroup.model';
import { IOrder } from '../../../models/Order.model';
import { IStore, ValidStoreType } from '../../../models/Store.model';
import LineItemGroupService from '../../../services/LineItemGroup.service';
import OrdersService from '../../../services/Orders/Orders.service';
import {
  getTimezoneDate,
  getUTCDate,
} from '../../../services/Util/Util.service';
import SearchFields from '../AllOrdersTable/OrdersSearchFields';
import OrdersTable from '../AllOrdersTable/OrdersTable';
import {
  DefaultSearchCriteria,
  IOrderTabComponentProps,
  RowsPerPageOptions,
} from '../Orders';
import {
  currentOrdersSecondaryTabs,
  ICurrentOrdersTab,
  IUpdateLineItemGroup,
  ordersSchema,
} from '../Orders.config';
import './CurrentOrders.scss';

const CurrentOrders = (props: IOrderTabComponentProps): JSX.Element => {
  const {
    enqueueSnackbar,
    handleChangePage,
    handleChangeRowsPerPage,
    handleStoreSelect,
    isLoading,
    lastRefreshRequested,
    orders,
    runners,
    searchCriteria,
    selectedRunnerId,
    selectedStoreId,
    setIsLoading,
    setOrders,
    setSearchCriteria,
    setSelectedRunnerId,
    triggerRefresh,
    venueId,
  } = props;

  const { stores, venue } = useAppContext();
  const [lastRefreshTime, setLastRefreshTime] = useState<Date>(new Date());
  const [tabValue, setTabValue] = useState<number>(0);
  const timeout = useRef<number | undefined>(undefined);
  const [totalCount, setTotalCount] = useState<number>(0);

  const getAllOrders = async (
    pageOverride?: number,
    limitOverride?: number,
    tabId?: string,
  ) => {
    const {
      eventDay,
      eventName,
      endTime,
      firstName,
      lastName,
      orderNo,
      page,
      refundStatus,
      rowsPerPage,
      startTime,
      status,
    } = searchCriteria;

    const { data } = await OrdersService.getCurrentVenueOrders({
      endTime: endTime
        ? getUTCDate(endTime, get(venue, 'timezone', '')).toISOString()
        : undefined,
      eventDay: eventDay ? eventDay : undefined,
      eventName: eventName ? eventName : undefined,
      firstName,
      lastName,
      limit: limitOverride || rowsPerPage,
      orderNo,
      page: (pageOverride || page) + 1, // needs to be 1 index
      refundStatus,
      sortBy: 'prepDueTime',
      sortOrder: 'ASC',
      startTime: startTime
        ? getUTCDate(startTime, get(venue, 'timezone', '')).toISOString()
        : undefined,
      status,
      storeId: selectedStoreId!,
      tabId: tabId?.toString(),
      venueId,
    });
    return data;
  };

  const loadOrdersToState = async () => {
    try {
      setIsLoading(true);
      if (!!selectedStoreId) {
        const { items, totalItems } = await getAllOrders();
        setOrders(items);
        setTotalCount(totalItems);
        setLastRefreshTime(new Date());
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedTab = currentOrdersSecondaryTabs[tabValue];

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(
      triggerRefresh,
      selectedTab.pollingInterval,
    );

    (async () => {
      await loadOrdersToState();
    })();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [
    lastRefreshRequested,
    searchCriteria.page,
    searchCriteria.rowsPerPage,
    selectedStoreId,
    tabValue,
  ]);

  const updateLineItemGroupsWithoutRefresh = (
    updatedLineItemGroup: ILineItemGroup,
  ) => {
    const store = stores.find((s: IStore) => s.id === selectedStoreId);
    if (!store) {
      return;
    }
    const selectedTab = currentOrdersSecondaryTabs[tabValue];

    const updatedOrderIndex = orders.findIndex(
      (order: IOrder) => order.id === updatedLineItemGroup.orderId,
    );

    // ensure actionable order is found in `orders` array
    if (updatedOrderIndex < 0) {
      return;
    }

    const updatedLineItemGroupIndex = orders[
      updatedOrderIndex
    ].lineItemGroups.findIndex(
      (lineItemGroup: ILineItemGroup) =>
        lineItemGroup.id === updatedLineItemGroup.id,
    );

    if (updatedLineItemGroupIndex < 0) {
      return;
    }

    if (
      (store.type === ValidStoreType.FULFILLMENT_CENTER &&
        selectedTab.status.includes(updatedLineItemGroup.status)) ||
      (store.type !== ValidStoreType.FULFILLMENT_CENTER &&
        selectedTab.status.includes(updatedLineItemGroup.order.status))
    ) {
      // updated state belongs in active tab
      setOrders(
        Object.assign([...orders], {
          [updatedOrderIndex]: {
            ...orders[updatedOrderIndex],
            lineItemGroups: Object.assign(
              [...orders[updatedOrderIndex].lineItemGroups],
              {
                [updatedLineItemGroupIndex]: updatedLineItemGroup,
              },
            ),
            status: updatedLineItemGroup.order.status,
          },
        }),
      );
    } else {
      // updated state does not belong in active tab, so remove it
      setOrders([
        ...orders.filter(
          (order: IOrder, index: number) => index !== updatedOrderIndex,
        ),
      ]);
    }
  };

  const updateLineItemGroupStatus = async (
    lineItemGroupId: string,
    body: IUpdateLineItemGroup,
  ) => {
    try {
      const { data: updatedLineItemGroup } = await LineItemGroupService.update(
        lineItemGroupId,
        body,
      );

      updateLineItemGroupsWithoutRefresh(updatedLineItemGroup);

      enqueueSnackbar('Status has been successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
  };

  const handleSelectRunner = (runnerId: string) =>
    setSelectedRunnerId(runnerId);

  const handleTabChange = async (event: any, newTabIndex: number) => {
    const selectedTab = currentOrdersSecondaryTabs[newTabIndex];

    if (tabValue !== newTabIndex) {
      setOrders([]);
      setTabValue(newTabIndex);
      setSearchCriteria({
        ...DefaultSearchCriteria[0],
        rowsPerPage: searchCriteria.rowsPerPage,
        status: selectedTab.status,
      });
    }
  };

  const handleSubOrders = async (tabId: string, hide?: boolean) => {
    const { items } = await getAllOrders(0, 10, tabId);
    const mainTabIndex = orders.findIndex(o => o.tabId === tabId);
    if (hide) {
      const indexes = orders
        .map((elm, idx) =>
          elm.tabId === tabId && elm.tabOrderType === 'SUB_ORDER' ? idx : '',
        )
        .filter(String);
      const ordersWithoutTabSubOrders = orders.filter((o, i) => {
        return !indexes.includes(i);
      });
      setOrders(ordersWithoutTabSubOrders);
    } else {
      const ordersWithSubOrders = orders.concat(
        items,
        orders.splice(mainTabIndex + 1, orders.length - 1),
      );
      setOrders(ordersWithSubOrders);
    }
  };

  const getWorksheets = async (): Promise<IWorksheet[]> => {
    const { items } = await getAllOrders(0, Number.MAX_SAFE_INTEGER);

    const timezone = get(venue, 'timezone', '');
    const dateDisplayFormat = 'MM/dd/yyyy hh:mm a';

    const orderSummaryRecords = [];
    const lineItemRecords = [];

    /* tslint:disable:object-literal-sort-keys */
    for (const order of items) {
      orderSummaryRecords.push({
        'Order Number': order.orderNo,
        'Store Name': order.store.name,
        'Order Date': format(
          getTimezoneDate(new Date(order.createdTime), timezone),
          dateDisplayFormat,
        ),
        'Due Date': format(
          getTimezoneDate(new Date(order.prepDueTime), timezone),
          dateDisplayFormat,
        ),
        Type: order.type,
        Instructions: order.instructions,
        'First Name': order.user ? order.user.firstName : '',
        'Last Name': order.user ? order.user.lastName : '',
        Email: order.user ? order.user.email : '',
        'Phone Number': order.user ? order.user.phoneNumber : '',
        'Event Name': order.event ? order.event.name : '',
        'Order Total': order.total,
        Currency: order.currency,
      });

      if (!order.lineItems) {
        continue;
      }

      for (const lineItem of order.lineItems) {
        lineItemRecords.push({
          'Order Number': order.orderNo,
          'Store Name': order.store.name,
          'Order Date': format(
            getTimezoneDate(new Date(order.createdTime), timezone),
            dateDisplayFormat,
          ),
          'Due Date': format(
            getTimezoneDate(new Date(order.prepDueTime), timezone),
            dateDisplayFormat,
          ),
          'Event Name': order.event ? order.event.name : '',
          'First Name': order.user ? order.user.firstName : '',
          'Last Name': order.user ? order.user.lastName : '',
          Email: order.user ? order.user.email : '',
          'Phone Number': order.user ? order.user.phoneNumber : '',
          Category: lineItem.product.category.name,
          'Line Item': lineItem.product.name,
          Quantity: lineItem.quantity,
          Instructions: lineItem.instructions,
          Total: lineItem.total,
          Currency: order.currency,
        });

        if (!lineItem.subSelections) {
          continue;
        }

        for (const addOnCategory of lineItem.subSelections) {
          for (const addOn of addOnCategory.subSelections) {
            lineItemRecords.push({
              'Order Number': order.orderNo,
              'Store Name': order.store.name,
              'Order Date': format(
                getTimezoneDate(new Date(order.createdTime), timezone),
                dateDisplayFormat,
              ),
              'Due Date': format(
                getTimezoneDate(new Date(order.prepDueTime), timezone),
                dateDisplayFormat,
              ),
              'Event Name': order.event ? order.event.name : '',
              'First Name': order.user ? order.user.firstName : '',
              'Last Name': order.user ? order.user.lastName : '',
              Email: order.user ? order.user.email : '',
              'Phone Number': order.user ? order.user.phoneNumber : '',
              Category: addOnCategory.name,
              'Line Item': addOn.name,
              Quantity: 1,
              Instructions: '',
              Total: addOn.price,
              Currency: order.currency,
            });
          }
        }
      }
    }
    /* tslint:enable:object-literal-sort-keys */

    const worksheets: IWorksheet[] = [
      {
        data: orderSummaryRecords,
        name: 'order-summary',
      },
      {
        data: lineItemRecords,
        name: 'line-items',
      },
    ];

    return worksheets;
  };

  const lastRefreshTimeValue = lastRefreshTime
    ? format(
        getTimezoneDate(new Date(lastRefreshTime), get(venue, 'timezone', '')),
        'MM-dd-yyyy hh:mm:ss a',
      )
    : '';

  return (
    <div className="orders">
      {stores.length > 0 && (
        <div className="search-groupby">
          <p className="label">Store: </p>
          <Select
            value={selectedStoreId}
            onChange={handleStoreSelect}
            classes={{
              root: 'select-store',
            }}
          >
            {stores.map((store: IStore) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className="orders__container">
        <div className="orders__tabs">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            classes={{
              indicator: 'indicator',
            }}
          >
            {currentOrdersSecondaryTabs.map(
              (secondaryTab: ICurrentOrdersTab, i: number) => (
                <Tab
                  key={i}
                  value={i}
                  label={secondaryTab.name}
                  classes={{
                    root: 'current-order-tab',
                    selected: 'selectedTab',
                  }}
                />
              ),
            )}
          </Tabs>
        </div>
        <div className="for-border-effect">
          <div className="orders__pagination">
            <TablePagination
              classes={{
                caption: 'pagination-caption',
                root: 'pagination-control',
                select: 'pagination-select',
                toolbar: 'pagination-control-buttons',
              }}
              colSpan={3}
              component="div"
              count={totalCount}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={searchCriteria.page}
              rowsPerPage={searchCriteria.rowsPerPage}
              rowsPerPageOptions={RowsPerPageOptions}
              SelectProps={{
                inputProps: { 'aria-label': 'Items per page' },
                native: true,
              }}
            />
            <div className="new-orders-refresh">
              Last Updated on {lastRefreshTimeValue} (updates every{' '}
              {currentOrdersSecondaryTabs[tabValue].updateIntervalText})
            </div>
          </div>
          <div className="orders__table">
            <OrdersTable
              assumeAllOrdersAreCompleted={false}
              expandOrders={true}
              isLoading={isLoading}
              onUpdateLineItemGroupStatus={updateLineItemGroupStatus}
              orders={orders}
              refresh={triggerRefresh}
              runners={runners}
              schema={ordersSchema}
              selectedRunnerId={selectedRunnerId}
              selectedStoreId={selectedStoreId}
              selectRunner={handleSelectRunner}
              showActionButton={true}
              tabId={handleSubOrders}
              tabValue={tabValue}
            >
              <SearchFields
                {...{
                  currentTab: currentOrdersSecondaryTabs[tabValue].id,
                  isLoading,
                  searchCriteria,
                  setSearchCriteria,
                  triggerRefresh,
                }}
              />
              {orders.length > 0 &&
                currentOrdersSecondaryTabs[tabValue].id === 'pre-orders' && (
                  <ExportToExcel
                    buttonText="Export to Excel"
                    filename="Pre-Orders"
                    getWorksheets={getWorksheets}
                  />
                )}
            </OrdersTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(CurrentOrders);
