import {
  Badge,
  Button,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';
import { format } from 'date-fns';
import { find, get, isEmpty, isEqual } from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Channel } from 'twilio-chat/lib/channel';
import { AppContext } from '../../../config/AppContext';
import LocalStorageService from '../../../services/LocalStorage/LocalStorage.service';
import './Chat.scss';

interface IChatState {
  channelsToShow: Channel[];
  currentChannelId: string;
  currentStoreId: string;
  globalChannels: Channel[];
  message: string;
  messages: any[];
}

export default class Chat extends React.Component<
  RouteComponentProps,
  IChatState
> {
  private messagesEnd: any;

  constructor(props: RouteComponentProps) {
    super(props);
    this.messagesEnd = React.createRef();

    const selectedStoreId = LocalStorageService.getLocalData('selectedStoreId');
    this.state = {
      channelsToShow: [],
      currentChannelId: '',
      currentStoreId:
        selectedStoreId === 'All Stores' ? '' : selectedStoreId || '',
      globalChannels: [],
      message: '',
      messages: [],
    };
  }

  public componentDidMount() {
    const newStoreId = get(this.props, ['location', 'state', '0', 'storeId']);
    const { channels, setCurrentChannel, setSelectedStoreId } = this.context;

    if (newStoreId) {
      // TODO: Use AppContext instead of state once this component is functional
      this.setState({
        currentStoreId: newStoreId,
      });
      setSelectedStoreId(newStoreId);
    }

    setCurrentChannel(undefined);
    this.setState({ globalChannels: channels }, () => this.setChannels());
  }

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      return this.messagesEnd.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  public async componentDidUpdate(prevProps: any, prevState: any) {
    const propsState = get(this.props, ['location', 'state', '0']);
    const newCurrentChannel = get(propsState, 'channelSid');
    const newStoreId = get(propsState, 'storeId');
    const { channels, setCurrentChannel, setSelectedStoreId } = this.context;

    if (newStoreId && newStoreId !== prevState.currentStoreId) {
      setCurrentChannel(undefined);
      // TODO: Use AppContext instead of state once this component is functional
      this.setState(
        {
          currentStoreId: newStoreId,
        },
        () => this.setChannels(),
      );

      setSelectedStoreId(newStoreId);

      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {},
      });
    }

    if (newCurrentChannel) {
      if (newCurrentChannel !== prevState.currentChannelId) {
        this.setState({
          currentChannelId: newCurrentChannel,
        });

        const channelToSet = channels.find(
          (channel: any) => newCurrentChannel === channel.sid,
        );
        await this.changeChannel(channelToSet);

        this.props.history.replace({
          pathname: this.props.location.pathname,
          state: {},
        });
      }
      await this.updateUnreadMessages(newCurrentChannel);
    }

    if (!isEmpty(channels) && !isEqual(channels, prevState.globalChannels)) {
      this.setState({ globalChannels: channels }, () => this.setChannels());
    }

    this.scrollToBottom();
  }

  public setChannels = () => {
    const { currentChannel, setCurrentChannel } = this.context;
    const { globalChannels, currentStoreId } = this.state;

    const channelsToShow = !isEmpty(currentStoreId)
      ? globalChannels.filter(
          (channel: Channel) => channel.friendlyName === currentStoreId,
        )
      : [];

    this.setState({ channelsToShow });

    if (channelsToShow.length > 0 && !currentChannel) {
      setCurrentChannel(channelsToShow[0]);
      this.changeChannel(channelsToShow[0]);
    }
  };

  public sendMessage = async () => {
    const { message } = this.state;
    const { currentChannel } = this.context;

    if (currentChannel) {
      await currentChannel.sendMessage(message);
      this.setState({
        message: '',
      });
    }

    this.updateUnreadMessages(currentChannel.sid);
  };

  public updateUnreadMessages = async (channelSid: string) => {
    const { unreadMessages, setUnreadMessages } = this.context;

    const updatedUnreadMessages = unreadMessages;

    if (channelSid in updatedUnreadMessages) {
      delete updatedUnreadMessages[`${channelSid}`];
      setUnreadMessages(updatedUnreadMessages);
    }
  };

  public handleStoreChange = (event: React.ChangeEvent<{}>) => {
    const { setCurrentChannel, setSelectedStoreId } = this.context;
    const newValue = get(event, ['target', 'value']);
    setSelectedStoreId(newValue);
    setCurrentChannel(undefined);

    // TODO: Use AppContext instead of state once this component is functional
    this.setState(
      {
        channelsToShow: [],
        currentChannelId: '',
        currentStoreId: newValue,
        messages: [],
      },
      () => this.setChannels(),
    );
  };

  public handleChange = (e: any) => {
    const {
      target: { value },
    } = e;

    this.setState({
      message: value,
    });
  };

  public changeChannel = async (channel: Channel) => {
    try {
      const { currentChannel, setCurrentChannel } = this.context;
      if (currentChannel) {
        currentChannel.removeListener('messageAdded', this.onMessageAdded);
      }
      const { items } = await channel.getMessages();
      this.setState({
        currentChannelId: channel.sid,
        messages: items,
      });

      // mark all messages in channel as read
      await channel.setAllMessagesConsumed();

      this.updateUnreadMessages(channel.sid);
      setCurrentChannel(channel);

      channel.on('messageAdded', this.onMessageAdded);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e);
    }
  };

  public onMessageAdded = (message: any) => {
    const { messages } = this.state;

    const existingMessage = find(messages, {
      state: { sid: message.state.sid },
    });
    if (!existingMessage) {
      this.setState({
        messages: messages.concat(message),
      });
    }
  };

  public render() {
    const { currentChannel, unreadMessages, venue, stores } = this.context;
    const { channelsToShow, currentStoreId, message, messages } = this.state;

    const currentChannelName =
      (currentChannel && currentChannel.uniqueName) || '';

    return (
      <div className="support">
        <div className="support__store">
          <InputLabel className="support__store__label">Store:</InputLabel>
          <Select
            autoWidth={false}
            classes={{
              root: 'support__store__root',
            }}
            onChange={this.handleStoreChange}
            value={currentStoreId || ''}
          >
            {stores.map((store: any, i: number) => (
              <MenuItem
                key={`support__store__select-value__${i}`}
                value={store.id}
              >
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Grid className="support-chat" container={true}>
          <Grid item={true} className="support-chat__channels" sm={4}>
            {channelsToShow.map((channel: Channel) => (
              <ListItem
                className={classnames(
                  'channel',
                  currentChannelName === channel.uniqueName &&
                    'channel__active',
                  channel.sid in unreadMessages && 'channel__unread',
                )}
                key={channel.sid}
                onClick={() => this.changeChannel(channel)}
                divider={true}
              >
                <Badge
                  classes={{
                    badge: 'channel__unread__dot',
                    root: 'channel__label',
                  }}
                  color="secondary"
                  invisible={!(channel.sid in unreadMessages)}
                  variant="dot"
                >
                  <p>{channel.uniqueName}</p>
                </Badge>
              </ListItem>
            ))}
          </Grid>
          <Grid item={true} className="support-chat__show-messages" sm={8}>
            <Grid
              container={true}
              className="support-chat__show-messages-container"
              direction="column"
            >
              {currentChannelName && (
                <Typography
                  align="center"
                  className="support-chat__show-messages-container__header"
                  variant="h6"
                >
                  {currentChannelName}
                </Typography>
              )}
              <Grid
                container={true}
                className="support-chat__show-messages-container__panel"
                direction="column"
              >
                <Grid
                  item={true}
                  className="support-chat__show-messages-container__panel-list"
                >
                  <List>
                    {messages.map((incomingMessage: any, index: number) => {
                      return (
                        <ListItem
                          key={incomingMessage.state.sid}
                          ref={
                            index === messages.length - 1
                              ? this.messagesEnd
                              : ''
                          }
                          className={
                            incomingMessage.state.author === venue.id
                              ? 'support-chat__show-messages-container__panel-message__sender'
                              : 'support-chat__show-messages-container__panel-message__recipient'
                          }
                        >
                          <div className="message-header">
                            <h4>
                              {incomingMessage.state.author === venue.id
                                ? venue.name
                                : incomingMessage.state.author}
                            </h4>
                            <h5>
                              {format(
                                incomingMessage.state.timestamp,
                                'MM/dd/yyyy hh:mm a',
                              )}
                            </h5>
                          </div>
                          <p>{incomingMessage.state.body}</p>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid
                  item={true}
                  className="support-chat__show-messages-container__panel-current"
                >
                  <Grid
                    className="support-chat__show-messages-container__panel-current-container"
                    container={true}
                    direction="row"
                  >
                    <Grid
                      item={true}
                      className="support-chat__show-messages-container__panel-current-container__message"
                    >
                      <TextField
                        value={this.state.message}
                        className="support-chat__show-messages-container__panel-current-container__message-field"
                        variant="outlined"
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Button
                      className="support-chat__show-messages-container__panel-current-container__button"
                      color="primary"
                      disabled={message.length === 0}
                      variant="outlined"
                      onClick={this.sendMessage}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Chat.contextType = AppContext;
