import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { useAppContext } from '../../../config/AppContext';
import { IStore } from '../../../models/Store.model';
import { getHeadersObject } from '../../../services/Util/Util.service';
import { ExportCSV } from '../ExportCSV';
import { taxReportSummaryHeaders } from '../ReportingConfig';
import { chain } from 'lodash';

interface IProps {
  onChangeStore: (storeId: string) => void;
  selectedStoreId: string | undefined;
  venueCurrency: string;
  venueTaxReportData: any[];
}

export function TaxReport({
  onChangeStore,
  selectedStoreId,
  venueTaxReportData,
}: IProps) {
  const [csvData, setCsvData] = useState([{}]);
  const [taxData, setTaxData] = useState<any>('');
  const [objectKeys, setKeys] = useState(['']);
  const [totalTax, setTotalTax] = useState(0);
  const { stores } = useAppContext();

  const setData = () => {
    const temp = new Map();
    venueTaxReportData?.forEach(item => {
      item?.lineItems?.forEach((lineItem: any) => {
        if (!lineItem?.percentage || !lineItem?.Type ||  lineItem?.percentage===null || lineItem?.Type === null) {
          const otherTax = temp.get('Other Tax') || {
            amount: 0,
          };
          otherTax.amount += +(lineItem?.totalTaxItem
            ? lineItem?.totalTaxItem
            : 0
          ).toFixed(2);
          temp.set('Other Tax', otherTax);
        } else {
          if (!lineItem?.taxRates?.length) {
            const subSelectionCost = chain(lineItem?.subSelections)
              .flatMap(sc =>
                sc?.subSelections.map((sp: any) => sp?.price * sp?.quantity),
              )
              .sum()
              .value();
            const total =
              (lineItem.productPrice +
                (subSelectionCost ? subSelectionCost : 0)) *
              lineItem.quantity;
            if (lineItem.Type === 'VENUE_SALES') {
              const salesTax = temp.get('Sales Tax') || {
                percent: lineItem.percentage,
                amount: 0,
              };
              salesTax.amount += +((total * lineItem.percentage) / 100).toFixed(
                2,
              );
              temp.set('Sales Tax', salesTax);
            } else {
              const alcoholTax = temp.get('Alcohol Tax') || {
                percent: lineItem.percentage,
                amount: 0,
              };
              alcoholTax.amount += +(
                (total * lineItem.percentage) /
                100
              ).toFixed(2);
              temp.set('Alcohol Tax', alcoholTax);
            }
          } else {
            lineItem?.taxRates?.forEach((tax: any) => {
              const accumulatedTax = temp.get(tax.name) || {
                percent: tax.percent,
                amount: 0,
              };
              accumulatedTax.amount +=
                (tax.percent * lineItem.totalTaxItem) / lineItem.percentage;
              temp.set(tax.name, accumulatedTax);
            });
          }
        }
      });
      if (item?.convenienceFee > 0) {
        const convenienceFeeTax = temp.get('convenienceFee Tax') || {
          percent: item.venueSalesTaxPercent,
          amount: 0,
        };
        const salesTaxPercent =item.venueSalesTaxPercent;
        convenienceFeeTax.amount += +(
          (item?.convenienceFee * salesTaxPercent) /
          100
        ).toFixed(2);
        temp.set('convenienceFee Tax', convenienceFeeTax);
      }
    });
    const result = Array?.from(temp, ([name, value]) => ({ name, ...value }));
    setTaxData(result);
    setTotalTax(
      venueTaxReportData?.reduce((acc, current) => {
        return acc + current.totalTaxOrder;
      }, 0),
    );
  };
  const setCsv = (objectValus: any) => {
    const summary: Array<{}> = [];
    if (!objectValus.length) {
      summary.push(getHeadersObject(taxReportSummaryHeaders));
    } else {
      objectValus.map((objectKey: any, ii: number) => {
        const obj = {
          'Tax Amount': `$${taxData[objectKey]?.amount.toFixed(2)}`,
          'Tax Name': `${taxData[objectKey]?.name}`,
          'Tax Percentage': `${taxData[objectKey]?.percent}%`,
        };

        summary.push(obj);
      });
      const tempobj = {
        'Tax Amount': `$${totalTax.toFixed(2)}`,
        'Tax Name': 'totalTax',
        'Tax Percentage': ``,
      };
      summary.push(tempobj);
    }

    setCsvData(summary);
  };
  useEffect(() => {
    setData();
  }, [venueTaxReportData]);

  useEffect(() => {
    const tempData = Object.keys(taxData);
    if (tempData) {
      setKeys(tempData);
      setCsv(tempData);
    } else {
      setKeys([]);
      setCsv([]);
    }
  }, [taxData]);

  const handleStoreSelect = (event: any) => {
    let newSelectedStoreId = get(event, ['target', 'value']);
    if (newSelectedStoreId === 'All Stores') {
      newSelectedStoreId = undefined;
    }
    onChangeStore(newSelectedStoreId);
  };

  return (
    <>
      <div className="records-container">
        <div className="search-groupby">
          <p className="label">Store: </p>
          <Select
            value={selectedStoreId || 'All Stores'}
            onChange={handleStoreSelect}
            classes={{
              root: 'select-store',
            }}
          >
            <MenuItem key="" value="All Stores">
              All Stores
            </MenuItem>
            {stores.map((store: IStore) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <ExportCSV csvData={csvData} fileName={'Tax-Report-Summary'} />
      </div>

      <Table className="reporting__table">
        <TableHead>
          <TableRow>
            {taxReportSummaryHeaders.map(text => (
              <TableCell
                key={text}
                classes={{
                  head: 'table-header',
                }}
              >
                {text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!objectKeys.length
            ? noDataAvailable(3)
            : objectKeys.map((objectKey: any, ii: number) => (
                <Fragment key={ii}>
                  <TableRow>
                    <TableCell className="table-cell table-cell-no-data">
                      {`${taxData[objectKey]?.name}`}
                    </TableCell>
                    <TableCell className="table-cell table-cell-no-data">
                      {taxData[objectKey]?.percent ?`${taxData[objectKey]?.percent}%` :' '}
                    </TableCell>
                    <TableCell className="table-cell table-cell-no-data">
                      {`$${taxData[objectKey]?.amount.toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
          {!objectKeys.length ? null : (
            <TableRow>
              <TableCell className="table-cell table-cell-no-data">
                Total
              </TableCell>
              <TableCell className="table-cell table-cell-no-data" />
              <TableCell className="table-cell table-cell-no-data">
                {`$${totalTax?.toFixed(2)}`}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
