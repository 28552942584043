import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/sponsor-modules`;

const createSponsorModule = (body: any) => axiosWithAuth.post(baseUrl, body);

const createSponsor = (sponsorModuleId: string, body: any) =>
  axiosWithAuth.post(`${baseUrl}/${sponsorModuleId}/sponsors`, body);

const getAllSponsorModules = (venueId: string, limit: number, page: number) =>
  axiosWithAuth.get(
    `${baseUrl}?venueId=${venueId}&limit=${limit}&page=${page}`,
  );

const getSponsorModule = (sponsorModuleId: string) =>
  axiosWithAuth.get(`${baseUrl}/${sponsorModuleId}`);

const getAllSponsors = (sponsorModuleId: string) =>
  axiosWithAuth.get(`${baseUrl}/${sponsorModuleId}/sponsors`);

const updateSponsorModule = (sponsorModuleId: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${sponsorModuleId}`, body);

const updateSponsor = (sponsorModuleId: string, sponsorId: string, body: any) =>
  axiosWithAuth.put(
    `${baseUrl}/${sponsorModuleId}/sponsors/${sponsorId}`,
    body,
  );

const deleteSponsorModule = (sponsorModuleId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${sponsorModuleId}`);

const deleteSponsor = (sponsorModuleId: string, sponsorId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${sponsorModuleId}/sponsors/${sponsorId}`);

export default {
  createSponsor,
  createSponsorModule,
  deleteSponsor,
  deleteSponsorModule,
  getAllSponsorModules,
  getAllSponsors,
  getSponsorModule,
  updateSponsor,
  updateSponsorModule,
};
