import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorMessage, Formik } from 'formik';
import { get } from 'lodash';
import React, { Fragment } from 'react';
import { NotificationValidationSchema } from '../Notificationconfig';
import { INotificationConfig, notificationConfig } from '../Notificationconfig';
import './NotificationDialog.scss';

const NotificationDialog = (props: any) => {
  const { notification, close, handleSubmitNotification } = props;

  const preProcessFinalValues = (values: any) => {
    const { scheduleTime } = values;
    if (scheduleTime && typeof scheduleTime !== 'string') {
      values.scheduleTime = values.scheduleTime.toISOString();
    }
    return values;
  };

  return (
    <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span className="section-title">
          {notification.id ? 'Edit Notification' : 'Add New Notification'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="notification-form">
          <Formik
            initialValues={notification}
            onSubmit={values => {
              handleSubmitNotification(preProcessFinalValues(values));
            }}
            validationSchema={NotificationValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleBlur,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                {notificationConfig.map(
                  (input: INotificationConfig, index: any) => (
                    <div key={index} className="notification-container">
                      <TextField
                        required={true}
                        error={
                          get(touched, input.fieldName) &&
                          !!get(errors, input.fieldName)
                        }
                        className="notification-form__field"
                        name={input.fieldName}
                        placeholder={input.placeholder}
                        inputProps={{
                          type: 'string',
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={input.label}
                        value={get(values, input.fieldName)}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name={input.fieldName}
                      />
                    </div>
                  ),
                )}
                {false && (
                  <Fragment>
                    <div>
                      <FormLabel required={true} component="label">
                        Schedule Delivery :
                      </FormLabel>
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        name="scheduleDelivery"
                        color="primary"
                        checked={values.scheduleDelivery}
                        value={values.scheduleDelivery}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {values.scheduleDelivery && (
                      <div className="schedule-time-container">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            required={true}
                            name="scheduleTime"
                            ampm={true}
                            disablePast={notification.id ? false : true}
                            className="notification-form__field"
                            variant="inline"
                            margin="normal"
                            label="Schedule Time"
                            value={values.scheduleTime}
                            onChange={value => {
                              setFieldValue('scheduleTime', value);
                            }}
                          />
                          <ErrorMessage
                            className="error"
                            component="div"
                            name="scheduleTime"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  </Fragment>
                )}
                <div className="form-control">
                  <FormLabel required={true} component="label">
                    Active :
                  </FormLabel>
                  <Switch
                    classes={{
                      track: 'custom-colored-switch-track',
                    }}
                    name="active"
                    color="primary"
                    checked={values.active}
                    value={values.active}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="button-holder-different-margin">
                  <DialogActions>
                    <Button onClick={close} color="primary" variant="outlined">
                      <div className="icon-title-wrapper">
                        <UndoOutlinedIcon />
                        <span className="icon-title">Cancel</span>
                      </div>
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={Object.keys(errors).length > 0 ? true : false}
                    >
                      <div className="icon-title-wrapper">
                        <CheckOutlinedIcon />
                        <span className="icon-title">
                          {notification.id ? 'Update' : 'Add'}
                        </span>
                      </div>
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationDialog;
