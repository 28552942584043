import axiosWithAuth from '../ServiceConfig';

const baseUrl = `/promotions`;

const createPromo = (body: any) => axiosWithAuth.post(`${baseUrl}`, body);

const updatePromo = (id: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${id}`, body);

const getAllPromos = (
  venueId: string,
  limit: number,
  page: number,
  code: string,
) =>
  axiosWithAuth.get(
    `${baseUrl}?venueId=${venueId}&limit=${limit}&page=${page}&code=${code}`,
  );

const storePromos=(
  venueId: string,
  storeId: string,
)=>
axiosWithAuth.get(`${baseUrl}/store/${storeId}/${venueId}`,);
   


const deletePromo = (id: string) => axiosWithAuth.delete(`${baseUrl}/${id}`);

export default {
  baseUrl,
  createPromo,
  deletePromo,
  getAllPromos,
  updatePromo,
  storePromos
};
