import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { addHours, format, getHours } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import GeneralStatsChart from '../../components/GeneralStatsChart';
import OrderStats from '../../components/OrderStats';
import Overview from '../../components/Overview';
import TotalOrdersBar from '../../components/TotalOrdersBar';
import { useAppContext } from '../../config/AppContext';
import {
  emptyDashboard,
  IVenueDashboard,
  IVenueDashboardTime,
} from '../../routes/Reporting/ReportingConfig';
import ReportingService, {
  ReportType,
} from '../../services/Reporting/Reporting.service';
import {
  getCurrencyPrefix,
  getDateString,
} from '../../services/Util/Util.service';
import './Dashboard.scss';

const Dashboard = (props: any) => {
  const { venueId, startDate, endDate, dashBoardStoreId, reportData } = props;

  const { setShowSpinner, venue } = useAppContext();
  const [dashBoardStartDate, setDashboardStartDate] = useState(
    new Date().toISOString(),
  );
  const [dashBoardEndDate, setDashboardEndDate] = useState(
    new Date().toISOString(),
  );

  const [venueDashboardData, setVenueDashboardData] = useState<IVenueDashboard>(
    emptyDashboard,
  );

  // useEffect(() => {
  //   const localStartDate = getDateString(new Date(startDate));
  //   setDashboardStartDate(localStartDate);
  //   const localEndDate = getDateString(new Date(endDate));
  //   setDashboardEndDate(localEndDate);
  // }, [startDate, endDate]);

  // Hook to update the dashboard every minute
  // useEffect(() => {
  //   const intervalId = setInterval(
  //     async () => await getVenueDashboardData(),
  //     60000,
  //   );
  //   return () => clearInterval(intervalId);
  // }, [dashboardDate]);

  // Hook to update the dashboard every time the date is changed
  // useEffect(() => {
  //   (async () => await getVenueDashboardData())();
  // }, [dashBoardStartDate, dashBoardEndDate, dashBoardStoreId]);

  useEffect(() => {
    if (reportData?.orders?.times) {
      const formattedTimes: any = formatTimesArray(reportData?.orders?.times);
      reportData.orders.formattedTimes = formattedTimes;
      setVenueDashboardData(reportData);
    }
  }, [reportData, dashBoardStartDate, dashBoardEndDate, dashBoardStoreId]);

  // const getVenueDashboardData = async () => {
  //   setShowSpinner(true);
  //   const startTime = dashBoardStartDate;
  //   const endTime = dashBoardEndDate;
  //   try {
  //     const {
  //       data,
  //     }: { data: IVenueDashboard } = await ReportingService.getReport({
  //       endTime,
  //       reportType: ReportType.VENUE_DASHBOARD,
  //       startTime,
  //       storeId: dashBoardStoreId ? dashBoardStoreId : '',
  //       tags: [],
  //       venueId,
  //     });

  //     if (data.orders.times) {
  //       data.orders.formattedTimes = formatTimesArray(data.orders.times);
  //     }
  //     setVenueDashboardData(data);
  //   } catch (error) {
  //     // tslint:disable-next-line: no-console
  //     console.log(error.response);
  //   }
  //   setShowSpinner(false);
  // };
  const formatTimesArray = (timesArr: string[]): IVenueDashboardTime[] => {
    const times: number[] = new Array(24).fill(0).slice();
    timesArr.forEach((time: any) => {
      const date = new Date(time);
      const hours = getHours(date);
      times[hours]++;
    });

    return times.map(
      (count: number, hours: number): IVenueDashboardTime => {
        const hour: string = format(new Date().setHours(hours), 'haaa');
        return { count, name: hour };
      },
    );
  };

  const { averages, orders, top, totals, tableData } = venueDashboardData;
  if (!venue) {
    return null;
  }

  return (
    <div className="dashboard">
      <Grid container={true} className="dashboard-container">
        <div className="dashboard-name-container">
          <span>
            <Typography variant="h5" className="section-title">
              VENUE DASHBOARD
            </Typography>
          </span>
          {get(venue, 'id') && (
            <span className="venue-active">
              <FiberManualRecordIcon
                fontSize="small"
                className={venue.active ? 'successDot' : 'failureDot'}
              />
              {venue.active ? 'LIVE' : 'CLOSED'}
            </span>
          )}
        </div>
      </Grid>
      <div className="card-container">
        <Card
          classes={{
            root: 'card-container__card',
          }}
        >
          <CardContent>
            <Typography variant="h4">
              <NumberFormat
                value={totals.sales}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={getCurrencyPrefix(venue.currency)}
              />
            </Typography>
            <Typography>Total Sales</Typography>
          </CardContent>
        </Card>
        <Card
          classes={{
            root: 'card-container__card',
          }}
        >
          <CardContent>
            <Typography variant="h4">{totals.orders}</Typography>
            <Typography>Total Orders</Typography>
          </CardContent>
        </Card>

        <Card
          classes={{
            root: 'card-container__card',
          }}
        >
          <CardContent>
            <Typography variant="h4">
              <NumberFormat
                value={averages.orderAmt}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={getCurrencyPrefix(venue.currency)}
              />
            </Typography>
            <Typography>Avg Order Amount</Typography>
          </CardContent>
        </Card>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              {dashBoardStoreId ? (
                <TableCell
                  align="center"
                  className="table-header table-cell-border"
                >
                  <b>Terminal</b>
                </TableCell>
              ) : (
                <TableCell
                  align="center"
                  className="table-header table-cell-border"
                >
                  <b>Store</b>
                </TableCell>
              )}
              <TableCell
                align="center"
                className="table-header table-cell-border"
              >
                <b>Total Orders</b>
              </TableCell>
              <TableCell
                align="center"
                className="table-header table-cell-border"
              >
                <b>Total Sales</b>
              </TableCell>
              <TableCell
                align="center"
                className="table-header table-cell-border"
              >
                <b>Avg Order Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row: any, index) => (
              <TableRow key={index} className="table-row">
                {dashBoardStoreId ? (
                  <TableCell align="center" className="table-cell">
                    {row.terminal}
                  </TableCell>
                ) : (
                  <TableCell align="center" className="table-cell">
                    {row.storeName}
                  </TableCell>
                )}
                <TableCell align="center" className="table-cell">
                  {row.orders}
                </TableCell>
                <TableCell align="center" className="table-cell">
                  {row?.totalSales?.toFixed(2)}
                </TableCell>
                <TableCell align="center" className="table-cell">
                  {row?.avgOrderAmt?.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="top-charts">
        <TotalOrdersBar {...orders} />
        <Overview {...top} />
      </div>
      <div className="bottom-charts">
        <GeneralStatsChart {...totals} />
        <OrderStats {...averages} />
      </div>
    </div>
  );
};

export default Dashboard;
