import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { ErrorMessage } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import UploadIcon from '../../../images/uploadIcon.png';
import AddSponsorImageDialog from './AddSponsorImageDialog';

const Sponsor = (props: any) => {
  const {
    arrayHelpers,
    errors,
    handleChange,
    setFieldValue,
    setSponsorsToDelete,
    sponsorNum,
    touched,
    values,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [showAddSponsorImageDialog, setShowAddSponsorImageDialog] = useState(
    false,
  );
  const [sponsorImage, setSponsorImage] = useState<object | null>(null);
  const [sponsorImageUrl, setSponsorImageUrl] = useState('');

  const getImageURL = (imageUrl: string) => {
    setSponsorImageUrl(imageUrl);
  };

  const onAddSponsorImage = (e: any) => {
    setFieldValue(`sponsors[${sponsorNum}].imageFile`, sponsorImage);
    setFieldValue(`sponsors[${sponsorNum}].imageUrl`, sponsorImageUrl);
    setShowAddSponsorImageDialog(false);
  };

  return (
    <>
      <Typography className="sponsor-modules__sponsor-title">
        {`Sponsor ${sponsorNum + 1}`}
        {sponsorNum > 0 ? (
          <Button
            classes={{
              label: 'sponsor-modules__sponsor-title__delete-label',
              root: 'sponsor-modules__sponsor-title__delete',
            }}
            onClick={() => {
              arrayHelpers.remove(sponsorNum);
              if (get(values, `sponsors[${sponsorNum}].id`)) {
                setSponsorsToDelete((prevState: string[]) => {
                  return [
                    ...prevState,
                    get(values, `sponsors[${sponsorNum}].id`),
                  ];
                });
              }
            }}
            variant="text"
          >
            <CancelIcon className="sponsor-modules__sponsor-title__delete-icon" />
          </Button>
        ) : (
          ''
        )}
      </Typography>
      <TextField
        className="sponsor-modules__text-field"
        error={
          get(touched, `sponsors[${sponsorNum}].name`) &&
          !!get(errors, `sponsors[${sponsorNum}].name`)
        }
        label="Sponsor/Partner Name"
        inputProps={{
          maxLength: 30,
        }}
        name={`sponsors[${sponsorNum}].name`}
        onChange={handleChange}
        required={true}
        type="text"
        value={get(values, `sponsors[${sponsorNum}].name`)}
      />
      <ErrorMessage
        className="error"
        component="div"
        name={`sponsors[${sponsorNum}].name`}
      />

      <Grid className="sponsor-modules__sponsor-image" container={true}>
        {!isEmpty(get(values, `sponsors[${sponsorNum}].imageUrl`)) ? (
          <Grid
            className="sponsor-modules__sponsor-image__current"
            container={true}
          >
            <img
              alt="sponsor logo"
              className="sponsor-modules__sponsor-image__current-preview"
              src={get(values, `sponsors[${sponsorNum}].imageUrl`)}
            />
            <Button
              className="sponsor-modules__sponsor-image__current-upload"
              color="primary"
              onClick={() => setShowAddSponsorImageDialog(true)}
              variant="text"
            >
              Upload new image
            </Button>
          </Grid>
        ) : (
          <>
            <Grid
              className="sponsor-modules__sponsor-image__upload"
              container={true}
              direction="column"
              alignItems="center"
              justify="space-between"
            >
              <img
                alt="upload sponsor logo"
                src={UploadIcon}
                className="sponsor-modules__sponsor-image__upload-icon"
              />
              <Button
                className="sponsor-modules__sponsor-image__upload-button"
                onClick={() => setShowAddSponsorImageDialog(true)}
                variant="contained"
              >
                UPLOAD A LOGO
              </Button>
              <Typography
                className="sponsor-modules__sponsor-image__upload-caption"
                variant="caption"
              >
                Select an image from your computer
              </Typography>
              <ErrorMessage
                className="error"
                component="div"
                name={`sponsors[${sponsorNum}].imageUrl`}
              />
            </Grid>
          </>
        )}
      </Grid>

      <ExpansionPanel
        classes={{
          expanded: 'sponsor-modules__sponsor-image__specs expanded',
          root: 'sponsor-modules__sponsor-image__specs',
        }}
        expanded={expanded}
        onChange={() => setExpanded(prevState => !prevState)}
      >
        <ExpansionPanelSummary
          classes={{
            content: 'sponsor-modules__sponsor-image__specs-content__title',
            expandIcon: 'sponsor-modules__sponsor-image__specs expanded__icon',
            expanded: 'sponsor-modules__sponsor-image__specs-content expanded',
            root: 'sponsor-modules__sponsor-image__specs-content',
          }}
          expandIcon={
            expanded ? (
              <CancelIcon className="sponsor-modules__sponsor-image__specs expanded__icon" />
            ) : (
              <ArrowForwardIosOutlinedIcon className="sponsor-modules__sponsor-image__specs expanded__icon" />
            )
          }
        >
          <Typography variant="subtitle1">SPECIFICATIONS</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: 'sponsor-modules__sponsor-image__specs-details',
          }}
        >
          JPEG (.jpeg/.jpg), GIF(.gif) and PNG (.png) formats accepted. Image
          pixel dimensions of at least 200 or larger in height preferred. sRGB
          or CMYK color mode.
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {showAddSponsorImageDialog && (
        <AddSponsorImageDialog
          currentImageUrl={get(
            values,
            ['sponsors', sponsorNum, 'imageUrl'],
            '',
          )}
          getImageURL={getImageURL}
          onImageSave={onAddSponsorImage}
          onClose={() => setShowAddSponsorImageDialog(false)}
          setImage={(file: object | null) => setSponsorImage(file)}
        />
      )}
    </>
  );
};

export default Sponsor;
