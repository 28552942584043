// see here for documentation: https://github.com/i-like-robots/react-tags

import _ from 'lodash';
import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import './index.scss';

interface ITag {
  id: string;
  name: string;
}

interface ITagManager {
  allTags: string[];
  currentTags: string[];
  onTagsUpdated: (tags: string[]) => void;
}

const TagManager = (props: ITagManager) => {
  const { allTags, currentTags, onTagsUpdated } = props;

  const [tags, setTags] = React.useState<ITag[]>(
    currentTags.map((tag: string) => ({ id: tag, name: tag })),
  );
  const suggestions = allTags.map((tag: string) => ({ id: tag, name: tag }));

  const onDelete = (i: number) => {
    const newTags = tags.filter((tag: ITag, index: number) => index !== i);
    setTags(newTags);

    onTagsUpdated(newTags.map((tag: ITag) => tag.name));
  };

  const onAddition = (tag: ITag) => {
    const newTags = [...tags, tag];
    setTags(newTags);

    onTagsUpdated(newTags.map((t: ITag) => t.name));
  };

  const suggestionsTransform = (query: string, suggns: ITag[]) =>
    _.differenceWith(
      suggns,
      tags,
      (a, b) => a.id === b.id,
    ).filter((tag: ITag) =>
      tag.name.toLowerCase().includes(query.toLowerCase()),
    );

  return (
    <ReactTags
      {...{ tags, suggestions, onDelete, onAddition, suggestionsTransform }}
    />
  );
};

export default TagManager;
