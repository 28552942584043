import React from 'react';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';

interface IPromoCodeSummaryProps {
  data: IPromotionsDataRecord[];
  isAdmin: boolean;
}

export interface IPromotionsDataRecord extends IHasCurrency {
  code: string;
  isDeleted: boolean;
  totalSold: number;
  totalValue: number;
}

const exportName = 'promo-code-summary';
const columns: Array<IReportColumn<IPromotionsDataRecord>> = [
  {
    accessor: record => record.code,
    name: 'Promo Code',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.totalSold,
    name: 'Number of Times Used',
    total: true,
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.totalValue,
    name: 'Total Discounts Applied',
    total: true,
    type: ReportColumnType.currency,
  },
];

const PromoCodeSummary = (props: IPromoCodeSummaryProps): JSX.Element => (
  <BasicReport {...{ columns, exportName }} {...props} />
);

export default PromoCodeSummary;
