import { Backdrop, Chip, LinearProgress, Typography } from '@material-ui/core';
import {
  Delete,
  FileCopyOutlined,
  OpenInNew,
  Receipt,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import CustomTabs, {
  CustomRoutes,
} from '../../../components/CustomTabs/CustomTabs';
import DeleteDialog from '../../../components/DeleteDialog';
import { useAppContext } from '../../../config/AppContext';
import { ISecondaryTab } from '../../../config/Common';
import { StoreProvider, useStoreContext } from '../../../config/StoreContext';
import { useCloneStore } from '../../../hooks/clone-store.hook';
import {
  IStore,
  ValidStoreType,
  validStoreTypeName,
} from '../../../models/Store.model';
import { IUser } from '../../../models/User.model';
import { canEditStore, canEditStoreAch } from '../../../services/Auth.service';
import StoreService from '../../../services/Store/Store.service';
import { getStoreWebLink } from '../../../services/WebLinks.service';
import {
  HeaderButton,
  HeaderButtonType,
} from '../../../theme/components/buttons/HeaderButton';
import { venuePath } from '../../TabConfig';
import ACH from '../ACH';
import { Categories } from '../Categories/Categories';
import Menu from '../Menu/Menu';
import EtaDialog from './EtaDialog';
import { StorePrinters } from './Printers/StorePrinters';
import { isFulfullmentCenter } from './Store.config';
import StoreDialog from './StoreDialog';
import ScheduleHours from './ScheduleHour/ScheduleHour';
import { StoreLocations } from './StoreLocations';
import './Stores.scss';
import Terminals from './Terminals';
import LocalStorageService from '../../../services/LocalStorage/LocalStorage.service';

enum StoreTab {
  ACH = 'ACH',
  CATEGORIES = 'CATEGORIES',
  EDIT = 'EDIT',
  ETAS = 'ETAS',
  TERMINALS = 'TERMINALS',
  ScheduleHours = 'SCHEDULE',
  LOCATIONS = 'LOCATIONS',
  MENU = 'MENU',
  PRINTERS = 'PRINTERS',
}

const CategoriesTab: ISecondaryTab = {
  component: Categories,
  name: StoreTab.CATEGORIES,
  route: '/categories',
};

const MenuTab: ISecondaryTab = {
  component: Menu,
  name: StoreTab.MENU,
  route: '/menu',
};

const ScheduleHourTab: ISecondaryTab = {
  component: ScheduleHours,
  name: StoreTab.ScheduleHours,
  route: '/schedule',
};

const EtasTab: ISecondaryTab = {
  component: EtaDialog,
  name: StoreTab.ETAS,
  route: '/etas',
};

const TerminalsTab: ISecondaryTab = {
  component: Terminals,
  name: StoreTab.TERMINALS,
  route: '/terminals',
};

const PrintersTab: ISecondaryTab = {
  component: StorePrinters,
  name: StoreTab.PRINTERS,
  route: '/printers',
};

const LocationsTab: ISecondaryTab = {
  component: StoreLocations,
  name: StoreTab.LOCATIONS,
  route: '/locations',
};

const AchTab: ISecondaryTab = {
  component: ACH,
  name: StoreTab.ACH,
  route: '/ach',
};

const EditStoreTab: ISecondaryTab = {
  component: StoreDialog,
  name: StoreTab.EDIT,
  route: '/edit',
};

const getStoreTabs = (
  user: IUser,
  venueId: string,
  store: IStore,
  tenantSchema: boolean,
) => {
  const tabs: ISecondaryTab[] = [CategoriesTab, MenuTab, EtasTab, TerminalsTab];
  if (tenantSchema) tabs.splice(2, 0, ScheduleHourTab);

  if (isFulfullmentCenter(store) && canEditStore(user, venueId, store.id)) {
    return [PrintersTab, EditStoreTab];
  }

  if (canEditStoreAch(user, venueId, store.id)) {
    return [...tabs, PrintersTab, LocationsTab, AchTab, EditStoreTab];
  }

  if (canEditStore(user, venueId, store.id)) {
    return [...tabs, PrintersTab, LocationsTab, EditStoreTab];
  }

  return tabs;
};

const Store = () => {
  const { fetchStores, setShowSpinner, user, venue } = useAppContext();
  const { setSelectedProductCategoryId, store } = useStoreContext();
  const { cloneStore, clonedPercent, storeToClone } = useCloneStore();
  const [tenantSchema, setTenantSchema] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [storeForDelete, setStoreForDelete] = useState<IStore | undefined>();

  useEffect(() => {
    const tenant_schema_check = LocalStorageService.getLocalData(
      'tenant-schema',
    );
    setTenantSchema(tenant_schema_check === 'true');
  }, [venue]);

  if (!store || !user || !venue) {
    return null;
  }

  const onCloneStore = async (s: IStore) => {
    if (!venue) {
      return;
    }

    try {
      const newStore = await cloneStore(s);
      setSelectedProductCategoryId(undefined);
      fetchStores();

      history.push(venuePath(venue.id, `stores/${newStore.id}/menu`));
      enqueueSnackbar('Store has been successfully cloned', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error.response);
      enqueueSnackbar('Store could not be cloned. Please try again.', {
        variant: 'error',
      });
    }
  };

  const deleteStore = async () => {
    if (!storeForDelete || !venue) {
      return;
    }

    setShowSpinner(true);
    try {
      await StoreService.deleteStore(storeForDelete.id);
      await fetchStores();
      history.push(venuePath(venue.id, 'stores'));

      enqueueSnackbar('Store was successfully deleted', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }

    setShowSpinner(false);
  };

  const storeTabs = getStoreTabs(user, venue.id, store, tenantSchema);

  return (
    <div className="store-container">
      <div className="store-header">
        <BackButton
          onClick={() => history.replace(venuePath(venue.id, 'stores'))}
        />
        <span id="form-details-title" className="section-title">
          {store?.name}
        </span>
        {store.type !== ValidStoreType.REGULAR && (
          <Chip
            label={validStoreTypeName[store.type]}
            size="small"
            variant="outlined"
          />
        )}
        <div className="header-button-group">
          <HeaderButton
            icon={Receipt}
            onClick={() => history.push(venuePath(venue.id, 'orders'))}
            text="View Orders"
            type={HeaderButtonType.SECONDARY}
          />
          {!isFulfullmentCenter(store) && (
            <HeaderButton
              icon={OpenInNew}
              href={getStoreWebLink(venue.id, store.id)}
              target="_blank"
              text="View Store In Web"
              type={HeaderButtonType.SECONDARY}
            />
          )}
          {canEditStore(user, venue.id, store.id) && (
            <>
              <HeaderButton
                icon={FileCopyOutlined}
                onClick={() => onCloneStore(store)}
                text="Clone Store"
                type={HeaderButtonType.SECONDARY}
              />
              <HeaderButton
                icon={Delete}
                onClick={() => setStoreForDelete(store)}
                text="Delete Store"
                type={HeaderButtonType.SECONDARY}
              />
            </>
          )}
        </div>
      </div>
      <CustomTabs tabs={storeTabs} />
      <CustomRoutes tabs={storeTabs} />
      {storeToClone && (
        <Backdrop className="stores__backdrop" open={!!storeToClone}>
          <div className="stores__backdrop__header">
            <Typography className="stores__backdrop__text" variant="h5">
              {`${storeToClone.name} is being cloned...`}
            </Typography>
            <LinearProgress
              classes={{
                bar: 'stores__backdrop__progress-bar__value',
                root: 'stores__backdrop__progress-bar',
              }}
              variant="determinate"
              value={clonedPercent}
            />
          </div>
        </Backdrop>
      )}
      {storeForDelete && (
        <DeleteDialog
          dialogTitle="Delete Store"
          dialogContent="Are you sure you want to delete this Store?"
          onClose={() => setStoreForDelete(undefined)}
          onSubmit={() => deleteStore()}
        />
      )}
    </div>
  );
};

export const StoreContainer = () => {
  const { storeId } = useParams<{ storeId: string }>();
  if (storeId === 'store-list') {
    return null;
  }

  return (
    <StoreProvider storeId={storeId}>
      <Store />
    </StoreProvider>
  );
};
