import IBaseModel from './Base.model';

export interface IUser extends IBaseModel {
  countryCode: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: UserRole;
  securityGroups: ISecurityGroup[];
  venueIds: string[];
}

export interface ISecurityGroup extends IBaseModel {
  allEvents: boolean;
  eventIds: string[];
  role: UserVenueRole;
  storeIds: string[];
  suiteIds: string[];
  userId: string;
  venueId: string;
}

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  FANFOOD_ADMIN = 'FANFOOD_ADMIN',
  REGULAR = 'REGULAR',
}

export enum UserVenueRole {
  VENUE_MANAGER = 'VENUE_MANAGER',
  STORE_MANAGER = 'STORE_MANAGER',
  STORE_EMPLOYEE = 'STORE_EMPLOYEE',
  RUNNER = 'RUNNER',
  SUITE_ATTENDANT = 'SUITE_ATTENDANT',
  SUITE_CUSTOMER = 'SUITE_CUSTOMER',
  POS_USER = 'POS_USER',
}
