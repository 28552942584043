import { Button } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { get, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import DeleteDialog from '../../../components/DeleteDialog';
import { useAppContext } from '../../../config/AppContext';
import { useStoreContext } from '../../../config/StoreContext';
import ICategory from '../../../models/Category.model';
import CategoryService from '../../../services/Category/Category.service';
import CategoriesTable from './CategoriesTable';
import { categoryInitialState, VALID_CATEGORY_TYPES } from './Category.config';
import CategoryDialog from './CategoryDialog';

interface IFormErrors {
  name?: string;
  priority?: string;
}

export const Categories = () => {
  const { setShowSpinner } = useAppContext();
  const {
    categories,
    categoryPagination,
    categoryMaxPriority: maxPriority,
    fetchCategories,
    store,
  } = useStoreContext();
  const { enqueueSnackbar } = useSnackbar();

  const [categoryDetails, setCategoryDetails] = useState<any>();
  const [categoryForDelete, setCategoryForDelete] = useState<
    ICategory | undefined
  >();
  const [viewCategoryDialog, setViewCategoryDialog] = useState<boolean>(false);
  const [isAddingNewCategory, setIsAddingNewCategory] = useState<boolean>(
    false,
  );
  const [formErrors, setFormErrors] = useState<IFormErrors>({});

  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalCount,
  } = categoryPagination;

  const handleCategoryDetails = (selectedCategory: any) => {
    if (!get(selectedCategory, 'id')) {
      selectedCategory.priority = maxPriority + 1;
      setIsAddingNewCategory(true);
    } else {
      setIsAddingNewCategory(false);
    }
    const editedCategoryDetails = isEmpty(selectedCategory)
      ? categoryInitialState
      : selectedCategory;

    setCategoryDetails(editedCategoryDetails);
    setFormErrors({});
    setViewCategoryDialog(true);
  };

  const onDetailsChange = (event: React.ChangeEvent<{}>) => {
    const property = get(event, ['target', 'name']);
    const newValue =
      property === 'priority'
        ? parseInt(get(event, ['target', 'value']), 10)
        : get(event, ['target', 'value']);

    if (property === 'name') {
      setFormErrors(prev => ({
        ...prev,
        name: newValue.length > 0 ? '' : 'Name is Required',
      }));
    }

    if (property === 'priority') {
      setFormErrors(prev => ({
        ...prev,
        priority: newValue > 0 ? '' : 'Priority must be a positive number',
      }));
    }

    setCategoryDetails((prev: any) => ({
      ...prev,
      [property]: newValue,
    }));
  };

  const onCategoryChangeActive = async (
    event: any,
    selectedCategory: ICategory,
  ) => {
    setShowSpinner(true);
    try {
      if (get(selectedCategory, ['type']) === VALID_CATEGORY_TYPES.OPTIONS) {
        setShowSpinner(false);
        return;
      }
      await CategoryService.updateCategory(selectedCategory.id, {
        active: get(event, ['target', 'checked']),
      });

      await fetchCategories();
      enqueueSnackbar('Category status successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const onAddOrEditCategory = async () => {
    if (!store) {
      return;
    }

    const { id, name, priority, type } = categoryDetails;

    if (name.length === 0) {
      return setFormErrors(prev => ({
        ...prev,
        name: 'Name is required',
      }));
    }

    setShowSpinner(true);
    try {
      if (id) {
        await CategoryService.updateCategory(id, {
          name,
          priority,
          type,
        });

        enqueueSnackbar('Category has been successfully updated', {
          variant: 'success',
        });
      } else {
        await CategoryService.createCategory({
          active: type === VALID_CATEGORY_TYPES.OPTIONS ? false : true,
          name,
          priority,
          storeId: store.id,
          type,
        });
        enqueueSnackbar('Category has been successfully created', {
          variant: 'success',
        });
      }
      setPage(0);
      setViewCategoryDialog(false);
      fetchCategories();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      const errorMessage = get(
        error,
        ['response', 'data', 'message'],
        'Something went wrong. Please try again.',
      );
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const onDeleteCategory = async () => {
    if (!categoryForDelete) {
      return;
    }

    setShowSpinner(true);
    try {
      await CategoryService.deleteCategory(categoryForDelete.id);

      setPage(0);
      setCategoryForDelete(undefined);
      await fetchCategories();
      enqueueSnackbar('Category successfully deleted', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  if (!store) {
    return null;
  }

  return (
    <Fragment>
      <div className="categories__title-row">
        <p>
          Store: <span className="categories__store-name">{store.name}</span>
        </p>
      </div>
      <div className="pagination">
        <div className="categories__pagination-toolbar">
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[100, 200, 300]}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={totalCount}
          />
          <Button
            size="large"
            color="primary"
            classes={{ root: 'category__add-button' }}
            onClick={() => handleCategoryDetails(categoryInitialState)}
          >
            <div className="icon-title-wrapper">
              <AddOutlinedIcon />
              <span className="icon-title">Add Category</span>
            </div>
          </Button>
        </div>
        <CategoriesTable
          onChangeActive={onCategoryChangeActive}
          onEditCategory={handleCategoryDetails}
          onDeleteCategory={c => setCategoryForDelete(c)}
          categories={categories}
        />
        {viewCategoryDialog && (
          <CategoryDialog
            error={formErrors}
            isAddingNewCategory={isAddingNewCategory}
            onClose={() => setViewCategoryDialog(false)}
            onAddOrEditCategory={onAddOrEditCategory}
            onDetailsChange={onDetailsChange}
            category={categoryDetails}
          />
        )}
        {categoryForDelete && (
          <DeleteDialog
            dialogTitle="Delete Category"
            dialogContent="Are you sure you want to remove this Category?"
            onClose={() => setCategoryForDelete(undefined)}
            onSubmit={onDeleteCategory}
          />
        )}
      </div>
    </Fragment>
  );
};
