import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';
import { Dictionary } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { ICreatePartnerEntity, Partner } from '../../models/Partner.model';

interface IPartnerDialogProps {
  baseEntityId: string;
  createPartnerEntity: (values: ICreatePartnerEntity) => Promise<void>;
  entityName: string;
  keys: Dictionary<string>;
  onClose: () => void;
}

const PartnerEntityValidationSchema = {
  id: Yup.string().required(),
  partner: Yup.string().required(),
};

export default function PartnerDialog({
  baseEntityId,
  createPartnerEntity,
  entityName,
  onClose,
}: IPartnerDialogProps) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: 'partner-dialog',
      }}
    >
      <DialogTitle id="form-dialog-title" className="section-title">
        <span className="section-title">Add {entityName}</span>
      </DialogTitle>
      <DialogContent>
        <div className="partner-form">
          <Formik
            initialValues={{
              partner: Partner.CUBS,
              partnerEntityId: '',
            }}
            onSubmit={values =>
              createPartnerEntity({
                ...values,
                baseEntityId,
              })
            }
            validationSchema={PartnerEntityValidationSchema}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid spacing={1} container={true} className="container">
                  <Grid item={true} xs={10} className="form-field-container">
                    <InputLabel>{entityName} ID</InputLabel>
                    <TextField
                      fullWidth={true}
                      required={true}
                      error={
                        touched.partnerEntityId && !!errors.partnerEntityId
                      }
                      name="partnerEntityId"
                      inputProps={{
                        type: 'string',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.partnerEntityId}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      render={() => (
                        <div className="error">
                          {entityName} ID is a required field
                        </div>
                      )}
                      name="partnerEntityId"
                    />
                  </Grid>

                  <Grid item={true} xs={10} className="form-field-container">
                    <InputLabel>Partner</InputLabel>
                    <Select
                      name="partner"
                      required={true}
                      // className="form__field"
                      value={values.partner}
                      onChange={handleChange}
                    >
                      {Object.values(Partner).map(value => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item={true} xs={12} className="buttons-container">
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={onClose}
                        variant="outlined"
                      >
                        <div className="icon-title-wrapper">
                          <span className="icon-title">Cancel</span>
                        </div>
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={Object.keys(errors).length > 0}
                      >
                        <div className="icon-title-wrapper">
                          <span className="icon-title">Save</span>
                        </div>
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}
