import { TextField } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { createRef, useEffect } from 'react';
/* global google */

const GoogleAutoComplete = (props: any) => {
  const autocompleteInput: any = createRef();
  let autocomplete: any = null;
  const { error } = props;

  useEffect(() => {
    // eslint-disable-next-line
    autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput.current,
      { types: [] },
    );

    autocomplete.addListener('place_changed', handlePlaceChanged);
  }, []);

  const getDetails = (place: any) => {
    const addressObject: any = { addressLine1: '' };
    const { address_components } = place;
    address_components.forEach(({ long_name, short_name, types }: any) => {
      if (types.includes('street_number')) {
        addressObject.addressLine1 += long_name;
      } else if (types.includes('locality')) {
        addressObject.city = long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressObject.state = long_name;
      } else if (types.includes('postal_code')) {
        addressObject.zip = long_name;
      } else if (types.includes('route')) {
        addressObject.addressLine1 += ' ' + long_name;
      } else if (types.includes('country')) {
        addressObject.country = short_name;
      }
    });

    return addressObject;
  };

  const handlePlaceChanged = () => {
    const place = autocomplete.getPlace();
    const geometry = get(place, ['geometry'], {});
    if (!isEmpty(geometry)) {
      const {
        location: { lat, lng },
      } = geometry;
      props.onPlaceLoaded(lat(), lng(), getDetails(place));
    }
  };

  return (
    <TextField
      {...props.field}
      inputRef={autocompleteInput}
      id="autocomplete"
      label={props.label}
      type="text"
      className={props.className}
      required={true}
      error={error}
    />
  );
};

export default GoogleAutoComplete;
