import {
  Button,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { ErrorMessage, Field, Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { get, includes, isEmpty } from 'lodash';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';
import { IUpdateVenueWithImageRequest } from '../../../models/Request.model';
import {
  getTimezone,
  unMaskPhoneNumber,
} from '../../../services/Util/Util.service';
import VenueService from '../../../services/Venue/Venue.service';
import AddImage from '../../AddImage/AddImage';
import {
  currencyOptions,
  ISelectConfig,
} from '../../EditVenue/EditVenueConfig';
import ValidLocationsDialog from '../../EditVenue/VenueSettings/ValidLocationsDialog/ValidLocationsDialog';
import GoogleAutoComplete from '../../Map/GoogleAutoComplete';
import {
  IOrganizationDetailsFormValues,
  IOrganizationDetailsProps,
  IValidLocation,
  organizationDetails,
  organizationSettingsSchema,
} from '../Settings.config';
import './OrganizationDetails.scss';
import { TaxRates } from './TaxRates';
import { Tagging } from './Tagging';

const OrganizationDetails = (props: IOrganizationDetailsProps) => {
  const {
    venue,
    onUpdate,
    showImageDetails,
    setShowImageDetails,
    enqueueSnackbar,
  } = props;
  const [venueTimezone, setVenueTimezone] = useState({});
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const [linkCopy, setLinkCopy] = useState(false);
  const [newImageFile, setNewImageFile] = useState<any>(null);
  const [showValidLocationsDialog, setShowValidLocationsDialog] = useState(
    false,
  );
  const [venueImgUrl, setVenueImgUrl] = useState(
    get(venue, ['imageUrl'], null),
  );

  const vanityUrl = get(venue, ['vanityUrl'], '');

  const validLocationTypes = get(venue, 'validLocationTypes', []);
  const venueId = get(venue, 'id', '');
  const [displayValidLocationsError, setDisplayValidLocationsError] = useState(
    validLocationTypes.length < 1,
  );

  useEffect(() => {
    const validLocations = get(venue, 'validLocationTypes', []);
    setDisplayValidLocationsError(validLocations.length < 1);

    getTimezoneData(get(venue, 'latitude'), get(venue, 'longitude'));

    if (venue) {
      setVenueImgUrl(venue.imageUrl);
    }
  }, [venue]);

  const onCancel = (resetForm: any) => {
    resetForm();
    setVenueImgUrl(get(venue, ['imageUrl'], null));
    enqueueSnackbar('Form values have been reset', {
      variant: 'success',
    });
  };

  const handleLinkCopy = () => {
    navigator.clipboard.writeText(vanityUrl).then(() => setLinkCopy(true));
  };

  const getTimezoneData = async (latitude: number, longitude: number) => {
    try {
      const { data } = await getTimezone(latitude, longitude);
      setVenueTimezone(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }
  };

  const uploadImage = (imageFile: any) => {
    const reader = new FileReader();
    reader.onload = () => setVenueImgUrl(reader.result);

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }

    setShowImageDetails(false);
  };

  const handleVenueSettingsUpdate = async (
    values: IOrganizationDetailsFormValues,
  ) => {
    if (values.deliveryAvailable && displayValidLocationsError) {
      enqueueSnackbar('Please add at least one valid location for delivery', {
        variant: 'error',
      });
      return;
    }

    values.timezone = get(venueTimezone, 'timeZoneId');

    if (values.contactPhoneNumber!.indexOf('+') > -1) {
      values.contactPhoneNumber = unMaskPhoneNumber(
        formatPhoneNumber(values.contactPhoneNumber!),
      );
    }

    if (newImageFile) {
      const formData = new FormData();
      formData.append('imageFile', newImageFile);

      await onUpdate({
        ...values,
        imageData: formData,
      } as IUpdateVenueWithImageRequest);
    } else {
      await onUpdate(values as IUpdateVenueWithImageRequest);
    }
  };

  const handleDeleteLocation = async (curLocation: IValidLocation) => {
    const locationTypeId = get(curLocation, 'id');
    try {
      await VenueService.deleteLocationType(venueId, locationTypeId);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
    }

    return await onUpdate();
  };

  return venue ? (
    <div className="organization-details">
      <Typography className="subtitleInfo">
        {organizationDetails.subtitleInfo}
      </Typography>
      <Typography className="info required-text">
        {organizationDetails.requiredInfo}
      </Typography>
      <Typography className="info">
        {organizationDetails.optionalInfo}
      </Typography>

      <Formik<IOrganizationDetailsFormValues>
        enableReinitialize={true}
        initialValues={{ ...venue }}
        onSubmit={async (values: IOrganizationDetailsFormValues) =>
          await handleVenueSettingsUpdate(values)
        }
        validationSchema={organizationSettingsSchema}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          resetForm,
        }) => {
          return (
            <div>
              <form
                onSubmit={handleSubmit}
                onKeyPress={(event: any) => {
                  const key = event.charCode || event.keyCode || 0;
                  if (key === 13) {
                    event.preventDefault();
                  }
                }}
              >
                <div className="organization-container">
                  <Grid container={true} spacing={3} className="container">
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Organization Information
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel htmlFor="name">Organization Name</InputLabel>
                      <TextField
                        id="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={true}
                        error={get(touched, 'name') && !!get(errors, 'name')}
                        name="name"
                        className="field"
                        value={values.name}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="name"
                      />
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel htmlFor="vanityUrl">
                        Custom Vanity URL
                      </InputLabel>
                      <TextField
                        id="vanityURL"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={true}
                        error={
                          get(touched, 'vanityUrl') &&
                          !!get(errors, 'vanityUrl')
                        }
                        name="vanityUrl"
                        className="field"
                        value={values.vanityUrl}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="vanityUrl"
                      />
                      <div onClick={handleLinkCopy} className="copy-link">
                        <div>Copy Link</div>
                        {linkCopy && (
                          <div className="copy-link-status">Copied!</div>
                        )}
                      </div>
                    </Grid>
                    <Grid item={true} xs={12} className="form-field-container">
                      <InputLabel htmlFor="tagline">
                        Tagline{' '}
                        <p className="appended-text">
                          (One line description of your organization)
                        </p>
                      </InputLabel>
                      <TextField
                        id="tagline"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`eg. The best burger of the Mississippi`}
                        error={
                          get(touched, 'tagline') && !!get(errors, 'tagline')
                        }
                        name="description"
                        className="field"
                        value={values.description ? values.description : ''}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="tagline"
                      />
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel className="required">
                        Enter your address
                      </InputLabel>
                      <Field
                        className="field"
                        required={true}
                        name="addressLine1"
                        render={({
                          field,
                          form,
                        }: {
                          field: any;
                          form: any;
                        }) => (
                          <GoogleAutoComplete
                            className="field"
                            field={field}
                            error={
                              get(touched, 'addressLine1') &&
                              !!get(errors, 'addressLine1')
                            }
                            value={values.name}
                            onPlaceLoaded={async (
                              latitude: number,
                              longitude: number,
                              address: any,
                            ) => {
                              const {
                                addressLine1,
                                city,
                                state,
                                zip,
                              } = address;

                              setFieldValue('addressLine1', addressLine1);
                              setFieldValue('city', city);
                              setFieldValue('state', state);
                              setFieldValue('zip', zip || '');
                              setFieldValue('latitude', latitude);
                              setFieldValue('longitude', longitude);
                              await getTimezoneData(latitude, longitude);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="addressLine1"
                      />
                    </Grid>
                    <Grid item={true} xs={3} className="form-field-container">
                      <InputLabel className="required" htmlFor="zip">
                        Zipcode
                      </InputLabel>
                      <TextField
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="zip"
                        error={get(touched, 'zip') && !!get(errors, 'zip')}
                        className="field"
                        value={values.zip}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="zip"
                      />
                    </Grid>
                    <Grid item={true} xs={3} className="form-field-container">
                      <InputLabel htmlFor="apt">
                        Suite, Apt, Floor, etc.
                      </InputLabel>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="addressLine2"
                        className="field"
                        value={get(values, ['addressLine2']) || ''}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel className="required" htmlFor="city">
                        City
                      </InputLabel>
                      <TextField
                        id="city"
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={get(touched, 'city') && !!get(errors, 'city')}
                        name="city"
                        className="field"
                        value={values.city}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="city"
                      />
                    </Grid>
                    <Grid item={true} xs={3} className="form-field-container">
                      <InputLabel className="required" htmlFor="state">
                        State
                      </InputLabel>
                      <TextField
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={get(touched, 'state') && !!get(errors, 'state')}
                        name="state"
                        className="field"
                        value={values.state}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="state"
                      />
                    </Grid>
                    <Grid item={true} xs={12} className="form-field-container">
                      <InputLabel>Timezone</InputLabel>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field"
                        name="timezone"
                        value={
                          !!get(venueTimezone, 'timeZoneId')
                            ? `${get(venueTimezone, 'timeZoneId')} - ${get(
                                venueTimezone,
                                'timeZoneName',
                              )}`
                            : ''
                        }
                        autoComplete="off"
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container={true} spacing={3} className="container">
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Your organization's point of contact
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel className="required">First Name</InputLabel>
                      <TextField
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          get(touched, 'contactFirstName') &&
                          !!get(errors, 'contactFirstName')
                        }
                        className="field"
                        name="contactFirstName"
                        value={values.contactFirstName}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="contactFirstName"
                      />
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel className="required">Last Name</InputLabel>
                      <TextField
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          get(touched, 'contactLastName') &&
                          !!get(errors, 'contactLastName')
                        }
                        className="field"
                        name="contactLastName"
                        value={values.contactLastName}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="contactLastName"
                      />
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel className="required">Email</InputLabel>
                      <TextField
                        required={true}
                        name="contactEmail"
                        type="email"
                        onChange={handleChange}
                        error={
                          get(touched, 'contactEmail') &&
                          !!get(errors, 'contactEmail')
                        }
                        onBlur={handleBlur}
                        className="field"
                        value={values.contactEmail}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="contactEmail"
                      />
                    </Grid>
                    <Grid item={true} xs={6} className="form-field-container">
                      <InputLabel className="required">Phone Number</InputLabel>
                      <PhoneInput
                        defaultCountry={phoneNumberUtil.getRegionCodeForCountryCode(
                          Number(values.contactCountryCode || '1'),
                        )}
                        placeholder="Enter Phone Number"
                        name="contactPhoneNumber"
                        value={values.contactPhoneNumber!}
                        className={`phone-container ${
                          get(touched, 'contactPhoneNumber') &&
                          !!get(errors, 'contactPhoneNumber')
                            ? 'error'
                            : ''
                        }`}
                        numberInputProps={{
                          className: 'phone-container__input',
                        }}
                        onChange={(e: string) => {
                          setFieldValue('contactPhoneNumber', e);
                        }}
                        onCountryChange={(code: string = '') => {
                          if (!isEmpty(code)) {
                            setFieldValue(
                              'contactCountryCode',
                              getCountryCallingCode(code),
                            );
                          }
                        }}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="contactPhoneNumber"
                      />
                    </Grid>
                  </Grid>
                  <Grid container={true} spacing={3} className="container">
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Location Settings
                        <span className="appended-text">&nbsp;(Optional)</span>
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <div className="toggle-container">
                        {/* Always disabled here. Setting is available in Venue Edit page. */}
                        <CustomSwitch
                          disabled={true}
                          name="allowFreeProducts"
                          color="primary"
                          checked={values.allowFreeProducts}
                          value={values.allowFreeProducts}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>
                          Allow Free Products
                          <span className="appended-text">
                            &nbsp;(FanFood Admins only)
                          </span>
                        </InputLabel>
                      </div>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <div className="toggle-container">
                        <CustomSwitch
                          name="deliveryAvailable"
                          color="primary"
                          checked={values.deliveryAvailable}
                          value={values.deliveryAvailable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>Delivery Available</InputLabel>
                      </div>
                    </Grid>

                    <Grid
                      item={true}
                      xs={6}
                      className={
                        values.deliveryAvailable ? '' : 'make-invisible'
                      }
                    >
                      <div className="toggle-container">
                        <CustomSwitch
                          name="enableDeliveryTips"
                          color="primary"
                          checked={
                            !values.deliveryAvailable
                              ? false
                              : values.enableDeliveryTips
                          }
                          value={
                            values.deliveryAvailable
                              ? false
                              : values.enableDeliveryTips
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>Enable Delivery Tips</InputLabel>
                      </div>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <div className="toggle-container">
                        <CustomSwitch
                          name="pickupAvailable"
                          color="primary"
                          checked={values.pickupAvailable}
                          value={values.pickupAvailable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>Pickup Available</InputLabel>
                      </div>
                    </Grid>
                    <Grid
                      item={true}
                      xs={6}
                      className={values.pickupAvailable ? '' : 'make-invisible'}
                    >
                      <div className="toggle-container">
                        <CustomSwitch
                          name="enablePickupTips"
                          color="primary"
                          checked={
                            !values.pickupAvailable
                              ? false
                              : values.enablePickupTips
                          }
                          value={
                            !values.pickupAvailable
                              ? false
                              : values.enablePickupTips
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>Enable Pickup Tips</InputLabel>
                      </div>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <div className="toggle-container">
                        <CustomSwitch
                          name="sendOrderNotifications"
                          color="primary"
                          checked={values.sendOrderNotifications}
                          value={values.sendOrderNotifications}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>Allow Order Notifications</InputLabel>
                      </div>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Location Triggered Notifications
                        <span className="appended-text"> (Optional)</span>
                      </Typography>
                      <Typography className="italic">
                        {organizationDetails.locationNotificationInfo}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={4} className="form-field-container">
                      <InputLabel>Max distance for notifications</InputLabel>
                      <div className="side-text-container">
                        <TextField
                          className="field quarter-width"
                          name="maxDistance"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.maxDistance}
                          autoComplete="off"
                          inputProps={{
                            min: '3',
                            step: '0.1',
                            type: 'number',
                          }}
                        />
                        <span className="side-text">miles</span>
                      </div>
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="maxDistance"
                      />
                    </Grid>
                  </Grid>
                  <Grid container={true} spacing={3} className="container">
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Alcohol Settings
                        <span className="appended-text"> (Optional)</span>
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={4} className="form-field-container">
                      <InputLabel>Alcohol serving limit per order</InputLabel>
                      <TextField
                        className="field quarter-width"
                        name="alcoholLimit"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alcoholLimit}
                        autoComplete="off"
                        inputProps={{
                          min: '0',
                          step: '1',
                          type: 'number',
                        }}
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="alcoholLimit"
                      />
                    </Grid>
                    <Grid item={true} xs={8} className="form-field-container">
                      <InputLabel>Alcohol message to customers</InputLabel>
                      <TextField
                        required={values.alcoholLimit! > 0}
                        name="alcoholMessage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          get(touched, 'alcoholMessage') &&
                          !!get(errors, 'alcoholMessage')
                        }
                        placeholder="eg. You must provide a valid ID upon delivery"
                        className="field"
                        value={values.alcoholMessage || ''}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="alcoholMessage"
                      />
                    </Grid>
                  </Grid>
                  <Grid container={true} spacing={3} className="container">
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Tax Settings
                        <span className="appended-text"> (Optional)</span>
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={4} className="form-field-container">
                      <InputLabel className="required" htmlFor="currency">
                        Display Currency
                      </InputLabel>
                      <Select
                        value={values.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="currency"
                        className="field input"
                        classes={{
                          select: 'select-component',
                        }}
                      >
                        {currencyOptions.map((currency: ISelectConfig) => (
                          <MenuItem key={currency.name} value={currency.value}>
                            {currency.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item={true} xs={8}>
                      <div className="toggle-container">
                        <CustomSwitch
                          name="calculateTaxes"
                          color="primary"
                          checked={values.calculateTaxes}
                          value={values.calculateTaxes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputLabel>Calculate and Apply Taxes</InputLabel>
                      </div>
                    </Grid>
                    <Grid
                      item={true}
                      xs={4}
                      className="form-field-container remove-top-padding"
                    >
                      <InputLabel>Sales Tax Percent</InputLabel>
                      <div className="side-text-container">
                        <TextField
                          className="field quarter-width"
                          name="salesTaxPercent"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.salesTaxPercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <span className="side-text">%</span>
                      </div>
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="salesTaxPercent"
                      />
                    </Grid>
                    <Grid
                      item={true}
                      xs={4}
                      className="form-field-container remove-top-padding"
                    >
                      <InputLabel>Liquor Tax Percent</InputLabel>
                      <div className="side-text-container">
                        <TextField
                          className="field quarter-width"
                          name="liquorTaxPercent"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.liquorTaxPercent}
                          autoComplete="off"
                          inputProps={{
                            min: '0.00',
                            step: '0.01',
                            type: 'number',
                          }}
                        />
                        <span className="side-text">%</span>
                      </div>
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="liquorTaxPercent"
                      />
                    </Grid>
                    <TaxRates />
                  </Grid>
                  <Grid container={true} spacing={3} className="container">
                    <Grid item={true} xs={12}>
                      <Typography className="container-title">
                        Tag Settings
                        <span className="appended-text"> (Optional)</span>
                      </Typography>
                    </Grid>
                    <Tagging />
                  </Grid>
                  <div className="container-white-background">
                    <Grid container={true} spacing={3} className="container">
                      <Grid item={true} xs={12}>
                        <Typography className="container-title">
                          Add Image
                        </Typography>
                        <Typography className="italic">
                          {includes(
                            ['TENANT_BUILDING', 'SERVICE_PROVIDER'],
                            venue.type,
                          )
                            ? organizationDetails.tenantBuildingImageInfo
                            : organizationDetails.venueBannerImageInfo}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12}>
                        {venueImgUrl ? (
                          <img
                            alt="venue.name"
                            src={venueImgUrl}
                            width={500}
                            height={
                              includes(
                                ['TENANT_BUILDING', 'SERVICE_PROVIDER'],
                                venue.type,
                              )
                                ? 400
                                : 150
                            }
                          />
                        ) : null}
                        <div className="add-image-component">
                          <AddImage
                            getFile={(imageFile: any) => {
                              setNewImageFile(imageFile);
                            }}
                            width={500}
                            height={
                              includes(
                                ['TENANT_BUILDING', 'SERVICE_PROVIDER'],
                                venue.type,
                              )
                                ? 400
                                : 150
                            }
                            showImageDetails={showImageDetails}
                            setShowImageDetails={setShowImageDetails}
                            minWidth={500}
                            minHeight={150}
                          />
                        </div>
                        {newImageFile && showImageDetails && (
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="upload-img-btn dark-button"
                            onClick={() => uploadImage(newImageFile)}
                          >
                            Done Editing
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  {!venue.useNewLocations && (
                    <div className="container-white-background">
                      <Grid container={true} spacing={3} className="container">
                        <Grid item={true} xs={12}>
                          <Typography className="container-title">
                            Add Delivery Spots
                          </Typography>
                          <Typography className="italic">
                            {organizationDetails.deliverySpotsInfo}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <Button
                            onClick={() => {
                              setShowValidLocationsDialog(true);
                            }}
                            variant="contained"
                            className="dark-button"
                          >
                            Add a spot
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <div className="locations-grid-container">
                                              
                            {validLocationTypes.map(
                              (validLocation: any, index: any) => (
                                <Grid
                                  key={index}
                                  item={true}
                                  className="grid-item"
                                >
                                       
                                  <div className="location-name">
                                    {validLocation.name}
                                  </div>
                                                        
                                  <div className="icon-container">
                                    <Icon
                                      className="icon-btn"
                                      onClick={() => {
                                        setShowValidLocationsDialog(true);
                                      }}
                                    >
                                      edit
                                    </Icon>
                                    <Icon
                                      className="icon-btn"
                                      onClick={() => {
                                        handleDeleteLocation(validLocation);
                                      }}
                                    >
                                      close
                                    </Icon>
                                  </div>
                                </Grid>
                              ),
                            )}
                                            
                          </div>
                          {displayValidLocationsError &&
                            values.deliveryAvailable && (
                              <div className="required-text">
                                Need atleast one valid location.
                              </div>
                            )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <Grid container={true} className="padding-bottom">
                    <Grid className="form-save" item={true} xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="save-btn"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={onCancel.bind(null, resetForm)}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
      {showValidLocationsDialog && (
        <ValidLocationsDialog
          close={() => {
            setShowValidLocationsDialog(false);
          }}
          onLocationUpdate={async (value: string) => await onUpdate()}
          venueId={venueId}
          validLocationTypes={validLocationTypes}
        />
      )}
    </div>
  ) : null;
};

export default withSnackbar(OrganizationDetails);
