import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { get } from 'lodash';
import React, { useState } from 'react';
import './AddVenueDialog.scss';

const AddVenueDialog = (props: any) => {
  const { onClose, onAddVenue } = props;
  const [name, setName] = useState('');

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <span className="section-title">Create Venue</span>
      </DialogTitle>
      <DialogContent>
        <div className="generic-dialog">
          <TextField
            required={true}
            name="venueName"
            inputProps={{
              type: 'string',
            }}
            onChange={event => setName(get(event, ['target', 'value'], ''))}
            classes={{
              root: 'text-field',
            }}
            label="Name"
            value={name}
            autoComplete="off"
          />
          <div className="button-holder-different-margin">
            <DialogActions>
              <Button onClick={onClose} color="primary" variant="outlined">
                <div className="icon-title-wrapper">
                  <UndoOutlinedIcon />
                  <span className="icon-title">Cancel</span>
                </div>
              </Button>
              <Button
                onClick={() => onAddVenue(name)}
                color="primary"
                variant="contained"
                disabled={!name.length}
                classes={{
                  containedPrimary: 'custom-colored-button-filled',
                }}
              >
                <div className="icon-title-wrapper">
                  <CheckOutlinedIcon />
                  <span className="icon-title">Create</span>
                </div>
              </Button>
            </DialogActions>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddVenueDialog;
