import React, { useEffect, useState } from 'react';
import { TabPanel } from '../../components/TabPanel';
// import TabContext from '@material-ui/lab/TabContext';
// import TabList from '@material-ui/lab/TabList';
import Products from './Products';
import { Tab, Tabs } from '@material-ui/core';
import CategoryService from '../../services/Category/Category.service';
import { useAppContext } from '../../config/AppContext';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export function PosTabs({
  addToCart,
  Productloading,
  fetchProducts,
  handleFetchProductsFalse,
}: any) {
  const { selectedStoreId } = useAppContext();

  const [tabs, setTabs] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>(0);
  const [selectedTab, setSelectedTab] = useState('1');

  const useStyles = makeStyles({
    tabs: {
      '& .MuiTabs-fixed': {
        overflow: 'scroll !important',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#2cb691',
        height: 5,
        width: '20px',
      },
      '& .MuiTab-root.Mui-selected': {
        color: '#2cb691',
        'font-weight': 'bold',
      },
    },
  });
  const classes = useStyles();

  const handleChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
    console.log('tab changed', newValue);
  };

  const fetchStoreCategoriesByStoreId = async () => {
    const rowsPerPage = 100;
    const page = 0;
    const store = selectedStoreId!;

    try {
      const response: any = await  CategoryService.getAllPOSCategories({
        storeId: store,
        limit: rowsPerPage,
        page: page + 1,
        active: true,
        type: ['MERCHANDISE', 'STANDALONE', 'COMBO']
      });
      const categoryTabs = response['data'].items
        .filter((x: any) => x.type !== 'OPTIONS')
        .map((category: any, index: any) => ({
          value: (index + 1).toString(),
          key: index,
          label: category.name,
          id: category.id,
          name: category.name,
          component: <Products categoryId={category.id} />,
        }));
      setTabs(categoryTabs);
      setCategoryId(response.data.items[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCateogry = (val: any) => {
    console.log(val.id, 'vallllll');
    setCategoryId(val.id);
  };

  useEffect(() => {
    fetchStoreCategoriesByStoreId();
  }, [selectedStoreId]);
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        textColor="primary"
        className={classes.tabs}
      >
        {tabs.map((tab: any) => {
          return (
            <Tab
              onClick={() => handleCateogry(tab)}
              label={tab.label}
              key={tab.value}
              value={tab.value}
            />
          );
        })}
      </Tabs>
      {Productloading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <Products
            addToCart={addToCart}
            categoryId={categoryId}
            fetchProducts={fetchProducts}
            handleFetchProductsFalse={handleFetchProductsFalse}
          />
        </>
      )}

      {/* {
              tabs.map((tab : any) => {
                  return (
                      <Tab label={tab.label} key={tab.value} value={tab.value} />
                  )
              })
          } */}
      {/* </TabPanel> */}
      {/* <TabContext value={selectedTab}>
                    <TabList onChange={handleChange}
                        variant="scrollable"
                    className={`${classes.tabs} tabs-style`}
                    >
                        {
                            tabs.map((tab : any) => {
                                return (
                                    <Tab label={tab.label} key={tab.value} value={tab.value} />
                                )
                            })
                        }
                    </TabList>
                {
                    tabs.map((panel : any) => {
                        return (
                            <TabPanel key={panel.value} value={panel.value}>
                                {panel.component}
                            </TabPanel>
                        )
                    })
                }
            </TabContext> */}
    </>
  );
}
