const storeData = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

const getLocalData = (key: string) => {
  return localStorage.getItem(key);
};

const clearLocalData = async () => {
  const keyToKeep = 'tenant-schema';

  for (let i = 0; i < localStorage.length; i++) {
    const key: any = localStorage.key(i);
    if (key !== keyToKeep) {
      localStorage.removeItem(key);
    }
  }
  localStorage.removeItem('authToken');
  localStorage.removeItem('_grecaptcha');
  localStorage.removeItem('selectedStoreId');
};

const clearLocalByKey = (key: string) => {
  localStorage.removeItem(key);
};

export default {
  clearLocalByKey,
  clearLocalData,
  getLocalData,
  storeData,
};
