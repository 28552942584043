import React, { useState } from 'react';
import EditPromoUsers from './EditPromoUsers';
import './Promos.scss';
import PromoTable from './PromoTable';

interface IPromoProps {
  venueId: string;
}

const Promos = (props: IPromoProps): JSX.Element => {
  const { venueId } = props;

  const [editUsersPromotionId, setEditUsersPromotionId] = useState<
    string | undefined
  >(undefined);

  const onCloseEditUsers = (): void => setEditUsersPromotionId(undefined);
  const onEditUsers = (promotionId: string): void =>
    setEditUsersPromotionId(promotionId);

  if (editUsersPromotionId) {
    return (
      <EditPromoUsers
        {...{ onCloseEditUsers, promotionId: editUsersPromotionId }}
      />
    );
  }

  return <PromoTable {...{ onEditUsers, venueId }} />;
};

export default Promos;
