import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getDate, getMonth, getYear } from 'date-fns';
import { ErrorMessage, Field, Formik, FormikActions } from 'formik';
import { get, set } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import DeleteDialog from '../../../components/DeleteDialog';
import { useAppContext } from '../../../config/AppContext';
import { ICreateACHAccountRequest } from '../../../models/Request.model';
import GoogleAutoComplete from '../../Map/GoogleAutoComplete';
import {
  achSchema,
  initialAchDetails,
  IPaymentSettingsFormValues,
  IPaymentSettingsProps,
  paymentSettingsInfo,
} from '../Settings.config';
import './PaymentSettings.scss';

declare const Stripe: (
  key: string,
) => {
  createToken: (type: string, options: any) => Promise<any>;
};

declare const ENVARS: any;

const PaymentSettings = (props: IPaymentSettingsProps) => {
  const {
    bankAccountLast4,
    bankAccountName,
    disableActionButton,
    gatewayConnectAccountId,
    onUpdate,
    onDelete,
  } = props;
  const [editACH, setEditACH] = useState(false);
  const { setShowSpinner } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [acceptedTC, setAcceptedTC] = useState(false);

  const handleAchDetails = async (
    values: IPaymentSettingsFormValues,
    formikActions: FormikActions<IPaymentSettingsFormValues>,
  ) => {
    setShowSpinner(true);
    const stripe = Stripe(ENVARS.STRIPE.API_KEY);
    const accountType = values.businessUnit ? 'company' : 'individual';
    const accountHolderDetails = {
      address: {
        city: values.city,
        line1: values.addressLine1,
        line2: values.addressLine2,
        postal_code: values.zip,
        state: values.state,
      },
      dob: {
        day: getDate(values.dob),
        month: getMonth(values.dob) + 1,
        year: getYear(values.dob),
      },
      first_name: values.firstName,
      last_name: values.lastName,
      ssn_last_4: values.ssnLast4,
    };
    const accountOptions = {
      business_type: accountType,
      tos_shown_and_accepted: true,
    };
    let personTokenResponse;
    let tokenInfo: ICreateACHAccountRequest;

    if (values.businessUnit) {
      set(accountOptions, 'company', {
        name: values.companyName,
        tax_id: values.companyTaxId,
      });
    } else {
      set(accountOptions, 'individual', accountHolderDetails);
    }

    try {
      const {
        error: bankAccountError,
        token: bankAccountToken,
      } = await stripe.createToken('bank_account', {
        account_holder_name: `${values.firstName} ${values.lastName}`,
        account_holder_type: accountType,
        account_number: values.accountNumber,
        country: 'US',
        currency: 'usd',
        routing_number: values.routingNumber,
      });

      if (bankAccountError) {
        // tslint:disable-next-line: no-console
        console.log(bankAccountError.message);

        throw new Error(bankAccountError.message);
      }

      const {
        error: accountError,
        token: accountToken,
      } = await stripe.createToken('account', accountOptions);

      if (accountError) {
        // tslint:disable-next-line: no-console
        console.log(accountError.message);

        throw new Error(accountError.message);
      }

      if (values.businessUnit) {
        personTokenResponse = await stripe.createToken('person', {
          person: {
            ...accountHolderDetails,
            relationship: {
              representative: true,
            },
          },
        });
      }

      if (personTokenResponse && personTokenResponse.error) {
        // tslint:disable-next-line: no-console
        console.log(personTokenResponse.error.message);

        throw new Error(personTokenResponse.error.message);
      }

      tokenInfo = {
        accountToken: get(accountToken, 'id'),
        externalAccountToken: get(bankAccountToken, 'id'),
      };

      if (values.businessUnit) {
        set(
          tokenInfo,
          'personToken',
          get(personTokenResponse, ['token', 'id']),
        );
      }
      await onUpdate(tokenInfo);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.message);
      enqueueSnackbar(
        get(error, ['message'], 'Something went wrong. Please try again'),
        {
          variant: 'error',
        },
      );
    }
    setShowSpinner(false);
    formikActions.setSubmitting(false);
  };

  const customHandleChange = (e: React.ChangeEvent<any>, handleChange: any) => {
    handleChange(e);
  };

  return !editACH && gatewayConnectAccountId && bankAccountLast4 ? (
    <div className="current-account">
      <div className="container">
        <h3>Current Account</h3>
        <div className="details">
          <p>Account Number:</p>
          <p>{`**** **** **** ${bankAccountLast4}`}</p>
        </div>
        <div className="details">
          <p>Bank Name:</p>
          <p>{bankAccountName}</p>
        </div>
        <div className="action-buttons">
          <Button
            variant="contained"
            color="primary"
            disabled={disableActionButton}
            onClick={() => setEditACH(true)}
          >
            Edit
          </Button>
          <Button
            disabled={disableActionButton}
            variant="contained"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
          {showDeleteModal && (
            <DeleteDialog
              dialogTitle="Delete Current Account"
              dialogContent="Are you sure you want to remove this account?"
              onClose={() => setShowDeleteModal(false)}
              onSubmit={async () => {
                await onDelete();
                setShowDeleteModal(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="payment-settings">
      <Typography className="subtitle-info">
        {paymentSettingsInfo.subtitleInfo}
      </Typography>
      <Typography className="subtitle required-text">
        {paymentSettingsInfo.requiredInfo}
      </Typography>
      <Typography className="subtitle margin-bottom">
        {paymentSettingsInfo.optionalInfo}
      </Typography>

      <Formik<IPaymentSettingsFormValues>
        initialValues={initialAchDetails}
        onSubmit={handleAchDetails}
        validationSchema={achSchema}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          errors,
          touched,
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <Grid container={true} className="container" spacing={3}>
                <Grid item={true} xs={12}>
                  <Typography className="container-title">
                    Organization Information
                  </Typography>
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Account Number</InputLabel>
                  <TextField
                    error={
                      get(touched, 'accountNumber') &&
                      !!get(errors, 'accountNumber')
                    }
                    required={true}
                    className="field"
                    name="accountNumber"
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    value={values.accountNumber}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="accountNumber"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">
                    Confirm Account Number
                  </InputLabel>
                  <TextField
                    error={
                      get(touched, 'accountNumberConfirm') &&
                      !!get(errors, 'accountNumberConfirm')
                    }
                    required={true}
                    className="field"
                    name="accountNumberConfirm"
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    value={values.accountNumberConfirm}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="accountNumberConfirm"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Routing Number</InputLabel>
                  <TextField
                    error={
                      get(touched, 'routingNumber') &&
                      !!get(errors, 'routingNumber')
                    }
                    required={true}
                    className="field"
                    name="routingNumber"
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    value={values.routingNumber}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="routingNumber"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">
                    Last 4 digits of EIN/SSN
                  </InputLabel>
                  <TextField
                    error={
                      get(touched, 'ssnLast4') && !!get(errors, 'ssnLast4')
                    }
                    required={true}
                    className="field width-30"
                    name="ssnLast4"
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    value={values.ssnLast4}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="ssnLast4"
                  />
                </Grid>
              </Grid>
              <Grid container={true} className="container" spacing={3}>
                <Grid item={true} xs={12}>
                  <Typography className="container-title">
                    Business Details
                  </Typography>
                </Grid>
                <>
                  <Grid item={true} xs={5} className="form-field-container">
                    <InputLabel className="required">Business Name</InputLabel>
                    <TextField
                      error={
                        get(touched, 'companyName') &&
                        !!get(errors, 'companyName')
                      }
                      onChange={e => customHandleChange(e, handleChange)}
                      onBlur={handleBlur}
                      name="companyName"
                      className="field"
                      value={values.companyName}
                      autoComplete="off"
                      required={true}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="companyName"
                    />
                  </Grid>
                  <Grid item={true} xs={5} className="form-field-container">
                    <InputLabel className="required">
                      Business Tax ID
                    </InputLabel>

                    <TextField
                      error={
                        get(touched, 'companyTaxId') &&
                        !!get(errors, 'companyTaxId')
                      }
                      className="field"
                      name="companyTaxId"
                      onChange={e => customHandleChange(e, handleChange)}
                      onBlur={handleBlur}
                      value={values.companyTaxId}
                      autoComplete="off"
                      required={true}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="companyTaxId"
                    />
                  </Grid>
                </>
              </Grid>
              <Grid container={true} spacing={3} className="container">
                <Grid item={true} xs={12}>
                  <Typography className="container-title">
                    Ownership Validation
                  </Typography>
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">First Name</InputLabel>
                  <TextField
                    error={
                      get(touched, 'firstName') && !!get(errors, 'firstName')
                    }
                    required={true}
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    name="firstName"
                    className="field"
                    value={values.firstName}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="firstName"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Last Name</InputLabel>
                  <TextField
                    error={
                      get(touched, 'lastName') && !!get(errors, 'lastName')
                    }
                    required={true}
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    name="lastName"
                    className="field"
                    value={values.lastName}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="lastName"
                  />
                </Grid>

                <Grid className="form-field-container" item={true} xs={2}>
                  <InputLabel className="required">Date of Birth</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required={true}
                      name="dob"
                      format="MM/dd/yyyy"
                      className="field"
                      variant="inline"
                      value={values.dob}
                      onChange={value => {
                        setFieldValue('dob', value);
                      }}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="dob"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Street Address</InputLabel>
                  <Field
                    required={true}
                    name="addressLine1"
                    className="field"
                    render={({ field, form }: { field: any; form: any }) => (
                      <GoogleAutoComplete
                        className="field"
                        field={field}
                        error={
                          get(touched, 'addressLine1') &&
                          !!get(errors, 'addressLine1')
                        }
                        value={values.addressLine1}
                        onPlaceLoaded={async (
                          latitude: number,
                          longitude: number,
                          address: any,
                        ) => {
                          const { addressLine1, city, state, zip } = address;

                          setFieldValue('addressLine1', addressLine1);
                          setFieldValue('city', city);
                          setFieldValue('state', state);
                          setFieldValue('zip', zip || '');
                          setFieldValue('latitude', latitude);
                          setFieldValue('longitude', longitude);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="addressLine1"
                  />
                </Grid>
                <Grid item={true} xs={5} className="form-field-container">
                  <InputLabel>
                    Suite, Apt, Floor etc.
                    <p className="appended-text">(Optional)</p>
                  </InputLabel>

                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="addressLine2"
                    className="field width-40"
                    value={values.addressLine2}
                    autoComplete="off"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={2}>
                  <InputLabel className="required">Zipcode</InputLabel>
                  <TextField
                    error={get(touched, 'zip') && !!get(errors, 'zip')}
                    required={true}
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    name="zip"
                    className="field"
                    value={values.zip}
                    autoComplete="off"
                  />
                  <ErrorMessage className="error" component="div" name="zip" />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">State</InputLabel>
                  <TextField
                    error={get(touched, 'state') && !!get(errors, 'state')}
                    required={true}
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    name="state"
                    className="field"
                    value={values.state}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="state"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">City</InputLabel>
                  <TextField
                    error={get(touched, 'city') && !!get(errors, 'city')}
                    required={true}
                    onChange={e => customHandleChange(e, handleChange)}
                    onBlur={handleBlur}
                    name="city"
                    className="field"
                    value={values.city}
                    autoComplete="off"
                  />
                  <ErrorMessage className="error" component="div" name="city" />
                </Grid>
              </Grid>

              <Grid container={true} className="terms-and-conditions">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="checkbox"
                      checked={acceptedTC}
                      onChange={(e, checked) => setAcceptedTC(checked)}
                    />
                  }
                  label={
                    <span>
                      I have read and agree to the{' '}
                      <Link
                        href="https://stripe.com/connect-account/legal"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Stripe Connected Account Agreement
                      </Link>
                    </span>
                  }
                />
              </Grid>

              <Grid container={true} className="padding-bottom">
                <Grid item={true} xs={12} className="form-save">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="save-btn"
                    disabled={isSubmitting || !acceptedTC}
                  >
                    Save
                  </Button>
                  {gatewayConnectAccountId && bankAccountLast4 && (
                    <Button
                      variant="outlined"
                      onClick={() => setEditACH(false)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default PaymentSettings;
