import IBaseModel from './Base.model';

export enum Country {
  AE = 'AE',
  CA = 'CA',
  US = 'US',
  ZA = 'ZA',
}

export enum CurrencyCode {
  AED = 'AED',
  CAD = 'CAD',
  USD = 'USD',
  ZAR = 'ZAR',
}

export enum PaymentGateway {
  SQUARE = 'SQUARE',
  STRIPE = 'STRIPE',
  BYPASS = 'BYPASS',
}

interface ITheme {
  primaryColor: string | null;
}

interface mobileCCFees {
  mobileCCFeesPercentage?: number;
  mobileCCFeesNumber?: number;
}

interface posCCFees {
  posCCFeesPercentage?: number;
  posCCFeesNumber?: number;
}
export interface IVenue extends IBaseModel {
  active: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  alcoholAgeRestriction: number;
  alcoholLimit: number;
  alcoholMessage: string | null;
  allowFreeProducts: boolean;
  allowOpenTabs: boolean;
  bankAccountLast4: string;
  bankAccountName: string;
  calculateTaxes: boolean;
  city: string | null;
  contactCountryCode: string;
  contactEmail: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  contactPhoneNumber: string | null;
  country: Country;
  currency: CurrencyCode;
  deferPaymentCapture: boolean;
  deliveryAvailable: boolean;
  deliveryFee: number;
  deliveryFeeSharePercent: number;
  deliveryFeeUnit: string;
  deliveryRevenueSharePercent: number;
  description: string | null;
  enableDeliveryTips: boolean;
  customDiscount: boolean;
  enablePickupTips: boolean;
  gatewayConnectAccountId: string;
  imageUrl: string | null;
  isDeleted: boolean;
  latitude: number | null;
  liquorTaxPercent: number;
  longitude: number | null;
  mapEastBound: number | null;
  mapImageUrl: string | null;
  mapLat: number | null;
  mapLng: number | null;
  mapNorthBound: number | null;
  mapSouthBound: number | null;
  mapWestBound: number | null;
  maxDistance: number | null;
  name: string;
  oldVenueId: number | null;
  orderAheadAvailable: boolean;
  orderNowAvailable: boolean;
  paymentGateway: PaymentGateway;
  pickupAvailable: boolean;
  pickupFee: number;
  pickupFeeSharePercent: number;
  pickupFeeUnit: string; // needs enum
  pickupRevenueSharePercent: number;
  //POS Fees
  posFee: number;
  posFeeSharePercent: number;
  posFeeUnit: string; // needs enum
  posRevenueSharePercent: number;
  //Service Fees
  serviceAvailable: boolean;
  serviceFee: number;
  serviceFeeSharePercent: number;
  serviceFeeUnit: string; 
  //Service Fees
  promotionFundLeft: number;
  promotionThreshold: number;
  salesTaxPercent: number;
  sendOrderNotifications: boolean;
  sponsorModules?: any[]; // needs ISponsorModule
  state: string;
  tags: string[];
  theme: ITheme | null;
  timezone: string;
  tipRecipient: boolean;
  type: string; // needs enum
  useNewLocations: boolean;
  validLocationTypes: any[]; // needs ILocationType
  validLocations: any[] | null; // needs IValidLocation
  vanityUrl: string | null;
  zip: string | null;
  mobileCCFees?: mobileCCFees;
  posCCFees?: posCCFees;
  mobileCCFeesPercentage?: number;
  mobileCCFeesNumber?: number;
  posCCFeesPercentage?: number;
  posCCFeesNumber?: number;
}

export interface IVenueAch {
  accountNumber: string;
  accountNumberConfirm: string;
  addressLine1: string;
  addressLine2: string;
  businessUnit: boolean;
  city: string;
  companyName: string;
  companyTaxId: string;
  dob: Date;
  firstName: string;
  lastName: string;
  routingNumber: string;
  ssnLast4: string;
  state: string;
  zip: string;
}
