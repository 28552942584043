import { Button, Grid, Icon, InputLabel } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import React from 'react';

const SelectProductImage = ({
  touched,
  values,
  errors,
  setFieldValue,
  onOpenGlobalProducts,
  onOpenAddCustomImage,
}: any) => {
  return (
    <Grid
      className="menu-add-edit__row combo"
      alignItems="flex-start"
      container={true}
      wrap="nowrap"
    >
      <Grid item={true} xs={3} style={{ alignSelf: 'flex-end' }}>
        <InputLabel
          className="menu-add-edit__form-field-label"
          error={touched.imageUrl && !!errors.imageUrl}
        >
          Photo
        </InputLabel>
      </Grid>
      <Grid
        container={true}
        item={true}
        wrap="nowrap"
        alignItems="center"
        justify="center"
        className="image-upload-button-container"
      >
        <div className="pos-rel">
          {!values.isCustomImage && values.imageUrl && (
            <div className="image-upload-button-container__image-container">
              <Icon
                onClick={() => {
                  setFieldValue('globalProductId', null);
                  setFieldValue('imageUrl', '');
                }}
                classes={{
                  root: 'image-upload-button-container__image-container__icon',
                }}
              >
                close
              </Icon>
              <img alt={values.name} src={values.imageUrl} />
            </div>
          )}
          <Button
            color="primary"
            onClick={onOpenGlobalProducts}
            variant="contained"
            className="image-upload-button-container__label"
          >
            Select from catalog
          </Button>

          <ErrorMessage
            render={() => (
              <span className="menu-add-edit__form-field-error">
                Must choose a product image.
              </span>
            )}
            name="imageUrl"
          />
        </div>
        <p>OR</p>
        <div className="pos-rel">
          {values.isCustomImage && values.imageUrl && (
            <div className="image-upload-button-container__image-container">
              <Icon
                onClick={() => {
                  setFieldValue('imageFile', null);
                  setFieldValue('imageUrl', '');
                }}
                classes={{
                  root: 'image-upload-button-container__image-container__icon',
                }}
              >
                close
              </Icon>
              <img alt={values.name} src={values.imageUrl} />
            </div>
          )}
          <Button
            color="primary"
            onClick={onOpenAddCustomImage}
            variant="contained"
            className="image-upload-button-container__label"
          >
            Upload Custom Image
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export { SelectProductImage };
