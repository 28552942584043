import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../../config/AppContext';
import ILineItemGroup from '../../../models/LineItemGroup.model';
import { IOrder } from '../../../models/Order.model';
import { IStore } from '../../../models/Store.model';
import { IUserAdmin } from '../../../models/UserAdmin.model';
import OrdersService from '../../../services/Orders/Orders.service';
import OrderDetailsDialog from '../OrderDetailsDialog/OrderDetailsDialog';
import {
  getOrderStates,
  IOrderSchema,
  IUpdateLineItemGroup,
  RefundType,
  VALIDSTATUS,
} from '../Orders.config';
import RunnerDialog from '../RunnerDialog/RunnerDialog';
import OrderRow from './OrderRow';
import './OrdersTable.scss';
import RefundModal, { IRefundItem } from './RefundModal/RefundModal';
interface IOrderTableProps extends WithSnackbarProps {
  assumeAllOrdersAreCompleted: boolean;
  children: any | any[];
  expandOrders: boolean;
  isLoading: boolean;
  onUpdateLineItemGroupStatus: (
    lineItemGroupId: string,
    body: IUpdateLineItemGroup,
  ) => Promise<void>;
  orders: IOrder[];
  refresh: () => void;
  runners: IUserAdmin[];
  schema: IOrderSchema[];
  selectedRunnerId?: string;
  selectedStoreId?: string;
  selectRunner: (runnerId: string) => void;
  showActionButton: boolean;
  tabId?: (tabId: string, hide?: boolean) => void;
  tabValue?: number;
  terminalName?: string;
}

const OrdersTable = (props: IOrderTableProps) => {
  const {
    expandOrders,
    assumeAllOrdersAreCompleted,
    enqueueSnackbar,
    isLoading,
    onUpdateLineItemGroupStatus,
    orders,
    refresh,
    runners,
    schema,
    selectedRunnerId,
    selectedStoreId,
    selectRunner,
    showActionButton,
    tabId,
    tabValue,
    terminalName,
  } = props;
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [showOrderDetailsDialog, setShowOrderDetailsDialog] = useState(false);
  const [showRunnerDialog, setShowRunnerDialog] = useState(false);
  const [showCoverDialog, setShowDialogCover] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const { stores } = useAppContext();
  const [currentOrderForRunner, setCurrentOrderForRunner] = useState<any>({});
  const [coverValue, setCoverValue] = useState<any>('0');
  const [coverOrder, setCoverOrder] = useState<any>({});
  const storeInfo: IStore | undefined = stores.find(
    (store: IStore) => store.id === selectedStoreId,
  );
  const orderStates = getOrderStates(
    !!storeInfo && storeInfo.bypassOrderFulfillment,
  );

  useEffect(() => {
    if (!isEmpty(selectedOrder) && !showOrderDetailsDialog) {
      setShowModal(true);
    }
  }, [selectedOrder, showOrderDetailsDialog]);

  const refundOrder = async (
    type: string,
    id: string,
    reason: string,
    lineItems: IRefundItem[],
  ) => {
    try {
      if (type === RefundType.FULL) {
        await OrdersService.RefundOrder(id, { type, reason });
      } else if (type === RefundType.PARTIAL) {
        await OrdersService.RefundOrder(id, { type, reason, lineItems });
      }

      setShowModal(false);
      refresh();

      enqueueSnackbar('Order has been refunded successfully', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
  };

  const handleAssignRunner = async () => {
    const { lineItemGroups } = currentOrderForRunner;
    const storeControlledLig = lineItemGroups.find(
      (lig: ILineItemGroup) =>
        storeInfo && storeInfo.id === lig.fulfillmentCenterId,
    );

    if (!selectedRunnerId) {
      return;
    }
    if (!storeControlledLig) {
      return;
    }

    await onUpdateLineItemGroupStatus(storeControlledLig.id, {
      runnerId: selectedRunnerId,
      status: VALIDSTATUS.OUT_FOR_DELIVERY,
    });

    setShowRunnerDialog(false);
    refresh();
  };
  const handleAddCover = async () => {
    try {
      if (Object.keys(coverOrder).length > 0) {
        const { data } = await OrdersService.addCover(
          coverOrder.id,
          coverValue,
        );
        if (data) {
          setShowDialogCover(false);
          setCoverValue(data.cover);
          setCoverOrder({});
          setShowModal(false);
          refresh();
          enqueueSnackbar('Cover has been added successfully', {
            variant: 'success',
          });
        }
        else{
          enqueueSnackbar('Something went wrong', {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('No Order Selected', {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log(err.message);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
  };
  return (
    <div className="custom-table">
      {props.children}
      {orders.length <= 0 && (
        <Grid
          className="orders__no-data"
          container={true}
          alignItems="center"
          justify="center"
        >
          <Typography variant="body1">
            {isLoading ? 'Loading...' : 'No Data Available'}
          </Typography>
        </Grid>
      )}
      {!!storeInfo &&
        orders.map((order: IOrder, i: number) => (
          <OrderRow
            key={i}
            {...{
              expandByDefault: expandOrders,
              onUpdateLineItemGroupStatus,
              order,
              orderStates,
              schema,
              setCurrentOrderForRunner,
              setSelectedOrder,
              setShowOrderDetailsDialog,
              setShowRunnerDialog,
              showActionButton,
              store: storeInfo,
              tabIdCall: tabId,
              tabValue: Number(tabValue),
              terminalName: terminalName && terminalName,
              setShowDialogCover,
              setCoverOrder,
              setCoverValue
            }}
          />
        ))}
      {showModal && (
        <RefundModal
          onClose={() => {
            setShowModal(false);
            setSelectedOrder({});
          }}
          order={selectedOrder}
          orderStates={orderStates}
          refundOrder={refundOrder}
        />
      )}
      {showOrderDetailsDialog && (
        <OrderDetailsDialog
          order={selectedOrder}
          isOrderCompleted={assumeAllOrdersAreCompleted}
          onClose={() => {
            setShowOrderDetailsDialog(false);
            setSelectedOrder({});
          }}
        />
      )}
      {showRunnerDialog && (
        <RunnerDialog
          {...{
            handleAssignRunner,
            onClose: () => {
              setShowRunnerDialog(false);
              selectRunner('');
            },
            runners,
            selectRunner,
            selectedRunnerId,
          }}
        />
      )}
      <Dialog
        open={showCoverDialog}
        onClose={() => {
          setShowDialogCover(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="numberInput"
            label="Add Cover"
            type="number"
            value={coverValue}
            onChange={e => setCoverValue(e.target.value)}
            fullWidth
            inputProps={{ min: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddCover}
            color="primary"
            variant="outlined"
            disabled={!coverValue || parseInt(coverValue) < 1}
          >
            Add Cover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withSnackbar(OrdersTable);
