import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import React, { useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { IUserAdmin } from '../../../models/UserAdmin.model';
import { humanReadableRoles } from '../UserAdminConfig';
import '../Users.scss';

interface ITableConfig {
  accessor: (user: IUserAdmin) => string | number;
  header: string;
}

const tableConfig: ITableConfig[] = [
  {
    accessor: user => user.firstName,
    header: 'First Name',
  },
  {
    accessor: user => user.lastName,
    header: 'Last Name',
  },
  {
    accessor: user => user.email,
    header: 'Email',
  },
  {
    accessor: user =>
      formatPhoneNumber(`+${user.countryCode || '1'}${user.phoneNumber}`),
    header: 'Phone',
  },
  {
    accessor: user => humanReadableRoles[user.role],
    header: 'Role',
  },
];

const UserAdminTable = ({
  userAdmins,
  editUserAdmin,
  deleteUserAdmin,
  searchUserAdmins,
}: any) => {
  const [firstNameSearchTerm, setFirstNameSearchTerm] = useState('');
  const [lastNameSearchTerm, setLastNameSearchTerm] = useState('');

  return (
    <div className="users-table-container">
      <Table>
        <TableHead>
          <TableRow
            classes={{
              root: 'table-row',
            }}
          >
            {tableConfig.map((config: ITableConfig, index: any) => (
              <TableCell
                key={index}
                classes={{ root: 'table-cell table-cell-head' }}
              >
                {config.header}
              </TableCell>
            ))}
            <TableCell classes={{ root: 'table-cell-action table-cell-head' }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              key={0}
              align="left"
              classes={{ root: 'user-table-body__cells' }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                onChange={event => {
                  const {
                    target: { value },
                  } = event;
                  setFirstNameSearchTerm(value);
                }}
                value={firstNameSearchTerm}
              />
            </TableCell>
            <TableCell
              key={1}
              align="left"
              classes={{ root: 'user-table-body__cells' }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                onChange={event => {
                  const {
                    target: { value },
                  } = event;
                  setLastNameSearchTerm(value);
                }}
                value={lastNameSearchTerm}
              />
            </TableCell>
            <TableCell colSpan={tableConfig.length - 2} />
            <TableCell
              align="center"
              classes={{ root: 'user-table-body__cells' }}
            >
              <Button
                onClick={() =>
                  searchUserAdmins(firstNameSearchTerm, lastNameSearchTerm)
                }
                classes={{ root: 'search-button' }}
              >
                <div className="icon-title-wrapper">
                  <SearchOutlinedIcon />
                  <span className="icon-title">Search</span>
                </div>
              </Button>
            </TableCell>
          </TableRow>
          {userAdmins.length > 0
            ? userAdmins.map((admin: IUserAdmin, index: number) => (
                <TableRow key={index} className="users-table-row">
                  {tableConfig.map((config: ITableConfig, index2: number) => (
                    <TableCell key={index2} classes={{ root: 'table-cell' }}>
                      {config.accessor(admin)}
                    </TableCell>
                  ))}
                  <TableCell classes={{ root: 'table-cell-action' }}>
                    <Button
                      size="small"
                      onClick={() => editUserAdmin(admin)}
                      classes={{
                        root: 'userAdmin-table-button',
                      }}
                      value="edit"
                    >
                      <div className="icon-title-wrapper">
                        <EditOutlinedIcon />
                        <span className="icon-title">Edit</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      value="delete"
                      onClick={() => deleteUserAdmin(admin)}
                      classes={{
                        root: 'userAdmin-table-button',
                      }}
                    >
                      <div className="icon-title-wrapper">
                        <DeleteOutlinedIcon />
                        <span className="icon-title">Delete</span>
                      </div>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : noDataAvailable(6)}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserAdminTable;
