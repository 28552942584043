import { IPrinter, IPrinterWithStore } from '../../models/Printer.model';
import axiosWithAuth from '../ServiceConfig';

export interface ICreatePrinterDto {
  copyCount: number;
  name: string;
  storeId: string;
  allOrders: boolean;
  mobileOrders: boolean;
  terminals: string[];
  printAuto : boolean;
}

export interface IUpdatePrinterDto {
  copyCount: number;
  name: string;
  allOrders: boolean;
  mobileOrders: boolean;
  terminals: string[];
  printAuto : boolean;
}

const baseUrl = `/printers`;

const getAll = () => axiosWithAuth.get<IPrinterWithStore[]>(baseUrl);

const addPrinter = (body: ICreatePrinterDto) =>
  axiosWithAuth.post<IPrinter>(baseUrl, body);

const updatePrinter = (printerId: string, body: IUpdatePrinterDto) =>
  axiosWithAuth.put<IPrinter>(`${baseUrl}/${printerId}`, body);

const movePrinter = (printerId: string, body: any) =>
axiosWithAuth.put(`${baseUrl}/move/${printerId}`, body);

const clearPrintQueue = (printerId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${printerId}/clearQueue`);

const PrintDocument = (order: any) =>
  axiosWithAuth.post(`${baseUrl}/print-document`, order);

export default {
  addPrinter,
  clearPrintQueue,
  getAll,
  updatePrinter,
  PrintDocument,
  movePrinter
};
