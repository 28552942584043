import IBaseModel from './Base.model';
import ICategory from './Category.model';
import { Partner } from './Partner.model';
import { ITaxRate } from './TaxRate.model';
import { IProductTag } from './ProductTag.model';
export default interface IProduct extends IBaseModel {
  active: boolean;
  alcohol: boolean;
  availabilityType: string;
  category?: ICategory;
  categoryId: string;
  tagId: string;
  description?: string;
  fulfillmentCenterId: string;
  globalProductId?: string;
  globalProduct?: IGlobalProduct;
  hasSubProducts: boolean;
  imageUrl?: string;
  name: string;
  partner: Partner;
  partnerProductId: string;
  price: number;
  priority: number;
  stock: number;
  storeId: string;
  subCategories: ISubCategory[];
  taxable: boolean;
  pos: boolean;
  testAvailabilityType: string[];
  countAsInventory: boolean;
  inventoryCount: number;
  tags: IProductTag[];
}

export enum AvailabilityType {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
  POS = 'POINT OF SALE',
  ALL = 'ALL',
  NONE = 'NONE',
}

export interface ISubCategory {
  categoryId: string;
  multiselect: boolean;
  required: boolean;
  selectionLimit: number;
  subProducts: ISubProduct[];
}

export interface ISubProduct {
  autoSelect: boolean;
  active: boolean;
  price: number;
  productId: string;
  quantityLimit: number;
}

export interface IGlobalProduct {
  id: string;
  imageUrl: string;
  name: string;
}

export enum TaxRateType {
  CUSTOM = 'CUSTOM',
  STORE_LIQUOR = 'STORE_LIQUOR',
  STORE_SALES = 'STORE_SALES',
  VENUE_LIQUOR = 'VENUE_LIQUOR',
  VENUE_SALES = 'VENUE_SALES',
}

export const humanReadableTaxRateTypes: { [key in TaxRateType]: string } = {
  [TaxRateType.CUSTOM]: 'Custom Tax Rate',
  [TaxRateType.STORE_LIQUOR]: 'Store Liquor Tax Rate',
  [TaxRateType.STORE_SALES]: 'Store Sales Tax Rate',
  [TaxRateType.VENUE_LIQUOR]: 'Venue Liquor Tax Rate',
  [TaxRateType.VENUE_SALES]: 'Venue Sales Tax Rate',
};

export interface IProductTaxes {
  effectiveTaxRate: number;
  taxRateType: TaxRateType;
  taxRates: ITaxRate[];
}
