import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ICategory from '../../../../models/Category.model';
import IProduct from '../../../../models/Product.model';
import { ICategoryProducts, ISubCategoryMap } from '../AddEditProductForm';
import ComboTable from './ComboTable';

enum Checked {
  CHECKED,
  PARTIAL,
  UNCHECKED,
}

interface IComboSelectItemsProps {
  categories: ICategory[];
  categoryProducts: ICategoryProducts;
  setSubCategoryMap: React.Dispatch<React.SetStateAction<ISubCategoryMap>>;
  subCategoryMap: ISubCategoryMap;
}

const ComboSelectItems = ({
  categories,
  categoryProducts,
  setSubCategoryMap,
  subCategoryMap,
}: IComboSelectItemsProps) => {
  const [activeCategoryId, setActiveCategoryId] = useState<string | undefined>(
    undefined,
  );

  const setSubCategory = (
    subCategory: ISubCategoryMap[keyof ISubCategoryMap],
  ) => {
    const newSubCategoryMap = { ...subCategoryMap };
    newSubCategoryMap[subCategory.categoryId] = subCategory;
    setSubCategoryMap(newSubCategoryMap);
  };

  const deleteSubCategory = (categoryId: string): void => {
    const newSubCategoryMap = { ...subCategoryMap };
    delete newSubCategoryMap[categoryId];
    setSubCategoryMap(newSubCategoryMap);
  };

  const findOrBuildSubCategory = (
    categoryId: string,
  ): ISubCategoryMap[keyof ISubCategoryMap] => {
    const subCategory = subCategoryMap[categoryId];
    if (subCategory) {
      return subCategory;
    }

    return {
      categoryId,
      multiselect: false,
      required: false,
      selectionLimit: 0,
      subProductMap: {},
    };
  };

  const onToggleAllCategoryProducts = (categoryId: string) => {
    // If CHECKED, delete the entire subCategory
    if (getCategoryCheckStatus(categoryId) === Checked.CHECKED) {
      return deleteSubCategory(categoryId);
    }

    const subCategory = findOrBuildSubCategory(categoryId);
    categoryProducts[categoryId]
      .filter((p: IProduct) => !subCategory.subProductMap[p.id])
      .forEach((p: IProduct) => {
        subCategory.subProductMap[p.id] = {
          autoSelect: false,
          price: 0,
          productId: p.id,
          active: p.active,
          quantityLimit: 1,
        };
      });

    setSubCategory(subCategory);
  };

  const onToggleMultiselect = (categoryId: string) => {
    const subCategory = findOrBuildSubCategory(categoryId);

    subCategory.multiselect = !subCategory.multiselect;
    if (subCategory.multiselect) {
      subCategory.selectionLimit = Object.keys(
        subCategory.subProductMap,
      ).length;
    } else {
      subCategory.selectionLimit = 0;
      for (const subProduct of Object.values(subCategory.subProductMap)) {
        subProduct.autoSelect = false;
      }
    }

    setSubCategory(subCategory);
  };

  const onSelectionLimitChange = (event: any, categoryId: string) => {
    const subCategory = findOrBuildSubCategory(categoryId);
    subCategory.selectionLimit = +event.target.value;
    setSubCategory(subCategory);
  };

  const onToggleRequired = (categoryId: string) => {
    const subCategory = findOrBuildSubCategory(categoryId);
    subCategory.required = !subCategory.required;
    setSubCategory(subCategory);
  };

  const updateProductProperties = (
    productId: string,
    categoryId: string,
    active: boolean,
    values?: any,
  ) => {
    const subCategory = findOrBuildSubCategory(categoryId);

    // Remove the product and return early
    if (values.removeSelectedProduct) {
      delete subCategory.subProductMap[productId];

      // If there are no more products, remove the subCategory
      if (Object.keys(subCategory.subProductMap).length === 0) {
        return deleteSubCategory(subCategory.categoryId);
      }

      return setSubCategory(subCategory);
    }
    const subProduct = subCategory.subProductMap[productId] || {
      autoSelect: false,
      active: active,
      price: 0,
      productId,
      quantityLimit: 1
    };

    if (!subCategory.multiselect && values.autoSelect) {
      // Set all other products in the category to autoSelect = false
      for (const key of Object.keys(subCategory.subProductMap)) {
        subCategory.subProductMap[key].autoSelect = false;
      }
    }

    subCategory.subProductMap[productId] = Object.assign(subProduct, values);
    setSubCategory(subCategory);
  };

  const getCategoryCheckStatus = (categoryId: string): Checked => {
    const subCategory = subCategoryMap[categoryId];
    if (!subCategory) {
      return Checked.UNCHECKED;
    }

    const selected = Object.keys(subCategory.subProductMap).length;
    const total = (categoryProducts[categoryId] || []).length;
    return total === selected ? Checked.CHECKED : Checked.PARTIAL;
  };

  return (
    <>
      <Typography
        className="menu__add-edit-form combo__selection-text"
        align="left"
        variant="h6"
      >
        Product Add-ons
      </Typography>
      {categories.map((category: ICategory, index: number) => {
        const subCategory = subCategoryMap[category.id];
        const products = categoryProducts[category.id];

        if (isEmpty(products)) {
          return null;
        }

        return (
          <ExpansionPanel
            key={index}
            expanded={activeCategoryId === category.id}
            onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
              setActiveCategoryId(expanded ? category.id : undefined)
            }
          >
            <ExpansionPanelSummary
              classes={{
                content: 'combo__category-panel__header',
                expandIcon: 'combo__category-panel__header-icon',
              }}
              expandIcon={<ExpandLessIcon />}
            >
              <Checkbox
                checked={
                  getCategoryCheckStatus(category.id) === Checked.CHECKED
                }
                classes={{
                  indeterminate: 'primary',
                }}
                color="primary"
                indeterminate={
                  getCategoryCheckStatus(category.id) === Checked.PARTIAL
                }
                onChange={() => onToggleAllCategoryProducts(category.id)}
              />
              {`All ${category.name}`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={{
                root: 'menu__add-edit-form combo__category-panel__body',
              }}
            >
              <InputLabel className="menu__add-edit-form combo__category-panel__body-options">
                Selection Required
                <Switch
                  classes={{
                    track: 'custom-colored-switch-track',
                  }}
                  checked={!!(subCategory && subCategory.required)}
                  color="primary"
                  name="required"
                  onChange={() => onToggleRequired(category.id)}
                  size="medium"
                  value="required"
                />
              </InputLabel>
              <Grid container={true} alignItems="center">
                <Grid item={true}>
                  <InputLabel className="combo__category-panel__body-options">
                    Allow Multiple Selections
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={!!(subCategory && subCategory.multiselect)}
                      color="primary"
                      name="multiselect"
                      onChange={() => onToggleMultiselect(category.id)}
                      size="medium"
                      value="multiselect"
                    />
                  </InputLabel>
                </Grid>
                <Grid item={true}>
                  {subCategory && subCategory.multiselect && (
                    <Grid
                      className="combo__category-panel__body-options"
                      container={true}
                      direction="row"
                    >
                      <Typography variant="body1">
                        Limit total quantity selection to{' '}
                      </Typography>
                      <TextField
                        className="combo__category-panel__body-options__selection"
                        inputProps={{
                          min: 0,
                          step: 1,
                          type: 'number',
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          classes: {
                            input:
                              'combo__category-panel__body-options__selection-input',
                            root:
                              'combo__category-panel__body-options__selection-container',
                          },
                        }}
                        name="selectionLimit"
                        onChange={(e: any) =>
                          onSelectionLimitChange(e, category.id)
                        }
                        value={subCategory.selectionLimit.toString()}
                        variant="outlined"
                      />
                      <Typography variant="body1"> Items*</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Typography variant="caption">
                  *Entering 0 in any field will allow the customer to select an
                  unlimited quantity of that product.
                </Typography>
              </Grid>
              {subCategory &&
                subCategory.required &&
                Object.keys(subCategory.subProductMap).length <= 0 && (
                  <Typography color="error" variant="caption">
                    **Selection is required for this category.
                  </Typography>
                )}
              <ComboTable
                categoryId={category.id}
                isMultiselect={subCategory && subCategory.multiselect}
                products={products}
                selectedProducts={
                  (subCategory && subCategory.subProductMap) || {}
                }
                updateProductProperties={updateProductProperties}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </>
  );
};

export default ComboSelectItems;
