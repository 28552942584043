import { Breadcrumbs, Button, Link, Typography } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { isEmpty, last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../config/AppContext';
import {
  ICreateLocationDto,
  IUpdateVenueLocationDto,
  IVenueLocation,
} from '../../../models/Location.model';
import VenueLocationService from '../../../services/Location/VenueLocation/VenueLocation.service';
import { ISettingsProps } from '../Settings.config';
import { VenueDeliveryLocation } from './VenueDeliveryLocation';
import './VenueLocation.scss';
import VenueLocationDialog from './VenueLocationDialog';
import { VenueLocationTable } from './VenueLocationTable';

export const VenueLocation = ({ enqueueSnackbar }: ISettingsProps) => {
  const {
    fetchVenueLocations,
    venue,
    venueLocations,
    setShowSpinner,
  } = useAppContext();
  const [locationForEdit, setLocationForEdit] = useState<
    IVenueLocation | undefined
  >(undefined);
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const [locationPaths, setLocationPaths] = useState<IVenueLocation[]>([]);
  const currentLocation = last(locationPaths);
  const locations = currentLocation
    ? currentLocation.locations
    : venueLocations;

  const refreshVenueLocationState = async () => {
    const newLocationPaths = [];
    let nestedLocations: IVenueLocation[] = venueLocations;
    for (const location of locationPaths) {
      const currentVenueLocation = nestedLocations.find(
        (vl: IVenueLocation) => vl.id === location.id,
      );
      if (!currentVenueLocation) {
        break;
      }

      newLocationPaths.push(currentVenueLocation);
      nestedLocations = currentVenueLocation.locations;
    }
    setLocationPaths(newLocationPaths);
  };

  useEffect(() => {
    refreshVenueLocationState();
  }, [venueLocations]);

  const handleLocationSelection = (location: IVenueLocation) => {
    setLocationPaths(oldPaths => [...oldPaths, location]);
  };

  const handleBreadCrumbSelection = (index: number, start: boolean = false) => {
    if (start) {
      setLocationPaths([]);
      return;
    }

    if (index >= locationPaths.length - 1 || !venueLocations) {
      return;
    }

    const newPath = locationPaths.slice(0, index + 1);
    setLocationPaths(newPath);
  };

  const onEditLocation = (location: IVenueLocation) => {
    setLocationForEdit(location);
    setOpenLocationDialog(true);
  };

  const onCloseLocationDialog = () => {
    setLocationForEdit(undefined);
    setOpenLocationDialog(false);
  };

  const editLocation = async (
    locationId: string,
    editDto: IUpdateVenueLocationDto,
  ) => {
    try {
      setShowSpinner(true);
      await VenueLocationService.updateVenueLocation(locationId, editDto);
      await fetchVenueLocations();

      enqueueSnackbar('Location has been successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const createLocation = async (locationDto: ICreateLocationDto) => {
    const parentId = currentLocation && currentLocation.id;
    try {
      setShowSpinner(true);
      await VenueLocationService.createVenueLocation({
        ...locationDto,
        ...(parentId && { parentId }),
      });
      await fetchVenueLocations();

      enqueueSnackbar('Location has been successfully created', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const deleteLocation = async (location: IVenueLocation) => {
    try {
      setShowSpinner(true);
      await VenueLocationService.deleteVenueLocation(location.id);
      await fetchVenueLocations();
      enqueueSnackbar('Location has been successfully deleted', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const cloneLocation = async (location: IVenueLocation) => {
    try {
      setShowSpinner(true);
      await VenueLocationService.cloneVenueLocation(location.id);
      await fetchVenueLocations();
      enqueueSnackbar(`${location.name} has been successfully cloned`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const locationAction = async (values: IVenueLocation) => {
    if (!venue) {
      return;
    }

    const { name, categoryName, type, id, isCustomInput } = values;
    if (!isEmpty(id)) {
      await editLocation(id, {
        categoryName,
        isCustomInput,
        name,
        type,
        venueId: venue.id,
      });
    } else {
      await createLocation({
        categoryName,
        isCustomInput,
        name,
        type,
        venueId: venue.id,
      });
    }

    onCloseLocationDialog();
  };

  return (
    <div className="locations">
      <div className="breadcrumb-container">
        <Breadcrumbs>
          <Link
            onClick={() => handleBreadCrumbSelection(-1, true)}
            underline="hover"
            color="inherit"
          >
            Locations
          </Link>
          {locationPaths.map(({ id, name }, index) => (
            <Link
              key={id}
              onClick={() => handleBreadCrumbSelection(index)}
              underline="hover"
              color={
                index === locationPaths.length - 1 ? 'textPrimary' : 'inherit'
              }
            >
              {name}
            </Link>
          ))}
        </Breadcrumbs>
        <Button
          size="medium"
          color="primary"
          aria-label="add"
          classes={{
            root: 'add-location-button',
          }}
          onClick={() => setOpenLocationDialog(true)}
        >
          <div className="icon-title-wrapper">
            <AddOutlined />
            <span className="icon-title">Add Location</span>
          </div>
        </Button>
      </div>

      {currentLocation && currentLocation.categoryName && (
        <Typography variant="h6" className="category-name">
          {currentLocation.categoryName}
        </Typography>
      )}

      {currentLocation && currentLocation.isDeliveryLocation ? (
        <VenueDeliveryLocation location={currentLocation} />
      ) : (
        <VenueLocationTable
          locations={locations}
          handleLocationSelection={handleLocationSelection}
          onCloneLocation={cloneLocation}
          onDeleteLocation={deleteLocation}
          onEditLocation={onEditLocation}
        />
      )}

      {openLocationDialog && (
        <VenueLocationDialog
          location={locationForEdit}
          locationAction={locationAction}
          onClose={onCloseLocationDialog}
          parent={currentLocation}
        />
      )}
    </div>
  );
};
