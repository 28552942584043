import {
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { get } from 'lodash';
import React from 'react';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { PartnerId } from '../../../components/Partner/PartnerId';
import ICategory from '../../../models/Category.model';
import {
  categoryTableColumnNames,
  VALID_CATEGORY_TYPES,
} from './Category.config';

interface ICategoriesTableProps {
  categories: ICategory[];
  onChangeActive: (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ICategory,
  ) => void;
  onDeleteCategory: (category: ICategory) => void;
  onEditCategory: (category: ICategory) => void;
}

const CategoriesTable = (props: ICategoriesTableProps) => {
  const {
    categories,
    onChangeActive,
    onEditCategory,
    onDeleteCategory,
  } = props;

  return (
    <Table className="categories__table">
      <TableBody>
        <TableRow classes={{ root: 'categories__table-header' }}>
          {categoryTableColumnNames.map((column: any, i: number) => (
            <TableCell
              key={i}
              align="left"
              size={column.size}
              variant="head"
              classes={{ root: 'categories__table-cell' }}
            >
              <p>{column.name}</p>
            </TableCell>
          ))}
        </TableRow>
        {categories.length < 1
          ? noDataAvailable(5)
          : categories.map((category: ICategory) => (
              <TableRow key={category.id} hover={true}>
                <TableCell
                  key={`category ${category.id}_name`}
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'categories__table-cell' }}
                >
                  <p>{category.name}</p>
                  <PartnerId
                    id={category.partnerCategoryId}
                    partner={category.partner}
                  />
                </TableCell>
                <TableCell
                  key={`category ${category.id}_active`}
                  align="left"
                  size="small"
                  variant="body"
                  classes={{ root: 'categories__table-cell' }}
                >
                  {get(category, ['type']) !== VALID_CATEGORY_TYPES.OPTIONS && (
                    <Switch
                      classes={{
                        track: 'custom-colored-switch-track',
                      }}
                      checked={category.active}
                      color="primary"
                      name="active"
                      onChange={e => onChangeActive(e, category)}
                      size="medium"
                      value={category.active}
                    />
                  )}
                </TableCell>
                <TableCell
                  key={`category ${category.id}_priority`}
                  align="left"
                  size="small"
                  variant="body"
                  classes={{ root: 'categories__table-cell' }}
                >
                  <p>{category.priority}</p>
                </TableCell>
                <TableCell
                  key={`category ${category.id}_type`}
                  align="left"
                  size="small"
                  variant="body"
                  classes={{ root: 'categories__table-cell' }}
                >
                  <p>{category.type}</p>
                </TableCell>
                <TableCell
                  key={`category ${category.id}_action`}
                  align="left"
                  size="medium"
                  variant="body"
                  classes={{ root: 'categories__table-cell' }}
                >
                  <Button
                    size="small"
                    classes={{ root: 'categories__action-buttons' }}
                    onClick={() => onEditCategory(category)}
                  >
                    <div className="icon-title-wrapper">
                      <CreateOutlinedIcon />
                      <span className="icon-title">Edit</span>
                    </div>
                  </Button>
                  <Button
                    size="small"
                    classes={{ root: 'categories__action-buttons' }}
                    onClick={() => onDeleteCategory(category)}
                  >
                    <div className="icon-title-wrapper">
                      <DeleteOutlinedIcon />
                      <span className="icon-title">Delete</span>
                    </div>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

export default CategoriesTable;
