import {
  Button,
  Icon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { noDataAvailable } from '../../../../components/NoDataAvailable';
import { getTimezoneDate } from '../../../../services/Util/Util.service';
import { columns } from '../Notificationconfig';
import '../Notifications.scss';

const NotificationTable = ({
  notifications,
  editNotification,
  deleteNotification,
  onChangeActive,
  searchNotification,
  sendNotificationNow,
  venueTimezone,
}: any) => {
  const [nameSearchTerm, setNameSearchTerm] = useState('');
  const [titleSearchTerm, setTitleSearchTerm] = useState('');
  const [contentSearchTerm, setContentSearchTerm] = useState('');

  return (
    <div className="notifications-table-container">
      <Table className="notifications-table">
        <TableHead classes={{ root: 'notifications-table-header' }}>
          <TableRow>
            {columns.map((column: any) => (
              <TableCell
                key={column.name}
                align="center"
                variant="head"
                classes={{ root: 'notifications-table-header__cells' }}
              >
                <p>{column.name}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              key={0}
              align="left"
              classes={{ root: 'notifications-table-body__cells' }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                onChange={event => {
                  const {
                    target: { value },
                  } = event;
                  setNameSearchTerm(value);
                }}
                value={nameSearchTerm}
              />
            </TableCell>
            <TableCell
              key={1}
              align="left"
              classes={{ root: 'notifications-table-body__cells' }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                onChange={event => {
                  const {
                    target: { value },
                  } = event;
                  setTitleSearchTerm(value);
                }}
                value={titleSearchTerm}
              />
            </TableCell>
            <TableCell
              key={2}
              align="left"
              classes={{ root: 'notifications-table-body__cells' }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                onChange={event => {
                  const {
                    target: { value },
                  } = event;
                  setContentSearchTerm(value);
                }}
                value={contentSearchTerm}
              />
            </TableCell>
            <TableCell colSpan={columns.length - 4} />
            <TableCell
              align="center"
              classes={{ root: 'notifications-table-body__cells' }}
            >
              <Button
                onClick={() =>
                  searchNotification(
                    nameSearchTerm,
                    titleSearchTerm,
                    contentSearchTerm,
                  )
                }
                classes={{ root: 'search-button' }}
              >
                Search
              </Button>
            </TableCell>
          </TableRow>
          {notifications.length > 0
            ? notifications.map((notification: any) => (
                <TableRow
                  key={notification.id}
                  className="notifications-table-row"
                >
                  <TableCell
                    classes={{ root: 'notifications-table-body__cells' }}
                  >
                    {notification.name}
                  </TableCell>
                  <TableCell
                    classes={{ root: 'notifications-table-body__cells' }}
                  >
                    {notification.title}
                  </TableCell>
                  <TableCell
                    classes={{ root: 'notifications-table-body__cells' }}
                  >
                    {notification.content}
                  </TableCell>
                  <TableCell
                    classes={{ root: 'notifications-table-body__cells' }}
                  >
                    {notification.sentTime
                      ? format(
                          getTimezoneDate(
                            new Date(notification.sentTime),
                            venueTimezone,
                          ),
                          'MM-dd-yyyy hh:mm a',
                        )
                      : ''}
                  </TableCell>
                  <TableCell
                    classes={{ root: 'notifications-table-body__cells' }}
                  >
                    {!isEmpty(notification.sentTime) ? (
                      <span className="sent-notification">Sent</span>
                    ) : (
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        checked={notification.active}
                        color="primary"
                        name="active"
                        onChange={e => onChangeActive(e, notification)}
                        size="medium"
                        value={notification.active}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    classes={{ root: 'notifications-table-body__cells' }}
                  >
                    {isEmpty(notification.sentTime) && (
                      <Button
                        size="small"
                        classes={{
                          disabled: 'notification-action-button__disabled',
                          root: 'notification-action-button',
                        }}
                        disabled={!isEmpty(notification.sentTime)}
                        onClick={() => {
                          editNotification(notification);
                        }}
                        value="edit"
                      >
                        <Icon>edit</Icon> edit
                      </Button>
                    )}
                    {notification.active && isEmpty(notification.sentTime) && (
                      <Button
                        size="small"
                        classes={{ root: 'notification-action-button' }}
                        onClick={() => {
                          sendNotificationNow(notification.id);
                        }}
                        value="send"
                      >
                        <Icon>send</Icon> Send
                      </Button>
                    )}
                    <Button
                      size="small"
                      classes={{ root: 'notification-action-button' }}
                      onClick={() => {
                        deleteNotification(notification);
                      }}
                      value="delete"
                    >
                      <Icon>delete</Icon> delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : noDataAvailable(8)}
        </TableBody>
      </Table>
    </div>
  );
};

export default NotificationTable;
