import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { ISecondaryTab } from '../../config/Common';
import './Marketing.scss';
import Notifications from './Notifications/Notifications';
import Promos from './Promos/Promos';
import SponsorModules from './Sponsors/SponsorModules';

const secondaryTabs: ISecondaryTab[] = [
  {
    component: SponsorModules,
    name: 'Sponsorship',
    route: 'sponsors',
  },
  {
    component: Notifications,
    name: 'Notifications',
    route: 'notifications',
  },
  {
    component: Promos,
    name: 'Promo Code',
    route: 'promo-code',
  },
];

const Marketing = (props: any) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const {
    location: { pathname },
    venueId,
  } = props;

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newTabValue: number,
  ) => {
    setActiveTabIndex(newTabValue);
  };

  useEffect(() => {
    if (pathname.indexOf('/marketing/') === -1) {
      props.history.push(`${props.match.url}/sponsors`);
      setActiveTabIndex(0);
    } else if (pathname.indexOf('/notifications') !== -1) {
      setActiveTabIndex(1);
    } else if (pathname.indexOf('/promo-code') !== -1) {
      setActiveTabIndex(2);
    }
  }, [pathname]);

  return (
    <div className="marketing-container">
      <div className="marketing-header-container">
        <Typography className="section-title">Marketing</Typography>
      </div>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        textColor="primary"
        classes={{
          flexContainer: 'secondary-tabs-buttons-container',
          indicator: 'indicator',
          root: 'secondary-tabs-container',
        }}
      >
        {secondaryTabs.map((secondaryTab: ISecondaryTab, tabIndex: number) => (
          <Tab
            component={Link}
            to={`${props.match.url}/${secondaryTab.route}`}
            key={tabIndex}
            label={secondaryTab.name}
            classes={{
              root: 'secondary-tab',
              selected: 'selectedTab',
              wrapper: 'orders-icon',
            }}
          />
        ))}
      </Tabs>
      {secondaryTabs.map((secondaryTab: ISecondaryTab, tabIndex: number) => (
        <Route
          render={childProps => (
            <secondaryTab.component {...childProps} {...{ venueId }} />
          )}
          path={`${props.match.path}/${secondaryTab.route}`}
          key={tabIndex}
        />
      ))}
    </div>
  );
};

export default Marketing;
