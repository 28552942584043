import React from 'react';
import BasicReport, {
  IHasCurrency,
  IReportColumn,
  ReportColumnType,
} from './BasicReport';

interface IBuildingRevenueSummaryProps {
  data: IBuildingRevenueSummaryDataRecord[];
  isAdmin: boolean;
}

export interface IBuildingRevenueSummaryDataRecord extends IHasCurrency {
  buildingName: string;
  date: string;
  discounts: number;
  orders: number;
  owedToStore: number;
  processingFees: number;
  productSales: number;
  sales: number;
  serviceCharge: number;
  serviceProviderName: string;
  storeRevenue: number;
  taxes: number;
  tips: number;
}

const exportName = 'building-revenue-summary';
const columns: Array<IReportColumn<IBuildingRevenueSummaryDataRecord>> = [
  {
    accessor: record => record.date,
    name: 'Event Date',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.buildingName,
    name: 'Building',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.serviceProviderName,
    name: 'Service Provider',
    type: ReportColumnType.string,
  },
  {
    accessor: record => record.orders,
    name: 'Total Orders Sold',
    total: true,
    type: ReportColumnType.integer,
  },
  {
    accessor: record => record.sales,
    name: 'Total Sales',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.productSales,
    name: 'Total Product Sales',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.storeRevenue,
    name: 'Store Revenue Share',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.serviceCharge,
    name: 'Total Service Charge Share',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.tips,
    name: 'Tips',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.discounts,
    name: 'Discounts',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.taxes,
    name: 'Total Taxes',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.processingFees,
    name: 'Total Credit Card Fees',
    total: true,
    type: ReportColumnType.currency,
  },
  {
    accessor: record => record.owedToStore,
    name: 'Total Owed to Store',
    total: true,
    type: ReportColumnType.currency,
  },
];

const BuildingRevenueSummary = (
  props: IBuildingRevenueSummaryProps,
): JSX.Element => <BasicReport {...{ columns, exportName }} {...props} />;

export default BuildingRevenueSummary;
