import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { ErrorMessage, Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import * as Yup from 'yup';
import { useAppContext } from '../../../config/AppContext';
import { IVenueLocation } from '../../../models/Location.model';
import { UserVenueRole } from '../../../models/User.model';
import { IUserAdmin } from '../../../models/UserAdmin.model';
import EventService from '../../../services/Event/Event.service';
import { unMaskPhoneNumber } from '../../../services/Util/Util.service';
import VenueService from '../../../services/Venue/Venue.service';
import { humanReadableRoles } from '../UserAdminConfig';

import './UserAdminDialog.scss';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip';

interface IFormInputConfig {
  fieldName: string;
  label: string;
  placeholder: string;
}

const formInputConfig: IFormInputConfig[] = [
  {
    fieldName: 'firstName',
    label: 'First Name',
    placeholder: 'Enter First Name',
  },
  {
    fieldName: 'lastName',
    label: 'Last Name',
    placeholder: 'Enter Last Name',
  },
  {
    fieldName: 'email',
    label: 'Email',
    placeholder: 'Enter email',
  },
];
interface IAssignable {
  id: string;
  name: string;
}


interface IUserAdminDialogProps {
  close: () => void;
  userAdmin: IUserAdmin;
  userAdminAction: (usrAdmin: IUserAdmin) => Promise<void>;
  venueId: string;
  otherPins: string[];
}

const UserAdminDialog = (props: IUserAdminDialogProps) => {
  const { close, userAdmin, userAdminAction, venueId } = props;
  const { stores } = useAppContext();
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const [events, setEvents] = useState<IAssignable[]>([]);
  const [suites, setSuites] = useState<IAssignable[]>([]);
  const [assignableLocations, setAssignableLocations] = useState<IAssignable[]>(
    [],
  );
  const [assignedEvents, setAssignedEvents] = useState(
    userAdmin.eventIds || [],
  );
  const [assignedStoreIds, setAssignedStoreIds] = useState(
    userAdmin.storeIds || [],
  );
  const [assignedSuites, setAssignedSuites] = useState(
    userAdmin.suiteIds || [],
  );

  const CreateUserAdminValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    firstName: Yup.string()
      .required('Required')
      .max(30),
    lastName: Yup.string()
      .required('Required')
      .max(30),
    phoneNumber: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    pin: Yup.string().matches(/^\d+$/, 'Must only be digits')
      .length(4)
      .test('is-unique', 'PIN must be unique from other PINS for venue', (pin: string) => !props.otherPins.includes(pin))
  });

  const EditUserAdminValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    pin: Yup.string().matches(/^\d+$/, 'Must only be digits')
      .length(4)
      .test('is-unique', 'PIN must be unique from other PINS for venue', (pin: string) => !props.otherPins.includes(pin))
  });

  const fetchEvents = async (): Promise<IAssignable[]> => {
    if (!venueId) {
      return [];
    }

    const {
      data: { items },
    } = await EventService.getAllEvents(500, 1, venueId, true);
    const newEvents = items.map((event: any) => ({
      id: event.id,
      name: `${event.name} (${new Date(event.startDate).toLocaleString()})`,
    }));
    setEvents(newEvents);
    return newEvents;
  };

  const fetchSuites = async (): Promise<IAssignable[]> => {
    if (!venueId) {
      return [];
    }

    const { data } = await VenueService.getVenueSuites(venueId);
    const newSuites = data.map((suite: IVenueLocation) => ({
      id: suite.id,
      name: suite.name,
    }));
    setSuites(newSuites);
    return newSuites;
  };

  const getAssignableEvents = async (
    newRole: UserVenueRole,
  ): Promise<IAssignable[]> => {
    switch (newRole) {
      case UserVenueRole.SUITE_CUSTOMER:
        if (events.length <= 0) {
          const newEvents = await fetchEvents();
          return newEvents;
        }
        return events;
      default:
        return [];
    }
  };

  const getAssignableLocations = async (
    newRole: UserVenueRole,
  ): Promise<IAssignable[]> => {
    switch (newRole) {
      case UserVenueRole.STORE_EMPLOYEE:
      case UserVenueRole.STORE_MANAGER:
        return stores;
      case UserVenueRole.SUITE_ATTENDANT:
      case UserVenueRole.SUITE_CUSTOMER:
        if (suites.length <= 0) {
          const newSuites = await fetchSuites();
          return newSuites;
        }
        return suites;
      default:
        return [];
    }
  };

  useEffect(() => {
    (async () => {
      setAssignableLocations(await getAssignableLocations(userAdmin.role));
      setEvents(await getAssignableEvents(userAdmin.role));
      setAssignedEvents(userAdmin.eventIds || []);
      setAssignedStoreIds(userAdmin.storeIds || []);
      setAssignedSuites(userAdmin.suiteIds || []);
    })();
  }, [userAdmin]);

  const onChangeRoleSelection = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRole = get(e, ['target', 'value'], '') as UserVenueRole;

    setAssignableLocations(await getAssignableLocations(newRole));
    setEvents(await getAssignableEvents(newRole));

    if (userAdmin.role !== newRole) {
      setAssignedStoreIds([]);
      setAssignedSuites([]);
    } else {
      setAssignedStoreIds(userAdmin.storeIds || []);
      setAssignedSuites(userAdmin.suiteIds || []);
    }
  };

  const handleListOfLocations = (
    id: any,
    role: any,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const getSelectedList = (): string[] => {
      switch (role) {
        case UserVenueRole.SUITE_CUSTOMER:
        case UserVenueRole.SUITE_ATTENDANT:
          return assignedSuites;
        default:
          return assignedStoreIds;
      }
    };

    const getSetSelectedList = (): React.Dispatch<React.SetStateAction<
      string[]
    >> => {
      switch (role) {
        case UserVenueRole.SUITE_CUSTOMER:
        case UserVenueRole.SUITE_ATTENDANT:
          return setAssignedSuites;
        default:
          return setAssignedStoreIds;
      }
    };

    const selectedList = getSelectedList();
    const setSelectedList = getSetSelectedList();

    const isPresent = selectedList.includes(id);
    if (isPresent) {
      const filteredArr = selectedList.filter((selectedId: string) => {
        return id !== selectedId;
      });
      setSelectedList(filteredArr);
    } else {
      setSelectedList([...selectedList, id]);
    }
  };

  const storeOrSuiteAssociationError = (role: UserVenueRole) => {
    switch (role) {
      case UserVenueRole.STORE_EMPLOYEE:
      case UserVenueRole.STORE_MANAGER:
        return (
          <div className="error-message">Please assign at least one store.</div>
        );
      case UserVenueRole.SUITE_ATTENDANT:
      case UserVenueRole.SUITE_CUSTOMER:
        return (
          <div className="error-message">Please assign at least one suite.</div>
        );
    }
  };

  const storeOrSuiteText = (role: UserVenueRole): string => {
    switch (role) {
      case UserVenueRole.STORE_EMPLOYEE:
      case UserVenueRole.STORE_MANAGER:
        return 'Store';
      case UserVenueRole.SUITE_ATTENDANT:
      case UserVenueRole.SUITE_CUSTOMER:
        return 'Suite';
      default:
        return '';
    }
  };

  const handleChangeSelectedEvents = (id: string) => {
    if (assignedEvents.includes(id)) {
      setAssignedEvents([
        ...assignedEvents.filter((eventId: string) => eventId !== id),
      ]);
    } else {
      setAssignedEvents([...assignedEvents, id]);
    }
  };

  const shouldDisableSubmitFormButton = (
    userAdminParams: IUserAdmin,
  ): boolean => {
    switch (userAdminParams.role) {
      case UserVenueRole.STORE_EMPLOYEE:
      case UserVenueRole.STORE_MANAGER:
        return assignedStoreIds.length <= 0;
      case UserVenueRole.SUITE_ATTENDANT:
      case UserVenueRole.SUITE_CUSTOMER:
        return assignedSuites.length <= 0;
      default:
        return false;
    }
  };

  const getValidationSchema = (isEdit: boolean): Yup.ObjectSchema => {
    return isEdit
      ? EditUserAdminValidationSchema
      : CreateUserAdminValidationSchema;
  };

  return (
    <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span className="section-title">
          {userAdmin.id ? 'Edit User' : 'Add User'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="user-form">
          <Formik
            initialValues={userAdmin}
            onSubmit={userAdminParams => {
              const updateAction = {
                ...userAdminParams,
                eventIds: assignedEvents,
                phoneNumber: unMaskPhoneNumber(
                  formatPhoneNumber(userAdminParams.phoneNumber),
                ),
                storeIds: assignedStoreIds,
                suiteIds: assignedSuites,
              };
              userAdminAction(updateAction);
            }}
            validationSchema={getValidationSchema(!!userAdmin.id)}
          >
            {({
              values: userAdminParams,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-container">
                  <InputLabel
                    className="user-form__field phoneInput"
                    error={
                      get(touched, 'phoneNumber') &&
                      !!get(errors, 'phoneNumber')
                    }
                    required={true}
                    shrink={true}
                  >
                    Phone Number
                  </InputLabel>
                  <PhoneInput
                    defaultCountry={phoneNumberUtil.getRegionCodeForCountryCode(
                      Number(userAdminParams.countryCode),
                    )}
                    placeholder="999-999-9999"
                    name="phoneNumber"
                    disabled={!!userAdmin.id}
                    value={userAdminParams.phoneNumber || ''}
                    className={`user-form__phone-container ${get(touched, 'phoneNumber') &&
                      !!get(errors, 'phoneNumber')
                      ? 'error'
                      : ''
                      }`}
                    numberInputProps={{
                      className: 'user-form__phone-input',
                    }}
                    onChange={e => {
                      setFieldValue('phoneNumber', e);
                    }}
                    onCountryChange={(code: string = '') => {
                      if (!isEmpty(code)) {
                        setFieldValue(
                          'countryCode',
                          getCountryCallingCode(code),
                        );
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="phoneNumber"
                  />
                </div>
                {formInputConfig.map((input: IFormInputConfig, index: any) => (
                  <div key={index} className="form-container">
                    <TextField
                      required={true}
                      error={
                        get(touched, input.fieldName) &&
                        !!get(errors, input.fieldName)
                      }
                      className="user-form__field"
                      disabled={!!userAdmin.id}
                      name={input.fieldName}
                      placeholder={input.placeholder}
                      inputProps={{
                        type: 'string',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={input.label}
                      value={get(userAdminParams, input.fieldName) || ''}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name={input.fieldName}
                    />
                  </div>
                ))}
                <FormControl required={true} className="user-form__field">
                  <InputLabel htmlFor="age-native-required">Role</InputLabel>
                  <Select
                    value={userAdminParams.role}
                    onChange={(e: any) => {
                      handleChange(e);
                      onChangeRoleSelection(e);
                    }}
                    onBlur={handleBlur}
                    name="role"
                    displayEmpty={true}
                    className="user-form__field"
                    inputProps={{
                      id: 'age-native-required',
                    }}
                  >
                    {/* TODO: Fix this to include human readable roles */}
                    {Object.keys(humanReadableRoles).map((role: string) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="form-container">
                  <TextField
                    type="text"
                    className="pin"
                    value={userAdminParams.pin}
                    onChange={handleChange}
                    placeholder="PIN"
                    name="pin"
                  />
                  <Typography className="info required-text" style={{ color: '#ee3653' }}>
                    *set a 4-digit pin for this user if you are using Fanfood POS
                  </Typography>
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="pin"
                  />
                </div>
                {[
                  UserVenueRole.STORE_EMPLOYEE,
                  UserVenueRole.STORE_MANAGER,
                  UserVenueRole.SUITE_ATTENDANT,
                  UserVenueRole.SUITE_CUSTOMER,
                ].includes(userAdminParams.role) && (
                    <div className="list-store-container">
                      <InputLabel
                        className="store-label"
                        htmlFor="age-native-required"
                      >
                        {`List ${storeOrSuiteText(userAdminParams.role)} :`}
                      </InputLabel>
                      <FormControl
                        component={'fieldset'}
                        required={true}
                        className="user-form__fieldset"
                      >
                        <FormGroup>
                          {assignableLocations.length ? (
                            assignableLocations.map(
                              ({ id, name }: IAssignable) => (
                                <FormControlLabel
                                  key={id}
                                  control={
                                    <Checkbox
                                      onChange={e => {
                                        handleListOfLocations(
                                          id,
                                          userAdminParams.role,
                                          e,
                                        );
                                      }}
                                      value={id}
                                      checked={[
                                        ...assignedStoreIds,
                                        ...assignedSuites,
                                      ].includes(id)}
                                    />
                                  }
                                  label={name}
                                />
                              ),
                            )
                          ) : (
                            <div className="user__stores-suites__selection-list">
                              {`Please create a ${storeOrSuiteText(
                                userAdminParams.role,
                              )}`}
                            </div>
                          )}
                        </FormGroup>
                      </FormControl>
                    </div>
                  )}
                {assignedStoreIds.length <= 0 &&
                  assignedSuites.length <= 0 &&
                  storeOrSuiteAssociationError(userAdminParams.role)}

                {userAdminParams.role === UserVenueRole.SUITE_CUSTOMER && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userAdminParams.allEvents}
                          name="allEvents"
                          onChange={handleChange}
                          value={userAdminParams.allEvents}
                        />
                      }
                      label="User can access all events"
                    />
                    {!userAdminParams.allEvents && (
                      <div className="list-store-container">
                        <InputLabel
                          className="store-label"
                          htmlFor="age-native-required"
                        >
                          Events:
                        </InputLabel>
                        <FormControl
                          component={'fieldset'}
                          className="user-form__fieldset"
                        >
                          <FormGroup>
                            {events.length ? (
                              events.map(({ id, name }: IAssignable) => (
                                <FormControlLabel
                                  key={id}
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleChangeSelectedEvents(id)
                                      }
                                      value={id}
                                      checked={assignedEvents.includes(id)}
                                    />
                                  }
                                  label={name}
                                />
                              ))
                            ) : (
                              <div className="user__stores-suites__selection-list">
                                There are no future events to assign
                              </div>
                            )}
                          </FormGroup>
                        </FormControl>
                      </div>
                    )}
                  </>
                )}

                <div className="button-holder-different-margin">
                  <DialogActions>
                    <Button onClick={close} color="primary" variant="outlined">
                      <div className="icon-title-wrapper">
                        <UndoOutlinedIcon />
                        <span className="icon-title">Cancel</span>
                      </div>
                    </Button>
                    <Button
                      disabled={shouldDisableSubmitFormButton(userAdminParams)}
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      <div className="icon-title-wrapper">
                        <CheckOutlinedIcon />
                        <span className="icon-title">
                          {userAdmin.id ? 'Update' : 'Add'}
                        </span>
                      </div>
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserAdminDialog;
