import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  AddOutlined as AddIcon,
  Clear as ClearIcon,
  Edit as EditIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { noDataAvailable } from '../../../../components/NoDataAvailable';
import { useAppContext } from '../../../../config/AppContext';
import { useStoreContext } from '../../../../config/StoreContext';
import { IPrinter } from '../../../../models/Printer.model';
import PrinterService from '../../../../services/Printer/Printer.service';
import {
  HeaderButton,
  HeaderButtonType,
} from '../../../../theme/components/buttons/HeaderButton';
import MovePrinterDialog from '../MovePrinterDialog';
import PrinterDialog from '../PrinterDialog';
import { getPrinterServerURL } from '../Store.config';

const printerInitialState = {
  copyCount: 1,
  id: '',
  name: '',
  status: '',
  printAuto : false
};

const printerTableColumnNames = ['Name', 'Status', 'Copy Count', 'Actions'];

export interface IAddEditPrinterParams {
  copyCount: number;
  id?: string;
  name: string;
  printAuto: boolean;
  printerTerminals: string[];
  printerLocation: string;
}

export const StorePrinters = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setShowSpinner, stores } = useAppContext();
  const { fetchPrinters, printers, store } = useStoreContext();
  const [selectedPrinterId, setSelectedPrinterId] = useState<string>()
  const [printerForEdit, setPrinterForEdit] = useState<
    Partial<IPrinter> | undefined
  >();

  const [printerForMove, setPrinterForMove] = useState<boolean>(false);

  const clearPrintQueue = async (printer: IPrinter) => {
    try {
      await PrinterService.clearPrintQueue(printer.id);
      enqueueSnackbar('Print queue cleared', { variant: 'success' });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e.response);
      enqueueSnackbar('Failed to clear print queue', { variant: 'error' });
    }
  };

  const onAddOrEditPrinter = async ({
    copyCount,
    id,
    name,
    printerTerminals,
    printerLocation,
    printAuto
  }: IAddEditPrinterParams) => {
    if (!store) {
      return null;
    }
    setShowSpinner(true);
    try {
      if (id) {
        let mobileOrders =false;
        let allOrders =false;
        if(printerLocation ==='All Orders'){
          allOrders = true;
        }
        else if(printerLocation === 'Mobile Orders'){
          mobileOrders = true;
        }
        await PrinterService.updatePrinter(id, {
          copyCount,
          name,
          allOrders,
          mobileOrders,
          terminals : printerTerminals,
          printAuto
        });
      } else {
        let mobileOrders =false;
        let allOrders =false;
        if(printerLocation ==='All Orders'){
          allOrders = true;
        }
        else if(printerLocation === 'Mobile Orders'){
          mobileOrders = true;
        }
        await PrinterService.addPrinter({
          copyCount,
          name,
          storeId: store.id,
          allOrders,
          mobileOrders,
          terminals : printerTerminals ,
          printAuto
        });
      }

      setPrinterForEdit(undefined);
      await fetchPrinters();
      enqueueSnackbar('Printer saved successfully', {
        variant: 'success',
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const onSelectPrinter = async (storeId: string) => {
    if (selectedPrinterId) {
      try {
        await PrinterService.movePrinter(selectedPrinterId, {storeId})
        setPrinterForMove(false)
        await fetchPrinters();
        enqueueSnackbar('Print moved successfully', { variant: 'success' });
      }
      catch(err) {
        enqueueSnackbar('Could not move, please ask admin', { variant: 'error' });
      }
    }
  }

  const copyToClipboard = (printer: IPrinter) => {
    const serverUrl = getPrinterServerURL(printer.id);
    navigator.clipboard.writeText(serverUrl);
    enqueueSnackbar('Printer Server URL Copied to Clipboard', {
      variant: 'success',
    });
  };

  const getAllPrinters = (printer: IPrinter) => {
    setSelectedPrinterId(printer.id)
    setPrinterForMove(true)
  };

  return (
    <div className="store-printers">
      <Grid
        container={true}
        alignItems="flex-end"
        justify="flex-end"
        direction="row"
        className="grid"
      >
        <Grid item={true}>
          <HeaderButton
            icon={AddIcon}
            onClick={() => setPrinterForEdit(printerInitialState)}
            text="Add Printer"
            type={HeaderButtonType.PRIMARY}
          />
        </Grid>
      </Grid>
      <Table className="generic-table">
        <TableBody>
          <TableRow classes={{ root: 'table-header' }}>
            {printerTableColumnNames.map((column: string) => (
              <TableCell
                key={column}
                align="left"
                size="small"
                variant="head"
                classes={{ root: 'table-cell' }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
          {printers.length < 1
            ? noDataAvailable(4, 'This Store has no Printers')
            : printers.map((printer: IPrinter) => (
                <TableRow key={printer.id} id={printer.id} hover={true}>
                  <TableCell
                    align="left"
                    size="medium"
                    variant="body"
                    classes={{ root: 'table-cell' }}
                  >
                    {printer.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'table-cell' }}
                  >
                    {printer.status || 'N/A'}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    variant="body"
                    classes={{ root: 'table-cell' }}
                  >
                    {printer.copyCount}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="medium"
                    variant="body"
                    classes={{ root: 'table-cell' }}
                  >
                    <Button
                      size="small"
                      classes={{ root: 'action-buttons' }}
                      onClick={() => setPrinterForEdit(printer)}
                    >
                      <div className="icon-title-wrapper">
                        <EditIcon />
                        <span className="icon-title">Edit</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      classes={{ root: 'action-buttons' }}
                      onClick={() => copyToClipboard(printer)}
                    >
                      <div className="icon-title-wrapper">
                        <LinkIcon />
                        <span className="icon-title">Copy URL</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      classes={{ root: 'action-buttons' }}
                      onClick={() => clearPrintQueue(printer)}
                    >
                      <div className="icon-title-wrapper">
                        <ClearIcon />
                        <span className="icon-title">Clear Print Queue</span>
                      </div>
                    </Button>
                    <Button
                      size="small"
                      classes={{ root: 'action-buttons' }}
                      onClick={() => getAllPrinters(printer)}
                    >
                      <div className="icon-title-wrapper">
                        <span className="icon-title">Move Printer</span>
                      </div>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {printerForEdit && (
        <PrinterDialog
          onClose={() => setPrinterForEdit(undefined)}
          onAddOrEditPrinter={onAddOrEditPrinter}
          printer={printerForEdit}
        />
      )}
      {printerForMove && (
        <MovePrinterDialog
          onClose={() => setPrinterForMove(false)}
          onAddOrEditPrinter={onSelectPrinter}
          stores={stores}
        />
      )}
    </div>
  );
};