import IBaseModel from './Base.model';
import { Partner } from './Partner.model';
import { IVenue } from './Venue.model';

export interface IStore extends IBaseModel {
  active: boolean;
  acceptsOpenTabOrders: boolean;
  averageDeliveryTimeSeconds: number;
  averagePrepTimeSeconds: number;
  bankAccountLast4?: string;
  bankAccountName?: string;
  bypassOrderFulfillment: boolean;
  calculateTaxes?: boolean;
  cartLevelNotesAvailable: boolean;
  deliveryAvailable: boolean;
  deliveryFee?: number;
  deliveryFeeSharePercent?: number;
  deliveryFeeUnit?: FeeUnit | null;
  deliveryRevenueSharePercent?: number;
  description?: string;
  enableDeliveryTips?: boolean;
  enablePickupTips?: boolean;
  gatewayConnectAccountId?: string;
  imageUrl?: string;
  isDeleted: boolean;
  latitude?: number;
  liquorTaxPercent?: number;
  location?: string;
  longitude?: number;
  maxOrdersPerFifteenMinutes: number;
  name: string;
  oldStoreId?: number;
  orderAheadAvailable: boolean;
  orderInstructionsAvailable: boolean;
  orderMinimum: number;
  orderNowAvailable: boolean;
  partner: Partner;
  partnerStoreId: string;
  pickupAvailable: boolean;
  pickupFee?: number;
  pickupFeeSharePercent?: number;
  pickupFeeUnit?: FeeUnit;
  pickupRevenueSharePercent?: number;
  printerIds: string[];
  priority: number;
  productLevelNotesAvailable: boolean;
  salesTaxPercent?: number;
  showProductImages: boolean;
  type: ValidStoreType;
  validLocationTypes?: any[]; // needs StoreLocationType[]
  storeVenueLocations?: string[];
  venue?: IVenue;
  venueId: string;
  visible: boolean;
  allowOpenTabs: boolean;
  customDiscount: boolean;
  image_file: string | null; // remove this once we find a better way to handle the image upload
  scheduleHour: ScheduleHour[];
}

export interface ScheduleHour {
  day: string;
  timeInterval: string;
  close: boolean;
  override: boolean;
}

export enum FeeUnit {
  AMOUNT = 'Amount',
  PERCENT = 'Percent',
}

// TODO: https://fanfood.atlassian.net/browse/MPA-281
// Using FULFILLMENT_CENTER and SUITE KITCHEN values differently to try to over- (or under-) engineer
// how display formatting work. Need to be more consistent
export enum ValidStoreType {
  FULFILLMENT_CENTER = 'FULFILLMENT_CENTER',
  REGULAR = 'REGULAR',
  SUITE_KITCHEN = 'SUITE_KITCHEN',
}

export const validStoreTypeName: { [key in ValidStoreType]: string } = {
  [ValidStoreType.SUITE_KITCHEN]: 'Suite Kitchen',
  [ValidStoreType.REGULAR]: 'Store',
  [ValidStoreType.FULFILLMENT_CENTER]: 'Fulfillment Center',
};

export interface IFulfillmentCenter {
  id: string;
  name: string;
}
