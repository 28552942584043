import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import React, { Component } from 'react';
import AddImage from '../../AddImage/AddImage';

interface IProps {
  onAddCustomImage: (obj: any) => void;
  closeDialog: any;
}
class AddCustomImage extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      disabledButton: true,
      image: '',
      imageUrl: '',
    };
  }

  public checkFileStatus = () => {
    const { image } = this.state;
    return image.length === 0 ? false : true;
  };

  public setImageFile = (file: any) => {
    if (file.name) {
      this.setState({ image: file });
    } else {
      this.setState({ image: '' });
    }
  };

  public render() {
    const { closeDialog } = this.props;

    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
        onClose={closeDialog}
        classes={{
          paper: 'global-products-dialog',
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <span className="section-title">New Custom Image</span>
        </DialogTitle>
        <DialogContent classes={{ root: 'global-product-dialog-content' }}>
          <AddImage
            width={200}
            height={160}
            showName={false}
            previewBottom={false}
            getFile={this.setImageFile}
            getImageURL={async (url: any) => {
              this.setState({
                imageUrl: url,
              });
            }}
            showImageDetails={true}
            minHeight={400}
            minWidth={500}
          />
        </DialogContent>
        <div className="button-holder-similar-margin">
          <DialogActions>
            <Button onClick={closeDialog} color="primary" variant="outlined">
              <div className="icon-title-wrapper">
                <UndoOutlinedIcon />
                <span className="icon-title">Cancel</span>
              </div>
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!this.checkFileStatus()}
              onClick={() =>
                this.props.onAddCustomImage({
                  image: this.state.image,
                  imageUrl: this.state.imageUrl,
                })
              }
            >
              <div className="icon-title-wrapper">
                <CheckOutlinedIcon />
                <span className="icon-title">Add Custom Image</span>
              </div>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default AddCustomImage;
