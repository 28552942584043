import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import ICategory from '../models/Category.model';
import IProduct, { ISubCategory, ISubProduct } from '../models/Product.model';
import { IStore } from '../models/Store.model';
import CategoryService from '../services/Category/Category.service';
import ProductService from '../services/Product/Product.service';
import StoreService from '../services/Store/Store.service';

interface IMappedIds {
  [oldId: string]: string;
}

export interface ICloneStoreHook {
  cloneStore: (store: IStore) => Promise<IStore>;
  clonedPercent: number;
  storeToClone: IStore | undefined;
}

export const useCloneStore = (): ICloneStoreHook => {
  const [clonedPercent, setClonedPercent] = useState(0);
  const [storeToClone, setStoreToClone] = useState<IStore | undefined>(
    undefined,
  );
  const { enqueueSnackbar } = useSnackbar();

  const cloneStore = async (store: IStore): Promise<IStore> => {
    setStoreToClone(store);
    setClonedPercent(0);

    const { id, name, description, location, priority, type, venueId , customDiscount} = store;
    const { data: newStore } = await StoreService.createStore({
      description,
      location,
      name: 'Cloned_' + name,
      priority,
      type,
      venueId,
      customDiscount
    });
    const clonedStoreId = newStore.id;

    setClonedPercent(20);

    const {
      data: { items: categoriesData },
    } = await CategoryService.getAllCategories(id, 5000);

    const categoryMappings: IMappedIds = {};
    const productMappings: IMappedIds = {};

    await cloneCategories(categoriesData, categoryMappings, clonedStoreId);

    setClonedPercent(40);

    const {
      data: { items: productsData },
    } = await ProductService.getStoreProducts(id, 5000);

    await cloneProducts(
      categoryMappings,
      clonedStoreId,
      productsData,
      productMappings,
      id,
    );

    setClonedPercent(60);

    await cloneComboProducts(categoryMappings, productsData, productMappings);

    setClonedPercent(80);
    setStoreToClone(undefined);

    return newStore;
  };

  const cloneCategories = async (
    categoriesData: ICategory[],
    categoryMappings: IMappedIds,
    storeId: string,
  ) => {
    if (isEmpty(categoriesData)) {
      return;
    }

    for (const category of categoriesData) {
      try {
        const {
          data: { id: clonedCategoryId },
        } = await CategoryService.createCategory({
          active: category.active,
          name: category.name,
          priority: category.priority,
          storeId,
          type: category.type,
        });
        categoryMappings[category.id] = clonedCategoryId;
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error.response);
        enqueueSnackbar(
          'Store Category could not be cloned. Please try again.',
          {
            variant: 'error',
          },
        );
      }
    }
  };

  const cloneProducts = async (
    categoryMappings: IMappedIds,
    storeId: string,
    productsData: IProduct[],
    productMappings: IMappedIds,
    originalStoreId: string,
  ) => {
    if (isEmpty(categoryMappings)) {
      return;
    }

    for (const product of productsData) {
      const currentProductCategoryId = product.categoryId;

      // if `product.fulfillmentCenterId !== originalStoreId`, preserve the originally fulfillment center.
      // else, it was fulfilled by the original store so use the cloned store's ID.
      const fulfillmentCenterId =
        product.fulfillmentCenterId !== originalStoreId
          ? product.fulfillmentCenterId
          : storeId;

      if (isEmpty(currentProductCategoryId)) {
        continue;
      }

      try {
        const {
          data: { id: clonedProductId },
        } = await ProductService.createProduct({
          active: product.active,
          alcohol: product.alcohol,
          availabilityType: product.availabilityType,
          categoryId: categoryMappings[currentProductCategoryId],
          description: product.description,
          fulfillmentCenterId,
          globalProductId: product.globalProductId,
          name: product.name,
          price: product.price,
          priority: product.priority,
          stock: product.stock,
          storeId,
          taxable: product.taxable,
          countAsInventory : product.stock > 0  ? product.countAsInventory :false ,
          inventoryCount : product.stock
        });
        productMappings[product.id] = clonedProductId;
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error.response);
        enqueueSnackbar(
          `${product.name} could not be cloned. Please try again.`,
          {
            variant: 'error',
          },
        );
      }
    }
  };

  const cloneComboProducts = async (
    categoryMappings: IMappedIds,
    productsData: IProduct[],
    productMappings: IMappedIds,
  ) => {
    if (isEmpty(categoryMappings) || isEmpty(productMappings)) {
      return;
    }

    for (const product of productsData) {
      const currentProductCategoryId = product.categoryId;
      if (!currentProductCategoryId || !product.hasSubProducts) {
        continue;
      }

      const { data: combo } = await ProductService.getProduct(product.id);
      const subCategories: ISubCategory[] = (combo.subCategories || []).map(
        (subCategory: ISubCategory) => {
          const currentSubCategory = {
            categoryId: categoryMappings[subCategory.categoryId],
            multiselect: subCategory.multiselect,
            required: subCategory.required,
            selectionLimit: subCategory.selectionLimit,
            subProducts: Array<ISubProduct>(),
          };

          currentSubCategory.subProducts = (subCategory.subProducts || []).map(
            (subProduct: ISubProduct) => ({
              autoSelect: subProduct.autoSelect,
              price: subProduct.price,
              productId: productMappings[subProduct.productId],
              quantityLimit: subProduct.quantityLimit,
              active : subProduct.active
            }),
          );

          return currentSubCategory;
        },
      );

      try {
        await ProductService.updateProduct(productMappings[product.id], {
          subCategories,
        });
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error.response);
        enqueueSnackbar(
          `${combo.name} could not be cloned. Please try again.`,
          {
            variant: 'error',
          },
        );
      }
    }
  };

  return {
    cloneStore,
    clonedPercent,
    storeToClone,
  };
};
