import { Button, Tab, Tabs, Typography } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import DeleteDialog from '../../components/DeleteDialog';
import { useAppContext } from '../../config/AppContext';
import { ICreateEventDto, IEvent } from '../../models/Event.model';
import EventService from '../../services/Event/Event.service';
import { getUTCDate } from '../../services/Util/Util.service';
import EventDetails from './EventDetails';
import './Events.scss';
import { initialEventState, RelativeTime } from './EventsConfig';
import EventsTable from './EventsTable';

const eventTabs = [
  {
    name: RelativeTime.UPCOMING,
    route: '',
  },
  {
    name: RelativeTime.PAST,
    route: '?date=past',
  },
];

export const Events = () => {
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const params = new URLSearchParams(search);
  const upcoming = params.get('date') !== 'past';

  const [eventToDelete, setEventToDelete] = useState<IEvent | undefined>();
  const [event, setEvent] = useState<IEvent | undefined>();

  const {
    buildingVenues,
    events,
    eventPagination,
    fetchEvents,
    setShowSpinner,
    venue,
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalCount,
  } = eventPagination;

  useEffect(() => {
    fetchEvents(upcoming);
  }, [upcoming, page, rowsPerPage]);

  const addOrEditEvent = async ({
    active,
    advanceOrderInterval,
    advanceOrderWindow,
    buildingVenueId,
    description,
    endDate,
    id,
    maxOrdersPerInterval,
    name,
    orderAheadCutoffType,
    startDate,
  }: IEvent): Promise<void> => {
    if (!venue) {
      return;
    }

    const dto: ICreateEventDto = {
      active,
      advanceOrderInterval: advanceOrderInterval * 60,
      advanceOrderWindow: advanceOrderWindow * 60 || 0,
      buildingVenueId,
      description,
      endDate: getUTCDate(new Date(endDate), venue?.timezone).toISOString(),
      maxOrdersPerInterval,
      name,
      orderAheadCutoffType,
      startDate: getUTCDate(new Date(startDate), venue?.timezone).toISOString(),
      venueId: venue.id,
    };

    setShowSpinner(true);
    try {
      let savedEvent: IEvent;
      if (id) {
        const { data: updatedEvent } = await EventService.updateEvent(id, dto);
        savedEvent = updatedEvent;
      } else {
        const { data: newEvent } = await EventService.createEvent(dto);
        savedEvent = newEvent;
      }

      setEvent(savedEvent);
      fetchEvents(upcoming);

      enqueueSnackbar('Event has been successfully saved', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const cloneEvent = async ({
    active,
    buildingVenueId,
    description,
    endDate,
    name,
    startDate,
    advanceOrderWindow,
    orderAheadCutoffType,
  }: IEvent) => {
    if (!venue) {
      return;
    }

    try {
      await EventService.createEvent({
        active,
        advanceOrderWindow,
        buildingVenueId,
        description,
        endDate: new Date(endDate).toISOString(),
        name: 'Cloned_' + name,
        orderAheadCutoffType,
        startDate: new Date(startDate).toISOString(),
        venueId: venue.id,
      });

      await fetchEvents(upcoming);

      enqueueSnackbar('Event has been successfully cloned', {
        variant: 'success',
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Event could not be cloned. Please try again.', {
        variant: 'error',
      });
    }
  };

  const deleteEvent = async () => {
    if (!eventToDelete) {
      return;
    }

    setShowSpinner(true);
    try {
      await EventService.deleteEvent(eventToDelete.id);

      setPage(0);
      await fetchEvents(upcoming);
      setEventToDelete(undefined);

      enqueueSnackbar('Event successfully deleted', { variant: 'success' });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error);
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  if (event) {
    return (
      <div className="events">
        <EventDetails
          event={event}
          onAddOrEditEvent={addOrEditEvent}
          buildingVenues={buildingVenues}
          close={() => setEvent(undefined)}
        />
      </div>
    );
  }

  return (
    <div className="events">
      <Typography component="h1" variant="h5" className="section-title">
        Events
      </Typography>
      <div className="custom-tabs-container">
        <Tabs
          value={upcoming ? 0 : 1}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            flexContainer: 'secondary-tabs-buttons-container',
            indicator: 'indicator',
            root: 'secondary-tabs-container',
          }}
        >
          {eventTabs.map(({ route, name }) => (
            <Tab
              component={Link}
              to={`${url}${route}`}
              key={name}
              label={name}
              classes={{
                root: 'secondary-tab',
                selected: 'selectedTab',
              }}
            />
          ))}
        </Tabs>
      </div>
      <div className="pagination-container">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
        <Button
          size="medium"
          color="primary"
          aria-label="add"
          classes={{
            root: 'add-event-button',
          }}
          onClick={() => setEvent(initialEventState)}
        >
          <div className="icon-title-wrapper">
            <AddOutlinedIcon />
            <span className="icon-title">Add Event</span>
          </div>
        </Button>
      </div>
      {eventToDelete && (
        <DeleteDialog
          dialogTitle="Delete Event"
          dialogContent="Are you sure you want to remove this Event?"
          onClose={() => setEventToDelete(undefined)}
          onSubmit={deleteEvent}
        />
      )}
      <EventsTable
        events={events}
        selectEvent={(e: IEvent) => setEvent(e)}
        deleteEvent={(e: IEvent) => setEventToDelete(e)}
        cloneEvent={(e: IEvent) => cloneEvent(e)}
      />
    </div>
  );
};
