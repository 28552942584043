import { ICreateEventDto, IEvent } from '../../models/Event.model';
import axiosWithAuth, { IPaginatedItems } from '../ServiceConfig';

const baseUrl = `/events`;

const getAllEvents = (
  limit: number,
  page: number,
  venueId: string,
  future: boolean = false,
  upcoming: boolean = false,
  past: boolean = false,
) =>
  axiosWithAuth.get<IPaginatedItems<IEvent>>(baseUrl, {
    params: {
      future,
      limit,
      page,
      past,
      upcoming,
      venueId,
    },
  });

const createEvent = (body: ICreateEventDto) =>
  axiosWithAuth.post<IEvent>(baseUrl, body);

const getEvent = (eventId: string) =>
  axiosWithAuth.get<IEvent>(`${baseUrl}/${eventId}`);

const updateEvent = (eventId: string, body: Partial<ICreateEventDto>) =>
  axiosWithAuth.put<IEvent>(`${baseUrl}/${eventId}`, body);

const deleteEvent = (eventId: string) =>
  axiosWithAuth.delete(`${baseUrl}/${eventId}`);

export default {
  createEvent,
  deleteEvent,
  getAllEvents,
  getEvent,
  updateEvent,
};
