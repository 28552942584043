import { Tab, Tabs, Typography } from '@material-ui/core';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { useAppContext } from '../../config/AppContext';
import { ISecondaryTab } from '../../config/Common';
import { IOrder } from '../../models/Order.model';
import { UserVenueRole } from '../../models/User.model';
import { IUserAdmin } from '../../models/UserAdmin.model';
import VenueService from '../../services/Venue/Venue.service';
import CurrentOrders from './CurrentOrders/CurrentOrders';
import OrderHistory from './OrderHistory/OrderHistory';
import { REFUNDSTATUS, VALIDSTATUS } from './Orders.config';
import axiosWithAuth from '../../services/ServiceConfig';
import './Orders.scss';

export interface ISearchCriteria {
  eventDay: string | null;
  eventName: string | null;
  endTime: Date | null;
  firstName: string;
  lastName: string;
  orderNo: string;
  page: number;
  refundStatus: string[];
  rowsPerPage: number;
  startTime: Date | null;
  status: string[];
  terminalId: string;
}

export interface IOrderTabComponentProps extends WithSnackbarProps {
  handleChangePage: (event: any, newPage: number) => void;
  handleChangeRowsPerPage: (event: any) => void;
  handleStoreSelect: (event: any) => void;
  handleTerminalSelect: (event: any) => void;
  isLoading: boolean;
  lastRefreshRequested: Date;
  orders: IOrder[];
  runners: IUserAdmin[];
  searchCriteria: ISearchCriteria;
  selectedRunnerId: string | undefined;
  selectedStoreId?: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOrders: React.Dispatch<React.SetStateAction<IOrder[]>>;
  setSearchCriteria: React.Dispatch<React.SetStateAction<ISearchCriteria>>;
  setSelectedRunnerId: React.Dispatch<React.SetStateAction<string | undefined>>;
  triggerRefresh: () => void;
  venueId: string;
  allTerminals: any[];
  terminalId: string;
  terminalName: string | undefined;
}

export const RowsPerPageOptions: number[] = [50, 100, 150];
const baseSearchCriteria = {
  endTime: null,
  eventDay: null,
  eventName: null,
  firstName: '',
  lastName: '',
  orderNo: '',
  page: 0,
  refundStatus: [],
  rowsPerPage: RowsPerPageOptions[0],
  startTime: null,
  status: [],
  terminalId: 'all',
};

// DefaultSearchCriteria is a constant array of 2 elements (for the Live Orders/Order History tabs)
// DefaultSearchCriteria[0] = criteria for Live Orders
// DefaultSearchCriteria[1] = criteria for Order History
export const DefaultSearchCriteria: ISearchCriteria[] = [
  {
    ...baseSearchCriteria,
    refundStatus: [REFUNDSTATUS.NOT_APPLICABLE, REFUNDSTATUS.PARTIAL_REFUNDED],
    rowsPerPage: RowsPerPageOptions[2],
    status: [VALIDSTATUS.NEW, VALIDSTATUS.PRE_ORDER],
  },
  { ...baseSearchCriteria },
];

const secondaryTabs: ISecondaryTab[] = [
  {
    component: CurrentOrders,
    name: 'Live Orders',
    route: 'live-orders',
  },
  {
    component: OrderHistory,
    name: 'Order History',
    route: 'order-history',
  },
];

interface IOrdersProps extends RouteComponentProps, WithSnackbarProps {
  venueId: string;
}

const Orders = (props: IOrdersProps) => {
  const {
    enqueueSnackbar,
    location: { pathname },
    venueId,
  } = props;

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastRefreshRequested, setLastRefreshRequested] = useState<Date>(
    new Date(),
  );
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>(
    DefaultSearchCriteria[0],
  );
  const [runners, setRunners] = useState<IUserAdmin[]>([]);
  const [selectedRunnerId, setSelectedRunnerId] = useState<string | undefined>(
    undefined,
  );
  const [terminalId, setTerminalId] = useState<string>('all');
  const [terminalName, setTerminalName] = useState<string>('all');

  const {
    selectedStoreId,
    setSelectedStoreId,
    allTerminals,
    setAllTerminals,
  } = useAppContext();

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newTabValue: number,
  ) => {
    if (newTabValue !== activeTabIndex) {
      setActiveTabIndex(newTabValue);
      setOrders([]);
      setSearchCriteria({
        ...DefaultSearchCriteria[newTabValue],
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await VenueService.getAllUserAdmins(
          venueId,
          1000,
          1,
          '',
          '',
        );

        // do we want only RUNNER roles to be assignable? should more senior team members be able to as well?
        const runnerUsers = data.items.filter(
          (runner: IUserAdmin) => runner.role === UserVenueRole.RUNNER,
        );

        setRunners(runnerUsers);
        if (runnerUsers.length > 0) {
          setSelectedRunnerId(runnerUsers[0].id);
        }
      } catch (error) {
        // tslint:disable-next-line
        console.log(error.response);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
      }
    })();
  }, [venueId]);

  const handleStoreSelect = (event: any) => {
    const { value } = event.target;

    setOrders([]);
    setSelectedStoreId(value);
    setTerminalId('all');
    setTerminalName('all');
    setSearchCriteria({
      ...searchCriteria,
      page: 0,
    });
  };
  const handleTerminalSelect = (event: any) => {
    const { value } = event.target;
    setOrders([]);
    if (value === 'all' || value === 'DELIVERY' || value === 'PICKUP') {
      setTerminalId(value);
      setTerminalName(value);
      setSearchCriteria({
        ...searchCriteria,
        page: 0,
        terminalId: value,
      });
    } else {
      const selectedTerminal = allTerminals.reduce((acc, item) => {
        if (item.terminalName === value) {
          return item;
        }
        return acc;
      }, null);
      setTerminalId(selectedTerminal.stripeTerminalId);
      setTerminalName(selectedTerminal.terminalName);
      setSearchCriteria({
        ...searchCriteria,
        page: 0,
        terminalId: selectedTerminal.stripeTerminalId,
      });
    }
  };
  useEffect(() => {
    getAllTerminals();
  }, [selectedStoreId]);

  const getAllTerminals = () => {
    axiosWithAuth
      .get(`terminals/store/${selectedStoreId}`)
      .then(response => {
        setAllTerminals(response.data);
      })
      .catch(error => {
        console.log('Error while fetching terminals ', error);
      });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setOrders([]);
    setSearchCriteria({
      ...searchCriteria,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setOrders([]);
    setSearchCriteria({
      ...searchCriteria,
      page: 0,
      rowsPerPage: +event.target.value,
    });
  };

  const triggerRefresh = () => setLastRefreshRequested(new Date());

  useEffect(() => {
    if (pathname.indexOf('/orders/') === -1) {
      props.history.push(`${props.match.url}/live-orders`);
      setActiveTabIndex(0);
    } else if (pathname.indexOf('/order-history') !== -1) {
      setActiveTabIndex(1);
    }
  }, [pathname]);

  return (
    <div className="orders-container">
      <div className="orders-header-container">
        <Typography classes={{ root: 'section-title' }}>Orders</Typography>
      </div>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        textColor="primary"
        classes={{
          flexContainer: 'secondary-tabs-buttons-container',
          indicator: 'indicator',
          root: 'secondary-tabs-container',
        }}
      >
        {secondaryTabs.map((secondaryTab: ISecondaryTab, index: number) => (
          <Tab
            component={Link}
            to={`${props.match.url}/${secondaryTab.route}`}
            key={index}
            label={secondaryTab.name}
            classes={{
              root: 'secondary-tab',
              selected: 'selectedTab',
              wrapper: 'orders-icon',
            }}
          />
        ))}
      </Tabs>
      {!!selectedStoreId &&
        secondaryTabs.map((secondaryTab: ISecondaryTab, index: number) => (
          <Route
            render={childProps => (
              <secondaryTab.component
                {...childProps}
                {...{
                  handleChangePage,
                  handleChangeRowsPerPage,
                  handleStoreSelect,
                  isLoading,
                  lastRefreshRequested,
                  orders,
                  runners,
                  searchCriteria,
                  selectedRunnerId,
                  selectedStoreId,
                  setIsLoading,
                  setOrders,
                  setSearchCriteria,
                  setSelectedRunnerId,
                  triggerRefresh,
                  venueId,
                  allTerminals:
                    secondaryTab.name === 'Order History' && allTerminals,
                  terminalId:
                    secondaryTab.name === 'Order History' && terminalId,
                  handleTerminalSelect:
                    secondaryTab.name === 'Order History' &&
                    handleTerminalSelect,
                  terminalName:
                    secondaryTab.name === 'Order History' && terminalName,
                }}
              />
            )}
            path={`${props.match.path}/${secondaryTab.route}`}
            key={index}
          />
        ))}
    </div>
  );
};

export default withSnackbar(Orders);
