import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { IUserAdmin } from '../../../models/UserAdmin.model';
import './RunnerDialog.scss';

interface IRunnerDialogProps {
  handleAssignRunner: () => Promise<void>;
  onClose: () => void;
  runners: IUserAdmin[];
  selectedRunnerId?: string;
  selectRunner: (runnerId: string) => void;
}

const RunnerDialog = (props: IRunnerDialogProps) => {
  const {
    handleAssignRunner,
    onClose,
    runners,
    selectedRunnerId,
    selectRunner,
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle
        classes={{
          root: 'dialog-root',
        }}
      >
        <span className="section-title">Assign runner</span>
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="age-native-required">Runner *</InputLabel>
        <FormControl required={true} className="runner-dialog__field">
          <Select
            disabled={isLoading}
            displayEmpty={true}
            name="type"
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              selectRunner(e.target.value as string);
            }}
            value={selectedRunnerId}
          >
            {runners.map((runner: IUserAdmin) => (
              <MenuItem key={runner.id} value={runner.id}>
                {runner.firstName} {runner.lastName} - {runner.phoneNumber}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <div className="button-holder-similar-margin">
        <DialogActions>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={onClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await handleAssignRunner();
              setIsLoading(false);
            }}
            variant="contained"
          >
            Assign
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default RunnerDialog;
