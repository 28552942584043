import * as Yup from 'yup';

export interface ISponsor {
  id?: string;
  name: string;
  imageFile?: string;
  imageUrl?: string;
  sponsorModuleId?: string;
}

export const sponsorModuleInitialState = {
  sponsors: [
    {
      imageUrl: '',
      name: '',
    },
  ],
  title: '',
};

export const sponsorsTableColumnNames = [
  { align: 'left', name: 'NAME', size: 'medium' },
  { align: 'right', name: 'ACTIVE', size: 'small' },
  { align: 'right', name: '', size: 'small' },
];

export const sponsorValidationSchema = () => {
  return Yup.object().shape({
    sponsors: Yup.array()
      .of(
        Yup.object().shape({
          imageUrl: Yup.string().required('Required'),
          name: Yup.string()
            .max(30, 'Please limit the name to 30 characters')
            .required('Required'),
        }),
      )
      .required('Must have at least 1 sponsor/partner'),
    title: Yup.string()
      .required()
      .max(30, 'Please limit the name to 30 characters'),
  });
};
