import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
  Button,
} from '@material-ui/core';
import StoreService from '../../../../services/Store/Store.service';
import { useStoreContext } from '../../../../config/StoreContext';
import { useSnackbar } from 'notistack';

interface TimeFormat {
  hours: string;
  minutes: string;
  period: string;
}

interface Schedule {
  open: boolean;
  timeIntervalFrom: TimeFormat;
  timeIntervalTo: TimeFormat;
  override: boolean;
}

interface ScheduleState {
  Monday: Schedule;
  Tuesday: Schedule;
  Wednesday: Schedule;
  Thursday: Schedule;
  Friday: Schedule;
  Saturday: Schedule;
  Sunday: Schedule;
}

const ScheduleHour: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { store, setStore, fetchStore } = useStoreContext();
  const initialSchedule: Schedule = {
    open: false,
    override: false,
    timeIntervalFrom: {
      hours: '12',
      minutes: '00',
      period: 'AM',
    },
    timeIntervalTo: {
      hours: '12',
      minutes: '00',
      period: 'AM',
    },
  };

  const [schedules, setSchedules] = useState<ScheduleState>({
    Monday: { ...initialSchedule },
    Tuesday: { ...initialSchedule },
    Wednesday: { ...initialSchedule },
    Thursday: { ...initialSchedule },
    Friday: { ...initialSchedule },
    Saturday: { ...initialSchedule },
    Sunday: { ...initialSchedule },
  });

  useEffect(() => {
    if (store && store?.scheduleHour?.length > 0) {
      const updatedSchedules: ScheduleState = store.scheduleHour.reduce(
        (acc: any, daySchedule: any) => {
          const { day, timeInterval, close, override } = daySchedule;

          let timeIntervalFrom = {
            hours: '12',
            minutes: '00',
            period: 'AM',
          };
          let timeIntervalTo = {
            hours: '12',
            minutes: '00',
            period: 'AM',
          };

          if (timeInterval) {
            const [from, to] = timeInterval.split('-');
            timeIntervalFrom = parseTime(from);
            timeIntervalTo = parseTime(to);
          }

          acc[day] = {
            open: !close,
            timeIntervalFrom,
            timeIntervalTo,
            override,
          };

          return acc;
        },
        {},
      );
      setSchedules(updatedSchedules);
    }
  }, [store]);

  const parseTime = (time: string): TimeFormat => {
    const [timePart, period] = time.split(/(AM|PM)/);
    const [hours, minutes] = timePart.split(':');
    return {
      hours,
      minutes,
      period: period.trim(),
    };
  };

  const handleToggle = (day: keyof ScheduleState) => {
    setSchedules(prevSchedules => ({
      ...prevSchedules,
      [day]: {
        ...prevSchedules[day],
        open: !prevSchedules[day].open,
        override: prevSchedules[day].override,
      },
    }));
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    day: keyof ScheduleState,
    timeType: 'from' | 'to',
    field: 'hours' | 'minutes' | 'period',
  ) => {
    const { value } = event.target;
    setSchedules(prevSchedules => ({
      ...prevSchedules,
      [day]: {
        ...prevSchedules[day],
        [timeType === 'from' ? 'timeIntervalFrom' : 'timeIntervalTo']: {
          ...prevSchedules[day][
            timeType === 'from' ? 'timeIntervalFrom' : 'timeIntervalTo'
          ],
          [field]: value,
        },
      },
    }));
  };

  const validateTime = (day: keyof ScheduleState): boolean => {
    const { timeIntervalFrom, timeIntervalTo } = schedules[day];
    const fromTime = convertTo24HourFormat(timeIntervalFrom);
    const toTime = convertTo24HourFormat(timeIntervalTo);
    return fromTime <= toTime;
  };

  const convertTo24HourFormat = (time: TimeFormat): string => {
    let hours = parseInt(time.hours, 10);
    const minutes = parseInt(time.minutes, 10);

    if (time.period === 'PM' && hours < 12) {
      hours += 12;
    } else if (time.period === 'AM' && hours === 12) {
      hours = 0;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (store && store.id) {
        const formattedSchedules = Object.keys(schedules).map(day => {
          const {
            timeIntervalFrom,
            timeIntervalTo,
            open,
            override,
          } = schedules[day as keyof ScheduleState];
          return {
            day,
            timeInterval: open
              ? `${timeIntervalFrom.hours}:${timeIntervalFrom.minutes}${timeIntervalFrom.period}-` +
                `${timeIntervalTo.hours}:${timeIntervalTo.minutes}${timeIntervalTo.period}`
              : '',
            close: !open,
            override: override,
          };
        });
        const { data } = await StoreService.setStoreSchedule(store?.id, {
          schedule: formattedSchedules,
        });
        setStore(data);
        return enqueueSnackbar('Schedule updated successfully', {
          variant: 'success',
        });
      }
      enqueueSnackbar('Store not found', {
        variant: 'error',
      });
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: 'error',
      });
    }
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <Typography variant="h6" gutterBottom>
        SCHEDULE
      </Typography>
      <form onSubmit={handleSubmit}>
        {Object.keys(schedules).map(day => (
          <Grid container spacing={2} alignItems="center" key={day}>
            <Grid item xs={3} 
            style={{marginTop :'22px'}}
            >
              <Typography variant="h6">{day}</Typography>
            </Grid>
            <Grid item xs={2} style={{marginTop :'22px'}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={schedules[day as keyof ScheduleState].open}
                    onChange={() => handleToggle(day as keyof ScheduleState)}
                    name={`open-${day}`}
                    color="primary"
                  />
                }
                label={
                  <Typography style={{ fontWeight: 'normal' }}>Open</Typography>
                }
                labelPlacement="start"
              />
            </Grid>
            {schedules[day as keyof ScheduleState].open && (
              <>
                <Grid item xs={3}>
                  <Typography>From</Typography>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Select
                        value={
                          schedules[day as keyof ScheduleState].timeIntervalFrom
                            .hours
                        }
                        onChange={e =>
                          handleChange(
                            e,
                            day as keyof ScheduleState,
                            'from',
                            'hours',
                          )
                        }
                        variant="outlined"
                        style={{ width: '70px' }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 180,
                            },
                          },
                        }}
                      >
                        {[...Array(12)].map((_, index) => (
                          <MenuItem
                            key={index + 1}
                            value={(index + 1).toString().padStart(2, '0')}
                          >
                            {(index + 1).toString().padStart(2, '0')}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Select
                        value={
                          schedules[day as keyof ScheduleState].timeIntervalFrom
                            .minutes
                        }
                        onChange={e =>
                          handleChange(
                            e,
                            day as keyof ScheduleState,
                            'from',
                            'minutes',
                          )
                        }
                        variant="outlined"
                        style={{ width: '70px' }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="00">00</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="45">45</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item>
                      <Select
                        value={
                          schedules[day as keyof ScheduleState].timeIntervalFrom
                            .period
                        }
                        onChange={e =>
                          handleChange(
                            e,
                            day as keyof ScheduleState,
                            'from',
                            'period',
                          )
                        }
                        variant="outlined"
                        style={{ width: '71px' }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="AM">AM</MenuItem>
                        <MenuItem value="PM">PM</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography>To</Typography>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Select
                        value={
                          schedules[day as keyof ScheduleState].timeIntervalTo
                            .hours
                        }
                        onChange={e =>
                          handleChange(
                            e,
                            day as keyof ScheduleState,
                            'to',
                            'hours',
                          )
                        }
                        variant="outlined"
                        style={{ width: '70px' }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 180,
                            },
                          },
                        }}
                      >
                        {[...Array(12)].map((_, index) => (
                          <MenuItem
                            key={index + 1}
                            value={(index + 1).toString().padStart(2, '0')}
                          >
                            {(index + 1).toString().padStart(2, '0')}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Select
                        value={
                          schedules[day as keyof ScheduleState].timeIntervalTo
                            .minutes
                        }
                        onChange={e =>
                          handleChange(
                            e,
                            day as keyof ScheduleState,
                            'to',
                            'minutes',
                          )
                        }
                        variant="outlined"
                        style={{ width: '70px' }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="00">00</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="45">45</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item>
                      <Select
                        value={
                          schedules[day as keyof ScheduleState].timeIntervalTo
                            .period
                        }
                        onChange={e =>
                          handleChange(
                            e,
                            day as keyof ScheduleState,
                            'to',
                            'period',
                          )
                        }
                        variant="outlined"
                        style={{ width: '71px' }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="AM">AM</MenuItem>
                        <MenuItem value="PM">PM</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {!validateTime(day as keyof ScheduleState) && (
              <Grid item xs={12}>
                <FormControl error>
                  <FormHelperText>
                    Store End Time Should be greater than start time
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Grid>
        ))}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '20px', float: 'right' }}
        >
          Save Schedule
        </Button>
      </form>
    </div>
  );
};

export default ScheduleHour;
