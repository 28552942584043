import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import React from 'react';

interface IConfirmDialogProps {
  content: string | JSX.Element;
  onClose: () => void;
  onSubmit: () => void;
  submitText?: string;
  title: string;
}

const ConfirmDialog = (props: IConfirmDialogProps) => {
  const { content, onClose, onSubmit, submitText, title } = props;

  return (
    <Dialog fullWidth={true} open={true} onClose={onClose}>
      <DialogTitle>
        <span className="section-title">{title}</span>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <div className="button-holder-similar-margin">
        <DialogActions>
          <Button color="primary" onClick={onClose} variant="outlined">
            <div className="icon-title-wrapper">
              <UndoOutlinedIcon />
              <span className="icon-title">Cancel</span>
            </div>
          </Button>
          <Button color="primary" onClick={onSubmit} variant="contained">
            <div className="icon-title-wrapper">
              <DeleteOutlinedIcon />
              <span className="icon-title">{submitText || 'OK'}</span>
            </div>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
