import IProduct from '../../models/Product.model';
import axiosWithAuth from '../ServiceConfig';
import IINVENTORY from '../../models/Inventory.model';
const baseUrl = `/inventory`;

const getProductInventory = (productId: string) =>
  axiosWithAuth.get(`${baseUrl}/getItemByProduct/${productId}`);


const getOutOfStockProducts = (storeId: string , categoryId : string) =>
  axiosWithAuth.get(`${baseUrl}/getOutOfStockPorducts/${storeId}/${categoryId}`);

const searchInventory = (
  storeId: string,
  categoryId: string,
  searchKey: string,
) => {
  let url = `${baseUrl}/getItemForInventory`;

  const queryParams = [];

  if (storeId) {
    queryParams.push(`storeId=${encodeURIComponent(storeId)}`);
  }

  if (categoryId) {
    queryParams.push(`categoryId=${encodeURIComponent(categoryId)}`);
  }

  if (searchKey) {
    queryParams.push(`searchKey=${encodeURIComponent(searchKey)}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }
  return axiosWithAuth.get(url);
};
const getInventoryLevels = (
  venueId : string,
  storeId: string,
  categoryId: string,
  searchKey: string,
  start_date :string,
  end_date :string
) => {
  let url = `${baseUrl}/getInventoryLevels`;

  const queryParams = [
    `venueId=${encodeURIComponent(venueId)}`
  ];
  if (start_date) {
    queryParams.push(`start_date=${encodeURIComponent(start_date)}`);
  }
  if (end_date) {
    queryParams.push(`end_date=${encodeURIComponent(end_date)}`);
  }
  if (storeId) {
    queryParams.push(`storeId=${encodeURIComponent(storeId)}`);
  }

  if (categoryId) {
    queryParams.push(`categoryId=${encodeURIComponent(categoryId)}`);
  }

  if (searchKey) {
    queryParams.push(`searchKey=${encodeURIComponent(searchKey)}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }
  return axiosWithAuth.get(url);
};

const getAdjustmentLogs = (
  venueId : string,
  start_date: string,
  end_date: string,
  storeId: string,
  searchKey: string
) => {
  let url = `${baseUrl}/getAdjustmentLog`;

  const queryParams = [
    `venueId=${encodeURIComponent(venueId)}`
  ];

  if (start_date) {
    queryParams.push(`start_date=${encodeURIComponent(start_date)}`);
  }

  if (end_date) {
    queryParams.push(`end_date=${encodeURIComponent(end_date)}`);
  }

  if (storeId) {
    queryParams.push(`storeId=${encodeURIComponent(storeId)}`);
  }

  if (searchKey) {
    queryParams.push(`searchKey=${encodeURIComponent(searchKey)}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  return axiosWithAuth.get(url);
};


const getInventoryById = (inventoryId: string) =>
  axiosWithAuth.get(`${baseUrl}/${inventoryId}`);


const updateInventroy = (inventroyId: string, body: any) =>
  axiosWithAuth.put(`${baseUrl}/${inventroyId}`, body);

export default {
  getProductInventory,
  searchInventory,
  updateInventroy,
  getOutOfStockProducts,
  getInventoryById,
  getAdjustmentLogs,
  getInventoryLevels
};
