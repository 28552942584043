import { Box, Button, Input, InputLabel, Modal } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState , useEffect } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Spinner from '../../components/Spinner';
import { useAppContext } from '../../config/AppContext';
import LocalStorageService from '../../services/LocalStorage/LocalStorage.service';
import LoginService from '../../services/Login/Login.service';
import { unMaskPhoneNumber } from '../../services/Util/Util.service';
import { useLocation } from 'react-router-dom';
import './login.scss';

enum CaptchaKey {
  clientKey = '6LdJ3JMiAAAAAHHle6rGZnuBWt0ACZ876PFzW9aT',
}
console.log('erlcome');
const Login = (props: any) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('1');
  const [OTP, setOTP] = useState('');
  const location = useLocation();
  const [sentOTP, setOTPSent] = useState(false);
  const [captcha, setCaptcha] = React.useState<boolean>(false);
  const { loadCurrentUser, showSpinner, setShowSpinner } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeCountryCode = (code: string = '') => {
    return !isEmpty(code) && setCountryCode(getCountryCallingCode(code));
  };
  useEffect(() => {
    // Check the URL when component mounts or URL changes
    if(location.pathname === '/login'){
      LocalStorageService.storeData('tenant-schema', false);
    }
    else if (location.pathname === '/twin-dolphin'){
      LocalStorageService.storeData('tenant-schema', true);
    }
  }, [location.pathname]);

  const handleChangeOTP = (e: any): void => {
    setOTP(get(e, 'target.value'));
  };

  const sendOTP = async () => {
    setShowSpinner(true);
    const phone = unMaskPhoneNumber(formatPhoneNumber(phoneNumber));
    const requestBody = {
      countryCode,
      phoneNumber: phone,
    };
    try {
      await LoginService.sendOTP(requestBody);
      setOTPSent(true);
      enqueueSnackbar('Passcode sent to your phone', {
        variant: 'success',
      });
    } catch (error) {
      if (error.message === 'Network Error') {
        enqueueSnackbar(
          'You are not connected to the internet. Please check your connection settings.',
          { variant: 'error' },
        );
      } else {
        enqueueSnackbar('Enter a valid phone number', { variant: 'error' });
      }
    }
    setShowSpinner(false);
  };

  const verifyOTP = async () => {
    setShowSpinner(true);
    const phone = unMaskPhoneNumber(formatPhoneNumber(phoneNumber));
    const requestBody = {
      countryCode,
      otpCode: OTP,
      phoneNumber: phone,
    };
    try {
      const response = await LoginService.verifyOTP(requestBody);
      const userProfile = get(response, ['data', 'data']);
      LocalStorageService.storeData('authToken', get(userProfile, 'authToken'));
      LocalStorageService.storeData('userId', userProfile.id);
      const tenant_schema = LocalStorageService.getLocalData('tenant-schema');
      if (tenant_schema) {
        if (tenant_schema === 'true') {
          LocalStorageService.storeData('tenant-schema', true);
        } else if (tenant_schema === 'fasle') {
          LocalStorageService.storeData('tenant-schema', false);
        }
      } else {
        LocalStorageService.storeData('tenant-schema', false);
      }

      setShowSpinner(false);
      const success = await loadCurrentUser();
      if (!success) {
        throw new Error();
      }

      enqueueSnackbar('Login Successful', { variant: 'success' });
      props.history.push('/home');
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response || error.message);
      enqueueSnackbar('Access Denied!', { variant: 'error' });
    }
    setShowSpinner(false);
  };

  const onChangeCaptcha = async (token: string | null) => {
    if (token) {
      const obj = {
        response: token,
      };

      const verifyCaptchaUrl = await LoginService.verifyCaptcha(obj);
      if (verifyCaptchaUrl && verifyCaptchaUrl.data.data.success) {
        sendOTP();
      }
    } else {
      setCaptcha(false);
      enqueueSnackbar('Captcha validation failed, Please try again.');
    }
  };

  const handleCaptcha = () => {
    setCaptcha(true);
  };

  return !sentOTP ? (
    <div className="login__form-holder">
      {showSpinner && <Spinner />}
      <Modal open={captcha} onClose={() => setCaptcha(false)}>
        <Box>
          <ReCaptcha
            className="login__recaptcha"
            sitekey={CaptchaKey.clientKey}
            onChange={onChangeCaptcha}
          />
        </Box>
      </Modal>
      <div className="login__phone-verification">
        <div className="login__phone-input">
          <InputLabel
            htmlFor="phone_number"
            classes={{
              focused: 'focused',
              root: 'login__phone-input__label',
            }}
            focused={true}
          >
            Sign In
          </InputLabel>
          <PhoneInput
            className="login__phone-input__field__container"
            defaultCountry="US"
            placeholder="Enter Phone Number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            onCountryChange={handleChangeCountryCode}
          />
          <Button className="login__button" onClick={handleCaptcha}>
            Submit
          </Button>
          <p className="errorMsg">
            Please make sure you are added as a user by the venue manager so you
            can proceed to login with your mobile phone number.
            <br />
            Text us at <span style={{ color: '#578ebe' }}>
              +1 256-269-7085
            </span>{' '}
            for assistance.
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className="login__form-holder">
      {showSpinner && <Spinner />}
      <div className="login__otp-verification">
        <div className="login__otp-input">
          <InputLabel
            htmlFor="otp_number"
            classes={{
              focused: 'focused',
              root: 'login__phone-input__label',
            }}
            focused={true}
          >
            Enter Passcode
          </InputLabel>
          <Input
            disableUnderline={true}
            id="otp_number"
            type="text"
            value={OTP}
            onChange={handleChangeOTP}
            className="login__otp-input__field"
          />
          <Button className="login__button" onClick={verifyOTP}>
            Verify Passcode
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
