import {
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import classNames from 'classnames';
import { format } from 'date-fns';
import { get } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import DeleteDialog from '../../../components/DeleteDialog';
import { noDataAvailable } from '../../../components/NoDataAvailable';
import { useAppContext } from '../../../config/AppContext';
import deleteIcon from '../../../images/deleteIcon.png';
import editIcon from '../../../images/editIcon.png';
import usersIcon from '../../../images/usersIcon.png';
import { IPromotion } from '../../../models/Promotion.model';
import PromoService from '../../../services/Promo/Promo.service';
import {
  getCurrencyPrefix,
  getTimezoneDate,
} from '../../../services/Util/Util.service';
import PromoDialog from './PromoDialog';
import { PromotionType } from './PromotionType.enum';

const Columns = [
  { name: 'Code', size: 'small' },
  { name: 'Begins', size: 'medium' },
  { name: 'Ends', size: 'medium' },
  { name: 'Type', size: 'small' },
  { name: 'Discount Amt', size: 'small' },
  { name: 'Discount %', size: 'small' },
  { name: 'Min Purchase', size: 'small' },
  { name: 'Active', size: 'small' },
  { name: 'Pos Discount', size: 'small' },
  { name: 'Limit Users', size: 'small' },
  { name: 'Action', size: 'medium' },
];

const endDateTime = new Date();
endDateTime.setMonth(endDateTime.getMonth() + 1);
const DefaultPromoState = {
  active: false,
  code: '',
  discountAmount: 0.0,
  discountPercentage: 0.0,
  endDateTime,
  excludeAlcohol: false,
  limitUsers: false,
  maxUsesPerUser: null,
  minPurchaseAmount: 0.0,
  startDateTime: new Date(),
  storeIds: [],
  type: PromotionType.MANUAL,
};

interface IPromoTableProps extends WithSnackbarProps {
  onEditUsers: (promotionId: string) => void;
  venueId: string;
}

const PromoTable = (props: IPromoTableProps): JSX.Element => {
  const { enqueueSnackbar, onEditUsers, venueId } = props;
  const { setShowSpinner, venue } = useAppContext();

  const [page, setPage] = useState<number>(0);
  const [selectedPromo, setSelectedPromo] = useState<any>(DefaultPromoState);
  const [promos, setPromos] = useState<IPromotion[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPromoId, setSelectedPromoId] = useState<string>('');
  const [showDeletePromoDialog, setShowDeletePromoDialog] = useState<boolean>(
    false,
  );
  const [showPromoDialog, setShowPromoDialog] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      await fetchPromos(rowsPerPage, page + 1);
    })();
  }, []);

  useEffect(() => {
    fetchPromos(rowsPerPage, page + 1);
  }, [page, rowsPerPage]);

  const fetchPromos = async (
    limit: number,
    pageNumber: number,
    code: string = '',
  ) => {
    setShowSpinner(true);
    try {
      const { data } = await PromoService.getAllPromos(
        venueId,
        limit,
        pageNumber,
        code,
      );
      data.items.map(
        (promo: any): IPromotion => {
          promo.startDateTime = new Date(promo.startDateTime);
          promo.endDateTime = new Date(promo.endDateTime);
          return promo;
        },
      );
      setPromos(data.items);
      setTotalCount(data.totalItems);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const toggleDeletePromoDialog = (promoId: string) => {
    setSelectedPromoId(promoId);
    setShowDeletePromoDialog(!showDeletePromoDialog);
  };

  const handleSubmitPromo = async (promoDetails: any) => {
    setShowSpinner(true);
    try {
      if (promoDetails.id) {
        await PromoService.updatePromo(promoDetails.id, promoDetails);
        enqueueSnackbar('Promotion has been successfully updated', {
          variant: 'success',
        });
      } else {
        await PromoService.createPromo({
          ...promoDetails,
          venueId,
        });
        enqueueSnackbar('Promotion has been successfully created', {
          variant: 'success',
        });
      }

      setShowPromoDialog(false);
      await fetchPromos(rowsPerPage, page + 1);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      const errorMessage = get(
        error,
        ['response', 'data', 'message'],
        'Something went wrong. Please try again.',
      );
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const handleEditPromo = (promo: any) => {
    setSelectedPromo(promo);
    setShowPromoDialog(true);
  };

  const handleSearchPromo = async (code: string) =>
    await fetchPromos(rowsPerPage, page + 1, code);

  const handleUpdatePromo = async (
    event: React.ChangeEvent<{}>,
    promoId: string,
    property: keyof IPromotion,
  ) => {
    await PromoService.updatePromo(promoId, {
      [property]: get(event, ['target', 'checked']),
    });

    setPage(0);
    await fetchPromos(rowsPerPage, 1);
  };

  const handleDeletePromo = async () => {
    setShowSpinner(true);
    try {
      await PromoService.deletePromo(selectedPromoId);
      await fetchPromos(rowsPerPage, 1);
      setShowDeletePromoDialog(false);
      enqueueSnackbar('Promotion successfully deleted', { variant: 'success' });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const isPromoExpired = (promo: IPromotion): boolean => {
    const now = new Date();
    return now < promo.startDateTime || now > promo.endDateTime;
  };

  const formatPromoCode = (promo: IPromotion): string => {
    const code = promo.code.toUpperCase();
    const now = new Date();
    if (now < promo.startDateTime) {
      return `${code} (future)`;
    }
    if (now > promo.endDateTime) {
      return `${code} (expired)`;
    }
    return code;
  };
  console.log(promos, 'promos');
  return (
    <div className="promos">
      <h2 className="promos__title section-title">Promos</h2>
      <div className="promos-pagination-toolbar">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
        <Button
          size="large"
          color="primary"
          className="add-promo-button"
          onClick={() => {
            setSelectedPromo(DefaultPromoState);
            setShowPromoDialog(true);
          }}
        >
          <div className="icon-title-wrapper">
            <AddOutlinedIcon />
            <span className="icon-title">Add Promo</span>
          </div>
        </Button>
      </div>
      {showPromoDialog && (
        <PromoDialog
          submit={handleSubmitPromo}
          close={() => {
            setShowPromoDialog(false);
          }}
          promo={selectedPromo}
        />
      )}
      <div className="promos-table-container">
        <Table className="promos-table">
          <TableHead className="promos-table-header">
            <TableRow>
              {Columns.map((column: any) => (
                <TableCell
                  key={column.name}
                  size={column.size}
                  align="center"
                  variant="head"
                  classes={{ root: 'promos-table-header__cells' }}
                >
                  <p>{column.name}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <TextField
                  margin="dense"
                  variant="outlined"
                  onChange={(event: any) => {
                    const {
                      target: { value },
                    } = event;
                    setSearchTerm(value);
                  }}
                  value={searchTerm}
                />
              </TableCell>
              <TableCell colSpan={Columns.length - 2} />
              <TableCell
                align="center"
                classes={{ root: 'promos-table-body__cells' }}
              >
                <Button
                  onClick={() => handleSearchPromo(searchTerm)}
                  className="search-button"
                >
                  Search
                </Button>
              </TableCell>
            </TableRow>
            {promos.length > 0
              ? promos.map((promo: IPromotion) => (
                  <TableRow
                    id={promo.id}
                    key={promo.id}
                    className={classNames('promos-table-row', {
                      'promos-table-row-disabled': isPromoExpired(promo),
                    })}
                  >
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {formatPromoCode(promo)}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {format(
                        getTimezoneDate(
                          new Date(promo.startDateTime),
                          get(venue, 'timezone', ''),
                        ),
                        'MM-dd-yyyy hh:mm a',
                      )}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {promo.endDateTime
                        ? format(
                            getTimezoneDate(
                              new Date(promo.endDateTime),
                              get(venue, 'timezone', ''),
                            ),
                            'MM-dd-yyyy hh:mm a',
                          )
                        : ''}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {promo.type}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <NumberFormat
                        value={promo.discountAmount || '-'}
                        decimalScale={2}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={getCurrencyPrefix(venue?.currency)}
                      />
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      {promo.discountPercentage
                        ? `${promo.discountPercentage}%`
                        : '-'}
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <NumberFormat
                        value={promo.minPurchaseAmount || '-'}
                        decimalScale={2}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={getCurrencyPrefix(venue?.currency)}
                      />
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        checked={promo.active}
                        color="primary"
                        name="active"
                        onChange={(e: any) =>
                          handleUpdatePromo(e, promo.id, 'active')
                        }
                        size="medium"
                        value={promo.active}
                      />
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        checked={promo.isPosDiscount}
                        color="primary"
                        name="isPosDiscount"
                        onChange={(e: any) =>
                          handleUpdatePromo(e, promo.id, 'isPosDiscount')
                        }
                        size="medium"
                        value={promo.isPosDiscount}
                      />
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <Switch
                        classes={{
                          track: 'custom-colored-switch-track',
                        }}
                        checked={promo.limitUsers}
                        color="primary"
                        name="limitUsers"
                        onChange={(e: any) =>
                          handleUpdatePromo(e, promo.id, 'limitUsers')
                        }
                        size="medium"
                        value={promo.limitUsers}
                      />
                    </TableCell>
                    <TableCell classes={{ root: 'promos-table-body__cells' }}>
                      <Button
                        size="small"
                        className="promo-edit-button"
                        onClick={() => {
                          handleEditPromo(promo);
                        }}
                        value="edit"
                      >
                        <div className="icon-title-wrapper">
                          <img
                            src={editIcon}
                            alt="edit icon"
                            title="Edit"
                            width={20}
                          />
                        </div>
                      </Button>
                      <Button
                        size="small"
                        className="promo-edit-button"
                        onClick={() => {
                          toggleDeletePromoDialog(promo.id);
                        }}
                        value="delete"
                      >
                        <div className="icon-title-wrapper">
                          <img
                            src={deleteIcon}
                            alt="delete icon"
                            title="Delete"
                            width={20}
                          />
                        </div>
                      </Button>
                      {promo.limitUsers && (
                        <Button
                          size="small"
                          className="promo-edit-button"
                          onClick={() => onEditUsers(promo.id)}
                          value="users"
                        >
                          <div className="icon-title-wrapper">
                            <img
                              src={usersIcon}
                              alt="users icon"
                              title="Users"
                              width={20}
                            />
                          </div>
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : noDataAvailable(10)}
          </TableBody>
        </Table>
      </div>
      {showDeletePromoDialog && (
        <DeleteDialog
          dialogTitle="Delete Promotion"
          onClose={toggleDeletePromoDialog}
          onSubmit={handleDeletePromo}
          dialogContent="Are you sure you want to delete this promotion?"
        />
      )}
    </div>
  );
};

export default withSnackbar(PromoTable);
