import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { get } from 'lodash';
import React, { useState } from 'react';
import { IEvent } from '../../../models/Event.model';
import { IVenueLocation } from '../../../models/Location.model';
import { IStore } from '../../../models/Store.model';
import { UserVenueRole } from '../../../models/User.model';
import { IVenueInvite } from '../../../models/VenueInvite.model';
import { humanReadableRoles } from '../UserAdminConfig';
import '../UserAdminDialog/UserAdminDialog.scss';

interface IVenueInviteDialogProps {
  close: () => void;
  events: IEvent[];
  stores: IStore[];
  suites: IVenueLocation[];
  save: (invite: Partial<IVenueInvite>) => Promise<void>;
}

export const VenueInviteDialog = ({
  close,
  events,
  stores,
  suites,
  save,
}: IVenueInviteDialogProps) => {
  const [role, setRole] = useState(UserVenueRole.VENUE_MANAGER);
  const [allEvents, setAllEvents] = useState(false);
  const [assignedEventIds, setAssignedEventIds] = useState<string[]>([]);
  const [assignedStoreIds, setAssignedStoreIds] = useState<string[]>([]);
  const [assignedSuiteIds, setAssignedSuiteIds] = useState<string[]>([]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    save({
      allEvents,
      eventIds: assignedEventIds,
      role,
      storeIds: assignedStoreIds,
      suiteIds: assignedSuiteIds,
    });
  };

  const onChangeRoleSelection = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRole = get(e, ['target', 'value'], '') as UserVenueRole;
    setRole(newRole);

    if (role !== newRole) {
      setAssignedEventIds([]);
      setAssignedStoreIds([]);
      setAssignedSuiteIds([]);
    }
  };

  const handleChangeSelectedEventIds = (id: string) => {
    if (assignedEventIds.includes(id)) {
      setAssignedEventIds(prev =>
        prev.filter((storeId: string) => storeId !== id),
      );
    } else {
      setAssignedEventIds(prev => [...prev, id]);
    }
  };

  const handleChangeSelectedStoreIds = (id: string) => {
    if (assignedStoreIds.includes(id)) {
      setAssignedStoreIds(prev =>
        prev.filter((storeId: string) => storeId !== id),
      );
    } else {
      setAssignedStoreIds(prev => [...prev, id]);
    }
  };

  const handleChangeSelectedSuiteIds = (id: string) => {
    if (assignedSuiteIds.includes(id)) {
      setAssignedSuiteIds(prev =>
        prev.filter((storeId: string) => storeId !== id),
      );
    } else {
      setAssignedSuiteIds(prev => [...prev, id]);
    }
  };

  const shouldDisableSubmitFormButton = (): boolean => {
    switch (role) {
      case UserVenueRole.STORE_EMPLOYEE:
      case UserVenueRole.STORE_MANAGER:
        return assignedStoreIds.length <= 0;
      case UserVenueRole.SUITE_ATTENDANT:
      case UserVenueRole.SUITE_CUSTOMER:
        return assignedSuiteIds.length <= 0;
      default:
        return false;
    }
  };

  return (
    <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span className="section-title">Create New Venue Invite</span>
      </DialogTitle>
      <DialogContent>
        <div className="user-form">
          <form onSubmit={handleSubmit}>
            <FormControl required={true} className="user-form__field">
              <InputLabel htmlFor="age-native-required">Role</InputLabel>
              <Select
                value={role}
                onChange={(e: any) => {
                  onChangeRoleSelection(e);
                }}
                name="role"
                displayEmpty={true}
                className="user-form__field"
                inputProps={{
                  id: 'age-native-required',
                }}
              >
                {Object.keys(humanReadableRoles).map((r: string) => (
                  <MenuItem key={r} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {[
              UserVenueRole.STORE_EMPLOYEE,
              UserVenueRole.STORE_MANAGER,
            ].includes(role) && (
              <>
                <div className="list-store-container">
                  <InputLabel
                    className="store-label"
                    htmlFor="age-native-required"
                  >
                    {`List Stores:`}
                  </InputLabel>
                  <FormControl
                    component={'fieldset'}
                    required={true}
                    className="user-form__fieldset"
                  >
                    <FormGroup>
                      {stores.length ? (
                        stores.map(({ id, name }: IStore) => (
                          <FormControlLabel
                            key={id}
                            control={
                              <Checkbox
                                onChange={() =>
                                  handleChangeSelectedStoreIds(id)
                                }
                                value={id}
                                checked={assignedStoreIds.includes(id)}
                              />
                            }
                            label={name}
                          />
                        ))
                      ) : (
                        <div className="user__stores-suites__selection-list">
                          Please create a Store First
                        </div>
                      )}
                    </FormGroup>
                  </FormControl>
                </div>
                {assignedStoreIds.length <= 0 && (
                  <div className="error-message">
                    Please assign at least one store.
                  </div>
                )}
              </>
            )}

            {[
              UserVenueRole.SUITE_ATTENDANT,
              UserVenueRole.SUITE_CUSTOMER,
            ].includes(role) && (
              <>
                <div className="list-store-container">
                  <InputLabel
                    className="store-label"
                    htmlFor="age-native-required"
                  >
                    {`List Stores:`}
                  </InputLabel>
                  <FormControl
                    component={'fieldset'}
                    required={true}
                    className="user-form__fieldset"
                  >
                    <FormGroup>
                      {suites.length ? (
                        suites.map(({ id, name }: IVenueLocation) => (
                          <FormControlLabel
                            key={id}
                            control={
                              <Checkbox
                                onChange={() =>
                                  handleChangeSelectedSuiteIds(id)
                                }
                                value={id}
                                checked={assignedSuiteIds.includes(id)}
                              />
                            }
                            label={name}
                          />
                        ))
                      ) : (
                        <div className="user__stores-suites__selection-list">
                          Please create a Suite First
                        </div>
                      )}
                    </FormGroup>
                  </FormControl>
                </div>
                {assignedSuiteIds.length <= 0 && (
                  <div className="error-message">
                    Please assign at least one suite.
                  </div>
                )}
              </>
            )}

            {role === UserVenueRole.SUITE_CUSTOMER && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allEvents}
                      name="allEvents"
                      onChange={() => setAllEvents(prev => !prev)}
                      value={allEvents}
                    />
                  }
                  label="User can access all events"
                />
                {!allEvents && (
                  <div className="list-store-container">
                    <InputLabel
                      className="store-label"
                      htmlFor="age-native-required"
                    >
                      Events:
                    </InputLabel>
                    <FormControl
                      component={'fieldset'}
                      className="user-form__fieldset"
                    >
                      <FormGroup>
                        {events.length ? (
                          events.map(({ id, name }: IEvent) => (
                            <FormControlLabel
                              key={id}
                              control={
                                <Checkbox
                                  onChange={() =>
                                    handleChangeSelectedEventIds(id)
                                  }
                                  value={id}
                                  checked={assignedEventIds.includes(id)}
                                />
                              }
                              label={name}
                            />
                          ))
                        ) : (
                          <div className="user__stores-suites__selection-list">
                            There are no future events to assign
                          </div>
                        )}
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
              </>
            )}

            <div className="button-holder-different-margin">
              <DialogActions>
                <Button onClick={close} color="primary" variant="outlined">
                  <div className="icon-title-wrapper">
                    <UndoOutlinedIcon />
                    <span className="icon-title">Cancel</span>
                  </div>
                </Button>
                <Button
                  disabled={shouldDisableSubmitFormButton()}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  <div className="icon-title-wrapper">
                    <CheckOutlinedIcon />
                    <span className="icon-title">Save</span>
                  </div>
                </Button>
              </DialogActions>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
