import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { get, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import Spinner from '../../../components/Spinner';
import { useAppContext } from '../../../config/AppContext';
import UserService from '../../../services/User/User.service';
import { formatNumber } from '../../../services/Util/Util.service';
import './MyProfile.scss';
import { IProfile, ProfileValidationSchema } from './Profile.config';

const MyProfile = () => {
  const {
    loadCurrentUser,
    user,
    showSpinner,
    setShowSpinner,
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const phoneNumberUtil = PhoneNumberUtil.getInstance();

  const onCancel = (resetForm: any) => {
    resetForm();
    enqueueSnackbar('Form values have been reset', {
      variant: 'success',
    });
  };

  const handleUpdateProfile = async (id: string, updateBody: IProfile) => {
    setShowSpinner(true);
    try {
      if (id) {
        await UserService.updateUser(id, updateBody);
        await loadCurrentUser();
        enqueueSnackbar('User profile updated successfully', {
          variant: 'success',
        });
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="profile-settings">
      <Formik
        initialValues={user}
        onSubmit={async values => {
          const { id, firstName, lastName, email, phoneNumber } = values;
          const updateBody = {
            email,
            firstName,
            lastName,
            phoneNumber,
          };
          await handleUpdateProfile(id, updateBody);
        }}
        validationSchema={ProfileValidationSchema}
        enableReinitialize={true}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
          errors,
          touched,
        }) => (
          <div>
            {showSpinner && <Spinner />}
            <form onSubmit={handleSubmit}>
              <Grid container={true} className="container" spacing={3}>
                <Grid item={true} xs={12}>
                  <Typography className="container-title">
                    My Profile Information
                  </Typography>
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">First Name</InputLabel>
                  <TextField
                    error={
                      get(touched, 'firstName') && !!get(errors, 'firstName')
                    }
                    required={true}
                    className="field"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="firstName"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Last Name</InputLabel>
                  <TextField
                    error={
                      get(touched, 'lastName') && !!get(errors, 'lastName')
                    }
                    required={true}
                    className="field"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="lastName"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Email</InputLabel>
                  <TextField
                    error={get(touched, 'email') && !!get(errors, 'email')}
                    required={true}
                    className="field"
                    name="routingNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="email"
                  />
                </Grid>
                <Grid className="form-field-container" item={true} xs={5}>
                  <InputLabel className="required">Contact Number</InputLabel>
                  <PhoneInput
                    defaultCountry={phoneNumberUtil.getRegionCodeForCountryCode(
                      Number(values.countryCode),
                    )}
                    placeholder="999-999-9999"
                    name="phoneNumber"
                    disabled={true}
                    required={false}
                    value={formatNumber(values.phoneNumber)}
                    className="profile-form__phone-container"
                    numberInputProps={{
                      className: 'profile-form__phone-input',
                    }}
                    onChange={e => {
                      setFieldValue('phoneNumber', e);
                    }}
                    onCountryChange={(code: string = '') => {
                      if (!isEmpty(code)) {
                        setFieldValue(
                          'countryCode',
                          getCountryCallingCode(code),
                        );
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="phoneNumber"
                  />
                </Grid>
              </Grid>
              <Grid container={true} className="padding-bottom">
                <Grid item={true} xs={12} className="form-save">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="save-btn"
                  >
                    Save
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={onCancel.bind(null, resetForm)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default MyProfile;
