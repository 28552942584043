import { Button, TablePagination, Typography } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { get } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import CustomTabs, {
  CustomRoutes,
} from '../../components/CustomTabs/CustomTabs';
import DeleteDialog from '../../components/DeleteDialog';
import { useAppContext } from '../../config/AppContext';
import { ISecondaryTab } from '../../config/Common';
import { UserVenueRole } from '../../models/User.model';
import { IUserAdmin } from '../../models/UserAdmin.model';
import VenueService from '../../services/Venue/Venue.service';
import UserAdminDialog from './UserAdminDialog/UserAdminDialog';
import UserAdminTable from './UserAdminTable/UserAdminTable';
import './Users.scss';
import { VenueInvites } from './VenueInvites';

interface IUserTab extends ISecondaryTab {
  roles: UserVenueRole[];
}
interface IUserProps extends WithSnackbarProps, RouteComponentProps {
  isFanfoodUserAdmin: boolean;
  venueId: string;
  tab: IUserTab;
}

const emptyUser: IUserAdmin = {
  allEvents: false,
  countryCode: '1',
  email: '',
  eventIds: [],
  firstName: '',
  id: '',
  pin: '',
  lastName: '',
  phoneNumber: '',
  role: UserVenueRole.VENUE_MANAGER,
  storeIds: [],
  suiteIds: [],
};

const Users = (props: IUserProps) => {
  const rowsPerPageOptions = [20, 40, 60];
  const [firstNameSearch, setFirstNameSearch] = useState('');
  const [lastNameSearch, setLastNameSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showUserAdminDialog, setShowUserAdminDialog] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState<IUserAdmin>(emptyUser);
  const [userAdmins, setUserAdmins] = useState<IUserAdmin[]>([]);

  const allOtherPins = useMemo(() => {
    let allPins = userAdmins.map((admin: IUserAdmin) => admin.pin);
    if (selectedUser) {
      allPins = allPins.filter((pin: string) => pin !== selectedUser.pin)
    }
    return allPins;
  }, [userAdmins, selectedUser])

  const { venueId, enqueueSnackbar } = props;
  const { setShowSpinner, user } = useAppContext();
  useEffect(() => {
    fetchUserAdmins();
  }, [firstNameSearch, lastNameSearch, rowsPerPage, page]);

  const fetchUserAdmins = async () => {
    setShowSpinner(true);
    try {
      const {
        tab: { roles },
      } = props;

      if (venueId) {
        const response = await VenueService.getAllUserAdmins(
          venueId,
          rowsPerPage,
          page + 1,
          firstNameSearch,
          lastNameSearch,
          roles.join(','),
        );
        setTotalCount(get(response, ['data', 'totalItems'], 0));
        setUserAdmins(get(response, ['data', 'items'], []));
      }
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(+get(event, ['target', 'value'], rowsPerPageOptions[0]));
  };

  const handleEditModal = (userAdmin: IUserAdmin) => {
    setSelectedUser(userAdmin);
    setShowUserAdminDialog(true);
  };

  const handleDeleteModal = (userAdmin: IUserAdmin) => {
    setSelectedUser(userAdmin);
    setShowDeleteDialog(true);
  };

  const handleSearchUserAdmins = (firstName: string, lastName: string) => {
    setPage(0);
    setFirstNameSearch(firstName);
    setLastNameSearch(lastName);
  };

  const deleteUserAdmin = async () => {
    if (!(selectedUser && selectedUser.id)) {
      return;
    }

    setShowSpinner(true);
    try {
      await VenueService.deleteUserAdmin(venueId, selectedUser.id);

      enqueueSnackbar('User has been deleted successfully', {
        variant: 'success',
      });

      setPage(0);
      setShowDeleteDialog(false);
      setSelectedUser(emptyUser);
      await fetchUserAdmins();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
    setShowSpinner(false);
  };

  const openNewUserDialog = () => {
    setSelectedUser(emptyUser);
    setShowUserAdminDialog(true);
  };

  const handleUserAdminAction = async (userAdmin: IUserAdmin) => {
    setShowSpinner(true);
    try {
      if (userAdmin.id) {
        await VenueService.updateUserAdmin(venueId, userAdmin);
        enqueueSnackbar('User profile has been successfully updated', {
          variant: 'success',
        });
      } else {
        await VenueService.createUserAdmin(venueId, userAdmin);
        enqueueSnackbar('User profile has been successfully created', {
          variant: 'success',
        });
      }
      setShowUserAdminDialog(false);
      setSelectedUser(emptyUser);
      await fetchUserAdmins();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      const defaultError = 'Something went wrong. Please try again.';
      const errorToDisplay = get(
        error,
        ['response', 'data', 'message'],
        defaultError,
      );
      enqueueSnackbar(
        typeof errorToDisplay === 'string' ? errorToDisplay : defaultError,
        {
          variant: 'error',
        },
      );
    }
    setShowSpinner(false);
  };

  const handleCloseUserAdminDialogAction = () => {
    setShowUserAdminDialog(false);
    setSelectedUser(emptyUser);
  };

  return (
    <>
      <div className="pagination-container">
        <TablePagination
          classes={{
            caption: 'pagination-caption',
            root: 'pagination-root',
            select: 'pagination-select',
            toolbar: 'pagination-toolbar',
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          colSpan={3}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Button
          size="medium"
          color="primary"
          aria-label="add"
          classes={{
            root: 'add-user-button',
          }}
          onClick={openNewUserDialog}
        >
          <div className="icon-title-wrapper">
            <AddOutlinedIcon />
            <span className="icon-title">Add User</span>
          </div>
        </Button>
      </div>
      {showUserAdminDialog && (
        <UserAdminDialog
          userAdminAction={handleUserAdminAction}
          close={handleCloseUserAdminDialogAction}
          userAdmin={selectedUser}
          venueId={venueId}
          otherPins={allOtherPins}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          dialogTitle="Delete User Admin"
          dialogContent="Are you sure you want to remove this User Admin?"
          onClose={() => setShowDeleteDialog(false)}
          onSubmit={deleteUserAdmin}
        />
      )}
      <UserAdminTable
        userAdmins={userAdmins}
        searchUserAdmins={handleSearchUserAdmins}
        editUserAdmin={handleEditModal}
        deleteUserAdmin={handleDeleteModal}
      />
    </>
  );
};

const UserAdminRoles = [
  UserVenueRole.VENUE_MANAGER,
  UserVenueRole.STORE_MANAGER,
  UserVenueRole.STORE_EMPLOYEE,
  UserVenueRole.SUITE_ATTENDANT,
  UserVenueRole.RUNNER,
  UserVenueRole.POS_USER,
];

const SuiteCustomer = [UserVenueRole.SUITE_CUSTOMER];

const UserTabs: IUserTab[] = [
  {
    component: Users,
    name: 'User Admins',
    roles: UserAdminRoles,
    route: '',
  },
  {
    component: Users,
    name: 'Suite Customers',
    roles: SuiteCustomer,
    route: '/customers',
  },
  {
    component: VenueInvites,
    name: 'Venue Invites',
    roles: [],
    route: '/invites',
  },
];

const UserContainer: React.FC<IUserProps> = props => {
  return (
    <div className={'users'}>
      <Typography component="h1" variant="h5" className="section-title">
        Users
      </Typography>
      <CustomTabs {...props} tabs={UserTabs} />
      <CustomRoutes {...props} tabs={UserTabs} />
    </div>
  );
};

export default withSnackbar(UserContainer);
